const { renderAsync, renderTo } = require('./cmp-renderer')

const HTMLStringChild = {
  htmlString: true,

  toCode() {
    return 'html(`' + this.s + '`)'
  },

  toString() {
    return this.s
  },

  render() {
    if (this.el) return this.el
    const f = document.createElement('div')
    f.innerHTML = this.s
    this.el = Array.from(f.childNodes)
    this.rendered = true
    return this.el
  },

  renderAsync() {
    return renderAsync(this)
  },

  renderTo(x) {
    return renderTo(this, x)
  }
}

/**
 *
 * @param {string} s - Trusted HTML string to be inserted as HTML
 */
const html = s => {
  const result = Object.create(HTMLStringChild)
  result.s = s
  return result
}

exports.html = html

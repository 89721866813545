// makeDropdown7.js
const defaultMaxHeight = 270

module.exports.makeDropdown7 = (qcDd, ddParent, getOffsets) => {
  const reposition = function () {
    const rect = getOffsets()

    // set position again
    // vertical position
    // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
    const topFromBody = rect.top + rect.height

    const spaceBelow = document.body.scrollHeight - topFromBody
    const spaceAbove = rect.top - document.body.scrollTop

    const firstRender = !this.el?.offsetHeight
    const h = firstRender ? defaultMaxHeight : Math.min(this.el.offsetHeight, defaultMaxHeight)

    const floatUp = firstRender
      ? spaceAbove > spaceBelow && defaultMaxHeight > spaceBelow
      : spaceAbove > spaceBelow && h > spaceBelow

    const css = {
      top: floatUp ? undefined : rect.top + rect.height - no$(ddParent).offset().top + 'px',
      bottom: floatUp
        ? no$(ddParent).offset().top + ddParent.offsetHeight - rect.top + 'px'
        : undefined,
      left: rect.left - no$(ddParent).offset().left + 'px',
      maxHeight: Math.min(floatUp ? spaceAbove : spaceBelow, defaultMaxHeight) + 'px'
    }

    this.css(css)
  }

  return qcDd.addClass('dropdown dropdown7').props({ reposition }).bindState(reposition)
}

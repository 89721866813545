const { isDate } = require('../../lib/js-types')
const { dates } = require('./culture')
const { buddhistCalendarOffset } = dates

/**
 * Accepts date-time information and returns a date object containing that date-time information.
 * Note: If first parameter is missing any date/time components (e.g. minutes) the returned date object will set those componenents to values from the current date/time.
 *
 * @param {string} str - String representation of date time, e.g. '26/05/2021 22:09:15'  Not all components are required (e.g. seconds can be omitted). Delineators can be any non-letter/non-number character.
 * @param {string} format - Hyphen-separated string describing the format of the first parameter (e.g. 'HH-mm' to represent a string contining hours and minutes). These are the allowed characters: dd, MM, yyyy, HH, mm, ss. Note that MM represents months and mm represents minutes.
 */
const parseDate = (str = '', format = 'yyyyMMddHHmmss', calendar = dates.calendar) => {
  if (isDate(str)) return str
  if (format && Array.isArray(format)) return format.find(fmt => parseDate(str, fmt, calendar))

  if (str === null) return
  if (str === '') return

  if (format?.length === 1) format = dates.datePatterns[format] || format

  try {
    const allowedChars = ['dd', 'MM', 'yyyy', 'yy', 'HH', 'mm', 'ss']

    if ((str.split('T').length = 2 && str[4] === '-')) {
      if (str === '1899-12-30T00:00:00') return // this is a database null

      try {
        return new Date(str.replace('T', ' '))
      } catch (err) {
        console.error('parseDate failed')
        return
      }
    }
    var normalized = str.replace(/[^a-zA-Z0-9]/g, '-')
    var normalizedFormat = format.replace(/[^a-zA-Z0-9]/g, '-')

    var formatItems = normalizedFormat.split('-')
    var dateItems = normalized.split('-')

    // If the format parameter has prohibited characters, developer probably made a mistake, so throw an exception.
    formatItems.forEach(element => {
      if (!allowedChars.includes(element)) {
        throw `Prohibited characters found in 'format' parameter: ${element}. Only these characters are allowed: ${allowedChars}`
      }
    })

    var dayIndex = formatItems.indexOf('dd')
    var monthIndex = formatItems.indexOf('MM')
    var yearIndex = formatItems.indexOf('yyyy')
    var useShortYear = false
    if (yearIndex < 0) {
      yearIndex = formatItems.indexOf('yy')
      useShortYear = true
    }

    var hourIndex = formatItems.indexOf('HH')
    var minutesIndex = formatItems.indexOf('mm')
    var secondsIndex = formatItems.indexOf('ss')

    var now = new Date()
    var year = (yearIndex > -1 ? dateItems[yearIndex] : now.getFullYear().toString()) || ''

    const tippingShortYear = 60
    if (year.length === 2)
      year =
        calendar === 'buddhist'
          ? '25' + year
          : (parseInt(dateItems[yearIndex]) > tippingShortYear ? '19' : '20') + year

    var month = monthIndex > -1 ? parseInt(dateItems[monthIndex]) - 1 : now.getMonth()
    var day = dayIndex > -1 ? parseInt(dateItems[dayIndex]) : now.getDate()

    var hour = parseInt(((hourIndex > -1 ? dateItems[hourIndex] : '0') + '0').substr(0, 2))
    var minute = parseInt(((minutesIndex > -1 ? dateItems[minutesIndex] : '0') + '0').substr(0, 2))
    var second = parseInt(
      ((secondsIndex > -1 ? dateItems[secondsIndex] || '00' : '0') + '0').substr(0, 2)
    )

    year = parseInt(year)

    year = calendar === 'buddhist' ? year - buddhistCalendarOffset : year

    return new Date(year, month, day, hour, minute, second)
  } catch (err) {
    console.warn('parseDate format failed on format', format, err)
    return
  }
}

module.exports = { parseDate }

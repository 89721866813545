const { card } = require('./scheduler7-card')
const { cmenu7 } = require('../cmenu7')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')

const { getWeekOfYear, weekStarts, formatDate, leftPad } = require('../../dates')
const { react } = require('../data-model7')

const findFirstFreeSpace = arr => {
  let result = 0
  while (arr[result]) result++
  return result
}

module.exports.findFirstFreeSpace = findFirstFreeSpace

const slotMap = (data, numSlots) => {
  let i,
    j,
    siblingId,
    map = []
  for (i = 0; i <= numSlots; i++) map[i] = []
  data.forEach(r => {
    if (r.rowid <= numSlots) {
      siblingId = findFirstFreeSpace(map[r.rowid])
      for (j = 0; j < (r.slot || 1); j++) if (map[r.rowid + j]) map[r.rowid + j][siblingId] = r
    }
  })

  return map
}

module.exports.slotMap = slotMap

const minsInADay = 60 * 24
const populateFixedRows = (gap, start = 0, end = minsInADay) => {
  let result = [start]
  while (start < end - gap) {
    start += gap
    result.push(start)
  }
  return result
}

const timelineHeader = scheduler =>
  qc('th').css({ minWidth: (scheduler.opts.cols?.colWidth ?? 100) + 'px' })

const slotCell = (scheduler, rowi, col) => {
  const me = qc('td.droppable-cell')
    .on('dragenter', e => {
      e.preventDefault()
      me.addClass('hovered')
    })
    .on('dragleave', () => me.removeClass('hovered'))
    .on('dragover', e => killEvent(e))
    .on('drop', e => {
      me.removeClass('hovered')

      // get the draggable element
      const { model } = scheduler.draggingCard
      model.rowid = rowi
      model.colid = col
      react(scheduler.view, model, 'rowid', 'colid')

      scheduler.draggingCard.el.remove()
      scheduler.draggingCard.renderTo(me.el)

      return killEvent(e)
    })
    .on('context-menu', e => {
      if (e.target?.tagName !== 'TD') return
      const mnu = cmenu7(e.target, {
        view: scheduler.view,
        point: { left: e.pageX, top: e.pageY },
        setWidth: false,
        content() {
          let res = qc('ul', scheduler.opts.menu({ rowi, col }) || []).on('click', () =>
            mnu.close()
          )
          res.rowid = rowi
          res.colid = col
          return res
        }
      })
      return killEvent(e)
    })
    .bindState(() => {
      const colData =
        scheduler.model?.filter(f => f.colid === col).sort((a, b) => a.rowid - b.rowid) ?? []

      const cardData = colData.filter(f => f.rowid === rowi && f.colid === col)

      const colSlotMap = slotMap(colData, minsInADay / scheduler.opts.interval)

      if (cardData.length !== me.kids().length || cardData.find(x => me.kids().model !== x)) {
        me.kids(
          cardData.map(model => {
            const siblingId = colSlotMap[model.rowid].indexOf(model)

            let siblingCount = 1
            for (let i = 0; i < (model.slot || 1); i++) {
              siblingCount = Math.max(siblingCount, colSlotMap[i + model.rowid].length)
            }

            let me

            return (me = card({
              model,
              fieldName: 'id',
              scheduler,
              siblingId,
              siblingCount,
              resizable: { rowid: model.rowid }
            }).on('dragstart', () => (scheduler.draggingCard = me)))
          })
        )
      }
    })

  return me
}

const buildSchedulerHeader = (scheduler, col) => {
  const { colFieldDesc, mode } = scheduler.opts.cols
  return qc(
    'th',
    qc('span', mode ? col['Desc'] : typeof col === 'object' ? col[colFieldDesc] : col)
  )
    .attr({ scope: 'col', role: 'columnheader' })
    .css({
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: (scheduler.opts.cols?.colWidth || 100) + 'px'
    })
}

//body
const drawRow = (scheduler, row, rowi, cols) =>
  qc('tr.ow-scheduler-row.row-' + rowi, [
    qc('td', row % 60 === 0 ? leftPad(row / 60) + ':00' : html('&nbsp')),
    cols.map(col => slotCell(scheduler, rowi, col))
  ])
    .attr({ rowid: rowi })
    .css({ lineHeight: (scheduler.opts.rowHeight ?? 20) + 'px' })

const schedulerBody = scheduler => {
  const _mode = scheduler.opts.cols?.mode || 0
  const populateWeeklyCols = () => {
    let cols = []
    const { selectedDate } = scheduler.state.current
    const { dateFormat } = scheduler.opts.cols
    const weekNo = getWeekOfYear(selectedDate, new Date().getFullYear())
    const base = weekStarts(weekNo)
    for (let i = 0; i < 7; i++) {
      cols.push({
        Id: base.valueOf().toString(),
        Desc: formatDate(new Date(base), dateFormat || 'yyyy-MM-dd')
      })
      base.setDate(base.getDate() + 1)
    }

    return cols
  }

  let _colObj = _mode ? populateWeeklyCols() : scheduler.opts.cols?.colList || []
  let _cols = _colObj.map(x => (_mode ? x['Id'] : x[scheduler.opts.cols?.colFieldName]))

  let tableHeader

  return qc(
    'div.ow-scheduler-context',
    qc('table.scheduler7', [
      qc(
        'thead',
        (tableHeader = qc('tr.ow-scheduler-head').bindState(() =>
          tableHeader.kids([
            timelineHeader(scheduler),
            _colObj.map(col => buildSchedulerHeader(scheduler, col))
          ])
        ))
      ),

      qc('tbody').bindState(
        () => scheduler.model,
        (v, me) =>
          me.kids(
            populateFixedRows(scheduler.opts.interval || 15, scheduler.opts.start).map(
              (row, index) => drawRow(scheduler, row, index, _cols)
            )
          )
      )
    ])
  ).props({
    scheduler,

    loadHeader() {
      if (!_mode) return
      _colObj = populateWeeklyCols()
      _cols = _colObj.map(x => x.Id)
      tableHeader.renderAsync()
    }
  })
}
module.exports.schedulerBody = schedulerBody

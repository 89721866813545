const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { $find } = require('../no-jquery')
const { _v } = require('../_v')
const { gridPager } = require('../controls/grid-pager')
const { qcControls } = require('../qcControls/common-qc-controls')
const { registerCtl, ctlType, displayValidity } = require('./ctl5')
const { filterController, dataItemController } = require('./dc5')
const { iconCodes } = require('../icon-codes')
const { filterOperators, colsToGridColumns } = require('./gridcols5')
const { dates } = require('../dates')
const { debounce } = require('../debounce')
const { setResizeHandler } = require('../viewWindow')
const { cmenu7 } = require('../ow7/cmenu7')

const $rec = tr => tr && tr.rec

const resetMeta = rec => {
  const orig = JSON.parse(JSON.stringify(rec))
  delete orig._meta
  Object.assign(rec._meta, {
    orig,
    prev: {},
    changes: {}
  })
  return rec
}

const groupMembers = (g, group) =>
  g.currentFilter.filterRecs.filter(r => !r._group && r._meta.group === group)

const setGroupTogglerStyle = g => {
  $find(g, '.group-toggler').forEach(el => {
    var tr = el.closest('tr')
    let r = $rec(tr)
    el.innerHTML = r._group.open ? '&#xf0d7' : '&#xf0da'
  })
}

const toggleGroup = (g, tr) => {
  let r = $rec(tr)
  r._group.open = !r._group.open
  g.dc.applyGroupVisibility(g.currentFilter)
  g.swapPage()
}

const expandAllGroups = (g, expand = true) => {
  if (!g.recs) return
  g.currentFilter.recs.filter(item => item._group).forEach(r => (r._group.open = expand))
  g.dc.applyGroupVisibility(g.currentFilter)
  g.swapPage()
}
const collapseAllGroups = g => expandAllGroups(g, false)

/**
 * For lightweight bulk editing of up to 10K records at once with client-side virtual page swapping
 * no line or cell "edit state" changes
 *
 * @params {object} opts.view -
 * @params {object} opts.dc -
 * @params {array|object} opts.cols -
 * @params {boolean} opts.live - will cause edited lines to be saved on row change and deletes to call to the server on delete click.
 * @params {boolean} opts.sortable - can click on header to sort by that field on clientside
 * @params {boolean} opts.resizable - can resize columns
 * @params {boolean} opts.selectable - can select rows (not yet implemented)
 * @params {boolean} opts.hasFilters -
 * @params {boolean} opts.hasColFilters -
 * @params {boolean} opts.editable - can edit rows
 * @params {boolean} opts.allowNewRow
 * @params {boolean} opts.resetMetaAfterPopulate - this will initialize the new row with the control's values after populate so they don't register as changes.
 * @params {object} opts.disallowNewWhenExistingNewInvalid
 * @params {boolean} opts.showDeletedRows - when user deletes a row, it can still be seen but is struck through - disappears on save.
 * @params {boolean} opts.indicateCurrentRow -
 * @params {boolean} opts.indicateCurrentCell -
 * @params {boolean} opts.saveOnlyChangedRows - when calling g.readData, only records that have changed will be .
 * @params {boolean} opts.allowColumnMenu - right click to hide-show columns
 * @params {boolean} opts.allowSaveTemplate - in header context menu you can save current column sizes etc as default.
 * @params {object} opts.reactFields optional - the properties are fieldnames of records, if false changes for that field do not require saving.  If a control exists with data-field=XXX then XXX will be set to true if not already set to false.
 * @params {array} opts.buttonColumn - similar to the old grid eg ['save', 'delete', {...}]
 * @params {string} opts.fieldName - this is normally set using attr data-field=fieldName
 * @params {boolean} opts.markChanges - indicates a row has unsaved changes on the left side
 * @params {object} opts.viewdata - if you need to supply data other than the normal $top.viewdata
 * @params {array[string]} opts.groupBy - list of fieldNames to groupby. Not compatible with client-side  filters and sorting
 * @params {boolean} opts.expandAllGroup -
 * @params {boolean} opts.saveOnlyFiltered - default false, only applies to child grids with client filtering
 *
 * @params {boolean} opts.paging - Abandoned previously - left in here for future but will not work as is.
 */
const grid5 = function (g, opts) {
  var $top = opts.view?.$top ?? g.myWin()
  if (!opts.view) {
    console.log('please pass opts.view into grid5')
    opts.view = $top.view()
  }

  const qGrid = qc(g)

  let qFooter, qBody, qHeader, qPlaceHolder, qPlaceHolderTail, qAddRowOnFocus, allRowElements

  g.ctlTypeClass = 'grid'

  g.opts = opts

  g.id = g.id || (opts.id ?? opts.iden ?? opts.name + 'Grid')
  if (!g.id) g.id = 'g-' + Date.now()
  g.iden = g.id

  qGrid.addClass('iden-' + g.iden)

  if (opts.resizable !== false) qGrid.addClass('resizable-columns')

  if (opts.hasColFilters === undefined && opts.hasFilters === false) opts.hasColFilters = false

  g.groupMembers = group => groupMembers(g, group)
  g.setGroupTogglerStyle = () => setGroupTogglerStyle(g)
  g.groupTogglerHTML = rec =>
    qc('i.fa.group-toggler', html(!rec._group.open ? '&#xf0da' : '&#xf0d7'))
      .css({
        position: 'absolute',
        left: '3px',
        fontSize: '1.4em',
        lineHeight: '1.5em',
        display: 'inline',
        color: '#666'
      })
      .on('click', e => toggleGroup(g, e.target.closest('tr')))

  g.expandAllGroups = () => expandAllGroups(g)
  g.collapseAllGroups = () => collapseAllGroups(g)

  if (opts.hasColFilters !== false) $(g).addClass('ow-has-filterrow')

  if (opts.dc) {
    g.dc = opts.dc
    g.dc.populate = function (recs) {
      g.dc._numChanges = 0
      g.dc._changes = {}
      if (opts.dc.opts.prePopulate) opts.dc.opts.prePopulate(recs)
      g.val(recs)
    }
    g.populate = function (model) {
      g.dc.populate(_v(model, opts.fieldName) || [])
      qc(g).trigger('ow-grid-databound')
    }
  }

  opts.editable = opts.editable || opts.editable !== false

  if (opts.editable) qGrid.addClass('editable-grid')

  if (opts.allowNewRow === undefined) opts.allowNewRow = opts.editable
  if (opts.tabOutNewRow === undefined) opts.tabOutNewRow = opts.allowNewRow !== false
  if (opts.markChanges !== false) $(g).addClass('ow-mark-changes')
  opts.viewdata = opts.viewdata || g.myWin().viewdata

  opts.view.viewdata.winpref.grids ??= {}
  opts.userSettings = opts.view.viewdata.winpref.grids[g.iden] ??= {}
  g.userSettings = opts.userSettings

  if (!opts.userSettings.filterToggle) qGrid.addClass('ow-hide-filterrow') // default to not showing

  g.recs = []
  g.state = { populating: 0 }

  g.fillout = function () {
    var $g = $(g)
    if (!$g) return
    var newHeight = $g.innerHeight(), // newWidth = $g.innerWidth(),
      otherElements = $g.children().not('.k-grid-content'),
      otherElementsHeight = 0
    if (newHeight < 20) return
    otherElements.forEach(el => (otherElementsHeight += $(el).outerHeight()))
    $g.children('.k-grid-content').outerHeight(newHeight - otherElementsHeight)
  }

  const gridCols = g.opts.cols.filter(c => c.gridCol !== false)

  // column hide, show, resize functionality
  {
    const setColStyles = () => {
      gridCols.forEach(c => {
        $(g)
          .find('.coli-' + c.coli)
          .css({ width: c.width + 'px', display: c.hidden ? 'none' : '' })
      })

      resetTableWidth()
    }
    g.setColStyles = setColStyles

    const totalColWidth = () => {
      var w = 0
      var i, col
      for (i = 0; i < g.opts.cols.length; i++) {
        col = g.opts.cols[i]
        if (col.gridCol !== false && !col.hidden) w += col.width
      }
      return w
    }

    const setTableWidth = w =>
      $(g)
        .find('table')
        .css({ width: w + 'px' })

    const getColumnWidth = th => qc(th).col.width

    const setColumnWidth = (th, w) => {
      w = parseFloat(w)
      qc(th).col.width = w
      resetTableWidth()
      g.setColStyles()
      _v(g.userSettings, 'cols.' + qc(th).coli + '.width', w)
    }

    const resetTableWidth = () => setTableWidth(totalColWidth())

    const fitColumns = () => {
      const widthRatio = totalColWidth() / (qGrid?.el.clientWidth - 26)

      if (widthRatio > 0.8 && widthRatio < 1.2) {
        var i, col
        for (i = 0; i < g.opts.cols.length; i++) {
          col = g.opts.cols[i]
          if (col.gridCol !== false && !col.hidden)
            col.width = Math.trunc((10 * col.width) / widthRatio) / 10
        }
        resetTableWidth()
        g.setColStyles()
      }
    }

    opts.fitColumns ??= true

    if (opts.fitColumns)
      qGrid.bindState(
        () => qGrid.el?.clientWidth,
        w => w && fitColumns()
      )

    const coliByName = name => {
      for (let i = 0; i < g.opts.cols.length; i++)
        if (g.opts.cols[i].field === name) return g.opts.cols[i].coli
      return -1
    }

    g.hideColumn = function (coli) {
      if (typeof coli === 'string') coli = coliByName(coli)
      var col = g.opts.cols.find(c => c.coli === coli)
      col.hidden = 1

      resetTableWidth()
      g.setColStyles()

      // update user settings
      _v(g.userSettings, 'cols.' + coli + '.hidden', 1)
    }

    g.showColumn = function (coli) {
      if (typeof coli === 'string') coli = coliByName(coli)
      var col = g.opts.cols.find(c => c.coli === coli)
      col.hidden = 0

      resetTableWidth()
      g.setColStyles()
      _v(g.userSettings, 'cols.' + coli + '.hidden', 0)
    }

    g.initColumnMenu = function (allowSaveTemplate) {
      qHeader.on('contextmenu', function (e) {
        cmenu7(e.target, {
          view: opts.view,
          point: { left: e.pageX, top: e.pageY },
          // point: { left: e.pageX - left, top: e.pageY - top },
          setWidth: false,
          content() {
            return qc('ul', [
              gridCols
                .filter(col => col.field)
                .map(col =>
                  qc(
                    'li',
                    qc('a.ow-check' + (col.isMandatory ? '.ow-disabled' : ''), col.title)
                      .bindState(
                        () => col.hidden,
                        (hidden, chk) => (hidden ? chk.removeClass('on') : chk.addClass('on'))
                      )
                      .attr({ href: '#', 'data-field': 'col-' + col.coli })
                      .on('click', () => {
                        col.hidden ? g.showColumn(col.coli) : g.hideColumn(col.coli)
                      })
                  ).attr({ 'data-coli': col.coli })
                ),
              ...(allowSaveTemplate
                ? [
                    qc(
                      'li',
                      qc('a.menu-item', __('Set Default Template'))
                        .attr({ href: '#' })
                        .on('click', () => qGrid.trigger('command-defaulttemplate'))
                    )
                  ]
                : [])
            ])
          }
        })
      })
    }

    qGrid.resizeColOnMouseDown = (e, th) => {
      var m_pos = e.pageX
      var origWidth = getColumnWidth(th)
      setResizeHandler(
        ({ pageX }) => {
          const newWidth = origWidth + pageX - m_pos
          setColumnWidth(th, newWidth < 5 ? 5 : newWidth)
          return false
        },
        () => {}
      )
      return false
    }
  }

  // virtual page swapping
  {
    g.swapPage = function (thenFn) {
      var fRecs = g.currentFilter.recs

      var pageTop = g.$content.scrollTop()
      var gridHeight = g.$content.innerHeight()
      var firstRowIndex = pageTop === 0 ? 0 : Math.max(0, Math.trunc(pageTop / g.rowHeight - 3))
      var lastRowIndex = Math.min(
        firstRowIndex + Math.trunc(gridHeight / g.rowHeight) + 12,
        fRecs.length
      )

      let wantRowIsIn = []
      for (let fi = firstRowIndex; fi < lastRowIndex; fi++) wantRowIsIn[fi] = fRecs[fi]

      var $previousInsert = $(qPlaceHolder.el)

      let activeRow = document.activeElement?.closest('tr.row')

      qBody.find('tr.row').forEach(tr => {
        var rowi = $(tr).attr('data-rowi')
        if (rowi === -1 || tr !== activeRow) return $(tr).detach()
        if (g.currentFilter.filterMap.indexOf(parseInt(rowi)) <= firstRowIndex)
          $previousInsert = $(tr)
      })

      qPlaceHolder.css({ height: firstRowIndex * g.rowHeight + 'px' })
      if (firstRowIndex === 0) qPlaceHolder.css({ display: 'none' })
      else qPlaceHolder.css({ display: undefined })

      const h = (fRecs.length - lastRowIndex) * g.rowHeight
      qPlaceHolderTail.css({ height: h + 'px', display: h === 0 ? 'none' : undefined })

      for (let fi = firstRowIndex; fi < lastRowIndex; fi++) {
        let $tr = g.get$tr(fRecs[fi])
        // if (!$tr[0]) $tr = g.drawRow(recs[fi], false, recs[fi]._meta.rowi)
        $tr.css({ height: g.rowHeight + 'px' })
        if (!$tr.parent()[0]) {
          $tr.insertAfter($previousInsert)
          $tr.find('[data-field]').ctlType()
          $tr[0] && qc($tr[0]).trigger('ow-swap-row-in')
        }
        $previousInsert = $tr
      }

      g.setColStyles()
      g.setGroupTogglerStyle()

      thenFn && thenFn() // doSwapping(thenFn)
    }

    var lastCheck = Date.now()
    var lastPosition = 0
    var scrollCheckTimeout = null

    // checks if the vertical scroll position requires page swapping
    g.scrollCheck = function () {
      var newPage = Math.max(0, Math.trunc(g.$content.scrollTop() / g.rowHeight - 3))

      if (newPage === g.page) return

      var posChanged = Math.abs(g.$content.scrollTop() - lastPosition) > 50 // there can be oscillations

      if (posChanged) {
        // wait for it to stop moving!
        lastPosition = g.$content.scrollTop()
        if (!scrollCheckTimeout) setTimeout(() => g.scrollCheck(), 20)
        return
      }

      if (Date.now() - lastCheck < 60 || g.pageChanging) {
        if (!scrollCheckTimeout) setTimeout(() => g.scrollCheck(), 20)
        return
      }
      lastCheck = Date.now()

      if (newPage !== g.page) {
        g.page = newPage
        g.swapPage()
      }
    }

    g.initMeta = function (rec, i) {
      new dataItemController({ rec, reactFields: opts.reactFields }).initMeta(i)
    }
  }

  g.update = () => {}

  g.val = function (rows) {
    if (rows === undefined) return g.recs

    if (rows === g.recs) return // if we are repopulating with same data

    if (g.dc) g.dc.cancelChanges() // resets the change control

    g.pageChanging = true

    g.recs = rows // g.recs is the result
    g.rowiMap = [] // an array of all the records including deleted.  rowi is the position in this array not g.recs

    var $t = g.$content.find('tbody')
    $t.find('tr.row').detach()
    allRowElements = []

    let rec, i
    for (i = 0; i <= g.recs.length - 1; i++) {
      rec = g.recs[i]
      g.rowiMap.push(rec)
      g.initMeta(rec, i)
    }

    const createRowElementsInBackground = () => {
      let i
      for (i = 0; i < g.rowiMap.length; i++)
        if (!allRowElements[i]) {
          g.get$tr(i)
          setTimeout(createRowElementsInBackground, 10)
          return
        }
    }

    createRowElementsInBackground()

    // work out row height
    g.rowHeight = 27

    // append the first row to find the rowHeight
    if (g.recs.length) {
      const $firstRow = g.get$tr(g.recs[0])
      $t.append($firstRow)
      g.setColStyles()
      g.rowHeight = $firstRow.outerHeight() || g.rowHeight
      qAddRowOnFocus.css({ height: g.rowHeight + 'px' })
      $firstRow.detach()
    }

    g.refilter()

    if (opts.groupBy && g.opts.expandAllGroup === false) {
      g.currentFilter.recs.forEach(rec => rec._group && (rec._group.open = false))
      g.dc.applyGroupVisibility(g.currentFilter)
    }

    g.pageChanging = true
    $t.show()
    g.pageChanging = false
    g.swapPage()
    g.refreshFooter()

    setTimeout(() => g.resizeGrid(), 200)

    return g.recs
  }

  g.resizeGrid = function () {
    g.fillout()
    if (g.$header) {
      var $topright = g.$header.find('.ow-grid-top-right')
      $topright.css({
        height: g.$header.outerHeight() + 'px',
        width: g.$header.outerWidth() - g.$header.find('> div').outerWidth() + 'px'
      })

      // set column size and hide
      g.setColStyles()

      g.swapPage()
    }
  }

  function applyColOptionsToRow($row) {
    var gCols = opts.cols.filter(c => c.gridCol !== false)

    gCols.forEach(col => {
      var $cell = $row.find('td.coli-' + col.coli)

      $cell.find('[data-field]').forEach(el => {
        if (!el.opts && $(el).hasClass('col-ctl')) {
          $(el).removeClass('col-ctl')
          if (col.ctl) el.opts = Object.create(col.ctl)
        }
        el.opts = el.opts || {}
        el.opts.model = g.getDataForRow($row)

        if (col.format && $(el).hasClass('read-only')) el.opts.format = col.format
      })
    })
  }

  g.refreshField = function (rec, field) {
    // todo: find the column first and use refreshCell if available
    const $tr = g.get$tr(rec, false)
    if (!$tr) return

    $tr.find('[data-field="' + field + '"]').forEach(el => {
      // var $el = $(el)
      const td = el.closest('td')

      if (!td) return // in case there's been a detachment since $tr.find

      const col = g.getColForCell($(td))

      const ro =
        col.readOnly === true || (typeof col.readOnly === 'function' && col.readOnly(rec) === true)

      const qTd = qc(td)
      const qSpan = qc(td.children[0])

      if (ro) {
        qTd.addClass('non-editable-cell').addClass('no-tab')
        qSpan.addClass('read-only')
      } else {
        qTd.removeClass('non-editable-cell').removeClass('no-tab')
        qSpan.removeClass('read-only')
      }

      g.state.populating++
      el.val(columnDataValue(col, rec))
      g.state.populating--

      qTd.renderAsync()
    })
  }

  g.refreshCell = function ($td) {
    const qTd = $td[0] && qc($td[0])
    const qTr = $td[0]?.parentElement && qc($td[0].parentElement)
    var rec = qTr.rec
    if (!rec) return
    var col = qTd?.col
    var hasFocus = $td.find(':focus').length

    qTd.kids(g.drawCell(col, rec, rec._meta.rowi).children)
    applyColOptionsToRow($td.parent())

    qTd.find('[data-field]').forEach(el => ow5.ctlType(el))

    if (col.field) g.refreshField(rec, col.field)

    const ro = typeof col.readOnly === 'function' ? col.readOnly(rec) : col.readOnly || false
    ro ? qTd.addClass('non-editable-cell no-tab') : qTd.removeClass('non-editable-cell no-tab')

    if (hasFocus) g.focusCell($td)

    qTd.renderAsync()
  }

  g.drawCell = (col, rec, rowi) => {
    if (col.gridCol === false) return ''

    const me = qc('td.gridcell.coli-' + col.coli)
      .props({ col, coli: col.coli, rec })
      .attr({ 'data-coli': '' + col.coli })
      .on('click', function () {
        if (me.hasClass('non-editable-cell')) g.current($(me.el))
      })
      .on('focusin', () => g.current($(me)))

    if (col.field === 'i') return me.kids('' + rowi) // For testing

    let ro =
      col.readOnly === true || (typeof col.readOnly === 'function' && col.readOnly(rec) === true)
    if (ro) me.addClass('non-editable-cell no-tab')

    let classes = (typeof col.classes === 'function' ? col.classes(rec) : col.classes) || ''

    classes && me.addClass(classes)
    col.kCol.attributes?.class && me.addClass(col.kCol.attributes.class)
    col.type && me.addClass(col.type + '-col')

    let content
    if (rec._group) {
      if (!rec._group.footer && col.groupHeaderTemplate) content = col.groupHeaderTemplate(rec)
      if (rec._group.footer && col.groupFooterTemplate) content = col.groupFooterTemplate(rec)
      me.addClass('nongroupcell')
    } else {
      content = col.kCol.template(rec)
      if (content && ro && col.field) {
        content =
          typeof content === 'string'
            ? content.replace('class=', 'data-field="' + col.field + '" class=')
            : content.attr({ 'data-field': col.field })
      }
    }

    me.kids(typeof content === 'string' ? html(content || '&nbsp;') : content)

    if (col.colspan && col.colspan > 1) me.attr({ colspan: '' + col.colspan })

    col.preRender?.(me)
    return me
  }

  g.refreshRow = function ($tr, rec) {
    if (!rec) rec = g.getDataForRow($tr)
    if (!rec) return
    const qTr = qc($tr[0])
    opts.cols
      .filter(c => c.gridCol !== false)
      .map(col => {
        const ro = typeof col.readOnly === 'function' ? col.readOnly(rec) : col.readOnly || false
        const qTd = qc(qTr.find('td.coli-' + col.coli)[0])
        if (col.refresh) return col.refresh(rec, qTd)

        // it was editable, if no longer then replace
        const needsReplacing = !ro && qTd.hasClass('non-editable-cell') ? true : false

        if (!needsReplacing && col.field) {
          ro
            ? qTd.addClass('non-editable-cell no-tab')
            : qTd.removeClass('non-editable-cell no-tab')

          // if readonly then we need the formatted text value
          let v = ro ? columnValue(col, rec, col.coli, true, true) : columnDataValue(col, rec)

          $tr.find('[data-field="' + col.field + '"]').forEach(el => el.val(v))
        } else g.refreshCell($(qTd.el))
      })

    applyColOptionsToRow($tr)
    qTr.renderAsync()
  }

  g.drawRow = function (rec, hidden, rowi) {
    var gCols = opts.cols.filter(c => c.gridCol !== false)
    var cells = gCols.map(col => g.drawCell(col, rec, rowi))

    var classList = ['row-' + rowi]
    if (rowi % 2) classList.push('k-alt')
    if (rec._group) {
      classList.push(rec._group.footer ? 'k-group-footer' : 'k-group-header')
      classList.push(rec._group.footer ? 'ow-group-footer' : 'ow-group-header')
    }
    const tr = qc(
      'tr.row.' + classList.join('.'),
      cells.map(x => (typeof x === 'string' ? html(x) : x))
    )
      .attr({ 'data-rowi': '' + rowi })
      .on('keydown', function (e) {
        // '.ow-grid tr td input, .ow-grid tr td a.ow-check, .ow-grid tr td span'
        if (e.which === 38 || e.which === 40) {
          if (!{ input: 1, a: 1, span: 1 }[e.target.tagName.toLowerCase()]) return

          // up/down arrow
          const down = e.which === 40

          var $c = $(e.target).closest('td')
          var $tr = $c.closest('tr')

          let fi = $rec($tr[0])._meta.filterIndex

          var q = 'input, a.ow-check' // , > span';
          var i = $c.find(q).index(e.target)

          if (i !== -1) {
            // if it's a span on readonly row without the controls.
            _v(g, 'state.lastCellControlIndex', i) // set for later
          } else i = _v(g, 'state.lastCellControlIndex') || 0

          let gotoFi = fi,
            gotoRec
          do {
            gotoFi = gotoFi + (down ? 1 : -1)
            if (gotoFi < 0) return false
            gotoRec = g.currentFilter.recs[gotoFi]
          } while (gotoRec && gotoRec._group)

          const goto$tr = gotoRec && g.get$tr(gotoRec)

          if (goto$tr) {
            let $td = $(goto$tr.children()[$c.index()])
            document.activeElement && qc(document.activeElement).trigger('ow-change')
            g.focusCell($td)
          } else {
            if (down && g.opts.editable) {
              qc(e.target).trigger('ow-change')
              if (!g.isNewBlank(g.getDataForRow($tr))) g.addRow()
            }
          }
          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          return false
        }
        // todo: pagedown/up moves focus
      })
      .on('keydown keyup keypress', function (e) {
        if (e.which === 33 || e.which === 34) {
          if (e.type === 'keyup') e.which === 33 ? g.pageUp() : g.pageDown()
          return false
        }
      })
      // .on('ow-swap-row-in', () => tr.find('[data-field]').forEach(ow.ctlType))
      // F2
      .on('keydown', function (e) {
        if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
          if (g.opts.editable === false) {
            tr.trigger('command-' + (g.opts.viewdata.mode === 'select') ? 'select' : 'edit')
            e.preventDefault()
            return false
          }
          if (g.opts.live) {
            // first apply current control editing
            qc(e.target).trigger('ow-change')
            setTimeout(() => g.saveRow($(tr.el)), 50)
            e.preventDefault()
            return false
          }
          // else allow to bubble up
        }
      })
    // .on('keyup', function () { // removed as per issue: 11505
    //   if (e.which === 27) g.cancelChanges($(this))
    // })

    tr.rowi = rowi

    const dummyWin = qc('div.win-con.ow5').props({ $top }).render()

    tr.renderTo(dummyWin)
    var $row = $(tr.el)
    tr.rec = rec
    $row[0].rec = rec
    applyColOptionsToRow($row)
    $row.detach()
    $row.on('focusin', 'td', function () {
      if ($(this).is('td')) g.current($(this))
      else g.current($(this).closest('td'))
    })

    return $row
  }

  g.drawFooter = function () {
    // g.$footer.empty()
    qFooter.kids(gridCols.map(col => g.drawColFooter(col)))
  }

  qGrid.addClass('no-footer')

  g.refreshFooter = function () {
    qc(g.$footer[0]).renderAsync()
    // g.opts.cols.forEach(col => {
    //   if (col.footer === 'sum' || typeof col.footer === 'function') {
    //     var ctl = g.$footer.find('[data-field=footer-coli-' + col.coli + ']')[0]
    //     if (ctl) {
    //       if (col.format) ctl.opts.format = col.format
    //       ctl.val(typeof col.footer === 'function' ? col.footer() : calcSum(col))
    //     }
    //   }
    // })
  }

  const calcSum = col =>
    (g.currentFilter?.recs || [])
      .filter(x => !x._meta.deleted && !x._group)
      .reduce((soFar, rec) => soFar + ((col.calc ? col.calc(rec) : _v(rec, col.field)) ?? 0), 0)

  g.drawColFooter = function (col) {
    if (col.gridCol === false) return ''

    var classes = ''
    var style = ''

    if (col.kCol.footerAttributes?.class)
      classes = classes + ' ' + col.kCol.footerAttributes.class + ' '
    if (col.kCol.footerAttributes?.style) style = style + ';' + col.kCol.footerAttributes.style

    const span = qc('span.' + (col.type || ''))
      .props({ opts: {} })
      .attr({ 'data-field': 'footer-coli-' + col.coli })

    if (col.format) span.opts.format = col.format

    if (col.footer) {
      qGrid.removeClass('no-footer')
      if (col.footer === 'sum') col.footer = () => calcSum(col)
      if (typeof col.footer === 'function') span.bindState(() => span.el?.val(col.footer()))
    }

    const me = qc(
      'td.k-footer.ow-footer.' + classes + '.' + (col.type || '') + '-col.coli-' + col.coli,
      span
    )
      .css(style)
      .css({ paddingLeft: '0 !important', paddingRight: '0 !important' })
      .attr({ 'data-coli': '' + col.coli })
      .props({ coli: col.coli, col })

    col.preRenderFooter?.(me)

    return me
  }

  g.drawColHeader = function (col) {
    if (col.gridCol === false) return ''

    const me = qc('th.ow-header.k-header ' + (col.type + '-col') + '.coli-' + col.coli).props({
      col,
      coli: col.coli
    })

    if (col.kCol.headerAttributes?.class) me.addClass(col.kCol.headerAttributes.class)
    if (col.kCol.headerAttributes?.style) me.css(col.kCol.headerAttributes.style)

    const titleSpan = qc('span').bindState(
      () => col.title,
      title => titleSpan.kids(title)
    )

    const sortIcon = qc('i.icon')
      .css({
        marginLeft: '3px',
        fontSize: '1rem',
        color: '#999',
        verticalAlign: 'text-top'
      })
      .bindState(
        () => g.sorts.indexOf(sort => sort[2] === col.coli),
        (sortOrder, me) => me.css({ color: ['#000', '#666', '#999'][sortOrder] })
      )
      .bindState(
        () => g.sorts.find(sort => sort[2] === col.coli)?.[1],
        (sortDir, me) => me.kids(html(iconCodes[['angle-down', 'angle-up'][sortDir]] ?? ''))
      )

    me.kids([
      qc(
        'span',
        g.opts.sortable
          ? [
              qc('a.col-sort', titleSpan)
                .attr({ href: '#', 'data-coli': col.coli, tabindex: '-1' })
                .on('click', () => {
                  const addSort = (sorts = [], field, coli) => {
                    if (sorts[2]?.[2] === col.coli) sorts.pop()
                    if (sorts[1]?.[2] === col.coli) sorts.splice(1, 1)
                    if (sorts[0]?.[2] === col.coli)
                      sorts[0][1] === 0 ? sorts.splice(0, 1) : (sorts[0][1] = 0) // reverse
                    else sorts.unshift([field, 1, coli])
                    if (sorts.length > 3) sorts.pop()

                    return sorts
                  }

                  g.sorts = addSort((g.sorts ??= []), col.sortOnField ?? col.field, col.coli)

                  g.refilter()
                }),
              sortIcon
            ]
          : titleSpan
      ),
      ...(col.checkAllNone
        ? [
            qc(
              'a.check-all-none.on',
              qc('span.tri-bl').on('click', e => {
                const value = $(e.target).hasClass('all-on')

                if (value) $(e.target).removeClass('all-on')
                else $(e.target).addClass('all-on')

                const f = col.checkAllNoneField || col.field
                g.myWin().progress()
                setTimeout(() => {
                  g.currentFilter.recs.forEach(rec => {
                    _v(rec, f, !value)
                    g.get$tr(rec)
                      .find('.ow-check[data-field="' + f + '"]')
                      .forEach(el => el.val(!value))
                  })
                  g.myWin().progress(false)
                }, 1)
              })
            ).attr({ tabindex: '-1' })
          ]
        : []),
      qc('div.resize-col-handle')
        .attr({ draggable: 'false' })
        .on('mousedown', e => qGrid.resizeColOnMouseDown(e, me.el))
    ])
      .attr({ scope: 'col', role: 'columnheader', 'data-coli': col.coli })
      .css({ paddingLeft: '0', paddingRight: '0' })

    col.preRenderHeader?.(me)

    return me
  }

  g.drawColFilter = function (col) {
    if (col.gridCol === false) return ''
    const me = qc('th.ow-filter-col.' + col.type + '-col.coli-' + col.coli)
      .props({ col, coli: col.coli })
      .attr({ 'data-coli': col.coli })
      .css({ paddingBottom: '3px' })

    if (col.filterType === 'na') return me

    const dsName = g.opts.dc.opts.dsName ?? g.id
    const filterField = col.filterField || col.field

    const cWrap5 = qEl => qc('div.ow-ctl-wrap.ow-textbox', qEl)

    const filterDataTypes = {
      int: 'int',
      integer: 'int',
      decimal: 'float',
      float: 'float',
      currency: 'float',
      number: 'float',
      date: 'date',
      datetime: 'datetime',
      time: 'time',
      boolean: 'booleanFilter',
      bit: 'booleanFilter'
    }

    let edType =
      filterDataTypes[col.filterType] ??
      filterDataTypes[col.dataType] ??
      filterDataTypes[col.ctl?.dataType] ??
      filterDataTypes[col.type] ??
      filterDataTypes[col.ctl?.type] ??
      'text'

    col.field
      ? cWrap5(
          (me.filterControl = qc('input.ow-filter-control.' + (col.filterType || col.type || ''))
            .attr({
              'data-filter-for': dsName,
              'data-field': filterField,
              'data-ctl-type': edType
            })
            .props({
              opts: {
                isFilterControl: true,
                field: filterField,
                dsName,
                clientFilter: col.clientFilter || false,
                ...(col.op ? { op: col.op } : {})
              }
            })
            .css({ borderRadius: '0.31em' })
            .on('init', (e, el) => ctlType(el)))
        ).css({ borderWidth: '1px', borderStyle: 'solid' })
      : []

    me.kids(qc('span', me.filterControl))
      .css({ 'padding-left': '0', 'padding-right': '0' })
      .attr({ 'data-coli': col.coli })
      .on('click', function (e) {
        if (!e.target.classList.contains('ow-ctl-wrap')) {
          $find('.cmenu7')[0]?.remove()
          return
        }
        var col = g.getColForCell($(e.target).closest('th'))

        const openMenu = cmenu7(e.target, {
          view: opts.view,
          point: { left: e.pageX, top: e.pageY },
          setWidth: false,
          content() {
            var ftypes = filterOperators[col.filterType || col.type || 'string']
            return qc('ul', [
              ...Object.keys(ftypes).map(op =>
                qc(
                  'li',
                  qc('a.menu-item', ftypes[op])
                    .attr({ href: '#', 'data-op': op })
                    .on('click', () => {
                      me.filterControl.attr({ 'data-filter-operator': op })
                      me.filterControl.el.opts.op = op
                      me.filterControl.el.focus()
                      openMenu.close()
                    })
                )
              ),
              qc(
                'li',
                qc('a.menu-item.clear-filter', __('Clear filter'))
                  .attr({ href: '#' })
                  .on('click', () => {
                    me.filterControl.el.opts.op = col.op
                    me.filterControl.attr({ 'data-filter-operator': col.op })
                    me.filterControl.el.focus()
                    me.filterControl.el.val(null)
                    me.filterControl.trigger('change')
                    openMenu.close()
                  })
              )
            ])
          }
        })
      })

    me.on('change', () => g.refilter())

    col.preRenderFilter?.(me)

    return me
  }

  g.select = function ($row) {
    if (opts.selectable !== false) {
      if ($row) $row.addClass('k-state-selected')
      return $(allRowElements.filter(tr => tr?.classList.contains('k-state-selected'))[0])
    }
    // row--1 means row: -1 -> grouping row
    if (!$row || $row.hasClass('row--1')) return g.currentRow()
    g.current($row.find('td:first'))
    return $row
  }

  g.leaveRow = function leaveRow($tr) {
    if (!$tr.length) return

    var el = document.activeElement
    if ($tr.is($(el).closest('tr'))) {
      // if focus is still in previous row, clean up.
      el && qc(el).trigger('ow-change')
      if (document.activeElement.dropdownOpen) document.activeElement.$dropdown.close()
    }
    var data = g.getDataForRow($tr)

    if (!data) return // if it has reloaded or something

    if (g.recs.indexOf(data) === -1) return

    if (data._group) return // don't do saving on group headers/footers

    if (g.isNewBlank(data) && opts.editable && opts.tabOutNewRow !== false) {
      if (data._meta.deleted) return
      g.cancelChanges($tr)
      // ow.devLog('// todo: look at grid5 change events')
      qGrid.trigger('ow-grid-change', data, $tr)
      if (opts.groupBy) g.refilter()
    } else {
      // allow changes to propagate before validating and saving etc.
      setTimeout(() => {
        if (g.getDataForRow($tr)) {
          if (opts.live) g.saveRow($tr)
          else g.validateRow($tr)
          if (opts.groupBy) g.refilter()
        }
      }, 500)
    }
  }

  g.currentRow = function () {
    if (!g.current()) return null
    return g.current().parent()
  }

  g.current = function ($cell) {
    if (!$cell) return g.$currentCell

    // ow.devLog('grid.current is not a table cell!  Invalid.')
    if (!$cell.is('td')) return g.$currentCell

    // No group header or footers
    if ($cell.parent().is('tr.row--1')) return g.$currentCell

    let leavingRow
    if (g.$currentCell[0] && g.$currentCell[0].parentElement !== $cell[0]?.parentElement) {
      leavingRow = g.$currentCell[0]?.parentElement
      if (leavingRow) g.leaveRow($(leavingRow))
    }

    g.$currentCell.parent().removeClass('ow-current-row')
    g.$currentCell.removeClass('ow-current-cell')
    g.$currentCell = $cell
    g.$currentCell.addClass('ow-current-cell')
    g.$currentCell.parent().addClass('ow-current-row')

    if (leavingRow) opts.view.qTop.renderAsync()

    return $cell
  }

  if (opts.indicateCurrentRow !== false) qGrid.addClass('indicate-current-row')
  if (opts.indicateCurrentCell === true) qGrid.addClass('indicate-current-cell')

  hasFilterControls(g)

  // if no $tr suppplied, it will cancel all changes
  g.cancelChanges = function ($tr) {
    if ($tr) {
      // todo: what about set of tr?
      var rec = g.getDataForRow($tr)
      if (rec._meta.new) {
        g.deleteRow($tr)
        g.refilter()
        return
      }
      var irec = g.recs.indexOf(rec)
      var rowi = rec._meta.rowi
      new dataItemController({
        $el: $tr,
        rec,
        reactFields: opts.reactFields
      }).cancelChanges()
      g.rowiMap[rowi]._meta.rowi = rowi
      g.recs[irec] = g.rowiMap[rowi]

      g.rowReact($tr)
      qGrid.trigger('ow-grid-change', rec, $tr, 'cancel')
    } else {
      Object.keys(g.dc._changes).forEach(srowi => {
        const rowi = parseInt(srowi)
        const $tr = g.get$tr(rowi)
        g.cancelChanges($tr)
      })
      g.dc.cancelChanges()
    }
    g.refilter()
  }

  g.saveRow = async function ($tr) {
    var dc = opts.dc || g.myWin()[0]

    var rowi = parseInt($tr.attr('data-rowi'))
    var rec = g.getDataForRow($tr)

    if (!g.validateRow($tr).resVal) return

    var req = {
      url: dc.opts.baseURL.split('?')[0],
      data: { data: [rec] }
    }

    const success = response => {
      ow0.popSaveOK(response)
      qc($tr[0]).trigger('ow-data-saved', response)

      if (rec._meta.deleted) g.removeRowFromRecs($tr)

      // todo: if we have values or new ID, apply it.
      // If it was deleted, remove from the list.
      delete rec.isnew
      delete rec._meta
      g.initMeta(g.rowiMap[rowi], rowi)
      $tr.removeClass('ow-dirty')
      $tr.find('.ow-dirty').removeClass('ow-dirty')
      g.updateRowChangeTracking(g.rowiMap[rowi])
      g.refreshRow($tr)
      qc($tr[0]).renderAsync()
      g.swapPage()

      return true
    }

    const fail = err => {
      ow0.popSaveError(err)
      return false
    }

    if (rec) {
      if (rec._meta.new && rec._meta.deleted) return true

      if (rec._meta.deleted) {
        req.type = 'DELETE'
        req.url = req.url + '/delete'
        return $ajax(req).then(success).catch(fail)
      }

      if (rec._meta.new) {
        req.type = 'POST'
        return $ajax(req).then(success).catch(fail)
      }

      if (Object.keys(rec._meta.changes).length) {
        req.type = 'PUT'
        req.url = req.url + '/update'
        return $ajax(req).then(success).catch(fail)
      }
    }

    return false
  }

  g.saveRows = function () {
    var dc = opts.dc || g.myWin()[0]
    var data = {}
    g.readData(data, true)

    return $ajax({
      type: 'PUT',
      url: dc.opts.baseURL.split('?')[0] + '/update',
      data
    })
      .then(response => {
        ow0.popSaveOK(response)
        if (opts.batch && opts.closeAfterSave) return g.myWin()?.closeForm?.(true)
        g.refresh(true)
      })
      .catch(ow0.popSaveError)
  }

  g.saveChanges = function () {
    if (opts.batch) {
      if (g.validate().resVal) g.saveRows()
      return
    }

    var changedRows = Object.keys(g.dc._changes).map(srowi => g.get$tr(parseInt(srowi)))

    changedRows.forEach($tr => g.saveRow($tr))
  }

  const debouncedFooterRefresh = debounce(() => g.refreshFooter(), 100)

  g.undeleteRow = function ($tr) {
    if (!opts.showDeletedRows) return // this should never happen.

    $tr.removeClass('ow-deleted-row')
    var rec = g.getDataForRow($tr)

    delete rec._meta.deleted
    delete rec.Deleted
    g.rowReact($tr)

    debouncedFooterRefresh()

    qc(g).trigger('ow-grid-change', rec, $tr, 'undelete')
  }

  // forget means that this rec changes are removed from changeTracking.
  g.removeRowFromRecs = function ($tr) {
    $tr.find('.ow-current-cell').removeClass('ow-current-cell')
    g.$currentCell = $(g).find('.ow-current-cell')

    var $prevRow = $tr.prev('tr.row')
    var $nextRow = $tr.next('tr.row')
    var rowi = parseInt($tr.attr('data-rowi'))
    var rec = g.getDataForRow($tr)
    var hadFocus = $tr.find(':focus').length
    var scrollTop = g.$content.scrollTop()

    var fi = g.currentFilter.recs.indexOf(rec)
    if (fi === -1) console.error('Record not found in currentFilter')

    g.currentFilter.inFilter[rowi] = false
    $tr.detach()
    g.currentFilter.recs.splice(fi, 1)
    g.currentFilter.filterMap.splice(fi, 1)

    var iRec = g.recs.indexOf(rec)
    g.recs.splice(iRec, 1)
    g.updateRowChangeTracking(rec)

    if (hadFocus) {
      g.$content.scrollTop(scrollTop)
      g.swapPage()
      setTimeout(() => {
        if ($nextRow.length) g.focusCell($nextRow.find('td'))
        else if ($prevRow.length) g.focusCell($prevRow.find('td'))
      }, 200)
    }
  }

  g.deleteRow = function ($tr) {
    var rec = g.getDataForRow($tr)
    rec.Deleted = true
    rec._meta.deleted = true
    if (rec._meta.new) {
      rec._meta.changes = {}
      if (g.dc._changes[rec._meta.rowi]) {
        delete g.dc._changes[rec._meta.rowi]
        g.dc._numChanges--
      }
    }
    $tr.addClass('ow-deleted-row')

    if (!opts.showDeletedRows || rec._meta.new) g.removeRowFromRecs($tr)
    else g.rowReact($tr)

    if (opts.live) g.saveRow($tr).then(result => result === false && g.undeleteRow($tr))

    if (opts.groupBy) g.refilter()

    debouncedFooterRefresh()
    qc(g).trigger('ow-grid-change', rec, $tr, 'delete')
  }

  $(g).on('row-delete', function (e, rowIndex, rec, $tr) {
    if (rec._meta?.deleted) {
      g.undeleteRow($tr)
      return
    }

    if (!g.opts.live && opts.showDeletedRows) g.deleteRow($tr)
    else if (g.isNewBlank(rec)) g.deleteRow($tr)
    else
      ow0.confirm(
        __('Delete'),
        __('Are you sure you want to delete this row?'),
        r => r && g.deleteRow($tr)
      )
  })

  g.pageUp = function () {
    var h = Math.floor(g.$content.innerHeight() / g.rowHeight) * g.rowHeight
    g.$content.scrollTop(g.$content.scrollTop() - h)
  }

  g.pageDown = function () {
    var h = Math.floor(g.$content.innerHeight() / g.rowHeight) * g.rowHeight
    g.$content.scrollTop(g.$content.scrollTop() + h)
  }

  g.getColForCell = $td => qc($td[0]).col

  g.getDataForRow = $tr => $rec($($tr)[0])

  g.get$tr = (recOrRowi, create = true) => {
    if (typeof recOrRowi === 'object') {
      if (recOrRowi._meta.rowi !== -1) return g.get$tr(recOrRowi._meta.rowi, create)
      // group recs have rowi -1
      return g.drawRow(recOrRowi, true, -1)
    }
    const rowi = recOrRowi
    let tr = allRowElements[rowi]
    if (!tr) {
      if (create === false) return
      tr = g.drawRow(g.rowiMap[rowi], true, rowi)[0]
      allRowElements[rowi] = tr
    }
    return $(tr)
  }

  g.build = function () {
    qGrid.addClass('ow-grid')

    g.sorts = opts.sorts || []
    g.$currentCell = $(g).find('.ow-current-cell')

    // button column
    const buttonCol = {
      command: [],
      title: '',
      attributes: { class: 'command-cell no-tab' },
      width: 24,
      template() {
        return this.command.map(cmd => {
          return qc('a.ow-btn.ow-grid-btn.ow-grid-btn-' + cmd.name, qc('i.fa', html(cmd.text)))
            .attr({
              role: 'button',
              'data-command': cmd.type,
              href: '#',
              tabindex: '-1'
            })
            .on('click', function () {
              const tr = this.closest('tr')
              const { rec } = qc(tr)
              qc(tr).trigger('row-' + cmd.type, rec._meta?.filterIndex, rec, $(tr))
            })
        })
      }
    }

    const { buttonColumn = [] } = opts

    buttonColumn.forEach(btn => {
      btn = typeof btn === 'string' ? { type: btn, imageClass: btn } : btn
      btn.text = btn.text || iconCodes[btn.type] || ''
      btn.name = btn.name || btn.type + '-row'
      btn.imageClass = btn.imageClass || btn.name
      btn.iconClass = btn.iconClass || ''
      buttonCol.command.push(btn)
      buttonCol.width = buttonCol.width + (btn.width || 14)
    })

    if (buttonCol.command.length) opts.cols.push(buttonCol)
    // end of button column

    opts.cols.forEach((col, coli) => {
      col.coli = coli

      if (col.combo) col.template = col.template || ow5.colTemplates.combo

      if (col.type === 'date') col.format = col.format || dates.DateFormatSetting

      if (col.type === 'integer') col.type = 'int'
      if (col.type === 'decimal') col.type = 'float'

      col.ctl = col.ctl || {}
      if (col.format) col.ctl.format = col.ctl.format || col.format

      if (col.gridCol !== false && col.readOnly !== true)
        col.template = col.template || ow5.colTemplates[col.type] || ow5.colTemplates.text

      col.width = _v(g.userSettings, 'cols.' + coli + '.width') || col.width
      col.hidden = _v(g.userSettings, 'cols.' + coli + '.hidden') || 0
    })

    colsToGridColumns(opts.cols, g, {})

    let gridCols = opts.cols.filter(c => c.gridCol !== false)

    let totalColWidth = 0
    const calcColWidth = () => {
      totalColWidth = 0
      return gridCols.forEach(col => (totalColWidth += col.hidden ? 0 : col.width || 0))
    }
    // set up reaction fields
    opts.reactFields = opts.reactFields || {}
    opts.cols.forEach(col => {
      var noTrack =
        col.trackChanges !== true &&
        (col.trackChanges === false || col.calc || col.readOnly === true || col.uid) // remember readOnly can be a function

      if (col.field) if (!(col.field in opts.reactFields)) opts.reactFields[col.field] = !noTrack
    })

    g.swapPageSize = opts.swapPageSize || 25
    g.page = 0

    const qTitleRow = qc('tr.ow-titlerow', qc(gridCols.map(col => g.drawColHeader(col))))
    const qFilterRow = qc(
      'tr.ow-filterrow',
      opts.hasColFilters !== false ? qc(gridCols.map(col => g.drawColFilter(col))) : []
    ) // f2 on grid selects/edits the current row
      .on('keydown', e => {
        if (!e.target.classList.contains('ow-filter-control')) return

        // F2 or enter
        if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
          setTimeout(
            e.target.opts?.clientFilter
              ? () => (g.refilter ? g.refilter() : g.refresh())
              : () => (g.dc?.load ? g.dc.load() : g.refresh()),
            10
          )
          return false
        }
      })
      .on('change ow-select', e => {
        if (!e.target.classList.contains('ow-filter-control')) return
        setTimeout(
          e.target.opts?.clientFilter
            ? () => (g.refilter ? g.refilter() : g.refresh())
            : () => (g.dc?.load ? g.dc.load() : g.refresh()),
          10
        )
      })

    qHeader = qc('div.k-grid-header', [
      styles('#' + g.id),
      qc('span.ow-grid-top-right', qc('i.fa', html(''))).on('click', () => {
        qGrid.toggleClass('ow-hide-filterrow')
        opts.userSettings.filterToggle = !qGrid.hasClass('ow-hide-filterrow')
        g.resizeGrid()
      }),
      qc('div.k-grid-header-wrap.k-auto-scrollable').attr({ 'data-role': 'resizable' }),
      qc('table', qc('thead', [qTitleRow, qFilterRow]))
    ])

    qAddRowOnFocus = qc('input.add-row-on-focus').on('focus', () => {
      if (!opts.tabOutNewRow) return
      var $tr = g.$currentCell.parent()
      if (g.isNewBlank(g.getDataForRow($tr))) {
        // this should be cancelled but check later in case
        setTimeout(() => $tr.closest('table') && g.leaveRow($tr), 50)
        return
      }
      g.addRow()
    })

    qGrid.kids([
      qHeader,
      // body
      (qBody = qc('div.k-grid-content.k-auto-scrollable', [
        qc('div.k-grid-content-wrap.k-auto-scrollable', [
          qc(
            'table',
            qc('tbody', [
              (qPlaceHolder = qc(
                'tr.page-holder',
                gridCols.map(col =>
                  qc('td.coli-' + col.coli, html('&nbsp;'))
                    .attr({ 'data-coli': col.coli })
                    .props({ col, coli: col.coli })
                )
              ).css({
                visibility: 'hidden',
                height: '0px'
              })),
              (qPlaceHolderTail = qc(
                'tr.tail.page-holder',
                gridCols.map(col =>
                  qc('td.coli-' + col.coli, html('&nbsp;'))
                    .attr({ 'data-coli': col.coli })
                    .props({ col, coli: col.coli })
                )
              ).css({
                visibility: 'hidden',
                height: '0px'
              }))
            ])
          ),
          qAddRowOnFocus
        ])
      ])).css({ backgroundColor: '#fff' }),

      // footer
      (qFooter = qc(
        'div.k-grid-footer.ow-grid-footer',
        qc(
          'div.ow-grid-footer-wrap',
          qc(
            'table',
            qc(
              'tbody',
              qc(
                'tr.k-footer-template.ow-footer-template',
                gridCols.map(col => g.drawColFooter(col))
              )
            )
          )
        )
      )
        .attr({ tabindex: '-1' })
        .css({ paddingRight: '23px;' }))
    ])

    // paging - NOT USED NOT TESTED but left here for future.
    if (opts.paging) {
      g.pager = gridPager(g, 1, 50, 17, 50 * 17 + 5)
      g.pager.renderTo(g)
    }

    qGrid
      .bindState(
        () => {
          calcColWidth()
          return totalColWidth + 'px'
        },
        width => $(g).find('table').css({ width })
      )
      .bindState(
        () => qGrid.el?.parentElement?.clientHeight,
        () => g.resizeGrid()
      )

    g.$content = $(qBody.el)
    g.$header = $(qHeader.el)
    g.$footer = $(qFooter.el)

    qGrid.renderAsync()

    if (opts.allowColumnMenu) g.initColumnMenu(opts.allowSaveTemplate)

    var prevScrollLeft = g.$content.scrollLeft()
    g.$content.on('scroll', function () {
      qBody.trigger('ow-grid-scroll') // because jQuery scroll event doesn't bubble up
      if (prevScrollLeft === g.$content.scrollLeft()) return
      prevScrollLeft = g.$content.scrollLeft()
      g.$header.css({ 'margin-left': '-' + g.$content.scrollLeft() + 'px' })
      g.$footer.css({ 'margin-left': '-' + g.$content.scrollLeft() + 'px' })
    })
    commandsOnGrid(g)
  }

  g.onRowChangeDefault = function ($tr, rec, f) {
    g.refreshField(rec, f)
    qGrid.trigger('ow-grid-change', rec, $tr, 'field', f)

    var $td = $tr.find('[data-field="' + f + '"]').closest('td')
    var v = _v(rec, f)

    qc($tr[0]).trigger?.('ow-field-changed', f, v, rec, $td)
    setTimeout(() => g.rowReact($tr), 1)
  }

  // This is for overriding.
  g.onRowChange = ($tr, rec, f) => g.onRowChangeDefault($tr, rec, f)

  // updates the _meta with any changes, or just for field if provided
  g.rowReact = function ($tr) {
    if (!$tr.length) return
    var rec = g.getDataForRow($tr)
    new dataItemController({
      $el: $tr,
      rec,
      reactFields: opts.reactFields
    }).recordReact((rec, f) => g.onRowChange($tr, rec, f))
    g.updateRowChangeTracking(rec) // do this incase of delete
  }

  // rec is optional
  g.fieldReact = function ($tr, f, rec) {
    rec = rec || g.getDataForRow($tr)
    var result = new dataItemController({
      $el: $tr,
      rec,
      reactFields: opts.reactFields
    }).fieldReact(f, (rec, f) => g.onRowChange($tr, rec, f))
    g.updateRowChangeTracking(rec)

    return result
  }

  g.updateRowChangeTracking = function (rec) {
    if (rec._meta.updateRowChangeTimeout) return

    rec._meta.updateRowChangeTimeout = setTimeout(() => {
      delete rec._meta.updateRowChangeTimeout

      var rowi = rec._meta.rowi

      var rowHasChanges = Object.keys(rec._meta.changes).length > 0
      rowHasChanges = rowHasChanges || (rec._meta.deleted && !rec._meta.new)
      rowHasChanges = rowHasChanges || (!rec._meta.deleted && rec._meta.new)

      if (!rowHasChanges) {
        g.get$tr(rec, false)?.removeClass('ow-dirty')
        if (g.dc._changes[rowi]) {
          delete g.dc._changes[rowi]
          g.dc._numChanges--
          qGrid.trigger('change', rec)
        }
      } else {
        g.get$tr(rec, false)?.addClass('ow-dirty')
        if (!g.dc._changes[rowi]) {
          g.dc._changes[rowi] = 1
          g.dc._numChanges++
          qGrid.trigger('change', rec)
        }
      }

      g.dc.saveCancelState()
    }, 100)
  }

  g.focusCell = function ($td) {
    // console.log('focusCell');

    if ($td.length > 1) {
      $td = $td.not('.non-editable-cell').length
        ? $td.not('.non-editable-cell').first()
        : $td.first()
    }

    if ($td.find(':focus').length) return

    var q = 'input, a.ow-check'

    var $x = $td.find(q)
    if ($x.length === 0) {
      var $span = $td.find('span').first()
      $span.attr('tabindex', 1) // will be removed
      $span.each(function (i, span) {
        span.focus()
      }) // focus cell because no control
      $span.attr('tabindex', -1) // removed
    } else {
      var ci = _v(g, 'state.lastCellControlIndex')
      ci = Math.min($x.length - 1, ci || 0)
      // console.log('state.lastCellControlIndex :' + ci);
      $x[ci].focus()
    }
  }

  g.cellFocus = function (row, col, body) {
    var $row = body ? body.find('tr:eq(' + row + ')') : $(g).find('tbody tr:eq(' + row + ')')
    var $cell = $row.find('td:eq(' + col + ')')
    if ($cell.length === 0) return
    g.focusCell($cell)
  }

  g.isNewBlank = function (rec) {
    if (rec === undefined) return false
    return rec._meta.new && Object.keys(rec._meta.changes).length === 0
  }

  // don't worry about the scrolling etc (for doing batch adds etc)
  // After doing this call g.swapPage and set focus...?
  g.quietAddRow = function (rec = {}) {
    // apply defaults if nothing is set already.
    opts.cols.forEach(col => {
      if ('defaultValue' in col && col.field && _v(rec, col.field) === undefined)
        _v(
          rec,
          col.field,
          typeof col.defaultValue === 'function' ? col.defaultValue() : col.defaultValue
        )
      else if (col.uid) {
        common
          .$put({
            url: '/nextUids',
            data: { dbType: col.uid === true ? 'sagapos' : col.uid, count: 1 }
          })
          .then(({ uids }) => (rec[col.field] = uids[0]))
      }
    })
    rec = g.dc.opts.newRec?.(rec) ?? rec

    g.rowiMap.push(rec)
    g.recs.push(rec)
    var rowi = g.rowiMap.length - 1
    g.initMeta(rec, rowi)
    rec._meta.new = true

    rec._meta.filterIndex = g.currentFilter.recs.length
    g.currentFilter.recs.push(rec)

    g.currentFilter.filterMap.push(rowi)
    g.currentFilter.inFilter[rowi] = true

    var $newRow = g.get$tr(rec)

    $newRow.find('[data-field]').ow_populate(rec)
    if (g.opts.resetMetaAfterPopulate) resetMeta(rec)

    g.updateRowChangeTracking(rec)

    return $newRow
  }

  g.addRecords = function (recs = []) {
    recs.map(r => g.quietAddRow(r))
    g.swapPage()
  }

  function newRowAllowed() {
    if (g.opts.editable === false) return false
    if (opts.disallowNewWhenExistingNewInvalid) {
      var res = g.validate()
      if (res.resVal === 0) return false
    }
    if (typeof opts.allowNewRow === 'function') return opts.allowNewRow()
    return opts.allowNewRow
  }

  g.newRowAllowed = newRowAllowed

  g.addRow = function () {
    if (!newRowAllowed()) return

    var atBottom =
      g.$content.scrollTop() + 2 > g.$content[0].scrollHeight - g.$content.innerHeight()

    if (g.currentFilter.recs.length && !atBottom) {
      g.page = g.currentFilter.recs.length - 1
      g.$content.scrollTop(Math.ceil(g.$content[0].scrollHeight - g.$content.innerHeight()))

      g.swapPage(() => g.addRow()) // try again

      return
    }

    var newRec = {}

    var $newRow = g.quietAddRow(newRec)

    $newRow.insertBefore($(qPlaceHolderTail.el))
    $newRow.find('[data-field]').forEach(el => el.val())

    g.refilter()
    setTimeout(() => {
      g.page = g.currentFilter.recs.length - 1
      g.swapPage()
      g.focusCell($newRow.find('td:not(.non-editable-cell)'))
    }, 50)
  }

  $(g).on('row-new', () => {
    g.addRow()
    return false
  })

  g.validateRow = function ($tr, onInvalid) {
    $tr.find('.k-input-errbg').removeClass('k-input-errbg')

    var result = { resVal: 1, uid: [], errMsg: '' }

    var localMessageArray = []

    onInvalid =
      onInvalid ||
      function (title, msg, el) {
        localMessageArray.push(title + ': ' + msg)

        el && $tr[0] !== el && displayValidity(el, false, msg)
        $tr.addClass('ow-row-invalid') // $el.addClass('k-input-errbg')
        setTimeout(() => $tr.removeClass('ow-row-invalid'), 1000)
      }

    var rec = g.getDataForRow($tr)

    // ignore new blank rows
    if (g.isNewBlank(rec)) return result

    opts.cols.forEach(col => {
      if (!result) return false
      if (rec._meta.deleted) return

      if (col.validation?.validateFunction) {
        var rv = col.validation.validateFunction.call(col, rec)
        if (rv && typeof rv === 'string') {
          rv = {
            resVal: 0,
            errMsg: rv,
            uid: [col.coli] //parseInt($tr.attr('data-rowi'))
          }
        }
        if (rv && rv.resVal === 0) {
          result.resVal = 0
          result.errMsg = rv.errMsg
          result.uid = result.uid.concat(rv.uid)
          onInvalid(
            col.title,
            result.errMsg,
            $tr.find('[data-field=' + col.field + ']')[0] || $tr[0]
          )
        }
      }

      const objectValidation = validation => {
        for (var rule in validation) {
          if (rule === 'validateFunction') continue
          var v = validation[rule]
          var displayValue = v // if it's a column name

          v = typeof v === 'function' ? v(rec, col) : v

          if (v === undefined) {
            // if the rule refers to another field by Name
            continue
          }

          var val = _v(rec, col.field)

          if (typeof v === 'string') {
            displayValue = (opts.cols.filter(c => c.field === v)[0] || {}).title + ', ' + rec[v]
            v = rec[v]
            if (col.type === 'time') {
              if (val && val.toJSON) val = val.toJSON().split(' ')[1]
              if (v && v.toJSON) v = v.toJSON().split(' ')[1]
            }
            if (col.type === 'date') {
              if (val && val.toJSON) val = val.toJSON()
              if (v && v.toJSON) v = v.toJSON()
            }
          }

          var err = ''

          if (!val && val !== 0) {
            if (rule === 'required' && v) err = ' ' + __('requires a value')
          } else {
            // if (typeof val !== 'undefined' && val !== null && val !== '') {
            if (rule === 'eq' && !(val === v)) {
              err = ' ' + __('should be equal to') + ' ' + displayValue
            } else if (rule === 'neq' && !(val !== v)) {
              err = ' ' + __('should not be equal to') + ' ' + displayValue
            } else if (rule === 'gt' && !(val > v)) {
              err = ' ' + __('should be greater than') + ' ' + displayValue
            } else if (rule === 'gte' && !(val >= v)) {
              err = ' ' + __('should be greater than or equal to') + ' ' + displayValue
            } else if (rule === 'lt' && !(val < v)) {
              err = ' ' + __('should be less than') + ' ' + displayValue
            } else if (rule === 'lte' && !(val <= v)) {
              err = ' ' + __('should be less than or equal to') + ' ' + displayValue
            } else if (
              rule === 'url' &&
              !new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i).test(val)
            )
              err = ' ' + __('Invalid URL') + ' ' + displayValue
            else if (rule === 'regEx' && !new RegExp(validation[rule]).test(val))
              err = ' ' + __('Invalid format') + ' ' + validation[rule]
            else if (rule === 'minLength' && ('' + (val ?? ''))?.length < v)
              err = ' ' + __('should have min length of ' + v)
            else if (rule === 'maxLength' && ('' + (val ?? ''))?.length > v)
              err = ' ' + __('should have max length of ' + v)
          }

          if (err !== '') {
            result.resVal = 0
            result.errMsg = err
            result.uid.push(col.coli)
            onInvalid(
              col.title,
              result.errMsg,
              $tr.find('[data-field=' + col.field + ']')[0] || $tr[0]
            )
          }
        }
      }

      if (typeof col.validation === 'object') objectValidation(col.validation)
    })

    if (localMessageArray.length) ow0.popInvalid('Invalid', html(localMessageArray?.join?.('<br>')))

    return result
  }

  g.validate =
    g.validate ||
    function (onInvalid, messageArray) {
      var result = true
      Object.keys(g.dc._changes).forEach(srowi => {
        var rowi = parseInt(srowi)
        var rec = g.rowiMap[rowi]
        var $tr = g.get$tr(rec)
        result = result && g.validateRow($tr, onInvalid, messageArray)
      })
      return result
    }

  g.moveNextCell = function (back) {
    const currCell = g.current()[0]
    if (!currCell) return

    const allRows = qBody.find('td.gridcell').filter(td => !td.classList.contains('no-tab'))
    const currIndex = allRows.indexOf(currCell)

    const goToCell = $(allRows[currIndex + (back ? -1 : 1)])
    if (goToCell.length) g.focusCell(goToCell)
    else qAddRowOnFocus.el.focus()
  }

  // this is called from window win_close for all .k-grid.
  g.hasChanges = g.hasChanges || (() => g.dc._numChanges)

  g.readData = function (rec, changedRowsOnly = opts.saveOnlyChangedRows) {
    const f = opts.fieldName || 'data'

    if (changedRowsOnly) {
      if (g.currentRow()) g.leaveRow(g.currentRow())

      var v = Object.keys(g.dc._changes)
        .filter(srowi => {
          const rowi = parseInt(srowi)
          return (
            g.currentFilter.inFilter[rowi] ||
            (g.rowiMap[rowi]._meta.deleted && !g.rowiMap[rowi]._meta.new)
          ) // either in filteredRows OR has been deleted
        })
        .map(srowi => {
          const rowi = parseInt(srowi)
          var r = JSON.parse(JSON.stringify(g.rowiMap[rowi]))
          // r._rowi = rowi
          delete r._meta
          return r
        })

      _v(rec, f, v)
      return rec
    }

    _v(
      rec,
      f,
      (!opts.saveOnlyFiltered ? g.recs : g.currentFilter.filterRecs || g.currentFilter.recs)
        .filter(r => !r._meta.deleted && !g.isNewBlank(r) && !r._group)
        .map(r => {
          var r1 = Object.assign({}, r)
          delete r1._meta
          return r1
        })
    )
    return rec
  }

  g.getData = function () {
    return g.currentFilter?.filterRecs || g.currentFilter?.recs || []
  }

  g.refilter = function () {
    if (g.myWin().progress) g.myWin().progress()
    // get the grid client filters
    var filters = { filters: [] }
    g.readClientFilterControls(filters)

    if (!g.dc.applyClientFilterSort)
      g.dc.applyClientFilterSort = ow5.collectionController.applyClientFilterSort

    g.currentFilter = g.dc.applyClientFilterSort(
      g.recs,
      filters.filters,
      g.sorts,
      opts.showDeletedRows,
      opts.groupBy,
      opts.showGroupHeaders,
      opts.showGroupFooters
    )

    if (g.myWin().progress) g.myWin().progress(false)

    g.swapPage()

    if (g.focusFirstRowOnPopulate) {
      g.focusFirstRowOnPopulate = false
      setTimeout(() => {
        var $firstTr = g.$content.find('tr').first()
        var $td = $firstTr.find('td.non-editable-cell, td')
        g.focusCell($td)
      }, 10)
    }
  }

  g.refresh = g.refilter // todo - if we have a live grid we also need to manage refreshing from server...?  separate client and server filters?

  g.build()

  g.onEdChange = function ($el) {
    var f = $el.attr('data-field') || _v($el[0], 'opts.fieldName')
    if (!f) return
    if (g.opts.reactFields[f] !== false) g.opts.reactFields[f] = true

    if (g.state.populating) return

    var $td = $el.closest('td')
    if ($td.hasClass('non-editable-cell')) return
    var $tr = $el.closest('tr')
    var rec = g.getDataForRow($tr)

    if (!rec) return // for events that fire on destroyed rows.
    if (rec._group) return

    var v = _v(rec, f)
    var prev = f in rec._meta.prev ? rec._meta.prev[f] : _v(rec._meta.orig, f)

    _v(rec, f, $el[0].val())
    v = _v(rec, f)

    if (v === prev) return // Added 2020-05-10 - correct working but will it cause issues!

    g.fieldReact($tr, f, rec)

    if (Object.keys(rec._meta.changes).length) $td.addClass('ow-dirty')
    else $td.removeClass('ow-dirty')

    var col = g.getColForCell($td)
    if (col?.onEdit) {
      var p = {} // JSON.parse(JSON.stringify(rec._meta.orig));
      _v(p, f, prev)
      col.onEdit(p, rec, $td, f)
    }
  }

  // Can we put some of these on the body and add in '.ow-grid '
  // split these out
  g.$content.on('change ow-change', 'tr td [data-field]', function () {
    return g.onEdChange($(this))
  })

  g.$content
    .on('keydown', 'tr', function (e) {
      if (e.which === 9) {
        var $tr = $(e.target)
        if ($tr.is('tr'))
          qAddRowOnFocus.attr('tabindex', g.isNewBlank(g.getDataForRow($tr)) ? '-1' : '0')
      }
    })
    .on('click', function (e) {
      if (e.target !== this && e.target.parentElement !== this) return
      if ($(e.target).closest('table').length) return

      if ($(e.target).hasClass('k-i-close')) return //for closing weekdays in grid

      var newRowAllowed = g.newRowAllowed()
      var lastRec = g.currentFilter.recs[g.currentFilter.recs.length - 1]

      var focusLast = (lastRec && g.isNewBlank(lastRec)) || !newRowAllowed

      if (focusLast && lastRec) {
        var $tr = g.get$tr(lastRec)
        return g.focusCell($tr.find('td')) // this will be cancelled.
      }
      if (newRowAllowed) return g.addRow()

      // if you reach here, it's because you aren't allowed to addNewRows and grid is empty (can be client-side filters)
    })

  g.$content.on('scroll', () => g.scrollCheck())

  g.opts.viewdata = opts.viewdata || g.myWin().viewdata

  g.isEditable = function () {
    return opts.editable !== false
  }

  g.isTabOutNewRow = function () {
    return opts.tabOutNewRow || opts.tabOutNewRow !== false
  }

  g.rowCount = function () {
    return g.recs.length
  }

  g.destroy = function () {
    $(g).remove()
    $(g).empty()
  }

  g.val([])
}

registerCtl('grid', {
  init() {
    return grid5(this, this.opts)
  }
})

exports.grid = grid5

const cellReadOnly = (col, d) =>
  typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly || false

const columnDataValue = (col, rec) => {
  const f = col.field
  var v = col.calc ? col.calc(rec) : f ? _v(rec, f) : undefined

  if (v && (col.type === 'date' || col.type === 'datetime') && typeof v === 'string') {
    // this happens after JSON.parse() and is still a string
    if (v.indexOf('T') + 1) {
      console.error('Unexpected condition, ISO string ', col.type, 'timezone may be wrong')
      v = new Date(v)
    } else if (v.split(':').length === 3 && v.split('-').length === 3 && v.split(' ').length === 2)
      v = new Date(v)
    else {
      console.error('Unexpected condition, formatted date string ', col.type)
      v = ow0.parseDate(v, col.format)
    }
  }

  return v
}

/**
 * returns the formatted string value for a column.
 * @param {*} col
 * @param {*} d
 * @param {number} i unused
 * @param {*} readOnly
 * @param {boolean} ctlUse - if it's for in a control rather than HTML span content
 * @returns
 */
const columnValue = (col, d, i, readOnly, ctlUse) => {
  let v = columnDataValue(col, d)

  v = v === undefined && v === null && !ctlUse ? '' : col.format ? ow0.toString(v, col.format) : v

  if (readOnly) {
    if (col.ctl?.objectFieldName && d[col.ctl.objectFieldName])
      return d[col.ctl.objectFieldName][col.ctl?.textField || 'Text'] || v

    if (v === null) return ctlUse ? '' : '&nbsp;'
    return (col.readOnlyText ? col.readOnlyText(d) : v) ?? (ctlUse ? '' : '&nbsp;')
  }

  return v
}

const checkBoxTemplate = function (d) {
  return qc(
    'div.ow-ctl-wrap.ow-check-wrap',
    qc('a.ow-check' + (columnDataValue(this, d) ? '.on' : ''), ' ').attr({
      href: '#',
      'data-field': this.field
    })
  )
}

const textTemplate = function (d) {
  const col = this

  var f = col.field
  var readOnly = cellReadOnly(col, d)

  if (readOnly) return '' + (columnValue(col, d, null, readOnly, false) ?? '&nbsp;')
  var v = columnValue(col, d, null, readOnly, true)

  const { ctl = {} } = col
  ctl.value = v

  ctl.ctlType = ctl.ctlType ?? ctl.edType

  let maxlength = ctl.maxLength ?? col.maxLength ?? col.validation?.maxLength
  if (maxlength) maxlength = maxlength + ''

  if (ctl.useQcControls) {
    console.log('using qcControls', ctl)

    ctl.fieldName = ctl.fieldName ?? f
    ctl.model = d
    ctl.inGrid = true
    ctl.maxLength = maxlength

    return qcControls.qCtl5(ctl).wrap().css({ display: 'block' })
  }
  let input = qc('input' + '.col-ctl.' + col.type + '.' + ctl.ctlType).attr({
    value: '' + (v ?? ''),
    'data-field': f,
    maxlength,
    type: 'text'
  })

  if (ctl.ctlType === 'combo') {
    var icon = qc(
      'i.fa.text-item-icon' + (col.ctl.popUp ? '.popup' : '.combo-icon'),
      html(col.ctl.popUp ? col.ctl.iconCode || iconCodes.magnifier : iconCodes.angleDown)
    )
    input = qc('div.ow-ctl-wrap.ow-textbox.ow-combo-wrap.text-icon-after', [input, icon])
  } else input = qc('div.ow-ctl-wrap.ow-textbox', input)

  return input
}

exports.colTemplates = {
  boolean: checkBoxTemplate,
  checkBox: checkBoxTemplate,
  text: textTemplate,
  combo: textTemplate,
  string: textTemplate
}

/**
 * adds the standard grid button command event handlers to a grid
 * commands are refresh, edit, copy, delete, new
 *
 */
const commandsOnGrid = function (g) {
  function triggerCommandOnCurrentRow(cmd) {
    var $tr = g.currentRow()
    var data = $tr[0] && g.getDataForRow($tr) // k.dataItem(g.rowByIndex(g.rowIndex()));
    // call handler
    qc(g).trigger(cmd, $tr ? $tr.index() : -1, data, $tr)
  }

  $(g)
    .on('command-refresh', function () {
      g.refresh()
      return false
    })
    .on('command-edit', function () {
      triggerCommandOnCurrentRow('row-edit')
      return false
    })
    .on('command-select', function () {
      triggerCommandOnCurrentRow('row-select')
      return false
    })
    .on('command-copy', function () {
      triggerCommandOnCurrentRow('row-copy')
      return false
    })
    .on('command-delete', function (e) {
      triggerCommandOnCurrentRow('row-delete')
      e.preventDefault()
      return false
    })
    .on('command-new', function () {
      triggerCommandOnCurrentRow('row-new')
      return false
    })
    .on('command-add-row', function () {
      triggerCommandOnCurrentRow('row-new')
      return false
    })
    .on('command-save', function () {
      triggerCommandOnCurrentRow('row-save')
      return false
    })
    .on('command-cancel', function () {
      triggerCommandOnCurrentRow('row-cancel')
      return false
    })
}

const hasFilterControls = function (g) {
  $(g).on('ow-grid-databound', function () {
    if (g.focusFirstRowOnPopulate) {
      g.focusFirstRowOnPopulate = false
      // g.cellFocus(0,0);
      var $c = $(g).find('tbody tr:first td:first')
      if ($c.length) {
        g.current($c)
        $(g).find('.k-grid-content table')[0].focus()
        g.resolveFocus()
      }
    }
  })

  var fireFilters = function (e) {
    if (g.opts?.focusFirstRowOnPopulate !== false) {
      g.focusFirstRowOnPopulate = true
      var $panel = $(e.target).closest('.filter-panel')
      $panel[0].focus()
      var btn = $panel.find('.filter-button')[0]
      if (btn) btn.focus()
    }
    qc(e.target).trigger('change')
    setTimeout(
      e.target.opts?.clientFilter
        ? () => (g.refilter ? g.refilter() : g.refresh())
        : () => (g.dc && g.dc.load ? g.dc.load() : g.refresh()),
      10
    )
  }

  if (g.opts.hasFilters !== false)
    g.myWin().on('keydown', '.filter-panel', function (e) {
      if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        // F2
        fireFilters(e)
        // e.originalEvent.keyCode = 0;
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    })

  $(g).on('command-filter-change', () => setTimeout(() => g.refresh(), 50))

  if (Array.isArray(g.opts?.dsName))
    g.opts.dsName.forEach(dsName => filterController(g, { dsName }))
  else filterController(g, { dsName: g.opts?.dsName ?? g.id })
}

const styles = (scope = '.ow5 .ow-grid') =>
  html(`<style>
${scope} .k-grid-header .ow-filterrow { display: none; }
${scope}.ow-has-filterrow .k-grid-header .ow-filterrow {display: table-row;}
${scope}.ow-hide-filterrow .k-grid-header .ow-filterrow {display: none;}
${scope} .k-grid-header .ow-grid-top-right i {display: none;}
${scope} .k-grid-header .ow-grid-top-right {position: absolute;right: 0;top: 0;height: 3em;display: inline-block;width: 2em;background-color: inherit;z-index: 2;text-align: right;overflow: visible;}
${scope}.ow-has-filterrow .k-grid-header .ow-grid-top-right i {display: inline-block;font-size: 1.3em;color: #307ebe;overflow: visible;position: absolute;right: 5px;top: 5px;}
${scope} .ow-filterrow .ow-ctl-wrap {
  margin-right: 1.6em;
  margin-left: 0.16em;
  position: relative;
  overflow: visible;
}
${scope} .ow-filterrow .ow-ctl-wrap::after {
  content: "\\f0b0";
  font-family: "FontAwesome";
  line-height: 1.2em;
  font-size: 1em;
  cursor: pointer;
  color: #307ebe;
  position: absolute;
  right: -1.2em;
  top: 0.3em;
  padding: 0.22em 0;
}

${scope} .ow-filterrow,
${scope} .ow-filterrow th { overflow: visible; overflow-x: hidden; }
${scope} tr.colgroup th { line-height: 0; }
${scope} tr.ow-group-header { background-color: #f3f3f4; }

${scope} .ow-grid .row div.ow-ctl-wrap.ow-textbox,
${scope} .ow-grid .row input { background: transparent }

${scope} .ow-grid .ow-filterrow .ow-ctl-wrap.ow-textbox {
  border: #dedee0 1px solid ! important;
}
</style>`)

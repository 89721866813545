const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')

const styles = () =>
  html(`
<style>
.tree-grid5 ul li .column > a { font-size: 10px; line-height: 17px }
.tree-grid5 {
height: 100%;
overflow: auto;
}
.tree-grid5 ul {
list-style: none;
}
.tree-grid5 .row {
overflow-x: visible;
white-space: nowrap;
border-top: 1px white solid;
}
.tree-grid5 .column {
width: 14%;
display: inline-block;
text-align: center;
color: #fff;
}
.tree-grid5 .first-cell {
display: inline-block;
overflow-x: visible;
width: 40%;
box-sizing: border-box;
padding-left: 0.3em;
}
.tree-grid5 span.empty,
.tree-grid5 li .text > .fa {
display: inline-block;
width: 1em;
}
.tree-grid5 li > div {
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden !important;
}
.tree-grid5 li {
margin-top: -3px;
}
.tree-grid5 ul li .first-cell .extra .fa {
display: inline-block;
margin-right: 3px;
}
.tree-grid5 ul li .first-cell .extra {
display: inline-block;
margin-left: calc(15px * var(--spacing-x));
}
.tree-grid5:not(.dotted) ul li .first-cell .extra {
margin-left: calc(15px * var(--spacing-x) - 15px);
}
.tree-grid5 ul li {
position: relative;
}
.tree-grid5.dotted ul li:not(:first-child):last-child .first-cell .extra::before {
opacity: 0;
}
.tree-grid5.dotted ul li:not(:last-child) > .first-cell .extra::before {
opacity: 1 !important;
}
.tree-grid5.dotted ul li:not(.tree-header) .first-cell .extra::before {
content: ' ';
position: absolute;
width: 1px;
top: -2px;
bottom: -3px;
left: -10px;
margin-left: calc(15px * var(--spacing-x));
border: none;
border-left: 1px dotted #000;
color: #fff;
background-color: #fff;
}
.tree-grid5.dotted ul li .first-cell::before {
display: list-item;
content: ' ';
position: absolute;
width: 1px;
top: -2px;
bottom: 7px;
height: 5px;
left: -10px;
margin-left: calc(15px * var(--spacing-x));
border: none;
border-left: 1px dotted #000;
color: #fff;
background-color: #fff;
}
.tree-grid5.dotted ul li:not(.tree-header) .first-cell .extra::after {
content: ' ';
position: absolute;
left: -10px;
width: 10px;
height: 1px;
top: 3px;
margin-left: calc(15px * var(--spacing-x));
border: none;
border-top: 1px dotted #000;
color: #fff;
background-color: #fff;
}
.tree-grid5 .fa.i-expandable::before {
font-family: FontAwesome;
content: '\\f196';
cursor: pointer;
}
.tree-grid5 .fa.i-expandable.expanded::before {
font-family: FontAwesome;
content: '\\f147';
cursor: pointer;
}
.tree-grid5 .tree-preheader {
margin-top: 0px;
}
.tree-grid5 .tree-header input {
margin-top: 3px;
border: solid 0.2px #dedede;
padding: 2px 5px;
border-radius: 3px;
}
.tree-grid5 .summ-cell .summ-cell-span {
float: right;
margin-right: 10px;
border: solid 0.2px #dedede;
padding: 3px;
border-radius: 3px;
}
</style>
`)

const treeGrid5 = (data, opts = {}) => {
  let _total = 0
  let _checked = 0
  let dataList = []
  const nestingKey = opts.nestingField || 'items'
  const groupLevelKey = opts.groupLevelField || 'GroupLevel'
  const groupKey = opts.groupKeyField || 'GroupKey'

  const box = (col, v, rec) => {
    let style = col.style || (col.width ? 'width: ' + col.width + 'px;' : '') || ''
    if (col.gridCol === false) style = 'display: none;'

    return qc(
      'div.column.' + (col.classes || []).map(c => c).join('.'),
      rec && col.template ? col.template(rec) : v !== undefined ? v : '\xa0'
    ).css(style)
  }

  const buildRow = (rec, depth = 0, index, parent) => {
    const stata = (opts.cols || []).map(c => box(c, rec[c.field], rec))
    const parentID =
      parent !== undefined ? parent.toString() + '-' + index.toString() : index.toString()
    const childs = ((rec.expanded && rec[nestingKey]) || []).map((kid, i) =>
      buildRow(kid, depth + 1, i, parentID)
    )
    const hasChild = rec[nestingKey] && rec[nestingKey].length > 0
    const expanded = hasChild && rec.expanded
    const treeTypeIcon = qc(
      'span.fa' + (!rec[opts.valueField] ? '.fa-fw.fa-sitemap' : '.fa-institution')
    )
    const expandableIcon = 'fa i-expandable' + (expanded ? ' expanded' : '')
    let me = [
      qc(
        'li.' + (rec[opts.textField] || '').toLowerCase().replace(/\s/g, '') + '-tab',
        [
          qc('div.first-cell' + (hasChild ? '.expandable' : '') + (expanded ? '.expanded' : ''), [
            [
              qc(
                'span.extra',
                hasChild ? [qc('span.' + expandableIcon), treeTypeIcon] : treeTypeIcon
              )
                .css({ marginLeft: 15 * (depth + 1) + 'px' })
                .on('init', (e, el) => {
                  // el.style[''] = 15 * (depth + 1) + 'px' //implement --spacing-x
                  let kid = el.querySelector('.i-expandable')
                  if (kid) {
                    kid.parent = parent
                    kid.index = index
                  }
                }),
              opts.checkbox
                ? qc(
                    'span.checkbox-select',
                    common.qcControls
                      .qCtl5({
                        ctlType: 'check',
                        model: rec,
                        fieldName: opts.checkboxOpts?.selectedField || 'selected',
                        noLabel: true,
                        value: rec[opts.checkboxOpts?.selectedField || 'selected'] || false
                      })
                      .addClass(rec[opts.checkboxOpts?.selectedField || 'selected'] ? 'on' : '')
                      .wrap() // .val(rec[opts.checkboxOpts?.selectedField || 'selected'] || false)
                  ).attr({ 'data-value': rec[opts.uniqueField] })
                : [],
              qc('span.text', rec[opts.textField]).attr({
                'data-value': rec[opts.uniqueField]
              })
            ]
          ])
        ]
          .concat(stata)
          .concat(expanded ? [qc('ul', childs)] : [])
      ).attr({ title: rec[opts.textField] || '' }) //column
    ]
    return me
  }

  // build header
  const stataHeader = opts.cols?.map(c => box(c, c.name)) ?? []
  let header = qc('li.row.tree-header', [
    qc(
      'div.first-cell',
      qc('input.search-field').attr({
        placeholder: opts.textFieldLabel || opts.textField
      })
    ),
    ...stataHeader
  ])

  let preheader = qc(
    'li.row.tree-preheader',
    qc('div.summ-cell', qc('span.text.summ-cell-span', ''))
  )

  let d = data.reduce((r, x, i) => r.concat(buildRow(x, 0, i)), [])
  d.unshift(header)
  if (opts.checkbox && opts.checkboxOpts && opts.checkboxOpts.showSumm) d.unshift(preheader)

  const accordionHandler = tree => {
    tree.addEventListener('click', function (event) {
      const target = event.target
      const targetRow = target.closest('li')
      if (target.classList.contains('i-expandable')) {
        const isExpanded = target.classList.contains('expanded') && targetRow.querySelector('ul')
        qc(targetRow.querySelector('ul')).css({ display: isExpanded ? 'none' : '' })
        if (!isExpanded)
          //update checkbox based on dataList
          setTimeout(() => {
            for (var el of tree.getElementsByTagName('a')) {
              let ds = el.closest('span').dataset
              let idx = dataList.findIndex(x => x[opts.uniqueField] === ds.value)
              if (idx > -1) el.val(dataList[idx][opts.checkboxOpts?.selectedField])
            }
            updatePartialIcon(tree)
          }, 100)

        target.classList.toggle('expanded')
      } else if (target.tagName === 'A') {
        var ulChild = targetRow.getElementsByTagName('ul')
        var ulParent = targetRow.closest('ul')
        updateChecks(ulParent, ulChild, target)
      } else {
        const handleSelected = () => {
          document
            .querySelectorAll('.tree-grid5 li')
            .forEach(el => el.classList.remove('treerow-selected'))
          targetRow.classList.add('treerow-selected')
        }
        handleSelected()

        let tne = new CustomEvent('treenode-click', {
          bubbles: true,
          cancelable: true,
          detail: { tr: targetRow, data: target.dataset && target.dataset.value }
        })
        tree.dispatchEvent(tne)
      }
    })
  }

  const updateChecks = (ulParent, ulChild, target) => {
    var el = ulChild[0]

    if (el) el.querySelectorAll('a.ow-check').forEach(chk => chk.val(target.val()))
    else {
      var isChecked = true
      if (ulParent.closest('li')) {
        ulParent
          .closest('li')
          .querySelectorAll('ul')[0]
          .querySelectorAll('a')
          .forEach(ele => {
            if (!isChecked) return
            isChecked = ele === target ? target.val() : ele.val()
          })
        ulParent.closest('li').querySelectorAll('.checkbox-select a')[0].val(isChecked)
      }
    }

    let tv = ulParent.closest('.tree-grid5')
    setTimeout(() => {
      tv.querySelectorAll('a').forEach(el => {
        let ds = el.closest('span').dataset
        //update dataList
        let idx = dataList.findIndex(x => x[opts.uniqueField] === ds.value)
        if (idx > -1) dataList[idx][opts.checkboxOpts?.selectedField] = el.val()

        _checked = dataList.filter(
          d => d[opts.checkboxOpts?.selectedField] && d[opts.valueField]
        ).length
      })

      updateSumm(tv)
    }, 100)

    //update partial indicator
    setTimeout(() => updatePartialIcon(tv), 150)
  }

  const readChecks = () =>
    dataList.filter(r => r[opts.valueField] && r[opts.checkboxOpts?.selectedField])

  const updateSumm = tv => {
    if (!opts.checkbox || !opts.checkboxOpts?.showSumm) return
    let target = tv.querySelector('li.tree-preheader .summ-cell .summ-cell-span')
    target.innerText = _checked + ' /' + _total
  }

  const updatePartialIcon = tv => {
    tv.querySelectorAll('span.fa-institution').forEach(el => {
      let ul = el.closest('ul')
      let targetLi = ul.parentNode.querySelectorAll('.first-cell')[0]
      targetLi.querySelectorAll('i').forEach(i => i.remove())
      let hasCheck = Array.from(ul.querySelectorAll('li a.ow-check')).find(chk => chk.val())
      if (hasCheck)
        qc('i.fa.fa-circle-o')
          .css({
            display: 'inline-block',
            paddingLeft: '5px',
            color: '#0075FF'
          })
          .renderTo(targetLi)
    })
  }

  const init = tvEl => {
    tvEl.readChecks = readChecks

    tvEl.filter = () => {
      let fv = (searchField && searchField.value) || ''
      d[2].kids([])
      let data = buildTree(dataList || [], fv)
      let dNew = data.reduce((r, x, i) => r.concat(buildRow(x, 0, i)), [])
      let ctx = qc('ul', dNew)

      let qryPath =
        opts.checkbox && opts.checkboxOpts && opts.checkboxOpts.showSumm
          ? 'ul:nth-child(1) li:nth-child(3)'
          : 'ul:nth-child(1) li:nth-child(2)'
      tvEl.querySelector(qryPath).innerHTML = ''
      ctx.renderTo(tvEl.querySelector(qryPath))

      setTimeout(() => updatePartialIcon(tvEl), 100)
    }

    tvEl.selectByName = (id, triggerEvent) => {
      tvEl.getElementsByTagName('LI')[1].getElementsByClassName('text')[0].outerText
      var liList = tvEl.getElementsByTagName('LI')
      var found = false
      for (let i = 0; i < liList.length; i++) {
        liList[i].classList.remove('treerow-selected')
        if (found) continue
        if (liList[i].classList.contains('tree-header')) continue
        if (liList[i].getElementsByTagName('UL').length) continue
        var target = liList[i].getElementsByClassName('text')[0]
        var txt = target.outerText
        if (txt.toLowerCase().indexOf(id.toLowerCase()) >= 0) {
          liList[i].classList.add('treerow-selected')
          found = true

          if (triggerEvent) {
            let tne = new CustomEvent('treenode-select', {
              bubbles: true,
              cancelable: true,
              detail: { tr: liList[i], data: target.dataset && target.dataset.value }
            })
            tvEl.dispatchEvent(tne)
          }
        }
      }
    }

    let arr = []
    dataList = flattenRecur(arr, data)
    let nodeList = dataList.filter(r => {
      return r[opts.valueField] || 0
    })
    _total = nodeList.length
    _checked = nodeList.filter(r => r[opts.checkboxOpts?.selectedField] || 0).length
    updateSumm(tvEl)
    updatePartialIcon(tvEl)

    let searchField = tvEl.getElementsByClassName('search-field')[0]
    searchField.addEventListener('keypress', e => {
      if (e.key === 'Enter') tvEl.filter()
    })
    searchField.addEventListener('blur', tvEl.filter)
    if (opts.hideFilter) searchField.style.display = 'none'
  }

  const flattenRecur = function (arr, d = []) {
    const dataItem = rec => {
      let di = {}
      opts.cols.forEach(c => (di[c.field] = rec[c.field]))
      di[opts.valueField] = rec[opts.valueField]
      di[opts.textField] = rec[opts.textField]
      di[opts.uniqueField] = rec[opts.uniqueField]
      if (opts.checkboxOpts) {
        di[opts.checkboxOpts.selectedField] = rec[opts.checkboxOpts.selectedField]
      }
      di[groupLevelKey] = rec[groupLevelKey]
      di[groupKey] = rec[groupKey]

      return di
    }

    d.map(r => {
      arr.push(dataItem(r))
      if (r[nestingKey]) return flattenRecur(arr, r[nestingKey])
    })

    return arr
  }

  const buildTree = function (data, fv) {
    var data2 = data.filter(r => r[opts.valueField] && r[opts.textField].indexOf(fv) > -1)
    let combiKeys = []
    data2.map(d2 => {
      let gk = d2[groupKey] || ''
      let grpKeys = gk.split('-')
      let sKey = ''
      grpKeys.map(k => {
        sKey = sKey === '' ? sKey + k : sKey + '-' + k
        if (combiKeys.indexOf(sKey) < 0) combiKeys.push(sKey)
      })
    })
    let fdata = data.filter(r => combiKeys.some(r2 => r2 === r[groupKey]))
    fdata = fdata.sort((a, b) => (a[groupKey] < b[groupKey] ? -1 : 1))

    let p = { p: null }
    p[nestingKey] = []
    var r = p
    fdata.forEach(x => {
      x[nestingKey] = []
      x.expanded = true

      if (x[groupLevelKey] > p[groupLevelKey]) {
        x.p = p
        if (x[opts.valueField] === '' || x[opts.textField].indexOf(fv) > -1) p[nestingKey].push(x)
        p = x
      } else {
        while (x[groupLevelKey] <= p[groupLevelKey]) p = p.p
        x.p = p
        if (x[opts.valueField] === '' || x[opts.textField].indexOf(fv) > -1) p[nestingKey].push(x)
        p = x
      }
    })
    const removeP = x => {
      delete x.p
      x[nestingKey].forEach(y => removeP(y))
      if (x[nestingKey].length === 0) delete x[nestingKey]
    }
    removeP(r)

    return r[nestingKey] || []
  }

  return qc('div.tree-grid5' + (opts.dotted ? '.dotted' : ''), [
    qc('ul', d).on('init', (e, ul) => accordionHandler(ul)),
    styles()
  ]).on('init', (e, el) => {
    init(el)
  })
}

const qTreeGrid5 = opts => {
  const me = treeGrid5(opts.model, opts)
  me.wrap = () => me
  return me
}

exports.treeGrid5 = treeGrid5
exports.qTreeGrid5 = qTreeGrid5

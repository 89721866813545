const { isDate } = require('../../lib/js-types')
const { longRunning } = require('./ajax-lrtask')

/**
 * $param
 * Accepts an object of filter keywords and values, and converts them into a valid query string which can be appended to a URL.
 *
 * @param {object} obj - Object (possibly nested) containing filter keywords and values.
 * @param {string} prefix - String representing the level in a nested object that is currently being processed. This parameter is set automatically by the function, when it is passed a nested object and the function is in-turn called recursively.
 */
const $param = (obj, prefix = '') => {
  let res = []

  if (!prefix && !obj) throw 'obj param is of invalid type or value.'
  if (typeof prefix !== 'string') prefix = '' // Ensure prefix param is a string.

  if (obj === undefined) res.push([prefix, ''])
  else if (obj === null) res.push([prefix, ''])
  //If value is a date object, return date-formatted query string.
  else if (isDate(obj)) res.push([prefix, obj.toJSON()])
  else if (Array.isArray(obj)) {
    obj.forEach((x, i) => (res = [...res, ...$param(x, prefix + '[' + i + ']')]))
    return res
  } else if (typeof obj === 'object') {
    // If all in the object has an undefined value, omit the entire object from the query string.
    if (prefix !== '' && JSON.stringify(Object.values(obj)) === '[undefined]')
      res.push([prefix, ''])
    else
      Object.entries(obj).forEach(
        ([k, v]) => (res = [...res, ...$param(v, prefix + (prefix ? '[' + k + ']' : k))])
      )
  } else res.push([prefix, obj + ''])

  if (prefix === '')
    return res.map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&')

  return res
}
module.exports.$param = $param

/**
 * Calls ajax - uses fetch() not jquery
 *
 * @param {object|string} opts - if string, performs get with url = opts
 * @param {string} opts.method default=get
 * @param {string} opts.type default=get deprecated use opts.method
 * @param {string} opts.url
 * @param {object} opts.data - Object for body (querystring if get) or can be string
 * @param {string} opts.headers - any extra headers
 * @param {string} opts.dataType default = json
 * @param {boolean} opts.includeTimestamp default = false. Adds data.ts with timestamp to let the server know timezone.
 * @param {function} opts.success (deprecated) called if success
 * @param {function} opts.error (deprecated) called if failed
 *
 * @returns promise of result object
 */
module.exports.$ajax = async opts => {
  if (typeof opts === 'string') opts = { url: opts }

  opts.top = opts.top ?? opts.view?.qTop.el

  let { success, url, dataType = 'json', data = {}, headers = {}, includeTimestamp = false } = opts

  const method = (opts.method || opts.type || 'get').toLowerCase()

  if (method !== 'get' && method !== 'DELETE')
    headers['content-type'] =
      dataType === 'json' ? 'application/json' : dataType === 'text' ? 'text/plain' : dataType
  else if (data) {
    const query = $param(data)
    if (query) url += (url.indexOf('?') + 1 ? '&' : '?') + query
  }

  if (window.csrfToken) headers['x-csrf-token'] = window.csrfToken // not used

  if (includeTimestamp) data.ts = new Date()

  let myFetch = (...args) =>
    window.fetch(...args).then(response => {
      const noContent =
        response.headers.get('Content-Length') === '0' || !response.headers.get('Content-Length')

      const contentType = response.headers.get('Content-Type') ?? 'text'

      const content =
        contentType.indexOf('octet') >= 0 || contentType.indexOf('image') >= 0
          ? noContent
            ? ''
            : response.blob()
          : contentType.indexOf('json') >= 0
            ? noContent
              ? undefined
              : response.json()
            : response.text()

      return response.ok === false
        ? content.then(x => {
            console.error('ajax failed', url, data, response)
            throw contentType.indexOf('json') ? x : { errMsg: x, status: response.status }
          })
        : content
    })

  myFetch = longRunning(opts, myFetch)

  const body =
    method !== 'get' ? (typeof data !== 'string' ? JSON.stringify(data) : data) : undefined

  if (opts.showProgress && opts.view)
    opts.view.progress(true, typeof opts.showProgress === 'string' ? opts.showProgress : undefined)

  return myFetch(url, {
    method,
    body,
    dataType,
    headers,
    credentials: 'same-origin'
  })
    .catch(err => {
      console.error('myFetch ERROR: ', url, body, err)
      if (opts.error) opts.error(err) // deprecated
      throw {
        ok: false,
        result: false,
        success: false,
        errMsg: __('Operation Failed'),
        ...err
      }
    })
    .then(r => (success ? success(r) : r))
    .finally(() => {
      if (opts.showProgress && opts.view) opts.view.progress(false)
    })
}

module.exports.$put = opts =>
  typeof opts === 'string'
    ? module.exports.$put({ url: opts })
    : module.exports.$ajax({ type: 'PUT', ...opts })

module.exports.$post = opts =>
  typeof opts === 'string'
    ? module.exports.$post({ url: opts })
    : module.exports.$ajax({ type: 'POST', ...opts })

module.exports.$delete = opts =>
  typeof opts === 'string'
    ? module.exports.$delete({ url: opts })
    : module.exports.$ajax({ type: 'DELETE', ...opts })

const { $put } = require('../ajax')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { _v } = require('../ow0/core')
const { applyFilter } = require('../ow7/filters')
const { popInvalid, popError } = require('../pop-box')
const { displayValidity } = require('./ctl5')

const toJSONNull = function () {
  return null
}

// copied from ow4.controls.validateBasicEds
const validateEditors = function ($top, dsName, messageArray, onInvalid) {
  messageArray = messageArray || []

  var result = true

  const defaultInvalid = (display, err, el) => {
    result = false
    console.log(display + ' invalid: ' + err)
    messageArray.push(display + ' invalid: ' + err)
    displayValidity(el, false, err)
  }

  onInvalid = onInvalid || defaultInvalid

  var passwordChecklist = []
  var gridFieldsChecklist = [] // for multiple grids

  $top.find("[data-field-for='" + dsName + "']").ow_validate(onInvalid, messageArray)

  //process password
  const pwdGroups = passwordChecklist.reduce((obj, item) => {
    obj[item.group] = obj[item.group] || []
    obj[item.group].push({ value: item.value, target: item.target })
    return obj
  }, {})

  for (var rec in pwdGroups) {
    if (pwdGroups[rec] !== undefined) {
      if (Array.isArray(pwdGroups[rec])) {
        var pwdCompare = null
        var matched = true
        pwdGroups[rec].forEach(l => {
          if (pwdCompare === null) pwdCompare = l.value
          else if (l.value !== pwdCompare) matched = false
        })

        if (!matched) {
          pwdGroups[rec].forEach(item => {
            var el = $(item.target)[0]
            if (el.makeMeInvalid) el.makeMeInvalid(onInvalid, messageArray)
            displayValidity(el, false, __('Password and Confirm Password mismatch'))
          })
          result = false
        }
      }
    }
  }

  //process grids validation
  gridFieldsChecklist.map(f => {
    if (!f.resVal) {
      ;(f.resErr || []).forEach(err => messageArray.push(err))
      result = false
    }
  })

  if (messageArray?.length) {
    popInvalid(html(messageArray.join('<br>')))
    var elError = $top.find('.resource_set.k-input-errbg input')[0]
    elError?.focus()
  }
  return result
}

const dataItemController = function (opts) {
  var o = this
  o.opts = opts || {}
  o.opts.reactFields = o.opts.reactFields || {}
  o.rec = o.opts.rec
}
exports.dataItemController = dataItemController

Object.assign(dataItemController.prototype, {
  /**
   *
   * @param {object} rec
   * @param {string} f - nested fieldname eg. "Product.Name"
   * @param {function} callIfChanged - is called if the field has changed before the _meta.prev is updated with the new value.
   */
  fieldReact(f, callIfChanged) {
    var rec = this.rec

    if (rec._meta === undefined) return

    var vNew = _v(rec, f)

    var $grid = this.opts.$top ? this.opts.$top.find('.ow-grid[data-field="' + f + '"]') : {}
    if ($grid.length && Array.isArray(vNew)) {
      // grid
      var g = $grid[0]
      if (!g.dc.hasChanges()) delete rec._meta.changes[f]
      else rec._meta.changes[f] = g.dc._changes

      // always call because this doesn't arrive unless it has
      if (callIfChanged) callIfChanged(rec, f)
      else if (this.opts.callIfChanged) this.opts.callIfChanged(rec, f)
    } else {
      var vOrig = _v(rec._meta.orig, f)
      var vPrev = f in rec._meta.prev ? rec._meta.prev[f] : vOrig // changes are stores as flattened values

      var compareVal = function (v) {
        if (Array.isArray(v) || typeof v === 'object') {
          var sVal = JSON.stringify(v)
          if (sVal.length > 5000 && ow0.dev)
            console.warn(
              'fieldReact large value being used for comparison, do we need to change it? ' + f
            )

          return sVal
        }
        return v
      }

      var prevCompareVal = compareVal(vPrev)
      var newCompareVal = compareVal(vNew)
      if (prevCompareVal === newCompareVal) return false

      var origCompareVal = compareVal(vOrig)
      if (origCompareVal === newCompareVal || this.opts.reactFields[f] === false) {
        delete rec._meta.changes[f]
      } else {
        rec._meta.changes[f] = vNew
      }

      if (callIfChanged) callIfChanged(rec, f)
      else if (this.opts.callIfChanged) this.opts.callIfChanged(rec, f)

      rec._meta.prev[f] = vNew
    }

    return true
  },

  recordReact(callIfChanged) {
    var o = this
    Object.keys(o.opts.reactFields).forEach(function (f) {
      o.fieldReact(f, callIfChanged)
    })
  },

  cancelChanges() {
    // Because we don't know what might be pointing at this object (It could be in an array of records),
    // we need to leave it the same instance and restore the original values.

    var rec = this.rec
    var meta = rec._meta

    Object.keys(this.rec).forEach(function (k) {
      // remove all the values
      if (k !== '_meta') delete rec[k]
    })
    Object.keys(meta.orig).forEach(function (k) {
      // restore all the orig values
      if (k !== '_meta') rec[k] = meta.orig[k]
    })
    this.initMeta(meta.rowi)
    this.rec._meta.prev = meta.prev

    return this.rec
  },

  initMeta(i) {
    var r = this.rec
    this.rec._meta = {
      orig: JSON.parse(JSON.stringify(r)),
      prev: {}, // JSON.parse(JSON.stringify(r)), // todo: nested fields!!!! and reactFields
      changes: {},
      // new: false,
      // deleted: false,
      toJSON: toJSONNull
    }

    if (i || i === 0) {
      this.rec._meta.rowi = i
      this.rec._meta.filterIndex = i
    }

    for (var p in r) {
      if (this.opts.reactFields && this.opts.reactFields[p] !== false) {
        if ((r[p] || {}).toISOString) {
          // restore dates to Date Object
          this.rec._meta.orig[p] = r[p]
          delete this.rec._meta.prev[p]
        }
      }
    }
  },

  hasChanged() {
    return (
      this.rec?._meta?.deleted ||
      this.rec?._meta?.new ||
      Object.keys(this.rec?._meta?.changes || {}).length
    )
  }
})

// form-data controller
// population and form flow
const dc5 = function (opts) {
  var o = this
  o.opts = opts

  if (!opts.view) {
    if (ow0.dev) {
      console.warn('Please pass view object into dc5 instead of $top')
      debugger
    }

    opts.view = opts.$top.view()
  } else opts.$top = opts.$top ?? opts.view.$top

  var $top = opts.$top
  o.dsName = opts.dsName

  $top.dataSources = $top.dataSources || {}
  $top.dataSources[o.dsName] = this

  opts.el = opts.el || $top[0]

  o.opts.reactFields = o.opts.reactFields || {}
  var recCon = new dataItemController({
    $top,
    rec: opts.rec || {},
    reactFields: o.opts.reactFields,
    callIfChanged:
      opts.callIfChanged || // for properties editor
      function (rec, f) {
        //console.log('field changed ' + f);
        var newValue = _v(rec, f)
        setTimeout(() => qc($top[0]).trigger('ow-field-changed', f, newValue, rec, o), 1)
        o.getEds(f).forEach(el => el.val(newValue, rec)) // todo: we need to find a way to deal with subEds (and grids), not setting el.val(data) each time.
        o.saveCancelState()
      }
  })
  this.recCon = recCon

  $top.dc = $top.dc || o
  // todo: move to the winCon check for $top.dc
  if (o === $top.dc && $top.viewdata) {
    $top.viewdata.requestClose =
      $top.viewdata.requestClose ||
      function () {
        if ($top.dc.hasChanged()) {
          $top.dc.displayName = $top.viewdata.name.split('-')[0]
          ow0.confirm(
            $top.dc.displayName,
            __('ConfirmDiscardChangesCloseForm'),
            r => r && $top.closeForm(true)
          )
          return false
        }
        return true
      }
  }

  if (!Array.isArray(opts.idField)) {
    o.opts.idFields = [o.opts.idField]
  } else {
    o.opts.idFields = o.opts.idField
    o.opts.idField = o.opts.idFields[0]
  }
  o.opts.idField = o.opts.idFields[0]

  // flag to prevent ow-data-changed events firing while populating
  let populating = 0

  // access for overriding
  this.populating = function (v) {
    if (v !== undefined) populating += v ? 1 : -1
    return populating
  }

  const onEdChanged = function (e) {
    if (this !== e.target) return
    var f = $(e.target).attr('data-field') || e.target.opts.fieldName
    if (o.recCon.opts.reactFields[f] !== false) o.recCon.opts.reactFields[f] = true
    if (o.populating()) return
    // console.log('onEdChanged ' + o.dsName + ' ' + this + ' ' + f);

    if ($(e.target).is('.ow-grid')) {
      o.recCon.fieldReact(f)
    } else {
      var newValue = e.target.val()
      _v(o.recCon.rec, f, newValue)
      o.recCon.fieldReact(f)
    }
  }
  $(opts.el).on('ow-change change', '[data-field-for=' + this.dsName + ']', onEdChanged)
  $(opts.el).on('click', 'a[data-field-for=' + this.dsName + ']', onEdChanged)
}
exports.dc = dc5
exports.dc5 = (...args) => new exports.dc(...args)

Object.assign(dc5.prototype, {
  rec() {
    return this.recCon.rec
  },

  fieldReact(f) {
    this.recCon.fieldReact(f)
  },

  react(f) {
    this.recCon.recordReact(f)
  },

  newRec(...args) {
    // override this for default values
    return this.opts.newRec?.(...args) ?? {}
  },

  myId(r, alwaysReturnObj) {
    if (this.opts.idFields.length === 1) {
      if (alwaysReturnObj) {
        var res = {}
        res[this.opts.idField] = r && _v(r, this.opts.idField)
        return res
      } else return r && _v(r, this.opts.idField)
    }
    var ids = {}
    this.opts.idFields.forEach(x => (ids[x] = _v(r, x)))
    return ids
  },

  idString(r) {
    var id = this.myId(r)
    return typeof id === 'object' ? JSON.stringify(id) : id
  },

  hasChanged() {
    return this.recCon.hasChanged()
  },

  edChanged() {
    if (!this.populating()) this.saveCancelState()
  },

  showBusyIcon() {
    this.opts.$top.progress(true)
  },
  hideBusyIcon() {
    this.opts.$top.progress(false)
  },

  validateData(messageArray, onInvalid) {
    // todo: - do we really want to do this control based validation?
    return validateEditors(this.opts.$top, this.dsName, messageArray, onInvalid)
  },

  // load the main record - request from server and then populate the controls
  load(id) {
    var o = this
    if (typeof id === 'object') id = JSON.stringify(id)

    var data = {}

    var url = (typeof o.opts.url === 'function' ? o.opts.url() : o.opts.url) || o.opts.baseURL

    url = url.split('?')
    url[0] = url[0] + (typeof id !== 'undefined' ? '/' + encodeURIComponent(id) : '')
    url = url.join('?')

    return $ajax({
      view: o.opts.view,
      showProgress: true,
      url,
      data
    })
      .then(response => o.populate(response))
      .catch(err => ow0.popError(err))
  },

  // extracts the data in an independent instance for saving.
  // todo: generalize for other component types, subEds etc.
  readData() {
    var o = this
    var result = o.recCon.rec

    // detach the child datasets
    var kids = {}
    o.getEds()
      .filter('.ow-grid')
      .forEach(el => {
        var f = $(el).attr('data-field') || el.opts.fieldName
        if (f in kids) return // in case there are 2, just do the first
        kids[f] = result[f] // store for later
        delete result[f]
      })

    result = ow0.clone(result, true)

    // attach to result
    o.getEds()
      .filter('.ow-grid')
      .forEach(el => {
        var f = $(el).attr('data-field') || el.opts.fieldName
        if (f in result) return
        o.rec()[f] = kids[f]
        el.readData(result) // use the extracted instance from the grid.
      })

    return result
  },

  async save() {
    var o = this
    var $top = this.opts.$top

    if (!o.validateData()) return

    var result = o.readData()
    if (this.preSave(result) === false) return

    delete result._meta

    // if this is a nested edit
    if ($top.viewdata.record) {
      $top.viewdata.result = result
      return $top.closeForm(true)
    }

    var idField = o.opts.idField
    var isNew = o.recCon.rec._meta.new

    if (!isNew || !result[idField] || result[idField] === -1 || this.opts.uid) return innerSave()

    // check for ID match
    var id = o.idString(result)
    return $ajax({
      view: o.opts.view,
      showProgress: true,
      url: o.opts.baseURL.split('?')[0] + '/' + o.idString(result)
    })
      .then(response => {
        if (o.idString(response) === id) {
          $top
            .find('#txt' + idField + ', [data-field="' + idField + '"]')
            .closest('.resource_set')
            .addClass('k-input-errbg')
          o.focusIDField()
          return ow0.popInvalid(__('ID already in use.'))
        }
        return innerSave()
      })
      .catch(() => innerSave())

    function innerSave() {
      return $ajax({
        view: o.opts.view,
        showProgress: true,
        type: isNew ? 'POST' : 'PUT',
        url:
          o.opts.baseURL.split('?')[0] +
          (!isNew ? '/' + o.idString(result) : '') +
          (o.opts.baseURL.split('?')[1] ? '?' + o.opts.baseURL.split('?')[1] : ''),
        data: result
      })
        .then(response => {
          ow0.popSaveOK(response)

          // console.warn('// todo: reload with data returned from the server.');
          o.populate(response.record ?? result)

          qc($top[0]).trigger('ow-data-saved', response, o)
          if (o.opts.closeAfterSave !== false) {
            $top.viewdata.result = result
            return $top.closeForm(true)
          }
          return response
        })
        .catch(err => ow0.popSaveError(err))
    }
  },

  async delete() {
    var o = this
    return ow0
      .confirm(o.opts.displayName, __('Are you sure you want to delete this record?'))
      .then(r =>
        !r
          ? false
          : $ajax({
              view: o.opts.view,
              showProgress: true,
              type: 'DELETE',
              url: o.opts.baseURL + '/' + o.idString(o.recCon.rec)
            })
              .then(response => {
                ow0.popDeleteOK(response)
                qc(o.opts.$top[0]).trigger('ow-data-deleted', response)
                var r = o.newRec()
                r.Name = ''
                o.populate(r)
              })
              .catch(err => ow0.popDeleteError(err))
      )
  },

  copy(id) {
    var o = this

    return $ajax({
      view: o.opts.view,
      showProgress: true,
      url: o.opts.baseURL + '/' + (typeof id === 'object') ? JSON.stringify(id) : id
    })
      .then(response => {
        var r = o.newRec()
        Object.assign(response, o.myId(r, true))
        o.populate(response)
      })
      .catch(err => ow0.alert('load returned error ' + err.errMsg))
  },

  callajax(callName, payload, onSuccess, onError, showProgress) {
    var o = this

    return $ajax({
      view: o.opts.view,
      showProgress,
      type: 'POST',
      url:
        o.opts.baseURL.split('?')[0] +
        '/method/' +
        callName +
        (o.opts.baseURL.split('?')[1] ? '?' + o.opts.baseURL.split('?')[1] : ''),

      data: payload
    })
      .then(response => onSuccess?.(response, payload))
      .catch(err => (onError ? onError(err) : ow0.popError(err)))
  },

  preSave(d) {
    return this.opts.preSave?.(d)
  }, // if you return false it will cancel save.

  cancel() {
    const o = this
    o.recCon.cancelChanges()
    var result = o.populate(o.recCon.rec)
    console.log(result)
  },

  new() {
    var o = this
    if (o.recCon.hasChanged()) {
      ow0.confirm(
        o.opts.displayName,
        __('Are you sure you want to discard changes and reset form?'),
        r => r && o.populate(o.newRec())
      )
      return false
    }
    return o.populate(o.newRec())
  },

  // Applies standard view form loading behaviour based on viewdata.
  async loadInitial(viewdata) {
    var o = this
    var $top = o.opts.$top

    if (viewdata.mode === 'new') {
      const r = o.newRec()
      if (o.opts.uid)
        r[o.opts.idField] = (
          await $put({
            url: '/nextUids',
            data: { dbType: o.opts.uid === true ? 'sagapos' : o.opts.uid, count: 1 }
          })
        ).uids[0]

      o.populate(r)
      if (o.recCon.rec._meta) o.recCon.rec._meta.new = true

      return
    }

    if (viewdata.mode === 'copy') {
      var recToCopy = viewdata.data ? viewdata.data : viewdata.record
      if (!recToCopy) o.copy(viewdata.id)
      else {
        recToCopy = JSON.parse(JSON.stringify(recToCopy))

        if (recToCopy[o.opts.idField] !== -1) recToCopy[o.opts.idField] = null
        // what about the other ids?  should be ok for this case
        //recToCopy._isNew = true;

        if (o.opts.uid)
          recToCopy[o.opts.idField] = (
            await $put({
              url: '/nextUids',
              data: { dbType: o.opts.uid === true ? 'sagapos' : o.opts.uid, count: 1 }
            })
          ).uids[0]

        o.populate(recToCopy)
        if (o.recCon.rec._meta) o.recCon.rec._meta.new = true
      }
    } else {
      if (viewdata.restoreData) {
        if (viewdata.mode === 'edit' && viewdata.id) {
          o.load(viewdata.id).then(() => o.restore(viewdata.restoreData))
        } else {
          o.populate(o.newRec())
          viewdata.mode = 'new'
          o.restore(viewdata.restoreData)
        }
      } else if (viewdata.record) o.populate(viewdata.record)
      else if (viewdata.id) o.load(viewdata.id)
      else {
        o.populate(o.newRec())
        viewdata.mode = 'new'
      }
      viewdata.mode = viewdata.mode || 'edit'
    }

    if (viewdata.mode === 'edit') {
      // this isn't really the right place.  There should be a class on these that says disable-on-edit
      $top
        .find('#txt' + o.opts.idField + ', [data-field="' + o.opts.idField + '"]')
        .forEach(o => o.odisable())
      $top.find('.disable-on-edit').forEach(o => o.odisable?.())
    }
  },

  // the same as for grids - future, get grids using dataController too
  saveCancelState() {
    var o = this
    var $top = o.opts.$top

    var hasChanged = o.hasChanged()
    var broadcast = hasChanged !== o._dirty
    o._dirty = hasChanged

    var state = {
      dataController: o,
      dsName: o.dsName,
      // editable: o.opts.editable,
      editing: o._dirty
    }
    console.log('State.editing:' + state.editing, 'dc')
    // Look for save and cancel buttons bound to this grid
    const broadcastStateChange = el => {
      state.called = el
      qc(el).trigger('ow-datastatechange', state)
    }
    // getSubsAndEds
    if (broadcast) {
      $top
        .find('[data-field-for="' + o.dsName + '"],[data-target-ref="' + o.dsName + '"]')
        .forEach(el => broadcastStateChange(el))

      if (o === $top.dc) broadcastStateChange($top[0])
    }
  },

  prePopulate(model, ...args) {
    return this.opts.prePopulate?.(model, ...args)
  },

  populate(r) {
    var o = this
    var $top = o.opts.$top

    o.getEds().forEach(el => {
      var f = $(el).attr('data-field') || el.opts?.fieldName
      if (f) if (o.recCon.opts.reactFields[f] !== false) o.recCon.opts.reactFields[f] = true
    })

    r = o.prePopulate?.(r) || r // don't assume prePopulate will return something everytime but use result if it does

    o.recCon.rec = r
    o.recCon.initMeta()
    o.populating(true)
    o.updateControls()

    qc($top[0]).trigger('ow-populate', o, r)
    o.populating(false)
    o.saveCancelState()
  },

  restore(r) {
    var o = this
    r = o.prePopulate?.(r) ?? r
    o.recCon.rec = r

    //update Meta
    for (var p in r) {
      if (o.opts.reactFields?.[p] !== false) {
        if ((r[p] || {}).toISOString) {
          // restore dates to Date Object
          o.recCon.rec._meta.orig[p] = r[p]
          if (o.recCon.rec._meta.prev) delete o.recCon.rec._meta.prev[p]
        }
      }
    }
    o.populating(true)
    o.updateControls()
    o.opts.view.qTop.trigger('ow-populate', o, r)
    o.populating(false)
    o.saveCancelState()
  },

  update(r) {
    var o = this
    o.populating(true)
    o.updateControls()
    o.opts.view.qTop.trigger('ow-populate', o, r)
    o.populating(false)
    o.saveCancelState()
  },

  updateControls() {
    var o = this
    var rec = o.recCon.rec
    o.populating(TextTrackCue)
    o.getEds().ow_populate(rec)
    o.populating(false)
    o.opts.view.qTop.renderAsync()
  },

  getEds(f) {
    // optional fieldname
    var eds = this.opts.$top.find("[data-field-for='" + this.dsName + "']")
    return f
      ? eds.filter(function () {
          return f == ($(this).attr('data-field') || this.opts?.fieldName)
        })
      : eds
  },

  focusIDField() {
    this.opts.$top.find('#txt' + this.opts.idField).focus()
  }
})

const buildGroupRows = (recs, currentFilter, fields, ags = {}, groups = {}) => {
  if (!Array.isArray(fields)) fields = [fields]

  currentFilter.groups = groups
  const data = []

  const addGroupFooterRecord = group => {
    group = JSON.parse(JSON.stringify(group))
    group._group.footer = true
    // console.log('GroupFooterRow: ', group._group)
    data.push(group)
  }

  let lastGroup, sKey, group, rec, i

  for (i = 0; i < recs.length; i++) {
    rec = recs[i]
    if (rec._group) console.log('discarding: ', i)

    if (!rec._group) {
      group = { _meta: { rowi: -1 } }
      sKey = fields
        .map(f => {
          group[f] = _v(rec, f)
          return group[f]
        })
        .map(v => {
          if (v === undefined) v = ''
          if (v === null) v = 'null'
          return v.toString()
        })
        .filter(s => s)
        .join('&')

      group._group = { open: true, key: sKey, ags: { count: 0 } }

      // is it in the same group?
      if (lastGroup && sKey === lastGroup._group.key) {
        group = lastGroup
      } else {
        // it's different
        if (lastGroup) addGroupFooterRecord(lastGroup) // footer row

        if (!groups[sKey]) {
          groups[sKey] = group
        } else {
          // console.warn('Reusing the existing group ', sKey)
          group = groups[sKey]
          group._group.ags = { count: 0 } // reset ags
        }

        data.push(group) // headerRow
      }

      lastGroup = group
      // ags are registered in column definitions,
      // eg. groupFooter: 'sum'
      group._group.ags.count++
      let count = group._group.ags.count
      Object.keys(ags).forEach(f => {
        const agType = ags[f]
        if (agType === 'sum') group._group.ags[f] = (group._group.ags[f] || 0) + _v(rec, f)
        if (agType === 'avg')
          group._group.ags[f] = ((count - 1) * (group._group.ags[f] || 0) + _v(rec, f)) / count
        // if (agType === 'avg') group._group.ags[f] = (group._group.ags[f] || 0) + _v(rec, f)
      })

      // console.log('Row: ', sKey)
      rec._meta.group = group
      rec._meta.groupKey = group._group.key
      data.push(rec)
    }
  }

  if (lastGroup) addGroupFooterRecord(lastGroup)

  currentFilter.recs = data
  return currentFilter
}

const applyGroupVisibility = currentFilter => {
  const { includeDeletes = true, showGroupHeaders = true, showGroupFooters = true } = currentFilter

  // filteredRecs is the result of the filters
  //   before applyingGroup
  currentFilter.filterRecs = currentFilter.filterRecs || currentFilter.recs

  currentFilter.recs = currentFilter.filterRecs.filter(r => {
    const rowi = r._meta.rowi
    if (r._group) {
      let group = r._group
      if (!group.footer && !showGroupHeaders) return false
      if (group.footer && !showGroupFooters) return false
    } else {
      currentFilter.inFilter[rowi] = false
      delete r._meta.filterIndex
      let open = r._meta.group ? r._meta.group._group.open : true
      if (open === false) return false
      if (!includeDeletes && r._meta.deleted) return false
      currentFilter.inFilter[rowi] = true
    }

    return true
  })

  // delete currentFilter.filterRecs

  // rebuild the index
  currentFilter.filterMap = []
  currentFilter.recs.forEach((r, i) => {
    r._meta.filterIndex = i
    currentFilter.filterMap.push(r._meta.rowi)
  })

  return currentFilter
}

/**
 * Maintains arrays of records, change control, client filters etc.
 *
 * @param {Object} opts
 * @param {Object} opts.view
 * @param {Object} opts.$top deprecated
 * @param {boolean} opts.hasFilters default true
 *
 */
const collectionController = function (opts) {
  var o = this
  o.dcOpts = opts
  o.opts = opts

  if (!opts.view) {
    if (ow0.dev) {
      console.warn('Please pass view object into ow5 collectionController.')
      debugger
    }
    opts.view = opts.$top.view()
  } else opts.$top = opts.$top ?? opts.view.$top

  opts.hasFilters = opts.hasFilters ?? true

  if (opts.hasFilters) exports.filterController(o, opts)

  o._changes = {} // = { "3": true }
  o._numChanges = 0

  o.saveCancelState()
}
exports.collectionController = collectionController

exports.collectionController5 = (...args) => new collectionController(...args)

Object.assign(collectionController.prototype, {
  populate(r) {
    return this.opts.prePopulate?.(r) ?? r
  },

  showBusyIcon() {
    this.opts.$top.progress(true)
  },

  hideBusyIcon() {
    this.opts.$top.progress(false)
  },

  saveCancelState() {
    var o = this
    var $top = this.opts.$top

    var hasChanged = o.hasChanges()

    var broadcast = hasChanged !== o._dirty
    this._dirty = hasChanged

    const dsName = this.opts.dsName

    var state = {
      dataController: o,
      dsName,
      editing: hasChanged
    }

    // Look for save and cancel buttons bound to this grid
    function broadcastStateChange(el) {
      state.called = el
      qc(el).trigger('ow-datastatechange', state)
    }

    if (broadcast) {
      if ($top) {
        $top
          .find(
            `[data-field-for="${dsName}"],[data-target-ref="${dsName}"],[data-filter-for="${dsName}"]`
          )
          .forEach(el => broadcastStateChange(el))
        if (o === $top.dc) broadcastStateChange($top[0])
      }
    }
  },

  hasChanges() {
    return this._numChanges > 0
  },

  load(noConfirmation) {
    const me = this
    const dc = me

    if (!noConfirmation && me.hasChanges())
      return ow0.confirm(
        __('Refresh'),
        __('Are you sure you want to discard changes and reload'),
        ok => ok && me.load(true)
      )

    var data = {}

    if (dc.opts.hasFilters === true || dc.opts.hasFilters === 'server') {
      data.filter = {}
      dc.readServerFilterControls(data.filter)
      data.filter = JSON.parse(JSON.stringify(data.filter)) // ensures correct Date string format for server.
    }

    var url = dc.url || dc.opts.baseURL
    var base = url.split('?')[0]
    var qry = url.split('?')[1] ? url.split('?')[1].split('&') : []
    url = base + '?' + qry.join('&')

    const success = response => {
      if (dc.prePopulate) response.data.forEach(dc.prePopulate)
      dc.populate(response.data)
      dc.saveCancelState()
    }

    return $ajax({
      view: dc.opts.view,
      showProgress: true,
      LRTask: dc.opts.LRTask !== false,
      data,
      url
    })
      .then(success)
      .catch(err => popError(err))
  },

  cancelChanges() {
    // todo: go through all recs and cancel meta changes, restore original.
    this._changes = {}
    this._numChanges = 0
  },

  filterRec(d, i, filters) {
    for (var j = 0; j < (filters || []).length; j++) {
      var f = filters[j]
      if (applyFilter(d, f) === false) return false
    }
    return true
  },

  recSorter(sorts) {
    return function (a, b) {
      if (sorts.length === 0) return 0

      let f, i, av, bv, result

      for (i = 0; i < sorts.length; i++) {
        f = sorts[i][0]

        if (typeof f === 'function') {
          av = f(a) || 0
          bv = f(b) || 0
        } else {
          av = _v(a, f) || 0
          bv = _v(b, f) || 0
        }

        if (typeof av === 'string') av = av.toLowerCase()
        if (typeof bv === 'string') bv = bv.toLowerCase()

        if (av !== bv) {
          result = sorts[i][1] ? av > bv : bv > av
          return result ? 1 : -1
        }
      }
      return 0
    }
  },

  applyGroupVisibility,

  // filtering
  applyClientFilterSort(
    recs,
    filters,
    sorts,
    includeDeletes,
    groupBy,
    showGroupHeaders = true,
    showGroupFooters = true
  ) {
    var dc = this

    const result = {
      filters,
      sorts,
      groupBy,
      recs: [],
      filterMap: [], // filterMaps to rowi
      inFilter: [],
      showGroupHeaders,
      showGroupFooters
    }

    result.recs = recs.filter(r => {
      var incl =
        (!includeDeletes || !r._meta.deleted) && dc.filterRec(r, result.inFilter.length, filters)
      result.inFilter.push(incl)
      return incl
    })

    if (sorts) result.recs.sort(this.recSorter(sorts))

    if (groupBy) {
      result.recs.sort(
        this.recSorter([
          [x => x?._meta?.new && Object.keys(x._meta.changes ?? {}).length === 0, 1],
          [groupBy, 1]
        ])
      )
      buildGroupRows(result.recs, result, groupBy, dc.opts.ags || {}, dc.groups)
      dc.groups = result.groups
      applyGroupVisibility(result)
    }

    // rebuild the index
    result.filterMap = []
    result.recs.forEach((row, fi) => {
      // rebuild the index
      row._meta.filterIndex = fi
      result.filterMap.push(row._meta.rowi)
    })

    return result
  }
})

exports.filterController = function (o, opts) {
  var $top = opts.$top || o.myWin()

  o.getMyFilterControls = function () {
    return $top.find('[data-filter-for="' + (o.dsName || opts.dsName) + '"]')
  }

  o.readFilterControls = function (filter) {
    var $filterControls = o.getMyFilterControls()

    var filters = []
    // call the filter() on each filterControl that's bound to me.
    $filterControls.forEach(fc => $(fc).ow_readFilter(filters))

    if (filters.length) {
      if (!filter.filters) filter.filters = filters
      else filter.filters = filter.filters.concat(filters)
    }
  }

  o.readServerFilterControls = function (filter) {
    var $filterControls = o.getMyFilterControls()

    var filters = []
    // call the filter() on each filterControl that's bound to me.
    $filterControls
      .filter(function () {
        return !this.opts || !this.opts?.clientFilter
      })
      .ow_readFilter(filters)

    if (filters.length) {
      if (!filter.filters) filter.filters = filters
      else filter.filters = filter.filters.concat(filters)
    }
  }

  o.readClientFilterControls = function (filter) {
    var $filterControls = o.getMyFilterControls()

    var filters = []
    // call the filter() on each filterControl that's bound to me.
    $filterControls
      .filter(function () {
        return this.opts?.clientFilter
      })
      .ow_readFilter(filters)

    if (filters.length) {
      if (!filter.filters) filter.filters = filters
      else filter.filters = filter.filters.concat(filters)
    }
  }
}

if (typeof $ !== 'undefined')
  $('body')
    .on('change ow-change', '[data-filter-for]', function (e) {
      const el = e.target
      const $top = el.myWin()
      const dsName = $(el).attr('data-filter-for') // for grid col filters dsName === ''

      const target =
        (dsName && $top.find('#' + dsName, ' , .iden-' + dsName)[0]) || el.closest('.ow-grid')

      target &&
        qc(target)
          .trigger('ow-filter-changed')
          .trigger(el.opts?.clientFilter ? 'ow-client-filter-changed' : 'ow-server-filter-changed')
    })
    .on('command-save', '.win-con > .ow5', function (e) {
      if (!$(e.target).attr('data-field-for') && !$(e.target).attr('data-target-ref')) {
        const $top = e.target.myWin()
        const dc = $top.dc
        if (dc?.save) {
          if (document.activeElement) qc(document.activeElement).trigger('ow-change') // in case there are changes
          setTimeout(() => dc.save(), 1)
          return false
        }
      }
    })
    .on('command-cancel', '.win-con > .ow5', e => {
      if (!$(e.target).attr('data-field-for') && !$(e.target).attr('data-target-ref')) {
        const $top = e.target.myWin()
        if ($top.dc?.cancel) {
          $top.dc.cancel()
          return false
        }
      }
    })
    .on('change ow-change', '.ow5 .k-input-errbg', e =>
      e.currentTarget.classList.remove('k-input-errbg')
    )

// Notes on layout controls (BasicEds)
// "ed" - editor control
// SEE formHelper layouts
// On clientside, BasicEds have:
// HTMLelement.val(v, rec) - getter/setter on the DOM object for population
// data-field-for="<objectName>" attr - used for automated populate/read
// HTMLelement.opts - read out of data-owauto-opts Array (where item.init=='basic-ed')
//     - fieldName,
//     - dataType (optional)
//     - id
//     - hasWrapper :true | false
//     - hasLabel :true | false
//     - label
//     - options (these are for the client-side passed through json in attribute data-<templ>-options="" )
//     - fieldSchema (manually pass in info about the data it connects to)
//     - dsName
// eg html could be ...
// <input id="?" class="owauto" data-owauto-opts="[{init:'basic-ed',...}]" data-field-for="<objectName>" />
// To render you would have EJS code:
// layouts().ed("Name", {templ: 'text', options: {client opts}});
//
// ow2+ - You can have passive data bound controls now without owautos
// eg.
// <input id="txt" data-field-for="datasource1" data-field="lastName" />
// note, if data-field is not set then the id will be used for fieldname.
//

const { _v } = require('../_v')
const { $param } = require('../ajax')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { basicEdCheck } = require('../controls/check')
const { ctlParsers } = require('../ctl-format')
const { dates } = require('../dates')
const { killEvent } = require('../killEvent')
const { delphiColortoHex, hextoDelphiColor, objToAttr, safeId, debounce } = require('../ow0/core')

const parseDate = (...args) => ow0.parseDate(...args)

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

const getFieldName = el => {
  el.opts = el.opts ?? {}
  el.opts.fieldName = el.opts?.fieldName ?? qc(el).attr('data-field') ?? el.id
  qc(el).attr({ 'data-field': el.opts.fieldName })
  return el.opts.fieldName
}

const populateField = (el, rec) => {
  if (el.populate) return el.populate(rec)

  var fieldName = getFieldName(el)
  var v = _v(rec, fieldName)

  if (typeof v === 'string' && el.opts.edType === 'int') v = !isNaN(parseInt(v)) ? parseInt(v) : v
  if (typeof v === 'string' && el.opts.edType === 'date') v = new Date(v)
  v = typeof v !== 'undefined' ? v : ''

  if (fieldName) {
    if (el.val && typeof el.val === 'function') el.val(v, rec)
    else if (typeof el.value !== 'undefined') el.value = v
    else el.innerHTML = v // for display labels
  } else console.log('populateField Failed: ' + $(el).data('fieldFor') + ' no fieldname')
}

const readField = (el, rec) => {
  el.opts = el.opts || { fieldName: el.id }
  if (el.readData) return el.readData(rec)
  var fieldName = getFieldName(el)
  if (!fieldName) return console.log('No readData for control ' + el.id)
  if (el.val) _v(rec, fieldName, el.val(undefined, rec))
  else if (el.value !== undefined) _v(rec, fieldName, el.value)
  else _v(rec, fieldName, el.innerHTML)
}

exports.owControlProps = {
  displayValidity(bValid, msg) {
    const el = this
    if (bValid) {
      el.closest('.resource_set')?.classList.remove('k-input-errbg')
      el.parentNode.invalidMsg = ''
    } else {
      el.parentNode.invalidMsg = msg
      el.parentNode.title = msg
      el.closest('.resource_set')?.classList.add('k-input-errbg')
    }
  }
}

/**
 * owControl (control) - pins standard behaviours on existing DOM editor controls,
 * please extend as required.
 *
 * to use in your instance code, on form init run  function passing in the DOM element
 * eg. owControl($('#mytextbox')[0]));
 *
 * @param {HTMLElement} el
 * @param {BasicEdOptions} opts
 * @returns element var
 */
const owControl = function (el, opts = {}) {
  el.opts ??= opts // for use by non-layouts controls

  Object.assign(el, exports.owControlProps)

  $(el)
    .closest('.resource_set')
    .find('label')
    .forEach(l => qc(l).attr({ for: undefined }))
}

/**
 * What is the purpose to declare  function without return any value? We have been using it a lot in our main view ejs files.
 * Why we need to call  function and not returning any value to the caller? The variables declared are inside  function closure,
 * we could not access externally.
 *
 * @param {HTMLElement||jq(HTMLElement)} top - html scope (usually the view window)
 * @param {any} dsName - maps to eds' data-field-for attr
 * @param {any} rec (optional) - data entity to write values onto
 */
exports.readBasicEds = (top, dsName, rec) => {
  var r = rec || {}
  $(top)
    .find("[data-field-for='" + dsName + "']")
    .forEach(el => !el.readOnly && readField(el, r))
}

/**
 *
 * @param {HTMLElement||jq(HTMLElement)} top - html scope (usually the k window)
 * @param {string} dsName - maps to eds' data-field-for attr
 * @param {Object} rec (optional) - data entity with values to populate controls with
 */
exports.populateBasicEds = (top, dsName, rec) => {
  $(top)
    .find("[data-field-for='" + dsName + "']")
    .forEach(el => populateField(el, rec))
}

/**
 * returns a jquery set of matching basicEd controls
 * @param {HTMLElement||jq(HTMLElement} top - html scope (usually the view window)
 * @param {string} dsName - maps to eds' data-field-for attr
 * @param {string} fieldName (optional) filter for opts.fieldName
 */
const getBasicEds = (top, dsName, fieldName, includeFilters) =>
  $(top)
    .find(
      "[data-field-for='" +
        dsName +
        "']" +
        (dsName === 'mainGrid' || includeFilters ? ",[data-filter-for='" + dsName + "']" : '')
    )
    .filter(
      (i, x) =>
        !fieldName || $(x).attr('data-field') === fieldName || x.opts?.fieldName === fieldName
    )
exports.getBasicEds = getBasicEds

const getBasicEd = ($top, dsName, fieldName) => getBasicEds($top, dsName, fieldName, true)[0]
exports.getBasicEd = getBasicEd

exports.validateBasicEds = function (top, dsName, messageArray, onInvalid) {
  messageArray = messageArray || []
  let result = true
  onInvalid =
    onInvalid ||
    ((display, err, el) => {
      console.log(display + ' invalid: ' + err)
      messageArray.push(display + ' invalid: ' + err)
      if (el.displayValidity) el.displayValidity(false, err)
    })

  var passwordChecklist = []
  var gridFieldsChecklist = [] //for multiple grids
  $(top)
    .find("[data-field-for='" + dsName + "']")
    .forEach(el => {
      if (el.displayValidity) el.displayValidity(true)
      // test for required
      // test for type
      // test for format match??
      // test for length
      if (el.validate) {
        var r = el.validate(onInvalid)
        if (typeof r !== 'undefined' && typeof r !== 'object') {
          result = result && r
        }
        if (typeof r === 'object') {
          if (r.edType === 'password') passwordChecklist.push(r)
          if (r.edType === 'grid') gridFieldsChecklist.push(r)
        }
      } else {
        var fname = el.opts.fieldName || qc(el).attr('data-field')
        console.log('No validation for control [' + dsName + '].' + fname)
      }
    })

  //process password
  var pwdGroups = passwordChecklist.reduce(function (obj, item) {
    obj[item.group] = obj[item.group] || []
    obj[item.group].push({ value: item.value, target: item.target })
    return obj
  }, {})
  for (var rec in pwdGroups) {
    if (Object.hasOwnProperty.call(pwdGroups, rec)) {
      if (Array.isArray(pwdGroups[rec])) {
        var pwdCompare = null
        var matched = true
        pwdGroups[rec].forEach(l => {
          if (pwdCompare === null) pwdCompare = l.value
          else if (l.value !== pwdCompare) matched = false
        })
        if (!matched) {
          pwdGroups[rec].map(item => {
            const el = $(item.target)[0]
            if (el.makeMeInvalid) el.makeMeInvalid(onInvalid, messageArray)
            if (el.displayValidity)
              el.displayValidity(false, __('Password and Confirm Password mismatch'))
          })
          result = false
        }
      }
    }
  }

  //process grids validation
  gridFieldsChecklist.forEach(f => {
    if (!f.resVal) {
      ;(f.resErr || []).forEach(err => messageArray.push(err))
      result = false
    }
  })

  if ((messageArray ?? []).length) {
    ow0.popInvalid(html((messageArray || []).join('<br>')))
    var elError = $(top).find('.resource_set.k-input-errbg input')[0]
    elError?.focus()
  }
  return result
}

// use for pinning basicEd type init functions
exports.basicEditorInits = {}

const BasicEd = function (el, opts) {
  el = $(el)[0]
  opts = opts || {}
  el.opts = opts

  // hook to allow views to set opts before editors are built
  var $top = el.myWin()
  if ($top?.edInit) {
    $top.edInit?.(el, el.opts)
    opts = el.opts
  }

  getFieldName(el)

  if (opts.isFilterControl) opts.filter = true
  if (opts.obscure) opts.edType = 'static'

  el.displayValidity = function (bValid, msg) {
    if (bValid) {
      $(el).removeClass('k-input-errbg')
      el.invalidMsg = ''
    } else {
      el.invalidMsg = msg
      el.title = msg
      $(el).addClass('k-input-errbg')
    }
  }

  $(el).closest('[role=presentation]').removeClass('k-textbox')

  el.populate = function (rec) {
    var fname = opts.childFieldName || opts.fieldName
    if (fname) {
      var v = _v(rec, fname)

      if (typeof v === 'string' && opts.edType === 'int') {
        v = !isNaN(parseInt(v)) ? parseInt(v) : v
      }
      if (typeof v === 'string' && opts.edType === 'date') {
        v = new Date(v)
      }
      v = typeof v !== 'undefined' ? v : ''
      if (el.val && typeof el.val === 'function') {
        el.val(v, rec)
      } else if (typeof el.value !== 'undefined') {
        el.value = v
      } else if (typeof el.innerHTML !== 'undefined') {
        el.innerHTML = v
      } else {
        console.log('populate Failed: ' + el.id + ' no val')
      }
    } else {
      console.log('populate Failed: ' + el.id + ' no fieldname')
    }
  }
  el.readData = function (rec) {
    var fname = opts.fieldName
    if (fname) {
      if (el.val) {
        _v(rec, fname, el.val(undefined, rec))
      } else if (typeof el.value !== 'undefined') {
        _v(rec, fname, el.value)
      }
    } else {
      'populateField Failed: ' + el + ' no fieldname'
    }
  }
  el.oval = (...args) => el.val(...args)

  el.validate = function (onInvalid, messageArray) {
    messageArray = typeof messageArray === 'undefined' ? [] : messageArray
    var v = el.val()
    var hasValue = !(
      typeof v === 'undefined' ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )
    if (
      (opts.required || (opts.required !== false && opts.schema && opts.schema.required)) &&
      !hasValue
    ) {
      if (onInvalid) {
        onInvalid(opts.label || opts.name, 'must have a value', el, messageArray)
        return false
      }
    }
    if (typeof v === 'number' && isNaN(v) && opts.edType === 'lookup' && opts) {
      if (onInvalid) {
        onInvalid(opts.label || opts.name, 'must select from the list.', el, messageArray)
        return false
      }
    }
    opts.minLength = opts.minLength || opts.min
    opts.maxLength = opts.maxLength || opts.max

    if (typeof v === 'string' && opts.minLength && opts.minLength < (v || '').length) {
      if (onInvalid)
        onInvalid(opts.label, 'must be have min length of ' + opts.minLength, el, messageArray)
      return false
    }
    if (typeof v === 'string' && opts.maxLength && opts.maxLength < (v || '').length) {
      if (onInvalid)
        onInvalid(opts.label, 'must be have max length of ' + opts.maxLength, el, messageArray)
      return false
    }

    if (opts.validation && typeof opts.validation === 'object') {
      if (opts.validation.ne !== undefined && v === opts.validation.ne) {
        if (onInvalid) onInvalid(opts.label, 'cannot be ' + opts.validation.ne, el, messageArray)
        return false
      }
    }

    // Validation for fields with from value and to value
    if (
      opts.edType === 'date' ||
      opts.edType === 'time' ||
      opts.edType === 'float' ||
      opts.edType === 'int'
    ) {
      var fromField, toField

      if (opts.isToField && opts.fromID) {
        var $top = el.myWin()
        var $from = $top.find(
          '#' + (opts.edType === 'float' || opts.edType === 'int' ? 'txt' : 'dp') + opts.fromID
        )

        if ($from.length > 0) {
          toField = typeof el.val() === 'number' ? el.val().toString() : el.val()
          fromField =
            typeof $from[0].val() === 'number' ? $from[0].val().toString() : $from[0].val()

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                opts.fromID + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }
    }

    return true
  }

  el.placeholder = opts.placeholder || opts.label || ''

  if (el.tagName.toLowerCase() === 'input') {
    // el["aria-busy"] = "false";
    // el["aria-autocomplete"] = "list";
    // el["aria-readonly"] = "false";
    /// el["aria-disabled"] = "false";
    // el["aria-haspopup"] = "true";
    // el.role = "textbox";
    // el.type = 'text';
  }

  exports.basicEditorInits[opts.edType]?.(el, opts)

  if (opts.readOnly) qc(el).attr({ readonly: '' })

  if (opts.isFilterControl) exports.basicEditorInits.filterControl(el, opts)
  else if (opts.filter) {
    exports.basicEditorInits.filterControl(el, opts)
    $(el).removeClass('ow-filter-control')
  }

  // initial Value
  if (typeof opts.value !== 'undefined') el.val(opts.value)

  if (opts.inGrid) el.name = opts.fieldName

  if (opts.onInit) {
    opts.onInit(el, opts)
    delete opts.onInit
  }

  if (opts.disabled) el.odisable?.()

  return el
}
exports.BasicEd = BasicEd
exports['basic-ed'] = BasicEd

const tabstrip = function (el, opts) {
  var $top = el.myWin()
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoTabStrip({
    scrollable: true,
    animation: { open: { effects: 'fadeIn' } }
  })
  $(el).addClass(hiddenClasses)
  var k = $(el).data('kendoTabStrip')

  if (k.items().length < 2) el.tabIndex = -1

  // Adjust for unusual grid resize behaviour in tabs
  k.bind('change', () => {
    qc(el).renderAsync()

    $top
      .find('[data-target-ref="' + el.id + '"]')
      .forEach(x => qc(x).trigger('ow-tab-change', el, el.oTabElement()))

    // Disabled tick buttons if no checklist found
    var disable = $(el.oTabElement()).find('.ow-checklist').length === 0
    $top.find('[data-command="tickall"]').attr('disabled', disable)
    $top.find('[data-command="untickall"]').attr('disabled', disable)

    // Disabled add-row button if no grid found
    var noGridFound = $(el.oTabElement()).find('.k-grid, .ow-grid').length === 0
    if (!noGridFound) {
      var hasEditable = false
      $(el.oTabElement())
        .find('.k-grid, .ow-grid')
        .forEach(g => {
          if (g.isEditable && g.isEditable() && g.isTabOutNewRow && g.isTabOutNewRow())
            hasEditable = true
        })
      noGridFound = !hasEditable
    }
    // don't do  if the add-row button is pointed at a particular grid.
    // why is the relationship between a button and grid being managed by the tabstrip!!!
    $top
      .find(
        '[data-command="add-row"][data-target-ref=tabstrip],[data-command="add-row"]:not([data-target-ref])'
      )
      .prop('disabled', noGridFound)
    $(el.oTabElement()).find('[data-command="add-row"]').prop('disabled', noGridFound)

    qc(el).trigger('ow-tab-change', el.oTabIndex(), el.oTabElement())
  })

  el.kDisable = v => k.enable(v === false)
  el.oTabIndex = () => k.select().index()

  el.oTabElement = index => k.contentElement(index || el.oTabIndex())

  el.activateTab = index => k.items()[index] && k.activateTab(k.items()[index])

  el.activeGrid = () => $(el.oTabElement()).find('.k-grid, .ow-grid')

  el.hideTab = function (tabClass, v) {
    const max = k.items().length
    for (let i = 0; i < max; i++) {
      var target = $(k.items()[i])
      if (target.hasClass(tabClass))
        v ? target.attr('style', 'display:none') : target.attr('style', '')
    }
  }

  el.addTab = function (name, position, activate) {
    if (typeof position === 'undefined') position = k.items().length
    if (position < 0) position = k.items().length + position + 1

    var tabOpts = {
      text: name + '<span class="delete-tab"> </span>',
      encoded: false,
      content: ''
    }

    if (position > k.items().length) position = k.items().length

    if (k.items().length === 0) {
      k.append([tabOpts])
    } else if (position === 0) k.insertBefore([tabOpts], k.items()[position])
    else k.insertAfter([tabOpts], k.items()[position - 1])

    if (activate !== false) k.activateTab(k.items()[position])

    var tab = el.oTabElement(position)

    if (!tab) {
      // try going for the div.
      tab = $(el).children('div.k-content')[position]
    }

    $(tab).addClass('tab_content')

    return tab
  }

  el.updateTabsRow = function () {
    let tabstrip = this
    let rowWidth = 0

    $(tabstrip)
      .find('ul li')
      .forEach(ele => (rowWidth += $(ele).width()))

    let rowHeight = $(tabstrip).find('ul').height() // fix tab height

    $(tabstrip)
      .find('div.k-content')
      .css({ top: rowHeight + 'px' })
  }

  // if no tab active, activate first one
  if (k.items().length) k.activateTab(k.items()[0])

  el.triggerChange = () => k.trigger('change')
  setTimeout(() => k.trigger('change'), 10)

  // if we receive an event meant for a grid, pass it to the grid on the selected Tab
  $(el).on('command-add-row', function (e) {
    var $gridsInTab = $(el.oTabElement()).find('.k-grid, .ow-grid')

    if (!$gridsInTab.length) {
      return console.log('no grid found')
    } else if ($gridsInTab.length > 1) {
      console.log(
        'WARNING: More than one grid found to send AddRow event, please overide behaviour'
      )
    }
    $gridsInTab.trigger('row-new', [null, null, $(e.target).data('comp')])

    return false
  })

  return el
}
exports.tabstrip = tabstrip

const weekdays = v => {
  const days = dates.shortDaysOfWeekJs.map(d => (d = d.toLowerCase()))
  let r = {}
  days.forEach(d => (r[d] = 0))

  if (v >= 64) {
    r[days[6]] = 1
    v = v - 64
  }
  if (v >= 32) {
    r[days[5]] = 1
    v = v - 32
  }
  if (v >= 16) {
    r[days[4]] = 1
    v = v - 16
  }
  if (v >= 8) {
    r[days[3]] = 1
    v = v - 8
  }
  if (v >= 4) {
    r[days[2]] = 1
    v = v - 4
  }
  if (v >= 2) {
    r[days[1]] = 1
    v = v - 2
  }
  if (v >= 1) r[days[0]] = 1
  r.toInt = function () {
    return (
      r[days[6]] * 64 +
      r[days[5]] * 32 +
      r[days[4]] * 16 +
      r[days[3]] * 8 +
      r[days[2]] * 4 +
      r[days[1]] * 2 +
      r[days[0]]
    )
  }
  return r
}

exports.basicEditorInits.weekdays = function (el, opts) {
  $(el).addClass('weekdays')
  opts.placeholder = opts.placeholder || __('Select Day') + '...'
  opts.autoClose = false
  opts.clearButton = false

  opts.list = []
  var shortDaysOfWeekJs = dates.shortDaysOfWeekJs
  shortDaysOfWeekJs.forEach(d => opts.list.push({ Value: d.toLowerCase(), Text: d }))

  exports.basicEditorInits.multiSelect(el, opts)
  var k = $(el).data('kendoMultiSelect')

  el.val = function (v) {
    var currMultiDays = k.value()
    var val = weekdays(0)

    if (typeof v !== 'undefined') {
      var days = weekdays(v)
      shortDaysOfWeekJs.forEach(function (day) {
        day = day.toLowerCase()
        val[day] = days[day] ? 1 : 0
        if (days[day]) {
          currMultiDays.push(day)
        }
      })
      k.value(currMultiDays)
    } else {
      shortDaysOfWeekJs.forEach(function (day) {
        day = day.toLowerCase()
        var found = currMultiDays.some(function (d) {
          return d === day
        })
        val[day] = found ? 1 : 0
      })
    }

    return val.toInt()
  }

  return el
}

exports.basicEditorInits.months = function (el, opts) {
  $(el).addClass('months')

  var months = opts.longFormat ? dates.months : dates.shortMonths //shortMonths by default

  var templ = i => `<a class="chk-${i} k-checkbox" id="chk-${months[i]}" href='#'>${months[i]}</a>`

  $([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(templ).join('\r\n')).appendTo($(el))

  $(el)
    .find('a')
    .on('keypress', function (e) {
      if (e.which === 32) {
        this.click()
        e.preventDefault()
        return false
      }
    })

  el.val = function (i) {
    var v = months.slice(0)
    if (typeof i !== 'undefined') {
      months.forEach(function (day, idx) {
        var d = $(el).find('.chk-' + idx.toString())
        if (i[idx]) d.addClass('on')
        else d.removeClass('on')
      })
    }
    var val = v
    months.forEach(function (day, idx) {
      val[idx] = $(el)
        .find('.chk-' + idx)
        .hasClass('on')
        ? 1
        : 0
    })

    return val
  }
}

exports.basicEditorInits.radio = function (el, opts) {
  const btns = () => $(el).find('a.ow-radio')

  opts.list.map((val, i) => {
    var $radio = $(
      '<a ' +
        (opts.width ? "style='width: " + opts.width + "em'" : '') +
        " class='ow-radio basic-ed " +
        ((opts.defaultIndex == null && i === 0) || i === opts.defaultIndex ? 'on' : '') +
        "' id='rbtn-" +
        opts.fieldName +
        "' href='#' data-radio-value='" +
        val.Value +
        "'>" +
        val.Text +
        '</a>'
    ).on('click', function () {
      btns().forEach(radio => radio.classList.remove('on'))
      $(this).addClass('on').trigger('change')
    })

    $(el).append($radio)
    el.val = function (v) {
      if (typeof v !== 'undefined' && v !== null) {
        btns().forEach(radio => {
          //check the radio
          var tmp = $(radio).attr('data-radio-value')
          if (v.toString && v.toString() === tmp) radio.click()
        })
      }
      if (el.opts.list == null) return

      var val = null
      // get selected radio
      btns().forEach((radio, i) => {
        if (radio.classList.contains('on')) val = el.opts.list[i].Value
      })
      return val
    }
  })

  $(el)
    .find('a')
    .on('keypress', e => {
      if (e.which === 32) {
        e.target.click()
        e.preventDefault()
        return false
      }
    })
    .on('click', () => qc(el).trigger('change'))

  owControl(el, opts)

  el.odisable = function (v) {
    v = v !== false
    //check the radio
    btns().forEach(radio =>
      v ? qc(radio).addClass('disabled') : qc(radio).removeClass('disabled')
    )
    qc(el).disable(v)
  }
}

const imageToStr64 = data => {
  var strOfChar = ''
  var bufferArray = data
  var typeArray
  var sizeToChunck = 100000
  var numOfSeparation = Math.ceil(bufferArray.length / sizeToChunck)
  for (let i = 0; i < numOfSeparation; i++) {
    typeArray = new Uint8Array(bufferArray.splice(0, sizeToChunck))
    strOfChar += String.fromCharCode.apply(null, typeArray)
  }
  return btoa(strOfChar)
}

const base64toHEX = base64 => {
  var raw = atob(base64)
  var HEX = ''
  for (let i = 0; i < raw.length; i++) {
    var _hex = raw.charCodeAt(i).toString(16)
    HEX += _hex.length === 2 ? _hex : '0' + _hex
  }
  return HEX.toUpperCase()
}

exports.basicEditorInits.picturebox = function (el, opts) {
  $(el).addClass('picturebox')
  var colOptions = el.opts

  let rec
  if (opts.model) rec = opts.model

  const allowedImageTypes = opts.accept?.length
    ? opts.accept.map(text => text.toLowerCase())
    : ['jpg', 'jpeg']

  const acceptAttribute = allowedImageTypes.map(attr => `.${attr}`).join(', ')

  var fieldName = colOptions.fieldName
  if (!$(el).find('img').length) {
    $(`<div class="k-button k-upload-button">
    <input type="file" class="btn-sel" name="files" data-role="upload" accept="${acceptAttribute}">
      <span>${__('Select files')} ...</span>
      </div>
    <button class="btn-clear k-button">${__('Clear')}</button>
    <img src="" alt="" class="${colOptions.classes}">`).appendTo($(el))
  }

  var imgContainer = $(el).find('img')[0]

  $(el)
    .find('.btn-sel')
    // .on('click', () => (this.value = null)) // to reset for select the same image after reset.
    .on('change', function () {
      let me = this
      var file = me.files[0]
      if (!file) return

      var imageIdentifier = /^image\//
      if (!imageIdentifier.test(file.type))
        return ow0.popError(
          __('Error'),
          `${__('Fileshouldbeoneoftheallowedimagetypes')}: ${opts.accept?.join(', ')}.`,
          5000
        )

      const imageType = file.type.split('/')[1].toLowerCase()
      if (imageType && !allowedImageTypes.includes(imageType.toLowerCase()))
        return ow0.popError(
          __('Error'),
          `${__('Fileshouldbeoneoftheallowedimagetypes')}: ${opts.accept?.join(', ')}.`,
          5000
        )

      var fileSize = file.size / 1024 / 1024
      if (opts.maxFileSize && fileSize > opts.maxFileSize)
        return ow0.popError(
          __('Error'),
          `${__('Filesizecannotbegreaterthan')} ${opts.maxFileSize} ${__('MB')}.`,
          5000
        )

      qc(el).files = me.files

      var reader = new FileReader()
      reader.onload = (function (aImg) {
        return function (e) {
          el.isDirty = true
          aImg.src = e.target.result
          $(el).trigger('change')
        }
      })(imgContainer)

      reader.readAsDataURL(file)
    })

  $(el)
    .find('.btn-clear')
    .on('click', () => {
      el.isDirty = true
      el.val(null, rec)
      qc(el).trigger('ow-clear')
    })

  el.populate = function (model) {
    rec = model
    el.isDirty = false
    var v = _v(rec, el.opts.fieldName)
    el.val(v, rec, true)
  }

  el.readData = function (rec) {
    var v = el.val(undefined, rec)
    if (el.isDirty && v === '') rec.isDirty = true
    _v(rec, el.opts.fieldName, el.val())
    return v
  }

  el.val = function (v, model, populating = false) {
    if (populating) rec = model

    if (typeof v !== 'undefined') {
      var hasChanged = false

      if (v === imgContainer.src) return v

      const addPrefix = s => {
        var hex = base64toHEX(s.substr(0, 8)).substr(0, 4)
        if (hex === 'FFD8') s = 'data:image/jpeg;base64,' + s
        else if (hex === '424D') s = 'data:image/bmp;base64,' + s
        else if (hex === '4749') s = 'data:image/gif;base64,' + s
        else if (hex === '8950') s = 'data:image/png;base64,' + s
        return s
      }
      const convertToHexData = v => {
        var s = imageToStr64(v[0].Pic.data)
        return addPrefix(s)
      }
      if (Array.isArray(v) && rec.length) v = convertToHexData(v)

      if (v === null) {
        hasChanged = !imgContainer.src || imgContainer.src.length < 30
        imgContainer.src = 'data:image/jpeg;base64,'
        if (rec?.[fieldName]) {
          hasChanged = true
          delete rec[fieldName]
        }
      } else {
        if (v) {
          const afterLoadBlob = data => {
            if (
              data === '' ||
              data === 'data:image/jpeg;base64,' ||
              (typeof data === 'object' && !data?.size)
            ) {
              imgContainer.src = 'data:image/jpeg;base64,'
              return
            }
            imgContainer.src = URL.createObjectURL(data)

            // newer promise based version of img.onload
            return imgContainer.decode().then(() => URL.revokeObjectURL(imgContainer.src))
          }

          if (typeof v === 'string' && v.substr(0, 5) !== 'data:')
            $ajax({ method: 'post', url: v }).then(afterLoadBlob)
          else afterLoadBlob(v)
        }
      }
      if (hasChanged && !populating) qc(el).trigger('change')
    }

    if (imgContainer.src === 'data:image/jpeg;base64,') return imgContainer.src
    if ((imgContainer.src || '').substr(0, 5) !== 'data:') return null

    // read from control
    return imgContainer.src //return Raw
  }

  return el
}

const openPopUpFactory = function (el) {
  var opts = el.opts

  const isDisabled = el => !el || el.classList.contains('ow-disabled')

  return function () {
    if (isDisabled(el) || isDisabled(el.parentElement)) return

    var $top = $(el).myWin()

    opts.popUp.defaultCallback =
      opts.popUp.defaultCallback ||
      function ($win, viewdata) {
        el.popUpOpen = false
        $top.toFront()
        if (viewdata.result) {
          var v = opts.popUp.fieldName ? _v(viewdata.result, opts.popUp.fieldName) : viewdata.result

          if (opts.model) {
            if (opts.objectFieldName) _v(opts.model, opts.objectFieldName, viewdata.result) // console.log('Setting4 ' + opts.objectFieldName + ': ' + JSON.stringify(viewdata.result) );
            if (el.selectItem) el.selectItem(viewdata.result)
            _v(opts.model, opts.fieldName, v)
          }
          el.populate(viewdata.result) // THIS IS INCORRECT!  populate is for the base model not the lookup object!
          el.popUpResult = viewdata.result // if it needs more info.
          $(el).trigger('change')
          $top.toFront()
        }
      }
    opts.popUp.callback = opts.popUp.callback || opts.popUp.defaultCallback

    opts.popUp.record = el.val()
    opts.popUp.result = null
    opts.popUp.mode = opts.popUp.mode || 'select'
    opts.popUp.userRole = opts.popUp.userRole || el.myWin().viewdata.userRole

    el.popUpOpen = true
    ow0.windows.openView(opts.popUp)
  }
}

const addPopUp = (el, opts, k) => {
  $(el).parent().addClass('ow-popup')
  if (opts.half) $(el).parent().addClass('short')

  $(el)
    .parent()
    .append(
      '<span unselectable="on" class="k-select ow-button-popup" role="button" tabindex="-1">' +
        '<span class="k-icon k-i-popup" style="margin: -.54em;"></span></span>'
    )

  el.openPopUp = opts.popUp.openPopUp || openPopUpFactory(el)

  $(el)
    .parent()
    .find('.k-select.ow-button-popup')
    .on('mousedown', () => (el.popUpOpen = true)) // to prevent the filterControl change event.
  $(el)
    .parent()
    .find('.k-select.ow-button-popup')
    .on('mouseup', () => (el.popUpOpen = false)) //to prevent the filterControl change event.
  $(el)
    .parent()
    .find('.k-select.ow-button-popup')
    .on('click', () => el.openPopUp())

  if (!opts.popUp.disableShortcut) {
    // ignore if shortcut not required
    var target = (k && k.input) || el
    $(target).on('keydown', e => (e.which === 120 ? el.openPopUp() : true))
  }
}

exports.basicEditorInits['text'] = function (el, opts) {
  el.val = v => {
    if (typeof v !== 'undefined') el.value = v
    return el.value
  }

  if (el.parentElement?.classList.contains('ow-textbox')) {
    el.wrapper = $(el.parentElement)
  } else {
    el.wrapper = $('<span class="k-textbox ow-textbox ow-ctl-wrap"></span>')
    $(el).replaceWith(el.wrapper)
    el.wrapper.append($(el))
  }

  if (opts.width) el.wrapper.addClass(opts.width)

  owControl(el, opts)
  if (opts.popUp) {
    $(el).parent().addClass('k-dropdown-wrap basic-ed k-dropdown-wrap k-textbox k-widget')
    addPopUp(el, opts)
  }

  if (opts.maxLength || opts.max) el.maxLength = opts.maxLength || opts.max
  el.otext = el.val

  return el
}

exports.basicEditorInits['email'] = function (el, opts) {
  exports.basicEditorInits['text'](el, opts)
  el.type = 'email'
  var regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  var validate = el.validate
  el.validate = function (onInvalid, messageArray) {
    var result = validate.call(el, onInvalid)
    if (el.val() && !regex.test(el.val())) {
      onInvalid(opts.label, __('Invalid Email Format'), el, messageArray)
      return false
    }
    return result
  }

  return el
}

exports.basicEditorInits.static = function (el, opts) {
  el.readOnly = true
  if (opts.obscure) el.type = 'password'
  el.placeholder = '-'
  el.classList.add('static-text')
  exports.basicEditorInits['text'](el, opts)
  el.tabIndex = -1
  return el
}

exports.basicEditorInits['textarea'] = function (el, opts) {
  el.val = function (v) {
    if (typeof v !== 'undefined') el.value = v
    return el.value
  }
  if (opts.rows) el.rows = opts.rows
  owControl(el, opts)
  if (opts.maxLength || opts.max) el.maxLength = opts.maxLength || opts.max
  return el
}

exports.basicEditorInits['password'] = function (el, opts) {
  exports.basicEditorInits['text'](el, opts)
  el.type = 'password'
  var validate = el.validate
  el.validate = function (onInvalid) {
    var result = validate.call(el, onInvalid)
    var v = el.val()
    var resultObj = {}
    if (opts.targetGroup) {
      resultObj.group = opts.targetGroup
      resultObj.value = v
      resultObj.edType = opts.edType
      resultObj.target = el
      el.makeMeInvalid = function (onInvalid, messageArray) {
        if (opts.label !== 'Password')
          onInvalid(opts.label, __('Password and Confirm Password mismatch'), messageArray)
      }
      return resultObj
    }
    return result
  }
  return el
}

exports.basicEditorInits['check'] = basicEdCheck

/**
 * owDatePicker - repeated bahaviours for OW DatePickers.  Assumes already kendoDatePicker
 *
 */
exports.owDatePicker = function (el) {
  owControl(el) // core
  var k = $(el).data('kendoDatePicker')
  k.bind('change', () => $(el).trigger('ow-change'))
  el.applyValue = () => k.value(el.resolve(el.value, true))
  var input = el
  input.requestTabOut = function () {
    el.value = el.resolve(el.value, true)
    if (!input.validate()) {
      var err = __('Invalid date format')
      ow0.popError(__('Error'), err, 100)
      return false
    }
    return true
  }

  $(input).on('blur', function () {
    el.value = el.resolve(el.value, true)
    if (!input.validate()) {
      input.focus()
      var err = __('Invalid date format')
      ow0.popError(__('Error'), err, 100)
      return false
    }

    if (el.applyValue) {
      el.applyValue()
    }
    return true
  })

  el.otext = () => k.text()

  el.oval = v => (typeof v === 'undefined' ? k.value() : k.value(v))

  el.kDisable = v => k.enable(v === false)
  return el
}

/**
 * owDateTimePicker - repeated bahaviours for OW DatePickers.  Assumes already kendoDatePicker
 *
 */
exports.owDateTimePicker = function (el) {
  owControl(el) // core
  var k = $(el).data('kendoDateTimePicker')
  k.bind('change', () => $(el).trigger('ow-change'))

  el.applyValue = () => {
    const v = el.resolve(el.value, true)
    k.value(ctlParsers.datetime(v))
  }

  if (!el.opts.filter) {
    var input = el
    input.requestTabOut = function () {
      el.value = el.resolve(el.value, true)
      if (!input.validate()) {
        var err = __('Invalid date/time')
        ow0.popError(__('Error'), err, 100)
        return false
      }
      return true
    }

    $(input).on('blur', function () {
      el.value = el.resolve(el.value, true)
      if (!input.validate()) {
        var err = __('Invalid date/time')
        input.focus()
        ow0.popError(__('Error'), err, 100)
        return false
      }
      if (el.applyValue) {
        el.applyValue()
      }
      return true
    })
  }
  el.otext = () => k.text()

  el.oval = v => (typeof v === 'undefined' ? k.value() : k.value(v))

  el.kDisable = v => k.enable(v === false)

  return el
}

exports.basicEditorInits['date'] = function (el, opts) {
  var format = opts.format || ow0.dates.DateFormatSetting
  const today = new Date().removeTime()
  var max = opts.max || new Date(today.setFullYear(today.getFullYear() + 300))
  var min = opts.min || new Date(1899, 11, 30)
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoDatePicker({ format: format, max: max, min: min })
  $(el).addClass(hiddenClasses)
  $(el).closest('.k-datepicker').add($(el)).removeClass('k-textbox')

  var k = $(el).data('kendoDatePicker')

  el.resolve = function (value, finished) {
    var v = dates.resolveDate(value, finished, undefined, { position: el.selectionEnd })
    return v
  }

  $(el).on('keydown', () => (el.prevValue = el.value))

  $(el).on('keyup', function (e) {
    if (e.which === 8) return // backspace

    if (e.which === 46) {
      // delete
      var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
      if ('/-.'.indexOf(charDeleted) > -1) return
    }

    if (el.value !== el.prevValue) {
      var x = el.selectionEnd
      var caretAtEnd = x === el.value.length
      var v = el.resolve(el.value)
      if (v.indexOf('|') > -1) {
        x = v.indexOf('|')
        v = v.split('|').join('')
      }
      el.value = v
      el.prevValue = v
      if (caretAtEnd) return
      el.selectionStart = x
      el.selectionEnd = x
    }
  })

  el.val = function (v) {
    if (typeof v !== 'undefined') k.value(v)
    if (!v) return k.value()
    v = parseDate(el.resolve(el.value, true), format)
    return k.value(k.value(v))
  }

  el.validate = function (onInvalid, messageArray) {
    var value = k.element.val()
    var v = value // (value || '').replace(/[^0-9]/g, '').trim(); //used for checking if input is empty
    var hasValue = !(
      typeof v === 'undefined' ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )

    value = parseDate(value, k.options.parseFormats)
    value = value == null ? true : false

    if (value && hasValue) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must be valid', el, messageArray)
      return false
    } else if (opts.required && !hasValue && onInvalid) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must have a value', el, messageArray)
      return false
    }

    if (opts.isToField && opts.fromID) {
      var $top = el.myWin()
      var $from = $top.find('#dp' + opts.fromID)
      if ($from.length > 0) {
        let toField = el.val()
        let fromField = $from[0].val()
        if (fromField > toField) {
          if (onInvalid) {
            onInvalid(
              $from[0].opts.label + ' field',
              __('From field must be always lower than to field'),
              el,
              messageArray
            )
            return false
          }
        }
      }
    }

    return true
  }
  exports.owDatePicker(el, opts)
  $(el).attr('data-role', 'datepicker')

  return el
}

exports.basicEditorInits['datetime'] = function (el, opts) {
  var format = opts.format || ow0.dates.DateTimeFormatSetting
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoDateTimePicker({
    format,
    parseFormats: [
      'yyyy-MM-dd HH:mm:ss',
      'yyyy-MM-dd HH:mm:ss.zzz',
      'yyyy-MM-dd HH:mm',
      'dd/MM/yyyy HH:mm:ss',
      'dd/MM/yyyy HH:mm:ss.zzz',
      'dd/MM/yyyy HH:mm',
      ow0.dates.DateTimeFormatSetting
    ]
  })

  $(el).addClass(hiddenClasses)

  $(el).closest('.k-datetimepicker').add($(el)).removeClass('k-textbox')

  var k = $(el).data('kendoDateTimePicker')

  el.resolve = (value, finished) =>
    ow0.dates.resolveDateTime(value, finished, undefined, { position: el.selectionEnd })

  $(el).on('keydown', () => (el.prevValue = el.value))

  $(el).on('keyup', function (e) {
    if (e.which === 8) return // backspace

    // delete
    if (e.which === 46) {
      var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
      if ('/-.:'.indexOf(charDeleted) > -1) return
    }

    if (el.value !== el.prevValue) {
      var x = el.selectionEnd
      var caretAtEnd = x === el.value.length
      var v = el.resolve(el.value)
      if (v.indexOf('|') > -1) {
        x = v.indexOf('|')
        v = v.split('|').join('')
      }
      el.value = v
      el.prevValue = v
      if (caretAtEnd) return
      el.selectionStart = x
      el.selectionEnd = x
    }
  })

  el.val = function (v) {
    if (typeof v !== 'undefined') k.value(v)
    v = el.resolve(el.value, true)
    return k.value() || k.value(k.value(v))
  }

  el.validate = function (onInvalid, messageArray) {
    var v = (k.element.val() || '').replace(/[^0-9]/g, '').trim() //used for checking if input is empty
    var hasValue = !(
      typeof v === 'undefined' ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )

    var value = parseDate(k.element.val(), k.options.parseFormats)
    value = value == null ? true : false

    if (value && hasValue) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must be valid', el, messageArray)
      return false
    } else if (opts.required && !hasValue && onInvalid) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must have a value', el, messageArray)
      return false
    }
    return true
  }
  exports.owDateTimePicker(el, opts)
  $(el).attr('data-role', 'datetimepicker')
  if (opts.filter) {
    let k = $(el).data('kendoDateTimePicker') || $(el).data('kendoMaskedTextBox')
    const _value = k.value
    k.value = function (...args) {
      if (arguments.length === 0) return _value.call(k)
      const [x] = args
      args[0] = x ? new Date(x) : x
      return _value.call(k, ...args)
    }
  }
  return el
}

exports.basicEditorInits.time = function (el, opts) {
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoTimePicker({
    interval: opts.interval || 30,
    format: opts.format || ow0.dates.TimeFormatSetting,
    parseFormats: ['HHmm', 'HH:mm', 'HH:mm:ss', ow0.dates.TimeFormatSetting]
  })
  $(el).addClass(hiddenClasses)
  $(el).closest('.k-timepicker').add($(el)).removeClass('k-textbox')
  var k = $(el).data('kendoTimePicker')

  el.resolve = function (value, finished) {
    return ow0.dates.resolveTime(value, finished, opts, { position: el.selectionEnd })
  }

  el.applyValue = () => k.value(el.resolve(el.value, true))

  $(el).on('keydown', () => (el.prevValue = el.value))

  $(el).on('keyup', function (e) {
    if (e.which === 8) return // backspace
    // delete
    if (e.which === 46) {
      var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
      if (' .:'.indexOf(charDeleted) > -1) return
    }

    if (el.value !== el.prevValue) {
      var x = el.selectionEnd
      var caretAtEnd = x === el.value.length
      var v = el.resolve(el.value)
      if (v.indexOf('|') > -1) {
        x = v.indexOf('|')
        v = v.split('|').join('')
      }
      el.value = v
      el.prevValue = v
      if (caretAtEnd) return
      el.selectionStart = x
      el.selectionEnd = x
    }
  })

  if (opts.filter) {
    let k = $(el).data('kendoTimePicker') || $(el).data('kendoMaskedTextBox')
    var _val = k.value
    k.value = function (x) {
      return _val.call(this, x ? new Date(x) : x)
    }
  } else {
    var input = el
    input.requestTabOut = function () {
      el.value = el.resolve(el.value, true)
      if (!input.validate()) {
        var err = __('Invalid time')
        ow0.popError(__('Error'), err, 100)
        return false
      }
      return true
    }

    $(input).on('blur', function () {
      el.value = el.resolve(el.value, true)
      if (!input.validate()) {
        var err = __('Invalid time')
        input.focus()
        ow0.popError(__('Error'), err, 100)
        return false
      }
      return true
    })
  }

  k.bind('change', () => $(el).trigger('ow-change'))

  el.val = function (v) {
    if (typeof v !== 'undefined') k.value(v)
    v = el.resolve(el.value, true)
    return v
  }

  el.validate = function (onInvalid, messageArray) {
    var v = (k.element.val() || '').replace(/[^0-9]/g, '').trim() //used for checking if input is empty
    var hasValue = !(
      typeof v === 'undefined' ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )

    var value = parseDate(k.element.val(), k.options.parseFormats)
    value = value == null ? true : false

    if (value && hasValue) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must be valid', el, messageArray)
      return false
    }

    if (opts.isToField && opts.fromID) {
      var $top = el.myWin()
      var $from = $top.find('#dp' + opts.fromID)
      if ($from.length > 0) {
        let toField = el.val()
        let fromField = $from[0].val()
        if (fromField > toField) {
          if (onInvalid) {
            onInvalid(
              $from[0].opts.label + ' field',
              __('From field must be always lower than to field'),
              el,
              messageArray
            )
            return false
          }
        }
      }
    }
    return true
  }

  owControl(el, opts)
  el.kDisable = v => k.enable(v === false)

  return el
}

exports.basicEditorInits.timeString = function (el, opts) {
  opts.allowSec = opts.allowSec || false

  opts.list = []
  const addTimeToList = x => opts.list.push({ Value: x, Text: x })

  for (let i = 0; i < 24; i++) {
    addTimeToList(('0' + i).slice(-2) + ':00')
    addTimeToList(('0' + i).slice(-2) + ':30')
  }
  // addTimeToList('24:00')
  exports.basicEditorInits['lookup'](el, opts)

  var parseFormats = ['HHmm', 'HH:mm', 'HH:mm:ss', ow0.dates.TimeFormatSetting]

  var k = $(el).data('kendoComboBox')
  var input = k.input[0]

  el.resolve = (value, finished) =>
    ow0.dates.resolveTime(value, finished, opts, { position: input.selectionEnd })

  el.applyValue = () => (el.value = el.resolve(el.value, true))

  $(input).on('keydown', () => (el.prevValue = input.value))

  $(input).on('keyup', function (e) {
    if (e.which === 8) return // backspace
    // delete
    if (e.which === 46) {
      var charDeleted = (el.prevValue || input.value)[input.selectionEnd - 1]
      if (' .:'.indexOf(charDeleted) > -1) return
    }

    if (input.value !== el.prevValue) {
      var x = input.selectionEnd
      var caretAtEnd = x === input.value.length
      var v = el.resolve(input.value)
      if (v.indexOf('|') > -1) {
        x = v.indexOf('|')
        v = v.split('|').join('')
      }
      input.value = v
      el.prevValue = v
      if (caretAtEnd) return
      input.selectionStart = x
      input.selectionEnd = x
    }
  })

  input.requestTabOut = function () {
    input.value = el.resolve(input.value, true)
    if (!el.validate()) {
      ow0.popError(__('Error'), __('Invalid time'), 100)
      return false
    }
    return true
  }
  el.requestTabOut = input.requestTabOut

  $(input)
    .off('blur')
    .on('blur', function () {
      input.value = el.resolve(input.value, true)
      if (!el.validate()) {
        var err = __('Invalid time')
        input.focus()
        ow0.popError(__('Error'), err, 100)
        return false
      }
      return true
    })

  $(input).on('change', () => $(el).trigger('ow-change'))

  el.val = v => {
    if (typeof v !== 'undefined') input.value = v
    v = el.resolve(input.value, true)
    return v
  }

  el.validate = function (onInvalid, messageArray) {
    var v = input.value
    var hasValue = v !== ''

    var value = parseDate(v, parseFormats)
    value = value ? true : false

    if (!value && hasValue) {
      if (onInvalid) onInvalid(opts.label || opts.name, 'must be valid', el, messageArray)
      return false
    }
    return true
  }
  owControl(el, opts)

  return el
}

exports.basicEditorInits['int'] = function (el, opts) {
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoNumericTextBox({
    format: '0',
    min: opts.min,
    max: opts.max,
    decimals: 0,
    spinners: false,
    step: 0
  })
  $(el).addClass(hiddenClasses)
  var k = $(el).data('kendoNumericTextBox')

  k.bind('change', () => {
    $(el).trigger('ow-change')
    $(el).trigger('change')
  })

  el.applyValue = () => k.value(el.value)
  el.val = v => (typeof v !== 'undefined' ? k.value(v) : k.value())

  var validate = el.validate
  el.validate = function (onInvalid, messageArray) {
    var result = validate.call(el, onInvalid)
    var v = el.val()
    if (opts.required !== false && typeof opts.min !== 'undefined' && opts.min > v) {
      if (onInvalid) onInvalid(opts.label, 'Value must be at least ' + opts.min, el, messageArray)
      return false
    }
    if (opts.required !== false && typeof opts.max !== 'undefined' && opts.max < v) {
      if (onInvalid)
        onInvalid(opts.label, 'Value must be no more than ' + opts.max, el, messageArray)
      return false
    }
    return result
  }
  if (opts.readOnly) k.wrapper.find('.k-formatted-value').attr('tabindex', '-1')

  owControl(el, opts)
  el.kDisable = v => k.enable(v === false)

  return el
}

exports.basicEditorInits['float'] = function (el, opts) {
  if (!opts.format && opts.currency) opts.format = 'c'
  else if (!opts.format) opts.format = ''
  else if (opts.format && !opts.decimals)
    opts.decimals = opts.format.substr(1, opts.format.length) || null

  if (opts.obscure) opts.static = true

  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoNumericTextBox({
    spinners: false,
    format: opts.format,
    min: opts.min ?? -9007199254740991,
    max: opts.max,
    decimals: opts.decimals,
    step: 0
  })
  $(el).addClass(hiddenClasses)
  var k = $(el).data('kendoNumericTextBox')

  if (opts.obscure) {
    k.wrapper.find('input').forEach(el => (el.type = 'password'))
    el.odisable()
  }

  k.bind('change', () => qc(el).trigger('ow-change').trigger('change'))
  el.applyValue = () => k.value(el.value)

  el.val = function (v) {
    if (typeof v !== 'undefined') {
      k.value(v)
      k.wrapper.find('input').removeAttr('title') //remove tooltip
    }
    return k.value()
  }

  // remove tooltip
  $(el).on('blur', () => k.wrapper.find('input').removeAttr('title'))

  var validate = el.validate
  el.validate = function (onInvalid, messageArray) {
    var result = validate.call(el, onInvalid)
    var v = el.val()
    if (typeof opts.min !== 'undefined' && opts.min > v) {
      if (onInvalid) onInvalid(opts.label, 'Value must be at least ' + opts.min, el, messageArray)
      return false
    }
    if (typeof opts.max !== 'undefined' && opts.max < v) {
      if (onInvalid)
        onInvalid(opts.label, 'Value must be no more than ' + opts.max, el, messageArray)
      return false
    }
    return result
  }
  if (opts.readOnly) k.wrapper.find('.k-formatted-value').attr('tabindex', '-1')

  owControl(el, opts)
  el.kDisable = v => k.enable(v === false)

  return el
}

/**
 * owCombo - Adds Repeated behaviours for OW Combos.  Assumes already kendoComboBox
 * please extend!
 *
 * @param {HTMLElement} el
 * @param {Object} opts - BasicEd options
 * @returns el
 */
const owComboBox = function (el, opts) {
  owControl(el, opts)

  var k = $(el).data('kendoComboBox')
  opts = opts || {}

  el.kDisable = v => k.enable(v === false)

  el.open = () => k.open()
  el.refresh = () => {
    k.dataSource.read()
    k.refresh()
  }

  el.url = url => {
    k.options.dataSource.transport.read.url = url // not used anymore - overridden
    opts.url = url
    el.refresh()
  }

  el.textTemplate = el.textTemplate || (item => _v(item, opts.textField))

  // returns the selected items display
  el.displayText = function () {
    if (!el.selectedItem()) return ''
    return el.textTemplate(el.selectedItem())
  }

  el.selectItem = function (item, silent) {
    var v = item ? _v(item, opts.valueField) : null
    if (v && !el.val()) v = '' // to reset when clear the text on editor.
    ow0.log('selectItem: ' + el.lastSetValue + ' -> ' + v, 'combo', el)

    var hasChanged = el.lastSetValue !== v

    if (opts.model) {
      if (opts.objectFieldName) _v(opts.model, opts.objectFieldName, item)
      _v(opts.model, opts.fieldName, v)
    }
    el.val(v, opts.model)

    if (!silent) k.trigger('change') // in grid this often does nothing because the editor is destroyed cancelling the event bubble ...?

    if (hasChanged) $(el).trigger('ow-change', [item]) // used so we don't fire this change event on enter key in closed combo
  }

  el.selectFirst = function (silent) {
    var list = Array.prototype.map.call(k.dataItems(), function (x) {
      return x
    })
    var textFilter = $(el).siblings('span').find('input')[0]
    var item = list.find(x => {
      // the item is a match ...
      if (
        (el.textTemplate(x) || '')
          .toString()
          .toLowerCase()
          .indexOf(textFilter.value.toLowerCase()) + 1
      )
        return true // display text matches
      if (
        (_v(x, opts.valueField) || '').toString().toLowerCase() === textFilter.value.toLowerCase()
      )
        return true // ID matches
      return false
    })
    ow0.log('selectFirst ' + JSON.stringify(item ? item : null), 'combo')

    if (item) {
      textFilter.value = el.textTemplate(item) // prevents infinite loop
      el.selectItem(item, silent)
    }
    return item
  }

  el.otext = function (v) {
    if (v !== undefined) k.text(v)
    return k.text()
  }

  el.clearValue = () => el.otext('')
  el.oval = () => k.value()

  el.selectedItem = function () {
    if (k.selectedIndex < 0) return null

    var x = k.dataItem(k.selectedIndex)
    if (x) return JSON.parse(JSON.stringify(x))

    ow0.log("we have a selectedIndex but it doesn't map to the dataItems!", 'combo')
    return null
  }

  // for non data endpoint connected combos, replace the data list.
  el.lookupList = function (lookupList) {
    while (k.dataSource.at(0)) k.dataSource.remove(k.dataSource.at(0))
    lookupList.forEach(di => k.dataSource.add(di))
  }

  el.odisable = function (v) {
    v = v !== false
    var tmp = el.validateAndTryFirst
    el.validateAndTryFirst = function () {}
    k.enable(!v)
    qc(el).disable(v)
    el.validateAndTryFirst = tmp
  }

  var txtFilter = $(el).siblings('span').find('input')[0]
  $(txtFilter).on('keyup', function (e) {
    if (e.which === 38 || e.which === 40) {
      el.lastKeyUp = e.which
      return // ignore nav keys
    }

    if (e.which === 8 || e.which === 46) {
      // delete or backspace
      if (txtFilter.value === '' && k.value()) {
        ow0.log('clearing selection.', 'combo') // for in grid
        el.selectItem(null)
        //  if (el.isOpen)
        if (
          k.options.dataSource.transport ||
          (k.options.dataSource.data && k.options.dataSource.data.length)
        )
          k.dataSource.read()
      }
    }

    if (txtFilter.value.indexOf(String.fromCharCode(e.which)) + 1) {
      ow0.log('FILTERING', 'combo', el)
    }

    el.lastKeyUp = e.which
  })

  el.validateAndTryFirst = function () {
    if (!opts.selectOnArrow && el.defaultSelectedItem) {
      ow0.log(
        [
          '----> selecting defaultSelectedItem: ' + _v(el.defaultSelectedItem, opts.valueField),
          '  selecteditem: ' + k.selectedIndex,
          '  k.value() = ' + k.value()
        ],
        'combo',
        el
      )

      var item = el.defaultSelectedItem
      el.defaultSelectedItem = null
      el.selectItem(item)
      return true
    }

    if (el.userHasFiltered && !el.userHasFiltered()) {
      return true
    }

    if (!opts.filter && k.selectedIndex !== -1 && txtFilter.value !== el.displayText()) {
      // we have a text value and a selected item
      ow0.log('setting selectedIndex to -1', 'combo', el)
      k.selectedIndex = -1

      if (txtFilter.value !== '' && el.selectFirst()) return true

      if (el.opts.col) el.opts.label = el.opts.col().title

      var err = 'Invalid ' + (el.opts.label || el.opts.placeholder || '')
      ow0.popError(__('Error'), err, 100)
      return false
    }

    if (k.selectedIndex === -1 && txtFilter.value !== '' && !opts.filter) {
      if (el.defaultSelectedItem) {
        let item = el.defaultSelectedItem
        el.defaultSelectedItem = null
        el.selectItem(item)
        k.value(el.val())
        return true
      }

      if (el.selectFirst()) return true

      if (el.opts.col) el.opts.label = el.opts.col().title

      ow0.popError(__('Error'), 'Invalid ' + (el.opts.label || el.opts.placeholder || ''), 100)
      return false
    }
    return true
  }

  if (!opts.filter) {
    var input = el // $(el).siblings('span').find('input')[0];
    input.requestTabOut = function () {
      ow0.log('RequestTabOut: value: ' + input.value, 'combo', el)
      return el.validateAndTryFirst()
    }
    txtFilter.requestTabOut = el.requestTabOut

    $(txtFilter).on('blur', function () {
      ow0.log('BLUR', 'combo', el)
      el.validateAndTryFirst() // checks validity and shows error
      el.defaultSelectedItem = null
    })
  }

  $(k.list).on('click', 'li', function (e) {
    ow0.log('LI CLICKED', 'combo', el)
    el.defaultSelectedItem = k.dataItem(e.target)
  })

  /**
   * readData - used by basicEds for binding
   * with opts.objectFieldName the
   * readBasicEds function will now write onto the
   */
  if (opts.fieldName) {
    el.readData = function (rec) {
      var v = el.val(undefined, rec)

      if (_v(rec, opts.fieldName) !== v) {
        _v(rec, opts.fieldName, v)
        if (opts.objectFieldName && el.selectedItem()) {
          var item = ow0.clone(el.selectedItem())
          delete item._display
          delete item._dummy
          _v(rec, opts.objectFieldName, item)
        }
      }
    }
  }

  if (opts.filter) k.options.valuePrimitive = true

  return el
}

exports.basicEditorInits['lookup-ajaxtextvalue'] = function (el, opts) {
  // combo behaviour
  opts.selectOnArrow = false

  //
  var objRef = null
  opts.textField = opts.textField || 'Text'
  opts.valueField = opts.valueField || 'Value'

  el.textTemplate = opts.textTemplate || (d => (d && _v(d, opts.textField)) || '')

  if (opts.valueTextDisplay) {
    el.textTemplate = function (d) {
      if (!d) return ''
      var v = _v(d, opts.valueField)
      if (typeof v === 'undefined' || v === null) return ''
      var t = _v(d, opts.textField)
      if (typeof t === 'undefined' || t === null) return v
      return v + ' - ' + t
    }

    if (!opts.dropdownTemplate) opts.dropdownTemplate = d => d._display
  }

  el.isOpen = false

  var kcb = {
    serverFiltering: opts.noFilter ? false : true,
    filter: opts.noFilter ? null : 'contains',
    delay: 250,
    minLength: 1,
    serverPaging: opts.noFilter ? false : true,
    dataSource: {
      serverFiltering: opts.noFilter ? false : true,
      serverSorting: opts.noFilter ? false : true,
      sort: { field: 'order', dir: 'asc' },
      pageSize: opts.noFilter ? 9999 : 8,
      serverPaging: opts.noFilter ? false : true,
      transport: {
        read(options) {
          if (opts.loadOnInit === false) {
            if (opts.model && opts.objectFieldName) {
              objRef = ow0.clone(_v(opts.model, opts.objectFieldName))
              if (objRef) {
                objRef.Value = objRef[opts.valueField]
                objRef.Text = objRef[opts.textField]
                return options.success([objRef])
              }
            }

            return options.success([])
          }

          var url = typeof opts.url === 'function' ? opts.url(options) : opts.url

          var params = []
          if (url.split('?').length > 1) {
            params = url.split('?')[1].split('&')
            url = url.split('?')[0]
          }
          params.push('raw=1')
          var k = $(el).data('kendoComboBox')

          if (k) {
            if (opts.noFilter) {
              params.push('limit=999')
            } else {
              var sub = k.input.val() || ''
              if (opts.valueTextDisplay) {
                sub = sub.split(' - ')[0]
              }
              params.push('sub=' + sub)
              el.lastSub = sub
            }
          }

          params = params.join('&')
          if (el.lastParams === params && el.currentListParams === el.lastParams) {
            if (k && el.lastUrl === url) {
              ow0.log('Combo list already loaded', 'combo', el)
              return options.success(k.dataItems())
            }
          }
          el.lastParams = params
          el.lastUrl = url
          url = url + '?' + params
          ow0.log('combo reading filter ' + el.lastParams, 'combo', el)

          $.ajax({
            type: 'GET',
            url: url,
            // data: options.data,
            dataType: 'json',
            success(response) {
              el.currentListFilter = sub
              el.currentListParams = params
              //ow0.log('Combo Query result for: ' + sub + ', last: ' + el.lastSub);

              if (sub !== el.lastSub) {
                ow0.log('Combo result is not from the last query.', 'combo', el)
                // return;
              }

              // set the _display for each
              response.forEach(function (objRef) {
                if (typeof el.textTemplate(objRef) !== 'undefined')
                  objRef._display = el.textTemplate(objRef)
                else delete objRef._display
              })

              // if the list doesn't contain current selectedItem, add it.
              if (!el.isOpen && k && k.value() && objRef && _v(objRef, opts.valueField)) {
                ow0.log(
                  [
                    'Adding objRef to dropdown ' + el,
                    '-- Selected Item is: ' + _v(objRef, opts.valueField) + ' | ' + k.value()
                  ].join('\r\n'),
                  'combo',
                  el
                )

                var valueMatch = response.filter(function (x) {
                  return _v(x, opts.valueField) === _v(objRef, opts.valueField)
                })
                if (valueMatch.length === 0) {
                  // then it isn't in the list

                  if (
                    !sub ||
                    (_v(objRef, opts.textField) || '').toString().indexOf(sub) + 1 ||
                    ((_v(objRef, opts.valueField) || '').toString() || '').indexOf(sub) + 1
                  ) {
                    // either there's no substring OR it's a match with display

                    if (typeof el.textTemplate(objRef) !== 'undefined')
                      objRef._display = el.textTemplate(objRef)
                    else delete objRef._display
                    response.unshift(objRef) // insert
                    // response.push(objRef); // add on the end
                  }
                } else if (!objRef || objRef._dummy) {
                  objRef = valueMatch[0]
                  if (objRef && !Object.keys(objRef).length) objRef = null
                  ow0.log('dropdown loaded setting to:' + JSON.stringify(objRef), 'combo', el)
                }
              }
              response.forEach((item, i) => (item.order = i))
              options.success(response)
            },
            error(err) {
              console.log('read error: ' + JSON.stringify(err))
              options.error(err)
            }
          })
        },
        parameterMap(data, type) {
          return type === 'read'
            ? {
                raw: true,
                sub: data.filter && data.filter.filters[0] && data.filter.filters[0].value // || $this.val(),
              }
            : data
        }
      }
    },
    dataTextField: '_display', // opts.textField || 'Text',
    dataValueField: opts.valueField || 'Value',
    select(e) {
      ow0.log('onSELECT', 'combo', el)
      if (e.item && el.isOpen) {
        el.defaultSelectedItem = e.dataItem // used when bluring
        ow0.log(
          'onSELECT:combo default selection: ' + JSON.stringify(el.defaultSelectedItem),
          'combo',
          el
        )
        if (!opts.selectOnArrow) return
      }
      if (!e.item) el.defaultSelectedItem = null

      var selectedItem = e.item ? k.dataItem(e.item) : null
      if (k.text() && k.text().trim() && !e.item) selectedItem = k.dataItem(0)

      e.selectedItem = selectedItem // e.item ? k.dataItem(e.item) : null;

      // This code allows the control to prevent selection of the item
      if (el.onselected) {
        if (false === el.onselected(e) && k.value() !== _v(e.selectedItem, opts.valueField)) {
          // if the handler returns false undo it.
          k.value(k.value())
          e.preventDefault()
          return false
        }
      }
      if (el !== el && el.onselected) {
        if (false === el.onselected(e) && k.value() !== _v(e.selectedItem, opts.valueField)) {
          // if the handler returns false undo it.
          k.value(k.value())
          e.preventDefault()
          return false
        }
      }

      objRef = selectedItem === null ? null : ow0.clone(selectedItem)
      if (objRef && !Object.keys(objRef).length) objRef = null
      ow0.log(
        'onSELECT-----------------------------------------------------------------',
        'combo',
        el
      )

      if (!opts.filter) el.selectItem(objRef)
    },
    dataBound() {
      ow0.log('onDataBound: ' + el, 'combo', el)
      $(el).trigger('ow-data-bound')
    },
    open() {
      ow0.log('onOPEN', 'combo', el)
      el.isOpen = true
    },
    close() {
      ow0.log('onCLOSE', 'combo', el)
      el.isOpen = false
      if (!opts.selectOnArrow && el.defaultSelectedItem) el.validateAndTryFirst()
    }
  }

  if (opts.loadOnInit === false)
    kcb.open = e => {
      opts.loadOnInit = true
      e.sender.dataSource.read()
    }

  if (typeof opts.dropdownTemplate === 'string') {
    kcb.template = opts.dropdownTemplate
  } else if (typeof opts.dropdownTemplate === 'function') {
    kcb.template = d => opts.dropdownTemplate(d)
    kcb.dropdownTemplate = kcb.template
  }
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoComboBox(kcb)
  $(el).addClass(hiddenClasses)
  var k = $(el).data('kendoComboBox')
  if (opts.width) k.input.parent().parent().width(opts.width)
  k.list.width(opts.listWidth || 300)

  if ($(el).hasClass('w4') || $(el).hasClass('w3'))
    k.list.width(328)

    // tracking text key entry to decide on whether to auto select on tab out.
  ;(function () {
    var displayVal = ''
    var isFiltering = false

    el.userHasFiltered = () => isFiltering

    const input = k.input

    input
      .on('keyup', function () {
        var s = ['DISPLAYVAL: ' + displayVal + '->' + input.val()]
        isFiltering = displayVal !== input.val()
        s.push('-- isFiltering:' + isFiltering)
        ow0.log(s, 'combo', el)
      })
      .on('focus', function () {
        isFiltering = false
        displayVal = input.val()
        ow0.log('setDISPLAYVAL: ' + displayVal, 'combo', el)
      })
  })()

  if (opts.inGrid && !opts.filter) {
    k._ow_value = k.value
    k.value = function (v) {
      if (v || v === 0 || v === false || v === null) return k._ow_value(v)

      var res = k._ow_value()

      if (
        (k.select() === -1 ||
          (opts.valueTextDisplay && (res || '').toString().split('-').length > 1)) &&
        opts.fieldName &&
        opts.model
      )
        res = _v(opts.model, opts.fieldName)

      return res
    }
  }

  el.val = function (v, rec) {
    if (!opts.model || (rec && opts.model._dummy)) {
      opts.model = rec || { _dummy: true }

      if (opts.objectFieldName) {
        if (typeof _v(opts.model, opts.objectFieldName) === 'undefined') {
          _v(opts.model, opts.objectFieldName, null)
          ow0.log('VAL: Setting ' + opts.objectFieldName + ' to null', 'combo', el)
        }

        // if it's a filter we want to make sure this is the selected value.
        if (opts.filter && _v(opts.model, opts.objectFieldName) === null) {
          var values = { _display: v } //fix for IE
          _v(values, opts.valueField, v)
          _v(opts.model, opts.objectFieldName, values)
        }
      }
      ow0.log('VAL: opts.model for ' + el + ' is: ' + JSON.stringify(opts.model), 'combo', el)
    }

    if (typeof v !== 'undefined') {
      // for fast tabbing through ingrid, prevent it from losing value.
      if ((v || v === 0) && opts.inGrid && !opts.filter && !k.dataItems().length) {
        // is the value equal to the fieldName
        var valChanged = opts.valueField && _v(opts.model, opts.fieldName) !== v

        if (!opts.objectFieldName) {
          // this is the case when there is no lookup value, just a list, sometimes with int index
          objRef = {}
          _v(objRef, opts.valueField, v)
          if (opts.textField) _v(objRef, opts.textField, v)
          if (typeof el.textTemplate(objRef) !== 'undefined')
            objRef._display = el.textTemplate(objRef)
          k.dataSource.add(objRef)
          k.select(k.dataSource.data().length - 1)
          if (!valChanged) {
            el.value = v
            el.lastSetValue = v
            return v
          }
        } else if (
          _v(opts.model, opts.objectFieldName) &&
          v === _v(_v(opts.model, opts.objectFieldName), opts.valueField)
        ) {
          objRef = ow0.clone(_v(opts.model, opts.objectFieldName))
          if (typeof el.textTemplate(objRef) !== 'undefined')
            objRef._display = el.textTemplate(objRef)
          k.dataSource.add(objRef)
          k.select(k.dataSource.data().length - 1)
          if (!valChanged) {
            el.value = v
            el.lastSetValue = v
            return v
          }
        } else {
          if (ow0.dev) {
            var err =
              'model has no object value set on field, ' +
              opts.objectFieldName +
              ' but a populated value, ' +
              v +
              ' on field ' +
              opts.fieldName
            console.warn(err)
            ow0.popError('Bad Combobox', err)
          }

          objRef = {}
          _v(objRef, opts.valueField, v)
          _v(objRef, opts.textField, v)
          if (typeof el.textTemplate(objRef) !== 'undefined') objRef._display = v
          k.dataSource.add(objRef)
          k.select(k.dataSource.data().length - 1)
          if (!valChanged) {
            el.value = v
            el.lastSetValue = v
            return v
          }
        }
      }

      ow0.log(
        [
          'VAL: ---> combo.val(' + v + ')',
          el.isOpen ? 'open' : 'closed',
          'Elements loaded: ' + k.dataItems().length
          // 'Matches: ' + ,
        ],
        'combo',
        el
      )

      if (opts && opts.dataType && v !== null && v !== '') {
        if (opts.dataType === 'integer' || opts.dataType === 'int') {
          v = parseInt(v)
        } else if (
          opts.dataType === 'float' ||
          opts.dataType === 'decimal' ||
          opts.dataType === 'number'
        ) {
          v = parseFloat(v)
        }
      }

      if (k.value() !== v) k.value(v)
      el.lastSetValue = k.value()

      if (v === null) {
        objRef = null
        k.input.val('')
      }

      if (opts.model && opts.objectFieldName) {
        if (rec && el.lastSetValue !== _v(opts.model, opts.fieldName) && !opts.inGrid) {
          //issue #12711 - when re-populate the model is not updated for the combo
          opts.model = rec
        }
        var x = _v(opts.model, opts.objectFieldName)
        if (x && !Object.keys(x).length) objRef = null // in case of blank objects

        if (x) {
          objRef = x
          if (typeof el.textTemplate(objRef) !== 'undefined')
            objRef._display = el.textTemplate(objRef)
          else delete objRef._display

          // fixes for combobox when click on the first one and press enter, the selected item is disappear
          if (!opts.inGrid) {
            if (k.value() && el.isOpen && !opts.filter)
              ow0.log('VAL: NOT calling read!', 'combo', el)
            else {
              ow0.log('VAL: calling read!', 'combo', el)
              k.dataSource.read()
            }
          }
        }
      }

      // when the rec[objectFieldName] has no value but v has a value
      if (opts.objectFieldName && (!opts.model || !_v(opts.model, opts.objectFieldName))) {
        if (el.selectedItem()) {
          objRef = ow0.clone(el.selectedItem())
          if (objRef && !Object.keys(objRef).length) objRef = null
        } else {
          objRef = null
        }
      }
    } else {
      v = k.value() // reading the value out

      if (rec && opts.model !== rec) {
        if (opts.fieldName) _v(rec, opts.fieldName, v)

        if (opts.objectFieldName) {
          if (el.selectedItem()) {
            objRef = el.selectedItem()
            if (objRef && !Object.keys(objRef).length) objRef = null
          }
          _v(rec, opts.objectFieldName, objRef)
        }
      }

      if (opts && opts.dataType && v !== null && v !== '') {
        if (opts.dataType === 'integer' || opts.dataType === 'int') {
          v = parseInt(v)
        } else if (
          opts.dataType === 'float' ||
          opts.dataType === 'decimal' ||
          opts.dataType === 'number'
        ) {
          v = parseFloat(v)
        }
      }

      if (v && opts.objectFieldName && !el.selectedItem()) {
        objRef = _v(rec || opts.model, opts.objectFieldName)
        if (v !== _v(objRef, opts.valueField)) console.warn('NOT EQUAL ' + v)
      }
    }

    return v
  }

  var validate = el.validate
  el.validate = function (onInvalid, messageArray) {
    var result = validate.call(el, onInvalid)

    var obj = opts.objectFieldName ? _v(opts.model, opts.objectFieldName) : objRef

    if (k.text() && (!obj || !Object.keys(obj).length)) {
      if (k.selectedIndex > -1) {
        obj = ow0.clone(k.dataItem(k.selectedIndex)) // just in case it hasn't resolved
        if (obj && !Object.keys(obj).length) obj = null
      } else {
        onInvalid?.(opts.label, __('Invalid selection'), el, messageArray)
        return false
      }
    }
    if (
      opts.objectFieldName &&
      obj?._display &&
      obj._display !== k.input.val() &&
      _v(obj, opts.valueField) !== k.value()
    ) {
      onInvalid?.(opts.label, __('Invalid selection'), el, messageArray)
      return false
    }
    return result
  }

  owComboBox(el, opts)

  // Remove the owComboBox readData - that's for non-standard
  if (opts.fieldName)
    el.readData = function (rec) {
      var v = el.val(undefined, rec)
      if (_v(rec, opts.fieldName) !== v) _v(rec, opts.fieldName, v)
    }

  if (opts.popUp) {
    $(el).parent().addClass('k-dropdown-wrap basic-ed k-dropdown-wrap k-textbox k-widget')
    addPopUp(el, opts, k)
  }

  return el
}

exports.basicEditorInits['lookup'] = function (el, opts) {
  var kcb = { dataSource: {} }

  if (opts.value) kcb.value = opts.value
  if (opts.valueField) kcb.dataValueField = opts.valueField
  if (opts.textField) kcb.dataTextField = opts.textField

  if (opts.list) {
    if (typeof opts.list === 'string') {
      eval('opts.list=' + opts.list)
    }
    if (opts.list.length > 0) {
      // if it's an array of objects, use Text Value OR use first and second property
      if (typeof opts.list[0] === 'object') {
        opts.valueField = opts.valueField || 'Value'
        opts.textField = opts.textField || 'Text'

        kcb.dataValueField =
          kcb.dataValueField || typeof _v(opts.list[0], opts.valueField) !== 'undefined'
            ? opts.valueField
            : Object.keys(opts.list[0])[0]
        kcb.dataTextField =
          kcb.dataTextField || typeof _v(opts.list[0], opts.textField) !== 'undefined'
            ? opts.textField
            : Object.keys(opts.list[0])[1]
      }
    }
  }

  if (opts.inGrid) {
    kcb.valuePrimitive = true
  }

  kcb.dataSource.data = opts.list || ['no options supplied']
  kcb.select = function (e) {
    e.selectedItem = e.item ? k.dataItem(e.item) : null
    if (el.onselected) el.onselected(e)
    $(el).trigger('select', [e.selectedItem])
    $(el).trigger('ow-select', [e.selectedItem])
  }

  if (opts.dropdownTemplate) kcb.template = opts.dropdownTemplate

  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoComboBox(kcb)
  $(el).addClass(hiddenClasses)

  var k = $(el).data('kendoComboBox')
  if (opts.width) k.input.parent().parent().width(opts.width)
  k.list.width(opts.listWidth || 300)
  if ($(el).hasClass('w4') || $(el).hasClass('w3')) {
    k.list.width(328)
  }

  el.val = function (v, rec) {
    function correctType() {
      if (opts && opts.dataType && v !== null && v !== '') {
        if (opts.dataType === 'integer' || opts.dataType === 'int') {
          v = parseInt(v)
        } else if (
          opts.dataType === 'float' ||
          opts.dataType === 'decimal' ||
          opts.dataType === 'number'
        ) {
          v = parseFloat(v)
        }
      }
    }

    if (typeof v !== 'undefined') {
      k.value(v)
      correctType()

      ow0.log('setting: ' + v + ' of type: ' + typeof v + ' -> now ' + k.value(), 'combo')
      if (rec && opts.fieldName) {
        _v(rec, opts.fieldName, v)
      }
    }
    v = k.value()
    // ow0.log('-----------------' + v + ', ' + _v(el.selectedItem(), opts.valueField), 'combo');
    // if (el.selectedItem()) {
    //     v = _v(el.selectedItem(), opts.valueField);
    // }

    correctType()

    return v
  }

  owComboBox(el, opts)

  return el
}

exports.basicEditorInits['colorpicker'] = function (el, opts) {
  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoColorPicker({
    buttons: opts.showbuttons || false,
    clearButton: opts.clearButton || false,
    close() {
      $(el).trigger('ow-change') //update model value when value not changed on close
    },
    select() {},
    change(e) {
      e.selectedItem = e.value
      $(el).trigger('select', [e.selectedItem])
      $(el).trigger('ow-select', [e.selectedItem])
    }
  })
  $(el).addClass(hiddenClasses)

  var k = $(el).data('kendoColorPicker')

  el.val = function (v, rec) {
    if (typeof v !== 'undefined') {
      if (typeof v !== 'string') v = delphiColortoHex(v)

      k.value(v === '0' || v === '' ? 'transparent' : v)
      _v(rec, opts.fieldName, k.value())
    } else {
      setTimeout(() => {
        //used to update value in model field
        if (rec && opts.fieldName)
          _v(rec, opts.fieldName, typeof v !== 'string' ? hextoDelphiColor(v) : v)
      }, 1)
    }
    v = k.value() || 'transparent'

    return v
  }

  return el
}

exports.basicEditorInits.multiSelect = function (el, opts) {
  var dataSource = {}

  if (opts.url) {
    dataSource = {
      serverFiltering: true,
      transport: {
        read: {
          type: 'GET',
          dataType: 'json',
          url: opts.url
        },
        parameterMap(data, type) {
          return type === 'read'
            ? {
                raw: true,
                sub: data.filter && data.filter.filters[0] && data.filter.filters[0].value // || $this.val(),
              }
            : data
        }
      }
    }
  } else dataSource = { data: opts.list }

  opts.textField = opts.textField || 'Text'
  opts.valueField = opts.valueField || 'Value'
  opts.autoClose = opts.autoClose !== false
  opts.clearButton = opts.clearButton !== false

  var hiddenClasses = ''
  if (opts.hideClassesFromKendo) {
    hiddenClasses = el.className
    $(el).attr('class', 'basic-ed')
  }
  $(el).kendoMultiSelect({
    placeholder: opts.placeholder || 'Select items...',
    dataTextField: opts.textField,
    dataValueField: opts.valueField,
    autoBind: false,
    width: 300,
    dataSource: dataSource,
    clearButton: opts.clearButton,
    autoClose: opts.autoClose
  })
  $(el).addClass(hiddenClasses)

  var k = $(el).data('kendoMultiSelect')
  k.value([])

  var objRef = []

  k.bind('select', e => objRef.push(e.dataItem))
  k.bind(
    'deselect',
    e => (objRef = objRef.filter(x => _v(e.dataItem, opts.valueField) !== _v(x, opts.valueField)))
  )
  k.bind('change', () => qc(el).trigger('change').trigger('ow-change', el.val()))

  if (opts.width) $(el).parent().addClass(opts.width)

  el.val = function (v, rec) {
    if (typeof v !== 'undefined') {
      if (opts.objectFieldName && rec) objRef = _v(rec, opts.objectFieldName) || []
      k.value(v)
    } else if (opts.objectFieldName && rec) _v(rec, opts.objectFieldName, objRef)

    return k.value(v)
  }

  if (opts.popUp) addPopUp(el, opts, k)

  el.odisable = v => k.enable(v === false)

  return el
}

document.body.addEventListener('click', e => {
  if (e.target.classList.contains('k-multiselect-wrap')) qc(e.target).find('.k-input')[0]?.focus()
})

exports.basicEditorInits.uda = function (el, opts) {
  opts.url = '/data/report/lookup/uda'
  opts.edType = 'multiSelect'
  $(el).addClass('uda-control')
  opts.popUp = {
    url: 'views/uda-select.js',
    name: __('UserDefinedAttribute'),
    mode: 'multi-select',
    modal: true,
    width: 600,
    height: 400,
    callback($win, viewdata) {
      if (viewdata.result) {
        $(el)
          .parent()
          .removeClass('uda-and')
          .removeClass('uda-or')
          .addClass('uda-' + (viewdata.result.operator ? 'and' : 'or'))

        el.val(
          (viewdata.result.filters || []).map(x => {
            x.Text = x.UDAInfo.Name + ':' + x.CodeValue
            x.Value = x.UdaID + ':' + x.Code
            return x
          })
        )
        $(el).trigger('change')
      }
    }
  }
  exports.basicEditorInits.multiSelect(el, opts)

  $(el).parent().addClass('uda-or')
  $(el).parent().find('> div').append('<a>  </a>')
  $(el)
    .parent()
    .find('a')
    .on('click', e => {
      $(el).parent().toggleClass('uda-or').toggleClass('uda-and')
      $(el).trigger('change')
      e.stopPropagation()
      return false
    })

  el.readFilter = function (filters) {
    if (el.val().length)
      filters.push({
        field: opts.fieldName || 'ProductID',
        operator: $(el).parent().hasClass('uda-and') ? 'uda-and' : 'uda-or',
        value: el.val()
      })
    return filters
  }

  return el
}

/**
 *
 *
 * @param {any} el
 * @param {any} opts
 * @param {any} opts.indentField - the item fieldname in the list that indicates indentation level
 * @returns
 */
exports.basicEditorInits['checklistbox'] = function (el, opts) {
  $(el).addClass('checklistbox').addClass('ow-checklist')

  opts.textField = opts.textField || 'Text'
  opts.valueField = opts.valueField || 'Value'

  function buildCheckBox(item) {
    var chkboxId = safeId(_v(item, opts.valueField))
    return (
      '<a data-owauto-opts="' +
      objToAttr([{ init: 'basic-ed', edType: 'check' }]) +
      '" href="#" class="ow-check owauto' +
      (opts.indentField ? ' indent-' + item[opts.indentField] : '') +
      '" id="chk' +
      chkboxId +
      '">' +
      item[opts.textField] +
      '</a>'
    )
  }

  el.list = null
  el.dataList = []
  el.url = url => loadList(url)

  function buildList() {
    $(el).empty()
    var listHtml = el.list.map(function (item) {
      return buildCheckBox(item)
    })
    $(listHtml.join('\r\n')).appendTo($(el))
    updateChecks()
    $(el).trigger('ow-checklist-populate')
  }

  function updateChecks() {
    // uncheck all
    $(el)
      .find('a')
      .forEach(x => x.val(false))
    ;(el.dataList || []).forEach(function (d) {
      var chkboxId = ow0.safeId(_v(d, opts.valueField))
      if (d.checked || typeof d.checked === 'undefined') {
        var chk = $(el).find('#chk' + chkboxId)
        if (chk.length) chk[0].val(true)
      }
    })
  }

  function loadList(newUrl) {
    return $ajax({ url: newUrl || opts.url })
      .then(data => {
        el.list = data.Data || data.data || data
        buildList()
        return el.list
      })
      .catch(err => ow0.popError(err))
  }

  const readChecks = () => {
    var result = []

    el.list.forEach(function (d) {
      var chkboxId = ow0.safeId(_v(d, opts.valueField))
      if (
        $(el)
          .find('#chk' + chkboxId)[0]
          .val()
      )
        result.push(d)
    })

    return result
  }

  el.val = function (v) {
    if (typeof v !== 'undefined') {
      el.dataList = v || []
      updateChecks()
    } else {
      if (el.list === null) {
        return el.dataList
      }
      return readChecks()
    }
  }

  $(el).on('command-tickall', function () {
    $(el)
      .find('a.ow-check')
      .forEach(chk => !chk.classList.contains('disabled') && chk.val(true))
  })

  $(el).on('command-untickall', function () {
    $(el)
      .find('a.ow-check')
      .forEach(chk => !chk.classList.contains('disabled') && chk.val(false))
  })

  loadList()
  return el
}

/**
 * takes nested data item.items array - expand and contract, checking parent makes children the same.
 * The value data (ie. checks) is not nested, the "schema" is
 *
 * @param {any} el
 * @param {any} opts
 * @param {any} opts.indentField - the item fieldname in the list that indicates indentation level
 * @returns
 */
exports.basicEditorInits['checklisttree'] = function (el, opts) {
  $(el).addClass('checklisttree').addClass('ow-checklist')

  opts.textField = opts.textField || 'Text'
  opts.valueField = opts.valueField || 'Value'

  function buildCheckBox(item) {
    var kids = ''
    var isAnyKidDisabled = false
    if ((item.items || []).length) {
      kids =
        '<a class="hide-kids" href="#" onclick="var p = this; $(p.parentNode).toggleClass(\'closed\');return false;"><i class="fa fa-caret-down"></i><i class="fa fa-caret-up"></i></a>' +
        '<div class="tree-kids">' +
        item.items.map(kid => buildCheckBox(kid)).join('\r\n') +
        '</div>'
      isAnyKidDisabled = item.items.some(x => x.disabled)
    }

    var chkboxId = ow0.safeId(_v(item, opts.valueField))
    item.disabled = kids ? isAnyKidDisabled : item.disabled
    var result =
      '<a data-owauto-opts="' +
      objToAttr([{ init: 'basic-ed', edType: 'check' }]) +
      '" href="#" class="ow-check owauto' +
      (kids ? ' parent-check' : '') +
      '' + // ( opts.indentField ? ' indent-' + item[opts.indentField] : '' ) +
      '" id="chk' +
      chkboxId +
      //_v(item, opts.valueField) +
      '" ' +
      (item.disabled ? 'disabled' : '') +
      '>' +
      item[opts.textField] +
      '</a>'

    result = result + kids

    return '<div class="tree-node-wrap' + (kids ? ' has-kids' : '') + '">' + result + '</div>'
  }

  el.list = null
  el.dataList = []

  function buildList() {
    const listHtml = el.list.map(item => buildCheckBox(item)).join('\r\n')
    $(listHtml).appendTo($(el))

    $(el)
      .find('a.ow-check')
      .on('ow-change', function () {
        var p = this
        // update kids
        $(p.parentNode)
          .find('a.ow-check')
          .forEach(x => {
            var t = x.attributes['disabled']
            if (!t) x.val(p.val())
          })
      })

    updateChecks()
  }

  function updateChecks() {
    console.log('updateChecks')

    // uncheck all
    $(el)
      .find('a.ow-check')
      .forEach(x => x.val(false))
    ;(el.dataList || []).forEach(function (d) {
      if (d.checked || typeof d.checked === 'undefined') {
        var chkboxId = ow0.safeId(_v(d, opts.valueField))

        $(el)
          .find('#chk' + chkboxId)
          .forEach(chk => chk.val(true))
      }
    })

    $(el)
      .find('.parent-check')
      .each(function (i, chk) {
        var allChecked = $(chk).parent().find('.ow-check:not(.parent-check):not(.on)').length === 0
        console.log(
          'Parent check ' +
            chk.innerHTML +
            ' has ' +
            $(chk).parent().find('.ow-check:not(.parent-check):not(.on)').length +
            ' unchecked kids and ' +
            $(chk).parent().find('.ow-check:not(.parent-check).on').length +
            ' checked '
        )
        chk.val(allChecked)
      })
  }

  function loadList() {
    return $ajax({ url: opts.url })
      .then(data => {
        el.list = data
        buildList()
        return el.list
      })
      .catch(err => ow0.popError(err))
  }

  function readChecks() {
    var result = []

    function readItemChecks(items) {
      ;(items || []).forEach(function (d) {
        var chkboxId = ow0.safeId(_v(d, opts.valueField))
        if (
          $(el)
            .find('#chk' + chkboxId)[0]
            .val()
        )
          result.push(d)

        if (d.items) readItemChecks(d.items)
      })
    }
    readItemChecks(el.list)

    return result
  }

  el.val = function (v) {
    if (typeof v !== 'undefined') {
      el.dataList = v || []
      updateChecks()
    } else {
      if (el.list === null) return el.dataList
      return readChecks()
    }
  }

  const setTicks = v =>
    $(el)
      .find('a.ow-check')
      .forEach(chk => {
        if (!chk.attributes['disabled']) {
          chk.val(v)
        }
      })

  $(el).on('command-tickall', () => setTicks(true))
  $(el).on('command-untickall', () => setTicks(false))

  if (opts.url) loadList()
  else if (!opts.list) console.warn('No list or url found in checktreelist options')
  else {
    el.dataList = el.list = opts.list
  }

  return el
}

exports.enterKeyHandler = function (el) {
  $(el).on('enter-key', function () {
    var defaultButtons = $(el).find("[data-default-button='true']")
    if (!defaultButtons.length) defaultButtons = $(el).find('button')
    return false
  })

  $(el).on('keypress', function enterKeyTrap(e) {
    if (e.which === 13) {
      // if it's a button just click as normal
      if ({ button: 1, a: 1 }[document.activeElement.tagName.toLowerCase()]) return true
      $(el).trigger('enter-key')
    }
  })
  return el
}
exports['enter-key-handler'] = exports.enterKeyHandler

exports.basicEditorInits['sub-search'] = function (el, opts) {
  exports.basicEditorInits['text'](el, opts)
  $(el).on('keyup', function () {
    var input = el
    if (input.searchTimeOut) {
      // if already one waiting, cancel
      clearInterval(input.searchTimeOut)
      input.searchTimeOut = null
    }
    input.searchTimeOut = setTimeout(
      () =>
        el
          .myWin()
          .find(
            opts.gridQuery || '#' + $(el).data().fieldFor + ' , .iden-' + $(el).data().fieldFor
          )[0]
          .search(input.value),
      600
    )
  })
  return el
}

exports.basicEditorInits['current-store'] = function (el, opts) {
  opts.edType = 'lookup-ajaxtextvalue'
  opts.url = opts.url || '/data/report/lookup/store'
  $(el).addClass('w4') //default to longer width

  opts.valueTextDisplay = true

  if (ow0.currentStore.StoreType === 2) opts.noFilter = true

  exports.basicEditorInits['lookup-ajaxtextvalue'](el, opts)

  if ($(el).hasClass('ow-filter-control')) {
    opts.filterValueIfEmpty = opts.filterValueIfEmpty || -1
    exports.basicEditorInits.filterControl(el, opts)
  }

  if (ow0.currentStore.StoreType !== 2) {
    el.val(ow0.currentStore.StoreID)
  }
  el.odisable(ow0.currentStore.StoreType !== 2)
  var firstSet = false
  $(el).on('ow-data-bound', function () {
    if (!firstSet) {
      if (!$(el)[0].selectedItem() && ow0.currentStore.StoreType !== 2)
        el.val(ow0.currentStore.StoreID)
      firstSet = true
    }
  })
  return el
}

// buttonstrip - inits buttonstrip and sets up bindings for buttons
exports.commandButton = function (el) {
  // register shortcut(s)
  const cmd = $(el).data().command

  var isDefaultShortcut =
    cmd === 'save' ||
    cmd === 'refresh' ||
    cmd === 'delete' ||
    cmd === 'filter-change' ||
    cmd === 'close'

  var targetShortcut = $(el).data().shortcut
  if (isDefaultShortcut || targetShortcut) {
    el.myWin().on('keydown', function (e) {
      //Ctrl-D - Reserved for browser bookmark
      //F1 - Reserved for new Tab
      //F3 - Find
      //F6 - URL focus

      //if (e.which && e.altKey) {
      //if (e.which === 120 && $(el).data('command')==="filter-change") $(el).click(); //F9
      if (e.which === 113 && cmd === 'save') {
        el.focus()
        el.click()
      } //F2  //added $(el).focus(); for when empty row in grid is added, causes save: failed
      if (e.which === 118 && cmd === 'refresh') {
        el.click()
      } //F7
      // if (e.which === 115 && $(el).data('command')==="close") { $(el).click(); } //F4
      // if (e.which === 27) {
      //     e.preventDefault();
      //     e.stopImmediatePropagation();
      //     e.stopPropagation();
      //     setTimeout(() => $(el).myWin().closeForm(), 200);
      // } //ESC
      //}

      //With Alt
      if (e.which && e.altKey) {
        if (e.which === 114 && cmd === 'delete') el.click() //F3
        //if (e.which === 67) { $(el).myWin().closeForm(); } //Alt-C

        if (targetShortcut) {
          if (!targetShortcut.useCtrl && targetShortcut.key === e.which) el.click()

          // Ctrl + Alt + key
          if (e.ctrlKey) if (targetShortcut.useCtrl && targetShortcut.key === e.which) el.click()
        }
      }
    })
  }

  $(el).on(
    'click',
    debounce(
      () => {
        var cmd = $(el).data().command
        var $w = el.myWin()
        var target = null
        var targetRef = $(el).data('targetRef') ?? $(el).data('fieldFor')
        if (targetRef) target = $w.find('.iden-' + targetRef + ', #' + targetRef)[0]
        qc(target ?? el).trigger('command-' + cmd, el)
      },
      el.opts?.debounce ?? 500,
      true
    )
  )

  var $top = el.myWin()

  $top.on('ow-datastatechange', function (e, state) {
    if ($(el).attr('data-target-ref') && state.dsName !== $(el).attr('data-target-ref')) return

    var userRole = $top.viewdata.userRole || {}

    if (
      $(el).data('command') !== 'close' &&
      $(el).data('command') !== 'refresh' &&
      (!userRole ||
        (!userRole.CanWrite &&
          ($(el).data('command') === 'new' ||
            (!userRole.CanRead && $(el).data('command') === 'edit') ||
            $(el).data('command') === 'copy' ||
            $(el).data('command') === 'save' ||
            $(el).data('command') === 'add-row')) ||
        (!userRole.CanDelete && $(el).data('command') === 'delete'))
    ) {
      $(el).off()
      el.odisable(true)
      return
    }
    if ($(el).data('command') === 'save' || $(el).data('command') === 'cancel') {
      var disableSaveCancel = !state.editing
      el.odisable(disableSaveCancel)
    }
  })

  if ($(el).data('command') === 'advanced') {
    el.myWin().addClass('hide-filters')
    $(el).hide()
  }
}
exports['command-button'] = exports.commandButton

// default command button states.
if (typeof $ !== 'undefined')
  $('.main-content').on('ow-datastatechange', '.win-con .ow5 [data-command]', function (e, state) {
    var el = e.target
    var $top = el.myWin()

    if ($(el).attr('data-target-ref') && state.dsName !== $(el).attr('data-target-ref')) return

    var userRole = $top.viewdata.userRole || { noHave: true }

    var command = $(el).data('command')
    if (
      command !== 'close' &&
      command !== 'refresh' &&
      (userRole.noHave ||
        (!userRole.CanWrite &&
          (command === 'new' ||
            (!userRole.CanRead && command === 'edit') ||
            command === 'copy' ||
            command === 'save' ||
            command === 'add-row')) ||
        (!userRole.CanDelete && command === 'delete'))
    ) {
      $(el).off() // todo: this ain't righ',  the click handler should check if the button is disabled, in fact all of this could be handled in a disconnected way. styles?
      el.odisable(true)
      return
    }
    if (command === 'save' || command === 'cancel') {
      var disableSaveCancel = !state.editing
      el.odisable(disableSaveCancel)
    }
  })

exports.basicEditorInits.filterControl = function (el, opts) {
  opts.filter = true

  $(el).addClass('ow-filter-control')

  if (!el.readFilter || el.readFilter === HTMLElement.prototype.readFilter)
    el.readFilter = function (filters) {
      var v = el.val()

      if ((v !== '' && v !== null) || typeof opts.filterValueIfEmpty !== 'undefined') {
        v = v.toISOString ? v.toJSON() : v
        if (opts.filterValueIfEmpty && !v) {
          v = opts.filterValueIfEmpty
        }
        if (el.opts.edType === 'lookup-ajaxtextvalue') {
          if (
            !el.selectedItem() &&
            !(
              el.opts.objectFieldName &&
              el.opts.model &&
              _v(_v(el.opts.model, el.opts.objectFieldName), el.opts.valueField) === v
            )
          ) {
            if (el.opts.textFilterField) {
              filters.push({
                field: opts.textFilterField,
                operator: 'contains',
                value: v
              })
              return
            } else if (
              el.opts.objectFieldName &&
              el.opts.textField &&
              el.opts.textField !== 'Text'
            ) {
              filters.push({
                field: el.opts.objectFieldName + '.' + opts.textField,
                operator: 'contains',
                value: v
              })
              return
            }
          }
        } else if (opts.edType === 'lookup') {
          if (!el.selectedItem()) {
            if (opts.fieldName) {
              filters.push({
                field: opts.fieldName,
                operator: 'in',
                value: opts.list
                  .filter(function (item) {
                    return item.Text.toLowerCase().indexOf(v.toLowerCase()) > -1
                  })
                  .map(function (x) {
                    return x.Value
                  })
              })
              return
            }
          }
        } else if (opts.edType === 'date') {
          el.value = el.resolve(el.value, true)
          if (opts.fieldName) {
            filters.push({
              field: opts.fieldName,
              operator: opts.op || 'sameDay',
              value: el.val().toJSON()
            })
            return
          }
        } else if (opts.edType === 'datetime') {
          el.value = el.resolve(el.value, true)
          if (opts.fieldName) {
            filters.push({
              field: opts.fieldName,
              operator: opts.op || 'eq',
              value: el.val().toJSON()
            })
            return
          }
        }
        filters.push({
          field: opts.fieldName,
          operator: opts.op || 'eq',
          value: v
        })
      }
    }

  el.fieldFor = function () {
    var ref = $(el).data('fieldFor') || $(el).attr('data-filter-for')
    return el.myWin().find('#' + ref + ' , .iden-' + ref)[0]
  }

  $(el).on('change', () => {
    $(el.fieldFor()).trigger('ow-filter-changed')
  })

  if (opts.edType === 'check')
    $(el).on('click', () => $(el.fieldFor()).trigger('ow-filter-changed'))
  else
    el.target = function () {
      return el
        .myWin()
        .find('#' + $(el).data('targetRef') + ' , .iden-' + $(el).data('targetRef'))[0]
    }
}

/**
 * Initializes buttons that are mutually exclusive filters on a field.  The buttons should already exist in the scope
 * see taskschedulerhistoryheader-overview
 *
 * @param {jQuery} $top - jQuery wrapper of the top of the html scope, usually the ow view window
 * @param {Object} opts - the options for setting up the buttons
 * @param {String} opts.fieldName - the field that these buttons filter on
 * @param {String} opts.targetRef - the grid (or other dataset controller) that is filtered by this
 * @param {String} opts.default [optional]- the filter command set at the start.
 * @param {Object} opts.map object with button commands as property names
 * @param {Any} opts.map.<command> string|number|boolean|date or object.  eg "lessthan2": {op: 'lt', value: 2} for button with data-command="lessthan2"
 */
exports.toggleFilters = function ($top, opts) {
  var commands = Object.keys(opts.map)
  var selector = commands.map(cmd => '.command-button[data-command=' + cmd + ']').join(',')

  var $togs = $top.find(selector)
  $togs.forEach(el => {
    var cmd = $(el).data('command')
    $(el).attr('data-field', opts.fieldName)
    $(el).attr('data-field-for', opts.targetRef)

    el.opts = opts
    var filterOpts = { fieldName: opts.fieldName }
    if (typeof opts.map[cmd] === 'object' && opts.map[cmd]) {
      // if it has object as value extract the op and value
      filterOpts.op = opts.map[cmd].op || 'eq'
      opts.map[cmd] = opts.map[cmd].value
    }
    exports.basicEditorInits.filterControl(el, filterOpts)
    el.val = function () {
      return $(el).hasClass('halo') ? el.opts.map[cmd] : null
    }
    $(el).on('click', function () {
      $togs.removeClass('halo')
      $(this).addClass('halo').trigger('change')
    })
    if (cmd === opts.default) $(el).addClass('halo')
  })
}

exports.basicEditorInits['text-item'] = function (el, opts) {
  if (opts.filter) {
    exports.basicEditorInits['text'](el, opts)
    return el
  }

  var $top = el.myWin()
  var $td = $(el).closest('td')

  if (opts.showIcons) $td.addClass('use-icons')

  opts.msgs = opts.msgs || {}

  opts.msgs.IncorrectMatch = opts.msgs.IncorrectMatch || __('Incorrect match')
  opts.msgs.NoMatches = opts.msgs.NoMatches || __('No matches')

  exports.basicEditorInits['text'](el, opts)

  var r = $td.parent().index()
  var c = $td.index()

  opts.matchFirst = opts.matchFirst !== false // default true

  var $poplist = $(
    '<div class="poplist fit"><div class="fit overlay"></div><select></select></div>'
  )
  var $select = $poplist.find('select')
  $select.kendoListBox({
    dataSource: [],
    template: opts.itemTemplate,
    width: 300
  })
  var k = $select.data('kendoListBox')
  var $ul = $poplist.find('ul')

  var cancelPoplist = function () {
    el.closeList()
    if (opts.inGrid) opts.grid.focusCell($td)
  }

  function initList() {
    $poplist.find('.overlay').on('click', cancelPoplist)
    $ul.on('keyup', function (e) {
      if (e.which === 27) cancelPoplist() // esc
      if (e.which === 120) {
        cancelPoplist()
        if (el.openPopUp) el.openPopUp()
      }
    })

    $ul.on('keydown', function (e) {
      if (e.which === 13) {
        // enter
        var item = k.dataItem(k.select()) // response[$(e.target).index()];
        selectItem(item)
        setTimeout(() => opts.grid.moveNextCell($td.parent().index(), $td.index(), false, true), 1)
        return
      }

      if (e.which === 9) {
        // tab
        let item = k.dataItem(k.select()) // response[$(e.target).index()];
        selectItem(item)
        if (opts.inGrid) opts.grid.moveNextCell($td.parent().index(), $td.index(), false, true)
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()
        return
      }
    })

    $ul.on('click', 'li', e => {
      if ($(e.target).is('ul')) return
      selectItem(k.dataItem(e.target))
    })

    $ul.on('blur', () => {
      cancelPoplist()
      return false
    })

    $poplist.on('focusin', function (e) {
      ow0.log('suppressing focusin on poplist', 'focus')
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      return false
    })
  }

  el.openList = function (data) {
    if (typeof data !== 'undefined') k.dataSource.data(data)
    $top.children().first().append($poplist)
    el.popListOpen = true

    initList()

    // center
    ;(function ($c) {
      $c.css({
        position: 'absolute',
        top: $poplist.height() / 2 + 'px',
        left: $poplist.width() / 2 + 'px',
        margin: '-11.574em 0 0 -11.574em'
      })
    })($poplist.find('.k-widget'))
    k.select(k.items().first())
  }

  el.closeList = function () {
    $poplist.remove()
    el.popListOpen = false
  }

  var clearItem = function () {
    $td.removeClass('match-found').removeClass('match-notfound').removeClass('matches-found')

    if (opts.objectFieldName) {
      if (_v(opts.model, opts.objectFieldName) !== null) {
        _v(opts.model, opts.objectFieldName, null)
        $(el).trigger('ow-change')
      }
    }
    $(el).trigger('ow-change')
  }

  el.matchedOn = opts.model ? _v(opts.model, opts.fieldName) : ''
  el.matchList = opts.objectFieldName && opts.model ? [_v(opts.model, opts.objectFieldName)] : []
  if (typeof el.matchList[0] === 'undefined') el.matchList = []
  if (el.matchList.length === 0) el.matchedOn = ''

  const selectItem = function (item) {
    if (item) {
      item = ow0.clone(item)
      delete item._display
    }
    if (el.popListOpen) el.closeList()
    if (opts.objectFieldName) _v(opts.model, opts.objectFieldName, item)
    if (!item) el.value = ''
    el.val(_v(item, opts.valueField))
    _v(opts.model, opts.fieldName, el.val())
    el.matchedOn = !item ? '' : el.val()
    $td.removeClass('match-found').removeClass('match-notfound').removeClass('matches-found')
    $td.addClass(item ? 'match-found' : 'match-notfound')
    $(el).trigger('ow-change')
  }
  el.selectItem = selectItem

  $(el).on('change', e => {
    // suppress onchange event if poplist is showing or enter key triggered it
    if (el.popListOpen || el.ignoreNextChange || el.popUpOpen) {
      el.ignoreNextChange = false
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      return false
    }

    findMatches()
  })

  el.requestTabOut = function () {
    findMatches()
    return el.val() !== '' || el.matchList.length === 1
  }

  function findMatches(then) {
    if (el.val() === el.matchedOn) {
      $td.addClass('match-found')
      return then ? then() : true
    }

    if (el.val() === '') {
      el.matchList = []
      return then ? then() : true
    }

    if (opts.inGrid)
      $td
        .removeClass('match-found')
        .removeClass('match-notfound')
        .removeClass('matches-found')
        .addClass('ow-loading')

    el.ignoreNextChange = true

    $.ajax({
      async: false,
      type: 'GET',
      url: typeof opts.url === 'function' ? opts.url() : opts.url,
      contentType: 'application/json',
      dataType: 'json',
      data: {
        sub: el.val()
      },
      success(response) {
        $td.removeClass('ow-loading')

        el.matchList = response

        if (response.length === 0) {
          el.selectItem(null)
          return ow0.popInvalid(opts.msgs.NoMatches)
        } else if (response.length === 1) {
          el.selectItem(response[0])
        } else {
          $td.addClass('matches-found')
          if (opts.matchFirst) el.selectItem(response[0])
        }
        $(el).trigger('ow-change')
        if (then) then()
      },
      error(err) {
        el.matchList = []
        $(el).trigger('ow-change')
        $(el).removeClass('ow-loading').removeClass('match-notfound')
        ow0.popError('The server returned an error looking up item matching text, Error: ' + err)
        $(el).myWin().progress(false)
      }
    })
  }

  el.findMatches = findMatches

  $(el).on('keydown', function (e) {
    if (e.which === 13) {
      if (el.val() === '') return

      _v(opts.model, opts.fieldName, el.value) // prevents new blank row being deleted.
      findMatches(() => el.matches.length > 1 && el.openList(el.matches))
      return killEvent(e)
    }

    if (e.which === 9) {
      if (el.val() === '') {
        // clear?
        clearItem()
        return
      }

      _v(opts.model, opts.fieldName, el.value) // prevents new blank row being deleted.

      const afterMatching = () =>
        el.matchList.length && opts.grid.moveNextCell(r, c, e.shiftKey, true)

      findMatches(afterMatching)

      return killEvent(e)
    }
  })

  var validate = el.validate
  el.validate = function (onInvalid) {
    var result = validate.call(el, onInvalid)
    findMatches()

    if ((opts.required && !el.val()) || el.matchList.length !== 1) {
      onInvalid(opts.name, opts.msgs.IncorrectMatch, el)
      return false
    }

    return result
  }
}

exports.basicEditorInits['text-unique-id'] = function (el, opts) {
  exports.basicEditorInits['text'](el, opts)

  if (opts.filter) return el

  var $td = $(el).closest('td')
  var $g = $(el).closest('.k-grid, .ow-grid')
  if (opts.showIcons) $td.addClass('use-icons')

  opts.url = opts.url || 'data/' + $g[0].opts.name + '/lookup/duplicateid'

  $(el).on('change', () => isUnique())
  el.requestTabOut = () => isUnique() && el.val() !== ''

  // async call to server
  function isUnique() {
    if (el.val() === el.matchedOn) {
      $td.addClass('match-found')
      return true
    }

    if (el.val() === '') return true

    if (opts.inGrid)
      $td.removeClass('match-found').removeClass('match-notfound').addClass('ow-loading')

    $.ajax({
      async: false,
      type: 'GET',
      url: typeof opts.url === 'function' ? opts.url() : opts.url,
      contentType: 'application/json',
      dataType: 'json',
      data: {
        id: el.val()
      },
      success(response) {
        $td.removeClass('ow-loading')
        if (response.length === 0) {
          $td.addClass('match-notfound')
          return ow0.popInvalid(__('Duplicate ID'))
        } else if (response.length === 1) {
          el.matchedOn = el.val()
          $td.addClass('match-notfound')
        }
        $(el).trigger('ow-change')
      },
      error(err) {
        el.matchList = []
        $(el).trigger('ow-change')
        $(el).removeClass('ow-loading').removeClass('match-notfound')
        ow0.popError('The server returned an error looking up duplicate ID, Error: ' + err)
        $(el).myWin().progress(false)
      }
    })

    return el.matchedOn === el.val()
  }

  el.isUnique = isUnique

  $(el).on('keydown', e => {
    if (e.which === 9) {
      if (el.val() === '') return
      _v(opts.model, opts.fieldName, el.value) // prevents new blank row being deleted.
      if (el.matchedOn !== el.val() && !isUnique()) return killEvent(e)
    }
  })

  var validate = el.validate
  el.validate = function (onInvalid) {
    var result = validate.call(el, onInvalid)
    if ((opts.required && !el.val()) || !el.isUnique()) {
      onInvalid(opts.name, __('Duplicate ID'), el)
      return false
    }
    return result
  }
}

exports.basicEditorInits.fileUpload = function (el, opts) {
  var file
  opts = opts || {}

  $(el).attr('data-role', 'upload')
  if (!el.parent().hasClass('k-button')) $(el).wrap("<div class='k-button'></div>")

  var $elWrapper = $(el).parent()
  $(
    '<div class="progress" style="display:none"><div class="progress-bar" id="progressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div></div>'
  ).appendTo($elWrapper)

  const onSubmit = function () {
    var data = new FormData()
    data.append('saveAs', opts.saveAs ? opts.saveAs : file && file.name)
    if (opts.dirname) data.append('dirname', opts.dirname)
    if (opts.maxFileSize) data.append('maxFileSize', opts.maxFileSize)
    data.append('fileInput', file)
    if (window.csrfToken) data.append('x-csrf-token', window.csrfToken)

    function update_progressbar(value) {
      $elWrapper
        .find('#progressBar')
        .css('width', value + '%')
        .html(value + '%')
      if (value === 0) {
        $elWrapper.find('.progress').css({ width: '0%', float: 'unset' })
        $(el).css({ width: '100%', float: 'unset' })
        $elWrapper.find('.progress').hide()
      } else {
        $elWrapper.find('.progress').css({ width: '18%', float: 'right' })
        $(el).css({ width: '80%', float: 'left' })
        $elWrapper.find('.progress').show()
      }
    }

    var ajax = new XMLHttpRequest()
    ajax.upload.addEventListener(
      'progress',
      e => update_progressbar(Math.round((e.loaded / e.total) * 100)),
      false
    )

    ajax.addEventListener(
      'load',
      e => {
        if (e.target.responseText.toLowerCase().indexOf('error') >= 0) {
          ow0.popError(__('Error'), e.target.responseText, 5000)
          $(el).trigger('ow-file-uploaderror', [e])
        } else $(el).trigger('ow-file-uploaded', [e.target])

        update_progressbar(0)
        $(el).val('')
      },
      false
    )

    ajax.addEventListener(
      'error',
      e => {
        ow0.popError(__('Error'), __('Upload Failed'))
        $(el).trigger('ow-file-uploaderror', [e])
        update_progressbar(0)
      },
      false
    )

    ajax.addEventListener(
      'abort',
      e => {
        ow0.popError(__('Error'), __('Upload Aborted'))
        $(el).trigger('ow-file-uploaderror', [e])
        update_progressbar(0)
      },
      false
    )

    $(el).trigger('ow-file-uploadstart')
    ajax.open(
      window.usePUT ? 'PUT' : 'POST',
      '/data/fileupload?' +
        $param({
          ...(window.csrfToken ? { _csrf: window.csrfToken } : {}),
          ...(opts.dirname ? { dirname: opts.dirname } : {}),
          ...(opts.saveAs ? { saveas: opts.saveAs } : {})
        })
    )
    ajax.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    if (sessionStorage.getItem('sessionid')) {
      ajax.setRequestHeader('sessionid', sessionStorage.getItem('sessionid'))
    }
    ajax.send(data)
  }

  el.upload = function () {
    var me = this

    file = me[0].files[0]
    if (file) {
      var fileSize = file.size / 1024 / 1024
      if (opts && opts.maxFileSize && fileSize > opts.maxFileSize) {
        ow0.popError(
          __('Error'),
          __('Filesize should not greater than ' + opts.maxFileSize + ' M'),
          5000
        )
        return
      }
    }

    var reader = new FileReader()
    reader.onload = onSubmit
    reader.readAsDataURL(file)
  }

  el.saveAs = v => (opts.saveAs = v)

  return el
}

exports.basicEditorInits.subEd = function (el, opts) {
  var o = el

  var rec = {}
  function newRec() {
    return {}
  }

  el.val = function (v) {
    if (typeof v !== 'undefined') {
      rec = v || newRec({})
      return exports.populateBasicEds(el, opts.fieldName, rec)
    }

    exports.readBasicEds(el, opts.fieldName, rec)
    return rec
  }

  el.validate = function (onInvalid) {
    return exports.validateBasicEds(el, opts.fieldName, undefined, onInvalid)
  }

  return o
}

/**
 *  returns html string for adding a control to the page dynamically on client side
 *  id
 *  label
 *  dsName - data controller name for reading/populating ed controls
 *  edOpts - basicEd options, see above
 *
 */
exports.ed = function (id, label, dsName, edOpts, noAutos) {
  edOpts.init = edOpts.init || 'basic-ed'

  edOpts.classes = edOpts.classes || ''
  if (!noAutos && edOpts.classes.indexOf('owauto') === -1)
    edOpts.classes = edOpts.classes + ' owauto'
  if (edOpts.half && edOpts.classes.indexOf('short') === -1)
    edOpts.classes = edOpts.classes + ' short'

  var slabel = ''
  if (!edOpts.noLabel) slabel = '<label class="resource_label">' + label + '</label>'

  var inner =
    edOpts.edType === 'check'
      ? '<a' +
        (noAutos === true ? '' : ' data-owauto-opts="' + objToAttr([edOpts]) + '"') +
        ' class="ow-check ' +
        (edOpts.value ? 'on ' : '') +
        edOpts.classes + // ( opts.indentField ? ' indent-' + item[opts.indentField] : '' ) +
        '" id="' +
        id +
        '"  href="#" data-field-for="' +
        dsName +
        '" >' +
        // label +
        '</a>'
      : [
          edOpts.edType === 'weekdays' || edOpts.edType === 'months' || edOpts.edType === 'radio'
            ? '<div'
            : '<input',
          'id="' + id + '"',
          'class="' + edOpts.classes + '"',
          'data-field-for="' + dsName + '"',
          // Should be:
          // dsName ? 'data-field-for="' + dsName + '"' : '',
          // edOpts.fieldName ? 'data-field="' + edOpts.fieldName + '"' : '',
          noAutos === true ? '' : "data-owauto-opts='[" + objToAttr(edOpts) + "]'",
          edOpts.edType === 'weekdays' || edOpts.edType === 'months' || edOpts.edType === 'radio'
            ? '></div>'
            : '>'
        ].join(' ')
  if (edOpts.noWrapper) return inner

  return (
    '<div class="resource_set">' +
    slabel +
    '<span role="presentation">' +
    inner +
    '</span>' +
    '</div>'
  )
}

// disable spinners on kendo numeric
if (typeof $ !== 'undefined') {
  {
    const _super = $.fn.kendoNumericTextBox
    $.fn.kendoNumericTextBox = function (a = {}, b, c, d) {
      a.spinners ??= false
      _super.call(this, a, b, c, d)
      return this
    }
  }
  {
    const _super = $.fn.kendoComboBox
    $.fn.kendoComboBox = function (a, b, c, d) {
      // disable the updown arrow keys when combo not open
      _super.call(this, a, b, c, d)

      var $input = this.parent().parent().find('input:first')

      // disable the updown arrow keys for grid's combobox
      $input.on('keydown', function (e) {
        if (e.which === 38 || e.which === 40) {
          if ($(e.target).closest('.k-grid-edit-row .k-combobox:not(.k-state-border-down)').length)
            return killEvent()
        }
      })
      // move to the front

      var events = $._data ? $._data($input[0]).events : $input.data.events
      var x = events.keydown.pop()
      events.keydown.unshift(x)

      return this
    }
  }
}

const { qc } = require('./cmp/qc')

let mousemove, mouseup // function to call on drag handles or titlebar for resizing or moving the window.

qc(document.body)
  .on(
    'mousemove',
    e => {
      if (e.buttons === 0) {
        mousemove = undefined
        return
      }

      if (mousemove) return mousemove?.(e)
    },
    true
  )
  .on(
    'mouseup',
    e => {
      if (e.buttons !== 0) return
      const r = mouseup?.(e)
      mouseup = undefined
      mousemove = undefined
      if (mouseup) return r
    },
    true
  )

module.exports.setDragRectHandler = (el, elementsToSelectFrom, dragEnd) => {
  qc(el).on(
    'mousedown',
    e => {
      let x = e.pageX,
        y = e.pageY,
        selected = [],
        moved

      const rect = qc('div.drag-rect', '').css({
        position: 'absolute',
        display: 'inline-block',
        top: '0',
        left: '0',
        width: '0',
        height: '0',
        backgroundColor: 'transparent',
        border: 'dotted 1px blue',
        zIndex: '99'
      })
      rect.renderTo(document.body)

      mousemove = e => {
        moved = true

        rect.css({
          top: Math.min(y, e.pageY) + 'px',
          left: Math.min(x, e.pageX) + 'px',
          width: Math.abs(e.pageX - x) + 'px',
          height: Math.abs(e.pageY - y) + 'px'
        })

        const { top, bottom } = rect.el.getBoundingClientRect()

        selected = elementsToSelectFrom().filter(el => {
          const offset = el.getBoundingClientRect()

          const inRect = offset.bottom >= top && offset.top <= bottom

          if (inRect) qc(el).addClass('in-drag-rect') //.css({ backgroundColor: 'red' })
          else qc(el).removeClass('in-drag-rect') //.css({ backgroundColor: undefined })

          return inRect
        })
      }
      mouseup = e => {
        rect.el.remove()
        if (moved) {
          selected.forEach(el => qc(el).removeClass('in-drag-rect'))
          dragEnd(e, rect, selected)
        }
      }
    },
    true
  )
}

exports.viewPreprocessor = function (viewId, viewCode, __) {
  var debug = ['view length: ' + viewCode.length]
  var ts = Date.now()

  viewCode = viewCode.split('---__(')

  debug.push('replacements: ' + viewCode.length)

  var splitLine
  viewCode = viewCode
    .map(function (line, i) {
      if (i === 0) return line
      splitLine = line.split(')---')
      splitLine[0] = splitLine[0] === 'viewId' ? viewId : __(splitLine[0])
      return splitLine.join('')
    })
    .join('')

  debug.push('view preprocessor and translation took ' + (Date.now() - ts) + 'ms')

  ow.log?.(debug)
  return viewCode
}

/**
 * Accepts a string of selectors, and an object of attributes, and returns an object containing the tag and attributes.
 * Pure Function - no side-effects
 *
 * @param {string} sel - String containing selector in valid CSS syntax. Contains at least a tag, and at most a tag + one ID + multiple classes.
 * @param {string} classes - String containing space separated class names
 *
 * @returns { tag, id, class }
 */
const stringSelectorToCmp = (sel, classes = '') => {
  // create an object of classes as keys
  if (!sel || typeof sel !== 'string') throw 'sel parameter should be a non-empty string.'
  if (typeof classes !== 'string') throw 'classes parameter should be a string.'

  let classList = {}

  let id, tag
  sel.split('.').forEach((cls, i) => {
    let x = cls.split('#')
    id = id || x[1]
    cls = x[0]
    if (i) classList[cls] = 1
    else tag = cls
  })
  classes.split(' ').forEach(x => (classList[x] = 1))

  return {
    tag,
    id,
    class: Object.keys(classList)
      .filter(x => x)
      .join(' ')
  }
}

module.exports.stringSelectorToCmp = stringSelectorToCmp

const { renderContent, renderAsync, renderTo } = require('./cmp-renderer')
const { contentToString } = require('./contentToString')
const { unnestArray } = require('../unnest-array')
const { textnode } = require('./textnode')

/**
 * Attaches methods to an array of elements, which can be used to render all elements to the page.
 *
 * @param {array} a - array of Cmp Objects
 */
const cmpCollection = a => {
  if (!Array.isArray(a)) a = [a]

  unnestArray(a)
  a.forEach((x, i) => {
    if (typeof x === 'string') a[i] = textnode(x)
  })

  a.toString = renderScope => contentToString(a, renderScope)
  a.render = renderScope => renderContent(a, renderScope)
  a.renderAsync = () => renderAsync(a)
  a.renderTo = parentEl => renderTo(a, parentEl)
  return a
}

exports.cmpCollection = cmpCollection

const { qc } = require('../cmp/qc')
const { mergeProps } = require('../cmp/mergeProps')
const { isDate } = require('../js-types')
const stylesheet = require('./stylesheet')
const { culture } = require('../culture')
const dates = require('../dates')

const calendarProps = {
  select() {
    console.warn('you need to override calendar select(value)')
  },

  nextMonth() {
    if (this.CurrentMonth === 11) {
      this.CurrentMonth = 0
      this.CurrentYear++
    } else {
      this.CurrentMonth++
    }

    this.showCurrent()
  },

  previousMonth() {
    if (this.CurrentMonth === 0) {
      this.CurrentMonth = 11
      this.CurrentYear--
    } else {
      this.CurrentMonth--
    }

    this.showCurrent()
  },

  previousYear() {
    this.CurrentYear--
    this.showCurrent()
  },

  nextYear() {
    this.CurrentYear++
    this.showCurrent()
  },

  async showCurrent() {
    if (typeof this.data === 'function')
      this.dataSet = await this.data(this.CurrentYear, this.CurrentMonth)

    this.kids(this.build(this.CurrentYear, this.CurrentMonth))
    this.renderAsync()
  },

  build(y, m) {
    const cal = this
    var now = new Date()
    var todayMonth = now.getMonth()
    var todayYear = now.getFullYear()
    const today = new Date(todayYear, todayMonth, now.getDate()).getTime()

    cal.DaysOfWeek = culture().shortDaysOfWeekJs // Days of week, starting on Sunday
    cal.Months = culture().shortMonths // Months, stating on January
    // Set the current month, year

    cal.CurrentYear = typeof y === 'number' ? y : todayYear
    cal.CurrentMonth = typeof m === 'number' ? m : todayMonth

    cal.format = culture().DateFormatSetting

    var dt = new Date(cal.CurrentYear, cal.CurrentMonth, 1)

    var p = dt.getDay() // 1st day of the selected month
    //p = p ? (p - 7) : 0; // if it's not the first day of the week then move back
    p = (p + 1) * -1
    var aDay = 24 * 60 * 60 * 1000 // ms
    dt = new Date(dt.getTime() + aDay * p)

    // eg Jun 2019, doesnt show all if 5 weeks only
    const content = [0, 1, 2, 3, 4, 5].map(() => {
      return qc(
        'tr',
        cal.DaysOfWeek.map(() => {
          dt = new Date(dt.getTime() + aDay)
          let val = new Date(dt.getTime())

          var isToday = dt.getTime() === today

          const cls =
            dt.getFullYear() !== cal.CurrentYear
              ? dt.getFullYear() < cal.CurrentYear
                ? 'prevmonthdates'
                : 'nextmonthdates'
              : ['prevmonthdates', 'currentmonthdates', 'nextmonthdates'][
                  dt.getMonth() - cal.CurrentMonth + 1
                ]

          const tdTemplate = cal.template
            ? cal.template(dt, cal.dataSet)
            : qc('a', dt.getDate().toString())
          return qc(
            'td' + (isToday ? '.today' : '') + '.' + cls,
            tdTemplate.on('click', () => cal.select(val))
          )
        })
      )
    })

    return qc(
      'div.calendar-table',
      qc(
        'table',
        qc('tbody', [
          qc('tr.calendar-controls', [
            qc('th', qc('a', '<<').attr({ href: '#', title: 'Previous Year' })).on('click', () =>
              cal.previousYear()
            ),
            qc('th', qc('a', '<').attr({ href: '#', title: 'Previous Month' })).on('click', () =>
              cal.previousMonth()
            ),
            qc(
              'th',
              { colspan: '3' },
              cal.Months[cal.CurrentMonth] +
                ' ' +
                (cal.CurrentYear +
                  (culture().calendar === 'buddhist' ? dates.buddhistCalendarOffset : 0))
            ),
            qc('th', qc('a', '>').attr({ href: '#', title: 'Next Month' })).on('click', () =>
              cal.nextMonth()
            ),
            qc('th', qc('a', '>>').attr({ href: '#', title: 'Next Year' })).on('click', () =>
              cal.nextYear()
            )
          ]),
          // Write the header of the days of the week
          qc(
            'tr',
            cal.DaysOfWeek.map(day => qc('th.daysheader', day))
          ),
          ...content
        ])
      )
    )
  }
}

const calendar6 = (props, m) => {
  props = props || Date.today()
  let y
  if (m !== undefined) {
    y = props
    props = {}
  } else if (isDate(props)) {
    y = props.getFullYear()
    m = props.getMonth()
  } else {
    y = props.y
    m = props.m
  }
  props.y = y
  props.m = m

  props = mergeProps(calendarProps, props)
  const me = qc('div.calendar6').props(props)

  if (y) me.CurrentYear = y
  if (m) me.CurrentMonth = m

  me.showCurrent()

  me.bindState(
    () => me.y + '-' + me.m,
    () => me.kids(me.build(me.y, me.m))
  )

  return me
}

module.exports = { calendar6 }

let sCss = `
#scope .calendar6 th,
#scope .calendar6 td {
  text-align: center;
  padding: 0;
  line-height: 1.92em;
}
#scope .calendar6 th > a,
#scope .calendar6 td a {
  box-sizing: border-box;
  display: block;
  padding: 0.5em 0.825em;
  cursor: pointer;
  line-height: 1.92em;
}

#scope .calendar6 td.prevmonthdates,
#scope .calendar6 td.nextmonthdates {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}
#scope .calendar6 td.currentmonthdates.today {
  font-weight: bold;
  text-decoration: underline;
}
#scope .calendar6 td:hover {
  background-color: #99c0ff;
  color: #515956;
}
/* Wrapper div. That makes the inner div into an inline element that can be centered with text-align.*/
#scope .calendar6 {
  text-align: center;
}
/* This is a fluid div as width will be changing */
#scope .calendar6 .calendar-controls > a {
  display: inline-block;
}
#scope .calendar6 .calendar-controls > a,
#scope .calendar6 .calendar-controls > a {
  margin-left: 0.625em;
}
`

stylesheet.addCss('calendar6css', sCss)

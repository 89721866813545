const { rs } = require('./rs')
const { addCss } = require('./stylesheet')
const { modelBind } = require('./data-model')
const { killEvent } = require('../killEvent')
const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { iconCodes } = require('../icon-codes')

/**
 *
 * @param {*} props
 * @returns the outer wrap for rednerering.  To get the inner
 */
const check6 = props => {
  let { label, labelRight, fieldName } = props

  props.tag = 'a.check6'
  props.value = props.value || false

  const input = qc(props)
    .props({
      applyState(prevState = {}) {
        input.attr(
          input.disabled ? { disabled: true, href: undefined } : { disabled: undefined, href: '#' }
        )
        input.disabled
          ? input.wrap().addClass('ow-disabled')
          : input.wrap().removeClass('ow-disabled')

        input.value ? input.addClass('on') : input.removeClass('on')
        input.kids([html(input.value ? iconCodes.check : '&nbsp')])
        return prevState
      },

      val(v) {
        if (typeof v !== 'undefined') {
          if (this.value !== v) {
            this.value = v
            // if (model) model[fieldName] = v // inform the data record
            // render change to UI:
            this.renderAsync()
          }
        }
        return this.value
      },

      readFilter(filters) {
        const v = this.val()
        const { fieldName, op = 'eq', filterValue = v } = this
        if (v || v === false) {
          filters.push({
            field: fieldName,
            operator: op,
            value: filterValue
          })
        }
      }
    })
    .on('click', () => {
      if (input.disabled) return
      input.val(!input.val())

      input.trigger('ow-change')
    })
    .on('keypress', e => {
      // space
      if (e.which === 32) {
        input.el.click(e)
        return killEvent(e)
      }
    })

  if (props.model) modelBind(props.view, input, props.model)

  const w = qc('span.ow-ctl-wrap.ow-check-wrap', [input, labelRight ? label : '']).props({
    input,
    rs: () => input.rs()
  })
  input.wrap = () => w

  input.rs = () => rs({ label: labelRight ? '' : label }, w).props({ input })

  return input
}

const sCss = `
#scope a.check6.hide-check {
  color: transparent;
}
#scope a.check6 {
  font-family: 'FontAwesome';
  color: transparent;
  border: 0.077em solid #c5c5c5;
  background: #fff;
  border-radius: 0.231em;
  width: 1.72em;
  box-sizing: border-box;
  padding: 0.077em 0.3em 0 0.3em;
  margin-right: 0.62em;
  line-height: 1.72em;
  display: inline-block;
  white-space: nowrap;
  font-size: 0.9em;
}
#scope .ow-grid-content .check6 {
  margin-right: 0;
  margin-top: 2px;
}
#scope a.check6.on {
  color: #535b6a;
}
#scope a.check6:focus {
border-color: #1a87cd;
-webkit-box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
}
#scope a.check6[disabled],
#scope .ow-check6-wrap.ow-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
#scope .resource_set .ow-check6-wrap {
  padding-left: 0;
}
#scope .resource_set > span > a.check6 {
  line-height: 2.25em;
  padding: 0;
}`
addCss('css-check6', sCss)

module.exports = { check6 }

/* 

todo: use Intl instead of kendo (with polyfills?) 
OR globalisejs

Intl (ECMA2020):
const number = 123456.789
new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number) // expected output: "123.456,79 €"
// the Japanese yen doesn't use a minor unit
new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(number) // expected output: "￥123,457"
// limit to three significant digits
new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number) // expected output: "1,23,000"

new Intl.NumberFormat('en-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"€1,234.45"
new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"1.234,45 €"
new Intl.NumberFormat('en-DE', {}).format(1234.45)
"1.234,45"
new Intl.NumberFormat('de-DE', {}).format(1234.45)
"1.234,45"

*/

const { numberFormats } = require('./number-formats')

// todo
let __ = (key, isData = false) => {
  if (typeof window === 'undefined') return key
  if (window.ow0.dev && window.cat && window.cat[key] === undefined && !isData) {
    ;(window.missingTxKeys = window.missingTxKeys ?? []).push(key)
    console.log('Missing Translation key found:', key)
    window.cat[key] = key // prevent it being added again.
  }
  return window.cat?.[key] ?? key
}

if (typeof window !== 'undefined') window.__ = __
exports.__ = __

const w = () => typeof window !== 'undefined' && window

const culture = (locale = w()?.ow?.locale ?? 'en-US') => ({
  ...require('./dates').dates,
  numberFormat: w()?.kendo?.culture().numberFormat ?? numberFormats[locale].numberFormat
})
exports.culture = culture

const spliceString = (r, i) => r.slice(0, i) + r.slice(i + 1)

exports.formatFloatOnType = el => {
  const decimal = culture().numberFormat['.']
  const currency = culture().numberFormat.currency.symbol
  const validChars = Array.prototype.reduce.call(
    '-0123456789' + decimal,
    (t, c) => ({ ...t, [c]: 1 }),
    {}
  )

  let selectionStart = el.selectionStart,
    selectionEnd = el.selectionEnd,
    hasDecimal = false,
    hasMinus = false

  let value = el.value

  console.log('>>>>>', value, 'selection:', value.slice(selectionStart, selectionEnd))
  console.log('start:', selectionStart, 'end:', selectionEnd)

  let r = value,
    i = 0
  console.log('removed', currency, r, 'selection:', r.slice(selectionStart, selectionEnd))

  value = Array.prototype.filter
    .call(value, x => {
      if (x === '(') x = '-'
      let allow = x in validChars

      if (allow && x === decimal) {
        if (hasDecimal) allow = false // only a single decimal point
        hasDecimal = true
      }
      if (allow && x === '-') {
        if (hasMinus) allow = false // only a single minus
        hasMinus = true
      }

      if (allow) {
        i++
        return true
      }

      if (selectionStart > i) selectionStart--
      if (selectionEnd > i) selectionEnd--
      r = spliceString(r, i)

      console.log('removed', x, r, 'selection:', r.slice(selectionStart, selectionEnd))
    })
    .join('')
    .replace('(', '-')

  if (value !== el.value) {
    el.value = value
    el.selectionStart = selectionStart
    el.selectionEnd = selectionEnd
  }

  console.log('>>>>>', value, 'selection:', value.slice(selectionStart, selectionEnd))

  return value
}

const { _v } = require('../_v')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { iconCodes } = require('../icon-codes')
const { killEvent } = require('../killEvent')
const { clone } = require('../ow0/core')

const { registerCtl, ctlTypes, openPopUpFactory } = require('./ctl5')

const evalOpt = (opts, opt, el) =>
  typeof opts[opt] === 'function' ? opts[opt].call(opts, opts.model, el) : opts[opt]

const subMatches = function (list, sub, valueField, textField, displayFunction) {
  if (s === '') return list
  var matches = []
  var s = sub.toLowerCase()
  let item, i, val, text, doesMatch

  for (i = 0; i < list.length; i++) {
    item = list[i]
    doesMatch = false

    val = _v(item, valueField)
    if (val === undefined || val === null) val = ''
    val = val.toString().toLowerCase()
    if (val.indexOf(s) + 1) doesMatch = true

    if (!doesMatch && valueField !== textField) {
      text = _v(item, textField)
      if (text === undefined || text === null) text = ''
      text = text.toString().toLowerCase()
      if (text.indexOf(s) + 1) doesMatch = true
    }

    if (!doesMatch && displayFunction) {
      text = displayFunction(item)
      if (text === undefined || text === null) text = ''
      text = text.toString().toLowerCase()
      if (text.indexOf(s) + 1) doesMatch = true
    }

    if (doesMatch) matches.push(item)
  }

  return matches
}

const urlHasChanged = el => evalOpt(el.opts, 'url', el) !== el.lastUrl

const fetchListUrl = async (url, data) => $ajax({ url, data })

const resolveItemSelection = function (el, forceSelection) {
  const me = qc(el)
  ow0.devLog('resolveItemSelection listIndex:' + el.listIndex, 'combo')
  // Is selectedItem still valid?
  if (el._selectedItem && el.opts.display(el._selectedItem) === el.value) return

  var index = el.listIndex || 0

  // for static list with showAll
  if (el.opts.list) {
    var list = evalOpt(el.opts, 'list', el)
    index = el.opts.showAll && el.matchList[index] ? list.indexOf(el.matchList[index]) : 0
    if (el.opts.showAll) el.matchList = list
    el.listIndex = index
  }

  var hasFocus = el === document.activeElement
  if (!hasFocus && !el.opts.allowUnmatchedText) forceSelection = true //

  if (el.$dropdown) el.$dropdown.updateList()

  if (el.dropdownOpen) {
    if (forceSelection) el.$dropdown.close()
  } else {
    if (forceSelection && !el.matchedOn && !el.opts.required) {
      me.selectItem(null)
    } else if (el.matchList.length === 0) {
      if (forceSelection) {
        me.selectItem(null)
        ow0.popInvalid(el.opts.msgs.NoMatches)
      }
    } else if (el.matchList.length === 1) {
      if (forceSelection || (el.opts.required && el.typedText)) me.selectItem(el.matchList[0])
      el.listIndex = 0
      return
    } else {
      // if there's an exact match, use that, not the first
      var exactMatch = el.matchList.find(item => {
        var matchOnValue = el.opts.valueField === el.opts.textField || el.opts.valueTextDisplay
        if (matchOnValue && _v(item, el.opts.valueField) === matchOnValue) return true
        return el.opts.display(item) === el.value
      })

      if (exactMatch && (forceSelection || el.opts.matchFirst)) {
        me.selectItem(exactMatch)
      } else if (forceSelection && el.matchedOn) {
        me.selectItem(null)
      } else if (el.opts.matchFirst) {
        me.selectItem(el.matchList[0])
      } else {
        if (!el.dropdownOpen && !forceSelection && el.opts.openOnType) me.openList(el)
        el.listIndex = index
        if (el.$dropdown) el.$dropdown.updateList()
      }
      // if that fails...
      if (!el._selectedItem && el.value && forceSelection) {
        el.value = ''
        el.typedText = null
      }
    }
  }
}

const combo5Props = {
  init(el) {
    // var ts = new Date().valueOf()
    el = el || this
    const $el = $(el)
    const me = qc(el)
    const { opts } = el

    ctlTypes.default.init.call(el)
    const wrap = qc(el.parentElement).addClass('ow-ctl-wrap').addClass('ow-combo-wrap')

    opts.valueField = opts.valueField ?? 'Value'
    opts.textField = opts.textField ?? 'Text'

    me.addClass('combo')

    if (opts.fieldName) {
      if ($el.attr('data-field') !== opts.fieldName) $el.attr('data-field', opts.fieldName)
    } else if ($el.attr('data-field')) opts.fieldName = $el.attr('data-field')

    let icon = wrap.find('i')[0]
    if (icon) icon = qc(icon)
    else {
      // if prebuilt with wrapper in combo we don't have to add
      wrap.addClass('text-icon-after')

      icon = qc(
        'i.fa.text-item-icon.' + (opts.popUp ? 'popup' : 'combo-icon'),
        html(opts.popUp ? opts.iconCode || iconCodes.magnifier : iconCodes.angleDown)
      )

      icon.renderTo(wrap.el)
    }

    me.icon = icon

    icon.on(
      'click',
      opts.popUp
        ? function () {
            if ($(el).hasClass('static-text') || el.disabled) return
            openPopUpFactory(el)()
            return false
          }
        : function (e) {
            if ($(el).hasClass('static-text') || el.disabled) return

            el.focus()
            if (el.dropdownOpen) el.$dropdown.close()
            else combo5Props.openList(el)

            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false
          }
    )

    $el
      .on('ow-delayed-blur', function () {
        if (el.dropdownOpen) {
          if (el === document.activeElement) return
          const $fc = $(document.activeElement)
          if ($fc.is(el.$dropdown) || $fc.closest('.ow-ctl-dropdown').is(el.$dropdown))
            return el.focus()
          el.$dropdown.close()
        }
      })
      .on('keydown keyup', function (e) {
        if (!el.dropdownOpen) return
        // let it propagate normally
        // if (ddOpts.keyHandler(e) === false) return false;

        if (e.type === 'keyup') {
          if (e.which === 27) {
            el.$dropdown.close()
            return killEvent(e)
          }

          if (e.which !== 38 && e.which !== 40 && e.which !== 9 && !el.matchTimeout)
            el.matchTimeout = setTimeout(() => {
              el.matchTimeout = null
              if (el.dropdownOpen) me.findMatches()
            }, 150)
        }

        if (e.type === 'keydown') {
          if (e.which === 9 || (e.which === 13 && !e.shiftKey)) {
            if (
              el.value !== '' ||
              el._selectedItem ||
              el.required ||
              el.listIndex ||
              e.which === 13
            ) {
              me.selectItem(el.matchList[el.listIndex])
              if (el.$dropdown) el.$dropdown.close()
              if (e.which === 13) return killEvent(e)
            }
          } else if (e.which === 38 || e.which === 40) {
            // left right arrows
            el.listIndex =
              (el.matchList.length + el.listIndex + (e.which === 40 ? 1 : -1)) % el.matchList.length

            el.$dropdown
              .find('li')
              .removeClass('ow-selected')
              .eq(el.listIndex)
              .addClass('ow-selected')
            return killEvent(e)
          }

          // escape
          if (e.which === 27) {
            el.$dropdown.close()
            return killEvent(e)
          }

          // shift+enter
          if (e.which === 13 && e.shiftKey) {
            el.$dropdown.close()
            return killEvent(e)
          }
        }
      })
      .on('blur', () =>
        setTimeout(() => el !== document.activeElement && qc(el).trigger('ow-delayed-blur'), 50)
      )
      .on('ow-delayed-blur', function (e) {
        var el = this
        if (el.dropdownOpen || el.ignoreNextChange || el.popUpOpen) {
          el.ignoreNextChange = false
          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          return false
        }
        if (el === document.activeElement) return false
        if (el.resolveBeforeExit) el.resolveBeforeExit(!el.opts.allowUnmatchedText)
        el.typedText = null
      })
      .on('keydown', function (e) {
        el._valueOnKeyDown = el.value
        if (e.which === 8 || e.which === 46) {
          // delete or backspace
          if (!el.opts.required)
            setTimeout(() => {
              if (el._selectedItem && el.value === '') me.selectItem(null, false)
            }, 50)
        } else if (e.which === 13) {
          if ($(el).hasClass('static-text') || el.disabled) return

          if (e.shiftKey || el.opts.openOnEnterKey !== false) combo5Props.openList(el)

          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          return false
        } else if (e.which === 120) {
          // F9
          if (el.opts.popUp && (typeof el.opts.popUp === 'function' ? el.opts.popUp(el) : true))
            openPopUpFactory(el)()
        }
      })
      .on('keyup', function (e) {
        if ($(el).hasClass('static-text') || el.disabled) return

        if (el._valueOnKeyDown === el.value) return

        var isTextKey = e.which === 8 || e.which === 32 || e.which >= 46

        if (isTextKey && el.opts.openOnType !== false && !el.dropdownOpen)
          if (el.value !== '')
            // don't open if we just deleted the value
            combo5Props.openListWithDelay(el)
        if (isTextKey) el.typedText = el.value
      })

    // set the user typing tracker - discerns typed text from set display values
    el.typedText = null

    opts.msgs = opts.msgs || {}
    opts.msgs.IncorrectMatch = opts.msgs.IncorrectMatch || __('Incorrect match')
    opts.msgs.NoMatches = opts.msgs.NoMatches || __('No matches')

    el.resolveValue = function (v) {
      ow0.devLog('COMBO cannot resolve for ' + opts.fieldName + ' to: ' + v)
    }

    if (!opts.display) {
      opts.display = function (item) {
        if (!item) return ''

        var t = _v(item, opts.textField) || ''
        t = t && t.toString ? t.toString() : t
        if (opts.valueTextDisplay) {
          var v = _v(item, opts.valueField)
          v = v === null || v === undefined ? '' : v + ' - '
          t = v + t
        }

        return t
      }
    }

    if (!opts.itemTemplate && opts.valueTextDisplay) {
      opts.itemTemplate = opts.display
    }

    el._selectedItem = null
    el.selectedItem = function () {
      return el._selectedItem
    }

    el.matchedOn = null // note, matchedOn is set to el.typedText
    el.matchList = el.opts.list
      ? evalOpt(el.opts, 'list', el)
      : opts.objectFieldName && opts.model
        ? [_v(opts.model, opts.objectFieldName)]
        : []
    if (typeof el.matchList[0] === 'undefined') el.matchList = []

    $el.on('change', function (e) {
      // suppress onchange event if poplist is showing or enter key triggered it
      if (el.dropdownOpen || el.ignoreNextChange || el.popUpOpen) {
        el.ignoreNextChange = false
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()
        return false
      }
      me.findMatches()
    })

    el.resolveBeforeExit = async function (clearIfInvalid) {
      ow0.devLog('Resolving selection for combo Exit ' + el.value + ' ' + $(el).attr('data-field'))

      // if there's selected item but typedText, we need to clear
      if (el._selectedItem) {
        if (el.typedText === null) return el.val()
        me.selectItem(null)
        if (el.waitingOnSub) el.resolveOnResponse = true
      }

      if (!el._selectedItem && el.value !== '' && el.opts.list) {
        await me.findMatches(true)
      }

      if (!el._selectedItem) {
        if (el.value !== '' && opts.required) await me.findMatches(true) //, function() { resolveItemSelection(el, true)})
      }
      var v = el.val()

      if (clearIfInvalid && v === null && el.value !== '') {
        el.value = ''
        el.typedText = null
      }
      qc(el).trigger('ow-change')

      return v
    }

    // if (!el.requestTabOut) {
    //   el.requestTabOut = async function(shiftKey) {

    //     ow0.devLog('Resolving selection for tabout ' + el.value)
    //     var v = await el.resolveBeforeExit(false)
    //     var allowTab = v || v === 0 || shiftKey || !opts.required // || (el.matchList.length === 1);
    //     if (!allowTab) popInvalid(__('Error'), __('Invalid Selection'), 2000)

    //     return allowTab
    //   }
    // }

    var validate = el.validate
    el.validate = function (onInvalid) {
      if (opts.required && (el.value === '' || !el._selectedItem)) {
        if (el.matchList?.length === 1) me.selectItem(el.matchList[0])
      }

      var result = !validate ? true : validate.call(el, onInvalid)

      ow0.devLog('combo.validate ' + result)

      var v = el.val()

      if (v === null && el.value !== '') me.findMatches(el)

      if (opts.required && (el.value === '' || !el._selectedItem)) {
        if (el.matchList?.length === 1) {
          me.selectItem(el.matchList[0])
        } else {
          onInvalid(opts.name || opts.label || '', opts.msgs.IncorrectMatch, el)
          return false
        }
      }
      return result
    }

    if (opts.model) $el.ow_populate(opts.model)

    //_v(window, 'timings.comboInit', (new Date().valueOf() - ts) + (parseInt(_v(window, 'timings.comboInit')) || 0))
  },

  populate(model) {
    var el = this
    var opts = this.opts
    var f = el.opts.fieldName || $(el).attr('data-field')

    opts.model = model

    if (opts.objectFieldName && opts.list && _v(model, f) && !_v(model, opts.objectFieldName)) {
      console.warn(
        'populating combo5',
        f,
        '.',
        opts.objectFieldName,
        'not found on model, attempting to find in list.'
      )
      _v(
        model,
        opts.objectFieldName,
        evalOpt(opts, 'list', el)?.find(r => _v(r, opts.valueField) === _v(model, f))
      )
    }

    if (opts.objectFieldName) {
      el._selectedItem = _v(model, opts.objectFieldName) || null
      el.value = (el._selectedItem ? opts.display(el._selectedItem) : '') || ''
    } else {
      var v = _v(model, f)
      if (v === undefined) v = null
      if (el.opts && el.opts.list) {
        el._selectedItem = evalOpt(el.opts, 'list', el).find(
          r => _v(r, opts.valueField) === _v(model, f)
        )
      }
      el.value = (el._selectedItem ? opts.display(el._selectedItem) : _v(model, f)) || ''
      if (v !== null && !el._selectedItem) {
        var dum = {}
        el._selectedItem = dum
        dum[opts.valueField] = v
        dum[opts.textField] = el.value
      }
    }
    if (el.typedText !== el.value || el._selectedItem) el.typedText = null
  },

  val(v, m) {
    var el = this
    var $el = $(el)
    var opts = el.opts

    el.opts.model = el.opts.model || m // this should only happen with populate

    var f = el.opts.fieldName || $el.attr('data-field')
    var item =
      opts.model && opts.objectFieldName
        ? _v(opts.model, opts.objectFieldName)
        : el._selectedItem || null

    var selectedChanged = false
    if (item !== el._selectedItem) {
      el._selectedItem = item
      selectedChanged = true
    }

    var currentV = opts.model ? _v(opts.model, f) : item ? _v(item, opts.valueField) : null

    var vOld
    if (opts.model && f) vOld = _v(opts.model, f)

    if (typeof v !== 'undefined') {
      // using val(v) to update value should not update the model!  It should match item, trigger a change event

      // ow0.devLog('COMBO val ' + f + ': ' + currentV + ' -> ' + v)

      if (v === null) {
        el._selectedItem = null
        el.value = ''
        if (item) {
          if (opts.objectFieldName && opts.model) _v(opts.model, opts.objectFieldName, null)
          //if (!populating)
          qc(el).trigger('ow-change')
        }
        return null
      }

      // clear the item if it doesn't match
      if (item && _v(item, opts.valueField) !== v) {
        item = null
        el._selectedItem = null
        if (opts.objectFieldName && opts.model) _v(opts.model, opts.objectFieldName, null) // clear on the model
      }

      if (!item && opts.list) {
        item = evalOpt(opts, 'list', el).find(x => _v(x, opts.valueField) === v) || null
        el._selectedItem = item
        if (item && opts.objectFieldName) _v(opts.model, opts.objectFieldName, item)
      }
      currentV = item ? _v(item, opts.valueField) : null

      // item doesn't match the new value
      if (v && currentV !== v && opts.objectFieldName && opts.model) {
        item = _v(opts.model._meta.orig, opts.objectFieldName) // if this is an undo, we can find the value in rec._meta.orig
        if (item && v === _v(item, opts.valueField)) currentV = v
        else item = null
      }

      el._selectedItem = item

      if (currentV !== v) {
        // need to resolve the item
        el.resolveValue(v)
        return currentV
      }
      var display = item ? opts.display(item) : ''

      if (el.value !== display) {
        // console.log('Setting combo display ' + el.value + ' -> ' + display)
        el.value = display
        el.typedText = null
      }

      if (vOld !== v || currentV !== v || selectedChanged) {
        // console.log('val ' + f + ': ' + vOld + ' -> ' + v + ', ' + display)
        if (opts.model) _v(opts.model, f, v)
        // if (!populating)
        qc(el).trigger('ow-change')
      }

      return v
    } else {
      // extra house keeping
      if (item && opts.display(item) !== el.value) {
        if (opts.model && opts.objectFieldName) _v(opts.model, opts.objectFieldName, null)
        else el._selectedItem = null

        return opts.allowUnmatchedText ? el.value || null : null
      }

      if (!item && el._selectedItem) {
        if (
          opts.display(el._selectedItem) === el.value &&
          _v(el._selectedItem, opts.valueField) === _v(opts.model, opts.fieldName)
        ) {
          item = el._selectedItem
          if (opts.objectFieldName && opts.model) _v(opts.model, opts.objectFieldName, item)

          if (opts.model && opts.fieldName) {
            v = _v(item, opts.valueField)
            _v(opts.model, opts.fieldName, v)
            return v
          }
        }
      }
    }

    if (item) v = _v(item, opts.valueField)

    v = v === undefined ? (opts.allowUnmatchedText ? el.value : null) : v

    if (!item && v === '' && !opts.allowEmptyString) v = null

    return v
  },

  selectItem(item, closeAfter) {
    closeAfter = closeAfter !== false
    var el = this

    if (el.selectItem) {
      ow0.devLog(
        'selectItem has been assigned to combo5 - please use el.opts.onSelectItem and return false to prevent.'
      )
    }

    if (el.opts.onSelectItem) if (el.opts.onSelectItem.call(el, item) === false) return false

    var opts = el.opts
    if (item) item = clone(item)

    if (el.dropdownOpen && closeAfter) el.$dropdown.close()

    if (opts.model && opts.objectFieldName) _v(opts.model, opts.objectFieldName, item)

    if (!opts.model || !opts.objectFieldName) el._selectedItem = item

    if (!item) {
      el._selectedItem = item
      if (!opts.allowUnmatchedText) {
        el.value = ''
        el.val(null)
      }
    } else {
      var v = _v(item, opts.valueField)
      el.val(v)
    }

    if (el._selectedItem) el.typedText = null

    qc(el).trigger('ow-select', item)
  },

  openListWithDelay(el, delay) {
    const me = qc(el)
    if (el.dropdownOpen) {
      var $dd = el.$dropdown
      $dd.close()
      // delete el.dropdownOpen
      return
    }

    if (el.opts.fieldName && el.opts.model) _v(el.opts.model, el.opts.fieldName, el.value) // prevents new blank row being canceled on row change.

    if (!el.matchTimeout) {
      el.matchTimeout = setTimeout(
        function () {
          el.matchTimeout = null
          if (!el.dropdownOpen && el === document.activeElement) {
            combo5Props.openList(el)
            me.findMatches()
          }
        },
        delay === 0 ? 0 : delay || el.opts.delay || 300
      )
    }
  },

  openList(el) {
    const me = qc(el)
    const { opts } = el

    if (!opts.itemTemplate) opts.itemTemplate = opts.display

    el.myWin().parent().find('.ow-ctl-dropdown').detach()

    let ul = qc('ul').css({ listStyleType: 'none' })
    me.qDropdown = qc('div.ow5.ow-ctl-dropdown', qc('div.combo-list-popup', ul))

    el.$dropdown = $(me.qDropdown.render())
    el.$dropdown.$el = $(el)

    el.$dropdown.css({
      'font-size': '0.9em',
      position: 'absolute',
      'min-width': '60px',
      padding: '0',
      border: 'solid 0.077em #bbb',
      overflow: 'hidden',
      'z-index': 999,
      'background-color': '#fff',
      'box-shadow': '#ccc 0.14em 0.14em 0.3em 0em',
      'box-sizing': 'border-box',
      'max-height': '270.72px',
      'overflow-y': 'auto'
    })

    el.$dropdown.css({ width: $(el).parent().outerWidth() })

    el.$dropdown.attr('tabindex', 0) //necessary to detect relatedTarget

    el.$dropdown.setHorPosition = function () {
      var $x = el.myWin().parent()

      var l = $(el).parent().offset().left - $x.offset().left
      var posHorCss = { left: l + 'px' }
      el.$dropdown.css(posHorCss)
    }

    el.$dropdown.data('ow-dropdown', el.$dropdown)
    el.$dropdown.close = function () {
      qc(el).trigger('ow-dropdown-close')
      el.$dropdown.detach()
      el.$dropdown.off()
      el.dropdownOpen = false
      el.$dropdown.$el = null
      qc(el).removeClass('ow-dropdown-open')
      delete el.$dropdown
    }

    el.$dropdown.on('focusin click', () => el.focus()) // set the focus back to the input.

    el.dropdownOpen = true
    el.myWin().parent().append(el.$dropdown)
    el.$dropdown.setHorPosition()
    qc(el).addClass('ow-dropdown-open').trigger('ow-dropdown-open')

    el.$dropdown.on('mousedown', 'li', function () {
      el.focus()
      el.listIndex = $(this).index()
      me.selectItem(el.matchList[el.listIndex])
      if (el.dropdownOpen) el.$dropdown.close()
      setTimeout(() => el.focus(), 10)
    })

    el.$dropdown.css({
      width: (opts.listWidth === 1 ? $(el).parent().outerWidth() : opts.listWidth || 300) + 'px',
      padding: '0.14em'
    })

    el.$dropdown.updateList = () => {
      if (el.dropdownOpen)
        ul.kids(
          el.matchList.length
            ? el.matchList
                .map(opts.itemTemplate)
                .map(dirty => window.DOMPurify.sanitize(dirty))
                .map((item, i) =>
                  qc('li', html(item)).bindState(
                    () => el.listIndex === i,
                    function (v) {
                      v ? this.addClass('ow-selected') : this.removeClass('ow-selected')
                    }
                  )
                )
            : qc('li', __('No results found'))
        )
    }

    if (el.matchedOn !== el.value || urlHasChanged(el)) me.findMatches()
    else el.$dropdown.updateList()

    el.$dropdown.setVerPosition = function () {
      var $x = el.myWin().parent()
      var conHeight = $(el).parent().outerHeight() // The height of the drop-down element.
      var t = $(el).parent().offset().top - $x.offset().top

      var posVerCss = {}

      // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
      if (
        $(document).height() - $(el).parent().offset().top - conHeight >
        el.$dropdown.outerHeight()
      )
        posVerCss.top = t + conHeight + 'px'
      else posVerCss.bottom = $x.outerHeight() - t + 'px'

      el.$dropdown.css(posVerCss)
    }
    el.$dropdown.setVerPosition()
  },

  async findMatches(forceSelection, then) {
    const el = this
    ow0.devLog('findingMatches:' + el.value + ' typedText:' + el.typedText)

    var opts = el.opts

    var _sub =
      opts.sub ||
      function () {
        var s = el.typedText || ''
        if (opts.valueTextDisplay) {
          var splits = s.split(' - ')
          if (splits.length > 1) s = splits[0]
          else {
            // if ends with part of " - "
            s = s.trim()
            if (s.substr(-2) === ' -') s = s.substr(0, s.length - 2)
          }
        }
        return s
      }
    let sub = _sub(el.typedText)

    if (el._selectedItem && el.value === '') el.val(null)

    el.ignoreNextChange = true

    const onSuccess = function (response, matchedOn) {
      el.listIndex = el.listIndex || 0
      if (el.typedText && matchedOn !== el.typedText) return

      // if the match
      if (el.matchedOn !== matchedOn) el.listIndex = 0
      el.matchedOn = matchedOn
      el.matchList = response

      if (el.opts.showOnlySelected === false) {
        if (el._selectedItem && !el.typedText) {
          var v = _v(el._selectedItem, opts.valueField)

          if (
            !response.filter((x, i) => {
              if (v === _v(x, opts.valueField)) {
                el.listIndex = i
                return true
              }
            }).length
          )
            response.unshift(el._selectedItem)
        }
      }

      resolveItemSelection(el, forceSelection)
      if (el.dropdownOpen) el.$dropdown.updateList()

      if (then) then()
    }

    if (el.opts.list) {
      var list = evalOpt(el.opts, 'list', el)
      sub = sub.toLowerCase() // (el.value || '').toLowerCase()

      if (el.opts.showOnlySelected !== false) {
        if (!el.opts.showAll && el._selectedItem && el.typedText === null)
          return onSuccess([el._selectedItem], el.typedText)
      }

      if (sub === '') {
        if (el._selectedItem && el.typedText === null)
          el.listIndex = list.indexOf(el._selectedItem) || 0

        onSuccess(list, el.typedText)
      } else {
        var matches = subMatches(list, sub, opts.valueField, opts.textField, opts.itemTemplate) // opts.display)
        onSuccess(matches, el.typedText)
      }
    } else {
      if (el.opts.showOnlySelected !== false) {
        if (el._selectedItem && el.typedText === null)
          return onSuccess([el._selectedItem], el.typedText)
      }

      if (!el.opts.url) {
        el.opts.url =
          '/data/' +
          el.myWin().viewdata.url.split('/').pop().toLowerCase().split('-')[0] +
          '/lookup/' +
          ($(el).attr('data-field') || el.opts.fieldName).toLowerCase()
        console.log('// combo has no list or url so defaulting to ' + el.opts.url)
      }
      // if (!urlHasChanged(el)) return onSuccess(el.matchList, el.typedText)

      var url = evalOpt(el.opts, 'url', el)

      // decompose into host and query data with sub added
      let [host, query = ''] = url.split('?')
      let data = { sub }
      query
        .split('&')
        .filter(x => x)
        .map(x => x.split('='))
        .forEach(([k, v = '']) => k && (data[k] = v))

      query = Object.entries(data)
        .map(x => x.map(encodeURIComponent).join('='))
        .join('&')
      url = [host, query].filter(x => x).join('?')

      if (url === el.lastUrl && !!sub) return onSuccess(el.matchList, el.typedText)
      if (url === el.waitingOnUrl) return

      el.waitingOnUrl = url

      try {
        let r = await fetchListUrl(host, data)
        if (el.waitingOnUrl !== url)
          return console.log(
            'A newer request for ' + el.waitingOnUrl + 'has been sent, ignoring this one for ' + sub
          )

        el.waitingOnUrl = null
        el.lastUrl = url
        return onSuccess(r, el.typedText)
      } catch (err) {
        if (el.waitingOnUrl === url) el.waitingOnUrl = null
        el.lastUrl = url
        el.matchList = []
        if (el.dropdownOpen) el.$dropdown.updateList()
        qc(el).trigger('ow-change')
        $(el).removeClass('ow-loading').removeClass('match-notfound')
        let sErr = typeof err === 'string' ? err : (err.errMsg ?? err)
        console.error('Error loading list for combo box:', err)
        ow0.popError(__('Error loading dropdown list'), sErr)
        $(el).myWin().progress(false)
        throw 'The server returned an error looking up item matching text:' + sErr
      }
    }
  }
}
registerCtl('combo', Object.assign({}, ctlTypes.default, combo5Props))

/**
 * combo5
 *
 * @param {object | HTMLElement} el
 * @param {object} opts
 * @param {string} opts.fieldName
 * @param {string} opts.dsName
 * @param {string || function} opts.url
 * @param {array || function} opts.list - a static list rather than url
 * @param {boolean} opts.showAll - if you want to show all of the dropdown static list without client-side filtering
 * @param {function} opts.display - returns display string for a selected item
 * @param {function} opts.itemTemplate - returns HTML string a dropdown item
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 * @param {boolean} opts.matchFirst - automatically select first match on tab out if nothing selected
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {string} opts.objectFieldName
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {boolean} opts.allowUnmatchedText - if set to true, the typed text will be returned even if there's to matches
 * @param {boolean} opts.allowEmptyString - if not true, then a value of "" will be converted to null
 * @param {Object} opts.popUp - info for popping up another form to select a value.
 * @param {boolean} opts.openOnEnterKey
 * @param {boolean} opts.openOnType
 * @param {function} opts.onSelectItem
 * @param {Number} opts.delay - how many milliseconds on keyboard event waits for another key before calling url for matches
 * @param {function} opts.onInit is called after initializing the combo
 * @param {string} opts.msgs.NoMatches
 * @param {string} opts.msgs.IncorrectMatch
 * @param {number} opts.listWidth - if 1 it will match width of input else it will be px
 * @param {boolean} opts.showOnlySelected default true. if false then the first set of matches are also loaded into the dropdown list.
 */
exports.combo5 = function (el, opts) {
  el.opts = opts
  qc(el).addClass('combo')
  ow5.ctlType(el)
  el.val(el.val())
  return el
}

// body init
if (typeof $ !== 'undefined')
  $('body')
    // close combo dropdowns when grid scrolls.
    .on('ow-grid-scroll', '.k-grid-content', function () {
      $(this)
        .find('.combo:focus')
        .forEach(combo => {
          if (combo.dropdownOpen) combo.$dropdown.close()
        })
    })

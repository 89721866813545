const { $param } = require('../ajax')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { ctlCheck } = require('../controls/check')
const { qCalendar } = require('../controls/q-calendar')
const { parseCurrency, ctlParsers, ctlFormatters } = require('../ctl-format')
const { culture } = require('../culture')
const { dates } = require('../dates')
const { iconCodes } = require('../icon-codes')
const { $is } = require('../no-jquery')
const { openChildView } = require('../openChildView')
const { _v } = require('../ow0/core')
const { BasicEd } = require('../ow4/controls4')
const { allowFilterUndefined } = require('../ow7/filters')
const { popError } = require('../pop-box')
const { wrapControl } = require('./wrapControl')

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

// if (typeof $ === 'undefined') global.$ = no$

exports.currencySymbol = () =>
  ow0.toString(0, 'c1').replace(ow0.toString(0, 'n1'), '').replace(' ', '')
// sometimes space between num and symbol

exports.currencyDecimal = () => culture().numberFormat?.currency['.']
exports.currencyThousand = () => culture().numberFormat?.currency[',']
exports.floatThousand = () => culture().numberFormat[',']
exports.floatDecimal = () => culture().numberFormat['.']

exports.parseCurrency = parseCurrency
exports.ctlParsers = ctlParsers
exports.ctlFormatters = ctlFormatters

const addCtlTypeMethodsToQc = (qEl, myType) =>
  Object.keys(myType).forEach(key => {
    if (key !== 'init' && typeof myType[key] === 'function') {
      if (!Object.keys(qEl).includes(key)) qEl[key] = (...args) => myType[key].call(qEl.el, ...args)
      //else console.warn('Not overwriting Cmp method with ctlType.' + key)
    }
  })

const displayValidity = function (el, bValid, msg) {
  if (!el) return console.warn('displayValidity called with no element')
  const c = qc(el)

  const invalidClass = 'k-input-errbg'

  if (c.displayValidity) return c.displayValidity(bValid, msg)
  if (el.displayValidity) return el.displayValidity(bValid, msg)

  if (bValid) {
    c.removeClass(invalidClass).attr({ title: undefined })
    el.parentElement && qc(el.parentElement).removeClass(invalidClass)
    delete c.invalidMsg
  } else {
    c.invalidMsg = msg
    c.addClass(invalidClass).attr({ title: msg })
    el.parentElement && qc(el.parentElement).addClass(invalidClass)
  }
}
exports.displayValidity = displayValidity
document.body.addEventListener('input', e => displayValidity(e.target, true), true)
document.body.addEventListener('ow-change', e => displayValidity(e.target, true), true)
document.body.addEventListener('change', e => displayValidity(e.target, true), true)

const $ow_resolve = function (el) {
  const ctl = ctlType(el)
  if (ctl?.resolve) return ctl.resolve.call(el)
  if (el.resolve) return el.resolve()

  const qEl = qc(el)
  if (!ctl && qEl.resolve) return qEl.resolve()

  el.val(el.val())
}

const defaultCtlType = {
  init() {
    const el = this
    const qEl = qc(el)
    var $el = $(this)

    var opts = this.opts || {}

    if (opts.fieldName && !qEl.attr('data-field')) qEl.attr({ 'data-field': opts.fieldName })
    if (opts.dsName && !qEl.attr('data-field-for')) qEl.attr({ 'data-field-for': opts.dsName })

    el.ctlTypeClass && qEl.addClass(el.ctlTypeClass)

    if (!$el.is('span.read-only')) wrapControl(this)

    if ($el.is('input, textarea')) {
      var label = this.opts && this.opts.label
      label = label || $el.closest('.resource_set').find('label').html()
      if (!$el.attr('placeholder')) $el.attr('placeholder', this.opts?.placeholder ?? label ?? '')
    }
    if ($el.hasClass('static-text') || opts.obscure) {
      qc(el).attr({ readonly: '', tabindex: '-1' })
      $el.closest('.resource_set').addClass('static')
    }
    if (opts.obscure) $el.attr('type', 'password')

    if (opts.maxLength !== undefined) qEl.attr({ maxlength: opts.maxLength + '' })

    qEl.on('input', () => {
      if (qEl.hasClass('k-input-errbg')) {
        qEl.removeClass('k-input-errbg')
        qEl.attr({ title: undefined })
      }
    })
  },

  // (v, model, populating)
  val(v, model) {
    const el = this
    const me = qc(el)

    if (model) me.model = model

    const fmt = el.opts?.format

    if (arguments.length) {
      const s = el.ctlFormatter
        ? el.ctlFormatter(v, fmt)
        : me.hasClass('float') || me.hasClass('decimal')
          ? ctlFormatters.float(v, fmt)
          : me.hasClass('int')
            ? ctlFormatters.int(v, fmt)
            : me.hasClass('currency')
              ? ctlFormatters.currency(v, fmt)
              : me.hasClass('date')
                ? ctlFormatters.date(v, fmt)
                : me.hasClass('time')
                  ? ctlFormatters.time(v, fmt)
                  : me.hasClass('datetime')
                    ? ctlFormatters.datetime(v, fmt)
                    : fmt
                      ? ow5.toString(v, fmt)
                      : v === null
                        ? ''
                        : v === undefined
                          ? ''
                          : '' + v

      $is(el, 'span') ? $(el).text(s) : (el.value = s)
    }
    v = $is(el, 'span') ? $(el).text() : el.value

    if (v !== undefined)
      v = el.ctlParser
        ? el.ctlParser(v)
        : me.hasClass('float') || me.hasClass('decimal')
          ? ctlParsers.float(v)
          : me.hasClass('int')
            ? ctlParsers.int(v)
            : me.hasClass('currency')
              ? ctlParsers.currency(v)
              : me.hasClass('date')
                ? ctlParsers.date(v, fmt)
                : me.hasClass('datetime')
                  ? ctlParsers.datetime(v, fmt)
                  : me.hasClass('time')
                    ? ctlParsers.time(v, fmt)
                    : v

    return v
  },

  validate(onInvalid, messageArray = []) {
    var el = this
    const { opts } = el

    var name = opts.label || opts.name || opts.fieldName

    var v = el.val()
    var hasValue = !(
      v === undefined ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )
    if ((opts.required || (opts.required !== false && opts.schema?.required)) && !hasValue) {
      if (onInvalid) {
        onInvalid(name, 'must have a value', el, messageArray)
        return false
      }
    }
    if (typeof v === 'number' && isNaN(v) && opts.ctlType === 'combo') {
      if (onInvalid) {
        onInvalid(name, 'must select from the list.', el, messageArray)
        return false
      }
    }
    opts.minLength = opts.minLength || opts.min
    opts.maxLength = opts.maxLength || opts.max

    if (typeof v === 'string' && opts.minLength > (v || '').length) {
      if (onInvalid)
        onInvalid(name, 'must be have min length of ' + opts.minLength, el, messageArray)
      return false
    }

    if (typeof v === 'string' && opts.maxLength < (v || '').length) {
      onInvalid?.(name, __('must be have max length of ') + opts.maxLength, el, messageArray)
      return false
    }

    if (opts.validation && typeof opts.validation === 'object') {
      if (opts.validation.ne !== undefined && v === opts.validation.ne) {
        onInvalid?.(name, 'cannot be ' + opts.validation.ne, el, messageArray)
        return false
      }

      if (opts.validation.url) {
        if (!new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i).test(v)) {
          onInvalid?.(name, __('Is not a valid URL'), el, messageArray)
          return false
        }
      }

      if (opts.validation.regEx) {
        if (!new RegExp(opts.validation.regEx).test(v)) {
          onInvalid?.(name, __('should match ' + opts.validation.regEx), el, messageArray)
          return false
        }
      }
    }

    const ctlType = opts.ctlType ?? opts.edType

    if (
      ctlType === 'date' ||
      ctlType === 'time' ||
      ctlType === 'float' ||
      ctlType === 'int' ||
      opts.dataType === 'date' ||
      opts.dataType === 'time' ||
      opts.dataType === 'float' ||
      opts.dataType === 'int' ||
      opts.dataType === 'datetime'
    ) {
      const lte = opts.validation?.lte ?? opts.max
      if (lte !== undefined && !(v <= lte)) {
        onInvalid?.(name, __('must not be greater than ') + lte, el, messageArray)
        return false
      }
      const lt = opts.validation?.lt
      if (lt !== undefined && !(v < lt)) {
        onInvalid?.(name, __('must be less than ') + lt, el, messageArray)
        return false
      }
      const gte = opts.validation?.gte ?? opts.min
      if (gte !== undefined && !(v >= gte)) {
        onInvalid?.(name, __('must not be less than ') + gte, el, messageArray)
        return false
      }
      const gt = opts.validation?.gt
      if (gt !== undefined && !(v > gt)) {
        onInvalid?.(name, __('must be greater than ') + gt, el, messageArray)
        return false
      }

      // Validation for fields with from value and to value
      var fromField, toField

      if (opts.isToField && opts.fromID) {
        var $top = el.myWin()
        var $from = $top.find('[data-field="' + opts.fromID + '"]')

        if ($from.length > 0) {
          toField = typeof el.val() === 'number' ? el.val().toString() : el.val()
          fromField =
            typeof $from[0].val() === 'number' ? $from[0].val().toString() : $from[0].val()

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                opts.fromID + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }
    }

    return true
  }
}

/**
 * Passive control Types - they don't require any owauto/basicEd inits
 * Lightweight, only init when functionality is called (through the HTMLElement prototype)
 */
const ctlTypes = {}
exports.ctlTypes = ctlTypes

// build the automatic ctl ow_xxx functions
const registerCtl = (name, def) => {
  ctlTypes[name] = def

  if (typeof $ !== 'undefined')
    Object.keys(def).forEach(key => {
      // for each control type function
      if ($.fn['ow_' + key]) return
      $.fn['ow_' + key] = function (...args) {
        this.forEach(el => {
          // todo: qc has first priority
          // if (key !== 'init' && typeof qEl[key] === 'function') return qEl[key](...args)
          // element has next (ow4)
          if (typeof el[key] === 'function') return el[key](...args)

          const ctl = ctlType(el)
          if (ctl?.[key]) return ctl[key].call(el, ...args)

          const qEl = qc(el)
          if (typeof qEl[key] === 'function') return qEl[key](...args)

          console.warn('CTL fn not found: ' + key)
        })
        return this
      }
    })
  const _init = def.init
  def.init = function (...args) {
    addCtlTypeMethodsToQc(qc(args[0] || this), def)
    return _init.call(this, ...args)
  }
}
exports.registerCtl = registerCtl

registerCtl('default', defaultCtlType)
ctlTypes.text = ctlTypes.default
ctlTypes.int = ctlTypes.default
ctlTypes.float = ctlTypes.default
ctlTypes.currency = ctlTypes.default

registerCtl('check', ctlCheck)

registerCtl(
  'date',
  Object.assign({}, defaultCtlType, {
    init() {
      var el = this

      el.ctlFormatter = ctlFormatters.date
      el.ctlParser = ctlParsers.date

      el.requestTabOut = function () {
        $ow_resolve(el)
        return true
      }
      defaultCtlType.init.call(this)

      if (el.parentElement && !el.parentElement.classList.contains('text-icon-after')) {
        qc(el.parentElement).addClass('text-icon-after').addClass('ow-date-wrap')
        qc('i.fa.text-item-icon', html(iconCodes.calendar)).renderTo(el.parentElement)
      }
    },

    resolve() {
      var el = this
      if (el.value) {
        var v = dates.resolveDate(el.value, true)
        if (el.value !== v) el.value = v
      }
    }
  })
)

registerCtl(
  'time',
  Object.assign({}, defaultCtlType, {
    init() {
      var el = this
      el.requestTabOut = function () {
        $ow_resolve(el)
        return true
      }

      if (el.opts.dataType === 'string') {
        el.ctlFormatter = v => v
        el.ctlParser = v => v
      }
      ctlTypes.default.init.call(this)
    },

    resolve() {
      var el = this
      if (el.value) {
        var v = dates.resolveTime(el.value, true, {
          allowSec: el.opts.allowSec ?? el.opts.format?.slice(-3) === ':ss'
        })
        if (el.value !== v) el.value = v
      }
    }
  })
)

registerCtl(
  'datetime',
  Object.assign({}, defaultCtlType, {
    init() {
      var el = this
      el.requestTabOut = function () {
        $ow_resolve(el)
        return true
      }
      ctlTypes.default.init.call(this)

      if (el.parentElement && !el.parentElement.classList.contains('text-icon-after')) {
        $(el).parent().addClass('text-icon-after').addClass('ow-date-wrap').addClass('ow-time-wrap')
        $(el)
          .parent()
          .append('<i class="fa text-item-icon i-time">' + iconCodes.clock + '</i>')
          .append('<i class="fa text-item-icon date-icon">' + iconCodes.calendar + '</i>')
        // el.val = ctlTypes.default.val;
      }
    },

    resolve() {
      var el = this
      if (el.value) {
        var v = dates.resolveDateTime(el.value, true)
        if (el.value !== v) el.value = v
      }
    }
  })
)

registerCtl(
  'textarea',
  Object.assign({}, defaultCtlType, {
    init() {
      var el = this
      defaultCtlType.init.call(this)
      $(el).parent().addClass('ow-textarea-wrap')
      // el.val = ctlTypes.default.val;
    }
  })
)

// col-basiced bridges the gap between gridCol basicEds and basicEds on the form.
registerCtl('col-basiced', {
  init() {
    var el = this
    const g = $(el).closest('.ow-grid')[0]
    if (!g) return

    el.opts =
      // el.opts ||
      Object.create({
        ...g.getColForCell($(el).closest('td')).basicEd
      })

    // el.opts.value = opts.value;
    BasicEd(el, el.opts)
  }
  // val(...args) {
  //   return this.val(...args)
  // }
})

registerCtl('basiced', {
  init() {
    var el = this
    el.opts = el.opts || {}
    BasicEd(el, el.opts)
  }
  // val(...args) {
  //   var el = this
  //   return el.val(...args)
  // }
})

registerCtl(
  'booleanFilter',
  Object.assign({}, defaultCtlType, {
    init() {
      this.opts.list = [
        { Value: null, Text: '' },
        { Value: false, Text: __('false') },
        { Value: true, Text: __('true') }
      ]
      this.opts.showAll = true
      ctlTypes.combo.init.call(this)
    },
    readFilter(filters) {
      if (this.val()) {
        var filter = {
          field: this.opts.fieldName || $(this).attr('data-field'),
          operator: this.opts.filterOperator || $(this).attr('data-filter-operator') || 'eq',
          value: this.val()
        }
        filters.push(filter)
      }
    }
  })
)

const FileUpload = {
  init(el) {
    el = el || this
    var id = el.id || 'fu' + new Date().valueOf()
    defaultCtlType.init.call(this)

    let progressBar

    const progress = qc(
      'div.progress',
      (progressBar = qc('div.progress-bar').attr({
        role: 'progressbar',
        'aria-valuemin': 0,
        'aria-valuemax': 100
      }))
    )

    const qLabel = qc('label.input-file-trigger', this.opts?.label || __('Select a file') + '...')
      .attr({ tabindex: '0' })
      .on('keydown', e => {
        if (e.which === 13 || e.which === 32) qc(el).trigger('click')
      })

    const icon = qc('i.fa.text-item-icon.icon-file-trigger', html(iconCodes['file-o'])).on(
      'click',
      e => el !== e.target && qc(el).trigger('click')
    )

    qc(el)
      .css({ width: '0', height: '0' })
      .props({ icon, progress, qLabel, progressBar })
      .attr({ id, 'data-role': 'upload', type: 'file', accept: el.opts?.accept || '.zip' })
      .on('change', e =>
        qLabel.kids(e.target.value || this.opts?.label || __('Select a file') + '...')
      )

    qc(el.parentElement)
      .addClass('text-icon-after ow-fileupload-wrap')
      .kids([qLabel, icon, progress, qc(el)])

    el.upload = FileUpload.upload
  },

  val() {
    return this.value
  },

  upload() {
    var el = this
    const me = qc(el)
    var file = el.files?.[0]
    if (file) {
      var fileSize = file.size / 1024 / 1024
      if (fileSize > el.opts?.maxFileSize)
        return popError(
          'Error',
          __('Filesize should not greater than') + ' ' + el[0].opts.maxFileSize + ' M',
          5000
        )
    }

    var reader = new FileReader()
    reader.onload = () => {
      var data = new FormData()
      var file = el.files?.[0]
      data.append('saveAs', el.opts.saveAs ? el.opts.saveAs : file && file.name)
      if (el.opts.dirname) data.append('dirname', el.opts.dirname) // if not supply, .tmp\uploads folder
      if (el.opts.maxFileSize) data.append('maxFileSize', el.opts.maxFileSize)
      data.append('fileInput', file)
      if (window.csrfToken) data.append('x-csrf-token', window.csrfToken)

      const update_progressbar = value => {
        me.progressBar.kids([value + '%'])
        if (value == 0) {
          me.icon.css({ display: undefined })
          me.progress.addClass('hide')
          me.progressBar.kids([''])
        } else {
          me.icon.css({ display: 'none' })
          me.progress.removeClass('hide')
        }
      }

      var ajax = new XMLHttpRequest()
      ajax.upload.addEventListener(
        'progress',
        function (evt) {
          var percentage = (evt.loaded / evt.total) * 100
          update_progressbar(Math.round(percentage))
        },
        false
      )
      ajax.addEventListener(
        'load',
        function (evt) {
          if (evt.target.responseText.toLowerCase().indexOf('error') >= 0) {
            popError('Error', evt.target.responseText, 5000)
            qc(el).trigger('ow-file-uploaderror', evt)
          } else qc(el).trigger('ow-file-uploaded', evt.target)

          update_progressbar(0)
          $(el).val('')
          me.qLabel.kids([el.opts?.label || __('Select a file') + '...'])
        },
        false
      )
      ajax.addEventListener(
        'error',
        function (evt) {
          ow0.popError('Error', 'upload failed')
          qc(el).trigger('ow-file-uploaderror', evt)
          update_progressbar(0)
        },
        false
      )
      ajax.addEventListener(
        'abort',
        function (evt) {
          ow0.popError('Error', 'upload aborted')
          qc(el).trigger('ow-file-uploaderror', evt)
          update_progressbar(0)
        },
        false
      )

      qc(el).trigger('ow-file-uploadstart')
      ajax.open(
        window.usePUT ? 'PUT' : 'POST',
        '/data/fileupload?' +
          $param({
            ...(window.csrfToken ? { _csrf: window.csrfToken } : {}),
            ...(el.opts.dirname ? { dirname: el.opts.dirname } : {}),
            ...(el.opts.saveAs ? { saveas: el.opts.saveAs } : {})
          })
      )
      ajax.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
      if (sessionStorage.getItem('sessionid')) {
        ajax.setRequestHeader('sessionid', sessionStorage.getItem('sessionid'))
      }
      ajax.send(data)
    }
    reader.readAsDataURL(file)
  }
}
registerCtl('fileUpload', FileUpload)

/**
 * Finds a passive ctlType dynamically based on attrs
 *
 */
const ctlType = function (el) {
  el = el || this

  if (el.ctlTypeClass) return ctlTypes[el.ctlTypeClass]

  const $top = el.myWin()
  const isAttached = $top && $top.length

  el.ctlTypeClass = 'default'

  const qEl = qc(el)
  el.opts = el.opts || {}
  const extraOpts = { ...(qEl.opts ?? {}), ...el.opts }
  Object.assign(el.opts, extraOpts) // VERY IMPORTANT for qc()
  qEl.opts = el.opts

  const $el = $(el)

  if (isAttached) $top.edInit?.(el, el.opts)
  else
    ow0.dev &&
      console.log(
        'ctlType resolving on detached control',
        el.ctlTypeClass,
        el,
        'view.ed() will not be called for this control'
      )

  if ($(el).attr('data-ctl-type')) el.ctlTypeClass = $(el).attr('data-ctl-type')
  else if (el.opts.ctlType) el.ctlTypeClass = el.opts.ctlType
  else if ($el.is('.basic-ed')) el.ctlTypeClass = 'basiced'
  // does nothing because it's a basic ed and has its own thing going
  else if ($el.is('.ow-grid')) el.ctlTypeClass = 'grid'
  else if ($el.is('.combo')) el.ctlTypeClass = 'combo'
  else if ($el.is('.ow-check')) el.ctlTypeClass = 'check'
  else if ($el.is('.ow-checklist')) el.ctlTypeClass = 'checklistbox'
  else if ($el.is('.date')) el.ctlTypeClass = 'date'
  else if ($el.is('.time')) el.ctlTypeClass = 'time'
  else if ($el.is('.datetime')) el.ctlTypeClass = 'datetime'
  else if ($el.is('textarea')) el.ctlTypeClass = 'textarea'

  const myType = ctlTypes[el.ctlTypeClass] || defaultCtlType
  myType.init?.call(el)

  if (el.opts.onInit) {
    el.opts.onInit(el, el.opts)
    delete el.opts.onInit
  }

  return myType
}
exports.ctlType = ctlType

if (typeof $ !== 'undefined')
  $.fn.ctlType = function () {
    return Array.from(this).map(el => ctlType(el))[0]
  }

/**
 * Uses passive ctlType to get and set data value of ed
 */
HTMLElement.prototype.val = function (...args) {
  const el = this

  if (args[1]) qc(el).model = args[1]

  const hasOwnValMethod = el.val !== HTMLElement.prototype.val

  if (hasOwnValMethod) {
    console.warn('Unexpected condition in HTMLElement.prototype.val', el)
    return el.val(...args) // this should never happen because it wouldn't have arrived here
  }

  const qEl = qc(el)
  if (qEl.val && qEl.val.toString().indexOf('.el).val.apply') === -1) return qEl.val(...args)

  // use ow5 ctlType - ideally this never happens either
  return (ctlType(el)?.val ?? defaultCtlType.val).call(el, ...args)
}

if (typeof $ !== 'undefined') {
  $.fn.ow_val = function (...args) {
    return this.toArray().map(el => {
      // currently there is always a el.val
      const hasOwnValMethod = el.val !== HTMLElement.prototype.val
      return hasOwnValMethod ? el.val(...args) : ctlType(el)?.val?.call(el, ...args)
    })[0]
  }

  $.fn.ow_populate = function (model) {
    this.forEach(el => {
      qc(el).model = model
      if (el.populate) return el.populate(model)

      const ctl = ctlType(el)
      if (ctl?.populate) return ctl.populate.call(el, model)

      const qEl = qc(el)
      if (!ctl && qEl.populate) return qEl.populate(model)

      var fieldName = $(el).attr('data-field') || el.opts?.fieldName
      var v = _v(model, fieldName)
      if (fieldName) el.val(v, model, true)
    })

    return this
  }

  $.fn.ow_readFilter = function (filters) {
    this.forEach(el => {
      if (el.readFilter) return el.readFilter(filters)
      const ctl = ctlType(el)
      if (ctl?.readFilter) return ctl.readFilter.call(el, filters)
      if (!ctl && qc(el).readFilter) return qc(el).readFilter(filters)

      return readFilter.call(el, filters)
    })
    return this
  }
}

const readFilter = function (filters) {
  var el = this
  var v = el.val()

  if (v && dates.isDate(v)) v = v.toJSON()

  var filter = {
    field: el.opts?.fieldName || qc(el).attr('data-field'),
    operator: typeof el.val() !== 'string' ? 'eq' : 'contains',
    value: v
  }

  if (el.ctlTypeClass === 'combo') {
    if (v || v === 0) filter.operator = 'eq'
    else if (typeof v !== 'string') filter.operator = 'eq'
    else if (el.value) {
      filter.operator = 'contains'
      filter.value = el.value || null
    }
  }

  filter.operator = el.opts.op ? el.opts.op : filter.operator

  if (el.opts?.textFilterField && filter.value) {
    filter.field = el.opts.textFilterField
    filter.operator = el.opts.op || $(this).attr('data-filter-operator') || 'contains'
    filter.value = el.value || null
  }

  if (
    allowFilterUndefined[filter.operator] ||
    (filter.value !== undefined && filter.value !== null && filter.value !== '')
  )
    filters.push(filter)
}

exports.readFilter = readFilter

exports.openPopUpFactory = function (el) {
  var opts = el.opts

  return function () {
    if ($(el).parent().hasClass('ow-disabled') || $(el).hasClass('ow-disabled')) return

    var $top = $(el).myWin()

    let popUpViewData = typeof opts.popUp === 'function' ? opts.popUp(el) : opts.popUp

    if (popUpViewData === false) return

    el.popUpOpen = true

    popUpViewData = {
      defaultCallback($win, viewdata) {
        el.popUpOpen = false
        $top.toFront()
        if (viewdata.result) {
          var v = viewdata.result
          if (this.fieldName) v = _v(viewdata.result, this.fieldName)

          if (opts.model) {
            if (opts.objectFieldName) _v(opts.model, opts.objectFieldName, viewdata.result)

            if (
              (el.selectItem
                ? el.selectItem(viewdata.result)
                : $(el).ctlType().selectItem?.call(el, viewdata.result)) === false
            )
              _v(opts.model, opts.fieldName, v)
          }
          el.popUpResult = viewdata.result // if it needs more info.
          qc(el).trigger('change')
          $top.toFront()
        }
      },
      // for overriding
      callback(...args) {
        return this.defaultCallback(...args)
      },
      mode: 'select',
      userRole: el.myWin().viewdata.userRole,
      ...popUpViewData,
      record: el.val(),
      result: null
    }

    if (popUpViewData.childView) openChildView(popUpViewData)
    else ow0.windows.openView(popUpViewData)
  }
}

// dropdown
// abstract code for managing dropdown boxes attached to a textbox
// creates an icon, kb event handlers, dropdown box creation, open. close
// Content should not receive focus, other than clicking on item or button,
// focus is reuurned immediately to the textbox otherwise the dropdown will close.
const openDropDownFactory = function (el, ddOpts) {
  // console.warn('Dropdown keyHandler open: ' + el.dropdownOpen);
  ddOpts = ddOpts || {}

  ddOpts.keyHandler =
    ddOpts.keyHandler ||
    function () {
      // if (e.type === 'keyup') {
      //     setTimeout(function() {
      //         if ()
      //         el.$dropdown.find('.input-val').html(el.value);
      //         }, 1);
      // }
    }

  ddOpts.content =
    ddOpts.content ||
    function (el) {
      return [
        '<span><b>This is a generic dropdown</b>',
        'Nice eh?',
        'To use, pass in ddOpts.content,',
        'or handle the ow-dd-open events,',
        '<div class="input-val">' + el.value + '</div>',
        '</span>'
      ].join('<br/>')
    }

  return function () {
    if (!el.$dropdown || el.dropDownType != ddOpts.dropDownType) {
      el.dropDownType = ddOpts.dropDownType

      el.myWin().parent().find('.ow-ctl-dropdown').detach()

      el.$dropdown = $('<div class="ow5 ow-ctl-dropdown"/>')

      const dd = qc(el.$dropdown[0])

      el.$dropdown.$el = $(el)

      el.$dropdown.empty().append($(ddOpts.content(el)))

      el.$dropdown.css({
        'font-size': '0.9em',
        position: 'absolute',
        'min-width': '60px',
        padding: '0',
        border: 'solid 0.077em #bbb',
        overflow: 'hidden',
        'z-index': 999,
        'background-color': '#fff',
        'box-shadow': '#ccc 0.14em 0.14em 0.3em 0em',
        'box-sizing': 'border-box',
        'max-height': '270.72px',
        'overflow-y': 'auto'
      })

      if (ddOpts.css) dd.css(ddOpts.css)

      if (ddOpts.setWidth !== false) {
        el.$dropdown.css({
          width: $(el).parent().outerWidth()
        })
      }

      el.$dropdown.attr('tabindex', 0) //necessary to detect relatedTarget

      el.$dropdown.setPosition = function () {
        var $x = el.myWin().parent()
        var t = $(el).parent().offset().top - $x.offset().top
        var b = t + $(el).parent().outerHeight()
        var l = $(el).parent().offset().left - $x.offset().left

        var posCss = {
          // top: t + 'px',
          left: l + 'px'
        }
        if ($x.outerHeight() - b > t) {
          posCss.top = b + 'px'
        } else {
          posCss.bottom = $x.outerHeight() - t + 'px'
        }
        el.$dropdown.css(posCss)
      }

      el.$dropdown.data('ow-dropdown', el.$dropdown)
      el.$dropdown.close = function () {
        // console.warn('closeDropDown open: ' + el.dropdownOpen);
        if (el.dropdownOpen) {
          qc(el).trigger('ow-dropdown-close')
          el.$dropdown.detach()
          el.dropdownOpen = false
          qc(el).removeClass('ow-dropdown-open')
        }
      }

      el.$dropdown.on('focus click', '*', function (e) {
        if (this === e.target) el.focus() // set the focus back to the input.
      })

      var keyHandler = function (e) {
        if (!el.dropdownOpen) return // let it propagate normally

        if (ddOpts.keyHandler(e) === false) return false

        if (e.type === 'keydown') {
          if (e.which === 27) {
            // escape
            el.$dropdown.close()
            return false
          }
          if (e.which === 13 && e.shiftKey) {
            // shift+enter
            // console.log('// generic dropdown shift+enter');
            el.$dropdown.close()
            return false
          }

          return
        }

        // below is keydown.
        // pass on to the $wrapper, esp up and down arrow
        if (e.which === 27) {
          // escape
          return false
        }
      }

      $(el)
        .on('blur', function () {
          if (el.dropdownOpen) {
            setTimeout(function () {
              if (el.dropdownOpen) {
                if ($(el).is(':focus')) return
                var $fc = $(':focus')
                if ($fc.is(el.$dropdown) || $fc.closest('.ow-ctl-dropdown').is(el.$dropdown)) {
                  return el.focus()
                }
                el.$dropdown.close()
              }
            }, 300)
            return false
          }
        })
        .on('keydown keyup', keyHandler)
    }

    if (!el.dropdownOpen) {
      el.dropdownOpen = true
      el.myWin().parent().append(el.$dropdown)
      el.$dropdown.setPosition()
      qc(el).addClass('ow-dropdown-open').trigger('ow-dropdown-open')
    }
  }
}
exports.openDropDownFactory = openDropDownFactory

const dateDropDownOpts = function () {
  return {
    setWidth: false,

    keyHandler() {},

    css: {
      padding: '0.5rem',
      borderRadius: '4px',
      maxHeight: undefined,
      fontSize: '0.75em'
    },

    content(el) {
      const cal = (el.calendar = qCalendar(value => {
        el.val(value)
        qc(el).trigger('change')
        el.$dropdown.close()
      }, el.val()))

      return $(cal.render())
    }
  }
}
exports.dateDropDownOpts = dateDropDownOpts

const timeDropDownOpts = function () {
  return {
    setWidth: true,
    keyHandler() {},
    content(el) {
      var $result = $('<div class="combo-list-popup"><ul style="list-style-type:none;"></ul></div>')
      var interval = 30
      for (let index = 0; index < 1440 / interval; index++) {
        var currentHour = Math.floor((index * interval) / 60)
        var currentMin = (index * interval) % 60

        const intValue = index * interval
        const stringValue =
          dates.leftPad(currentHour, 2) +
          ':' +
          dates.leftPad(currentMin, 2) +
          (el.opts.format?.slice(-3) === ':ss' ? ':00' : '')

        const dataValue = el.opts.dataType === 'string' ? stringValue : intValue

        var $timer = $('<li data-value="' + dataValue + '">' + stringValue + '</li>')
        $timer.on('click', () => qc($result[0]).trigger('ow-select', dataValue))
        $timer.appendTo($result.find('ul'))
      }

      $result.on('ow-select', (e, value) => {
        var newValue =
          el.opts.dataType === 'string'
            ? value
            : new Date((el.val() || new Date()).setHours(Math.floor(value / 60), value % 60, 0, 0))
        el.val(newValue)
        qc(el).trigger('change')
        el.$dropdown.close()
      })

      return $result
    },
    dropDownType: 'time'
  }
}
exports.timeDropDownOpts = timeDropDownOpts

// Do we still need this?
exports.basicEditorInits = {}
const beds = exports.basicEditorInits

if (beds) {
  beds.text = (el, opts) => {
    el.classList.remove('basic-ed')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds.static = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('static-text')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds.int = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('int')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds.float = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('float')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds.currency = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('currency')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds.lookup = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('combo')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds['lookup-ajaxtextvalue'] = (el, opts) => {
    el.classList.remove('basic-ed')
    el.classList.add('combo')
    if (opts.width) el.classList.add('wrap-' + opts.width)
    return el
  }
  beds['checklistbox'] = el => {
    el.classList.remove('basic-ed')
    ctlTypes.checklistbox.init.call(el)
    return el
  }
}

if (typeof $ !== 'undefined')
  $('body') // This is done at body level now.
    .on('keydown', '.ow5 input.date', function (e) {
      if (e.which === 13) {
        var el = this
        if (!el.dropdownOpen) {
          if ($(el).hasClass('static-text') || el.disabled) return
          if (e.shiftKey || el.opts.openOnEnterKey) openDropDownFactory(el, dateDropDownOpts())()
        } else el.$dropdown.close()

        return false
      }
    })
    .on('click', '.ow5 .ow-date-wrap i', function () {
      var el = $(this).closest('.ow-date-wrap').find('input')[0]
      if (el) {
        el.focus()
        if (el.dropdownOpen) el.$dropdown.close()
        else {
          if ($(el).hasClass('static-text') || el.disabled) return
          var isTime = $(this).hasClass('i-time')
          openDropDownFactory(el, isTime ? timeDropDownOpts() : dateDropDownOpts())()
        }
        return false
      }
    })
    .on('click', '.ow5 .ow-check:not(.basic-ed):not(.check5)', function () {
      if (qc(this).qCheck) return
      $(this).toggleClass('on')
      qc(this).trigger('change')
    })
    .on('keypress', '.ow5 .ow-check:not(.check5)', function (e) {
      if (qc(this).qCheck) return
      if (e.which === 32) {
        this.click()
        e.preventDefault()
        return false
      }
    })
    .on('keyup', '.ow5 input.float, .ow5 input.int, .ow5 input.currency', function (e) {
      if (e.which > 46 || e.which === 8) {
        if (
          {
            int: 1,
            default: 1,
            text: 1,
            float: 1,
            currency: 1
          }[this.ctlTypeClass] !== 1
        )
          return

        var isFloat = $(this).hasClass('float') || $(this).hasClass('currency')
        const decimal = isFloat ? culture().numberFormat['.'] : '' // todo: other decimal symbols eg. ,

        var validChars = Array.prototype.reduce.call(
          '-0123456789' + decimal,
          (t, c) => {
            t[c] = 1
            return t
          },
          {}
        )
        var value = Array.prototype.filter.call(this.value, x => x in validChars).join('')

        if (decimal) {
          // only a single decimal point
          value = value.split(decimal)
          if (value.length > 1) value[1] = decimal + value[1]
          value = value.join('')
        }
        if (value !== this.value) this.value = value
      }
    })
    .on('blur', '.ow5 input.float, .ow5 input.int, .ow5 input.currency', function () {
      this.val(this.val())
      // this.value = ow5.toString(parseCurrency(this.value), 'c2');
    })
    .on('keyup', '.ow5 input[data-field]', function (e) {
      if (e.which === 13 && !e.shiftKey) {
        var el = this
        $ow_resolve(el)
      }
    })
    .on('keydown', '.ow5 input.date', function () {
      var el = this
      el.prevValue = el.value
    })
    .on('keyup', '.ow5 input.date', function (e) {
      var el = this
      if (e.which === 8) {
        // backspace
        return
      }
      if (e.which === 46) {
        // delete
        var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }
      if (el.value !== el.prevValue) {
        var x = el.selectionEnd
        var caretAtEnd = x === el.value.length
        var v = dates.resolveDate(el.value, false, undefined, { position: el.selectionEnd })
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        if (el.value !== v) el.value = v
        el.prevValue = v
        if (caretAtEnd) return
        el.selectionStart = x
        el.selectionEnd = x
      }
    })
    .on('keydown', '.ow5 input.time', function () {
      this.prevValue = this.value
    })
    .on('keyup', '.ow5 input.time', function (e) {
      var el = this

      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
        if (':'.indexOf(charDeleted) > -1) return
      }

      if (el.value !== el.prevValue) {
        var x = el.selectionEnd
        var caretAtEnd = x === el.value.length
        var v = dates.resolveTime(
          el.value,
          false,
          { allowSec: el.opts.allowSec ?? el.opts.format?.slice(-3) === ':ss' },
          { position: el.selectionEnd }
        )
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        el.value = v
        el.prevValue = v
        if (caretAtEnd) return
        el.selectionStart = x
        el.selectionEnd = x
      }
    })

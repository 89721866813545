const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const { setDragRectHandler } = require('../setDragRectHandler')

// const clearSelection = (gGrid) => {
//   gGrid.dc.recs
//     .map(r => qGrid.getTr(r, false))
//     .filter(x => x)
//     .forEach(tr => {
//       delete tr.selected
//       tr.removeClass('ow-selected')
//       delete tr.rec[opts.SelectedFieldName ?? 'Selected']
//     })
// }

const setSelected = (qGrid, tr, selected = true) => {
  selected ? (tr.selected = true) : delete tr.selected
  selected ? tr.addClass('ow-selected') : tr.removeClass('ow-selected')
  selected
    ? (tr.rec[qGrid.opts.SelectedFieldName ?? 'Selected'] = true)
    : tr.rec[qGrid.opts.SelectedFieldName ?? 'Selected']
}
module.exports.setSelected = setSelected

module.exports.toggleSelect = (qGrid, tr) => {
  setSelected(qGrid, tr, !tr.selected)
}

module.exports.selectableGrid = (grid, rowParent) => {
  grid.css({ userSelect: 'none' })

  rowParent
    .on(
      'keydown mousedown',
      e => !e.shiftKey && (grid.state.lastClickedRow = grid.state.currentRow)
    )
    .on('click keyup', e => {
      if (e.shiftKey) return
      const tr = e.target.closest('tr.row')
      if (tr) grid.state.lastClickedRow = qc(tr)
    })
    .on('init', () =>
      setDragRectHandler(
        rowParent.el,
        () => grid.dc.recs.map(r => grid.getTr(r, false)?.el).filter(x => x),
        (e, rect, selected) => {
          if (!e.ctrlKey)
            grid.dc.recs
              .map(r => grid.getTr(r, false))
              .filter(x => x)
              .forEach(tr => {
                delete tr.selected
                tr.removeClass('ow-selected')
              })

          // add to selection
          selected?.forEach(el => {
            const tr = qc(el)
            tr.selected = !tr.selected
            tr.toggleClass('ow-selected')
          })
        } // dragEnd
      )
    )
}

module.exports.selectableRow = (grid, tr) => {
  const { state } = grid

  tr.addClass('ow-selectable')
    .on('keypress', e => {
      // spacebar
      if (e.which === 32) {
        if (e.target === tr.el) {
          if (e.shiftKey) {
            const lastClickedRow = state.lastClickedRow ?? state.currentRow
            if (!lastClickedRow) return

            const toIndex = grid.recs.indexOf(tr.rec)
            let fromIndex = grid.recs.indexOf(lastClickedRow.rec)
            if (fromIndex === -1) return

            const f = Math.min(fromIndex, toIndex),
              t = Math.max(fromIndex, toIndex)

            grid.recs.forEach((r, i) => {
              const inRange = i <= t && i >= f

              const tr = grid.getTr(r, false)
              if (!tr) return

              if (!e.ctrlKey && !inRange) setSelected(grid, tr, false)
              if (inRange)
                if (e.ctrlKey) tr.toggleSelect()
                else setSelected(grid, tr, true)
            })
          } else tr.toggleSelect()
          return killEvent(e, true) // prevent the built in scrollbar scrolling
        }
      }
    })
    .on(
      'click',
      e => {
        if (e.shiftKey) {
          const lastClickedRow = state.lastClickedRow ?? state.currentRow ?? tr
          if (!lastClickedRow) {
            state.lastClickedRow = tr
            return
          }

          const toIndex = grid.recs.indexOf(tr.rec)
          let fromIndex = grid.recs.indexOf(lastClickedRow.rec)
          if (fromIndex === -1) {
            state.lastClickedRow = tr
            return
          }

          const f = Math.min(fromIndex, toIndex),
            t = Math.max(fromIndex, toIndex)

          grid.recs.forEach((r, i) => {
            const inRange = i <= t && i >= f

            const tr = grid.getTr(r, false)
            if (!tr) return

            if (!e.ctrlKey && !inRange) setSelected(grid, tr, false)
            if (inRange)
              if (e.ctrlKey) tr.toggleSelect()
              else setSelected(grid, tr, true)
          })

          state.lastClickedRow = tr
          return
        }

        if (e.ctrlKey) {
          state.lastClickedRow = tr
          tr.toggleSelect()
          return
        }
      },
      true // useCapture because we need this before currentRow.
    )
    .bindState(
      () => tr.selected === true,
      sel => (sel ? tr.addClass('ow-selected') : tr.removeClass('ow-selected'))
    )
}

// no-jquery.js
const { isString } = require('./js-types')
const { trigger, on } = require('./events')
const { htmlEncode } = require('./html-encode')

/**
 * converts a function of element to call either element OR if a set of elements, call on all
 * @param {function} fn - function to call on each element
 * @param {boolean} returnFirst - if true, returns the result of the first element
 */
const makeMulti = (fn, returnFirst) =>
  function (els, ...args) {
    if (typeof els === 'undefined' || els === null) return returnFirst ? undefined : []
    if (typeof els === 'object' && (Array.isArray(els) || els.length !== undefined)) {
      if (!els.length) return els
      const resArray = Array.from(els, el => fn(el, ...args))
      return returnFirst ? resArray[0] : els
    }
    return fn(els, ...args)
  }

const $is = makeMulti((el, selector) => {
  if (typeof selector !== 'string') {
    if (el === selector) return true
    throw '$is only accepts string selectors or an element'
  }

  return el.matches(selector)
}, true)

const $text = makeMulti((el, v) => {
  if (typeof v !== 'undefined') el.innerHTML = htmlEncode(v)
  return el.innerHTML
}, true)

const $index = el => {
  let c = el.parentElement?.children || [],
    i = 0
  for (; i < c.length; i++) if (c[i] === el) return i
}

/**
 *
 * @param {string|HTMLElement} el or Selector
 * @param {string|HTMLElement|undefined} el or selector
 * @returns
 */
const $find = (selector, el = document) => {
  if (isString(el)) return $find(el, selector) // swap for backward compat
  return el.find?.(selector) ?? Array.from(el.querySelectorAll(selector)) // top has find
}
const $attr = (el, attr, val) =>
  typeof val !== 'undefined' ? el.setAttribute(attr, val) : el.getAttribute(attr)
const $outerWidth = el => el.offsetWidth
const $outerHeight = el => el.offsetHeight
const $scrollLeft = el => el.scrollLeft
const $css = makeMulti((el, css) => Object.keys(css).forEach(k => (el.style[k] = css[k])))
const $offset = el => {
  let result = el.getBoundingClientRect()
  // log('offset: ', result, ' jquery:', $(el).offset())
  return result
}
const $addClass = makeMulti((el, c) => el.classList.add(c))
const $removeClass = makeMulti((el, c) => el.classList.remove(c))
const $hasClass = makeMulti((el, c) => el.classList.contains(c), true)
const $toggleClass = makeMulti((el, c) =>
  $hasClass(el, c) ? $removeClass(el, c) : $addClass(el, c)
)

/**
 * Registers a function as an event handler on one or more HTML elements.  Similar to jQuery's $.on() method.
 * @param {object} el - HTTML DOM element object.
 * @param {string} et - function to call on each element
 * @param {function} handler - function to call on each element
 */
const $on = makeMulti(on)

const $trigger = makeMulti(function (el, et, args = []) {
  return trigger(el, et, ...args)
})

const $prev = (el, selectorOrArrayOfEl, topEl = el.parentElement) => {
  const a = Array.isArray(selectorOrArrayOfEl)
    ? selectorOrArrayOfEl
    : $find(topEl, selectorOrArrayOfEl)
  return a[a.indexOf(el) - 1]
}

const $next = (el, selectorOrArrayOfEl, topEl = el.parentElement) => {
  const a = Array.isArray(selectorOrArrayOfEl)
    ? selectorOrArrayOfEl
    : $find(topEl, selectorOrArrayOfEl)
  return a[a.indexOf(el) + 1]
}

const no$ = el => {
  // selector string
  let result = typeof el === 'string' ? $find(document.body, el) : [el]
  result.attr = (...args) => $attr(el, ...args)
  result.find = (...args) => $find(el, ...args)
  result.removeClass = (...args) => $removeClass(el, ...args)
  result.addClass = (...args) => $addClass(el, ...args)
  result.hasClass = (...args) => $hasClass(el, ...args)
  result.on = function (...args) {
    $on(el, ...args)
    return this
  }
  result.not = function (...args) {
    return this.filter(x => !no$(x).is(...args))
  }
  result.is = (...args) => $is(el, ...args)
  result.text = (...args) => $text(el, ...args)
  result.css = (...args) => $css(el, ...args)
  result.index = (...args) => $index(el, ...args)
  result.trigger = function (et, args = []) {
    if (arguments.length > 2) {
      debugger
      console.warn(
        'no$().trigger(eventType, argsArray) has been called with an extra argument.  jquery compatible $.trigger takes an array of event args'
      )
    }
    return $trigger(el, et, args)
  }
  result.next = (...args) => $next(el, ...args)
  result.prev = (...args) => $prev(el, ...args)
  result.offset = () => $offset(el)
  return result
}
window.no$ = no$
window.$ = window.$ ?? no$

module.exports = {
  no$,
  $on,
  $trigger,
  $scrollLeft,
  $index,
  $text,
  $is,
  $find,
  $prev,
  $next,
  $attr,
  $outerWidth,
  $outerHeight,
  $css,
  $offset,
  $addClass,
  $removeClass,
  $hasClass,
  $toggleClass,
  makeMulti
}

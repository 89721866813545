const { rs } = require('./rs')
const { addCss } = require('./stylesheet')
const { modelBind } = require('./data-model')
const { filterFunctions } = require('./filters')
const { popInvalid } = require('../pop-box')
const { qc } = require('../cmp/qc')
const { isNumeric, ctlParsers, ctlFormatters } = require('../ctl-format')
const theme = require('../theme')
const { formatString } = require('../culture')

/**
 * Cmp for using as part of an input based control - text6 and combo both use input6
 * @param {object} props
 * @param {boolean} props.disabled
 * @param {boolean} props.readOnly
 * @returns Cmp Object
 */
const input6 = props => {
  props.tag = 'input'
  const me = qc(props)
  if (me.disabled) me.readOnly = true

  const w = qc('span.ow-ctl-wrap.text6', me).props({ input: me })
  if (me.disabled) w.addClass('ow-disabled') // makes the input transparent "grayed out"

  me.attr({
    placeholder: me.placeholder || me.label || undefined
  })

  if (me.model) modelBind(me.view, me, me.model) // will renderAsync() when data in the model changes

  // 'static-text'
  me.bindState(
    () => me.readOnly || me.obscure || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )

  me.rs = () => rs({ label: me.label || '' }, w)

  w.rs = () => me.rs()

  me.wrap = () => w

  return me
}

const formatV = (v, fmt, formatter) => {
  if (formatter) return formatter(v, fmt) || ''
  if (v && fmt) return common.toString(v, fmt) || ''
  return v === null || v === undefined ? '' : formatString(v)
}

const text6 = props => {
  const { label, fieldName } = props
  props.tag = props.tag || (props.type === 'textarea' ? 'textarea' : 'input')

  const me = qc(props).props({
    val(v) {
      const { format, parser, formatter, el } = this

      if (v !== undefined) {
        const value = formatV(v, format, formatter) || ''
        this.attr({ value })
        if (el) this.trigger('ow-change')
      }
      v = el.value

      if (v && parser) return parser(v)

      if (v === '') return this.blankValue
      return v
    },

    validate(onInvalid, messageArray = []) {
      const { label, fieldName, el, required, schema } = this

      var name = label || fieldName

      var v = this.val()
      var hasValue = !(
        v === undefined ||
        v === null ||
        v === '' ||
        (typeof v === 'string' && v.trim() === '')
      )
      if ((required || (required !== false && schema && schema.required)) && !hasValue) {
        if (onInvalid) {
          onInvalid(name, 'must have a value', el, messageArray)
          return false
        }
      }

      let { minLength, maxLength, min, max, validation } = this

      if (typeof v === 'number' && isNaN(v) && this.edType === 'lookup') {
        if (onInvalid) {
          onInvalid(name, 'must select from the list.', el, messageArray)
          return false
        }
      }
      minLength = minLength || min
      maxLength = maxLength || max

      if (typeof v === 'string' && minLength && minLength < (v || '').length) {
        if (onInvalid) onInvalid(name, 'must be have min length of ' + minLength, el, messageArray)
        return false
      }
      if (typeof v === 'string' && maxLength && maxLength < (v || '').length) {
        if (onInvalid) onInvalid(name, 'must be have max length of ' + maxLength, el, messageArray)
        return false
      }

      if (typeof validation === 'object' && validation) {
        if ('$ne' in validation && v === validation.$ne) {
          if (onInvalid) onInvalid(name, 'cannot be ' + validation.$ne, el, messageArray)
          return false
        }
      }

      const edType = this.edType || this.type

      let { isToField, fromCmp } = this

      // Validation for fields with from value and to value
      if (['date', 'time', 'currency', 'float', 'int'].find(x => x === edType)) {
        if (isToField && fromCmp) {
          let fromField, toField
          let v = this.val()
          toField = typeof v === 'number' ? common.toString(v) : v

          const vFrom = fromCmp.val()
          fromField = typeof vFrom === 'number' ? common.toString(vFrom) : vFrom

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                fromCmp.label + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }

      return true
    },

    readFilter(filters) {
      let { el, op, fieldName } = this
      let v = this.val()
      this.isSet = false

      if (el.value === '' && (op === 'contains' || op === 'eq')) return

      var filter = {
        field: fieldName,
        operator: op,
        value: v
      }

      filter.operator = op ? op : filter.operator

      if (this.textFilterField && filter.value) {
        filter.field = this.textFilterField
        filter.operator = op || 'contains'
        filter.value = el.value || null
      }

      if (this.filterMap && filter.value) {
        const s = filter.value.toLowerCase()
        const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

        filter.value = Object.keys(this.filterMap)
          .filter(matchFilter)
          .map(k => this.filterMap[k])

        if (filter.value.length === 0) {
          popInvalid(__('Invalid value for ' + filter.field))
          this.attr({ value: '' })
          return
        }
        filter.operator = 'in'
      }

      // if (filter.value !== null && filter.value !== '')
      filters.push(filter)

      this.isSet = true // indicates to the outside that there's a filter set on this field/col
    }
  })

  me.on('focus', () => {
    //normalize
    var decimal = common.culture().numberFormat['.']
    if (!me.el.value) return
    if (me.type === 'currency')
      me.el.value = ('' + ctlParsers.currency(me.el.value)).replace('.', decimal)
    else if (me.type === 'float')
      me.el.value = ('' + ctlParsers.float(me.el.value)).replace('.', decimal)
  })
    .on('keyup', e => {
      if (e.which === 13 && !e.shiftKey) me.resolve && me.resolve() // enter

      const { type } = me

      if (type === 'float' || type === 'currency' || type === 'int') {
        if (e.which > 46 || e.which === 8) {
          var decimal = common.culture().numberFormat['.'] //use standard decimal

          const { el } = me

          let isFloat = type === 'float' || type === 'currency'
          if (!isFloat) decimal = ''

          let validChars = Array.prototype.reduce.call(
            '-0123456789' + decimal,
            (t, c) => {
              t[c] = 1
              return t
            },
            {}
          )
          let value = Array.prototype.filter.call(el.value, x => x in validChars).join('')

          // only a single decimal point
          value = value.split(decimal)
          if (value.length > 1) value[1] = decimal + value[1]
          value = value.join('')

          if (value !== el.value) el.value = value

          me.trigger('ow-change')
        }
      } else if (type === 'string' || type === 'textarea' || !type) me.trigger('ow-change')
    })
    .on('blur', () => {
      if ({ float: true, int: true, currency: true }[me.type]) me.val(me.val())
    })

  if (me.disabled) me.readOnly = true

  const w = qc('span.ow-ctl-wrap.text6', me, { input: me })
  if (me.disabled) {
    // me.addClass('ow-disabled')
    w.addClass('ow-disabled') // makes the input transparent "grayed out"
  }

  if (props.type === 'textarea') w.addClass('ow-textarea-wrap')
  if (props.width)
    w.css({
      display: 'inline-block',
      width: props.width
    })

  me.parser = me.parser || ctlParsers[me.type] || null
  me.formatter = me.formatter || ctlFormatters[me.type] || null

  if (!('blankValue' in me)) {
    me.blankValue = ''
    if (me.type === 'currency') me.blankValue = undefined
    if (me.type === 'float') me.blankValue = undefined
    if (me.type === 'int') me.blankValue = undefined
    if (me.type === 'date') me.blankValue = undefined
    if (me.type === 'datetime') me.blankValue = undefined
    if (me.type === 'time') me.blankValue = undefined
  }

  me.attr({ type: me.obscure ? 'password' : 'text' })
  let ph = me.placeholder || me.label || ''
  if (ph) me.attr({ placeholder: ph })

  let v = me.value !== undefined ? formatV(me.value, me.format, me.formatter) : ''
  if (v) me.attr({ value: v })

  if (me.model) modelBind(me.view, me, me.model) // will renderAsync() when data in the model changes

  // 'static-text'
  me.bindState(
    () => me.readOnly || me.obscure || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )

  me.rs = () => rs({ label }, w)

  w.rs = () => me.rs()

  me.wrap = () => w

  return me
}

module.exports = { text6, input6 }

addCss(
  'text6-css',
  `
#scope span.ow-ctl-wrap { display: inline-block; }
#scope .text6.ow-ctl-wrap.ow-disabled { background: transparent; }
#scope .text6.ow-ctl-wrap.ow-disabled input { background: transparent; }
#scope i.text-item-icon { color: ${theme.iconBlue}; }
`
)

const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { popError } = require('../pop-box')
const { $offset } = require('../no-jquery')
const { makeDropdown7 } = require('./makeDropdown7')

const scrollItemIntoView = selectedLi => {
  const ul = selectedLi.el.parentElement

  if (!ul) return

  const bottom = selectedLi.el.offsetHeight + selectedLi.el.offsetTop
  if (bottom > ul.scrollTop + ul.clientHeight)
    ul.scrollTop = selectedLi.el.offsetTop - (ul.clientHeight - 40)
  else if (selectedLi.el.offsetTop < ul.scrollTop) ul.scrollTop = selectedLi.el.scrollTop - 2

  // console.log('scrolling to item at:', ul.scrollTop, 'px')
}

/**
 *
 * @param {object} opts
 * @param {Array} opts.list
 * @param {string} opts.url
 * @param {bool} opts.isFilterControl
 * @param {string} opts.textFilterField
 * @param {object} opts.filterMap
 * @param {string} opts.fieldName
 * @param {string} opts.dsName
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {string} opts.objectFieldName
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {function} opts.itemTemplate - returns HTML string a dropdown item
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 *
 * @returns {qc}
 */
const combo7 = opts => {
  if (!opts.view) throw 'combo7 requires opts.view'

  const viewParent = opts.view.qTop.el.parentElement

  // if (!qc(viewParent).find('.css-combo7')[0]) {
  //   viewParent.id = viewParent.id || opts.view.qTop.el.id + '_p'
  //   styles('#' + viewParent.id).renderTo(viewParent)
  // }
  let open = false

  opts.objectFieldName = opts.objectFieldName ?? '_' + (opts.fieldName ?? 'Value')
  opts.textField = opts.textField ?? 'Text'
  opts.valueField = opts.valueField ?? 'Value'

  let typedText = '',
    matchedOn
  let nominee, dd, selectedLi

  let list = opts.list ?? []

  const findMatch = () => {
    selectedLi = undefined

    if (typedText) {
      const s = typedText.toLowerCase()
      return s
        ? list.find(item => (me.template(item)?.toLowerCase() ?? '').indexOf(s) + 1) ?? list[0]
        : list[0]
    }

    const v = me.val()
    return v === undefined
      ? list[0]
      : list.find(item => _v(item, opts.valueField) + '' === v + '') ?? me.selectedItem() ?? list[0]
  }

  const addSelectedItem = list => {
    const v = me.val()
    if (v === undefined || v === null) return list // no value

    if (!list.find(x => _v(x, opts.valueField) === v)) {
      const item = me.selectedItem()
      if (item) list.unshift(item)
    }
    return list
  }

  const loadingLine = { [opts.textField ?? 'Text']: __('Loading...') }
  const fetchList = () => {
    if (opts.list) return addSelectedItem(opts.list)

    if (opts.url && matchedOn !== typedText) {
      const sub = typedText
      $ajax({ url: opts.url, data: { sub } })
        .then(res => {
          matchedOn = sub
          list = addSelectedItem(res.data || res)
          if (sub === '' && res.data && res.data?.length === res.total) {
            delete opts.url
            opts.list = list
          }
          renderList()
        })
        .catch(() => popError('lookup failed to load data.'))

      return [loadingLine]
    }

    return list
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    return (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
  }

  const renderList = async () => {
    const ddParent = viewParent

    if (dd)
      return open
        ? dd.el?.parentElement
          ? dd.renderAsync()
          : dd.renderTo(ddParent)
        : dd.el?.parentElement && dd.el.remove()

    dd = qc('ul.combo7.dropdown')
    no$(ddParent)
      .find('.ow-ctl-dropdown, .dropdown')
      .forEach(el => el !== dd && el.remove())

    list = fetchList()

    const buildItemLi = item =>
      qc('li.item', me.template(item))
        .bindState(
          () => nominee,
          function () {
            if (item === loadingLine) return
            if (!nominee) nominee = findMatch()
            nominee === item ? this.addClass('ow-selected') : this.removeClass('ow-selected')
            if (nominee === item) {
              selectedLi = this
              scrollItemIntoView(selectedLi)
            }
          }
        )
        .on('click', () => {
          if (item === loadingLine) return
          me.select(item)
          open = false
          typedText = ''
          nominee = item
          dd.el.remove()
          dd.renderAsync()
        })

    const buildList = () =>
      dd.kids(
        list.length ? list.map(buildItemLi) : qc('li', __('No matches')).css({ opacity: '0.5' })
      )

    makeDropdown7(dd, ddParent, () => $offset(me.wrap().el)).bindState(() =>
      dd.css({
        width:
          (opts.listWidth === undefined ? $offset(me.wrap().el).width : opts.listWidth || 300) +
          'px'
      })
    )

    dd.kids(buildList())
      .attr({ tabindex: '-1' })
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(
        () => opts.list,
        async () => {
          opts.list && (list = opts.list)
          buildList()
        }
      )
      .bindState(() => list, buildList)

    if (open) dd.renderTo(ddParent)
  }

  const me = input7(opts)
    .addClass('combo7')
    .props({
      template(model, i) {
        if (this.opts.template) return this.opts.template(model, i)

        if (this.opts.valueTextDisplay)
          return (
            (_v(model, this.opts.valueField ?? 'Value') ?? '') +
            ' - ' +
            _v(model, this.opts.textField ?? 'Text')
          )

        return _v(model, this.opts.textField ?? 'Text') ?? 'item-' + i
      },

      select(item) {
        if (item === loadingLine) return

        const me = this
        if (!item) {
          me.selectedItem = undefined
          _v(this.model, this.opts.objectFieldName, undefined)
          me.val(undefined)
          me.value('')
          return
        }
        // me.selectedItem = item
        _v(this.model, this.opts.objectFieldName, item)
        me.val(_v(item, me.opts?.valueField ?? 'Value'))

        me.value(me.template(item))
        me.trigger('ow-select', item)
        me.renderAsync()
      },

      selectedItem() {
        return _v(this.model, this.opts.objectFieldName)
      },

      val(v, model, populating = false) {
        const isOw4Read = v === undefined && model

        if (!isOw4Read && arguments.length > 0) {
          let item
          const prev = _v(this.model, this.opts.fieldName ?? 'Value')
          let hasChanged = v !== prev

          if (hasChanged) _v(this.model, this.opts.fieldName ?? 'Value', v)

          item = _v(this.model, me.opts.objectFieldName)
          if (item && _v(item, this.opts.valueField) !== v) item = undefined
          if ((v ?? undefined) !== undefined) item = findMatch()
          _v(this.model, me.opts.objectFieldName, item)

          me.value(item ? me.template(item) : '')
          if (hasChanged && !populating) this.trigger('ow-change')

          this.renderAsync()
        }
        return _v(this.model, this.opts.fieldName ?? 'Value')
      },

      populate(model) {
        const me = this
        const { opts } = me
        const v = _v(model, me.opts.fieldName ?? 'Value')
        let item = _v(model, me.opts.objectFieldName)

        if (v !== undefined && v !== null && (!item || _v(item, opts.valueField) === v)) {
          if (opts.list) item = opts.list.find(x => _v(x, opts.valueField) === v)

          if (!item) {
            console.warn('combo7', me.opts.objectFieldName, 'not found on model.  Creating dummy.')
            item = {
              dummy: true,
              [me.opts.valueField]: v,
              [me.opts.textField]: v + ''
            }
          }
          _v(model, me.opts.objectFieldName, item)
        }

        me.value(item ? me.template(item) : '')
      },

      readField(model) {
        const me = this
        _v(model, me.opts.fieldName ?? 'Value', this.val())
        _v(model, me.opts.objectFieldName, this.selectedItem())
      },

      readFilter(filters) {
        const { opts } = this
        let v = this.val()

        if (
          this.el.value === '' &&
          (this.op === undefined || this.op === 'contains' || this.op === 'eq')
        ) {
          delete this.op // use this not isSet
          return
        }
        this.op || (this.op = this.opts.op ?? 'contains')

        const operator = this.op

        var filter = {
          field: opts.fieldName,
          operator,
          value: v
        }

        if (opts.textFilterField && filter.value) {
          filter.field = opts.textFilterField
          filter.operator = operator || 'contains'
          filter.value = this.el.value || null
        }

        if (opts.filterMap && filter.value) {
          const s = filter.value.toLowerCase()
          const matchFilter = v => ow5.filterFunctions[filter.operator](v.toLowerCase(), s)

          filter.value = Object.keys(opts.filterMap)
            .filter(matchFilter)
            .map(k => opts.filterMap[k])

          if (filter.value.length === 0) {
            ow.popInvalid(__('Invalid value for ' + filter.field))
            this.value('')
            return
          }
          filter.operator = 'in'
        }

        filters.push(filter)
      }
    })
    .on('init', (e, el) => {
      // for use with ow4
      // el.val = me.val
      // el.populate = (...args) => me.populate(...args)
      el.readFilter = (...args) => me.readFilter(...args)
    })
    .props({ model: opts.model ?? {} })
    .on('keydown', e => {
      // tab
      if (e.which === 9) {
        if (me.val() === undefined && nominee && opts.selectOnTab !== false) {
          me.select(nominee)
          typedText = ''
        }
        return
      }

      // enter
      if (e.which === 13 && open) {
        me.select(nominee)
        typedText = ''
        open = false
        renderList()
        return
      }

      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        open = !open
        renderList()
        return
      }

      // escape
      if (e.which === 27) {
        open = false
        renderList()
        return
      }

      // downarrow
      if (e.which === 40) {
        if (!open) return
        nominee = list[Math.min(list.length - 1, list.indexOf(nominee) + 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        if (!open) return
        nominee = list[Math.max(0, list.indexOf(nominee) - 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
    .on('input', async () => {
      typedText = me.el.value

      const opening = open === false

      if (opening) open = true

      if (typedText !== matchedOn) {
        list = await fetchList()
        nominee = findMatch()
        return renderList()
      }

      if (opening) renderList()
    })
    .bindState(() => {
      if (open && !hasFocus()) {
        console.log('closing combo not focused')
        open = false
        me.renderAsync()
      }
    })
    .bindState(
      () => open,
      () => open && (dd ?? renderList())
    )
    .bindState(
      () => opts.list,
      v => (list = v) && (dd ?? renderList())
    )

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  me.wrap()
    .addClass('combo7 text-icon-after')
    .on('focusout', e => {
      if (e.target !== me.el) return
      setTimeout(() => {
        if (hasFocus()) return
        if (typedText && nominee && nominee !== me.selectedItem() && nominee !== loadingLine)
          me.select(nominee)
        open = false
        renderList()
        me.renderAsync()
      }, 100)
    })
    .kids([
      me,
      icon('caretDown')
        .addClass('combo-icon')
        .bindState(
          () => me.disabled,
          function (v) {
            this.css({
              cursor: !v ? 'pointer' : undefined,
              color: v ? '#ddd' : undefined
            })
          }
        )
        .on('click', e => {
          if (me.disabled) return
          open = !open
          me.el.focus()
          renderList()
          me.renderAsync()
          return killEvent(e, true)
        })
    ])
  return me
}

module.exports = { combo7 }

const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { react } = require('./data-model7')
const { killEvent } = require('../killEvent')

/**
 * modelBind binds a qCtl object to a model
 * The model must have had its meta initialized
 * references to models shouldn't be held, they need to be changeable in some cases so references should be qCtl.model
 * populate() always sets model property on the qCtl.
 * populate uses dsName to set the model
 *
 * on change events are set to update the model
 *
 * @param {Cmp} qCtl
 * @returns qCtl
 */
const modelBind = cmp => {
  if (cmp.opts?.fieldName)
    cmp.bindState(
      () => cmp.model && _v(cmp.model, cmp.opts.fieldName),
      v => {
        if (document.activeElement !== cmp.el)
          cmp.val ? cmp.val(v, cmp.model, true) : cmp.el?.val?.(v, cmp.model, true)
      }
    )

  return cmp.on('ow-change change', e => {
    if (cmp.el !== e.target) return
    if (cmp.opts?.fieldName) {
      const unknown = {}
      const v = (cmp.val ?? cmp.el?.val ?? (() => unknown))?.()
      if (v === unknown) return

      _v(cmp.model, cmp.opts.fieldName, v)
      react(cmp.model, cmp.opts.fieldName)
    }
  })
}

/**
 * dataBind sets qCtl the attributes data-field=fieldName data-field-for=dsName (or data-filter-for=dsName if filter)
 * if filter it also calls opts.dc.load() if available on change and F2.
 *
 * adds qCtl.populate() if there isn't already one.  populate calls modelBind() if there wasn't already a model.
 *
 * @param {Cmp} qCtl
 * @returns qCtl
 */
const dataBind = qCtl => {
  if (qCtl.opts.fieldName) qCtl.attr({ 'data-field': qCtl.opts.fieldName })
  const dsName = qCtl.opts.dsName ?? qCtl.opts.dc?.dsName ?? qCtl.opts.dc?.opts?.dsName
  if (dsName) qCtl.opts.dsName = dsName
  if (qCtl.opts.isFilterControl) {
    if (dsName) qCtl.attr({ 'data-filter-for': dsName })

    return qCtl.addClass('ow-filter-control').on('keyup', e => {
      // Enter, F2
      if ([13, 113].includes(e.which) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        qCtl.opts.clientFilter ? qCtl.opts.dc?.refilter(true) : qCtl.opts.dc?.load()
        return killEvent(e)
      }
    })
  }
  if (dsName) qCtl.attr({ 'data-field-for': qCtl.opts.dsName })

  modelBind(qCtl)

  if (qCtl.opts.fieldName && qCtl.populate === undefined && qCtl.val)
    qCtl.props({
      populate(model) {
        qCtl.model = model
        if (qCtl.el?.populate) return qCtl.el.populate(model)
        qCtl.val(_v(model, qCtl.opts.fieldName), model, true)
      }
    })

  return qCtl
}

const populateField = (el, model) => {
  const q = qc(el)
  q.model = model

  if (q.populate) return q.populate(model)
  if (el.populate) return el.populate(model)

  const opts = q.opts ?? el.opts

  var fieldName = opts?.fieldName ?? opts?.fieldName
  var v = _v(model, fieldName)

  if (typeof v === 'string' && opts.dataType === 'int') v = !isNaN(parseInt(v)) ? parseInt(v) : v
  if (typeof v === 'string' && opts.dataType === 'date') v = new Date(v)

  v = typeof v !== 'undefined' ? v : ''

  if (!fieldName) return console.log('populateField Failed: no fieldname', el)

  if (q.val && typeof q.val === 'function') return q.val(v, model)
  if (el.val && typeof el.val === 'function') return el.val(v, model)
  if (typeof el.value !== 'undefined') return (el.value = v)

  el.innerHTML = v // for display labels
}

const readField = (model, el) => {
  const q = qc(el)

  if (q.readData) return q.readData(model)
  if (el.readData) return el.readData(model)

  el.opts = q.opts ?? el.opts ?? {}

  const fieldName = el.opts?.fieldName
  if (!fieldName) return console.log('No readData for control ' + el.id)
  if (q.val) return _v(model, fieldName, q.val())
  if (el.val) return _v(model, fieldName, el.val())
  if (typeof el.value !== 'undefined') return _v(model, fieldName, el.value)

  _v(model, fieldName, el.innerHTML) //rec[fieldName] = el.innerHTML;
}

const dataControls = (dsName, el) =>
  no$(el)
    .find('[data-field]')
    .filter(el => qc(el).opts?.dsName === dsName)

const filterControls = (top, dsName) =>
  no$(top)
    .find('[data-filter-for]')
    .filter(el => el.opts?.dsName === dsName)

const populateControls = (dsName, model, el) =>
  dataControls(dsName, el).forEach(el => {
    populateField(el, model)
    qc(el).renderAsync()
  })

const readControls = (dsName, model, el) =>
  dataControls(dsName, el).forEach(el => readField(model, el))

module.exports = { dataBind, populateControls, readControls, dataControls, filterControls }

const readFilterFn = (fc, filter = {}) => {
  let ctl = qc(fc)
  ctl.lastFilterValue = ctl.val()
  return ctl.readFilter((filter.filters = filter.filters ?? []))
}

const readFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName).forEach(el => readFilterFn(el, filter))
  return filter.filters
}
module.exports.readFilterControls = readFilterControls

// call the filter() on each filterControl that's bound to me.
const readServerFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName)
    .filter(el => !qc(el).opts || !qc(el).opts?.clientFilter)
    .forEach(el => readFilterFn(el, filter))
  return filter.filters
}
module.exports.readServerFilterControls = readServerFilterControls

// call the filter() on each filterControl that's bound to me.
const readClientFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName)
    .filter(el => qc(el).opts?.clientFilter)
    .forEach(el => readFilterFn(el, filter))
  return filter.filters
}
module.exports.readClientFilterControls = readClientFilterControls

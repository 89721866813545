exports.contextMenu = function (opts) {
  var $menu = $('<ul class="ow-context-menu" />')
  if (opts.target.tagName === 'BODY') {
    $menu.removeClass('ow-context-menu')
  }
  $menu.appendTo($(opts.target))
  $menu.attr('winid', $(opts.target).closest('.k-window-content').attr('id'))

  opts.menuItems.forEach((item, i) => initCss(item, i))

  function initCss(item, i) {
    if (item.items && item.items.length > 0) {
      item.items.forEach((item, k) => initCss(item, i + '-' + k))
    } else item.cssClass = (item.cssClass + ' ' || '') + '_mnu' + i
  }

  function itemLookup(element, item, i) {
    if ($(element).hasClass('_mnu' + i)) {
      return item
    } else if (item.items && item.items.length > 0) {
      return item.items.find(function (item, k) {
        return itemLookup(element, item, i + '-' + k)
      })
    }
  }

  var node = opts.target

  var kOpts = {
    dataSource: opts.menuItems,
    orientation: 'vertical',
    target: $(opts.target),
    filter: opts.targetFilter,
    open(e) {
      node = e.event?.target || e.target
      if ($(e.item).hasClass('k-item') && !$(e.item).hasClass('k-state-hover')) {
        e.preventDefault()
        return
      }
      $menu.trigger('ow-menu-open', [e]) // TODO: document this event
    },
    close() {
      $(opts.target).trigger('ow-menu-close')
      opts.target.focus()
    },
    select(e) {
      var menuItem = null
      opts.menuItems.forEach((item, i) => {
        var itemFound = itemLookup(e.item, item, i)
        if (itemFound) menuItem = itemFound
      })

      if (menuItem && menuItem.click) {
        menuItem.click(node)
      }

      $(node).trigger('ow-context-menu', [menuItem, node]) // TODO: document this event
    }
  }
  if (opts.showOn) {
    kOpts.showOn = opts.showOn
  }

  $menu.kendoContextMenu(kOpts)

  var $targetFound = $(opts.target)
  var kMenu = $menu.data('kendoContextMenu')
  $menu[0].open = function () {
    if ($targetFound.length) {
      kMenu.open()
      $menu[0].focus()
    }
  }

  $menu[0].odisable = function (selector, v) {
    kMenu.enable(selector, !v)
  }

  $menu[0].destroy = function () {
    kMenu.destroy()
  }

  return $menu[0]
}

const { addCss } = require('../add-css')
const { qc } = require('../cmp/qc')
const theme = require('../theme')

/**
 * resourceSet - label | control
 * @param {Object} opts
 * @param {boolean} opts.noLabel - don't create a label element at all
 * @param {string} opts.label
 * @param {object} opts.input - the qc object we're wrapping
 * @param {string} opts.type - becomes a class of the RS div
 * @param {Object} content - what to put in the presentation span
 *
 * Note: the cmp this returns has method addContent
 */
const rs = (opts, content = []) => {
  opts.type = opts.type || ''

  let input = Array.isArray(content) ? content[0] : content
  input = input?.input ?? opts.input ?? input

  const span = qc('span', content).attr({ role: 'presentation' })

  return qc(
    'div.rs7.resource_set.' +
      opts.type +
      (opts.noLabel ? '.no-label' : '') +
      (opts.edType === 'static' ? '.static' : '')
  )
    .kids([qc('label', opts.noLabel ? '' : opts.label), span])
    .attr({ ...(opts.attrs || {}) })
    .props({
      input,
      addContent(x) {
        span.kids([...span.kids(), x]) // for ow6 compat
      }
    })
}

addCss(
  'rs-flex',
  `
.css7 *:focus-visible {
    outline: 0;
}

.css7 .resource_set {
  margin: 1px;
  display: block;
  min-height: 28px;
  /* height: 1.5rem; */
  line-height: 1.5rem ! important;
  box-sizing: border-box;
  white-space: nowrap;
}
.css7 .resource_set.multi-line {
  height: unset;
}
  
.css7 .resource_set > label {
  display: inline-block;
  text-align: right;
  line-height: inherit; 
  padding-right: 0.5rem;
  box-sizing: border-box;
  width: 35%;
}

.css7 .resource_set > span {
  width: 65%;
  box-sizing: border-box;
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;
}

.css7 .ow-ctl-wrap {
  display: inline-block;
  height: inherit;
  line-height: inherit;
  vertical-align: top;
}

.css7 .ow-ctl-wrap.ow-textbox {
  border: ${theme.textboxBorderColor} ${theme.borderWidth} solid;
  background: #fff; 
  border-radius: 4px;
  margin: -${theme.borderWidth} 0;
  white-space: nowrap;
  box-sizing: border-box;
}

.css7 .ow-ctl-wrap input {
  max-width: 99%;
  border: 0;
  outline: 0;
  height: inherit;
  text-indent: 4px;
  background: transparent;
}

.css7 .rs7 .ow-ctl-wrap input {
  width: 100%;
}

.css7 .ow-textbox.text-icon-after > input {
  width: calc(100% - 1.5rem);
}
.css7 .ow-textbox.text-icon-after > i {
  width: 1.5rem;
  text-align: center;
  padding: 0;
}

.css7 .ow-textbox.text-icon-after.text-2icon-after > input {
  width: calc(100% - 2.5rem);
}
.css7 .ow-textbox.text-icon-after.text-2icon-after > i.icon {
  width: 1.2rem;
}

.css7 .ow-textbox.text-icon-after.ow-multi-select-wrap > input {
  max-width: 3rem;
}


.css7 .resource_set > span { margin-right: -0.25rem; }
.css7 .resource_set .ow-ctl-wrap { margin-right: 0.25rem; box-sizing: border-box; }
.css7 .resource_set .ow-ctl-wrap.w1 { width: calc(25% - 0.25rem); }
.css7 .resource_set .ow-ctl-wrap,
.css7 .resource_set .ow-ctl-wrap.w2 { width: calc(50% - 0.25rem); }
.css7 .resource_set .ow-ctl-wrap.w3 { width: calc(75% - 0.25rem);  }
.css7 .resource_set .ow-ctl-wrap.w4 { width: calc(100% - 0.25rem); }
`
)

module.exports = { rs }

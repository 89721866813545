const { combo7 } = require('./combo7')
const { time7 } = require('./time7')
const { date7 } = require('./date7')
const { datetime7 } = require('./datetime7')
const { float7 } = require('./float7')
const { int7 } = require('./int7')
const { text7 } = require('./text7')
const { check7 } = require('./check7')
const { htmlEncode } = require('../html-encode')

const { culture, __ } = require('../culture')
const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { _v } = require('../_v')
const { formatString } = require('../ctl-format')
const { iconCodes } = require('../icon-codes')
const { dates } = require('../dates')
const { parseDate } = dates

const edTypeMap = {
  char: 'text7',
  bit: 'check7',
  check: 'check7',
  boolean: 'check7',
  decimal: 'float7',
  integer: 'int7',
  int: 'int7',
  float: 'float7',
  currency: 'float7',
  date: 'date7',
  datetime: 'datetime7',
  time: 'time7',
  weekdays: 'weekdays',
  timeString: 'time7'
}

exports.cellReadOnly = (col, d) =>
  typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly || false

const columnDataValue = (col, rec) => {
  const f = col.field
  let v = col.calc ? col.calc(rec) : f ? _v(rec, f) : undefined

  if (v && (col.type === 'date' || col.type === 'datetime') && typeof v === 'string') {
    // this happens after JSON.parse() and is still a string
    if (v.indexOf('T') + 1) {
      console.error('Unexpected condition, ISO string ', col.type, 'timezone may be wrong')
      v = new Date(v)
    } else if (
      v.split(':').length === 3 &&
      v.split('-').length === 3 &&
      v.split(' ').length === 2
    ) {
      v = new Date(v)
    } else {
      console.error('Unexpected condition, formatted date string ', col.type)
      v = parseDate(v, col.format)
    }
  }

  return v
}

/**
 * returns the formatted string value for a column.
 * @param {*} col
 * @param {*} d
 * @param {*} i
 * @param {boolean} readOnly
 * @param {boolean} ctlUse - if it's for in a control rather than HTML span content
 * @returns
 */
const columnValue = (col, d, i, readOnly, ctlUse) => {
  let v = columnDataValue(col, d)

  v = v === undefined && v === null && !ctlUse ? '' : col.format ? formatString(v, col.format) : v

  if (readOnly) {
    if (col.readOnlyText) col.readOnlyText(d)

    if (v !== null && col.type === 'combo') {
      const ctl = {
        ...{ objectFieldName: '_' + col.field, valueField: 'Value', textField: 'Text', list: [] },
        ...col,
        ...(col.ctl ?? {})
      }

      return (
        d[ctl.objectFieldName]?.[ctl.textField] ??
        ctl.list.find(item => item[ctl?.valueField] === v)?.[ctl.textField] ??
        v
      )
    }

    if (v === null) return ctlUse ? '' : '&nbsp;'
    return col.readOnlyText?.(d) ?? v ?? (ctlUse ? '' : '&nbsp;')
  }

  return v
}

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} grid.opts - grid opts
 * @param {boolean=false} grid.opts.hasFilters - if false, the column top filters aren't created
 */
exports.colsToGridColumns = function (cols, qGrid) {
  // Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".
  const { opts } = qGrid

  opts.cols.forEach((col, coli) => {
    col.coli = coli
    const ro = col.readOnly && typeof col.readOnly !== 'function'

    const editable = col.gridCol !== false && !ro && opts.editable !== false

    if (col.type === 'date') col.format = col.format || culture().DateFormatSetting
    if (col.type === 'integer') col.type = 'int'
    if (col.type === 'decimal') col.type = 'float'

    col.name = col.name ?? col.field ?? coli
    opts.userSettings.cols ??= {}
    col.setting = opts.userSettings.cols[col.name] ??= {}

    if (col.gridCol === false) return

    col.origWidth = col.width ??= 100

    if (editable)
      col.template =
        col.template ??
        function (model, i) {
          const col = this
          const f = col.field
          const readOnly = exports.cellReadOnly(col, model)

          if (readOnly) return '' + (columnValue(col, model, i, readOnly, false) ?? '&nbsp;')
          const v = columnDataValue(col, model, i, readOnly, true)

          const ctlTypes = { check7, combo7, int7, float7, text7, datetime7, time7, date7 }

          let me = (ctlTypes[col.ctl?.ctlType] ?? text7)({
            view: opts.view,
            inGrid: true,
            label: opts.label ?? col.title ?? f ?? '',
            col,
            model,
            value: v,
            fieldName: f,
            maxLength: col.maxLength ?? col.validation?.maxLength,
            ...(col.ctl ?? {})
          })

          return me.wrap()
        }

    if (col.gridCol !== false) {
      col.width = col.setting.width ?? col.width ?? 100
      col.hidden = col.setting.hidden ?? 0
      col.order = col.setting.order ?? coli
    }
  })

  const result = cols
    .filter(x => x.gridCol !== false)
    .map((col, i) => {
      if (col.editable === false)
        console.warn('gridCol.editable is deprecated, please use gridCol.readOnly: true')

      col.index = i
      Object.assign(
        col,
        Object.assign(
          {
            validation: {},
            attributes: { class: '' },
            title: '',
            align: ''
          },
          col
        )
      )

      // validation
      col.validation =
        typeof col.validation === 'function' ? { validateFunction: col.validation } : col.validation
      col.validation.required = col.validation.required ?? col.ctl?.required ?? col.required

      if ((col.field || '').indexOf('.') > -1 && !col.template && opts.unnestFields) {
        const field = col.field
        col.template =
          col.template ||
          function (d) {
            const v = _v(d, field) ?? ''
            return v && col.format ? formatString(v, col.format) : v
          }
        col.nestedField = field.split('.').join('_')
      }

      if (col.type === 'bit') {
        col.type = 'boolean'
        console.warn('Incorrect col.type: bit.  Please use boolean')
      }

      if (col.type === 'boolean')
        col.template =
          col.template ??
          (col.fullTimeCheck === false
            ? data => {
                const list = [__('false'), __('true')]
                return list[data[col.field] * 1]
              }
            : data => qc('i.icon', data[col.field] ? html(iconCodes.check) : html('&nbsp;')))

      // formats
      if (col.type === 'date') col.format = col.format || culture().DateFormatSetting

      if (col.type === 'datetime') col.format = col.format || culture().DateTimeFormatSetting

      if (col.type === 'time') {
        col.format = col.format || culture().TimeFormatSetting
        col.attributes = col.attributes || {}
        col.attributes.class = (col.attributes.class || '') + ' time'
      }
      if (col.type === 'currency') col.format = col.format || 'c'

      // staticlookup
      col.ctl = col.ctl || {}

      if (col.list) {
        col.ctl.list = col.ctl.list || col.list
        col.ctl.ctlType = 'combo7'
        col.ctl.fieldName = col.ctl.fieldName || col.field
      }

      if (col.type === 'combo') col.ctl.ctlType = col.ctl.ctlType ?? 'combo7'

      if (col.ctl.ctlType === 'combo') col.ctl.ctlType = 'combo7'

      if (!col.ctl.ctlType) col.ctl.ctlType = col.ctlType ?? edTypeMap[col.type] ?? 'text'

      const isLookup = () => col.ctl?.ctlType === 'combo7'
      if (isLookup()) {
        col.align = col.align || 'left'
        const list = col.ctl.list || []

        col.template =
          col.template ||
          function (d) {
            let v = _v(d, col.field)
            if (v === undefined) return ''

            if (d) {
              let matches = list.filter(y => _v(y, col.ctl.valueField) == v)
              if (matches[0]) return matches[0][col.ctl.textField || 'Text']
              if (typeof v === 'number') {
                v = list[parseInt(v)]
                if (typeof v === 'object') v = ''
              }
            }
            return v
          }
      }

      if (col.format) col.ctl.format = col.ctl.format ?? col.format

      col.ctl.inGrid = true
      col.ctl.col = () => col
      col.ctl.fieldName = col.ctl.fieldName ?? col.field
      col.ctl.noLabel = true
      if (col.maxLength) col.ctl.maxLength = col.maxLength

      if (isLookup()) col.attributes.class = (col.attributes.class || '') + ' lookup-col'

      if (col.type && !isLookup()) {
        col.attributes.class =
          (col.attributes.class || '') + (col.type === 'integer' ? ' align-left' : '')
        if (col.align) col.attributes.class += ' align-' + col.align
      }

      // cell templates
      col.kColTemplate = function (d) {
        function readValue(col) {
          let v = typeof col.calc === 'function' ? col.calc(d) : col.field ? _v(d, col.field) : ''
          if (col.type === 'date' || col.type === 'datetime')
            if (typeof v === 'string' && v !== '') v = parseDate(v, col.format)

          return v ?? ''
        }

        const defaultValue = () => {
          let result = readValue(col)
          if (col.format) result = formatString(result, col.format)
          if (col.encoded !== false) result = htmlEncode(result)
          return result
        }

        let val = col.template ? col.template(d, defaultValue()) : defaultValue()
        if (typeof val === 'string' && !col.encoded) val = html(val)
        val = val ?? []

        const classes = col.classes
          ? typeof col.classes === 'function'
            ? col.classes(d)
            : col.classes
          : ''

        const span = exports.cellReadOnly(col, d)
          ? qc('span.read-only', val)
          : qc('span' + (classes ? '.' + classes.split(' ').join('.') : ''), val)

        if (!col.template)
          span.bindState(
            () => defaultValue(),
            val => {
              if (typeof val === 'string' && !col.encoded) val = html(val)
              val = val ?? []
              span.kids(val)
            }
          )

        return span
      }

      // group footer
      if (col.groupFooter) {
        const wrapFooter = content =>
          qc('span.col-' + col.index, content).css({
            textAlign: col.footerAlign || col.align || 'right'
          })

        if (typeof col.groupFooter === 'string') {
          let ag = col.footer
          col.groupFooter = function (d) {
            // FROM R1: if (d[col.field]) return d[ag](col.field, d[col.field] && d[col.field].group)
            // FROM R2: if (d[col.field]) return grid[ag](col.field, d[col.field] && d[col.field].group)
            if (d[col.field])
              return (d[ag] ? d[ag] : qGrid.el[ag])(col.field, d[col.field] && d[col.field].group)
          } // eg. qGrid.sum('Quantity', group)
        }

        if (typeof col.groupFooter === 'function')
          col.groupFooterTemplate = d => wrapFooter(formatString(col.groupFooter(d), col.format))
      }

      col.width = col.width ?? col.defaultWidth
      return col
    })

  return result
}

const { html } = require('./cmp/html')
const { qc } = require('./cmp/qc')

let resize, mouseup // function to call on drag handles or titlebar for resizing or moving the window.

document.body.addEventListener(
  'mousemove',
  e => (e.buttons === 0 ? (resize = undefined) : resize?.(e)),
  true
)

document.body.addEventListener(
  'mouseup',
  e => {
    const r = mouseup?.(e)
    mouseup = undefined
    resize = undefined
    return r
  },
  true
)

module.exports.setResizeHandler = (onResize, onMouseUp) => {
  resize = onResize
  mouseup = onMouseUp
}

module.exports.viewWindow = opts => {
  const viewdata = opts

  let maximized, minimized

  let maxWidth = parseInt(viewdata.maxWidth ?? 6000)
  let maxHeight = parseInt(viewdata.maxHeight ?? 4000)

  let minWidth = parseInt(viewdata.minWidth ?? 200)
  let minHeight = parseInt(viewdata.minHeight ?? 100)

  let width = Math.min(
    maxWidth,
    Math.max(minWidth, parseInt(viewdata.width ?? viewdata.origWidth ?? 1200))
  )
  let height = Math.min(
    maxHeight,
    Math.max(minHeight, parseInt(viewdata.height ?? viewdata.origHeight ?? 600))
  )

  const topLimit = () => 41 - no$(opts.parentView?.viewWrap.el ?? document.body).offset().top

  let left = Math.max(-(width - 50), parseInt(opts.left ?? 200))
  let top = Math.max(topLimit(), parseInt(opts.top ?? 100))

  const viewWrap = qc('div.k-widget.k-window.ow-viewwindow') // .k-state-focused

  // viewdata.noWindow -> z-index: 0 and maximized and addClass('background-window')
  if (viewdata.noWindow) {
    maximized = true
    viewWrap.addClass('background-window')
  }

  if (viewdata.modal) viewWrap.addClass('modal')

  const toCenter = () => {
    const parentWidth = viewWrap.el?.parentElement?.clientWidth ?? window.innerWidth
    const parentHeight = viewWrap.el?.parentElement?.clientHeight ?? window.innerHeight

    left = (parentWidth - width) / 2
    top = Math.max(topLimit(), (parentHeight - height) / 2)
  }

  const toFront = () => {
    if (opts.noWindow) return

    const changingWindow = ow.windows.activeWindow !== $top
    if (
      changingWindow &&
      ow.windows.activeWindow?.closest('body')[0] &&
      ow.windows.activeWindow?.viewdata?.modal &&
      !opts.modal
    )
      return ow.windows.activeWindow.toFront()

    console.warn('toFront()', $top[0].id)

    ow.windows.activeWindow = $top

    const zIndex = qc(viewWrap.el?.parentElement ?? document.body)
      .find('.win-con')
      .filter(el => el !== qTop.el)
      .reduce(
        (r, el) => {
          const winWrap = qc(el.parentElement)
          if (!opts.modal && winWrap.hasClass('modal')) return r

          const z = parseInt('0' + (winWrap.css('zIndex') ?? '0'))
          return Math.max(z + 1, r)
        },
        opts.modal ? 50000 : 1000
      )

    if (viewWrap.css('z-index') !== '' + zIndex)
      viewWrap
        .css({ zIndex })
        .renderAsync()
        .then(() => {
          if (changingWindow) ow.windows.resolveFocus()
        })

    common.wait(50).then(() => ow.windows.resolveFocus())
  }

  const resizeHandleMouseDown = (e, handle) => {
    module.exports.addIframeOverlay($top.el)
    const m_pos_x = e.pageX
    const m_pos_y = e.pageY

    const orig = { height, width, left, top }

    const isTitleDrag = handle.hasClass('ow-window-title')
    const changeLeft =
      handle.hasClass('k-resize-w') ||
      handle.hasClass('k-resize-nw') ||
      handle.hasClass('k-resize-sw') ||
      isTitleDrag

    const changeTop =
      handle.hasClass('k-resize-n') ||
      handle.hasClass('k-resize-nw') ||
      handle.hasClass('k-resize-ne') ||
      isTitleDrag

    const changeWidth =
      handle.hasClass('k-resize-e') ||
      handle.hasClass('k-resize-ne') ||
      handle.hasClass('k-resize-se')

    const changeHeight =
      handle.hasClass('k-resize-s') ||
      handle.hasClass('k-resize-sw') ||
      handle.hasClass('k-resize-se')

    module.exports.setResizeHandler(
      e => {
        const { pageX, pageY } = e

        e.preventDefault()

        if (changeLeft) {
          left = Math.max(Math.min(parseFloat(orig.left + pageX - m_pos_x), 2000), 50 - width)
          if (!isTitleDrag)
            width = Math.max(
              Math.min(parseFloat(orig.width - (pageX - m_pos_x)), maxWidth),
              minWidth
            )
        }

        if (changeTop) {
          top = Math.max(Math.min(parseFloat(orig.top + pageY - m_pos_y), 1200), topLimit())
          if (!isTitleDrag)
            height = Math.max(
              Math.min(parseFloat(orig.height - (pageY - m_pos_y)), maxHeight),
              minHeight
            )
        }

        if (changeWidth)
          width = Math.max(Math.min(parseFloat(orig.width + pageX - m_pos_x), maxWidth), minWidth)

        if (changeHeight)
          height = Math.max(
            Math.min(parseFloat(orig.height + pageY - m_pos_y), maxHeight),
            minHeight
          )

        viewWrap.renderAsync()
      },
      () => {
        module.exports.removeIframeOverlay()
        qc(document.body)
          .renderAsync()
          .then(() => {
            if (ow.windows.activeWindow) qc(ow.windows.activeWindow[0]).trigger('resize')
          })
      }
    )

    e.preventDefault()
  }

  const addResizing = handle =>
    handle.on('mousedown', e => {
      if (e.target === handle.el) return resizeHandleMouseDown(e, handle)
    })

  const resizeHandles = () =>
    [
      qc('div.k-resize-handle.k-resize-n'),
      qc('div.k-resize-handle.k-resize-e'),
      qc('div.k-resize-handle.k-resize-s'),
      qc('div.k-resize-handle.k-resize-w'),
      qc('div.k-resize-handle.k-resize-se'),
      qc('div.k-resize-handle.k-resize-sw'),
      qc('div.k-resize-handle.k-resize-ne'),
      qc('div.k-resize-handle.k-resize-nw')
    ].map(addResizing)

  let titleSpan
  const titleBar = qc('div.ow-window-titlebar.k-window-titlebar.k-header', [
    addResizing(
      (titleSpan = qc('span.ow-window-title.k-window-title', opts.title ?? viewdata.name ?? '')
        .css({
          right: '0.44em',
          overflow: 'hidden',
          cursor: 'default',
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
          padding: '6px 10px',
          display: 'block'
        })
        .on('dblclick', () => {
          if (viewdata.parentView) return
          maximized = !maximized
          viewWrap.renderAsync()
        }))
    ),

    qc('div.k-window-actions', [
      ...(viewdata.parentView
        ? []
        : [
            qc('a.k-window-action.k-link', qc('span.k-icon.k-i-window-minimize'))
              .attr({
                role: 'button',
                href: '#',
                'aria-label': 'window-Minimize'
              })
              .on('click', () => {
                minimized = !minimized
                viewWrap.renderAsync()
              }),

            qc('a.k-window-action.k-link', qc('span.k-icon.k-i-window-maximize'))
              .attr({
                role: 'button',
                href: '#',
                'aria-label': 'window-Maximize'
              })
              .on('click', () => {
                maximized = !maximized
                viewWrap.renderAsync()
              })
          ]),

      qc('a.k-window-action.k-link', qc('span.k-icon.k-i-close'))
        .attr({
          role: 'button',
          href: '#',
          'aria-label': 'Close'
        })
        .on('click', () => win_close())
    ])
  ]).css({ marginTop: '-28px', paddingTop: '0', paddingBottom: '0', height: '1.8rem' })

  const win_close = () => {
    if (viewdata.requestClose?.() === false) return

    viewdata.callback?.(qTop, viewdata)

    if (viewdata.skipSavePrefs !== true)
      ow.windows.saveUserSetting(
        viewdata.userSettingKey || viewdata.url,
        $top,
        !viewdata.skipGridPref
      )

    qTop.trigger('close')

    if (ow.windows.activeWindow?.[0] === qTop.el) delete ow.windows.activeWindow
    viewWrap.el.remove()

    const callerEl = viewdata.caller?.[0] ?? viewdata.caller?.el
    callerEl && qc(callerEl)?.toFront?.() // could be qTop or $top

    ow.windows.windowListMenu()

    //instead of null, try greatest z-index
    var wins = ow.windows.findOpenWindows()
    if (wins.length > 0) {
      var maxZIdx = 0
      wins.forEach($w => {
        var kWinWithZ = $w.closest('.k-widget.k-window')
        var curZIdx = $(kWinWithZ).css('z-index')
        if ($top.wid !== $w.wid)
          if (curZIdx > maxZIdx) {
            maxZIdx = curZIdx
            ow.windows.activeWindow = $w
          }
      })
    } else ow.windows.activeWindow = null
  }

  let $top, progressText

  const qTop = qc(
    'div.k-window-content.k-content.win-con',
    viewdata.isContentProvided ? html(viewdata.contentRef) : []
  )
    .addClass(viewdata.uid)
    .attr({
      id: opts.winNameFormKey,
      'data-role': 'window',
      tabindex: '0',
      role: 'dialog'
    })
    .props({
      wrapper: viewWrap,
      toFront,
      viewdata,

      setViewTitle(title) {
        titleSpan.kids([title])
      },

      closeForm(forceClose) {
        if (forceClose) viewdata.requestClose = null

        win_close()

        // in case this window isn't currently active
        if (ow.windows.activeWindow === $top) ow.windows.activeWindow = null
      },

      setProgressText(text) {
        progressText = text
        viewWrap.renderAsync()
      },

      progress(on = true, text) {
        let done = !on

        if (done && !viewWrap.progressCount) console.warn('Progress: What the?!')

        viewWrap.progressCount = Math.max(0, (viewWrap.progressCount || 0) + (done ? -1 : 1))

        const loadCls = 'ow-loading'

        progressText = text

        if (viewWrap.progressCount < 1) viewWrap.removeClass(loadCls)
        else viewWrap.addClass(loadCls)

        viewWrap.renderAsync()
      }
    })
    .on('init', (e, el) => {
      const methods = {
        setMinWidth(w) {
          minWidth = w
          if (width < minWidth) width = minWidth
          viewWrap.renderAsync()
        },
        setMinHeight(h) {
          minHeight = h
          if (height < minHeight) height = minHeight
          viewWrap.renderAsync()
        },
        setMaxWidth(w) {
          maxWidth = w
          viewWrap.renderAsync()
        },
        setMaxHeight(h) {
          maxHeight = h
          viewWrap.renderAsync()
        },
        setVisible(v) {
          minimized = !v
          viewWrap.renderAsync()
        },
        width() {
          return width
        },
        height() {
          return height
        },
        wrapper: viewWrap
      }
      qTop.props(methods)

      $top = $(el)
      qc(el).$top = Object.assign($top, {
        viewdata,

        registry: viewdata.registry ?? {},

        setVisible(vis = true) {
          return viewWrap.css({ display: vis ? undefined : 'none' })
        },

        toFront,

        ...methods,

        closeForm(...args) {
          return qTop.closeForm(...args)
        },

        progress(...args) {
          return qTop.progress(...args)
        }
      })

      $top.wid = viewdata.wid ?? common._id()

      toCenter()

      // set top to just below the top open window's title bar + 30
      if (!opts.modal && ow.windows.activeWindow) {
        top = Math.max(topLimit(), ow.windows.activeWindow[0].parentElement.offsetTop + 30)
        left = left + 30
      }
      toFront()
    })
    .on('command-close', () => qTop.closeForm())
    .on('resize', () => qTop.renderAsync())

  toCenter()

  const modalOverlay = qc('div.modal-overlay').css({
    position: 'fixed',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    background: '#333',
    opacity: '0.5',
    display: viewdata.modal ? undefined : 'none'
  })

  const titleBarContextMenu = e => {
    // window title bar context menu

    common.ow7.cmenu7(e.target, {
      view: viewdata.view,
      point: { left: e.pageX, top: e.pageY },
      setWidth: false,
      menuItems: [
        {
          text: __('Reset Window Prefs'),
          encoded: false,
          click() {
            viewdata.skipSavePrefs = true
            qTop.closeForm()
            setTimeout(() => {
              viewdata.winpref = {}
              delete viewdata.width
              delete viewdata.height
              if (viewdata.origWidth) viewdata.width = viewdata.origWidth
              if (viewdata.origHeight) viewdata.height = viewdata.origHeight
              viewdata.skipLoadPrefs = true
              ow.windows.openView(viewdata)
              viewdata.skipSavePrefs = false
            }, 50)
          }
        }
      ]
    })

    return common.killEvent(e)
  }

  return viewWrap
    .kids([
      modalOverlay,
      titleBar.on('contextmenu', titleBarContextMenu),
      qTop,
      ...resizeHandles(),
      qc('div.ow-loading-mask.fit', [
        qc('span.ow-loading-text')
          .bindState(
            () => progressText ?? '', // __('Loading') + '...',
            (text, label) => label.kids([text])
          )
          .css({
            position: 'absolute',
            display: 'block',
            top: '55%',
            textAlign: 'center',
            width: '100%',
            textIndent: '0'
          }),

        qc('div.ow-loading-image').css({
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0',
          zIndex: '2'
        })
      ]).css({ position: 'absolute', top: '28px', zIndex: '100' })
    ])
    .props({ qTop, toFront })
    .attr({ 'data-role': 'draggable' })
    .on('mousedown click', () => toFront())
    .on('keydown', e => {
      if (e.which === 115) return qTop.trigger('command-close') //F4
    })
    .bindState(
      () => (maximized ? window.innerWidth - 4 : width),
      w => {
        viewWrap.css({ width: w + 'px' })
        qTop.trigger('resize')
      }
    )
    .bindState(
      () => (maximized ? window.innerHeight - 78 : height),
      h => {
        viewWrap.css({ height: h + 'px' })
        qTop.trigger('resize')
      }
    )
    .bindState(
      () => (maximized ? 1 : left),
      l => {
        viewWrap.css({ left: l + 'px' })
        qTop.trigger('resize')
      }
    )
    .bindState(
      () => (maximized ? topLimit() : top),
      t => {
        viewWrap.css({ top: t + 'px' })
        qTop.trigger('resize')
      }
    )
    .bindState(
      () => minimized,
      () => {
        viewWrap.css({ display: minimized ? 'none' : undefined })
        qTop.trigger('resize')
      }
    )
    .css({ paddingTop: '28px' })
}

module.exports.addIframeOverlay = () =>
  qc(document.body)
    .find('.win-con')
    .forEach(w => {
      qc('div.ui-draggable-iframe.fit')
        .css({
          background: '#fff',
          position: 'absolute',
          opacity: '0.01',
          zIndex: 1
        })
        .renderTo(w)
    })

module.exports.removeIframeOverlay = () =>
  qc(document.body)
    .find('.ui-draggable-iframe')
    .forEach(el => el.remove())

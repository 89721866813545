const { _v } = require('../_v')
const { qc } = require('../cmp/qc')
const { dates } = require('../dates')
const { htmlEncode } = require('../html-encode')
const { delphiColortoHex } = require('../ow0/core')
const { date7 } = require('../ow7/date7')
const { datetime7 } = require('../ow7/datetime7')
const { enterKeyHandler, basicEditorInits, BasicEd, ed } = require('./controls4')
const { filterController } = require('./filterController4')

const typeMap = {
  char: 'string',
  bit: 'boolean',
  decimal: 'number',
  integer: 'number',
  float: 'number',
  currency: 'number',
  date: 'date',
  datetime: 'date',
  time: 'string',
  timeString: 'string'
}

const edTypeMap = {
  char: 'text',
  bit: 'check',
  boolean: 'check',
  decimal: 'float',
  integer: 'int',
  float: 'float',
  currency: 'float',
  date: 'date',
  datetime: 'datetime',
  time: 'time',
  weekdays: 'weekdays',
  timeString: 'timeString'
}

exports.hideShowColumnEx = (win, grd, target, isChecked) => {
  var targetWin = $('#' + win)
  var grid = $(targetWin).find(grd).data('kendoGrid')
  if (grid) {
    if (isChecked) {
      var cmdCol = grid.columns.filter(r => r.command != undefined)
      var commandWidth =
        cmdCol?.length > 0
          ? typeof cmdCol[0].width === 'string'
            ? cmdCol[0].width.replace('px', '')
            : cmdCol[0].width
          : 0
      var refFullWidth = $(grid.content)[0].clientWidth - commandWidth
      var targetCol = grid.columns.filter(r => r.field == target)
      if (targetCol && targetCol.length > 0) {
        targetCol = targetCol[0]
        if (targetCol.width) {
          if (targetCol.width.toString().indexOf('%') > -1) {
            targetCol.width = targetCol.width.toString().replace('%', '')
            targetCol.width = Math.round((refFullWidth * targetCol.width) / 100) + 'px'
          }
          targetCol.width = targetCol.defaultWidth || targetCol.width
        }
        var currListing = []
        var previousColWidth = 0
        var currentColWidth = grid.columns.reduce((prev, cur) => {
          if (!cur.hidden || cur.field === target) {
            currListing.push(cur)
            if (cur.field !== target) {
              previousColWidth += parseInt(cur.defaultWidth)
              return (prev += parseInt(cur.defaultWidth))
            }
            return (prev += parseInt(cur.width))
          } else return prev
        }, 0)
        var gridHideWidth = $(grid.content)[0].clientWidth - 1
        if (currentColWidth < gridHideWidth) {
          var remainWidth = gridHideWidth - parseInt(targetCol.width)
          var shareShowWidth = (remainWidth - previousColWidth) / (currListing.length - 1)
          grid.columns.map(g => {
            if (!g.hidden && g.field !== target) g.width = parseInt(g.defaultWidth) + shareShowWidth
            return g
          })
        }
        grid.showColumn(target)
      }
    } else {
      var currHideListing = []
      var gridWidth = $(grid.content)[0].clientWidth - 1
      var currentHideColWidth = grid.columns.reduce((prev, cur) => {
        if (!cur.hidden && cur.field !== target) {
          currHideListing.push(cur)
          return (prev += parseInt(cur.width))
        } else return prev
      }, 0)

      if (currentHideColWidth < gridWidth) {
        var shareHideWidth = (gridWidth - currentHideColWidth) / currHideListing.length
        grid.columns.map(g => {
          if (!g.hidden && g.field !== target) g.width = parseInt(g.width) + shareHideWidth
          return g
        })
      }
      grid.hideColumn(target)
    }
    exports.sizeGridsIn(targetWin)
    grid._resize()
  }
}

exports.initColumnMenu = function ($win, gridIden, allowSaveTemplate) {
  const g = $win.find(gridIden)[0]
  var kGrid = $win.find(gridIden).data('kendoGrid')
  var gridId = gridIden.replace('#', '')
  var columnsData = []
  if (!kGrid)
    return console.error('Could not find Grid ' + gridIden + ', when trying to add columns menu.')

  columnsData = kGrid.columns || []

  var canClose = true
  var menuArr = []
  columnsData.forEach(r => {
    var isCheckedStr = r.hidden ? "'> " : "' checked> "
    var isDisable = r.isMandatory ? 'disabled' : ''
    var eleStr =
      "<label style='white-space:nowrap; display:inline-block'><input type='checkbox' " +
      isDisable +
      " onclick='this.checked = !this.checked' value='" +
      r.field +
      "' winname='" +
      $win.viewdata.windowName +
      "' gridname='" +
      gridIden +
      isCheckedStr +
      r.title +
      '</label>'
    if (r.field) menuArr.push({ text: eleStr, encoded: false })
  })
  if (allowSaveTemplate) {
    menuArr.push({
      text: '',
      cssClass: 'k-separator column-menu-cmd'
    })

    menuArr.push({
      text:
        '<label command="defaulttemplate" data="' +
        gridId +
        '">' +
        __('Set Default Template') +
        '</label>',
      encoded: false,
      cssClass: 'column-menu-cmd'
    })
  }

  var menuWidth = (Math.floor(menuArr.length / 17) + 1) * 150
  var $menu = $(
    "<ul id='hideShowColumnsMenu_" +
      gridId +
      "' class='hideShowColumnsMenu float-left-150' style='width:" +
      menuWidth +
      "px' />"
  )
  $menu.attr('winid', $win.attr('id'))

  $menu.kendoContextMenu({
    dataSource: menuArr,
    orientation: 'vertical',
    target: $win.find(gridIden),
    filter: 'div.k-grid-header',
    select(e) {
      var col = $(e.item.childNodes[0]).find('input')[0]
      var okay = false
      if (col && !col.disabled) {
        this.element.find('li').each(function (i, ele) {
          if ($(ele).find('input').length === 0) return
          if (
            $(ele).find('input')[0].checked &&
            ele.textContent.trim() !== e.item.textContent.trim()
          ) {
            okay = true
          }
        })
        if (okay) {
          var win = col.attributes['winname']
          var grd = col.attributes['gridname']
          var target = col.attributes['value']
          var isChecked = !col.checked
          const setting = g.opts.userSettings.cols[target.value.replace('_', '.')]
          if (isChecked && setting) delete setting.hidden
          else setting.hidden = true
          exports.hideShowColumnEx(win.value, grd.value, target.value, isChecked)
          col.checked = !col.checked
        }
      }
      canClose = false

      if (!col) {
        //Check is command
        var cmdLink = $(e.item.childNodes[0]).find('label')[0]
        if (cmdLink) {
          var cmd = $(cmdLink).attr('command')
          var gridId = $(cmdLink).attr('data')
          if (cmd) {
            $win.trigger('command-' + cmd, [gridId])
          }
        }
        canClose = true
      }
    },
    close(e) {
      if (!canClose) {
        e.preventDefault()
        canClose = true
      }
    }
  })
}

exports.sizeGridsIn = function ($el) {
  $el.find('.k-grid,.ow-grid').forEach(g => {
    if (g.fillout) g.fillout()
    else $(g).data('kendoGrid')?.fillout?.()
  })
}

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} opts - grid opts
 */
exports.colsToKendoSchemaFields = function (cols, g, opts) {
  var result = {}
  cols.forEach(function (col) {
    //var s = { type: col.type, defaultValue: col.defaultValue };
    var s = { type: typeMap[col.type] || col.type, defaultValue: col.defaultValue }
    if (col.editable === false) {
      s.editable = false
    }
    if (col.field === 'LineNumber' || col.field === opts.lineNumberField) {
      s.defaultValue = function () {
        return g.read ? g.read().length : 0
      }
    }
    // result[x.field] = x.calc ? x.calc : s;
    var f = opts.unnestFields ? col.field.split('.').join('_') : col.field
    result[f] = col.calc ? col.calc : s
  })
  return result
}

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} opts - grid opts
 * @param {boolean=false} opts.hasFilters - if false, the column top filters aren't created
 */
exports.colsToKendoGridColumns = function (cols, grid, opts) {
  // Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".

  grid.iden = grid.iden ?? grid.id

  if (!opts.userSettings && opts.gridTemplate) {
    //check gridtemplate if user pref not found
    var gridTemplates = JSON.parse(opts.gridTemplate)
    opts.userSettings = Array.isArray(gridTemplates)
      ? gridTemplates.find(prefs => prefs.iden === grid.iden)
      : gridTemplates[grid.iden] || null
  }

  opts.filterOpen = opts.viewdata?.registry?.gridFilterRow

  opts.userSettings.cols ??= {}
  cols.forEach((col, coli) => {
    col.iden = col.field ?? 'col' + coli
    const setting = (opts.userSettings.cols[col.iden] ??= {})

    col.defaultWidth = col.width
    col.width = setting.width ? setting.width : col.width
    col.hidden = !col.isMandatory && (setting.hidden ?? false)
    if (!col.hidden) delete col.hidden

    col.orderIndex = setting.orderIndex ?? coli
  })

  if (opts.reorderColumns)
    opts.cols.sort((a, b) => (b.orderIndex !== undefined ? a.orderIndex - b.orderIndex : 1))

  // page Size
  if ((opts.pageable || opts.scrollable?.virtual) && opts.userSettings.pageSize)
    opts.pageSize = opts.userSettings.pageSize

  // filter Row?
  if (opts.hasFilters !== false) opts.filterOpen = opts.userSettings.filterToggle ?? false

  var defaultFilters = ow0.clone(kendo.ui.FilterMenu.prototype.options.operators)
  defaultFilters.string = {
    contains: __('Contains'),
    equals: __('Equals'),
    isnull: __('Is null')
  }
  defaultFilters.date = Object.assign(
    {},
    {
      sameDay: __('On same day'),
      beforeendofday: __('Is before or equal to same day')
    },
    defaultFilters.date
  )
  // defaultFilters.date.sameDay = defaultFilters.date.eq;
  // delete defaultFilters.date.eq;
  defaultFilters.datetime = Object.assign(
    {},
    {
      sameDay: __('On same day'),
      sameHour: __('In same hour'),
      sameMinute: __('In same minute')
    },
    defaultFilters.date
  )

  function setFilters(col, x) {
    var isFilterable = col.filterType !== 'na'
    if (!isFilterable) x.filterable = false
    if (x.filterable === false) return

    x.filterable = {
      extra: false,
      operators: defaultFilters,
      cell: {}
    }

    if (col.filterTemplate) {
      x.filterable.cell = {
        template(args) {
          const el = args.element[0]
          const qEl = col.filterTemplate(col).renderTo(el.parentElement)
          el.replaceWith(qEl)
        }
      }

      return
    }

    if ((col.filterType ?? col.type) === 'datetime7') {
      x.filterable.cell = {
        template(args) {
          const el = args.element[0]

          const ed = datetime7({
            isFilterControl: true,
            fieldName: col.field
          })
            .css({ padding: '0' })
            .attr({ name: col.field })
            .on('change ow-select', () => {
              el.value = ed.val()
              $(el).trigger('change')
            })
            .on('keydown', e => {
              if (e.which === 13 || e.which === 113) {
                el.value = ed.val()
                $(el).trigger('change')
              }
            })

          qc(el).css({ display: 'none' })

          qc(el.parentElement).on('click', e => {
            if (no$(e.target).is('button.k-button') || no$(e.target).is('.k-i-close'))
              ed.el.value = ''
          })

          qc(el.parentElement).kids([ed.wrap(), ...qc(el.parentElement).kids()])
        }
      }
      // x.filterable.operators = { eq: 'Is equal to' }
      return
    }

    if ((col.filterType ?? col.type) === 'date7') {
      x.filterable.cell = {
        template(args) {
          const el = args.element[0]

          const ed = date7({
            isFilterControl: true,
            fieldName: col.field
          })
            .css({ padding: '0' })
            .attr({ name: col.field })
            .on('change ow-select', () => {
              el.value = ed.val()
              $(el).trigger('change')
            })
            .on('keydown', e => {
              if (e.which === 13 || e.which === 113) {
                el.value = ed.val()
                $(el).trigger('change')
              }
            })

          qc(el).css({ display: 'none' })

          qc(el.parentElement).on('click', e => {
            if (no$(e.target).is('button.k-button') || no$(e.target).is('.k-i-close'))
              ed.el.value = ''
          })

          qc(el.parentElement).kids([ed.wrap(), ...qc(el.parentElement).kids()])
        }
      }
      // x.filterable.operators = { eq: 'Is equal to' }
      return
    }

    // if (x.type === 'date') {
    //   x.filterable.cell = {
    //     template(args) {
    //       const el = args.element[0]
    //       const td = el.closest('th').children[0]
    //       td.innerHTML = ''

    //       qc('input.date')
    //         .props({ isFilterControl: true })
    //         .attr({ 'data-field': col.field })
    //         .on('ow-change', () => {
    //           // grid.setFilter(
    //           //   {
    //           //     field: col.field,
    //           //     operator: 'eq',
    //           //     value: data
    //           //   },
    //           //   false
    //         })
    //         .renderTo(td)

    //       // basicEditorInits.date(el, {
    //       //   filter: true
    //       // })
    //     }
    //   }
    //   x.filterable.operators = { eq: 'Is equal to' }

    //   return
    // }

    if (x.type === 'boolean') {
      x.filterable.messages = { isTrue: x.options.oneString, isFalse: x.options.zeroString }
      x.filterType = x.filterType || 'boolean'
      x.filterable.cell = {
        template(args) {
          basicEditorInits.lookup(args.element, {
            filter: true,
            list: [
              { value: false, text: 'False' },
              { value: true, text: 'True' }
            ]
          })
        }
      }
      x.filterable.operators = 'eq'
    }

    if (col.basicEd && col.basicEd.edType !== 'check' && col.basicEd.edType !== 'weekdays')
      x.filterable.cell = {
        template(args) {
          BasicEd(args.element[0], Object.assign({}, col.basicEd, { filter: true }))
          args.element.keydown(function (e) {
            if (e.which === 13 || e.keycode === 13) setTimeout(() => $(e.target).trigger('change'))
          })
        }
      }

    if (col.basicEd && col.basicEd.edType === 'weekdays') {
      x.filterable.cell = {
        template(args) {
          var el = basicEditorInits.weekdays(args.element, { filter: true })

          args.element.on('ow-change', function (e, data) {
            grid.setFilter(
              {
                field: col.field,
                operator: 'eq',
                value: data
              },
              false
            )
            el.val(data) //require to set back selection
          })
        }
      }
      x.filterable.operators.number = { eq: __('FILTERCELL:STRING:eq') } //affect filter icon show options
    }

    if (x.filterable)
      if (col.defaultOperator)
        if (x.filterable.cell) x.filterable.cell.operator = col.defaultOperator
  }

  // builds the following:
  //   editor control
  //   manages classes, readOnly state, redrawing etc
  function toKendoColumn(col, i) {
    if (col.editable === false)
      console.warn('gridCol.editable is deprecated, please use gridCol.readOnly: true')

    col.index = i
    var x = {
      attributes: { class: '' },
      options: {},
      validation: col.validation || {},
      title: col.title || '',
      align: col.align || ''
    }
    if (typeof col.required !== 'undefined') x.validation.required = col.required
    if (typeof col.sortable !== 'undefined') x.sortable = col.sortable

    if (col.min || col.min === 0) {
      x.validation.gte = x.validation.gte || col.min
      delete col.min
    }
    if (col.max) {
      x.validation.lte = x.validation.lte || col.max
      delete col.max
    }

    x.hidden = col.hidden
    if (col.width) x.width = col.width
    if (col.field) x.field = col.field
    if (col.type) x.type = typeMap[col.type] || col.type

    if ((x.field || '').indexOf('.') > -1 && !col.template && opts.unnestFields) {
      var field = x.field
      col.template =
        col.template ||
        function (d) {
          var v = _v(d, field)
          if (typeof v !== 'undefined' && v !== null)
            return col.format ? ow0.toString(v, col.format) : v

          return ''
        }
      x.field = field.split('.').join('_')
      col.nestedField = x.field
      x.sortable = typeof col.sortable === 'undefined' ? true : x.sortable
    }

    if (x.type === 'boolean')
      x.headerAttributes = x.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'center')
      }

    if (col.type === 'decimal' || col.type === 'float') {
      x.headerAttributes = x.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'right')
      }
    }
    if (col.type === 'currency')
      x.headerAttributes = x.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'right')
      }

    if (col.headerClass)
      x.headerAttributes.class = (x.headerAttributes.class || '') + col.headerClass

    if (col.fullTimeCheck)
      x.attributes.class = (x.attributes.class || '') + ' full-time-edit k-edit-cell'

    if (col.fullTimeCheck) {
      col.editable = false
      // col.readOnly = true;
      x.align = col.align || 'center'
      x.attributes.class = (x.attributes.class || '') + ' full-time-check'
      var id = ow0.safeId(col.field)
      col.template =
        col.template ||
        function (data) {
          var r = ed(id, col.field, '', {
            classes: 'full-time-check-' + id,
            edType: 'check',
            noWrapper: 1,
            noLabel: 1,
            inGrid: true,
            model: data,
            value: _v(data, col.field) || false
          })

          return r
        }
      $(grid)
        .off('click', '.full-time-check-' + id)
        .on('click', '.full-time-check-' + id, function (e) {
          var $row = $(e.target).closest('tr')
          var container = $(e.target).closest('td')
          // var data = e.target.opts.model;
          var data = grid.getDataForRow($row)
          e.target.opts.model = data
          _v(data, col.field, e.target.val())
          data.dirty = true
          if (grid.saveCancelState) grid.saveCancelState(true)
          else $(grid).trigger('ow-change')
          $(e.target).trigger('change')
          grid.refreshRow($row, data)
          if (col.onEdit) {
            var model = e.target.opts.model
            var prev = ow0.clone(model)
            col.onEdit(prev, model, container)
          }
          $(grid).trigger('ow-field-edit', [col.field, e.target.val(), data, container])
        })
    }

    if (x.type === 'boolean' && !col.fullTimeCheck) {
      // update boolean type string
      x.options.zeroString = x.options.zeroString || 'false'
      x.options.oneString = x.options.oneString || 'true'
      if (x.options.zeroString) {
        //Build display template
        col.template =
          col.template ||
          function (data) {
            var list = [x.options.zeroString, x.options.oneString]
            return list[data[x.field] * 1]
          }
      }
    }

    // formats
    if (col.type === 'date') col.format = col.format || dates.DateFormatSetting

    if (col.type === 'datetime') col.format = col.format || dates.DateTimeFormatSetting

    if (col.type === 'time') col.format = col.format || dates.TimeFormatSetting

    if (col.type === 'currency') col.format = col.format || 'c'

    if (col.format) {
      if (col.format[0] === '{') {
        console.warn('please set column format as inner format string eg. n2 not {0:n2}')
        col.format = col.format.split(':')[1].split('}')[0]
      }
      x.format = '{0:' + col.format + '}'
    }

    // Convert Lookup to BasicEd
    // deprecated
    if (col.lookup) {
      col.basicEd = col.options || {}
      col.basicEd.fieldName = col.basicEd.fieldName || col.field
      col.basicEd.edType === col.basicEd.edType || 'lookup-ajaxtextvalue'
      delete col.options
      delete col.lookup
      console.warn('gridCol.isLookup and isStaticLookup are deprecated, please use gridCol.basicEd')
      console.log('---> basicEd: ' + JSON.stringify(col.basicEd, null, '\t'))
    }

    // staticlookup
    if (col.list) {
      col.basicEd = col.basicEd || {}
      col.basicEd.list = col.basicEd.list || col.list
      col.basicEd.edType = col.basicEd.edType || 'lookup'
      col.basicEd.fieldName = col.basicEd.fieldName || col.field
    }

    if (col.basicEd && col.basicEd.edType === 'lookup') {
      col.align = col.align || 'left'
      var list = col.basicEd.list || []

      col.template =
        col.template ||
        function (d) {
          if (!d) return ''
          var v = _v(d, col.field)
          if (typeof v === 'undefined') return ''

          var matches = list.filter(y => y[col.basicEd.valueField] == v)
          if (!matches.length) {
            if (typeof d[col.field] === 'number') {
              v = list[parseInt(d[col.field])]
              if (typeof v === 'object') v = ''
            }
          } else v = matches[0][col.basicEd.textField || 'Text']

          return v
        }
    }

    if (col.basicEd && col.basicEd.edType === 'weekdays') {
      const weekdays = v => {
        var shortDaysOfWeekJs = dates.shortDaysOfWeekJs
        var multiDays = []
        if (v >= 64) {
          multiDays.push(shortDaysOfWeekJs[6])
          v = v - 64
        }
        if (v >= 32) {
          multiDays.push(shortDaysOfWeekJs[5])
          v = v - 32
        }
        if (v >= 16) {
          multiDays.push(shortDaysOfWeekJs[4])
          v = v - 16
        }
        if (v >= 8) {
          multiDays.push(shortDaysOfWeekJs[3])
          v = v - 8
        }
        if (v >= 4) {
          multiDays.push(shortDaysOfWeekJs[2])
          v = v - 4
        }
        if (v >= 2) {
          multiDays.push(shortDaysOfWeekJs[1])
          v = v - 2
        }
        if (v >= 1) {
          multiDays.push(shortDaysOfWeekJs[0])
        }
        return multiDays.reverse()
      }
      col.template =
        col.template ||
        function (d) {
          var v = _v(d, col.field)
          return typeof v === 'undefined' ? '' : weekdays(v).join(', ')
        }
    }
    var isLookup = function () {
      return (
        col.basicEd &&
        (col.basicEd.edType === 'lookup' || col.basicEd.edType === 'lookup-ajaxtextvalue')
      )
    }
    if (!col.basicEd)
      col.basicEd = {
        edType: col.edType || edTypeMap[col.type] || 'text',
        fieldName: col.field,
        noWrapper: true,
        noLabel: true,
        width: 'full',
        inGrid: true
      }

    col.basicEd.inGrid = true
    col.basicEd.col = () => col

    col.basicEd.fieldName = col.basicEd.fieldName || col.field
    col.basicEd.noWrapper = true
    col.basicEd.noLabel = true
    col.basicEd.width = col.basicEd.width || 'full'
    if (col.type === 'time') col.basicEd.format = col.format

    if (col.maxLength) col.basicEd.maxLength = col.maxLength

    if (isLookup()) x.attributes.class = (x.attributes.class || '') + ' lookup-col'

    if (x.type && !isLookup()) {
      x.attributes.class =
        (x.attributes.class || '') +
        ' ' +
        x.type +
        '-col' +
        (col.type === 'integer' ? ' align-left' : '')
      if (x.align) x.attributes.class = (x.attributes.class || '') + ' align-' + x.align
    }

    // decimals
    if (col.basicEd && col.basicEd.edType === 'float')
      if (col.decimals !== undefined) col.basicEd.decimals = col.decimals

    if (col.basicEd) {
      if (col.basicEd.popUp && col.basicEd.popUp.callback) {
        col.basicEd.popUp._callback = col.basicEd.popUp.callback // persistent
        delete col.basicEd.popUp.callback
      }

      col.editor =
        col.editor ||
        function (container, options) {
          var edOpts = col.basicEd
          var f = edOpts.fieldName
          var model = options.model

          if (edOpts.popUp) {
            delete edOpts.popUp.callback
            if (edOpts.popUp._callback) edOpts.popUp.callback = edOpts.popUp._callback
          }

          container.append(
            container[0].$ed ? container[0].$ed : $(ed(ow0.safeId('ed' + f), f, '', edOpts, true))
          )

          var $ed = container.find('#' + ow0.safeId('ed' + f))

          var el = $ed[0]
          delete el.lastUrl
          delete el.lastSub

          edOpts.dataType = edOpts.dataType || col.type
          edOpts.model = model
          edOpts.grid = grid
          var $cell = $ed.closest('td')
          var $row = $cell.closest('tr')
          edOpts.rowIndex = $row.index()
          edOpts.colIndex = $cell.index()
          // $ed[0].name = x.field; // binding  what about data-bind="value:<fieldName>"?  create our own bindable "value" alternative
          if (edOpts.popUp || edOpts.prevPopUp) {
            if (typeof edOpts.popUp === 'function' && !edOpts.prevPopUp)
              edOpts.prevPopUp = edOpts.popUp
            if (edOpts.prevPopUp) edOpts.popUp = edOpts.prevPopUp()
            var popUp = edOpts.popUp
            if (popUp) {
              popUp.defaultCallback = function ($win, viewdata) {
                if (viewdata.result) {
                  var v = viewdata.result
                  if (popUp.fieldName) v = _v(viewdata.result, popUp.fieldName)
                  if (edOpts.objectFieldName) _v(model, edOpts.objectFieldName, viewdata.result)

                  var x = valChanged(v)
                  if (typeof x !== 'undefined') v = x
                }
                $(grid)
                  .find('tbody tr')
                  .eq(edOpts.rowIndex)
                  .find('td')
                  .eq(edOpts.colIndex)[0]
                  .focus()
                grid.myWin()?.toFront?.()
                setTimeout(() => {
                  if (viewdata.result && edOpts.popUp.moveNextCellAfter !== false)
                    grid.moveNextCell(edOpts.rowIndex, edOpts.colIndex, false, true)
                }, 50)
              }
            }
          }
          if (
            col.basicEd.edType &&
            (typeof col.basicEd.edType === 'function' || col.basicEd.prevEdType)
          ) {
            if (!col.basicEd.prevEdType) col.basicEd.prevEdType = col.basicEd.edType
            col.basicEd.edType = col.basicEd.prevEdType()
          }

          if (edOpts.edType === 'float')
            $(container).on('blur', '*', function (e) {
              if (e.target !== el) return
            })

          BasicEd(el, edOpts)

          var initialValue = _v(edOpts.model, f)
          if (typeof initialValue === 'undefined') {
            initialValue = ''
            _v(edOpts.model, f, null)
          }

          if (isLookup())
            $ed.data('kendoComboBox').input.on('focus', function () {
              if (edOpts.openOnFocus !== false) setTimeout(() => el.open(), 100)
            })

          el.val(initialValue, model)
          initialValue = edOpts._lastVal = el.val()

          function valChanged(v) {
            var prev = ow0.clone(model)
            var oldVal = _v(prev, col.field)
            var valueChanged = edOpts._lastVal !== v // || (v !== initialValue);

            ow0.log(
              'valChanged: ' +
                col.field +
                ':' +
                _v(model, col.field) +
                ' -> ' +
                v +
                ' valueChanged: ' +
                valueChanged,
              'combo',
              el
            )

            edOpts._lastVal = v
            _v(model, col.field, initialValue) // this needs to be different, sometimes the model has already been set by the control to the new value

            if (col.field.split('.').length > 1) {
              // nested
              var aFields = col.field.split('.')
              var baseField = aFields.shift()
              var x = ow0.clone(model[baseField])
              _v(x, aFields.join('.'), v) // set on the copy
              model.set(baseField, x || '')
            } else {
              if (v === null) if (col.type === 'string') v = '' // this causes weird problems in the combo if we send null - displays "[object Object]"!!

              // _v(model, col.field, edOpts._lastVal);  // this needs to be different, sometimes the model has already been set by the control to the new value
              model.set(col.field, v)
            }
            model.dirty = true
            var newPrev = ow0.clone(model)
            if (isLookup() || (prev !== newPrev && valueChanged)) {
              ow0.log(col.field + ': ' + oldVal + ' -> ' + v, 'combo', edOpts)
              if (col.onEdit) col.onEdit(prev, model, container)
              $(grid).trigger('ow-field-edit', [col.field, v, model, container])
            }
          }

          $ed.on('change ow-change', function (e) {
            //editor changed
            // we don't want the kendo control types to use 'change', only ow-change or the new value won't have propagated yet.
            if (
              e.type === 'change' &&
              (isLookup() || { float: 1, int: 1, time: 1, date: 1, datetime: 1 }[edOpts.edType])
            )
              return

            if (isLookup() && el.otext() !== '') el.validateAndTryFirst()

            var v = el.val(undefined, model)
            valChanged(v)
          })

          el.valChanged = valChanged

          if (col.editorInit) col.editorInit(el)
        }
    }

    // editors!
    if (col.editor)
      x.editor = function (container, options) {
        return (typeof col.readOnly === 'function' ? col.readOnly(options.model) : col.readOnly)
          ? $(x.template(options.model)).appendTo(container)
          : col.editor(container, options)
      }

    // cell templates
    x.template = function (d) {
      const readValue = col => {
        var v = typeof col.calc === 'function' ? col.calc(d) : col.field ? _v(d, col.field) : ''
        if (col.type === 'date' || col.type === 'datetime')
          if (typeof v === 'string' && v !== '') v = new Date(v)

        return typeof v === 'undefined' || v === null ? '' : v
      }

      let defaultValue = readValue(col)

      // formatting
      defaultValue =
        col.type === 'date'
          ? dates.formatDate(defaultValue, col.format)
          : col.type === 'datetime'
            ? dates.formatDateTime(defaultValue, col.format)
            : col.format
              ? ow0.toString(defaultValue, col.format)
              : defaultValue
      if (col.encoded !== false) defaultValue = htmlEncode(defaultValue) // prevents xss injection

      var val = col.template ? col.template(d, defaultValue) : defaultValue // col.format is 'XXX', x.format is '{0:XXX}'

      var classes = col.classes
        ? typeof col.classes === 'function'
          ? col.classes(d)
          : col.classes
        : ''

      if (typeof val === 'undefined' || val === null) val = ''
      else if (col.encoded) val = htmlEncode(val)

      return (typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly)
        ? '<span class="read-only">' + val + '</span>'
        : col.basicEd && col.basicEd.edType === 'colorpicker'
          ? '<span style="display: block; background-color: ' +
            (col.type === 'integer' ? delphiColortoHex(val) : val) +
            '">&nbsp;</span>'
          : '<span' + (classes ? ' class="' + classes + '"' : '') + '>' + val + '</span>'
    }

    // header
    if (col.headerAlign || col.align)
      x.headerAttributes = x.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || '')
      }

    if (col.checkAllNone) {
      // puts All/None links under the title
      var cmd = (col.field || '').toLowerCase()
      x.headerTemplate =
        x.headerTemplate ||
        function () {
          return (
            x.title +
            '<br><a class="auto-command-button owauto check-all-none" id="tickall-' +
            cmd +
            '"  data-command="tick-all" href="#">' +
            __('All') +
            '</a>' +
            '    <a class="auto-command-button owauto check-all-none" id="ticknone-' +
            cmd +
            '" data-command="tick-none" href="#">' +
            __('None') +
            '</a>'
          )
        }

      $(grid).on('command-tick-all', '#tickall-' + cmd, e => {
        e.target.focus() //remove focus from checkbox
        grid.myWin().progress(true)
        setTimeout(() => {
          var rows = grid.getViewData() //grid.getData();
          if (opts.dataSourceGroup && opts.dataSourceGroup.length > 0) {
            var index = -1
            rows.forEach((data, i) => {
              //update checkbox value
              $($(grid).find('tbody tr.k-grouping-row input')[i]).prop('checked', true)
              data.items?.forEach(data => {
                index++
                _v(data, col.field, true)
                grid.refreshRow($(grid).find('tbody tr:not(".k-grouping-row")')[index], data)
              })
            })
          } else
            rows.forEach((data, i) => {
              //update checkbox value
              _v(data, col.field, true)
              grid.refreshRow($(grid).find('tbody tr')[i], data)
            })

          setTimeout(() => {
            qc(grid).trigger(cmd + '-all')
            grid.myWin().progress(false)
            grid.dc?.saveCancelState()
          }, 100)
        }, 1)
      })
      $(grid).on('command-tick-none', '#ticknone-' + cmd, e => {
        e.target.focus() //remove focus from checkbox
        grid.myWin().progress(true)
        setTimeout(() => {
          var rows = grid.getViewData()
          if (opts.dataSourceGroup?.length > 0) {
            var index = -1
            rows.forEach((data, i) => {
              //update checkbox value
              $($(grid).find('tbody tr.k-grouping-row input')[i]).prop('checked', false)
              data.items?.forEach(function (data) {
                index++
                _v(data, col.field, false)
                grid.refreshRow($(grid).find('tbody tr:not(".k-grouping-row")')[index], data)
              })
            })
          } else
            rows.forEach((data, i) => {
              //update checkbox value
              _v(data, col.field, false)
              grid.refreshRow($(grid).find('tbody tr')[i], data)
            })

          setTimeout(() => {
            qc(grid).trigger(cmd + '-none')
            grid.myWin().progress(false)

            grid.dc?.saveCancelState()
          }, 100)
        }, 1)
      })
    }

    var wrapFooter = function (content) {
      var align = col.footerAlign || x.align || 'right'
      return (
        '<div style="text-align:' + align + ';" class="col-' + col.index + '">' + content + '</div>'
      )
    }

    // footer
    if (col.groupFooter) {
      if (typeof col.groupFooter === 'string') {
        var ag = col.groupFooter
        // use ours - childGrid
        col.groupFooter = function (d) {
          if (d[col.field]) return grid[ag](col.field, d[col.field] && d[col.field].group)
        } // eg. g.sum('Quantity', group)
      }

      if (typeof col.groupFooter === 'function')
        x.groupFooterTemplate = function (d) {
          return wrapFooter(ow0.toString(col.groupFooter(d), col.format))
        }
    }

    if (col.footer) {
      if (typeof col.footer === 'string') {
        const ag = col.footer
        if (opts.useKendoAggregates)
          // Kendo Aggregates don't seem to work some of the time.
          x.footerTemplate = d =>
            wrapFooter(ow0.toString((d[col.field] || {})[ag] || 0, col.format))
        // use ours - childGrid
        else col.footer = () => grid[ag](col.field) // eg. g.sum('Quantity')
      }

      if (typeof col.footer === 'function')
        x.footerTemplate = d => wrapFooter(ow0.toString(col.footer(d), col.format))
    }

    col.refreshFooter = function () {
      if (x.footerTemplate)
        $(grid)
          .find('.k-footer-template td div.col-' + col.index)
          .html(x.footerTemplate({}))
    }

    // filters
    if (opts.noFilters !== true) setFilters(col, x)

    x.owGridCol = col
    x.groupHeaderTemplate =
      col.groupHeaderTemplate ||
      function (d) {
        var title = x.title
        var value = d.value
        return title + ' : ' + value
      }
    x.defaultWidth = col.defaultWidth || col.width

    //added for hotel room overview
    if (col.locked !== null) x.locked = col.locked
    if (col.lockable !== null) x.lockable = col.lockable

    return x
  }

  return cols.filter(x => x.gridCol !== false).map(toKendoColumn)
}

/**
 * note many of these, although shared with FSPGrid should only be used by childGrid
 * because they can disconnect the FSPGrid from its dataSource
 * @param {jQuery} el - grid element - the already created kendoGrid
 * @param {Object} opts - See OW grid options
 * @returns
 */
const commonGrid = function (el, opts) {
  const o = el

  el.opts = opts

  o.fillout = function () {
    var $g = $(o)
    var newHeight = $g.innerHeight(),
      otherElements = $g.children().not('.k-grid-content'),
      otherElementsHeight = 0
    if (newHeight < 20) return
    otherElements.forEach(el => (otherElementsHeight += $(el).outerHeight()))
    $g.children('.k-grid-content').outerHeight(newHeight - otherElementsHeight)
  }

  o.isEditable = function () {
    return opts.editable !== false
  }
  o.isTabOutNewRow = function () {
    return opts.tabOutNewRow || opts.tabOutNewRow !== false
  }

  var k = $(el).data('kendoGrid')
  if (!k) return o

  // tabbing and focus control
  el.state = el.state || {}
  el.state.tableHasFocus = false
  el.state.lastFocused = { row: -1, cell: -1 }

  o.leaveRow = function leaveRow($tr, back, noSetFocus) {
    var data = o.getDataForRow($tr)
    if (el.isNewBlank(data) && opts.editable && opts.tabOutNewRow !== false) {
      if (el.state.lastFocused.row === $tr.index() && noSetFocus !== true) {
        el.state.lastFocused = { row: -1, cell: -1 }
        el.state.cellIndex = -1
        el.state.rowIndex = -1
      }
      el.state.inNewRow = false
      if (k) k.removeRow($tr)
      else o.cancelChanges($tr)

      $(el).trigger('ow-grid-change', [data, $tr])
    }
  }

  // empty - Removes all rows
  // This is really intended for read only grids.
  o.empty = function () {
    $(el).find('.k-grid-content tr').remove()
  }

  // deleting breaks the grid combo
  $(el).on('focus', 'input', e => {
    const t = e.target
    t.select()
    const tempKeyHandler = () => t.removeEventListener('keydown', tempKeyHandler)
    t.addEventListener('keydown', tempKeyHandler)
  })

  // focus tracking
  {
    var g = o
    var prevRow = -1

    g.fi = function ($td) {
      var $row = $td.parent()
      if ($td.is('td')) {
        if (prevRow !== $row.index() && prevRow > -1) {
          var $lastTr = $(g).find('tbody tr:eq(' + prevRow + ')')
          if ($lastTr.length) {
            ow0.log(
              ['>>>>>>>>>>>>>>>>>>>>>> ' + $row[0] + ' row: ' + prevRow + ' -> ' + $row.index()],
              'gridFocus'
            )

            o.state.lastFocused = { row: $row.index(), cell: $td.index() }
            // setTimeout(function() {
            o.leaveRow($lastTr, undefined, true)
            //},10);
          }
        }
        prevRow = $row.index()
      }
    }

    $(g).on('focus', '.k-grid-content tr td', function (e) {
      if ($(e.target).is('td')) g.fi($(e.target))
      else g.fi($(e.target).closest('td'))
    })
  }

  o.rowCount = function () {
    return k.dataSource.data().length
  }

  o.destroy = function () {
    $(el).remove()
    if (k) k.destroy()
    $(el).empty()
  }

  // this function extracts the values of just the core editable fields based on col defs
  // the value is then used in comparison to determine if changes have been made.
  o.dataRowCoreValue = function (rec, getRequiredOnly) {
    var result = (opts.cols || []).reduce(function (t, c) {
      var isReadOnly = typeof c.readOnly === 'function' ? c.readOnly(rec) : c.readOnly
      if (
        c.field &&
        c.gridCol !== false &&
        !c.calc &&
        !isReadOnly &&
        !c.ignoreCoreValue &&
        (getRequiredOnly ? c.required : true)
      )
        _v(t, c.field, _v(rec, c.field))

      return t
    }, {})

    function removeEmpty(result) {
      //remove null property
      Object.keys(result).forEach(function (key) {
        if (result[key] && typeof result[key] === 'object' && !(result[key] instanceof Date))
          result[key] = removeEmpty(result[key])
        // Recurse.
        else if (result[key] === undefined || result[key] === null) delete result[key]
        // Delete undefined and null.
      })
    }
    removeEmpty(result)

    return JSON.stringify(result)
  }

  function checkIsRequiredBlank(data) {
    if (!data) return true
    var coreValue = el.dataRowCoreValue(data, true)
    var isEmpty = false
    var cols = opts.cols.filter(function (c) {
      var isReadOnly = typeof c.readOnly === 'function' ? c.readOnly(data) : c.readOnly
      return c.required && !c.ignoreCoreValue && !isReadOnly
    })
    coreValue = JSON.parse(coreValue)
    isEmpty = cols.some(r => !coreValue[r.field] && coreValue[r.field] !== 0)
    return isEmpty
  }

  function checkIsNewBlank(data) {
    if (!data) return true
    // var isNewAndNotDirty = (data._new === true) && (data.dirty !== true);

    var coreValue = el.dataRowCoreValue(data)

    var result = el.newrow === coreValue && data._new && !data.notBlank // && !data.dirty);

    ow0.log(
      [
        'checkIsNewBlank: data: ' + coreValue + 'and el.newrow: ' + el.newrow,
        '  (_new, dirty, notBlank) -> ' +
          (data._new === true) +
          ' ' +
          data.dirty +
          ' ' +
          (el.notBlank === true),
        '  returns: ' + result
      ],
      'grid'
    )

    return result
  }

  o.newrow = null

  o.isNewBlank = function (rec) {
    return checkIsNewBlank(rec)
  }
  o.isRequiredBlank = function (rec) {
    return checkIsRequiredBlank(rec)
  }
  o.addRow = function () {
    if (opts.editable === false) return

    var rec = k.dataSource.add()
    k.dataSource.remove(rec)
    rec._new = true
    el.newrow = o.dataRowCoreValue(rec)
    k.dataSource.insert(k.dataSource.data().length, rec)

    $(el).trigger('ow-grid-change', [rec, k.dataSource.data().length - 1])
  }

  o.populate = function (data) {
    data = data || {}
    data = data[opts.fieldName || opts.name] || data[opts.name] || []

    // if any of the items are not objects, turn them into objects
    // handy for cases when you want to edit a list strings or ints etc
    if (Array.isArray(data)) {
      data = data.map(function (rec, i) {
        if (typeof rec !== 'object') {
          return {
            Index: i,
            Value: rec
          }
        }
        return rec
      })
    }

    var pageSize = opts.pageSize ? parseInt(opts.pageSize) : opts.pageable?.pageSize || 99999

    if (opts.scrollable) {
      if (opts.scrollable.virtual) {
        pageSize = opts.scrollable.pageSize || undefined
        k.dataSource.page(1)
      }
    }
    k.dataSource.data(data)

    if (pageSize !== undefined) k.dataSource.pageSize(pageSize)
  }

  o.setFilter = function (filter, refresh) {
    k.dataSource.filter(filter)
    if (refresh !== false) k.dataSource.read()
  }

  o.rowByIndex = function (rowIndex) {
    return $(el).find('tbody tr:eq(' + rowIndex + ')')
  }

  o.rowIndex = function () {
    if (!o.currentRow()) return -1
    return o.currentRow().index() || 0
  }

  o.getDataForRowIndex = function (index) {
    return k.dataItems()[index]
  }

  o.getPageSize = function () {
    return k.dataSource?._pageSize || 20
  }

  o.validate = function () {
    $(el).removeClass('k-input-errbg')
    $(el).find('td').removeClass('k-input-errbg')

    var result = { edType: 'grid', resVal: true, resErr: [] }
    var dataToValidate = k.dataSource.data()
    var viewdataToValidate = dataToValidate // k.dataSource.view();

    if (!opts.batch) viewdataToValidate = viewdataToValidate.filter(d => d.dirty)
    if (opts.required) {
      if (dataToValidate.length === 0) {
        result.resVal = false
        result.resErr.push((opts.title || opts.fieldName) + ' - No record found')
        $(el).addClass('k-input-errbg')
        return result
      }
    }

    var gridCols = k.getOptions().columns || []

    function allValid(dataToValidate, col) {
      var result = { resVal: 1, uid: [], errMsg: '' }

      col.validation = col.validation || {}

      if (!col.validation || !col.field) return result

      function rowForRecord(rec) {
        return $(el).find("tr[data-uid='" + rec.uid + "']")
      }

      dataToValidate.forEach(function (rec) {
        var $rowEl = rowForRecord(rec)

        if (typeof col.validation === 'function') {
          var rv = col.validation(rec, $rowEl)
          if (rv.resVal === 0) {
            result.resVal = 0
            result.errMsg = rv.errMsg
            result.uid = result.uid.concat(rv.uid)
          }
          return
        }

        for (var rule in col.validation) {
          var v = col.validation[rule]
          var displayValue = v // if it's a column name

          if (typeof v === 'undefined') {
            // if the rule refers to another field by Name
            continue
          }

          var val = rec[col.field]

          if (typeof v === 'string') {
            // if the rule refers to another field by Name
            // getFieldColName

            displayValue =
              (
                gridCols.filter(function (c) {
                  return c.field === v
                })[0] || {}
              ).title +
              ', ' +
              rec[v]
            v = rec[v]
            if (_v(col, 'owGridCol.type') === 'time') {
              // get rid of the arbitrary date parts of the time
              if (val && val.toJSON) val = val.toJSON().split(' ')[1]
              if (v && v.toJSON) v = v.toJSON().split(' ')[1]
            }
          }

          if (typeof val !== 'undefined' && val !== null && val !== '') {
            // if field has value is
            var err = ''

            if (rule === 'eq' && !(val === v)) {
              err = ' ' + __('should be equal to') + ' ' + displayValue
            } else if (rule === 'neq' && !(val !== v)) {
              err = ' ' + __('should not be equal to') + ' ' + displayValue
            } else if (rule === 'gt' && !(val > v)) {
              err = ' ' + __('should be greater than') + ' ' + displayValue
            } else if (rule === 'gte' && !(val >= v)) {
              err = ' ' + __('should be greater than or equal to') + ' ' + displayValue
            } else if (rule === 'lt' && !(val < v)) {
              err = ' ' + __('should be less than') + ' ' + displayValue
            } else if (rule === 'lte' && !(val <= v)) {
              err = ' ' + __('should be less than or equal to') + ' ' + displayValue
            }

            if (err !== '') {
              result.resVal = 0
              result.errMsg = err
              result.uid.push(rec.uid)
            }
          } else {
            if (col.validation.required) {
              if (col.owGridCol && col.owGridCol.readOnly) {
                var isReadOnly =
                  typeof col.owGridCol.readOnly === 'function'
                    ? col.owGridCol.readOnly(rec)
                    : col.owGridCol.readOnly
                if (isReadOnly) return
              }
              result.resVal = 0
              result.errMsg = ' requires a value'
              result.uid.push(rec.uid)
            }
          }
        }
      })

      return result
    }

    gridCols.map(function (col) {
      if (col.validation) {
        var validateObj = allValid(viewdataToValidate, col)
        if (!validateObj.resVal) {
          result.resVal = 0
          var msg = validateObj.errMsg !== '' ? validateObj.errMsg : ' field required'
          result.resErr.push(col.title + msg)
          ;(validateObj.uid || []).map(function (trId) {
            var tr = $(el).find("tr[data-uid='" + trId + "']") // get the row in question
            $(tr)
              .find("td[aria-describedby='" + col.headerAttributes.id + "']")
              .addClass('k-input-errbg') // find the cell and set invalid state
          })
        }
      }
    })

    return result
  }

  o.refresh = function () {
    k.dataSource.read()
  }

  o.addDefaultValues = function (defValues) {
    var schema = k.options.dataSource.schema
    if (schema.fields) schema = schema.fields

    schema = schema.model?.fields ? schema.model.fields : schema
    for (var field in defValues) {
      var value = defValues[field]
      schema[field] = schema[field] || { field: field } // add if doesn't exist
      schema[field].defaultValue = value
    }
  }
  if (opts.defaultValues) o.addDefaultValues(opts.defaultValues)

  o.saveAsExcel = function () {
    k.saveAsExcel()
  }

  // need to focus full-time controls when cell receives focus
  $(el).on('focus', 'tbody td.full-time-check > span:not(:has(.read-only))', function () {
    $(this).find('.ow-check')[0].focus()
  })

  $(el).on(
    'dblclick',
    'tbody tr:not(.k-grouping-row)',
    ow0.debounce(
      function (e) {
        var isTargetTbl = $(this).closest('table').attr('role') === 'grid'
        var $tr = isTargetTbl ? $(this) : $(this).closest('table').closest('tr')
        var $cell = $(e.target).is('td') ? $(e.target) : $(e.target).closest('td')
        o.select($tr)
        o.current($cell)
        $(el).trigger('row-edit', [$tr.index(), o.getDataForRow($tr)])
      },
      opts.debounce || 500,
      true
    )
  )

  // refreshes the data from server - or local data array.
  o.refresh = function (params) {
    $(el).data('kendoGrid')?.dataSource.read(params)
  }

  // changes to use a different url.
  o.setReadURL = function (url) {
    if (typeof k.dataSource.options.transport.read === 'function') o.url = url
    else k.dataSource.options.transport.read.url = url
  }

  o.selectFirst = function () {
    o.select($(o).find('tbody tr:first'))
    o.current($(o).find('tbody tr:first td:first'))
  }

  o.getDataForRow = function (rowEl) {
    return k.dataItem(rowEl)
  }

  o.selectRow = function ($rowEl) {
    if ($rowEl) $rowEl = $($rowEl) // just in case
    if ($rowEl) o.select($rowEl)
    return $rowEl
  }

  o.clearSelection = function () {
    return k.clearSelection()
  }

  o.showColumn = function (col) {
    k.showColumn(col)
  }

  o.hideColumn = function (col) {
    k.hideColumn(col)
  }

  o.showFilterRow = function (val) {
    var $filterRowDiv = $(o).find('.k-filter-row')
    if (val && val !== '0' && val !== 'false') $filterRowDiv.show()
    else $filterRowDiv.hide()
  }

  if (opts.hasFilters !== false) {
    var filterCells = $('.k-filter-row').find('input')
    filterCells.each(function (idx, cell) {
      //hide "No Data Found" message
      if ($(cell).data('role') == 'combobox') {
        var combo = $(cell).data('kendoComboBox')
        combo.setOptions({ noDataTemplate: ' ' })
      }
      if ($(cell).data('role') == 'autocomplete') {
        var autocomplete = $(cell).data('kendoAutoComplete')
        autocomplete.setOptions({ noDataTemplate: ' ' })
      }
    })

    //filterRow
    var parentFSP = $(el).parent()
    var $toggleFilterExisted = $(parentFSP).find('#toggleDiv')
    if ($toggleFilterExisted.length == 0) {
      var toggleIcon = '<a class="btn"><i class="fa fa-filter fa-2x"></i></a>'

      $(parentFSP).append(
        '<div class="gridToggleFilterDiv" id="toggleDiv">' + toggleIcon + '</div>'
      )

      setTimeout(() => {
        $(parentFSP)
          .find('#toggleDiv')
          .css('height', $(el).find('.k-grid-header').innerHeight() - 9 + 'px')
      })
    }
    $(parentFSP)
      .find('#toggleDiv')
      .off('click')
      .on('click', function () {
        var $filterRowDiv = $(el).find('.k-filter-row')

        opts.filterOpen = $filterRowDiv.css('display') == 'none'
        opts.userSettings.filterToggle = opts.filterOpen
        if (opts.filterOpen) $filterRowDiv.show()
        else $filterRowDiv.hide()
        setTimeout(() =>
          $(parentFSP)
            .find('#toggleDiv')
            .css('height', $(el).find('.k-grid-header').innerHeight() - 9 + 'px')
        )
        el.fillout?.()
      })

    o.showFilterRow(opts.filterOpen)
  } else {
    //used to hide the header scrollbar - fix for column and header not aligned when zoomed
    $(el).find('.k-grid-header').append('<div class="gridToggleFilterDiv"></div>')
    setTimeout(() =>
      $(el)
        .find('.gridToggleFilterDiv')
        .css('height', $(el).find('.k-grid-header').innerHeight() - 9 + 'px')
    )
  }

  enterKeyHandler(el) // put on specific enter-key handling

  $(el)
    .off('enter-key') // turn off default handler
    .on('enter-key', function () {
      if ($(document.activeElement).closest('.k-filtercell').length) {
        console.log('enter key in grid filter - suppress')
        return false
      } else if ($(document.activeElement).closest('.k-pager-input').length) {
        var elPager = $(document.activeElement).closest('.k-pager-input').find('.k-textbox')
        if (elPager.length > 0) k.dataSource.page($(elPager).val()) //when key enter in pager input
        return false
      } else {
        console.log('enter key in grid NOT filter - trigger upstream')
        return true
      }
    })

  //Reorder
  k.bind('columnReorder', e => {
    const { newIndex, oldIndex } = e

    setTimeout(() => {
      if (
        k.columns[newIndex].field === undefined ||
        k.columns[oldIndex].field === undefined ||
        k.columns[newIndex].isLocked ||
        k.columns[oldIndex].isLocked
      )
        k.reorderColumn(oldIndex, k.columns[newIndex])

      k.getOptions().columns.forEach((kCol, i) => {
        const col = kCol.owGridCol
        col.orderIndex = i
        opts.userSettings.cols[col.iden].orderIndex = col.orderIndex
      })
    }, 100)
  })

  k.resizable.bind('start', function (e) {
    var thCompare = $(e.currentTarget).data('th').data('field')

    var col = k.columns.find(function (x) {
      return x.field === thCompare
    })

    if (!thCompare || col.isNonResize) {
      e.preventDefault()
      setTimeout(() => {
        k.wrapper.removeClass('k-grid-column-resizing')
        $(document.body).add('.k-grid th').css('cursor', '')
        $(document.body).add('.k-grid th a').css('cursor', '')
      })
    }
  })

  // For itemfamilies-item
  o.getData = function () {
    return Array.prototype.slice.call(k.dataSource.data())
  }
  o.getViewData = function () {
    //get only visible rows
    return Array.prototype.slice.call(k.dataSource.view())
  }
  o.selectedData = function () {
    // Get the selected records on left grid
    var selectedData = []
    var selectedRows = o.select()
    for (var i = 0; i < selectedRows.length; i++)
      selectedData.push(o.getDataForRow(selectedRows[i]))

    return selectedData
  }
  o.removeSelectedRows = function () {
    var data = Array.prototype.slice.call(o.select())
    data.forEach(item => k.removeRow(item))
  }

  o.addLineData = function (record, key) {
    var data = o.getData()
    var founds = data.filter(f => f[key] === record[key])
    if (founds.length) return false
    else {
      k.dataSource.add(record)
      return true
    }
  }

  // for column change (by editing) events
  k.bind('save', function (e) {
    Object.keys(e.values).forEach(function (fieldName) {
      var newValue = _v(e.values, fieldName) // nested?

      opts.cols.forEach(function (col) {
        var fire = false

        // how do we correctly map from the "name" to the ow gridCol
        if (col.nestedField === fieldName) fire = true
        else if (col.field === fieldName) fire = true

        if (fire) {
          // if (fire && typeof col.on Edit === 'function') { // no longer fires on Edit so no need to test.
          _v(e.model, col.field, newValue)
          e.model.set(fieldName, newValue)
          $(el).find('.basic-ed').trigger('ow-change')
        }
      })

      // timeout so we get the updated values
      setTimeout(() => $(el).trigger('ow-change'), 10)
    })
  })

  o.refreshFooter = function () {
    opts.cols.forEach(col => col.refreshFooter && col.refreshFooter())
  }

  o.refreshColumn = function redrawColumn(row, column, i, rec) {
    var dataItem = rec || o.getDataForRow(row)

    var $cell = $(row).children('td[role="gridcell"]').eq(i)

    var cellIsEditing = $cell.find(':focus').length
    if (cellIsEditing) {
      // don't do anything if cell editor has focus
      return
    }

    if (typeof column.template === 'string') {
      var kendoTemplate = kendo.template(column.template)
      $cell.html(kendoTemplate(dataItem)) // Render using template
    } else if (typeof column.template === 'function') {
      $cell.html(column.template(dataItem)) // Render using template
    } else {
      // this is redundant as all columns have template in ow4
      // note, this is called by command button row!
      var fieldValue = dataItem[column.field]
      var values = column.values

      if (values !== undefined && values != null) {
        // use the text value mappings (for enums)
        for (var j = 0; j < values.length; j++) {
          var value = values[j]
          if (value.value === fieldValue) {
            $cell.html(value.text)
            break
          }
        }
      } else if (typeof column.format !== 'undefined') {
        // use the format
        $cell.html(kendo.format(column.format, fieldValue))
      } else $cell.html(fieldValue) // Just dump the plain old value
    }
  }

  o.refreshRow = function (row, rec) {
    // kendoGrid, row DOM object
    k.columns.forEach((c, i) => o.refreshColumn(row, c, i, rec))

    $(row)
      .find('td:not(.command-cell) span:not(.read-only)')
      .closest('td')
      .attr('tabindex', 0)
      .removeClass('non-editable-cell')
      .removeClass('no-tab')
    $(row)
      .find('td:not(.command-cell) span.read-only')
      .closest('td')
      .attr('tabindex', -1)
      .addClass('non-editable-cell')
      .addClass('no-tab')
    $(row).find('td:not(.command-cell).no-tab').attr('tabindex', -1)
    $(row).find('td[role=gridcell]:not(.no-tab):not(.non-editable-cell)').attr('tabindex', 0)
    $(row).find('td.full-time-edit[role=gridcell]').attr('tabindex', -1) // when they permanently have a control in the cell

    // added so that tab thru on dynamic readOnly column allow for direct editing
    $(row)
      .find('td[role=gridcell]:not(.no-tab):not(.non-editable-cell)')
      .on('focus', function (e) {
        var $cell = $(e.target)
        o.focusCell($cell)
      })
  }

  o.select = function ($row) {
    if (k.options.selectable !== false) {
      if ($row) k.select($row)
      return k.select()
    }
    if (!$row) return o.currentRow()
    o.current($row.find('td:first'))
    return $row
  }

  o.focusCell = function ($cell) {
    ow0.log('@@@@@@@ g.FOCUSCELL ' + [$cell.parent().index(), $cell.index()], 'gridFocus')

    o.state.navigating = true

    if (opts.editable && !$cell.hasClass('non-editable-cell')) {
      if ($cell.hasClass('full-time-check')) {
        $cell.find('.ow-check')[0].focus()
      } else if (!$cell.hasClass('k-edit-cell')) {
        if (opts.editable === 'inline') k.editRow($cell.parent())
        else setTimeout(() => k.editCell($cell), 100)
      }
      o.select($cell.parent())
      o.current($cell)
    } else {
      o.select($cell.parent())
      o.current($cell)
      if ($cell.find('input, a')[0]) $cell.find('input, a')[0].focus()
      else if (!$cell.is(':focus')) $cell[0]?.focus()
    }

    o.state.navigating = false
  }

  o.cellFocus = function (row, col, body) {
    var $row = body ? body.find('tr:eq(' + row + ')') : $(el).find('tbody tr:eq(' + row + ')')
    var $cell = $row.find('td:eq(' + col + ')')

    if ($cell.length === 0) return

    o.focusCell($cell)
  }

  o.resolveFocus = function () {
    if (!_v(o, 'state.lastFocused') && o.current && o.current()) {
      console.warn('NO grid.state.lastFocused set')
      let $cell = o.current()
      _v(o, 'state.lastFocused', { row: $cell.parent().index(), cell: $cell.index() })
    }
    if (_v(o, 'state.lastFocused')) {
      if (_v(o, 'state.lastFocused.row') < 0)
        console.warn('incorrect grid.state.lastFocused.row value')

      ow0.log('>>>>>>> GRID FOCUSING: ' + JSON.stringify(_v(o, 'state.lastFocused')), 'gridFocus')
      o.cellFocus(_v(o, 'state.lastFocused.row') || 0, _v(o, 'state.lastFocused.cell') || 0)
    } else {
      ow0.log('grid no have state.lastFocused - not setting!', 'gridFocus')
      o.cellFocus(0, 0)
    }
  }

  o.moveNextCell = function (currentRowIndex, currentCellIndex, back, isEmulate) {
    var g = el
    var $tr = k.tbody.find('tr:eq(' + currentRowIndex + ') ')
    var current = $tr.find('td:eq(' + currentCellIndex + ') ')

    back = back === true

    var rowIndex = $tr.index()
    var isLastCell =
      current.index() >=
      $tr.children('td[role=gridcell]:not(.no-tab):not(.non-editable-cell):last').index()
    var isLastRow = $tr.is(':last-child')
    var isFirstCell =
      current.index() <=
      $tr.children('td[role=gridcell]:not(.no-tab):not(.non-editable-cell):first').index()
    // var isFirstRow = $tr.is(':first-child')

    if (!back && isLastCell && g.FSPGrid) o.insertNewAfterSave = true

    ow0.log('##MOVENEXTCELL: ' + currentRowIndex + ', ' + currentCellIndex, 'gridFocus')

    const addLine = () => {
      g.addRow()
      var $cell = k.tbody.find('tr:last td:not(.no-tab):first')
      g.focusCell($cell)
    }
    // test to see if we need to create a new row, or discard new, unchanged record.
    var changingRow = (isLastCell && !back) || (isFirstCell && back)

    // set Timeout to allow things to propagate
    if (changingRow)
      return setTimeout(() => {
        var data = o.getDataForRow($tr)
        var isNewBlank = el.isNewBlank(data)
        var isRequiredBlank = false
        if (opts.disallowNewWhenExistingNewInvalid) {
          isRequiredBlank = el.isRequiredBlank(data)
          if (isRequiredBlank) el.validate()
        }
        o.leaveRow($tr)

        // if it wasn't a dummy row, add a new one because we
        if (
          !back &&
          !isNewBlank &&
          isLastCell &&
          isLastRow &&
          opts.tabOutNewRow !== false &&
          !isRequiredBlank
        ) {
          if (!o.insertNewAfterSave) {
            if (opts.maximumRow && el.rowCount && el.rowCount() >= opts.maximumRow) {
              //child grid maximum row
              ow0.popWarning('Warning', 'Reached maximum allowed rows.', 3000)
              return
            }
            // only if we're not already saving changes and adding row after!
            addLine()
          }
        } else if (!back && !isLastRow && isEmulate)
          setTimeout(() => {
            var $r = $(k.tbody.find('tr')[rowIndex + 1])
            var $c = $r.find('td[role=gridcell]:not(.no-tab):not(.non-editable-cell):first')
            o.focusCell($c)
          }, 50)

        if (back && rowIndex > 0)
          setTimeout(() => {
            var $r = $(k.tbody.find('tr')[rowIndex - 1])
            var $c = $r.find('td[role=gridcell]:not(.no-tab):not(.non-editable-cell):last')
            o.focusCell($c)
          }, 50)

        if (back && rowIndex === 0) setTimeout(() => g.focus(), 50)
      }, 10)

    if (isEmulate) {
      var isFound = false

      if (back) {
        var $cells = $tr.children('td[role=gridcell]:not(.no-tab):not(.non-editable-cell)')
        var c = null
        $cells.forEach(ele => {
          if ($(ele).index() < current.index()) c = $(ele).index()
        })
        if (c !== null) {
          ow0.log('  ##MOVEPRVECELL: FOCUSING CELL ' + rowIndex + ', ' + c, 'gridFocus')
          g.cellFocus(rowIndex, c)
        }
      } else {
        $tr.children('td[role=gridcell]:not(.no-tab):not(.non-editable-cell)').forEach(ele => {
          var col = current.index()
          if (!isFound && $(ele).index() > col) {
            ow0.log(
              '  ##MOVENEXTCELL: FOCUSING CELL ' + rowIndex + ', ' + $(ele).index(),
              'gridFocus'
            )
            g.cellFocus(rowIndex, $(ele).index())
            isFound = true
          }
        })
      }
    }
  }

  o.selectable = function (v) {
    if (k.options.editable === true) console.warn('selectable editable grid has not been tested')
    k.setOptions({ selectable: v })
  }

  o.sortable = function (v) {
    k.setOptions({ sortable: v })
  }

  o.reorderable = function (v) {
    k.setOptions({ reorderable: v })
  }

  o.editable = function (v) {
    k.setOptions({ editable: v })
  }

  if (opts.hideHeader) {
    $(o).addClass('no-header')
  }

  k.prefsApplied = true

  opts.bindBtnsonSelect = opts.bindBtnsonSelect || []
  o.disableBindBtns = function (disable) {
    opts.bindBtnsonSelect.forEach(name => {
      var $btnCmd = o.myWin().find('[data-command=' + name + ']')
      if ($btnCmd.length > 0) {
        var userRole = opts.viewdata.userRole
        if (
          !userRole ||
          (!userRole.CanWrite && name === 'copy') ||
          (!userRole.CanDelete && name === 'delete') ||
          (!userRole.CanRead && name === 'edit')
        )
          $btnCmd.forEach(x => x.odisable(true))
        else $btnCmd.forEach(x => x.odisable(disable))
      }
    })
  }

  if (opts.indicateCurrentRow !== false) $(el).addClass('indicate-current-row')
  if (opts.indicateCurrentCell === true) $(el).addClass('indicate-current-cell')

  o.indicateCurrent = function () {
    var $cell = o.current()
    if ($cell) {
      _v(o, 'state.lastFocused', { row: $cell.parent().index(), cell: $cell.index() })

      $(el).find('.ow-current-cell').removeClass('ow-current-cell')
      $cell.addClass('ow-current-cell')

      if (!opts.editable) {
        // only highlight row if non editable
        $(el).find('tr.ow-current-row').removeClass('ow-current-row')
        if ($cell) $cell.parent().addClass('ow-current-row')
      }
    }
  }

  $(el).on('click', 'tr td', function () {
    const t = this
    const $td = $(t).is('td') ? $(t) : $(t).closest('td')
    o.current($td)
  })

  o.currentRow = function () {
    if (!o.current()) return null
    return o.current().parent()
  }

  o.current = function ($cell) {
    if (!$cell) return k.current()
    if (!$cell.is('td')) return console.warn('grid.current is not a table cell!  Invalid.')
    var r = k.current($cell)
    o.indicateCurrent()
    return r
  }

  qc(o).bindState(() => o.fillout())

  return o
}
exports.commonGrid = commonGrid

/**
 * adds the standard grid button command event handlers to a grid
 * commands are refresh, edit, copy, delete, new
 *
 */
exports.commandsOnGrid = function (el) {
  var o = el

  const triggerCommandOnCurrentRow = cmd => {
    var $tr = o.currentRow()
    var data = o.getDataForRow($tr)
    // call handler
    $(el).trigger(cmd, [$tr ? $tr.index() : -1, data, $tr])
  }

  $(el)
    .on('command-refresh', function () {
      o.refresh()
      return false
    })
    .on('command-edit', function () {
      triggerCommandOnCurrentRow('row-edit')
      return false
    })
    .on('command-select', function () {
      triggerCommandOnCurrentRow('row-select')
      return false
    })
    .on('command-copy', function () {
      triggerCommandOnCurrentRow('row-copy')
      return false
    })
    .on('command-delete', function (e) {
      triggerCommandOnCurrentRow('row-delete')
      e.preventDefault()
      return false
    })
    .on('command-new', function () {
      triggerCommandOnCurrentRow('row-new')
      return false
    })
    .on('command-add-row', function () {
      triggerCommandOnCurrentRow('row-new')
      return false
    })
    .on('command-save', function () {
      triggerCommandOnCurrentRow('row-save')
      return false
    })
    .on('command-cancel', function () {
      triggerCommandOnCurrentRow('row-cancel')
      return false
    })
    // f2 on grid selects/edits the current row
    .on('keydown', '*', function (e) {
      // if (e.target.closest('.filter-panel').length === 0) return;

      if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        // F2
        var $row = o.currentRow()

        var mode = el.opts.viewdata.mode
        if (mode && mode === 'select') {
          var alreadySelected = o.current().closest('.k-state-selected').length
          o.clearSelection()
          if (!alreadySelected) o.current($row)
          $(el).trigger('command-select')
        } else {
          // o.current($row);
          $(el).trigger('command-edit')
        }
        e.preventDefault()
        return false
      }
    })
}

exports.hasFilterControls = function (el) {
  var o = $(el)[0]
  // var tickTock = 0

  $(el).on('ow-grid-databound', function () {
    if (o.focusFirstRowOnPopulate) {
      o.focusFirstRowOnPopulate = false
      var $c = $(el).find('tbody tr:first td:first')
      if ($c.length) {
        o.current($c)
        $(el).find('.k-grid-content table')[0].focus()
        el.resolveFocus()
      }
    }
  })

  el.myWin().on('keydown', '.filter-panel *', function (e) {
    if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
      // F2
      if (o.opts && o.opts.focusFirstRowOnPopulate !== false) {
        o.focusFirstRowOnPopulate = true
        var $panel = $(e.target).closest('.filter-panel')
        $panel[0].focus()
        var btn = $panel.find('.filter-button')[0]
        if (btn) btn.focus()
      }
      $(e.target).trigger('change')
      if (e.target.opts && e.target.opts.clientFilter)
        setTimeout(
          () => (o.refilter ? o.refilter() : o.refresh()), // owGrid
          10
        )
      else setTimeout(() => (o.dc?.load ? o.dc.load() : o.refresh()), 10)

      e.preventDefault()
      e.stopPropagation()
      return false
    }
  })

  $(el).on('command-filter-change', () => o.refresh())

  o.findColumnFilterControl = function (fieldName) {
    var controlName = "input[name='" + fieldName + "']"
    return o.myWin().find('.k-filtercell').find(controlName)[0]
  }

  if (el.opts?.dsName && Array.isArray(el.opts?.dsName))
    el.opts.dsName.forEach(ds => filterController(o, { dsName: ds }))
  else filterController(o, { dsName: el.id })
}

exports.ColumnResize = function (e) {
  //to prevent white space after last column. From https://www.telerik.com/forums/last-column-resizing-css-issue
  var grid = e.sender
  var gridHeaderTable = grid.thead.parent()
  var gridBodyTable = grid.tbody.parent()

  if (gridBodyTable.width() < grid.wrapper.width() - kendo.support.scrollbar()) {
    // remove the width style from the last VISIBLE column's col element
    gridHeaderTable.find('> colgroup > col').last().width('')
    gridBodyTable.find('> colgroup > col').last().width('')

    // remove the width property from the last VISIBLE column's object
    grid.columns[grid.columns.length - 1].width = ''

    // remove the Grid tables' pixel width
    gridHeaderTable.width('')
    gridBodyTable.width('')
  }
}

// Chrome update ~ Nov 2017 stopped kendo grid column resizing
// This is workaround
if (/chrom(e|ium)/.test(navigator.userAgent.toLowerCase())) {
  // if it's Chrome

  kendo.ui.Grid.prototype._positionColumnResizeHandle = function () {
    var that = this,
      indicatorWidth = that.options.columnResizeHandleWidth,
      lockedHead = that.lockedHeader ? that.lockedHeader.find('thead:first') : $()

    that.thead.add(lockedHead).on('mousemove' + '.kendoGrid', 'th', function (e) {
      var th = $(this)
      if (th.hasClass('k-group-cell') || th.hasClass('k-hierarchy-cell')) {
        return
      }

      function getPageZoomStyle() {
        var docZoom = parseFloat($(document.documentElement).css('zoom'))
        if (isNaN(docZoom)) {
          docZoom = 1
        }
        var bodyZoom = parseFloat($(document.body).css('zoom'))
        if (isNaN(bodyZoom)) {
          bodyZoom = 1
        }
        return 1
      }

      var clientX = e.clientX / getPageZoomStyle(),
        winScrollLeft = $(window).scrollLeft(),
        position = th.offset().left + (!kendo.support.isRtl(this.element) ? this.offsetWidth : 0)

      if (
        clientX + winScrollLeft > position - indicatorWidth &&
        clientX + winScrollLeft < position + indicatorWidth
      ) {
        that._createResizeHandle(th.closest('div'), th)
      } else if (that.resizeHandle) {
        that.resizeHandle.hide()
      }
    })
  }
}

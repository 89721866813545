const { navigator } = require('./scheduler7-header')
const { schedulerBody } = require('./scheduler7-body')
const { _id } = require('../../_id')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')
const { cancelChanges } = require('../data-model7')
const { dataBind } = require('../databind7')

const styles = scope =>
  html(`<style>
${scope} {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;      
}
${scope} .ow-scheduler-panel {
  padding: 10px;
} 
${scope} .ow-scheduler-context {
  overflow: auto;
  height: 100%;
}   
${scope} table.scheduler7 {
  margin:0;
  border:none;
  background-color:#fff;
  border-collapse:separate;
  border-spacing:0;
  border-left:1px solid #ccc;
}
/*${scope} table.scheduler7 tbody tr {
  line-height: 20px;
}*/
${scope} table.scheduler7 th {
  background:#777;
  border-right:1px solid #999;
  color:#fff;
  padding:3px;
  position:sticky;
  top:0;
  /* ensure header row sits atop everything else when scrolling down */
  z-index:7001;
}
${scope} table.scheduler7 td {
  background:#fff;
  border-right:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding: 0px;
} 
${scope} table.scheduler7 td>span {
  position: relative; 
  display: flex; /* fix for FF margin shifted*/     
}
${scope} table.scheduler7 td>span .card {
  padding: 0px;
  background: aqua;
  position: absolute;
  margin-top: -10px; /* half line-height */
  resize: vertical; /* IE not compatible */
  overflow: hidden; /*set to visisble will not work for resize*/
}  
/* ensure first header cell sits atop everything else when scrolling right */
${scope} table.scheduler7 th:first-child {
  position:sticky; /* IE not compatible */
  left:0;
  z-index:7002;
}
/* make first column sticky when scrolling right */
${scope} table.scheduler7 td:first-child {
  position:sticky; /* IE not compatible */
  left:0;
  border-right-color:#aaa;
  z-index: 7001;
}
${scope} table.scheduler7 tr td.hovered {
  background: #bfdbee;
  border: dashed 1px #000;
}
${scope} .hold { opacity: .5 }  
</style>`)

/**
 * container
 * --Navigate panel
 * --Scheduler table
 * @param {object} opts
 * @param {object} opts.view
 * @returns
 */
const scheduler7 = opts => {
  const id = 'scheduler-' + _id()

  const me = qc('div.scheduler7.fit.focusable')
    .attr({ id })
    .props({
      opts,

      // populate(model) {
      //   debugger
      // },

      val(cards) {
        const sche = this
        if (arguments.length) {
          if (cards === sche.model) return // if we are repopulating with same data

          if (sche.model) cancelChanges(sche.model) // resets the change control
          sche.model = cards
          sche.renderAsync()
        }
        return sche.model
      }
    })
    .attr({ tabindex: '0' })
    .css({ outline: '0' })

  me.state = { populating: 0, current: { selectedDate: new Date(new Date().setHours(0, 0, 0, 0)) } }
  me.sorts = []

  // const model = me.model
  // const dataSet = _v(model, me.fieldName) || []
  // me.val(dataSet)

  dataBind(me)

  me.header = navigator(me)
  me.body = schedulerBody(me)
  me.kids([styles('#' + id), me.header, me.body])

  if (me.el) me.trigger('ow-scheduler-databound')

  me.bindState('state.current.selectedDate', selectedDate => {
    me.body.loadHeader(selectedDate)
  })

  return me
}

module.exports.scheduler7 = scheduler7

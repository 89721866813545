const { qc } = require('../cmp/qc')
const { _v } = require('../_v')

const dataBind = qCtl => {
  if (qCtl.opts.fieldName) qCtl.attr({ 'data-field': qCtl.opts.fieldName })
  const dsName = qCtl.opts.dsName ?? qCtl.opts.dc?.dsName ?? qCtl.opts.dc?.opts?.dsName
  if (dsName) qCtl.opts.dsName = dsName
  if (qCtl.opts.isFilterControl) {
    if (dsName) qCtl.attr({ 'data-filter-for': dsName })

    return qCtl
      .addClass('ow-filter-control')
      .on('change', () => {
        qCtl.trigger(
          qCtl.opts?.clientFilter ? 'ow-client-filter-changed' : 'ow-server-filter-changed'
        )
        qCtl.opts.dc?.load?.()
      })
      .on('keydown', e => e.which === 113 && qCtl.opts.dc?.load()) // F2
  }
  if (qCtl.opts.dsName) qCtl.attr({ 'data-field-for': qCtl.opts.dsName })

  // qCtl.on('ow-change change', () => {
  //   if (qCtl.model) readField(qCtl.model, qCtl.el)
  // })

  if (qCtl.opts.fieldName && qCtl.populate === undefined && qCtl.val) {
    qCtl.populate = function (model) {
      this.model = model
      if (this.el?.populate) return this.el.populate(model)

      this.val(_v(model, this.opts.fieldName), model, true)
    }
  }

  return qCtl
}

const populateField = (el, model) => {
  const q = qc(el)
  q.model = model

  if (q.populate) return q.populate(model)
  if (el.populate) return el.populate(model)

  const opts = q.opts ?? el.opts

  var fieldName = opts?.fieldName ?? opts?.fieldName
  var v = _v(model, fieldName)

  if (typeof v === 'string' && opts.dataType === 'int') v = !isNaN(parseInt(v)) ? parseInt(v) : v
  if (typeof v === 'string' && opts.dataType === 'date') v = new Date(v)

  v = typeof v !== 'undefined' ? v : ''

  if (!fieldName) return console.log('populateField Failed: no fieldname', el)

  if (q.val && typeof q.val === 'function') return q.val(v, model)
  if (el.val && typeof el.val === 'function') return el.val(v, model)
  if (typeof el.value !== 'undefined') return (el.value = v)

  el.innerHTML = v // for display labels
}

const readField = (model, el) => {
  const q = qc(el)

  if (q.readData) return q.readData(model)
  if (el.readData) return el.readData(model)

  el.opts = q.opts ?? el.opts ?? {}

  const fieldName = el.opts?.fieldName
  if (!fieldName) return console.log('No readData for control ' + el.id)
  if (q.val) return _v(model, fieldName, q.val())
  if (el.val) return _v(model, fieldName, el.val())
  if (typeof el.value !== 'undefined') return _v(model, fieldName, el.value)

  _v(model, fieldName, el.innerHTML) //rec[fieldName] = el.innerHTML;
}

const dataControls = (dsName, el) =>
  no$(el)
    .find('[data-field]')
    .filter(el => qc(el).opts?.dsName === dsName)

const filterControls = (top, dsName) =>
  no$(top)
    .find('[data-filter-for]')
    .filter(el => el.opts?.dsName === dsName)

const populateControls = (dsName, model, el) =>
  dataControls(dsName, el).forEach(el => {
    populateField(el, model)
    qc(el).renderAsync()
  })

const readControls = (dsName, model, el) =>
  dataControls(dsName, el).forEach(el => readField(model, el))

module.exports = { dataBind, populateControls, readControls, dataControls, filterControls }

const readFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName).forEach(fc =>
    qc(fc).readFilter((filter.filters = filter.filters ?? []))
  )
  return filter.filters
}
module.exports.readFilterControls = readFilterControls

// call the filter() on each filterControl that's bound to me.
const readServerFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName)
    .filter(el => !qc(el).opts || !qc(el).opts?.clientFilter)
    .forEach(el => qc(el).readFilter((filter.filters = filter.filters ?? [])))
  return filter.filters
}
module.exports.readServerFilterControls = readServerFilterControls

// call the filter() on each filterControl that's bound to me.
const readClientFilterControls = (top, dsName, filter = {}) => {
  filterControls(top, dsName)
    .filter(el => qc(el).opts?.clientFilter)
    .forEach(el => qc(el).readFilter((filter.filters = filter.filters ?? [])))
  return filter.filters
}
module.exports.readClientFilterControls = readClientFilterControls

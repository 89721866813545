const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { formatString, culture, __ } = require('../culture')
const { parseDate } = require('../date-parser')
const { iconCodes } = require('../icon-codes')
const { no$, $find, $offset } = require('../no-jquery')
const theme = require('../theme')
const { check7 } = require('./check7')
const { cmenu7 } = require('./cmenu7')
const { combo7 } = require('./combo7')
const { date7 } = require('./date7')
const { datetime7 } = require('./datetime7')
const { float7 } = require('./float7')
const { int7 } = require('./int7')
const { text7 } = require('./text7')
const { gridPager7 } = require('./gridPager7')
const { dataBind } = require('./databind7')
const { recordReact, fieldReact, $meta } = require('./dc7')
const { filterOperators } = require('./filters')
const { iconBlue } = require('../theme')
const { killEvent } = require('../killEvent')
const { _v } = require('../_v')
const { htmlEncode } = require('../html-encode')

const headerCellTag = 'td'

const filterTypeMap = {
  bit: 'boolean',
  decimal: 'float',
  int: 'integer',
  float: 'float',
  currency: 'currency',
  date: 'date',
  datetime: 'datetime'
}

const typeMap = {
  char: 'string',
  bit: 'boolean',
  decimal: 'number',
  integer: 'number',
  float: 'number',
  currency: 'number',
  date: 'date',
  datetime: 'date',
  time: 'string',
  timeString: 'string'
}

const edTypeMap = {
  char: 'text7',
  bit: 'check7',
  boolean: 'check7',
  decimal: 'float7',
  integer: 'int7',
  float: 'float7',
  currency: 'float7',
  date: 'date7',
  datetime: 'datetime7',
  time: 'time7',
  weekdays: 'weekdays',
  timeString: 'timeString'
}

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} grid.opts - grid opts
 * @param {boolean=false} grid.opts.hasFilters - if false, the column top filters aren't created
 */
const colsToGridColumns = function (cols, qGrid) {
  // Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".
  const { opts } = qGrid

  const colTemplates = {
    checkBox(model) {
      const col = this
      return check7({
        inGrid: true,
        col,
        ...(col.ctl ?? {}),
        fieldName: col.field,
        model
      }).wrap()
    },

    text(model, i) {
      const col = this
      var f = col.field
      var readOnly = cellReadOnly(col, model)

      if (readOnly) return '' + (columnValue(col, model, i, readOnly, false) ?? '&nbsp;')
      var v = columnDataValue(col, model, i, readOnly, true)

      let me = (
        col.dataType === 'int'
          ? int7
          : col.ctl?.ctlType === 'date7' || col.dataType === 'date' || col.type === 'date'
          ? date7
          : col.ctl?.ctlType === 'datetime7' || col.dataType === 'date' || col.type === 'date'
          ? datetime7
          : col.dataType === 'float'
          ? float7
          : text7
      )({
        inGrid: true,
        col,
        model,
        value: v,
        fieldName: f,
        maxlength: this.maxLength && '' + this.maxLength,
        ...(col.ctl ?? {})
      })

      return me.wrap()
    },

    combo(model, i) {
      const col = this
      var f = col.field
      var readOnly = cellReadOnly(col, model)

      if (readOnly) return '' + (columnValue(col, model, i, readOnly, false) ?? '&nbsp;')
      var v = columnDataValue(this, model)

      let me = combo7({
        view: opts.view,
        inGrid: true,
        model,
        col,
        ...(col.ctl ?? {}),
        value: v,
        fieldName: f
      })

      return me.wrap()
    }
  }

  colTemplates.boolean = colTemplates.checkBox
  colTemplates.check = colTemplates.checkBox

  opts.cols.forEach((col, coli) => {
    col.coli = coli
    const editable = col.gridCol !== false && !col.readOnly && opts.editable !== false

    if (col.type === 'date') col.format = col.format || culture().DateFormatSetting
    if (col.type === 'integer') col.type = 'int'
    if (col.type === 'decimal') col.type = 'float'

    col.name = col.name ?? col.field ?? coli
    col.setting = opts.userSettings?.cols?.[col.name] ?? {}

    if (col.gridCol === false) return

    if (editable && col.combo) col.template = col.template || colTemplates['combo']

    if (col.width === undefined) col.width = 100

    const ctl = col.ctl || {}
    if (col.format) ctl.format = ctl.format || col.format

    if (editable) col.template = col.template || colTemplates[col.type] || colTemplates.text

    if (col.gridCol !== false) {
      col.width = col.setting.width ?? col.width ?? 100
      col.hidden = col.setting.hidden ?? 0
      col.order = col.setting.order ?? coli
    }
  })

  function setFilters(col) {
    var isFilterable = col.filterType !== 'na'
    if (!isFilterable) return

    if (!col.filterType) {
      if (col.ctl?.list) col.filterType = 'listFilter'
      else {
        col.filterType = col.type ?? col.dataType ?? 'string'
        if (col.filterType !== 'boolean')
          col.filterType = filterTypeMap[col.filterType] ?? col.filterType
      }
    }

    if (col.filterType === 'check') col.filterType = 'boolean'

    if (col.defaultOperator)
      if (col.filterable?.cell) col.filterable.cell.operator = col.defaultOperator
  }

  var result = cols
    .filter(x => x.gridCol !== false)
    .map((col, i) => {
      if (col.editable === false)
        console.warn('gridCol.editable is deprecated, please use gridCol.readOnly: true')

      col.index = i
      Object.assign(col, {
        attributes: { class: '' },
        options: {},
        title: col.title || '',
        align: col.align || ''
      })

      if (typeof col.required !== 'undefined')
        if (col.ctl && !('required' in col.ctl)) col.ctl.required = col.required

      if (typeof col.validation === 'function')
        col.validation = { validateFunction: col.validation }
      col.validation = col.validation || {}

      if ('required' in col || col.ctl?.required)
        col.validation.required = col.required || col.ctl?.required

      if (col.min) col.validation.gte = col.validation.gte || col.min

      if (col.max) {
        col.validation.lte = col.validation.lte || col.max
        delete col.max
      }

      if (col.type) col.mappedType = typeMap[col.type] || col.type

      if ((col.field || '').indexOf('.') > -1 && !col.template && opts.unnestFields) {
        var field = col.field
        col.template =
          col.template ||
          function (d) {
            var v = _v(d, field)
            return v === undefined && v === null ? '' : col.format ? formatString(v, col.format) : v
          }
        col.nestedField = field.split('.').join('_')
      }

      if (col.mappedType === 'boolean')
        col.headerAttributes = col.headerAttributes || {
          style: 'text-align: ' + (col.headerAlign || col.align || 'center')
        }

      if (col.type === 'int' || col.type === 'decimal' || col.type === 'float')
        col.headerAttributes = col.headerAttributes || {
          style: 'text-align: ' + (col.headerAlign || col.align || 'right')
        }

      if (col.type === 'currency')
        col.headerAttributes = col.headerAttributes || {
          style: 'text-align: ' + (col.headerAlign || col.align || 'right')
        }

      if (col.headerClass)
        col.headerAttributes.class = (col.headerAttributes.class || '') + col.headerClass

      if (col.fullTimeEdit || col.fullTimeCheck !== false)
        col.attributes.class = (col.attributes.class || '') + ' full-time-edit ow-edit-cell'

      if (col.mappedType === 'boolean')
        col.template =
          col.template ??
          (col.fullTimeCheck === false
            ? data => {
                var list = [__('false'), __('true')]
                return list[data[col.field] * 1]
              }
            : data => qc('i.icon', data[col.field] ? html(iconCodes.check) : html('&nbsp;')))

      // formats
      if (col.type === 'date') col.format = col.format || culture().DateFormatSetting

      if (col.type === 'datetime') col.format = col.format || culture().DateTimeFormatSetting

      if (col.type === 'time') {
        col.format = col.format || culture().TimeFormatSetting
        col.attributes = col.attributes || {}
        col.attributes.class = (col.attributes.class || '') + ' time'
      }
      if (col.type === 'currency') col.format = col.format || 'c'

      // staticlookup
      if (col.list) {
        col.ctl = col.ctl || {}
        col.ctl.list = col.ctl.list || col.list
        col.ctl.ctlType = 'combo'
        col.ctl.fieldName = col.ctl.fieldName || col.field
      }

      if (col.ctl?.ctlType === 'combo') {
        col.align = col.align || 'left'
        var list = col.ctl.list || []

        col.template =
          col.template ||
          function (d) {
            var v = _v(d, col.field)
            if (v === undefined) return ''

            if (d) {
              var matches = list.filter(y => _v(y, col.ctl.valueField) == v)
              if (matches[0]) return matches[0][col.ctl.textField || 'Text']
              if (typeof v === 'number') {
                v = list[parseInt(v)]
                if (typeof v === 'object') v = ''
              }
            }
            return v
          }
      }

      const isLookup = () => col.ctl?.ctlType === 'combo'

      if (!col.ctl)
        col.ctl = {
          ctlType: col.ctlType || edTypeMap[col.type] || 'text',
          fieldName: col.field,
          noWrapper: true,
          noLabel: true,
          inGrid: true
        }

      col.ctl.inGrid = true
      col.ctl.col = () => col
      col.ctl.fieldName = col.ctl.fieldName || col.field
      col.ctl.noWrapper = true
      col.ctl.noLabel = true
      if (col.maxLength) col.ctl.maxLength = col.maxLength

      if (isLookup()) col.attributes.class = (col.attributes.class || '') + ' lookup-col'

      if (col.mappedType && !isLookup()) {
        col.attributes.class =
          (col.attributes.class || '') +
          ' ' +
          col.mappedType +
          '-col' +
          (col.type === 'integer' ? ' align-left' : '')
        if (col.align) {
          col.attributes.class = (col.attributes.class || '') + ' align-' + col.align
        }
      }

      // cell templates
      col.kColTemplate = function (d) {
        function readValue(col) {
          var v = typeof col.calc === 'function' ? col.calc(d) : col.field ? _v(d, col.field) : ''
          if (col.type === 'date' || col.type === 'datetime')
            if (typeof v === 'string' && v !== '') v = parseDate(v, col.format)

          return v ?? ''
        }

        var defaultValue = readValue(col)

        if (col.format) defaultValue = formatString(defaultValue, col.format)

        if (col.encoded !== false) defaultValue = htmlEncode(defaultValue)

        var val = col.template ? col.template(d, defaultValue) : defaultValue

        var classes = col.classes
          ? typeof col.classes === 'function'
            ? col.classes(d)
            : col.classes
          : ''

        if (typeof val === 'string' && !col.encoded) val = html(val)
        val = val ?? []

        return (typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly)
          ? qc('span.read-only', val)
          : // : col.ctl?.ctlType === 'colorpicker'
            // ? qc('span', html('&nbsp;')).css({
            //     display: 'block',
            //     backgroundColor: col.type === 'integer' ? delphiColortoHex(val) : val
            //   })
            qc('span' + (classes ? '.' + classes.split(' ').join('.') : ''), val)
      }

      // header
      if (col.headerAlign || col.align) {
        col.headerAttributes = col.headerAttributes || {
          style: 'text-align: ' + col.headerAlign || col.align
        }
      }

      // group footer
      if (col.groupFooter) {
        const wrapFooter = content =>
          qc('span.col-' + col.index, content).css({
            textAlign: col.footerAlign || col.align || 'right'
          })

        if (typeof col.groupFooter === 'string') {
          let ag = col.footer
          col.groupFooter = function (d) {
            // FROM R1: if (d[col.field]) return d[ag](col.field, d[col.field] && d[col.field].group)
            // FROM R2: if (d[col.field]) return grid[ag](col.field, d[col.field] && d[col.field].group)
            if (d[col.field])
              return (d[ag] ? d[ag] : qGrid.el[ag])(col.field, d[col.field] && d[col.field].group)
          } // eg. g.sum('Quantity', group)
        }

        if (typeof col.groupFooter === 'function')
          col.groupFooterTemplate = d => wrapFooter(formatString(col.groupFooter(d), col.format))
      }

      // filters
      if (opts.noFilters !== true) setFilters(col)

      col.width = col.width ?? col.defaultWidth
      return col
    })

  return result
}

const bindColWidth = cell => {
  cell
    .bindState(
      () => cell.col.width + 'px',
      width => cell.css({ width })
    )
    .bindState(
      () => (cell.col.hidden ? 'none' : undefined),
      display => cell.css({ display })
    )
}

const groupMembers = (g, group) => qc(g).recs.filter(r => !r._group && $meta(r).group === group)

const toggleGroup = (qGrid, rec) => {
  rec._group.open = !rec._group.open
  qGrid.dc.applyGroupVisibility()
  qGrid.val(qGrid.dc.currentFilter.recs)
  qGrid.renderAsync()
}

const expandAllGroups = (g, expand = true) => {
  const qGrid = qc(g)
  const { dc } = qGrid
  if (!qGrid.recs) return
  qGrid.recs.filter(item => item._group).forEach(r => (r._group.open = expand))
  dc.applyGroupVisibility()
}
const collapseAllGroups = g => expandAllGroups(g, false)

const isNewBlank = rec =>
  rec === undefined ? false : $meta(rec).new && Object.keys($meta(rec).changes).length === 0

const applyOrderToRow = tr => {
  let orderChanged
  const kids = qc(tr)
    .kids()
    .sort((a, b) => {
      const swap = a.col.order - b.col.order
      if (swap < 0) orderChanged = true
      return swap
    })
  if (orderChanged) qc(tr).kids(kids)
}

const drawColFooter = function (qGrid, col) {
  if (col.gridCol === false) return ''

  const { dc, opts } = qGrid

  if (col.footer && opts.hasFilter !== false) opts.hasFooter = true

  var classes = ''
  var style = ''

  if (col.footerAttributes?.class) classes = classes + ' ' + col.footerAttributes.class + ' '
  if (col.footerAttributes?.style) style = style + ';' + col.footerAttributes.style

  const me = qc(
    'td.ow-footer.ow-footer.' + classes + '.' + (col.type || '') + '-col.coli-' + col.coli,
    dataBind(
      qc('span.' + (col.type || '')).props({ opts: { fieldName: 'footer-coli-' + col.coli } })
    )
  )
    .css(style)
    .props({ coli: col.coli, col })

  bindColWidth(me)

  const wrapFooter = content =>
    qc('span.col-' + col.coli, content).css({
      textAlign: col.footerAlign || col.align || 'right'
    })

  if (typeof col.footer === 'string') {
    const ag = col.footer
    if (['sum', 'avg', 'max', 'min', 'count'].includes(ag))
      me.bindState(
        () => (ag === 'count' ? dc.count : dc.aggregates?.[col.field][ag]),
        v => {
          v = v === undefined ? '' : formatString(v, col.format || 'n')
          me.kids([wrapFooter(v)])
        }
      )
  }

  if (typeof col.footer === 'function')
    me.bindState(
      () => col.footer(),
      v => me.kids([wrapFooter(v)])
    )

  me.kids([wrapFooter(html('&nbsp;'))])

  col.preRenderFooter?.(me)

  return me
}

let draggingCol

const drawColHeader = function (qGrid, col) {
  if (col.gridCol === false) return ''

  const me = qc(
    headerCellTag + '.ow-header' + (col.type ? '.' + col.type + '-col' : '') + '.coli-' + col.coli
  ).props({
    col,
    coli: col.coli
  })

  if (qGrid.opts.reorderable !== false) {
    // me.attr({ draggable: 'true' })
  }

  bindColWidth(me)

  if (!col.title && col.field) col.title = __(col.field)

  if (col.headerAttributes?.class) me.addClass(col.headerAttributes.class)
  if (col.headerAttributes?.style) me.css(col.headerAttributes.style)

  me.kids([
    qc(
      'span',
      qGrid.opts.sortable && col.sortable !== false
        ? qc('a.col-sort', html(col.title || ''))
            .attr({ href: '#', tabindex: '-1' })
            .on('click', e => {
              const qTitle = qc(e.target)
              qTitle.removeClass('fa-caret-up').removeClass('fa-caret-down')

              const { dc } = qGrid

              const sorts = dc.sort ?? []

              if (sorts.length && sorts[sorts.length - 1][2] === col.coli) {
                let asc = sorts[sorts.length - 1][1]
                if (!asc) sorts.pop()
                else {
                  sorts[sorts.length - 1][1] = false
                  qTitle.addClass('fa-caret-up')
                }
              } else {
                let f = col.sortOnField
                f = f ?? col.calc ?? col.field

                sorts.push([f, 1, col.coli])
                qTitle.addClass('fa-caret-down')
                if (sorts.length > 3) sorts.splice(-1)
              }

              if (qGrid.opts.sortable === 'server') {
                dc.sort = sorts
                dc.load()
              } else qGrid.el.refilter()
            })
        : html(col.title || '')
    )
      .attr({ draggable: qGrid.opts.reorderable !== false ? 'true' : 'false' })
      .on('dragstart', () => {
        draggingCol = me
      })
      .on('dragend', () => {
        draggingCol = undefined
      })
      .on('dragover', e => {
        if (draggingCol === me) return
        if (draggingCol) return common.killEvent(e, true)
      })
      .on('drop', e => {
        if (draggingCol === me) return

        e.preventDefault()

        const newIndex = me.col.order
        const fromIndex = draggingCol.col.order

        console.log(newIndex, fromIndex)

        qGrid.opts.cols.forEach(col => {
          if (col === draggingCol.col) col.order = newIndex
          else if (newIndex < fromIndex)
            col.order >= newIndex && col.order < fromIndex && col.order++
          else col.order <= newIndex && col.order > fromIndex && col.order--

          col.setting.order = col.order

          if (col.setting.order === col.coli) delete col.setting.order
        })

        // insert col
        qGrid.find('tr').forEach(applyOrderToRow)
      }),

    ...(col.checkAllNone
      ? [
          qc(
            'a.check-all-none.on',
            qc('span.tri-bl').on('click', function () {
              const value = !no$(this).toggleClass('all-on').hasClass('all-on')

              const f = col.checkAllNoneField || col.field
              qGrid.progress()
              setTimeout(() => {
                qGrid.recs.forEach(rec => {
                  _v(rec, f, !value)
                  no$(qGrid.getTr(rec).el)
                    .find('.ow-check[data-field="' + f + '"]')
                    .forEach(el => el.val(!value))
                })
                qGrid.progress(false)
              }, 1)
            })
          ).attr({ tabindex: '-1' })
        ]
      : []),

    qc('div.resize-col-handle')
      .attr({ draggable: 'false' })
      .on('mousedown', e => qGrid.resizeColHandleMouseDown(e, me.el))
  ]).attr({ scope: 'col', role: 'columnheader' })

  col.preRenderHeader?.(me)

  return me
}

const drawColFilter = function (qGrid, col, qFilterRow) {
  if (col.gridCol === false) return ''
  const me = qc(
    headerCellTag +
      '.ow-filter-col' +
      (col.type ? '.' + col.type + '-col' : '') +
      '.coli-' +
      col.coli
  ).props({ col, coli: col.coli })

  bindColWidth(me)

  if (col.filterType === 'na' || !col.field) return me

  const dsName = qGrid.opts.dc.opts.dsName ?? qGrid.id
  const filterField = col.filterField || col.field

  let filterType = col.filterType

  me.filterControl = (
    filterType === 'datetime' ? datetime7 : filterType === 'date' ? date7 : text7
  )({
    dc: qGrid.opts.dc,
    isFilterControl: true,
    fieldName: filterField,
    label: col.title ?? '',
    dsName,
    filterType,
    col,
    clientFilter: col.clientFilter || false
  }).addClass(filterType || '')

  me.filterControl.op =
    me.filterControl.op ?? me.filterControl.defOp ?? filterType === 'string' ? 'contains' : 'eq'

  if (filterType === 'listFilter')
    me.filterControl.props({
      readFilter(filters) {
        const valueField = col.ctl.valueField ?? 'Value'
        const textField = col.ctl.textField ?? 'Text'
        const sub = this.el?.value.toLowerCase?.()

        filters.push({
          field: col.field,
          operator: 'in',
          value: col.ctl.list
            .filter(item => !sub || _v(item, textField)?.toLowerCase().indexOf(sub) > -1)
            .map(item => _v(item, valueField))
        })
      }
    })

  me.kids(
    qc('span', [
      me.filterControl.wrap(),

      qc('i.icon.fa.filter-icon', html('')).on('click', e => {
        const openMenu = cmenu7(e.target, {
          view: qGrid.opts.view,
          point: { left: e.pageX, top: e.pageY },
          setWidth: false,
          content() {
            const ftypes =
              filterType === 'boolean'
                ? { true: __('true'), false: __('false') }
                : filterOperators()[filterType ?? 'string']

            return qc(
              'ul',
              Object.keys(ftypes)
                .map(op =>
                  qc('li', qc('a.menu-item', ftypes[op]).props({ op }).attr({ href: '#' }))
                    .on('click', () => {
                      console.log('Setting filter Op:', op)
                      if (me.filterControl) me.filterControl.op = op
                      openMenu.close()
                      me.filterControl?.el?.focus()
                      me.filterControl?.trigger('change')
                      me.renderAsync()
                    })
                    .addClass(me.filterControl?.op === op ? 'selected' : '')
                )
                .concat(
                  qc(
                    'li',
                    qc('a.menu-item', __('Clear Filter'))
                      .attr({ href: '#' })
                      .on('click', () => {
                        delete me.filterControl.op
                        if (me.filterControl.clear) return me.filterControl.clear()
                        me.filterControl.val(null)
                        me.filterControl.isSet = false
                        openMenu.close()
                        me.filterControl?.el?.focus()

                        me.filterControl?.trigger('change') // qGrid.dc.load()
                        me.renderAsync()
                      })
                  )
                )
            )
          }
        })
      })
    ])
  )

  col.preRenderFilter?.(me)

  return me
}

/**
 * @params {object} opts.dc -
 * @params {array|object} opts.cols -
 * @params {boolean} opts.live - will cause edited lines to be saved on row change and deletes to call to the server on delete click.
 * @params {boolean} opts.sortable - can click on header to sort by that field on clientside
 * @params {boolean} opts.resizable - can resize columns
 * @params {boolean} opts.selectable - can select rows (not yet implemented)
 * @params {boolean=true} opts.reorderable - if false, the column top filters aren't created
 * @params {boolean} opts.hasFooter -
 * @params {boolean} opts.hasFilters -
 * @params {boolean} opts.hasColFilters -
 * @params {boolean} opts.editable - can edit rows
 * @params {boolean} opts.allowNewRow
 * @params {object} opts.disallowNewWhenExistingNewInvalid
 * @params {boolean} opts.showDeletedRows - when user deletes a row, it can still be seen but is struck through - disappears on save.
 * @params {boolean} opts.indicateCurrentRow -
 * @params {boolean} opts.indicateCurrentCell -
 * @params {boolean} opts.saveOnlyChangedRows - when calling qGrid.readData, only records that have changed will be .
 * @params {boolean} opts.allowColumnMenu - right click to hide-show columns
 * @params {boolean} opts.allowSaveTemplate - in header context menu you can save current column sizes etc as default.
 * @params {object} opts.reactFields optional - the properties are fieldnames of records, if false changes for that field do not require saving.  If a control exists with data-field=XXX then XXX will be set to true if not already set to false.
 * @params {array} opts.buttonColumn - icons in an extra column eg ['save', 'delete', {...}]
 * @params {string} opts.fieldName - for dataBind to Array property of parent record - childGrid
 * @params {boolean} opts.markChanges - indicates a row has unsaved changes on the left side
 * @params {array[string]} opts.groupBy - list of fieldNames to groupby. Not compatible with client-side  filters and sorting
 * @params {boolean} opts.expandAllGroup -
 */
const grid7 = opts => {
  if (!opts.view) throw 'grid7 needs opts.view'

  const iden = opts.iden ?? opts.id ?? opts.name + 'Grid'
  opts.iden = iden
  if (!iden) throw 'Grid7 requires opts.iden'

  opts.userSettings = JSON.parse(
    JSON.stringify(opts.view.viewdata?.winpref?.grids?.[opts.iden] ?? {})
  )

  let currentRow

  const qGrid = qc('div.grid.grid7.iden-' + iden)
    // methods
    .props({
      select(tr) {
        if (this.opts.selectable !== false) {
          if (tr) {
            if (currentRow !== tr) {
              currentRow?.removeClass('ow-state-selected')
              currentRow = tr
              tr.addClass('ow-state-selected')
            }
          }
          return tr
        }
        // row--1 means row: -1 -> grouping row
        if (!tr || tr.hasClass('row--1')) return currentRow
        this.current(tr.kids()[0])
        return tr
      },

      readField(model) {
        const me = this
        me.opts.fieldName ?? _v(model, me.opts.fieldName, me.val())
      },

      refilter(isClient) {
        isClient ? this.refilter() : this.dc.load()
      },

      newRowAllowed() {
        if (opts.editable === false) return false
        if (opts.disallowNewWhenExistingNewInvalid) {
          var res = qGrid.el.validate()
          if (res.resVal === 0) return false
        }
        if (typeof opts.allowNewRow === 'function') return opts.allowNewRow()
        return opts.allowNewRow
      },

      addRow() {
        const qGrid = this

        if (!qGrid.newRowAllowed()) return

        var atBottom = qBody.el.scrollTop + 2 > qBody.el.scrollHeight - qBody.el.clientHeight

        if (qGrid.recs.length && !atBottom) {
          qGrid.page = qGrid.recs.length - 1
          qBody.el.scrollTop = Math.ceil(qBody.el.scrollHeight - qBody.el.clientHeight)

          qGrid.renderAsync()
          qGrid.addRow() // try again
          return
        }

        var newRec = {}

        var newRow = qGrid.el.quietAddRow(newRec)

        newRow.renderTo(rowParent.el)
        newRow.find('[data-field]').forEach(el => el.val())

        qGrid.el.refilter()
        setTimeout(() => {
          qGrid.page = qGrid.recs.length - 1
          qGrid.renderAsync()
          qGrid.el.focusCell(newRow.kids().find(c => !c.hasClass('non-editable-cell'))?.el)
        }, 50)
      },

      readData(rec, changedRowsOnly) {
        changedRowsOnly = changedRowsOnly || opts.saveOnlyChangedRows

        const f = opts.fieldName || 'data'

        if (changedRowsOnly) {
          const v = Object.keys(dc._changes)
            .filter(srowi => {
              const rowi = parseInt(srowi)
              const rec = dc.recs[rowi]
              return qGrid.recs.indexOf(rec) > -1 || ($meta(rec).deleted && !$meta(rec).new) // either in filteredRows OR has been deleted
            })
            .map(srowi => {
              const rowi = parseInt(srowi)
              var r = JSON.parse(JSON.stringify(dc.recs[rowi]))
              delete $meta(r)
              return r
            })

          _v(rec, f, v)
          return rec
        }

        _v(
          rec,
          f,
          qGrid.recs
            .filter(r => !$meta(r).deleted && !isNewBlank(r) && !r._group)
            .map(r => {
              var r1 = Object.assign({}, r)
              $meta(r1, {})
              return r1
            })
        )
        return rec
      }
    })
    .props({ opts, id: iden, rowHeight: 26 })

  qGrid.addClass(qGrid.opts.editable === false ? 'non-editable' : 'editable')

  if (opts.editable === false) qGrid.attr({ tabindex: '0' })

  let rowParent, qFooter, qBody, qHeader

  let dc = (qGrid.dc = opts.dc)
  if (opts.paging) dc.paging = opts.paging

  dc.sort = opts.sorts ?? dc.sort ?? []

  opts.cols.forEach((col, coli) => {
    if (typeof col === 'string') {
      col = { field: col }
      opts.cols[coli] = col
    }
  })

  // button column
  const buttonCol = {
    command: [],
    title: '',
    attributes: { class: 'command-cell no-tab' },
    width: 24,
    template() {
      return this.command.map(cmd => {
        return qc('a.ow-btn.ow-grid-btn.ow-grid-btn-' + cmd.name, qc('i.fa', html(cmd.text)))
          .attr({
            role: 'button',
            'data-command': cmd.type,
            href: '#',
            tabindex: '-1'
          })
          .on('click', function () {
            const tr = this.closest('tr')
            const { rec } = qc(tr)
            qc(tr).trigger('row-' + cmd.type, $meta(rec)?.filterIndex, rec, tr)
          })
      })
    }
  }
  const { buttonColumn = [] } = opts
  buttonColumn.forEach(btn => {
    btn = typeof btn === 'string' ? { type: btn, imageClass: btn } : btn
    btn.text = btn.text || iconCodes[btn.type] || ''
    btn.name = btn.name || btn.type + '-row'
    btn.imageClass = btn.imageClass || btn.name
    btn.iconClass = btn.iconClass || ''
    buttonCol.command.push(btn)
    buttonCol.width = buttonCol.width + (btn.width || 14)
  })
  if (buttonCol.command.length) opts.cols.push(buttonCol)
  // end of button column

  const gridCols = opts.cols.filter(c => c.gridCol !== false)

  colsToGridColumns(opts.cols, qGrid)

  // set up reaction fields
  opts.reactFields = opts.reactFields || {}
  opts.cols.forEach(col => {
    var noTrack =
      col.trackChanges !== true && (col.trackChanges === false || col.calc || col.readOnly === true) // remember readOnly can be a function

    if (col.field) if (!(col.field in opts.reactFields)) opts.reactFields[col.field] = !noTrack
  })

  let totalColWidth = 200
  const calcColWidth = () => {
    totalColWidth = 0
    gridCols.forEach(col => (totalColWidth += col.hidden ? 0 : col.width || 0))
  }

  // sets table widths on resize
  const bindTableWidth = function () {
    this.css({ width: totalColWidth + 'px' })
  }

  const qTitleRow = qc(
    'tr.ow-titlerow',
    qc(gridCols.sort((a, b) => a.order - b.order).map(col => drawColHeader(qGrid, col)))
  )
  const qFilterRow = qc('tr.ow-filterrow')
  qFilterRow
    .kids(
      opts.hasColFilters !== false
        ? qc(
            gridCols
              .sort((a, b) => a.order - b.order)
              .map(col => drawColFilter(qGrid, col, qFilterRow))
          )
        : []
    ) // f2 on grid selects/edits the current row
    .on('keydown', e => {
      if (!e.target.classList.contains('ow-filter-control')) return
      // F2 or enter
      if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        setTimeout(() => qGrid.refilter(e.target.opts?.clientFilter), 10)
        return false
      }
    })

  qHeader = qc('div.ow-grid-header')

  let resize
  document.body.addEventListener(
    'mousemove',
    e => (e.buttons === 0 ? (resize = undefined) : resize?.(e)),
    true
  )
  document.body.addEventListener(
    'mouseup',
    () => {
      resize = undefined
      qc(document.body).renderAsync()
    },
    true
  )

  qGrid.resizeColHandleMouseDown = (e, cell) => {
    var m_pos = e.pageX
    var origWidth = qc(cell).col.width
    resize = ({ pageX }) => {
      const newWidth = origWidth + pageX - m_pos
      let w = newWidth < 5 ? 5 : newWidth
      w = parseFloat(w)
      qc(cell).col.width = w
      qc(cell).col.setting.width = w
      calcColWidth()
      qGrid.renderAsync()
      return false
    }
    return false
  }
  qHeader.on('mousemove', e => resize?.(e)).on('mouseup', () => resize && (resize = null))

  qHeader.kids([
    styles('#' + opts.view.qTop.el.id + ' .iden-' + opts.iden + '.grid7'),

    qc(
      'span.ow-grid-top-right',
      qc('i.fa', html('')).css({
        fontSize: '1rem',
        // color: theme.iconBlue,
        overflow: 'visible',
        position: 'absolute',
        right: '0',
        paddingRight: '4px',
        top: 0,
        bottom: 0,
        paddingTop: '4px',
        backgroundColor: 'inherit',
        cursor: 'pointer'
      })
    )
      .bindState(function () {
        this.css({
          height: qHeader.el?.offsetHeight ?? '0' + 'px',
          width: (qHeader.el.offsetWidth ?? 0) - (qHeader.el?.children[0]?.offsetWidth ?? 0) + 'px'
        })
      })
      .on('click', function () {
        qGrid.toggleClass('ow-hide-filterrow')
        recalcBodyHeight()
        qGrid.renderAsync() // resize
      }),

    qc(
      'div.ow-grid-header-wrap.ow-auto-scrollable',
      qc('table', qc('thead', [qTitleRow, qFilterRow])).bindState(bindTableWidth)
    ).attr({ 'data-role': 'resizable' })
  ])

  if (opts.allowColumnMenu !== false)
    qHeader.on('contextmenu', e =>
      cmenu7(e.target, {
        view: opts.view,
        point: { left: e.pageX, top: e.pageY },
        setWidth: false,
        content() {
          return qc('ul', [
            gridCols
              .filter(col => col.field)
              .map(col =>
                qc('li', [
                  check7({
                    label: col.title,
                    labelRight: true,
                    fieldName: 'col-' + col.coli
                  })
                    .addClass(col.isMandatory ? 'ow-disabled' : '')
                    .bindState(
                      () => col.hidden,
                      function (hidden) {
                        this.val(!hidden)
                      }
                    )
                    .on('ow-change', function () {
                      col.hidden = this.val() ? 0 : 1
                      calcColWidth()
                      qGrid.renderAsync()
                      if (col.hidden) col.setting.hidden = col.hidden
                      else delete col.setting.hidden
                    })
                    .wrap()
                  // qc('span', col.title)
                ])
              ),
            ...(opts.allowSaveTemplate
              ? [
                  qc(
                    'li',
                    qc('a.menu-item', __('Set Default Template'))
                      .attr({ href: '#' })
                      .on('click', () => qGrid.trigger('command-defaulttemplate'))
                  )
                ]
              : [])
          ])
        }
      })
    )

  let qAddRowOnFocus = qc('input.add-row-on-focus').on('focus', () => {
    if (!opts.tabOutNewRow) return
    var tr = qGrid.el.currentCell?.parentElement
    if (tr && isNewBlank(qc(tr).rec))
      return setTimeout(() => tr.closest('table') && qGrid.el.leaveRow(tr), 50) // this should be cancelled but check later in case

    qGrid.addRow()
  })

  let firstRowIndex = 0,
    fullHeight = 1000,
    useVirtualScroll,
    tableTop = 0

  const renderRows = function () {
    const fRecs = qGrid.recs

    const pageSize = Math.ceil(qBody.el.clientHeight / qGrid.rowHeight) + 6
    let lastRowIndex = useVirtualScroll
      ? Math.min(firstRowIndex + pageSize, fRecs.length - 1)
      : fRecs.length - 1

    const tableKids = []

    for (let fi = firstRowIndex; fi <= lastRowIndex; fi++) {
      const rec = fRecs[fi]
      const tr = qGrid.getTr(rec).props({ fi })
      tableKids.push(tr.css({ height: qGrid.rowHeight + 'px' }))
    }

    let activeRow = document.activeElement?.closest('tr.row')
    if (activeRow && activeRow.parentElement === rowParent.el) {
      let hideActive = true

      let activeRowfi = fRecs.indexOf(qc(activeRow))
      if (activeRowfi > lastRowIndex) tableKids.push(qc(activeRow))
      else if (activeRowfi < firstRowIndex) tableKids.unshift(qc(activeRow))
      else hideActive = false

      qc(activeRow).css({
        position: hideActive ? 'absolute' : undefined,
        top: hideActive ? '-' + tableTop + 'px' : undefined
      })
    }
    rowParent.kids(tableKids) // let the cmp renderer do the work
  }

  qBody = qc('div.ow-grid-content.ow-auto-scrollable', [
    qc('div.ow-grid-content-wrap.ow-auto-scrollable', [
      qc('table', (rowParent = qc('tbody')))
        .css({ position: 'absolute' })
        .bindState(bindTableWidth)
        .bindState(
          () => tableTop + 'px',
          function (top) {
            this.css({ top })
          }
        ),
      qAddRowOnFocus
    ])
      .css({ boxSizing: 'border-box' })
      .bindState(function () {
        useVirtualScroll = (qGrid.recs.length ?? 0) > 1000
        fullHeight = (1 + qGrid.recs.length ?? 0) * qGrid.rowHeight

        // set table top position in the scrollable fullHeight
        tableTop = Math.max(0, qBody.el.scrollTop - 3 * qGrid.rowHeight)

        this.css({ height: fullHeight + 'px' })
        firstRowIndex = Math.max(0, Math.ceil(qBody.el.scrollTop / qGrid.rowHeight) - 3)
        if (!useVirtualScroll) {
          this.css({ top: 0 })
          firstRowIndex = 0
          tableTop = 0
        }
        // renderRows()
      })
      .bindState(() => firstRowIndex, renderRows)
      .bindState(() => qGrid.recs, renderRows)
  ]).on('scroll', () => qHeader.renderAsync() && qFooter.renderAsync())

  qFooter = qc(
    'div.ow-grid-footer.ow-grid-footer',
    qc(
      'div.ow-grid-footer-wrap.ow-state-border-down',
      qc(
        'table',
        qc(
          'tbody',
          qc(
            'tr.ow-footer-template.ow-footer-template',
            gridCols.sort((a, b) => a.order - b.order).map(col => drawColFooter(qGrid, col))
          )
        )
      ).bindState(bindTableWidth)
    )
  )
    .attr({ tabindex: '-1' })
    .css({
      borderTop: theme.borderWidth + ' solid ' + theme.textboxBorderColor,
      display: opts.hasFooter !== true ? 'none' : undefined
    })

  // binds header and footer marginLeft to the body scroll
  function bindMarginLeft() {
    if (qBody.el)
      this.css({ marginLeft: qBody.el.scrollLeft ? '-' + qBody.el.scrollLeft + 'px' : '0' })
  }

  if (dc.paging && opts.userSettings?.pageSize) {
    if (dc.paging === true) dc.paging = {}
    dc.paging.pageSize = opts.userSettings?.pageSize
  }

  qGrid.kids([
    qHeader.props({ bindMarginLeft }).bindState(bindMarginLeft),
    qBody,
    qFooter.props({ bindMarginLeft }).bindState(bindMarginLeft),
    gridPager7(opts.view, dc.paging, () => dc?.load())
  ])

  dataBind(qGrid)

  const progress = (...args) => opts.view.progress(...args)
  qGrid.progress = progress

  const recalcBodyHeight = function () {
    let newHeight = qGrid.el?.clientHeight ?? 100,
      otherElementsHeight = 0
    qGrid.kids().forEach(q => q.el !== qBody.el && (otherElementsHeight += $offset(q.el).height))

    const height = newHeight - otherElementsHeight + 'px'
    if (height !== qBody.el?.style.height) qBody.css({ height })
  }

  const setCurrentRow = trNew => {
    let hasChanged = currentRow !== trNew

    if (trNew?.rec._group) return // is it focusable
    if (hasChanged) {
      currentRow?.removeClass('ow-current-row')
      currentRow = trNew
      trNew?.addClass('ow-current-row')
      trNew ? trNew.el?.focus() : qGrid.el.focus()

      qGrid.renderAsync()
    }
  }

  qGrid
    .addClass('ow-grid')
    .props({
      opts,
      recs: [],

      wrap() {
        return qGrid
      },

      current(cell) {
        const g = qGrid.el
        if (arguments.length === 0) return g.currentCell
        if (!no$(cell.el).is('td')) return g.currentCell

        // No group header or footers
        if (no$(cell.el.parentElement).hasClass('row--1')) return g.currentCell

        var leavingRow = g.currentCell?.parentElement !== cell.el?.parentElement

        if (leavingRow) {
          leavingRow = g.currentCell.parentElement
          if (leavingRow) g.leaveRow(leavingRow)
        }

        if (g.currentCell) {
          no$(g.currentCell.parentElement).removeClass('ow-current-row')
          no$(g.currentCell).removeClass('ow-current-cell')
        }
        g.currentCell = cell.el
        no$(g.currentCell).addClass('ow-current-cell')
        no$(g.currentCell.parentElement).addClass('ow-current-row')

        return cell
      },

      fillout() {},

      val(recs = []) {
        if (arguments.length === 0) return dc.recs

        qGrid.recs = recs
        const t = rowParent.el

        rowParent.kids([])

        // append the first row to find the rowHeight
        let firstRow
        if (t && recs[0]) {
          firstRow = qGrid.getTr(recs[0])
          firstRow.el ? t.append(firstRow.el) : firstRow.renderTo(t)
          qGrid.renderAsync()

          if (firstRow.el.offsetHeight && firstRow.el.offsetHeight !== qGrid.rowHeight)
            console.warn('RowHeight issue found', qGrid.rowHeight, firstRow.el.offsetHeight)

          firstRow.el.remove()
        }
        qAddRowOnFocus.css({ height: qGrid.rowHeight + 'px' })

        if (opts.groupBy && opts.expandAllGroup === false) {
          recs.forEach(rec => rec._group && (rec._group.open = false))
          dc.applyGroupVisibility()
        }

        qGrid.renderAsync().then(() => {
          const tr = qGrid.find('tr.ow-selectable')[0]
          tr ? setCurrentRow(qc(tr)) : setCurrentRow()
          qGrid.renderAsync()
        })

        return dc.recs
      }
    })
    .bindState(recalcBodyHeight)
    .bindState(calcColWidth)
    .on('init', (e, g) => {
      g.opts = opts
      g.currentCell = no$(g).find('.ow-current-cell')[0]

      g.footer = qFooter.el

      commandsOnGrid(g)

      g.ctlTypeClass = 'grid7'

      if (opts.resizable !== false) qGrid.addClass('resizable-columns')

      if (opts.hasColFilters === undefined && opts.hasFilters === false) opts.hasColFilters = false

      g.groupMembers = group => groupMembers(g, group)
      g.groupTogglerHTML = rec =>
        qc('i.icon.fa.group-toggler')
          .css({ display: 'inline-block', color: '#666', padding: '0 6px', width: '9px' })
          .on('click', () => toggleGroup(qGrid, rec))
          .bindState(
            () => rec._group.open,
            function (v) {
              this.kids(html(!v ? iconCodes['plus-square'] : iconCodes['minus-square']))
            }
          )

      g.expandAllGroups = () => expandAllGroups(g)
      g.collapseAllGroups = () => collapseAllGroups(g)

      if (opts.hasColFilters !== false) no$(g).addClass('ow-has-filterrow')

      dc.populate = function (recs) {
        dc._numChanges = 0
        dc._changes = {}
        qGrid.val(recs)
      }

      opts.editable = opts.editable !== false

      if (opts.allowNewRow === undefined) opts.allowNewRow = opts.editable
      if (opts.tabOutNewRow === undefined) opts.tabOutNewRow = opts.allowNewRow !== false
      if (opts.markChanges !== false) no$(g).addClass('ow-mark-changes')

      g.getUserSettings = grids => {
        if (opts.userSettings) {
          // hasChanged = true
          opts.userSettings.cols = opts.cols.reduce((r, col) => {
            if (Object.keys(col.setting).length) r[col.name] = col.setting
            return r
          }, {})

          if (qGrid.hasClass('ow-hide-filterrow')) delete opts.userSettings.filterToggle
          else opts.userSettings.filterToggle = true

          if (dc.paging?.pageSize) opts.userSettings.pageSize = dc.paging.pageSize

          grids[opts.iden] = opts.userSettings
        }
      }

      qGrid.recs = []
      g.state = { populating: 0 }

      g.update = () => {}

      g.resizeGrid = function () {
        qGrid.renderAsync()
      }

      const drawCell = (col, rec, tr) => {
        if (col.gridCol === false) return ''

        const me = qc('td.gridcell.coli-' + col.coli)
          .props({ col, coli: col.coli, rec })
          .on('click', () => me.hasClass('non-editable-cell') && qGrid.current(me))
          .on('focusin', () => qGrid.current(me))

        bindColWidth(me)

        if (col.field === 'i') return me.kids('' + tr.rowi) // For testing

        let ro =
          col.readOnly === true ||
          (typeof col.readOnly === 'function' && col.readOnly(rec) === true)
        if (ro) me.addClass('non-editable-cell no-tab')

        let classes = (typeof col.classes === 'function' ? col.classes(rec) : col.classes) || ''

        classes && me.addClass(classes)
        col.attributes?.class && me.addClass(col.attributes.class)
        col.type && me.addClass(col.type + '-col')

        let content
        if (rec._group) {
          if (!rec._group.footer && col.groupHeaderTemplate) content = col.groupHeaderTemplate(rec)
          if (rec._group.footer && col.groupFooterTemplate) content = col.groupFooterTemplate(rec)
          me.addClass('nongroupcell')
        } else {
          content = col.kColTemplate(rec)
          if (content && ro && col.field) content.attr({ 'data-field': col.field })
        }

        me.kids(typeof content === 'string' ? html(content || '&nbsp;') : content)

        if (col.colspan > 1) me.attr({ colspan: '' + col.colspan })

        col.preRender?.(me)
        return me
      }

      const drawRow = rec => {
        // set filterIndex
        const fi = qGrid.recs.indexOf(rec)
        const rowi = dc.recs.indexOf(rec)

        let tr = qc('tr.row')
          .props({ rowi, fi, rec })
          .addClass('row-' + rowi)

        if (rowi % 2) tr.addClass('ow-alt')
        if (rec._group) {
          tr.addClass(rec._group.footer ? 'ow-group-footer' : 'ow-group-header')
          tr.addClass(rec._group.footer ? 'ow-group-footer' : 'ow-group-header')
        }

        const cells = gridCols.sort((a, b) => a.order - b.order).map(col => drawCell(col, rec, tr))

        let selectedRows = []

        const clearSelection = () => {
          selectedRows.forEach(tr => {
            tr.removeClass('ow-selected')
            delete rec.rec.selected
          })
          selectedRows = []
        }

        const toggleSelect = tr => {
          tr.rec.selected = !tr.rec.selected
          tr.selected ? tr.addClass('ow-selected') : tr.removeClass('ow-selected')
        }

        if (opts.editable === false && !rec._group)
          tr.attr({ tabindex: '0' })
            .addClass('ow-selectable')
            .on('click', e => {
              if (opts.selectable && e.ctrlKey) toggleSelect(tr)
              tr.el.focus()
            })
            .on('dblclick', () =>
              qGrid.trigger('command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit'))
            )
            .on(
              'keyup',
              e =>
                e.which === 13 &&
                qGrid.trigger(
                  'command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit')
                )
            )
            .on('focus', () => setCurrentRow(tr))

        return tr
          .kids(cells.map(x => (typeof x === 'string' ? html(x) : x)))
          .on('keydown', e => {
            if (opts.editable === false) return

            if (e.which === 38 || e.which === 40) {
              if (e.target === tr.el) {
                const navRecs = qGrid.recs.filter(x => !x._group)

                let fi = navRecs.indexOf(tr.rec)
                if (fi === -1) console.warn('tr.rec not found in navigatable recs')

                const gotoRec = navRecs[fi + (e.which === 38 ? -1 : 1)]
                if (gotoRec) {
                  const gotoTr = qGrid.getTr(gotoRec)
                  gotoTr?.el?.focus()
                  setCurrentRow(gotoTr)
                }
                return killEvent(e, true)
              }
              return killEvent(e)
            }
          })
          .on('keydown keyup keypress', function (e) {
            if (e.which === 33 || e.which === 34) {
              if (e.type === 'keyup') e.which === 33 ? g.pageUp() : g.pageDown()
              return killEvent(e, false)
            }
          })
          .on('keydown', e => {
            // F2
            if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
              if (opts.editable === false) {
                tr.trigger('command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit'))
                return killEvent(e)
              }
              if (opts.live) {
                // first apply current control editing
                qc(e.target).trigger('ow-change')
                setTimeout(() => g.saveRow(tr.el), 50)
                return killEvent(e)
              }
              // else allow to bubble up
            }
          })
          .on('keydown', e => {
            if (e.which === 9) {
              if (no$(e.target).is('tr'))
                qAddRowOnFocus.attr('tabindex', isNewBlank(qc(e.target).rec) ? '-1' : '0')
            }
          })
      }

      g.select = function (tr) {
        return qGrid.select(tr)
      }

      g.leaveRow = function (tr) {
        if (!tr) return

        var el = document.activeElement
        if (tr === el.closest('tr')) {
          // if focus is still in previous row, clean up.
          qc(el).trigger('ow-change')
          if (document.activeElement.dropdownOpen) document.activeElement.$dropdown.close()
        }
        var data = qc(tr).rec

        if (!data) return // if it has reloaded or something

        if (dc.recs.indexOf(data) === -1) return

        if (data._group) return // don't do saving on group headers/footers

        if (isNewBlank(data) && opts.editable && opts.tabOutNewRow !== false) {
          if ($meta(data).deleted) return
          g.cancelChanges(tr)
          qc(g).trigger('ow-grid-change', data, tr)
          if (opts.groupBy) g.refilter()
        }
        // allow changes to propagate before validating and saving etc.
        else
          setTimeout(() => {
            if (data) {
              if (opts.live) g.saveRow(tr)
              else qGrid.validateRow(tr)
              if (opts.groupBy) g.refilter()
            }
          }, 500)
      }

      g.currentRow = function () {
        console.warn('DEPRECATED: please use qGrid.currentRow() not qGrid.el.currentRow() ')
        return currentRow
      }

      qGrid.currentRow = () => currentRow

      if (opts.indicateCurrentRow !== false) qGrid.addClass('indicate-current-row')
      if (opts.indicateCurrentCell === true) qGrid.addClass('indicate-current-cell')

      // if (opts.hasFilters !== false)
      hasFilterControls(qGrid)

      g.undeleteRow = function (tr) {
        if (!opts.showDeletedRows) return console.warn('this should never happen.')

        tr.removeClass('ow-deleted-row')
        var rec = qc(tr).rec

        delete $meta(rec).deleted
        delete rec.Deleted
        g.rowReact(tr)

        // debouncedFooterRefresh()

        qc(g).trigger('ow-grid-change', rec, tr, 'undelete')
      }

      // forget means that this rec changes are removed from changeTracking.
      g.removeRowFromRecs = function (tr) {
        no$(tr).find('.ow-current-cell')[0]?.classList.remove('ow-current-cell')
        g.currentCell = no$(g).find('.ow-current-cell')[0]

        // var rowi = qc(tr).rowi
        var rec = qc(tr).rec
        var hadFocus = document.activeElement?.closest('tr') === tr
        var scrollTop = qBody.el.scrollTop

        const fi = qGrid.recs.indexOf(rec)

        tr.el.remove()
        qGrid.recs.splice(fi, 1)
        // reIndexFilters
        qGrid.recs.forEach((rec, fi) => $meta(rec)?.filterIndex === fi)
        g.updateRowChangeTracking?.(rec)

        if (hadFocus) {
          qBody.el.scrollTop = scrollTop
          setTimeout(() => {
            let nextRow = qGrid.getTr(qGrid.recs[fi] ?? qGrid.recs[fi - 1] ?? qGrid.recs[0])
            g.focusCell(nextRow.el?.children[0])
          }, 200)
        }
      }

      g.deleteRow = function (tr) {
        var rec = qc(tr).rec

        rec.Deleted = true
        $meta(rec).deleted = true
        if ($meta(rec).new) $meta(rec).changes = {}

        qc(tr).addClass('ow-deleted-row')

        if (!opts.showDeletedRows || $meta(rec)?.new) g.removeRowFromRecs(tr)
        else g.rowReact(tr)

        if (opts.live) g.saveRow(tr)

        if (opts.groupBy) g.refilter()

        //debouncedFooterRefresh()
        qGrid.trigger('ow-grid-change', rec, tr, 'delete')
      }
      qGrid.deleteRow = g.deleteRow

      // don't worry about the scrolling etc (for doing batch adds etc)
      // After doing this call g.swapPage and set focus...?
      g.quietAddRow = function (rec) {
        if (!rec) rec = {}

        // apply defaults if nothing is set already.filterMap
        opts.cols.forEach(function (col) {
          if ('defaultValue' in col && col.field && _v(rec, col.field) === undefined) {
            _v(
              rec,
              col.field,
              typeof col.defaultValue === 'function' ? col.defaultValue() : col.defaultValue
            )
          }
        })

        dc.recs.push(rec)
        var rowi = dc.recs.length - 1
        dc.initMeta(rec, rowi)
        $meta(rec).new = true
        $meta(rec).filterIndex = qGrid.recs.length
        qGrid.recs.push(rec)

        var qNewRow = qGrid.getTr(rec)
        g.updateRowChangeTracking?.(rec)

        return qNewRow
      }

      g.addRecords = function (recs = []) {
        recs.map(r => g.quietAddRow(r))
        qGrid.renderAsync()
      }

      // updates the _meta with any changes, or just for field if provided
      g.rowReact = function (tr) {
        if (!tr) return
        tr = qc(tr)
        recordReact(tr.rec, (rec, f) => g.onRowChange(tr, rec, f), opts.view)
        g.updateRowChangeTracking(tr.rec) // do this incase of delete
      }

      // rec is optional
      g.fieldReact = function (tr, f, rec) {
        rec = rec || qc(tr).rec
        var result = fieldReact(
          rec,
          f,
          // opts.reactFields,
          (rec, f) => g.onRowChange(tr, rec, f),
          opts.view
        )
        g.updateRowChangeTracking(rec)

        return result
      }

      if (opts.editable) {
        g.cancelChanges = function (tr) {
          if (tr) {
            tr = qc(tr)
            if (!$meta(tr.rec)) throw 'No _meta'
            dc.cancelRowChanges($meta(tr.rec).rowi)
            $meta(dc.recs[$meta(tr.rec).rowi]).rowi = $meta(tr.rec).rowi
            g.rowReact(tr)
            qGrid.trigger('ow-grid-change', tr.rec, tr, 'cancel')
          } else dc.cancelChanges()
          g.refilter()
        }

        g.saveRow = function (tr) {
          var dc = opts.dc || opts.view.qTop.el

          var rowi = qc(tr).rowi
          var rec = qc(tr).rec

          if (!qGrid.validateRow(tr).resVal) return

          var req = {
            url: dc.opts.baseURL.split('?')[0],
            data: { data: [rec] }
          }

          const success = response => {
            ow.popSaveOK(response)
            qc(tr).trigger('ow-data-saved', response)

            if ($meta(rec).deleted) g.removeRowFromRecs(tr)

            // todo: if we have values or new ID, apply it.
            // If it was deleted, remove from the list.
            delete rec.isnew
            $meta(rec, {})
            dc.initMeta(dc.recs[rowi], rowi)
            tr.removeClass('ow-dirty')
            tr.find('.ow-dirty').removeClass('ow-dirty')
            g.updateRowChangeTracking(dc.recs[rowi])
            g.refreshRow(tr)
            qc(tr).renderAsync()
          }

          const fail = err => ow.popSaveError(err)

          if (rec) {
            if ($meta(rec).new && $meta(rec).deleted) return

            if ($meta(rec).deleted) {
              req.type = 'DELETE'
              req.url = req.url + '/delete'
              $ajax(req).then(success).catch(fail)
              return
            }

            if ($meta(rec).new) {
              req.type = 'POST'
              $ajax(req).then(success).catch(fail)
              return
            }

            if (Object.keys($meta(rec).changes).length) {
              req.type = 'PUT'
              req.url = req.url + '/update'
              // + (!isNew ? '/' + g.idString(result) : '')
              $ajax(req).then(success).catch(fail)
              return
            }
          }
        }

        g.saveRows = function () {
          var dc = opts.dc || g.closest('.win-con')[0]
          var data = {}
          qGrid.readData(data, true)

          return $ajax({
            type: 'PUT',
            url: dc.opts.baseURL.split('?')[0] + '/update',
            data
          })
            .then(response => {
              ow.popSaveOK(response)
              g.refresh(true)
            })
            .catch(ow.popSaveError)
        }

        g.saveChanges = function () {
          if (opts.batch) {
            if (g.validate().resVal) g.saveRows()
            return
          }

          var changedRows = Object.keys(dc._changes).map(srowi =>
            qGrid.getTr(dc.recs[parseInt(srowi)])
          )

          changedRows.forEach(tr => g.saveRow(tr))
        }

        // This is for overriding.
        g.onRowChange = (tr, rec, f) => g.onRowChangeDefault(tr, rec, f)

        g.updateRowChangeTracking = function (rec) {
          if ($meta(rec).updateRowChangeTimeout) return

          $meta(rec).updateRowChangeTimeout = setTimeout(() => {
            delete $meta(rec).updateRowChangeTimeout

            var rowi = $meta(rec).rowi

            var rowHasChanges = Object.keys($meta(rec).changes).length > 0
            rowHasChanges = rowHasChanges || ($meta(rec).deleted && !$meta(rec).new)
            rowHasChanges = rowHasChanges || (!$meta(rec).deleted && $meta(rec).new)

            if (!rowHasChanges) {
              qGrid.getTr(rec).removeClass('ow-dirty')
              if (dc._changes[rowi]) {
                delete dc._changes[rowi]
                dc._numChanges--
                qc(g).trigger('change', rec)
              }
            } else {
              qGrid.getTr(rec).addClass('ow-dirty')
              if (!dc._changes[rowi]) {
                dc._changes[rowi] = 1
                dc._numChanges++
                qc(g).trigger('change', rec)
              }
            }

            dc.saveCancelState()
          }, 100)
        }

        g.onRowChangeDefault = function (tr, rec, f) {
          // g.refreshField(rec, f)
          qc(g).trigger('ow-grid-change', rec, tr, 'field', f)

          var v = _v(rec, f)

          qc(tr).trigger('ow-field-changed', f, v, rec)
          setTimeout(() => g.rowReact(tr), 1)
        }

        qGrid.on('row-new', () => {
          qGrid.addRow()
          return false
        })

        qGrid.validateRow = function (tr, onInvalid) {
          no$(tr)
            .find('.ow-input-errbg')
            .forEach(x => qc(x).removeClass('ow-input-errbg'))

          var result = { resVal: 1, uid: [], errMsg: '' }

          var localMessageArray = []

          onInvalid =
            onInvalid ||
            function (title, msg, el) {
              localMessageArray.push(title + ': ' + msg)
              el && tr !== el && el.displayValidity(false, msg)
              qc(tr).addClass('ow-row-invalid')
              setTimeout(() => qc(tr).removeClass('ow-row-invalid'), 1000)
            }

          var rec = qc(tr).rec

          // ignore new blank rows
          if (isNewBlank(rec)) return result

          opts.cols.forEach(col => {
            if (!result) return false
            if ($meta(rec).deleted) return

            if (col.validation?.validateFunction) {
              var rv = col.validation.validateFunction.call(col, rec)
              if (rv && typeof rv === 'string') {
                rv = {
                  resVal: 0,
                  errMsg: rv,
                  uid: [col.coli]
                }
              }
              if (rv && rv.resVal === 0) {
                result.resVal = 0
                result.errMsg = rv.errMsg
                result.uid = result.uid.concat(rv.uid)
                onInvalid(
                  col.title,
                  result.errMsg,
                  no$(tr).find('[data-field=' + col.field + ']')[0] || tr
                )
              }
            }

            const objectValidation = validation => {
              for (var rule in validation) {
                if (rule === 'validateFunction') continue
                var v = validation[rule]
                var displayValue = v // if it's a column name

                v = typeof v === 'function' ? v(rec, col) : v

                if (v === undefined) {
                  // if the rule refers to another field by Name
                  continue
                }

                var val = _v(rec, col.field)

                if (typeof v === 'string') {
                  displayValue =
                    (opts.cols.filter(c => c.field === v)[0] || {}).title + ', ' + rec[v]
                  v = rec[v]
                  if (col.type === 'time') {
                    if (val && val.toJSON) val = val.toJSON().split(' ')[1]
                    if (v && v.toJSON) v = v.toJSON().split(' ')[1]
                  }
                }

                var err = ''

                if (!val && val !== 0) {
                  if (rule === 'required' && v) err = ' ' + __('requires a value')
                } else {
                  // if (typeof val !== 'undefined' && val !== null && val !== '') {
                  if (rule === 'eq' && !(val === v)) {
                    err = ' ' + __('should be equal to') + ' ' + displayValue
                  } else if (rule === 'neq' && !(val !== v)) {
                    err = ' ' + __('should not be equal to') + ' ' + displayValue
                  } else if (rule === 'gt' && !(val > v)) {
                    err = ' ' + __('should be greater than') + ' ' + displayValue
                  } else if (rule === 'gte' && !(val >= v)) {
                    err = ' ' + __('should be greater than or equal to') + ' ' + displayValue
                  } else if (rule === 'lt' && !(val < v)) {
                    err = ' ' + __('should be less than') + ' ' + displayValue
                  } else if (rule === 'lte' && !(val <= v)) {
                    err = ' ' + __('should be less than or equal to') + ' ' + displayValue
                  }
                }

                if (err !== '') {
                  result.resVal = 0
                  result.errMsg = err
                  result.uid.push(col.coli)
                  onInvalid(
                    col.title,
                    result.errMsg,
                    no$(tr).find('[data-field=' + col.field + ']')[0] || tr
                  )
                }
              }
            }

            if (typeof col.validation === 'object') objectValidation(col.validation)
          })

          if (localMessageArray.length) ow.popInvalid('Invalid', localMessageArray)

          return result
        }

        g.validate =
          g.validate ||
          function (onInvalid, messageArray) {
            var result = true
            Object.keys(dc._changes).forEach(srowi => {
              var rowi = parseInt(srowi)
              var rec = dc.recs[rowi]
              var tr = qGrid.getTr(rec)
              result = result && qGrid.validateRow(tr, onInvalid, messageArray)
            })
            return result
          }
      }

      g.pageUp = function () {
        var h = Math.floor(qBody.el.clientHeight / qGrid.rowHeight) * qGrid.rowHeight
        qBody.el.scrollTop = qBody.el.scrollTop - h
      }

      g.pageDown = function () {
        var h = Math.floor(qBody.el.clientHeight / qGrid.rowHeight) * qGrid.rowHeight
        qBody.el.scrollTop(qBody.el.scrollTop + h)
      }

      qGrid.getColForCell = td => qc(td).col
      g.getDataForRow = tr => qc(tr).rec // for ow5 backwards compat
      // backwards compat
      g.swapPage = (swapFn = () => {}) => qBody.renderAsync(swapFn)

      qGrid.rowMap = new WeakMap()

      /**
       *
       * @param {object|integer} recOrRowi model or fi
       * @returns qc(tr)
       */
      g.getTr = rec => {
        if (typeof recOrRowi === 'object') throw 'getTr takes record object'
        let tr
        if (!qGrid.rowMap.has(rec)) {
          tr = drawRow(rec)
          qGrid.rowMap.set(rec, tr)
        } else {
          tr = qGrid.rowMap.get(rec)
          applyOrderToRow(tr.el)
        }
        return tr
      }
      qGrid.getTr = g.getTr

      g.focusCell = function (td) {
        if (td) td = no$(td).not('.non-editable-cell')[0] ?? td.children[0]
        if (document.activeElement?.closest('td') === td) return

        const q = 'input, a.ow-check'

        let x = no$(td).find(q)
        if (x.length === 0) {
          let span = no$(td).find('span')[0]
          if (span) {
            qc(span).attr('tabindex', 1) // will be removed
            span.focus() // focus cell because no control
            qc(span).attr('tabindex', -1) // removed
          }
        } else {
          let ci = _v(g, 'state.lastCellControlIndex')
          ci = Math.min(x.length - 1, ci || 0)
          x[ci].focus()
        }
      }

      g.moveNextCell = function (back) {
        const currCell = qGrid.current()
        if (!currCell) return

        const allcells = $find(qBody.el, 'td.gridcell').filter(
          td => !td.el.classList.contains('no-tab')
        )

        const currIndex = allcells.indexOf(currCell)

        const goToCell = allcells[currIndex + (back ? -1 : 1)]
        if (goToCell.length) g.focusCell(goToCell)
        else qAddRowOnFocus.el.focus()
      }

      // this is called from window win_close for all .ow-grid.
      g.hasChanges = g.hasChanges || (() => dc._numChanges)

      g.refilter = function () {
        if (dc.recs === []) return

        progress()
        dc.refilter()
        progress(false)
        qGrid.renderAsync()

        if (qGrid.focusFirstRowOnPopulate) {
          qGrid.focusFirstRowOnPopulate = false
          setTimeout(() => {
            var firstTr = no$(qBody.el).find('tr')[0]
            var td = $find(firstTr, 'td.non-editable-cell, td')[0]
            td && g.focusCell(td)
          }, 10)
        }
      }

      g.refresh = () => dc.load() // todo - if we have a live grid we also need to manage refreshing from server...?  separate client and server filters?

      if (opts.editable) {
        g.onEdChange = function (el) {
          const f = el?.opts?.fieldName
          if (!f) return
          if (g.opts.reactFields[f] !== false) g.opts.reactFields[f] = true

          if (g.state.populating) return

          var td = el.closest('td')
          if (no$(td).hasClass('non-editable-cell')) return
          var tr = td.parentElement
          var rec = qc(tr).rec

          if (!rec) return // for events that fire on destroyed rows.
          if (rec._group) return

          var v = _v(rec, f)
          var prev = f in $meta(rec).prev ? $meta(rec).prev[f] : _v($meta(rec).orig, f)

          _v(rec, f, el.val())
          v = _v(rec, f)

          if (v === prev) return // Added 2020-05-10 - correct working but will it cause issues!

          g.fieldReact(tr, f, rec)

          if (Object.keys($meta(rec).changes).length) qc(td).addClass('ow-dirty')
          else qc(td).removeClass('ow-dirty')

          var col = qc(td).col
          if (col?.onEdit) {
            var p = {}
            _v(p, f, prev)
            col.onEdit(p, rec, td, f)
          }
        }
        qBody
          .on('click', function (e) {
            if (e.target !== this && e.target.parentElement !== this) return
            if (e.target.closest('table')) return

            const newRowAllowed = qGrid.newRowAllowed()
            var lastRec = qGrid.recs[qGrid.recs.length - 1]

            var focusLast = (lastRec && isNewBlank(lastRec)) || !newRowAllowed

            if (focusLast && lastRec) {
              var tr = qGrid.getTr(lastRec)
              return g.focusCell(no$(tr.el).find('td')[0]) // this will be cancelled.
            }
            if (newRowAllowed) return qGrid.addRow()

            // if you reach here, it's because you aren't allowed to addNewRows and grid is empty (can be client-side filters)
          })
          .on('change ow-change', function (e) {
            if (!no$(e.target).is('tr td [data-field]')) return
            return g.onEdChange(e.target)
          })
      }

      // Can we put some of these on the body and add in '.grid7 '
      // split these out
      qBody.on('scroll', () => {
        qHeader.bindMarginLeft()
        qFooter.bindMarginLeft()
        qBody.renderAsync()
      })

      // apply arrow up and down on grid.
      qGrid.on('keydown', e => {
        if (e.which === 38 || e.which === 40) {
          const tr = currentRow
          if (!tr) return
          const navRecs = qGrid.recs.filter(x => !x._group)

          const fi = navRecs.indexOf(tr.rec)
          if (fi === -1) throw 'tr.rec not found in navigatable recs'
          const gotoRec = navRecs[fi + (e.which === 38 ? -1 : 1)]
          if (gotoRec) {
            const gotoTr = qGrid.getTr(gotoRec)
            gotoTr?.el?.focus()
            setCurrentRow(gotoTr)
          }
          return killEvent(e, true)
        }
      })

      qGrid.val([])
    })

  if (!opts.userSettings?.filterOpen) qGrid.addClass('ow-hide-filterrow')

  return qGrid
}

const cellReadOnly = (col, d) =>
  typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly || false

const columnDataValue = (col, rec) => {
  const f = col.field
  var v = col.calc ? col.calc(rec) : f ? _v(rec, f) : undefined

  if (v && (col.type === 'date' || col.type === 'datetime') && typeof v === 'string') {
    // this happens after JSON.parse() and is still a string
    if (v.indexOf('T') + 1) {
      console.error('Unexpected condition, ISO string ', col.type, 'timezone may be wrong')
      v = new Date(v)
    } else if (
      v.split(':').length === 3 &&
      v.split('-').length === 3 &&
      v.split(' ').length === 2
    ) {
      v = new Date(v)
    } else {
      console.error('Unexpected condition, formatted date string ', col.type)
      v = parseDate(v, col.format)
    }
  }

  return v
}

/**
 * returns the formatted string value for a column.
 * @param {*} col
 * @param {*} d
 * @param {*} i
 * @param {*} readOnly
 * @param {boolean} ctlUse - if it's for in a control rather than HTML span content
 * @returns
 */
const columnValue = (col, d, i, readOnly, ctlUse) => {
  let v = columnDataValue(col, d)

  v = v === undefined && v === null && !ctlUse ? '' : col.format ? formatString(v, col.format) : v

  if (readOnly) {
    if (col.ctl?.objectFieldName && d[col.ctl.objectFieldName])
      return d[col.ctl.objectFieldName][col.ctl?.textField || 'Text'] || v

    if (v === null) return ctlUse ? '' : '&nbsp;'
    return (col.readOnlyText ? col.readOnlyText(d) : v) ?? (ctlUse ? '' : '&nbsp;')
  }

  return v
}

/**
 * adds the standard grid button command event handlers to a grid
 * commands are refresh, edit, copy, delete, new
 *
 */
const commandsOnGrid = function (g) {
  const qGrid = qc(g)

  const triggerCommandOnCurrentRow = cmd => {
    var tr = g.currentRow()
    var data = tr?.rec
    qGrid.trigger(cmd, tr ? no$(tr.el).index() : -1, data, tr)
  }

  qGrid
    .on('command-refresh', e => {
      g.refresh()
      return killEvent(e)
    })
    .on('command-edit', e => {
      triggerCommandOnCurrentRow('row-edit')
      return killEvent(e)
    })
    .on('command-select', e => {
      triggerCommandOnCurrentRow('row-select')
      return killEvent(e)
    })
    .on('command-copy', e => {
      triggerCommandOnCurrentRow('row-copy')
      return killEvent(e)
    })
    .on('command-delete', e => {
      triggerCommandOnCurrentRow('row-delete')
      return killEvent(e)
    })
    .on('command-new', e => {
      triggerCommandOnCurrentRow('row-new')
      return killEvent(e)
    })
    .on('command-add-row', e => {
      triggerCommandOnCurrentRow('row-new')
      return killEvent(e)
    })
    .on('command-save', e => {
      triggerCommandOnCurrentRow('row-save')
      return killEvent(e)
    })
    .on('command-cancel', e => {
      triggerCommandOnCurrentRow('row-cancel')
      return killEvent(e)
    })
}

const hasFilterControls = function (qGrid) {
  qGrid.on('ow-grid-databound', function () {
    if (qGrid.focusFirstRowOnPopulate) {
      qGrid.focusFirstRowOnPopulate = false
      var c = no$(qGrid.el).find('tbody tr:first td:first')[0]
      if (c) {
        qGrid.current(c)
        no$(qGrid.el).find('.ow-grid-content table')[0]?.focus()
        qGrid.el.resolveFocus()
      }
    }
  })

  var fireFilters = function (e) {
    if (qGrid.opts.focusFirstRowOnPopulate !== false) {
      qGrid.focusFirstRowOnPopulate = true
      var panel = e.target.closest('.filter-panel')
      panel.focus()
      var btn = no$(panel).find('.filter-button')[0]
      if (btn) btn.focus()
    }
    qc(e.target).trigger('change')
    setTimeout(
      e.target.opts?.clientFilter
        ? () => (qGrid.el.refilter ? qGrid.el.refilter() : qGrid.el.refresh())
        : () => (qGrid.el.dc && qGrid.el.dc.load ? qGrid.el.dc.load() : qGrid.el.refresh()),
      10
    )
  }

  if (qGrid.opts.hasFilters !== false)
    no$(qGrid.el.closest('.win-con'))
      .find('.filter-panel')[0]
      ?.on('keydown', function (e) {
        if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
          // F2
          fireFilters(e)
          // e.originalEvent.keyCode = 0;
          e.preventDefault()
          e.stopPropagation()
          return false
        }
      })

  qGrid.on('command-filter-change', () => setTimeout(() => qGrid.el.refresh(), 50))
}

const styles = (scope = '.grid7') =>
  html(
    `<style>
${scope} tr:focus-visible { outline: 0; }

${scope} .ow-grid-header .ow-filterrow { display: none; }
${scope}.ow-has-filterrow .ow-grid-header .ow-filterrow {display: table-row;}
${scope}.ow-hide-filterrow .ow-grid-header .ow-filterrow {display: none;}
${scope} .ow-grid-header .ow-grid-top-right i {display: none;}
${scope} .ow-grid-header .ow-grid-top-right {position: absolute;right: 0;top: 0;height: 3em;display: inline-block;width: 2em;background-color: inherit;z-index: 2;text-align: right;overflow: visible;}
${scope}.ow-has-filterrow .ow-grid-header .ow-grid-top-right i {display: inline-block; }` +
      // ${scope} .ow-filterrow .ow-ctl-wrap {
      //   margin-right: 1.6em;
      //   margin-left: 0.16em;
      //   position: relative;
      //   overflow: visible;
      // }
      // ${scope} .ow-filterrow .ow-ctl-wrap::after {
      //   content: "\\f0b0";
      //   font-family: "FontAwesome";
      //   font-size: 1em;
      //   cursor: pointer;
      //   color: #aaa;
      //   position: absolute;
      //   right: -1rem;
      // }
      // ${scope} .ow-filterrow,
      // ${scope} .ow-filterrow ${headerCellTag} { overflow: visible; overflow-x: hidden; }

      `
${scope} tr.colgroup ${headerCellTag} { line-height: 0; }
${scope} tr.ow-group-header,
${scope} tr.ow-group-footer {
  background-color: ${theme.grayBackgroundColor};
}
.tab_content > ${scope}.fit,
.tab_content > div > ${scope}.fit,
.tab_content > div.fit {
  margin: 1rem;
}
.grid-widget > ${scope} {
  margin-bottom: 1rem;
}

.tab_content div.grid-widget > ${scope} {
  margin-left: 0;
  margin-right: 0;
}

${scope} .ow-btn {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

${scope} .ow-grid-header { 
  border-bottom: ${theme.borderWidth} solid;
}

${scope} .ow-grid-header ${headerCellTag} {
  border-width: 0 ${theme.borderWidth} 0 0; 
  border-color: #dedee0;
  border-style: solid;
  overflow: hidden;
  white-space: normal;
  vertical-align: top;
  text-overflow: ellipsis;
}

${scope} .ow-grid-header .ow-filterrow {
  border-top: ${theme.borderWidth} solid #dedee0;
}

${scope} td {
  border-color: #dedee0;
  border-style: solid;
  border-width: 0 0 ${theme.borderWidth} ${theme.borderWidth};
  overflow: hidden;
  white-space: normal;
  vertical-align: top;
  text-overflow: ellipsis;
}
${scope} .ow-btn.ow-grid-btn {
  padding-right: 0;
}

${scope} td.ow-state-focused {
  -webkit-box-shadow: inset 0 0 0.15em 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0.15em 0 rgba(0, 0, 0, 0.25);
}

${scope} > div.ow-grid-footer,
${scope} > div.ow-grid-header {
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
  padding-right: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
${scope} div.ow-grid-header-wrap {
  border-right: 0;
}
${scope} .add-row-on-focus {
  height: 0;
  width: 0;
  border: 0;
}
${scope} .ow-titlerow,
${scope} tr.ow-filterrow {
  min-height: 1.5rem !important;
}

${scope} .ow-titlerow ${headerCellTag} {
  border-bottom-color: transparent;
}

${scope} table tr > :first-child {
  box-sizing: border-box;
  border-left-width: 0;
}
div${scope} {
  border: ${theme.borderWidth} solid #dedee0;
}
${scope} tr.row td.non-editable-cell {
  background-color: ${theme.grayBackgroundColor};
}

${scope} tr.row,
${scope} td,
${scope} tr.ow-filterrow ${headerCellTag} {
  padding: 0;
}
${scope} td.gridcell.non-editable-cell.ow-current-cell {
  border: ${theme.borderWidth} solid rgb(77, 144, 254);
}
${scope} td > span.read-only {
  padding: 0 4px;
}
${scope} .ow-filterrow input,
${scope} td input {
  width: 100%;
  border: 0px;
  height: 100%;
  max-width: 98%;
  padding: 0 4px 0 0;
  color: #555;
}

${scope} td span.ow-ctl-wrap.text-icon-after {
  width: 100%;
}
${scope} td span.ow-ctl-wrap.text-icon-after > input {
  width: calc(100% - 2rem);
}
${scope} td span.ow-ctl-wrap.text-icon-after.text-2icon-after > input {
  width: calc(100% - 3rem);
}

${scope} td.boolean-col {
  text-align: center;
}
${scope} table {
  margin: 0;
  min-height:3px; /* keeps horizontal scroll bar when no records  */
  max-width: none;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: 0;
}

${scope} td.ow-footer.float-col,
${scope} td.ow-footer.currency-col,
${scope} td.ow-footer.number-col,
${scope} td.ow-footer.int-col,
${scope} td.float-col.non-editable-cell {
  text-align: right;
}
${scope} {
  overflow-y: hidden;
  overflow-x: auto;
}
${scope} > .ow-grid-header .ow-filterrow ${headerCellTag} {
  text-align: left;
}
${scope} > .ow-grid-header .ow-titlerow ${headerCellTag} {
  position: relative;
}
${scope} > .ow-grid-header ${headerCellTag} .check-all-none {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  text-decoration: none;
  padding: 0;
}
${scope} > .ow-grid-header ${headerCellTag} .check-all-none::before {
  border: 0px;
  text-decoration: none;
}

${scope} { position: relative; }

${scope} a.col-sort::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0.4em;
}

${scope}.indicate-current-row tbody tr.ow-current-row > td,
${scope}.indicate-current-cell tbody td.ow-current-cell {
  background-color: ${theme.selectedRowBackgroundColor};
  /* border-color: #a10a7d; plum */
}

${scope} td > span {
  width: inherit;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

${scope} .ow-grid-content td > span {
  height: inherit;
  box-sizing: border-box;
  white-space: nowrap;
}

${scope}.non-editable .ow-grid-content td > span {
  padding: 0 3px;
}

i.fa.text-item-icon { color: ${iconBlue}; }
${scope} .ow-grid-btn-delete-row i.fa {
  color: ${theme.iconRed};
}
${scope} .ow-grid-btn {
  border: 0px;
}

${scope} input.int,
${scope} input.float,
${scope} input.number,
${scope} input.currency {
  text-align: right;
}
${scope} input.int.combo {
  text-align: left;
}

${scope}.ow-mark-changes tr.ow-dirty td:first-child > span,
${scope}.ow-mark-changes tr.ow-deleted-row td:first-child > span {
  box-sizing: border-box;
  border-left: 0.33em rgb(77, 144, 254) solid;
}
${scope} tr.ow-deleted-row td > * {
  opacity: 0.5;
}
${scope} tr.ow-deleted-row * {
  text-decoration: line-through;
}
${scope} tr.row {
  background-color: inherit;
  transition: background-color 0.5s, border-color 0.5s;
}
${scope} tr.row.ow-row-invalid td {
  background-color: pink;
  transition: background-color 1s;
}

${scope} tr.row.ow-row-invalid td input {
  background-color: pink;
  transition: background-color 1s;
}

${scope}.no-footer > div.ow-grid-footer {
  display: none;
  height: 0;
}

${scope} td .ow-ctl-wrap,
${scope} ${headerCellTag} .ow-ctl-wrap {
  text-indent: 0;
  padding-top: 0;
  padding-left: 0;
}

${scope} .ow-grid-content td .ow-textbox {
  border: transparent;
}

${scope} .resize-col-handle {
  width: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
}
${scope}.resizable-columns .resize-col-handle {
  cursor: ew-resize;
  width: 0.4em;
}
${scope} .ow-header { border-color: ${theme.textboxBorderColor}; }

${scope} td.ow-footer { border-top: 0; border-bottom: 0; }

${scope} .ow-header ${headerCellTag} {
  padding-left: 0;
  padding-right: 0;
}
${scope} .ow-header ${headerCellTag} > span {
  display: inline-block;
  padding: 0 0.22em;
}
${scope} .int-col > span.read-only {
  text-align: right;
}

${scope} .ow-grid-content {
  background: #fff;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  zoom: 1;
  min-height: 0;
}
${scope} tr.row .ow-ctl-wrap.ow-textbox {
  border-radius: 0;
  border-color: transparent;
  background: transparent;
}

${scope} tr.row.ow-alt { background-color: ${theme.rowAltColor} }

${scope}.grid7 tr.row .ow-ctl-wrap.ow-textbox:focus-within {
  background: #fff;
}

${scope} > div::-webkit-scrollbar { width: 8px; height: 8px; background: transparent; }
${scope} > div::-webkit-scrollbar-track { background: transparent; }
${scope} > div.ow-grid-content::-webkit-scrollbar-track { background: #eaeaea; }
${scope} > div::-webkit-scrollbar-thumb {
  background: #d2d2d7;
  border-radius: 5px
}
${scope} > div::-webkit-scrollbar-thumb:hover { background: #d2d2d7; }

${scope} tr {
  font-weight: normal;
  line-height: 24px;
}

${scope} ${headerCellTag} > span, 
${scope} td > span {
  height: inherit;
}

${scope}.non-editable tr {
  cursor: pointer;
}

${scope} .ow-grid-header tr > * > span {
  padding: 3px;
  display: block;
  box-sizing: border-box;
}

${scope} .ow-grid-header tr.ow-titlerow > * > span {
  line-height: 1rem;
  padding: 6px 3px;
}

${scope} .ow-grid-header tr.ow-filterrow > * > span {
  line-height: inherit;
}

${scope} .ow-grid-header tr.ow-filterrow .filter-icon {
  padding-left: 0.25rem;
}
${scope} .ow-grid-header tr.ow-filterrow .ow-ctl-wrap.ow-textbox {
  border-color: ${theme.textboxBorderColor};
  outline: 0;
  margin: 0;
  box-sizing: border-box;
  width: calc(100% - 1rem);
}

${scope} td.currency-col input,
${scope} td.float-col input,
${scope} td.int-col input {
    text-align: right;
    box-sizing: border-box;
}
${scope} td.currency-col input:focus,
${scope} td.float-col input:focus,
${scope} td.int-col input:focus {
    text-align: left;
}

a.check7.ow-check {
  padding: 0;
}

${scope} a.check7.ow-check:before {
  text-align: center;
  margin-right: 0;
}

</style>
`
  )

module.exports = { grid7 }

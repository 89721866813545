// wireUpView5.js

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @param {object} view
 * @returns
 */
module.exports.wireUpView5 = (view = { ow: ow5 }) => {
  const { qc, html } = common.cmp

  view.ow = view.ow ?? ow5

  const { grid5, combo5, button5, footerBar, headerBar } = common.qcControls

  const result = {
    view,
    common,
    ...common.ow5,

    ...common.qcControls,

    combo5(opts) {
      opts.view = view
      return combo5(opts)
    },

    button5(opts) {
      return button5(
        typeof opts === 'string' ? { name: opts, view, command: opts } : { ...opts, view }
      )
    },

    footerBar(opts, dsName) {
      return footerBar(opts, dsName, view)
    },

    headerBar(opts, dsName) {
      return headerBar(opts, dsName, view)
    },

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = true
      return $ajax(opts)
    },

    grid5(opts) {
      opts.view = view
      return grid5(opts)
    },

    collectionController5(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return ow5.collectionController5(opts)
    },

    dc5(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return ow5.dc5(opts)
    },

    styles(scope = view.qTop.el?.id ? '#' + view.qTop.el.id : '.css5') {
      return html(`<style>
/* css5 */
/* ${scope} * { float: none; } */
</style>`)
    }
  }

  view.afterInit = () => {
    const { qTop } = view

    // qTop.on('keydown', shortcutKeyHandler(view))

    // prevent filter change propagating out from the view
    // especially from filters
    qc(qTop.el.parentElement).on('change ow-change', e => common.killEvent(e))

    qc(qTop.el.parentElement).addClass('ow5')

    result.styles().renderTo(qTop.el.parentElement)
  }
  return result
}

const { qc } = require('../cmp/qc')
const { registerCtl, ctlTypes } = require('./ctl5')

exports.currentStore = (el, opts) => {
  el.opts = opts
  opts.ctlType = 'current-store'
  ow5.ctlType(el)
  const me = qc(el)
  me.val(me.val(), undefined, true)
  return el
}

registerCtl('current-store', {
  ...ctlTypes.combo,
  init() {
    const el = this
    const qEl = qc(el)

    Object.assign(
      el.opts,
      {
        fieldName: el.opts?.fieldName || qEl.attr('data-field') || 'StoreID',
        valueTextDisplay: true,
        valueField: 'StoreID',
        textField: 'StoreName',
        objectFieldName: 'BOStoreInfo',
        classes: 'w4'
      },
      el.opts
    )

    if (ow.currentStore.StoreType === 2) {
      el.opts.noFilter = true
      el.opts.url = el.opts.url ?? '/data/report/lookup/store'
    } else el.opts.list = [ow.currentStore]

    ow.ctlTypes.combo.init.call(el)

    if (qEl.hasClass('ow-filter-control')) {
      if (!('filterValueIfEmpty' in el.opts))
        console.warn('Store Filter Combo needs opts.filterValueIfEmpty ') // we need to use this as a filter
      el.opts.filterValueIfEmpty = el.opts.filterValueIfEmpty ?? -1
    }

    el.odisable(ow.currentStore.StoreType !== 2)
  },

  val(v, m) {
    const el = this
    if (m) el.opts.model = el.opts.model || m

    if (ow.currentStore.StoreType !== 2) {
      el._selectedItem = { ...ow.currentStore }
      v = ow.currentStore.StoreID
      if (!arguments.length) return v
    }
    let v1 = ow5.ctlTypes.combo.val.call(el, v, m)

    return v1
  }
})

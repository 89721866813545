const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { dataControls, populateControls } = require('./databind7')

function toJSONNull() {
  return null
}

const hasChanged = (module.exports.hasChanged = model =>
  $meta(model)?.deleted || $meta(model)?.deleted || Object.keys($meta(model)?.changes || {}).length)

/**
 *
 * @param {object} rec
 * @param {string} f - nested fieldname eg. "Product.Name"
 * @param {function} callIfChanged - is called if the field has changed before the _meta.prev is updated with the new value.
 */
const fieldReact = (module.exports.fieldReact = (rec, f, callIfChanged, view) => {
  if (!view) throw 'fieldReact requires view argument'

  const meta = $meta(rec)

  let newVal = _v(rec, f)

  if (Array.isArray(newVal)) {
    // child grid
    const g = view.qTop.find('[data-field="' + f + '"]')[0]
    if (g) {
      if (!g.dc.hasChanges()) delete meta.changes[f]
      else meta.changes[f] = g.dc._changes

      // always call because this doesn't arrive unless it has
      callIfChanged?.(rec, f)
    }
    return true
  }

  let vOrig = _v(meta.orig, f)
  let vPrev = f in meta.prev ? meta.prev[f] : vOrig // changes are stores as flattened values

  const compareVal = function (v) {
    if (Array.isArray(v) || typeof v === 'object') {
      var sVal = JSON.stringify(v)
      if (sVal.length > 5000) {
        console.warn(
          'fieldReact large value being used for comparison, do we need to change it? ' + f
        )
      }
      return sVal
    }
    return v
  }

  let prevCompareVal = compareVal(vPrev)
  let newCompareVal = compareVal(newVal)
  if (prevCompareVal === newCompareVal) return false

  let origCompareVal = compareVal(vOrig)

  if (origCompareVal === newCompareVal || meta.reactFields[f] === false) delete meta.changes[f]
  else meta.changes[f] = newVal

  callIfChanged?.(rec, f)
  view.qTop.renderAsync()

  meta.prev[f] = newVal

  return true
})

const recordReact = (module.exports.recordReact = (model, callIfChanged, view) => {
  if (!view) throw 'recordReact requires view argument'
  const meta = $meta(model)
  Object.keys(meta.reactFields).forEach(f => fieldReact(f, callIfChanged, view))
})

const cancelChanges = (module.exports.cancelChanges = model => {
  var meta = $meta(model)

  Object.keys(model).forEach(k => delete model[k]) // remove all the values
  Object.keys(meta.orig).forEach(k => (model[k] = meta.orig[k])) // restore all the orig values

  $meta(model, meta)

  initMeta(meta.rowi)
  $meta(model).prev = meta.prev

  return model
})

function $meta(model, setTo) {
  return (model._meta = arguments.length > 1 ? setTo : model._meta ?? {})
}
module.exports.$meta = $meta

const initMeta = (model, i, reactFields = {}) => {
  const _meta = Object.assign($meta(model), {
    orig: JSON.parse(JSON.stringify(model)),
    prev: {}, // JSON.parse(JSON.stringify(r)), // todo: nested fields!!!! and reactFields
    changes: {},
    // new: false,
    // deleted: false,
    toJSON: toJSONNull,
    reactFields
  })

  if (i || i === 0) {
    _meta.rowi = i
    _meta.filterIndex = i
  }

  let p
  for (p in model)
    if (reactFields[p] !== false)
      if ((model[p] || {}).toISOString) {
        // restore dates to Date Object
        _meta.orig[p] = model[p]
        delete _meta.prev[p]
      }

  return _meta
}
module.exports.initMeta = initMeta

const dataItemController7 = opts => {
  if (!opts.view) throw 'dataItemController7 opts requires the view object'
  var me = Object.create({
    fieldReact(f, callIfChanged = opts.callIfChanged) {
      return fieldReact(me.rec, f, callIfChanged, opts.view)
    },

    recordReact(callIfChanged = opts.callIfChanged) {
      return recordReact(me.rec, callIfChanged, opts.view)
    },

    cancelChanges() {
      // Because we don't know what might be pointing at this object (It could be in an array of records),
      // we need to leave it the same instance and restore the original values.
      return cancelChanges(me.rec)
    },

    initMeta(i) {
      return initMeta(me.rec, i, me.opts.reactFields)
    },

    hasChanged() {
      return hasChanged(me.rec)
    }
  })
  me.opts = opts || {}
  me.opts.reactFields = me.opts.reactFields || {}
  me.rec = me.opts.rec
  return me
}

const validateEditors = function (qTop, dsName, messageArray, onInvalid) {
  messageArray = messageArray || []

  var result = true

  var defaultInvalid = function (display, err, el) {
    result = false
    console.log(display + ' invalid: ' + err)
    messageArray.push(display + ' invalid: ' + err)
    qc(el).displayValidity?.(false, err)

    // if (el.opts.edType == 'password') passwordChecklist.push(r)
    // if (el.opts.edType == 'grid') gridFieldsChecklist.push(r)
  }

  onInvalid = onInvalid || defaultInvalid

  var passwordChecklist = []
  var gridFieldsChecklist = [] //for multiple grids

  qTop.find("[data-field-for='" + dsName + "']").forEach(el => el.validate(onInvalid, messageArray))

  //process password
  var pwdGroups = passwordChecklist.reduce(function (obj, item) {
    obj[item.group] = obj[item.group] || []
    obj[item.group].push({ value: item.value, target: item.target })
    return obj
  }, {})
  for (var rec in pwdGroups) {
    if (rec in pwdGroups && Array.isArray(pwdGroups[rec])) {
      var pwdCompare = null
      var matched = true
      pwdGroups[rec].forEach(function (l) {
        if (pwdCompare === null) pwdCompare = l.value
        else {
          if (l.value !== pwdCompare) {
            matched = false
          }
        }
      })
      if (!matched) {
        pwdGroups[rec].map(function (item) {
          var el = item.target
          if (el.makeMeInvalid) el.makeMeInvalid(onInvalid, messageArray)
          qc(el)?.displayValidity(false, __('Password and Confirm Password mismatch'))
        })
        result = false
      }
    }
  }

  //process grids validation
  gridFieldsChecklist.map(f => {
    if (!f.resVal) {
      ;(f.resErr || []).map(err => messageArray.push(err))
      result = false
    }
  })

  if ((messageArray || []).length) {
    ow.popInvalid((messageArray || []).join('<br>'))
    qTop.find('.resource_set.k-input-errbg input')[0]?.focus()
  }
  return result
}

// form-data controller
// population and form flow
module.exports.dc7 = opts => {
  if (!opts.view) throw 'dc7 requires the view object'
  const { view } = opts
  const { qTop, viewdata } = view

  const me = Object.create({
    rec() {
      return this.recCon.rec
    },

    fieldReact(f) {
      this.recCon.fieldReact(f)
    },

    react(f) {
      this.recCon.recordReact(f)
    },

    newRec() {
      // override this for default values
      return this.opts.newRec?.() ?? {}
    },

    myId(r, alwaysReturnObj) {
      if (this.opts.idFields.length === 1) {
        if (alwaysReturnObj) {
          var res = {}
          res[this.opts.idField] = r && _v(r, this.opts.idField)
          return res
        } else return r && _v(r, this.opts.idField)
      }
      var ids = {}
      this.opts.idFields.forEach(x => (ids[x] = _v(r, x)))
      return ids
    },

    idString(r) {
      var id = this.myId(r)
      if (typeof id === 'object') {
        id = JSON.stringify(id)
      }
      return id
    },

    hasChanged() {
      return this.recCon.hasChanged()
    },

    edChanged() {
      if (!this.populating()) this.saveCancelState()
    },

    validateData(messageArray, onInvalid) {
      // todo: - do we really want to do this control based validation?
      return validateEditors(this.opts.view.qTop, this.dsName, messageArray, onInvalid)
    },

    // load the main record - request from server and then populate the controls
    load(id) {
      var o = this

      if (typeof id === 'object') id = JSON.stringify(id)

      var data = {}

      var url = (typeof o.opts.url === 'function' ? o.opts.url() : o.opts.url) || o.opts.baseURL

      url = url.split('?')
      url[0] = url[0] + (typeof id !== 'undefined' ? '/' + encodeURIComponent(id) : '')
      url = url.join('?')

      $ajax({ view: o.opts.view, showProgress: true, LRTask: o.opts.LRTask ?? true, url, data })
        .then(response => o.populate(response))
        .catch(err => ow.popError(__('load returned error ') + (err?.errMsg ?? err)))
    },

    // extracts the data in an independent instance for saving.
    // todo: generalize for other component types, subEds etc.
    readData() {
      var o = this
      var result = o.recCon.rec

      // detach the child datasets
      var kids = {}
      dataControls(o.dsName, o.opts.view.qTop.el)
        .filter('.ow-grid')
        .forEach(g => {
          var f = qc(g).opts?.fieldName ?? g.opts?.fieldName
          if (f in kids) return // in case there are 2, just do the first
          kids[f] = result[f] // store for later
          delete result[f]
        })

      result = JSON.parse(JSON.stringify(result, true))

      // attach to result
      dataControls(o.dsName, o.opts.view.qTop.el)
        .filter('.ow-grid')
        .forEach(g => {
          var f = g.opts.fieldName
          if (f in result) return
          o.rec()[f] = kids[f]
          g.readData(result) // use the extracted instance from the grid.
        })

      return result
    },

    save() {
      var o = this
      var qTop = this.opts.view.qTop
      const { viewdata } = this.opts.view

      if (!o.validateData()) return

      var result = o.readData()
      if (o.opts.preSave?.(result) === false) return

      $meta(result, {})

      // if this is a nested edit
      if (viewdata.record) {
        viewdata.result = result
        return qTop.closeForm(true)
      }

      var idField = o.opts.idField
      var isNew = $meta(o.recCon.rec).new

      if (!isNew || !result[idField] || result[idField] === -1) return innerSave()

      // check for ID match
      var id = o.idString(result)
      return $ajax({
        view: o.opts.view,
        showProgress: true,
        LRTask: o.opts.LRTask ?? true,
        url: o.opts.baseURL.split('?')[0] + '/' + o.idString(result)
      })
        .then(response => {
          if (o.idString(response) === id) {
            qTop
              .find('[data-field="' + idField + '"')[0]
              ?.closest('.resource_set')
              ?.addClass('k-input-errbg')

            qTop.find('[data-field="' + idField + '"')[0]?.focus()
            return ow.popInvalid(__('ID already in use.'))
          }
          innerSave()
        })
        .catch(() => innerSave())

      function innerSave() {
        $ajax({
          view: o.opts.view,
          showProgress: true,
          LRTask: o.opts.LRTask ?? true,
          type: isNew ? 'POST' : 'PUT',
          url:
            o.opts.baseURL.split('?')[0] +
            (!isNew ? '/' + o.idString(result) : '') +
            (o.opts.baseURL.split('?')[1] ? '?' + o.opts.baseURL.split('?')[1] : ''),
          data: result
        })
          .then(response => {
            ow.popSaveOK(response)

            // console.warn('// todo: reload with data returned from the server.');
            o.populate(result)

            qTop.trigger('ow-data-saved', response, o)
            if (o.opts.closeAfterSave !== false) {
              o.opts.view.viewdata.result = result
              return qTop.closeForm(true)
            }
          })
          .catch(err => ow.popSaveError(err))
      }
    },

    delete() {
      var o = this
      var qTop = this.opts.view.qTop

      ow.confirm(
        this.opts.displayName,
        __('Are you sure you want to delete this record?'),
        r => r && innerDelete()
      )

      const innerDelete = () => {
        $ajax({
          view: o.opts.view,
          showProgress: true,
          LRTask: o.opts.LRTask ?? true,
          type: 'DELETE',
          url: o.opts.baseURL + '/' + o.idString(o.recCon.rec)
        })
          .then(response => {
            ow.popDeleteOK(response)
            qTop.trigger('ow-data-deleted', response)
            var r = o.newRec()
            r.Name = ''
            o.populate(r)
          })
          .catch(err => ow.popDeleteError(err))
      }
    },

    copy(id) {
      var o = this
      if (typeof id === 'object') id = JSON.stringify(id)

      $ajax({
        view: o.opts.view,
        showProgress: true,
        LRTask: o.opts.LRTask ?? true,
        url: o.opts.baseURL + '/' + id
      })
        .then(response => {
          var r = o.newRec()
          Object.assign(response, o.myId(r, true))
          o.populate(response)
        })
        .catch(err => ow.alert('load returned error ' + err?.errMsg ?? err))
    },

    cancel() {
      const o = this
      o.recCon.cancelChanges()
      var result = o.populate(o.recCon.rec)
      console.log(result)
    },

    new() {
      var o = this
      if (o.recCon.hasChanged()) {
        ow.confirm(
          o.opts.displayName,
          __('Are you sure you want to discard changes and reset form?'),
          r => r && o.populate(o.newRec())
        )
        return false
      }
      o.populate(o.newRec())
    },

    // todo: move this to the winCon
    // Applies standard view form loading behaviour based on viewdata.
    loadInitial(viewdata) {
      var o = this

      if (!viewdata.mode) viewdata.mode = viewdata.record || viewdata.id ? 'edit' : 'new'

      if (viewdata.mode === 'new') {
        o.populate(o.newRec())
        if ($meta(o.recCon.rec)) $meta(o.recCon.rec).new = true
        return
      }

      if (viewdata.record) o.populate(viewdata.record)
      else if (viewdata.id !== undefined) o.load(viewdata.id)
    },

    // the same as for grids - future, get grids using dataController too
    saveCancelState() {
      var o = this
      var qTop = this.opts.view.qTop

      var hasChanged = o.hasChanged()
      var broadcast = hasChanged !== o._dirty
      o._dirty = hasChanged

      var state = {
        dataController: o,
        dsName: this.dsName,
        // editable: o.opts.editable,
        editing: o._dirty
      }
      // console.log('State.editing:' + state.editing, 'dc')
      // Look for save and cancel buttons bound to this grid
      function broadcastStateChange(el) {
        state.called = el
        qc(el).trigger('ow-datastatechange', state)
      }
      // getSubsAndEds
      if (broadcast) {
        qTop
          .find('[data-field-for="' + this.dsName + '"],[data-target-ref="' + this.dsName + '"]')
          .forEach(el => broadcastStateChange(el))

        if (o === qTop.dc) broadcastStateChange(qTop.el)
      }
    },

    populate(r) {
      var o = this
      var qTop = o.opts.view.qTop
      dataControls(o.dsName, qTop.el).forEach(el => {
        var f = el.opts?.fieldName
        if (f) if (o.recCon.opts.reactFields[f] !== false) o.recCon.opts.reactFields[f] = true
      })

      r = o.opts.prePopulate?.(r) ?? r // don't assume prePopulate will return something everytime but use result if it does

      o.recCon.rec = r
      o.recCon.initMeta()
      o.populating(true)
      o.updateControls()

      qTop.trigger('ow-populate', o, r)
      o.populating(false)
      o.saveCancelState()
    },

    restore(r) {
      var o = this
      var qTop = o.opts.view.qTop
      r = o.opts.prePopulate?.(r) ?? r
      o.recCon.rec = r

      //update Meta
      for (var p in r) {
        if (o.opts.reactFields?.[p] !== false)
          if ((r[p] || {}).toISOString) {
            // restore dates to Date Object
            $meta(o.recCon.rec).orig[p] = r[p]
            delete $meta(o.recCon.rec).prev[p]
          }
      }
      o.populating(true)
      o.updateControls()
      qTop.trigger('ow-populate', o, r)
      o.populating(false)
      o.saveCancelState()
    },

    update(r) {
      var o = this
      var qTop = o.opts.view.qTop
      o.populating(true)
      o.updateControls()
      qTop.trigger('ow-populate', o, r)
      o.populating(false)
      o.saveCancelState()
    },

    updateControls() {
      var o = this
      var rec = o.recCon.rec
      var qTop = o.opts.view.qTop
      o.populating(TextTrackCue)
      populateControls(o.opts.dsName, rec, qTop.el)
      o.populating(false)
      qTop.renderAsync()
    }
  })
  me.opts = opts
  me.dsName = me.opts.dsName

  qTop.dataSources = qTop.dataSources || {}
  qTop.dataSources[me.dsName] = me

  opts.el = opts.el || qTop.el

  me.opts.reactFields = me.opts.reactFields || {}
  const recCon = dataItemController7({
    view: opts.view,
    rec: opts.rec || {},
    reactFields: me.opts.reactFields,
    callIfChanged:
      opts.callIfChanged || // for properties editor
      function (rec, f) {
        //console.log('field changed ' + f);
        const newValue = _v(rec, f)
        setTimeout(() => qTop.trigger('ow-field-changed', f, newValue, rec, me), 1)

        dataControls(me.dsName, view.qTop.el).forEach(
          el => el.opts?.fieldName === f && el.val(newValue, rec)
        ) // todo: we need to find a way to deal with subEds (and grids), not setting el.val(data) each time.
        me.saveCancelState()
      }
  })
  me.recCon = recCon

  qTop.dc = qTop.dc || me

  if (me === qTop.dc && view.viewdata) {
    view.viewdata.requestClose =
      view.viewdata.requestClose ||
      function () {
        if (qTop.dc.hasChanged()) {
          qTop.dc.displayName = viewdata.name.split('-')[0]
          ow.confirm(
            qTop.dc.displayName,
            __('Are you sure you want to discard changes and close form?'),
            r => r && qTop.closeForm(true)
          )
          return false
        }
        return true
      }
  }

  if (!Array.isArray(opts.idField)) {
    me.opts.idFields = [me.opts.idField]
  } else {
    me.opts.idFields = me.opts.idField
    me.opts.idField = me.opts.idFields[0]
  }
  me.opts.idField = me.opts.idFields[0]

  // flag to prevent ow-data-changed events firing while populating
  var populating = 0

  // access for overriding
  me.populating = function (v) {
    if (typeof v !== 'undefined') {
      if (v) populating++
      else populating--
    }
    return populating
  }

  const onEdChanged = function (e) {
    if (qc(e.target).opts?.dsName !== me.dsName) return

    var f = qc(e.target).opts.fieldName
    if (me.recCon.opts.reactFields[f] !== false) me.recCon.opts.reactFields[f] = true
    if (me.populating()) return
    // console.log('onEdChanged ' + o.dsName + ' ' + this + ' ' + f);

    if (qc(e.target).hasClass('ow-grid')) {
      me.recCon.fieldReact(f)
    } else {
      var newValue = e.target.val()
      _v(me.recCon.rec, f, newValue)
      me.recCon.fieldReact(f)
    }
  }
  qc(opts.el).on('ow-change change', onEdChanged)

  return me
}

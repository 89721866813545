const { propsToStyle } = require('../css')
const { $map } = require('../element-map')
const { unnestArray } = require('../unnest-array')
const { wait } = require('../wait')
const { $cmp } = require('./$cmp')

const sameEl = (el1, el2) => el1 && el2 && el1 === el2

const isAncestorOf = (an, des, depth = 0) => {
  if (!des || !des.parentElement) return false
  if (sameEl(an, des)) return false
  if (sameEl(an, des.parentElement)) return true
  return isAncestorOf(an, des.parentElement, depth++)
}

const appendChildArray = (el, a) => {
  if (!Array.isArray(a)) throw 'appendChildArray cannot append non arrays'
  a.filter(x => x).forEach(x => (Array.isArray(x) ? appendChildArray(el, x) : el.appendChild(x)))
}

/**
 *
 * @param {HTMLElement} el - the parent whose kids we're merging
 * @param {Array} a - Array of HTMLElements
 * @param {boolean = true} preserveFocus
 */
const mergeChildArray = (el, a, preserveFocus = true) => {
  if (!Array.isArray(a)) throw 'mergeChildArray cannot append non arrays'

  a = a.filter(x => x !== undefined)

  // work out the activeElement if it's inside
  let activeElement =
    preserveFocus && (document.activeElement !== document.body ? document.activeElement : null)

  let childContainingFocus

  if (activeElement) {
    if (isAncestorOf(el, activeElement)) {
      let i, kid
      for (i = 0; i < el.childNodes.length && !childContainingFocus; i++) {
        kid = el.childNodes[i]
        if (sameEl(kid, activeElement) || isAncestorOf(kid, activeElement))
          childContainingFocus = kid
      }

      // log('rendering element containing activeElement: ', childContainingFocus)
    } else {
      activeElement = null // disregard
    }
  }

  let i, nodeToInsert, xIsAlreadyChildNode, xContainsActive
  if (a.length === 0) el.childNodes = []
  else {
    for (i = 0; i < a.length; i++) {
      nodeToInsert = a[i]
      if (!nodeToInsert) continue

      if (!el.childNodes[i]) {
        el.appendChild(nodeToInsert)
        continue
      }

      if (sameEl(el.childNodes[i], nodeToInsert)) continue // already correct, do nothing with this one

      xIsAlreadyChildNode = sameEl(el, nodeToInsert.parentElement)
      xContainsActive = xIsAlreadyChildNode ? sameEl(nodeToInsert, childContainingFocus) : false

      if (xContainsActive) {
        // remove all those infront
        while (el.childNodes[i] && !sameEl(el.childNodes[i], nodeToInsert))
          el.childNodes[i].remove()

        continue
      }

      if (xIsAlreadyChildNode) nodeToInsert.remove()

      el.insertBefore(nodeToInsert, el.childNodes[i])
    }
  }

  // remove trailing extras
  while (el.childNodes[a.length]) el.childNodes[a.length].remove()
}

const renderContent = (c, renderScope) =>
  typeof c === 'string'
    ? document.createTextNode(c)
    : Array.isArray(c)
      ? c.filter(x => x).map(x => renderContent(x, renderScope))
      : c.render?.(renderScope) || ''

let nextTick

const renderAsync = async c => {
  c.tsLastRenderRequest = new Date().getTime()
  c.renderWaiting =
    c.renderWaiting ??
    (nextTick ?? (nextTick = wait(10))).then(() => {
      if (!c.renderWaiting) return c.el
      delete c.renderWaiting
      if (c.el && (!c.tsRender || c.tsLastRenderRequest > c.tsRender)) return c.render()
      return c.el
    })

  return c.renderWaiting
}

const renderStyles = (style, element) => {
  if (!style) return
  const sStyle = propsToStyle(style)
  if (element.hasAttribute('style') || sStyle !== '') element.setAttribute('style', sStyle)
}

const renderAttrs = (attrs, element) => {
  let i = 0,
    k,
    v,
    currentV,
    keys = Object.keys(attrs)

  // first remove any that aren't on Cmp
  for (i = element.attributes.length - 1; i >= 0; i--) {
    k = element.attributes[i].name
    if (attrs[k] === undefined) element.removeAttribute(k)
  }

  const hasNoAttrs = element.attributes.length === 0

  for (i = 0; i < keys.length; i++) {
    k = keys[i]
    v = attrs[k]
    if (v === null) v = undefined

    if (k === 'style') continue

    if (k === 'class') {
      if (v !== undefined) {
        v = v
          .split(' ')
          .filter(x => x)
          .join(' ')
        if (v === '') v = undefined
      }
      if (!v && element.hasAttribute('class')) element.removeAttribute(k)
      else if (v && (!element.hasAttribute('class') || element.getAttribute('class') !== v))
        element.setAttribute('class', v)

      continue
    }

    // enforce string type,
    // remember
    // "" - is a valid value,
    // undefined - removes the attribute
    if (v !== undefined && typeof v !== 'string') v = '' + v

    if (k === 'value') {
      // value is not an attribute
      // we need this for setting the el.value on first render
      if (v !== element.value) {
        element.value = v
        // console.log('SET value =', v, element)
      }
      delete attrs.value // make sure it doesn't overwrite again later
    } else if (v === undefined) {
      if (!hasNoAttrs && element.hasAttribute(k)) element.removeAttribute(k)
    } else {
      currentV = !hasNoAttrs && element.hasAttribute(k) ? element.getAttribute(k) : undefined
      if (hasNoAttrs || !element.hasAttribute(k) || currentV !== v) {
        // log('Setting changed attribute ' + k + ':' + currentV + ' -> ' + v)
        element.setAttribute(k, v)
      }
    }
  }

  return element
}

const renderChildren = (cParent, myScope) => {
  if (
    cParent.children !== undefined ||
    (!cParent.isNorm && !cParent.isQc && cParent.lastRendered !== undefined)
  ) {
    const { lastRendered = {} } = cParent

    if (!Array.isArray(cParent.children))
      cParent.children = cParent.children ? [cParent.children] : []
    const kids = cParent.children

    if (kids !== lastRendered.children) {
      let i = 0,
        kid
      for (; i < kids.length; i++) {
        kid = kids[i]
        if (kid === undefined || kid === null) return
        if (kid === cParent) throw new Error('child is the parent - recursive loop')
      }

      mergeChildArray(
        cParent.el,
        kids.reduce((r, kid) => {
          if (kid === undefined || kid === null) return r
          return r.concat(renderContent(kid, myScope))
        }, [])
      )
    } else {
      let i = 0,
        kid
      for (; i < kids.length; i++) {
        kid = kids[i]
        renderContent(kid, myScope)
      }
    }

    if (cParent.isQc || cParent.isNorm) delete cParent.children

    lastRendered.children = kids

    return cParent
  }

  // pass thru
  let i = 0,
    el,
    kid
  for (; i < cParent.el.children.length; i++) {
    el = cParent.el.children[i]
    if (el) kid = $cmp(el)
    if (!el || el === cParent.el || cParent === kid) {
      if (ow0.dev)
        console.log(
          'A problem with DOM Element children has occured, usually the grid4 pageSize select',
          cParent.el
        )
    } else renderContent(kid, myScope)
  }

  return cParent
}

const preRender = me => {
  if (me._el.tagName.toLowerCase() === 'a' && (!me.attr('href') || me.attr('href') === '#'))
    me.attr({ href: 'javascript:void(0);' })

  if (me.children) {
    if (!Array.isArray(me.children)) me.children = [me.children]

    // try not to disrupt the children array instance
    unnestArray(me.children)

    me.children.forEach(
      (x, i) => typeof x === 'string' && (me.children[i] = module.exports.textnode(x))
    )
  }
}

/**
 *
 * @param {Cmp} me
 * returns HTMLElement
 */
const render = (me, renderScope) => {
  if (!me) return document.createTextNode('')

  if (me._el === document.body) console.warn('render called on body, please check')

  if (me.rendering > 2) {
    if (ow0.dev) console.warn('Qc rendering loop detected.', me.el)
    return me.el
  }
  // initialize scope if none yet
  let myScope = renderScope || { renderTop: me }
  myScope.inits ??= []
  myScope.count = (myScope.count ?? 0) + 1

  const tik = !renderScope ? Date.now() : 0
  me.tsRender = tik
  me.rendering = (me.rendering ?? 0) + 1

  preRender(me)

  if (!me.el) {
    me.el = me._el ?? document.createElement(me.tag)
    myScope.inits.push(() => {
      if (me.init) {
        me.init(me.el)
        delete me.init
      }
      if (me.onInit) {
        let stopBubble
        ;(Array.isArray(me.onInit) ? me.onInit : [me.onInit]).forEach(
          init => !stopBubble && (stopBubble = init.call(me, {}, me.el, me) === false)
        )
        delete me.onInit
      }
    })
    me._state = me.applyState(me._state || {})
    renderChildren(me, myScope)
    me.qcRendered?.()
  } else {
    me._state = me.applyState(me._state || {})
    renderChildren(me, myScope)
  }

  if (!renderScope) {
    const t = Date.now() - tik
    if (t > 10 && t / myScope.count > 0.05)
      console.warn(
        '>>> Qc render took: ' +
          t +
          'ms for ' +
          myScope.count +
          'cmps, avg: ' +
          (myScope.count ? t / myScope.count : '-').toString().slice(0, 5),
        me._el
      )

    myScope.inits.forEach(f => f())
  }
  me.rendering ??= 1
  me.rendering--
  if (me.rendering < 1) delete me.rendering

  return me.el
}

/**
 * renderTo - appends the HTML and calls the chained inits
 *
 */
const renderTo = function (cmp, parentEl) {
  parentEl = parentEl[0] || parentEl // make sure it's an Element not jQuery
  let renderScope = { parentEl, inits: [] }
  var res = renderContent(cmp, renderScope)
  appendChildArray(parentEl, Array.isArray(res) ? res : [res])
  renderScope.inits.forEach(f => f())
  return res
}

const renderAs = (cmpObj, element) => {
  cmpObj.el = element
  if ($map(element).cmp) console.warn('renderAs called on existing Cmp element, overwriting.')

  $map(element).cmp = cmpObj
  cmpObj.render()
  return cmpObj
}

module.exports = {
  renderStyles,
  renderAttrs,
  renderChildren,
  mergeChildArray,
  isAncestorOf,
  renderContent,
  renderTo,
  renderAs,
  renderAsync,
  render,
  preRender
}

const { isDate, isString, isNumber } = require('../../lib/js-types')
const { culture } = require('./culture')
const { dates } = require('./dates')

const floatThousand = () => culture().numberFormat[',']
const floatDecimal = () => culture().numberFormat['.']

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

const parseCurrencyNoKendo = (s, locale) => {
  if (s === '') return
  // remove currency chars
  const decimal = culture(locale).numberFormat['.']
  const a = Array.from(s)

  let i = 0,
    neg = '',
    r = ''
  for (; i < a.length; i++) {
    if (a[i] === '-' || a[i] === '(') neg = '-'
    if (a[i] === decimal) r = r + '.'
    else if (a[i] >= '0' && a[i] <= '9') r = r + a[i]
  }

  return parseFloat(neg + r)
}
exports.parseCurrencyNoKendo = parseCurrencyNoKendo

const parseCurrency = v => parseCurrencyNoKendo(v)

const ctlParsers = {
  int(v) {
    return v === '' ? null : parseInt(v.split(floatThousand()).join(''))
  },

  currency(v) {
    return parseCurrency(v)
  },

  float(v, fmt = 'n') {
    if (typeof kendo !== 'undefined') return kendo.parseFloat(v, fmt)

    const point = floatDecimal()
    if (typeof v === 'string' && v.indexOf(point) !== -1) {
      v = v.split(floatThousand()).join('')
      if (point !== '.') v = v.split(point).join('.')
    }
    return parseFloat(v)
  },

  decimal(v, fmt = 'n') {
    return ctlParsers.float(v, fmt)
  },

  date(v, fmt = dates.DateFormatSetting, calendar = dates.calendar) {
    return dates.parseDate(v, fmt, calendar)
  },

  datetime(v, fmt = dates.DateTimeFormatSetting, calendar = dates.calendar) {
    return dates.parseDate(v, fmt, calendar)
  },

  time(v) {
    if (v === '') return undefined
    return dates.parseDate(v, dates.TimeFormatSetting)
  }
  // ow 6:
  // time(v, fmt) {
  //   if (v === '') return undefined
  //   let d = parseDate(v, fmt || culture().TimeFormatSetting, culture().calendar) // 'HH:mm:ss'
  //   return (
  //     d &&
  //     (d.getHours() * 60 * 60 + d.getMinutes() * 60 + d.getSeconds()) * 1000 + d.getMilliseconds()
  //   )
  // }
}

const ctlFormatters = {
  int(v = null) {
    return v === null ? '' : '' + v
  },

  currency(v = null, fmt = 'c') {
    return v === null ? '' : common.toString(v, fmt)
  },

  float(v = null, fmt = 'n') {
    return v === null ? '' : common.toString(v, fmt)
  },

  decimal(v = null, fmt = 'n') {
    return v === null ? '' : common.toString(v, fmt)
  },

  date(v = null, fmt = dates.DateFormatSetting, calendar = dates.calendar) {
    if (v && typeof v === 'string') v = ow0.parseDate(v)
    return !v ? '' : dates.formatDate(v, fmt, calendar)
  },

  // ow 6:
  // date(v, fmt = culture().DateFormatSetting, calendar = culture().calendar) {
  //   if (v && typeof v === 'string') v = parseDate(v, fmt, calendar) || new Date(v)
  //   return !v ? '' : v.toLocaleDateString() // formatString(v, fmt || culture().DateFormatSetting)
  // },

  datetime(v = null, fmt = dates.DateTimeFormatSetting, calendar = dates.calendar) {
    if (v && typeof v === 'string') v = ow0.parseDate(v)
    return !v ? '' : dates.formatDateTime(v, fmt, calendar)
  },

  // ow 6:
  // datetime(v = null, fmt) {
  //   if (v && typeof v === 'string')
  //     v = parseDate(v, fmt || culture().DateFormatSetting, culture().calendar) || new Date(v)
  //   return !v ? '' : v.toLocaleString() //toString(v, fmt || culture().DateTimeFormatSetting)
  // },

  time(v = null, fmt) {
    return !v ? '' : common.formatString(v, fmt || dates.TimeFormatSetting)
  }
}

const formatString = (v, fmt, locale = window.ow?.locale || 'en-US') => {
  if (v === undefined) return ''
  if (v === null) return ''

  if (!fmt) return v || v === 0 || v === false ? v.toString() : ''

  if (typeof v === 'string') return v

  if (isDate(v) && isString(fmt)) {
    return dates.formatDateTime(v, fmt.length === 1 ? dates.patterns[fmt] : fmt || fmt)
  }

  // time
  if (isNumber(v) && (fmt === 'HH:mm' || fmt === 'HH:mm:ss'))
    return dates.formatDate(new Date(v), fmt)

  if (fmt[0] === 'n' || fmt[0] === 'c') {
    // todo!
    if (v === undefined || v === null || isNaN(v)) return ''

    if (fmt === 'c') {
      const { decimals = 2 } = culture(locale).numberFormat.currency
      fmt = 'c' + decimals
    }

    const minimumFractionDigits = fmt.length > 1 ? parseInt(fmt.slice(1, 99)) : undefined
    const maximumFractionDigits = minimumFractionDigits

    /**
     * Removes negative sign from zero, e.g. changes -0 to 0.
     *
     * @param {string} sVal
     * @returns {string}
     */
    const removeNegativeZero = sVal =>
      ctlParsers.float(sVal) === 0 ? sVal.replace('- ', '').replace('-', '') : sVal

    const sVal = removeNegativeZero(
      new Intl.NumberFormat(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
        style: 'decimal'
      }).format(v)
    )

    if (fmt[0] === 'c') {
      const { pattern = ['-$n', '$n'], symbol = '$' } = culture(locale).numberFormat.currency
      return pattern[sVal.indexOf('-') > -1 ? 0 : 1]
        .replace('n', sVal.replace('-', '').replace('(', '').replace(')', ''))
        .replace('$', symbol)
    }

    return sVal
  }

  if (isDate(v) && isString(fmt)) return dates.formatDate(v, fmt)

  return v
}

exports.ctlFormatters = ctlFormatters
exports.ctlParsers = ctlParsers
exports.isNumeric = isNumeric
exports.floatDecimal = floatDecimal
exports.parseCurrency = parseCurrency
exports.formatString = formatString

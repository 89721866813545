// Deprecated - use common.controls.*
// Move away from using the ow.controls and ow5.ctl which are initialized as elements
// the new controls build and add functions to the qc object before rendering.

// This module is for use as a bridge between ow4, ow5 EJS views that have been converted to modules
// Some of the code is from views/form-helper.js
// Please work towards removing kendo and jquery
// todo: create non-kendo version tabStrip
// todo: reduce button complexity - currently the code is extremely complex and achieves something very simple
//   eg. pull out button types to a simple map.  if (type === 'save') saveButton()
//
// Many of the qc types are decomposable and can produce code to create the same object.
// This is used in view conversion tools.

const { rs } = require('../controls/rs')

const { qTreeView4, buildCell } = require('./tree-view4')
const { treeGrid5 } = require('../controls/tree-grid5')
const { qFloat4 } = require('./float4')
const { dataBind } = require('../controls/databind')
const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { applyAttrsAndClasses } = require('../controls/qc-applyattrs')
const { iconCodes } = require('../icon-codes')
const { qCheck } = require('../controls/check')
const { textnode } = require('../cmp/textnode')
const { cmpCollection } = require('../cmp/cmpCollection')
const { camel, unCamel, toStyleSheet } = require('../css')

const ctlType = (...args) => ow5.ctlType(...args)

/**
 * Lightweight client side version of inflection.humanize()
 * @param {string} s eg. 'new-row' => 'New Row'
 * @returns replaces hypens with spaces and makes first chars upper case.
 */
const humanize = s =>
  !s
    ? ''
    : s
        .split('-')
        .join(' ')
        .split(' ')
        .map(x => x[0].toUpperCase() + x.substr(1))
        .join(' ')

const ctlWrapClasses = opts => {
  let classes = [
    ...(opts.class || '').split(' '),
    ...(opts.classes || '').split(' '),
    ...(opts.attrs?.class || '').split(' ')
  ].join(' ')

  let wrapClasses = ['ow-ctl-wrap'].concat((opts.wrapperClass || '').split(' '))

  const c = ' ' + classes + ' '
  if (opts.size === 'w1' || opts.size === 1 || c.indexOf(' w1 ') + 1) wrapClasses.push('w1')
  if (opts.size === 'w2' || opts.size === 2 || c.indexOf(' w2 ') + 1) wrapClasses.push('w2')
  if (opts.size === 'w3' || opts.size === 3 || c.indexOf(' w3 ') + 1) wrapClasses.push('w3')
  if (opts.size === 'w4' || opts.size === 4 || c.indexOf(' w4 ') + 1) wrapClasses.push('w4')
  if (opts.short || c.indexOf(' short ') + 1) wrapClasses.push('short')

  classes.split(' ').forEach(cls => cls.substr(0, 5) === 'wrap-' && wrapClasses.push(cls.substr(5)))

  return wrapClasses.join(' ')
}

const formColumn = (colCount, children, props = {}) => {
  const tag =
    'div.form_column' +
    ({
      2: '.form_column_half',
      4: '.form_column_quarter',
      c2: '.form_column_half',
      c4: '.form_column_quarter'
    }[colCount] || '')

  return qc({ tag, children, ...props })
}
const column = formColumn

const columnSet = (cls, children, props = {}) =>
  qc({
    tag: 'div.form-header' + (cls ? '.' + cls : '').split(' ').join('.'),
    children,
    ...props
  })

const cWrap = (opts, ctl) => {
  ctl.wrapper = qc('div' + ['', ...ctlWrapClasses(opts).split(' ')].join('.'), ctl)

  if (ctl.tag === 'input' || ctl.tag === 'textarea') ctl.wrapper.addClass('ow-textbox')
  return ctl.wrapper
}

const cWrap4 = (opts, basicEd) =>
  (basicEd.wrapper = qc(
    'span' +
      (basicEd.tag === 'input' ? '.k-textbox.ow-textbox' : '') +
      ['', ...ctlWrapClasses(opts).split(' ')].join('.'),
    basicEd
  ))

const tabStrip = opts => {
  // opts.editing
  const tabs = qc(
    'ul',
    opts.tabs.map((tab, i) => qc('li.ow-link.tab-' + i, html(tab.caption)))
  )

  const divs = opts.tabs.map(tab => {
    tab.caption = tab.caption || 'Tab'
    return qc('div.tab_content.k-content.widget-container', tab.content)
  })

  const res = qc('div#tabstrip.tabstrip.fit', [tabs, divs], {
    init: el => {
      el.opts = opts
      ow5.controls.tabstrip(el, {})
      el.parentElement.classList.add('fit')
      el.updateTabsRow()
    },
    opts
  })
  res.opts = opts
  res.ctlType = 'tabStrip'
  res.opts.ctlType = 'tabStrip'

  return res
}

// const tabToCode = tab => {
//   return `{
//     caption: \`${tab.caption}\`,
//     content: [${tab.content.map(qcToCode).join(',')}]
//   }`
// }

// <div id="purchasecardsGrid" class="owauto fit" data-field-for="BOCustomers"></div>
const childGrid = opts =>
  dataBind(
    qc('div#' + opts.name + 'Grid.fit').props({
      opts: Object.assign(
        opts,
        {
          edType: opts.edType || 'subEd',
          fieldName: opts.name
        },
        opts
      )
    })
  )
    .attr(opts.attrs || {})
    .on('init', (e, el) => {
      ow4.controls.BasicEd(el, el.opts)
    })

const fspGrid = function (opts = {}) {
  opts.name = opts.name ?? 'main'
  // opts.id = opts.id ?? opts.name + 'Grid'
  const id = opts.attrs?.id || opts.dsName || opts.name + 'Grid'
  return qc({
    tag: `div#${id}.${id}.fit`
  })
    .props({ opts })
    .on('init', (e, el) => {
      el.opts = el.opts
        ? Object.assign(el.opts, opts, el.opts) // keep default el.opts
        : opts
      ow4.grids.FSPGrid(el, el.opts)
    })
}

const combo5 = function (opts) {
  const me = qc('input.combo')
  me.opts = opts

  dataBind(me)
  applyAttrsAndClasses(me)

  opts.showOnlySelected = false
  me.on('init', (e, el) => {
    el.opts = me.opts
    if (me.opts.init) {
      console.warn('opts.init() is Deprecated')
      opts.init.call(el, el)
    }
    ow5.controls.combo5(el, el.opts)
    el.val(opts.value ?? el.val())
    if (opts.disabled) el.odisable(true)
  }).css(opts.style || {})

  const w = cWrap(opts, [
    me,
    qc(
      'i.fa.text-item-icon',
      html(opts.popUp ? opts.iconCode || iconCodes.magnifier : iconCodes.caretDown)
    ).addClass(opts.popUp ? 'popup' : 'combo-icon')
  ])
    .addClass('ow-textbox')
    .addClass('ow-combo-wrap')
    .addClass('text-icon-after')

  w.input = me
  me.wrap = () => w

  const _rs = rs({ label: opts.label }, w)
  _rs.input = me

  me.rs = () => _rs
  w.rs = () => _rs

  return opts.noWrapper ? w : _rs
}

const qCombo5 = opts => {
  const x = combo5(opts)
  x.input.wrap = x.input.wrap || (() => x.input?.wrapper || x.input)
  x.input.rs = x.input.rs || (() => x)
  return x.input
}

const currentStore5 = opts =>
  combo5(opts)
    .input.on('init', (e, el) => {
      el.opts = opts
      ow5.controls.currentStore(el, opts)
    })
    .attr({ 'data-ctl-type': 'current-store' })
    .rs()

const datetime5 = opts => {
  const input = qc('input.datetime')
    .on('ow-dropdown-open', () => {
      const v = input.val()
      if (v) input.el.calendar?.build(v.getFullYear(), v.getMonth())
    })
    .on('init', (e, el) => {
      el.opts = opts
      ctlType(el)
      input.val(opts.value ?? input.val())
      if (opts.disabled) el.odisable(true)
      return el
    })

  input.opts = opts

  applyAttrsAndClasses(input)
  dataBind(input)

  const w = cWrap(opts, [
    input,
    qc('i.fa.text-item-icon.i-time', html(iconCodes.clock)),
    qc('i.fa.text-item-icon.date-icon', html(iconCodes.calendar))
  ])
    .addClass('ow-textbox')
    .addClass('ow-date-wrap')
    .addClass('ow-time-wrap')
    .addClass('text-icon-after')

  const _rs = rs({ label: opts.label }, w)

  input.wrap = () => w
  input.wrapper = w
  w.rs = () => _rs
  input.rs = () => _rs

  w.input = input
  _rs.input = input

  return opts.noWrapper ? w : _rs
}

const time5 = function (opts) {
  const input = qc('input.time').on('init', (e, el) => {
    el.opts = opts
    ctlType(el)
    input.val(input.val())
    if (opts.disabled) el.odisable(true)
    return el
  })
  input.opts = opts

  applyAttrsAndClasses(input)
  dataBind(input)

  const w = cWrap(opts, [
    input,
    qc('i.fa.text-item-icon.clock-icon.i-time', html(iconCodes.clock)).on('click', e => {
      const { el } = input
      if (el) {
        el.focus()
        if (el.dropdownOpen) el.$dropdown.close()
        else {
          if ($(el).hasClass('static-text') || el.disabled) return
          ow.openDropDownFactory(el, ow.timeDropDownOpts())()
        }
        return common.killEvent(e)
      }
    })
  ])
    .addClass('ow-textbox')
    .addClass('ow-time-wrap')
    .addClass('text-icon-after')

  input.wrap = () => w

  const _rs = rs({ label: opts.label }, w).props({ input })
  input.rs = () => _rs

  return opts.noWrapper ? w : _rs
}

const date5 = function (opts) {
  const input = qc('input.date')
    .on('ow-dropdown-open', () => {
      const v = input.val()
      if (v) {
        input.el.calendar.build(v.getFullYear(), v.getMonth())
      }
    })
    .on('init', (e, el) => {
      el.opts = opts
      ctlType(el)
      input.val(opts.value ?? input.val())
      if (opts.disabled) el.odisable(true)
      return el
    })
  input.opts = opts

  applyAttrsAndClasses(input)
  dataBind(input)

  const w = cWrap(opts, [input, qc('i.fa.text-item-icon.date-icon', html(iconCodes.calendar))])
    .addClass('ow-textbox')
    .addClass('ow-date-wrap')
    .addClass('text-icon-after')

  input.wrap = () => w

  const _rs = rs(
    {
      label: opts.label
    },
    w
  ).props({ input })
  input.rs = () => _rs

  return opts.noWrapper ? w : _rs
}

const check5 = function (opts) {
  let anchor = qc('a.ow-check', opts.labelRight ? opts.label : '')
    .attr({ href: '#' })
    .on('init', (e, el) => {
      el.opts = opts
      ctlType(el)
      if (opts.disabled) el.odisable(true)
    })

  const me = anchor

  me.opts = opts

  dataBind(me)
  applyAttrsAndClasses(me)

  qCheck(me).addClass('check5')
  if (opts.labelBold) anchor.css({ fontWeight: 'bold' })

  var w = cWrap(opts, anchor).addClass('ow-check-wrap')
  me.wrap = () => w
  me.wrapper = w
  w.input = me

  const _rs = rs(
    {
      label: opts.labelRight ? '' : opts.label
    },
    w
  )
  w.rs = () => _rs
  me.rs = () => _rs

  _rs.input = me

  if (opts.labelBold) _rs.children[0].css({ fontWeight: 'bold' })

  return opts.noWrapper ? w : _rs
}

const text5 = function (opts) {
  const input = qc({
    tag:
      opts.multiline || opts.edType === 'textarea' || opts.ctlType === 'textarea'
        ? 'textarea'
        : 'input'
  }).on('init', (e, el) => {
    ctlType(el)
    input.val(opts.value ?? input.val())
    if (opts.disabled) el.odisable(true)
  })

  input.opts = opts
  dataBind(input)
  applyAttrsAndClasses(input)

  if (opts.maxLength) input.attr({ maxLength: opts.maxLength })

  var w = cWrap(opts, input).addClass(
    'ow-textbox' + (opts.multiline || opts.ctlType === 'textarea' ? ' ow-textarea-wrap' : '')
  )

  input.wrapper = w
  input.wrap = () => w
  w.input = input

  const _rs = rs(
    {
      label: opts.label
    },
    w
  ).props({ input })

  input.rs = () => _rs
  w.rs = () => _rs

  return opts.noWrapper ? w : _rs
}

const PictureBox4 = function (el, opts) {
  el.opts = opts
  opts.edType = 'picturebox'
  ow4.controls.BasicEd(el, opts)
  el.val(el.val())
  return el
}

const PictureBox5 = function (el, opts) {
  el.opts = opts
  opts.edType = 'picturebox'
  ow5.controls.BasicEd(el, opts)
  return el
}

const qFileUpload5 = function (opts) {
  const input = qc('input.file-upload')
    .attr({ 'data-ctl-type': 'fileUpload' })
    .on('init', (e, el) => {
      el.opts = opts
      el.opts.ctlType = 'fileUpload'
      ctlType(el)
      input.val(input.val())
      return el
    })

  input.opts = opts
  dataBind(input)
  applyAttrsAndClasses(input)

  const w = cWrap(opts, input).addClass('ow-textbox')
  const _rs = rs(
    {
      label: opts.label
    },
    w
  )

  w.input = input
  _rs.input = input
  input.wrap = () => w
  input.rs = () => _rs
  w.rs = () => _rs

  return input
}

const buttonBar5 = opts => {
  let attrs = Object.assign({}, opts.attrs || {})
  if (Array.isArray(opts)) opts = { left: opts }
  return qc('div', attrs, [
    qc('span.buttonset_left', opts.left || []),
    qc('span.buttonset_right', opts.right || [])
  ])
}

const button5 = opts => {
  if (typeof opts === 'string') {
    opts = { name: opts }

    if (opts.name === 'save') {
      opts.command = 'save'
      opts.label = typeof __ !== 'undefined' ? __('Save') : 'Save'
      opts.class = 'green-button'
    } else if (opts.name === 'close') {
      opts.command = 'close'
      opts.label = typeof __ !== 'undefined' ? __('Close') : 'Close'
      // opts.class = "green-button"
    }
  }
  opts.label = opts.label ?? __(opts.name)
  opts.command = opts.command || opts.name
  opts.class = (opts.class || '') + '.k-button.command-button' + (opts.icon ? '.icon-left' : '')

  return qc(
    'button' + (opts.id ? '#' + opts.id : '') + '.' + opts.class,
    opts.icon ? [icon5(opts.icon), opts.label] : [opts.label]
  )
    .attr({
      'data-command': opts.command,
      'data-target-ref': opts.targetRef || opts.dsName
    })
    .on('init', (e, el) => {
      el.opts = opts
      ow5.controls.commandButton(el, opts)
    })
}

const buttonOpts = name =>
  ({
    select: {
      label: __('Select'),
      name: 'select',
      icon: 'check',
      classes: 'icon-left',
      command: 'select'
      //, classes: 'green-button'
    }
  }[name])

const commandButton = (opts, targetRef) => {
  opts = typeof opts === 'string' ? buttonOpts(opts) ?? { name: opts } : opts

  opts.command = opts.command ?? opts.name
  opts.label = opts.label ?? __(opts.name ?? opts.command)

  if (targetRef) opts.targetRef = targetRef

  var icon = opts.icon
  if (typeof icon === 'string')
    icon = qc('i.fa.fa-' + icon.replace('fa fa-', ''), { 'aria-hidden': 'true' })

  return qc('button.k-button.ow-button.command-button', icon ? [icon, opts.label] : opts.label)
    .props({ opts })
    .addClass((opts.classes ?? '') + (opts.class ?? ''))
    .attr(opts.attrs ?? {})
    .attr({
      id: opts.id,
      'data-command': opts.command || opts.name,
      'data-target-ref': opts.targetRef || opts.dsName || ''
    })
    .on('init', (e, el) => {
      el.opts = opts
      ow4.controls.commandButton(el, opts)
    })
    .on('click', e => opts.onClick?.(e))
}

/**
 * grid5 - qc maker for grid5
 * @param {Object} opts - Cmp props and grid opts
 * @param {function} builder - the gridInit function, sets all the opts before calling ctlType.grid5
 * @param {string} dsName - dc for use as child grid
 * @param {string} fieldName - for use as child grid
 * @returns Cmp
 */
const grid5 = opts => {
  let attrs = Object.assign(
    {
      'data-field-for': opts.dsName,
      'data-field': opts.fieldName
    },
    opts.attrs || {}
  )
  if (opts.id) attrs.id = opts.id

  const props = Object.assign({ tag: 'div.ow-grid.fit' }, opts)

  if (opts.onInit) delete opts.onInit

  if (opts.init && opts.builder) {
    console.error('Unexpected condition.  Use of grid5 builder and init()')
  }

  if (!props.init)
    props.init = g => {
      g.opts = opts
      opts.builder?.(g)
      ctlType(g)
      return g
    }

  props.attrs = attrs
  return qc(props).props({ opts }).removeClass('owauto')
}

const ctl5 = opts => {
  opts.ctlType = opts.ctlType || opts.edType

  if (opts.edType && ow.dev) {
    console.error('edType is not a valid opts for qCtl5')
    debugger
  }

  if (opts.ctlType === 'check') return check5(opts)
  if (opts.ctlType === 'combo') return combo5(opts)
  if (opts.ctlType === 'datetime') return datetime5(opts)
  if (opts.ctlType === 'date') return date5(opts)
  if (opts.ctlType === 'time') return time5(opts)
  if (opts.ctlType === 'text') return text5(opts)
  if (opts.ctlType === 'textarea') return text5(opts)
  if (opts.ctlType === 'current-store') return currentStore5(opts)

  if (!opts.tag) opts.tag = 'input'

  const me = qc(opts).on('init', () => {
    me.el.opts = me.el.opts || opts
    ctlType(me.el)

    if (me.el.opts.value !== undefined) {
      me.el.val(me.el.opts.value, undefined, true)
    }
  })

  me.opts = opts
  dataBind(me)
  applyAttrsAndClasses(me)
  me.removeClass('owauto')

  if (opts.ctlType === 'basiced') {
    me.addClass('basic-ed')
    const w = me // cWrap4(opts, me)
    me.wrap = () => w
    me.wrapper = w
    w.input = me

    const _rs = rs({ label: opts.labelRight ? '' : opts.label }, w)
    w.rs = () => _rs
    me.rs = () => _rs

    _rs.input = me

    return opts.noWrapper ? w : _rs
  }

  const w = cWrap(opts, me)
  me.wrap = () => w
  me.wrapper = w
  w.input = me

  const _rs = rs(
    {
      label: opts.labelRight ? '' : opts.label
    },
    w
  )
  w.rs = () => _rs
  me.rs = () => _rs

  _rs.input = me

  return opts.noWrapper ? w : _rs
}

const qCtl5 = opts => {
  if (opts.label === undefined) opts.label = __(opts.name ?? opts.fieldName ?? 'No name')

  if (opts.ctlType === 'currency') {
    opts.classes = (opts.classes ?? '') + ' currency'
    opts.ctlType = 'text'
  } else if (opts.ctlType === 'float') {
    opts.classes = (opts.classes ?? '') + ' float'
    opts.ctlType = 'text'
  }
  if (opts.ctlType === 'int') {
    opts.classes = (opts.classes ?? '') + ' int'
    opts.ctlType = 'text'
  }

  const x = ctl5(opts)
  if (!x.input) x.input = x
  x.input.wrap = x.input.wrap || (() => x.input?.wrapper || x.input)
  x.input.rs = x.input.rs || (() => x)
  x.input.wrap().rs = x.input.wrap().rs || (() => x.input.rs())
  return x.input
}

// from form-helper .ed()
const getEdType = opts => {
  if (opts.edType) return opts.edType
  if (opts.templ) return opts.templ

  let key = opts?.schema?.dataType || opts?.schema?.schema?.dataType || 'string'

  let templType = {
    bit: 'check',
    boolean: 'check',
    datetime: 'date',
    date: 'date',
    int: 'int',
    integer: 'int',
    float: 'float',
    decimal: 'float'
  }[key]

  return templType || 'text'
}

function assignHeaderBtnIconProp(option) {
  if (option.icon.display !== false) {
    option.icon.display = true
    option.icon.align = option.icon.align || 'left'
    option.icon.color = option.icon.color || ''
  }
  return option
}

function assignFooterBtnIconProp(option) {
  if (option.icon.display === false) return option

  if (option.icon.align || option.icon.color || option.icon.name) {
    option.icon.display = true
  }

  if (option.icon.display) {
    option.icon.align = option.icon.align || ''
    option.icon.color = option.icon.color || ''
    option.icon.name = option.icon.name || ''
  }

  return option
}

const icon5 = opts =>
  qc('i').attr({
    class: opts.name + (opts.display === false ? ' hidden' : ''),
    'aria-hidden': 'true'
  })

const icon4 = (name = 'icon', display) =>
  qc('i.' + name + (display === false ? '.hidden' : '')).attr({ 'aria-hidden': 'true' })

const renderIcon = icon =>
  icon.display && icon.name ? qc('i.' + icon.name).attr({ 'aria-hidden': 'true' }) : []

function renderButtonHtml(options) {
  if (typeof options === 'string') options = { type: options, command: options }

  if (options) {
    options.name = options.name || humanize(options.type)
    options.type = options.type || options.name

    // Button basic settings
    if (typeof options.class === 'string') options.class = options.class.split(' ')

    options.class = options.class || []

    options.id = options.id || ''
    options.align = options.align || ''
    options.icon = options.icon || { name: '' }
    options.command = options.command || ''

    if (options.align) options.class.push('align-' + options.align)
    if (options.color) options.class.push(options.color + '-button')

    if (options.icon.display && options.icon.name) {
      options.icon.align = options.icon.align || 'left'
      options.icon.color ? options.class.push('icon-' + options.icon.color) : null
      options.icon.align ? options.class.push('icon-' + options.icon.align) : null
    }
  }

  options.content = options.content || [renderIcon(options.icon), options.name]
  options.class.push('k-button')

  if (options.command) options.class.push('command-button')

  let buttonAttr = {}
  buttonAttr['class'] = options.class.join(' ') // Set class attr
  options.id !== '' ? (buttonAttr['id'] = options.id) : null // Set id attr
  options.command ? (buttonAttr['data-command'] = options.command) : null // Set data-command attr
  options.targetRef ? (buttonAttr['data-target-ref'] = options.targetRef) : null // Set data-target-ref attr

  let buttonTag = qc('button', buttonAttr, options.content)
  if (options.command) buttonTag.on('init', (e, el) => ow4.controls.commandButton(el, el.opts))

  if (options.label && options.tag !== 'button') {
    let textBoxTag = []

    // E.g. '<label class="resource_label">UDA</label>'
    let labelTag = qc('label.resource_label', options.label)

    // E.g '<div class="k-textbox"><input type="text" placeholder="UDA"></div>'
    if (options.displayTextBox)
      textBoxTag = qc(
        'div.k-textbox',
        qc('input', {
          type: 'text',
          id: options.textBoxId,
          placeholder: options.label
        })
      )

    // E.g. '<span role="presentation"><button..></button></span>'
    let spanTag = qc('span', [textBoxTag, buttonTag])
      .css({ display: 'inline-flex' })
      .attr({ role: 'presentation' })

    // E.g. '<div class="resource_set"><label ...>...</label><span ...>...</span></div>'
    let containerTag = qc('div.resource_set', [labelTag, spanTag])

    return containerTag
  }

  return buttonTag
}

const generateButtonType = (arrButtons, type, dsName) => {
  const value = []

  if (!Array.isArray(arrButtons)) return generateButtonType([arrButtons])

  // Generate all required button type
  arrButtons.forEach(option => {
    let dataCommand = ''
    let isAcceptedType = true
    let buttonColor = ''

    // if (option === 'advanced') return
    if (option === 'quick-search') return

    // if it's already a Cmp object
    const isCmp = typeof option === 'object' && 'render' in option
    if (isCmp) return value.push(option)

    if (option.html) {
      value.push(html(option.html))
      return
    }

    if (typeof option === 'object' && option.type === undefined)
      option.type = option.html ? 'html' : 'custom'

    if (typeof option === 'string')
      option = {
        name: humanize(option),
        type: option,
        command: option,
        class: option === 'save' ? 'green-button' : undefined
      }

    option.targetRef = option.targetRef || dsName
    option.icon = option.icon || {}

    option.type = option.type.toLowerCase()

    // If button type is match with standard support buttons. We will set the default behaviour of button.
    // If icon.display is undefined, we will set the default configuration for button at first.
    if (type === 'header') {
      if (option.type.match(/new/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-plus'
        dataCommand = 'new'
      } else if (option.type.match(/edit/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-pencil'
        dataCommand = 'edit'
      } else if (option.type.match(/delete/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-trash'
        dataCommand = 'delete'
      } else if (option.type.match(/copy/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-clone'
        dataCommand = 'copy'
      } else if (option.type.match(/print/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-print'
        dataCommand = 'print'
      } else if (option.type.match(/email/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-envelope'
        dataCommand = 'email'
      } else if (option.type.match(/refresh/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-refresh'
        dataCommand = 'refresh'
      } else if (option.type.match(/advanced/i) !== null) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-caret-down'
        dataCommand = 'advanced'
      } else if (option.type.match(/custom/gi)) {
        option = assignHeaderBtnIconProp(option)
      } else if (option.type.match(/current-store-filter/i)) {
        value.push(
          basicEd({
            id: 'txtcurrentStore',
            dsName,
            fieldName: 'StoreID',
            edType: 'current-store',
            noWrapper: true,
            noLabel: true,
            isFilterControl: true
          })
        )
        isAcceptedType = false
      } else if (option.type.match(/current-store/i)) {
        option.noWrapper = true
        option.noLabel = true
        option.edType = 'current-store'
        option.dsName = dsName
        value.push(basicEd(option))
        isAcceptedType = false
      } else if (option.type === 'html') {
        value.push(html(option.html))
        isAcceptedType = false
      } else if (option.type.match(/divider/i)) {
        value.push(qc('label', '|'))
        isAcceptedType = false
      }
    } else if (type === 'footer') {
      // If button type is match with standard support buttons. We will set the default behaviour of button.
      // If icon.display is undefined, we will set the default configuration for button at first.
      if (option.type.match(/add|add-row/i)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-plus-circle'
        }

        dataCommand = option.command || option.type
        isAcceptedType = true
      } else if (option.type.match(/add|add row/i)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-plus-circle'
        }

        dataCommand = option.command || 'add'
        isAcceptedType = true
      } else if (option.type.match(/clear|clear value|clean/gi)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'black'
          option.icon.name = option.icon.name || 'fa fa-eraser'
        }

        dataCommand = 'clear'
        isAcceptedType = true
      } else if (option.type.match(/excel|export|share/gi)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-share'
        }

        dataCommand = 'export'
        isAcceptedType = true
      } else if (option.type.match(/select/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'green'
        dataCommand = 'select'
        isAcceptedType = true
      } else if (option.type.match(/save/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'green'
        dataCommand = 'save'
        isAcceptedType = true
      } else if (option.type.match(/delete/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'red'
        dataCommand = 'delete'
        isAcceptedType = true
      } else if (option.type.match(/close/gi)) {
        option = assignFooterBtnIconProp(option)

        dataCommand = 'close'
        isAcceptedType = true
      } else if (option.type.match(/cancel/gi)) {
        option = assignFooterBtnIconProp(option)

        dataCommand = 'cancel'
        isAcceptedType = true
      } else if (option.type.match(/custom/gi)) {
        option = assignFooterBtnIconProp(option)

        isAcceptedType = true
      }
    }

    // If option.icon.display set to false, we will destruct the icon object
    if (option.icon.display === false) {
      delete option.icon.color
      delete option.icon.name
      delete option.icon.align
    }

    // If color or command is not defined, then we will follow the default color and command for standard button
    option.color = option.color === undefined ? buttonColor : option.color
    option.command = option.command === undefined ? dataCommand : option.command

    // Set class for button
    if (typeof option.class === 'string') option.class = [option.class]

    option.hidden ? option.class.push('hide') : null

    if (!option.name && option.type) {
      option.name = humanize(option.type)
    }
    if (option.name) option.name = __(option.name)

    // Only accept those standard button, any customised button need to
    // configure using option object with type: 'custom'
    if (isAcceptedType) value.push(renderButtonHtml(option))
  })

  return value
}

const button4 = (opts, context = 'header', dsName) => generateButtonType([opts], context, dsName)[0]

const headerBar = (opts, dsName) => {
  // const toCode = () => 'headerBar(' + stringify(opts) + (dsName ? ", '" + dsName + "'" : '') + ')'

  if (Array.isArray(opts) && opts.length === 0) opts = null
  if (!opts) opts = ['new', 'edit', 'delete', 'divider', 'refresh']

  const r = qc('div.ow-buttonstrip', generateButtonType(opts, 'header', dsName)) // , { toCode })

  return r
}

const footerBar = (opts, dsName) => {
  // const toCode = () => 'footerBar(' + stringify(opts) + (dsName ? ", '" + dsName + "'" : '') + ')'

  if (Array.isArray(opts) && opts.length === 0) opts = null
  if (!opts) opts = { right: ['close'] }

  const r = qc('div', [
    qc('div.buttonset_left', generateButtonType(opts.left || [], 'footer', dsName)),
    qc('div.buttonset_right', generateButtonType(opts.right || [], 'footer', dsName))
  ])

  // r.toCode = toCode

  return r
}

const filterPanel = (opts, content) => {
  content = Array.isArray(content) ? content : [content]

  const r = qc('div.filter-panel.enter-key-handler', [
    ...content,
    commandButton({
      type: 'filter',
      label: __('Filter'),
      command: 'filter-change',
      class: ['filter-button'],
      name: 'Filter',
      targetRef: opts.dsName
    }).css({ display: opts.hideButton ? 'none' : undefined })
  ]).on('init', el => {
    el.opts = opts
    ow4.controls['enter-key-handler'](el, opts)
    opts.show === false && $(el).hide()
  })

  return r
}

const basicEd = opts => {
  const { schema = {}, name, attrs = {} } = opts

  if (opts.options) {
    console.warn("Please don't use opts.options in basicEd")
    Object.assign(opts, opts.options)
    delete opts.options
  }

  let fieldName = schema.fieldName || opts.fieldName || name
  opts.fieldName = fieldName

  opts.label = opts.label ?? __(fieldName)

  opts.edType = getEdType(opts)

  attrs.id = attrs.id || opts.id

  let fn = el => {
    el.opts = opts
    ow4.controls.BasicEd(el, opts)
    if (opts.value !== undefined) el.val?.(opts.value, undefined, true)
    if (opts.disabled) el.odisable(true)
  }

  let input,
    w,
    _rs,
    label = opts.label

  if (opts.edType === 'check') {
    if (opts.labelRight !== false) {
      label = ''
    }
    input = qc(
      'a.ow-check' + (opts.value ? '.on ' : '') + (opts.noLabel ? '.ow-check-after' : ''),
      opts.labelRight !== false ? opts.label : ''
    )
      .attr({ href: '#' })
      .on('init', (e, el) => {
        el.opts = opts
        fn(el)
        input.val = (...args) => el.val(...args)
        // w.val = (...args) => el.val(...args)
      })

    qCheck(input)
  } else if (
    opts.edType === 'checklistbox' ||
    opts.edType === 'checklisttree' ||
    opts.edType === 'picturebox' ||
    opts.edType === 'weekdays' ||
    opts.edType === 'months' ||
    opts.edType === 'radio'
  ) {
    input = qc('div').on('init', (e, el) => fn(el))
  } else {
    input = qc(opts.multiline || opts.edType === 'textarea' ? 'textarea' : 'input')
      .attr({ type: 'text' })
      .on('init', (e, el) => fn(el))

    if (opts.maxLength) input.attr({ maxLength: '' + opts.maxLength })
  }

  input.addClass('basic-ed')
  if (opts.width) input.addClass(opts.width + '')

  Object.assign(input, {
    val(...args) {
      if (!this.el) return
      if (this.el.val === HTMLElement.prototype.val) this.el.val = ow5.ctlTypes.default // prevents infinite loops
      return this.el.val(...args)
    },
    populate(...args) {
      return this.el.populate(...args)
    },
    odisable(...args) {
      return this.el.odisable(...args)
    }
  })

  input.opts = opts
  dataBind(input)
  applyAttrsAndClasses(input)

  w = input

  if (opts.edType === 'text' || opts.edType === 'textarea') {
    w = cWrap4(opts, input).addClass('ow-textbox')

    if (opts.multiline || opts.edType === 'textarea') {
      w.addClass('ow-textarea-wrap')
      input.css({ width: '100%', outline: 0, padding: '4px', boxSizing: 'border-box' })
    }
  }

  _rs = rs({ label, type: opts.edType }, opts.addAfter ? [w, html(input.opts.addAfter)] : [w])

  if (opts.edType === 'check') _rs.addClass('check')

  input.wrap = () => w
  input.rs = () => _rs
  w.input = input
  _rs.input = input
  w.rs = () => _rs

  return opts.noWrapper ? w : _rs
}

const qBasicEd = opts => {
  const x = basicEd(opts)

  if (!x.input) x.input = x
  x.input.wrap = x.input.wrap || (() => x.input?.wrapper || x.input)
  x.input.rs = x.input.rs || (() => x)
  // x.input.wrap().rs = () => x.input.rs()
  return x.input
}

/**
 * basicEd filterControl, calls basic ed
 * @param {*} opts
 * @returns
 */
const filterControl = opts => {
  if (opts.options) {
    Object.assign(opts, opts.options)
    delete opts.options
  }

  if (!opts.templ && opts.schema?.dataType === 'bit') {
    opts.edType = 'lookup'
    opts.list = ['', 'true', 'false']
  }

  opts.isFilterControl = true
  const r = basicEd(opts)
  r.input.addClass('ow-filter-control')
  return r
}

const qFilterControl = opts => {
  if (!opts.templ && opts.schema && opts.schema.dataType === 'bit') {
    opts.edType = 'lookup'
    opts.list = ['', 'true', 'false']
  }
  opts.isFilterControl = true

  return qBasicEd(opts).addClass('ow-filter-control')
}

////////////////////////////////////////////////////////
// Corrected patterns qBasicEd
////////////////////////////////////////////////////////

// const edTypeTagMap = {
//   check: 'a.ow-check',
//   radio: 'div.ow-radio',
//   checklistbox: 'div',
//   checklisttree: 'div',
//   weekdays: 'div',
//   months: 'div'
// }

//const tagFromOpts = ({ tag, edType }) => tag || (edType && edTypeTagMap[edType]) || 'input'
//
// const qBasicEd = opts => {
//   const me = qc(tagFromOpts(opts)).addClass('basic-ed')
//   delete opts.tag
//   me.opts = opts
//   applyAttrsAndClasses(me)
//   dataBind(me)

//   if (opts.edType === 'check') qCheck(me)

//   if (me.tag === 'input') me.attr({ type: 'text' })

//   opts.showOnlySelected = false
//   me.on('init', (e, el) => {
//     el.opts = opts
//     if (opts.init) opts.init(el)
//     ow4.controls.BasicEd(el, el.opts)
//   })

//   const _w = cWrap4(me.opts, me)
//   me.wrap = () => _w
//   _w.rs = () => me.rs()

//   let _rs
//   // me.rs = () => _rs || (_rs = rs(me.opts, _w))
//   me.rs = () => _rs || (_rs = rs(me.opts, me)) // in ow4 we don't wrap ourselves

//   return me
// }
//
// var basicEd = opts => {
//   const me = qBasicEd(opts)
//   const result = opts.noWrapper ? me.wrap() : me.rs()
//   result.input = me
// }

const qCombo4 = opts => {
  opts.edType = 'combo4'

  const me = qBasicEd(opts)
  let icon = qc('i.fa.text-item-icon')
    .addClass(opts.popUp ? 'popup' : 'combo-icon')
    .kids(html(opts.popUp ? opts.iconCode || iconCodes.magnifier : iconCodes.caretDown))

  me.wrap().addClass('ow-textbox ow-combo-wrap text-icon-after').children.push(icon)
  return me
}

const qcControls = {
  humanize,
  dataBind,
  cmpCollection,
  textnode,
  qc,
  html,
  toStyleSheet,
  camel,
  unCamel,

  rs,
  ctlWrapClasses,
  cWrap,

  column,
  columnSet,
  formColumn,
  filterPanel,
  tabStrip,

  icon4,
  button4,
  basicEd,
  PictureBox4,

  currentStore5,
  ctl5,
  qCtl5,
  date5,
  datetime5,
  check5,
  text5,
  combo5,
  qCombo5,
  PictureBox5,
  qFileUpload5,
  buttonBar5,
  button5,
  commandButton,
  headerBar,
  footerBar,
  filterControl,

  childGrid,
  fspGrid,
  grid5,
  qBasicEd,
  qFilterControl,

  qCombo4,

  qTreeView4,
  buildCell,
  qFloat4,

  treeGrid5
}

module.exports.qcControls = qcControls

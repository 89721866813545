const { _v } = require('../_v')
const {
  colsToKendoGridColumns,
  colsToKendoSchemaFields,
  ColumnResize,
  initColumnMenu,
  commonGrid
} = require('./grids4')

/**
 * ow4 childGrid
 *
 * @param {HTMLElement} el
 * @param {object} opts - OW Grid options
 * @param {object} opts.dc - the parent dataController, if not set it'll use qTop.el
 * @param {string} opts.lineNumberField - the automatic Line numbering field usually 'LineNumber'
 * @returns Grid element
 */
exports.childGrid = function (el, opts) {
  el = $(el)[0] // in case of incorrect jquery argument
  var o = el
  var $top = el.myWin()
  opts.fieldName = opts.fieldName || opts.name // for binding as basicEd
  // set options
  el.opts = opts
  opts.viewdata = opts.viewdata || $top.viewdata
  el.dc = opts.dc || el.myWin()[0] // set the parent dataController

  opts.iden = el.id

  opts.viewdata.winpref.grids ??= {}
  opts.userSettings = opts.viewdata.winpref.grids[opts.iden] ??= {}

  el.deletedRows = []

  if (opts.hasFilters === undefined) opts.hasFilters = false // default for childGrid is false.

  // pull out the aggregates
  var ags = []
  var groupAgs = []
  opts.cols ??= []
  opts.cols.forEach(col => {
    if (col.footer === 'sum') {
      var ag = { field: col.field, aggregate: 'sum' }
      ags.push(ag)
    }
    if (col.groupFooter === 'sum') {
      groupAgs.push({ field: col.field, aggregate: 'sum' })
      ags.push({ field: col.field, aggregate: 'sum' })
    }
  })

  if (opts.dataSourceGroup) {
    if (!Array.isArray(opts.dataSourceGroup)) {
      opts.dataSourceGroup = [opts.dataSourceGroup]
    }
  }

  var gridCols = colsToKendoGridColumns(opts.cols, el, opts)
  var schemaFields = colsToKendoSchemaFields(opts.cols, el, opts)

  opts.buttonColumn = opts.buttonColumn || ['edit', 'delete']

  $(el).on('row-delete', function (e, rowIndex, data) {
    if (rowIndex === -1) return
    var innerDelete = function () {
      if (data && !data._new) {
        if (typeof data.Deleted !== 'undefined') {
          data.Deleted = true
        }
        el.deletedRows.push(data)
      }

      k.removeRow($row)
      $(el).trigger('ow-grid-change', [data, $row, 'delete'])
    }

    var $row = $(el).find('tbody tr:eq(' + rowIndex + ')')
    var isNewBlank = el.isNewBlank(data)
    if (isNewBlank) {
      innerDelete()
    } else {
      ow0.confirm(
        opts.title || (opts.viewdata && opts.viewdata.name) || opts.name,
        __('Are you sure you want to delete this record?'),
        r => r && innerDelete()
      )
    }
    return false
  })
  $(el).on('row-new', function (e, rowIndex, data, target) {
    if (target) {
      if ($(this).data('comp') !== target) return
    }
    arriveInGrid(true)
  })

  o.refreshField = function (rec, field) {
    var v = _v(rec, field)
    // field = field.split('.').join('_'); should we?
    rec.set(field, v !== null ? null : '')
    rec.set(field, v)
  }

  o.rowChanged = function (rec) {
    o.refreshField(rec, opts.id)
    // Object.keys(JSON.parse(JSON.stringify(rec))).forEach(function(f) {
    //     o.refreshField(rec, f); // forces the grid to acknowledge the changed field and update.
    // });
  }

  var buttonCol = {
    command: [],
    title: '',
    attributes: { class: 'command-cell no-tab' },
    width: 20
  }

  const propagateChanges = el => {
    $(el).trigger('blur') // required for arrow down up between rows.
    // $(el).trigger('focusout')
    $(el).trigger('change')
  }

  ;(opts.buttonColumn || []).forEach(function (btn) {
    if (typeof btn === 'string') btn = { type: btn, imageClass: 'k-' + btn }

    buttonCol.command.push({
      name: btn.name || btn.type + '-row',
      text: btn.text || '',
      imageClass: btn.imageClass || 'k-' + btn,
      iconClass: btn.iconClass || 'k-icon ',
      click(e) {
        e.preventDefault()
        var $tr = $(e.target).closest('tr')
        var data = k.dataItem($tr)
        // call handler
        $(el).trigger('row-' + btn.type, [$tr.index(), data, $tr])
        return false
      }
    })

    buttonCol.width = buttonCol.width + (btn.width || 14)
  })

  if (buttonCol.command.length) gridCols.push(buttonCol)

  function updateTableFocus() {
    // if it's another window, don't do anything
    if (!document.activeElement.myWin()?.is($top)) return

    // track row and cell index
    const inGrid = document.activeElement.closest('div.k-grid') === el

    var $row = $(document.activeElement).closest('tbody tr')
    if (inGrid && $row.length > 0) {
      var $cell = $(document.activeElement).closest('td')
      let prevState = ow0.clone(el.state)
      el.state.cellIndex = $cell.index()
      el.state.rowIndex = $row.index()
      if (prevState.rowIndex !== el.state.rowIndex) {
        // row changed
        if (prevState.rowIndex > -1) {
          var $prevRow = $(el).find('tbody tr:eq(' + prevState.rowIndex + ')')
          if ($prevRow.length) el.leaveRow($prevRow)
        }
        el.state.inNewRow = false
      }
    }
    // if we've left the grid and on same window, then we can leave the Row.
    if (!inGrid) {
      let prevState = ow0.clone(el.state)
      if (prevState.rowIndex > -1) {
        let $prevRow = $(el).find('tbody tr:eq(' + prevState.rowIndex + ')')
        // if this prevRow is newrow,
        if ($prevRow.length) el.leaveRow($prevRow)
      }
      el.state.inNewRow = false //should behave like inGrid. leaveRow eventually checked dataChanged. If data changed should reset
    }

    var x = $(el).find('table').is($(document.activeElement).closest('table'))
    if (x !== el.state.tableHasFocus) {
      el.state.tableHasFocus = x
      console.log('tablefocus changed:' + x, 'focus')
      if (x) {
        var isCmdButtonClicked = $(document.activeElement).is('span.k-icon')
        if (!isCmdButtonClicked) arriveInGrid() //skip if command button clicked
        $(el).find('table')[0].tabIndex = -1 // prevents it pausing on table on the way out.
      } else {
        leavingGrid()
        $(el).find('table')[0].tabIndex = 0 // prevents it pausing on table on the way out.
      }
    }
  }

  $top.on('focus', '*', function (e) {
    this === e.target && updateTableFocus(e.target)
  })

  // up arrow, down arrow
  $(el).on('focus', 'td *', function (e) {
    var k = $(el).data('kendoGrid')
    if (this === e.target)
      $(e.target).on('keyup', function (e) {
        if (e.which === 38) {
          // if it's an open combobox, leave
          if ($(e.target).closest('.k-combobox.k-state-border-down').length) return

          let $tr = $(e.target).closest('tr')
          let $cell = $(e.target).closest('td')
          let cellIndex = $cell.index()
          let h = $tr.height()
          let isFirstRow = $tr.is(':first-child')

          if (isFirstRow && opts.scrollable) {
            var currentScrollTop = $(el).find('.k-scrollbar.k-scrollbar-vertical').scrollTop()
            $(el)
              .find('.k-scrollbar.k-scrollbar-vertical')
              .scrollTop(currentScrollTop - 4 * h)

            var tries = 40
            const poll4Scroll = function () {
              tries--
              var $rows = $(el).find('table tbody tr[role=row]')
              var $nextRow
              if ($tr.is($rows.first())) {
                if (tries) setTimeout(poll4Scroll, 50)
                else {
                  console.log('time ran out')
                  o.focusCell($($(el).find('table tbody tr[role=row]:first').children()[cellIndex]))
                }
                return
              }
              var i = 0
              do {
                $nextRow = $($rows[i])
                i++
              } while ($tr.data('uid') !== $($rows[i]).data('uid') && i < $rows.length - 1)

              if (i > $rows.length - 1) return console.warn("can't find the previous row")
              // console.warn('took ' + 50*(40-tries) + ' index ' + (i-1));
              o.focusCell($($nextRow.children()[cellIndex]))
            }
            poll4Scroll()

            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false
          }

          if (isFirstRow && !opts.scrollable) return

          propagateChanges(e.target) // have to have this or the value doesn't update

          let prevRow = $tr.prev()
          let prevCell = $(prevRow.children()[$cell.index()])

          o.focusCell(prevCell)

          // needs timeout to allow editing cell to set the data in the model.
          setTimeout(() => {
            var data = k.dataItem($tr)
            var isNewBlank = el.isNewBlank(data)
            if (isNewBlank) {
              el.leaveRow($tr)
              var $r = k.tbody.find('tr:last')
              o.select($r)
              var $c = $($r.find('td')[$cell.index()])
              $c.trigger('focus')
            }
          }, 1)

          // prevent it from going to the kendo handler
          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          return false
        }

        if (e.which === 40) {
          // if it's an open combobox, leave
          if ($(e.target).closest('.k-combobox.k-state-border-down').length) return

          let $tr = $(e.target).closest('tr')
          let $cell = $(e.target).closest('td')
          let cellIndex = $cell.index()
          let h = $tr.height()

          let isLastRow = $tr.is(':last-child')
          if (isLastRow) {
            propagateChanges(e.target)
            // $(e.target).trigger('blur') // have to have this or the value doesn't update
            // $cell.trigger('blur')

            if (opts.scrollable) {
              //daemon -s

              //var currentScrollTop = $(el).find('.k-scrollbar.k-scrollbar-vertical').scrollTop()
              $(el)
                .find('.k-scrollbar.k-scrollbar-vertical')
                .scrollTop(currentScrollTop + 4 * h)
              // $(el).find('.k-scrollbar.k-scrollbar-vertical').scrollTop(currentScrollTop + h);

              let tries = 40
              const poll4Scroll = function () {
                tries--
                var $rows = $(el).find('table tbody tr[role=row]')
                var $nextRow
                if ($tr.is($rows.last())) {
                  if (tries) setTimeout(poll4Scroll, 50)
                  else console.warn('time ran out')
                  return
                }
                var i = $rows.length - 1
                do {
                  $nextRow = $($rows[i])
                  i--
                } while ($tr.data('uid') !== $($rows[i]).data('uid') && i >= 0)
                o.focusCell($($nextRow.children()[cellIndex]))
              }
              poll4Scroll()

              e.preventDefault()
              e.stopPropagation()
              e.stopImmediatePropagation()
              return false
            } else {
              o.focusCell($cell)
            }

            // needs timeout to allow editing cell to set the data in the model.
            setTimeout(() => {
              if (opts.disallowNewWhenExistingNewInvalid) {
                var isRequiredBlank = el.isRequiredBlank(k.dataItem($tr))
                if (isRequiredBlank) {
                  el.validate()
                  return
                }
              }
              var isNewBlank = el.isNewBlank(k.dataItem($tr))
              if (!isNewBlank) {
                if (opts.tabOutNewRow === false) {
                  return
                }
                if (opts.maximumRow && el.rowCount() >= opts.maximumRow) {
                  ow0.popWarning(__('Warning'), __('Reached maximum allowed rows.'), 3000)
                  return
                }
                el.addRow()
                var $c = $(k.tbody.find('tr:last').children()[$cell.index()])
                o.focusCell($c)
                el.state.inNewRow = true
              }
            }, 50)
          } else {
            propagateChanges(e.target)
            const $c = $($tr.next().children()[$cell.index()])
            o.focusCell($c)
          }
          e.preventDefault()
          e.stopImmediatePropagation()
          e.stopPropagation()
          return false
        }
      })
  })

  // oh dear... tabbing backward out of cell editor gives td focus again!
  $(el).on('focus blur', 'td:not(.no-tab) *', function (e) {
    if (this !== e.target) return

    $(e.target)
      .closest('tr')
      .find('td[role=gridcell]:not(.full-time-edit):not(.no-tab)')
      .attr('tabIndex', 0)
    $(el)
      .find(':focus')
      .closest('td[role=gridcell]:not(.full-time-edit):not(.no-tab)')
      .attr('tabIndex', -1)
  })

  function leavingGrid() {
    // console.log('LeavingGrid: ' + document.activeElement );
  }

  function arriveInGrid(clickedIn) {
    var k = $(el).data('kendoGrid')
    var $cell = k.tbody.find('td[role=gridcell]:first')

    if (!opts.editable) return
    if (opts.tabOutNewRow === false) return

    if (opts.disallowNewWhenExistingNewInvalid) {
      var selectedIndex = el.rowIndex()
      if (selectedIndex !== -1) {
        var $tr = el.rowByIndex(selectedIndex)
        var isRequiredBlank = el.isRequiredBlank(k.dataItem($tr))
        if (isRequiredBlank) {
          el.validate()
          return
        }
      }
    }
    var hasFirstCell = $cell.length !== 0

    if (!hasFirstCell || clickedIn) {
      // addLine
      // if clickedIn and editing a new row, don't
      if (el.state.inNewRow) console.log("new row already, don't  add another")
      else {
        if (opts.maximumRow && el.rowCount() >= opts.maximumRow) {
          ow0.popWarning(__('Warning'), __('Reached maximum allowed rows.'), 3000)
          return
        }
        el.state.inNewRow = true //reduce triple click might create multiple blank row
        el.addRow()
        setTimeout(() => {
          var $cell = k.tbody.find('tr:last td[role=gridcell]:not(.no-tab):first')
          o.focusCell($cell)
        }, 10)
      }
    } else if (!clickedIn) {
      // focus the first cell - change to previous cell - need to have grid manage last row,cell index?
      setTimeout(() => {
        if (!k.tbody.find('td[role=gridcell]:focus, td[role=gridcell] *:focus').length) {
          var $cell = k.tbody.find('td[role=gridcell]:not(.no-tab):first')
          o.focusCell($cell)
        }
      }, 10)
    }
  }

  $(el).on('click', '.k-grid-content', function (e) {
    var validTarget =
      $(e.target).is('.k-grid-content') || $(e.target).is('.k-virtual-scrollable-wrap')
    if (validTarget) {
      updateTableFocus(e.target)
      if (!el.state.tableHasFocus) arriveInGrid(true)
      else {
        // if clickedIn and editing a new row, don't
        if (el.state.inNewRow) {
          console.log("new row already, don't  add another")
          o.focusCell(k.tbody.find('tr:last td[role=gridcell]:not(.no-tab):first'))
        } else {
          if (opts.maximumRow && el.rowCount() >= opts.maximumRow)
            return ow0.popWarning(__('Warning'), __('Reached maximum allowed rows.'), 3000)

          el.state.inNewRow = true //reduce triple click might create multiple blank row
          el.addRow()
          setTimeout(
            () => o.focusCell(k.tbody.find('tr:last td[role=gridcell]:not(.no-tab):first')),
            10
          )
        }
      }
    }
    e.stopPropagation()
  })

  $(el).on('focus', 'table', function (e) {
    if (e.target !== this) return

    if (!$(e.target).is('table')) return updateTableFocus(e.target)

    if (el.state.tableHasFocus) return

    updateTableFocus(e.target)
  })

  function rowTabKeyDownHandler(e, isEmulate) {
    if (!$(this).is('td')) return

    if (e.which === 9) {
      propagateChanges(e.target)

      var g = el
      g.moveNextCell($(this).closest('tr').index(), $(this).index(), e.shiftKey, isEmulate)
    }
  }

  opts.editable = opts.viewdata.userRole?.CanWrite ? opts.editable : false
  var kOpts = {
    autoBind: true,
    dataSource: {
      data: opts.data || [],
      batch: true,
      pageSize: opts.scrollable && opts.scrollable.virtual ? opts.scrollable.pageSize || 20 : 99999
    },
    scrollable: opts.scrollable === undefined ? true : opts.scrollable,
    navigatable: true,
    reorderable: opts.reorderable === true ? true : false,
    filterable: opts.filterable === undefined ? false : opts.filterable, // filterable default is false; apply to some of the childgrid;
    sortable: opts.sortable !== undefined ? opts.sortable : false,
    resizable: opts.resizable === undefined ? true : opts.resizable,
    selectable: opts.selectable === undefined ? true : opts.selectable, //for multiple selection
    columnResize(e) {
      ColumnResize(e)
      const col = e.column.owGridCol
      opts.userSettings.cols[col.iden].width = Math.round(e.newWidth)
    },
    change() {
      var currentDataItem = k.dataItem(o.currentRow())
      var selectedRows = o.select()
      if (opts.change) opts.change(currentDataItem)
      el.disableBindBtns(selectedRows ? false : true)
      $(el).trigger('ow-grid-change', [currentDataItem, o.currentRow()]) //, selectedRows]);
    },
    editable: !opts.editable
      ? false
      : {
          createAt: 'bottom',
          mode: opts.editable === true ? 'incell' : opts.editable, // 'incell' or true
          confirmation: false
        },
    pageable: typeof opts.pageable === 'undefined' ? false : opts.pageable,
    dataBound() {
      if (el.disableBindBtns) el.disableBindBtns(true)

      // make the delete buttons and non-editable cells non tabbable
      $(el)
        .find('td:not(.command-cell):not(.full-time-edit) span:not(.read-only)')
        .closest('td')
        .attr('tabindex', 0)
        .removeClass('non-editable-cell')
        .removeClass('no-tab')
      $(el)
        .find('td span.read-only')
        .closest('td')
        .attr('tabindex', -1)
        .addClass('non-editable-cell')
        .addClass('no-tab')
      $(el).find('.no-tab *').attr('tabindex', -1) // tabindex for buttons in buttonCol

      if (!$(el).attr('id')) console.log('Grid needs id for column menu to work.')
      else if (opts.allowColumnMenu) initColumnMenu(el.myWin(), '#' + el.id, opts.allowSaveTemplate)

      if (opts.dataBound) opts.dataBound() // do not pass the kendo event object through!

      $(el).trigger('ow-grid-databound') // todo: add appropriate extra info

      if (opts.editable !== false) {
        $(el).find('*').off('keydown') // NO! what if somewhere else we need to have a keydown handler on the grid elements!?
        $(el).find('tr').on('keydown', '*', rowTabKeyDownHandler)

        $(el)
          .find('td[role=gridcell]:not(.full-time-edit):not(.no-tab):not(.non-editable-cell)')
          .on('focus', e => o.focusCell($(e.target)))
        $(el)
          .find('tr td[role=gridcell]:not(.full-time-edit):not(.no-tab):not(.non-editable-cell)')
          .attr('tabIndex', 0)
      }

      if (opts.expandAllGroup === false) setTimeout(() => o.collapseAll && o.collapseAll(), 200)
    },
    navigate() {
      el.indicateCurrent()
    },
    columns: gridCols
  }

  if (schemaFields)
    kOpts.dataSource.schema = {
      model: opts.id ? { fields: schemaFields, id: opts.id } : { fields: schemaFields }
    }

  if (ags.length) kOpts.dataSource.aggregate = ags

  if (groupAgs.length) {
    ;(opts.dataSourceGroup || []).forEach(g => (g.aggregates = groupAgs))
  }

  var k

  el.val = function (lines) {
    if (typeof lines !== 'undefined') {
      k.dataSource.data(lines)
      el.deletedRows = []
    }

    if (opts.lineNumberField) o.updateLineNumbers()

    var data = (k || $(el).data('kendoGrid')).dataSource.data()
    return JSON.parse(JSON.stringify(data))
  }

  el.readData = function (rec) {
    _v(rec, opts.fieldName, el.val()) //rec[opts.fieldName] = el.val();
    _v(rec, opts.fieldName + 'Deleted', el.deletedRows) //rec[opts.fieldName + 'Deleted'] = el.deletedRows;

    if (opts.filterRec) {
      var v = _v(rec, opts.fieldName)
      _v(rec, opts.fieldName, opts.filterRec(v))
    }

    if (opts.compositeGrid) {
      var allProps = Object.keys(rec)
      var propsAry = allProps.filter(el =>
        el.match(new RegExp(opts.name + '_[0-9]+(?!Deleted)', 'ig'))
      )

      let v
      if (propsAry.indexOf(opts.fieldName) === 0) {
        v = _v(rec, opts.fieldName)
        _v(rec, opts.name, [].concat(v))
      } else if (propsAry.indexOf(opts.fieldName) > 0) {
        v = _v(rec, opts.fieldName)
        _v(rec, opts.name, [].concat(v))
      }
    }
  }

  el.sum = function (fieldNameOrFunction, groupValue) {
    function groupFilter(g) {
      return g[groupValue.field] === groupValue.value
    }

    var f = fieldNameOrFunction
    if (typeof f === 'string') {
      f = function (d) {
        var v = d.fields[fieldNameOrFunction] // for calc fields
        v = typeof v === 'function' ? (v = v(d)) : d[fieldNameOrFunction]
        v = typeof v === 'number' ? v : parseFloat(v)
        v = isNaN(v) ? 0 : v
        return v || 0
      }
    }

    var data = $(el).data('kendoGrid').dataSource.data()
    if (groupValue) data = data.filter(groupFilter)

    return data.reduce((result, x) => result + (f(x) || 0), 0)
  }

  el.avg = function (fieldNameOrFunction) {
    var result = 0
    var count = 0
    var f = fieldNameOrFunction
    if (typeof f === 'string') {
      f = function (d) {
        var v = d.fields[fieldNameOrFunction] // for calc fields
        v = typeof v === 'function' ? (v = v(d)) : d[fieldNameOrFunction]
        v = typeof v === 'number' ? v : parseFloat(v)
        v = isNaN(v) ? 0 : v
        return v || 0
      }
    }

    $(el)
      .data('kendoGrid')
      .dataSource.data()
      .forEach(x => {
        result = result + (f(x) || 0)
        count++
      })
    return count ? result / count : 0
  }

  el.getFocusCol = function (data) {
    var result = 0
    var colHideCount = 0
    ;(opts.cols || []).some(function (c, index) {
      var edType = (c.basicEd && c.basicEd.edType) || c.edType
      if (c.gridCol == false) {
        colHideCount++
        return false
      }
      var isReadOnly = typeof c.readOnly === 'function' ? c.readOnly(data) : c.readOnly
      if (isReadOnly) return false

      if (c.basicEd && c.basicEd.popUp) return false

      if (edType === 'lookup-ajaxtextvalue' || edType === 'lookup') return false

      result = index
      return true
    })
    return result - colHideCount
  }

  o.updateLineNumbers = lineNumberField => {
    lineNumberField = lineNumberField || opts.lineNumberField || 'LineNumber'
    ;(k || $(el).data('kendoGrid')).dataSource
      .data()
      .forEach((x, i, ds) => (ds[i][lineNumberField] = i + 1))
  }

  el.read = function () {
    var itemsData = (k || $(el).data('kendoGrid')).dataSource.data()
    var result = { resVal: true, data: [], resErr: '' }
    for (var i = 0; i < itemsData.length; i++) {
      var item = itemsData[i]
      if (item.LineType !== '') {
        var validateObj = this.validate(item)
        if (validateObj.resVal) result.data.push(item)
        else {
          result.resVal = false
          result.resErr = validateObj.resErr
          break
        }
      }
    }
    return result
  }

  el.removeRow = function (data, $row) {
    if (data && !data._new) {
      if (typeof data.Deleted !== 'undefined') data.Deleted = true
      el.deletedRows.push(data)
    }
    k.removeRow($row)
    $(el).trigger('ow-grid-change', [data, $row, 'delete'])
  }

  $(el).kendoGrid(kOpts)
  commonGrid(el, opts)
  k = $(el).data('kendoGrid')

  k.prefsApplied = true

  o.collapseAll = () => k.tbody.find('tr.k-grouping-row').forEach(el => k.collapseGroup(el))
  o.expandAll = () => k.tbody.find('tr.k-grouping-row').forEach(el => k.expandGroup(el))

  if (opts.dataSourceGroup) k.dataSource.group(opts.dataSourceGroup)

  return o
}

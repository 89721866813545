module.exports.openChildView = viewdata => {
  if (viewdata.view) {
    const { parentView } = viewdata

    let i = 1
    do viewdata.uid = viewdata.parentView.qTop.el.id + '_modal' + i++
    while (!viewdata.parentView.qTop.find('#' + viewdata.uid))

    viewdata.view.ow = ow0
    const win = common.controls.viewWindow(viewdata, viewdata.parentView)

    parentView.qTop.css({ pointerEvents: 'none' })
    const callback = viewdata.callback
    viewdata.callback = function (...args) {
      parentView.qTop.css({ pointerEvents: undefined })
      return callback.call(this, ...args)
    }

    win.renderTo(viewdata.parentView.viewWrap.el)
    win.$top = win.qTop.$top
    common.loadForm(win.$top)

    viewdata.view.afterLoad?.()
  }
}

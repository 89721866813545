const { qc } = require('./cmp/cmp')

/**
 * sets removes disabled state and also
 * sets state on the div.resource_set if it has one.
 *
 * @param {HTMLElement|HTMLElement[]} el usually input
 * @param {boolean} val disabled or not
 */
const odisable = (el, val) => {
  if (Array.isArray(el)) return el.forEach(x => odisable(x, val))
  val = val !== false ? true : false
  val ? (el.disabled = val) : delete el.disabled

  qc(el)
    [val ? 'addClass' : 'removeClass']('ow-disabled')
    .attr({
      readonly: val ? 'readonly' : undefined,
      disabled: val ? 'true' : undefined
    })
    .props({ disabled: val })

  const wrap = el.parentElement
  wrap &&
    qc(wrap).hasClass('ow-ctl-wrap') &&
    (val ? qc(wrap).addClass('ow-disabled') : qc(wrap).removeClass('ow-disabled'))

  const rs = el.closest('.resource_set')
  rs && (val ? qc(rs).addClass('ow-disabled') : qc(rs).removeClass('ow-disabled k-state-disabled'))
}

module.exports = { odisable }

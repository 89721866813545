window.require = module => window[module] // hack for browserify excludes, couldn't get expose working

require('../../lib/dates')
require('./polyfills')

window.cacheUrl = 'v=' + new Date().getTime()

module.exports.common = {
  ...require('./_v'),
  ...require('./_id'),
  theme: require('./theme'),
  ...require('./toValueText'),
  ...require('./debounce'),

  wait: (ms = 5) => new Promise(resolve => setTimeout(resolve, ms)),
  hexTo36: b16 => {
    let s = b16.split('-').join('')
    let r = [],
      piece
    while (s.length) {
      piece = parseInt(s.slice(-9), 16) // last part first
      s = s.slice(0, -9)
      r.push(((s.length ? '000000' : '') + piece.toString(36)).slice(-7))
    }

    return r.reverse().join('')
  },
  ...require('./element-map'),
  ...require('./culture'),
  ...require('./set-culture'),
  dates: require('./dates'),
  ...require('./ctl-format'),
  ...require('./html-encode'),
  events: require('./events'),
  ...require('./killEvent'),
  ...require('./ajax'),
  nojquery: require('./no-jquery'),

  ...require('./odisable'),
  ...require('./icon-codes'),
  pop: require('./pop-box'),

  ...require('./controls/controls'),

  ...require('./ow7/ow7'),
  ...require('./ow5/ow5'),
  ...require('./ow4/ow4'),
  ...require('./ow0/ow0'),

  cmp: require('./cmp/cmp'),

  // Deprecated unbundled qc built ow4 and ow5 controls.
  qcControls: require('./qcControls/common-qc-controls').qcControls
}

const common = exports.common
common.toString = common.formatString

window.common = common
window.no$ = common.nojquery.no$
window.$ajax = (...args) => common.$ajax(...args)
window.cmp = common.cmp
window.qc = common.cmp.qc
window.pop = common.pop

document.body.addEventListener?.(
  'dragstart',
  e => (window.dragEl = e.target),
  true // capture
)

window?.addEventListener?.('resize', e =>
  no$(document.body)
    .find('.win-con')
    .forEach(el => common.cmp.qc(el).trigger('resize', { originalEvent: e }))
)

// turn off browser interference
document.body.addEventListener(
  'focus',
  e => {
    const el = e.target
    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
      el.setAttribute('autocomplete', 'off')
      el.setAttribute('spellcheck', 'false')
    }
  },
  true
)

// href:# -> prevent navigation
document.body.addEventListener(
  'mousedown',
  e => {
    if (e.target.tagName === 'A' && e.target.href && e.target.href.substr(-1) === '#')
      e.target.href = 'javascript:void(0)'
  },
  true
)

// wireUpView7.js
const cmp = require('../cmp/cmp')

const { collectionController7 } = require('./collectionController7')
const { shortcutKeyHandler } = require('./commandButton7')
const { dc7 } = require('./dc7')
const { grid7 } = require('./grid7')
const { killEvent } = require('../killEvent')
const { tabstrip7 } = require('./tabstrip7')
const styles7 = require('./styles7')

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @param {object} view
 * @returns
 */
const wireUpView7 = (view = { ow: ow0 }) => {
  const { qc } = cmp

  const result = {
    view,
    common,
    ...common.ow7,
    ...cmp,
    cmp,

    combo7(opts) {
      opts.view = view
      return common.ow7.combo7(opts)
    },

    multiSelect7(opts) {
      opts.view = view
      return common.ow7.multiSelect7(opts)
    },

    weekdays7(opts) {
      opts.view = view
      return common.ow7.weekdays7(opts)
    },

    button(opts) {
      return common.ow7.button7(
        typeof opts === 'string' ? { name: opts, view, command: opts } : { ...opts, view }
      )
    },

    footerBar7(opts, dsName, view = result.view) {
      return common.ow7.footerBar7(opts, dsName, view)
    },

    headerBar7(opts, dsName, view = result.view) {
      return common.ow7.headerBar7(opts, dsName, view)
    },

    tabstrip7,

    tabstrip: tabstrip7,

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = true
      return $ajax(opts)
    },

    grid7(opts) {
      opts.view = view
      return grid7(opts)
    },

    collectionController7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return collectionController7(opts)
    },

    dc7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return dc7(opts)
    },

    styles(scope) {
      return styles7(scope ?? view.qTop.el?.id ? '#' + view.qTop.el.id : undefined)
    }
  }

  view.afterInit = () => {
    const { qTop } = view

    qTop.on('keydown', shortcutKeyHandler(view))

    // prevent filter change propagating out from the view
    // especially from filters
    qc(qTop.el.parentElement).on('change ow-change', e => killEvent(e))

    qc(qTop.el.parentElement).addClass('css7')
    result.styles().renderTo(qTop.el.parentElement)
    // combo7.styles().renderTo(qTop.el.parentElement)
  }
  return result
}

module.exports.wireUpView7 = wireUpView7

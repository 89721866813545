const { vex } = window

const vexDialog = (title, msg, options, customCb, buttons) => {
  vex.dialog.buttons.NO.text = __('Cancel')
  vex.dialog.buttons.YES.text = __('OK')

  const message = `
    <style>
      .vex-body::-webkit-scrollbar { width: 8px; height: 8px; background: transparent; }
      .vex-body::-webkit-scrollbar-track { background: transparent; }
      .vex-body::-webkit-scrollbar-thumb { background: #d2d2d7; border-radius: 5px; }
      .vex-body::-webkit-scrollbar-thumb:hover { background: #d2d2d7; } 
    </style>
    <div class="${options.className}">${title}</div>
    <div class="vex-body thin-scrollbar">
      <i class="${options.iconName}"></i>${msg}
    </div>`

  vex.dialog.confirm({
    message,
    showCloseButton: true,
    buttons: buttons,
    contentClassName: options.contentClassName,
    closeAllOnPopState: false,
    callback: customCb,
    afterOpen() {
      this.contentEl.children[0].children[0].innerHTML = message

      setTimeout(() => {
        $('.vex-dialog-buttons').find('.vex-dialog-button-primary').attr('tabindex', '1')
        $('.vex-dialog-buttons').find('.vex-dialog-button-secondary').attr('tabindex', '2')
        $('.vex-dialog-buttons').find('.vex-dialog-button-primary')[0]?.focus()
      }, 120)
    }
  })
}

const vexYesNo = (title, msg, options, customCb) =>
  vexDialog(title, msg, options, customCb, [vex.dialog.buttons.NO, vex.dialog.buttons.YES])

exports.confirm = (title, msg, cb, opts) =>
  new Promise(resolve => {
    vexYesNo(
      title,
      msg,
      {
        className: 'vex-title info',
        iconName: 'fa fa-question',
        contentClassName: opts?.contentClassName,
        closeAllOnPopState: false
      },
      function (r) {
        cb?.(r)
        resolve(r)
      },
      [vex.dialog.buttons.NO, vex.dialog.buttons.YES]
    )
  })

exports.confirmYes = (title, msg, cb) =>
  vexDialog(title, msg, { className: 'vex-title info', iconName: 'fa fa-question' }, cb, [
    vex.dialog.buttons.YES
  ])

exports.confirmYesNo = (title, msg, cb) =>
  vexDialog(title, msg, { className: 'vex-title info', iconName: 'fa fa-question' }, cb, [
    Object.assign({}, vex.dialog.buttons.NO, { text: 'No' }),
    Object.assign({}, vex.dialog.buttons.YES, { text: 'Yes' })
  ])

/**
 * A storage solution aimed at replacing jQuerys data function.
 * Implementation Note: Elements are stored in a (WeakMap)[https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap].
 * This makes sure the data is garbage collected when the node is removed.
 */

const _storage = window._cmp_element_map || (window._cmp_element_map = new WeakMap())

const $map = element => {
  if (!element) throw '$map called with no element argument'
  if (element[0]) element = element[0]

  var d = _storage.has(element) && _storage.get(element)
  if (!d) {
    d = {}
    _storage.set(element, d)
  }
  return d
}

module.exports = { $map }

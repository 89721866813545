const { cssStringToObject, camel, unCamel } = require('../css')
const { $map } = require('../element-map')
const { isObject } = require('../js-types')
const { cmpCollection } = require('./cmpCollection')
const { $cmp } = require('./$cmp')
const { render, renderAsync, renderTo } = require('./cmp-renderer')
const { bindState } = require('./cmp-state')
const { html } = require('./html')
const { isElement } = require('./isElement')
const { on, trigger } = require('../events')
const { formatString } = require('../ctl-format')
const { stringSelectorToCmp } = require('./stringSelectorToCmp')
const { unnestArray } = require('../unnest-array')
const { textnode } = require('./textnode')

const isCmp = obj => typeof obj === 'object' && typeof obj.render === 'function'

const normalizeChildren = children => {
  if (children && !Array.isArray(children)) children = [children]
  children = cmpCollection(children || [])
  unnestArray(children)
  children.forEach((x, ix) => {
    if (typeof x === 'string') children[ix] = textnode(x)
  })
  return children
}

const normProps = {
  isNorm: true,

  toString() {
    return this._el.outerHTML // todo
  },

  on(et, handler, capture) {
    if (et === 'init') {
      this.onInit =
        typeof this.onInit === 'function'
          ? [this.onInit]
          : Array.isArray(this.onInit)
            ? this.onInit
            : []
      this.onInit.push(handler)
      return this
    }
    on(this._el, et, handler, capture)
    return this
  },

  trigger(...args) {
    trigger(this._el, ...args)
    return this
  },

  off() {
    console.error('Cmp.off() is not supported') // unsupported
    return this
  },

  addClass(cls) {
    cls
      .split('.')
      .join(' ')
      .split(' ')
      .forEach(x => x && this._el.classList.add(x))
    return this
  },

  removeClass(cls) {
    cls
      .split('.')
      .join(' ')
      .split(' ')
      .forEach(x => x && this._el.classList.remove(x))
    return this
  },

  hasClass(cls) {
    return this._el.classList.contains(cls)
  },

  toggleClass(cls) {
    this.hasClass(cls) ? this.removeClass(cls) : this.addClass(cls)
    return this
  },

  setAttrs(attrs) {
    if (attrs.style) this.css(attrs.style)
    if (attrs.class) attrs.class.split(' ').forEach(cls => this.addClass(cls))

    let attr
    Object.keys(attrs).forEach(k => {
      if (k === 'style' || k === 'class') return

      attr = attrs[k]
      if (k === 'value') {
        this._el.value = attr
        return this
      }
      if (attr === undefined) {
        if (this._el.hasAttribute(k)) this._el.removeAttribute(k)
        return // next
      }
      this._el.setAttribute(k, attr)
    })
    return this
  },

  attr(name, value) {
    if (typeof name === 'object') return this.setAttrs(name)
    if (arguments.length > 1) return this.setAttrs({ [name]: value })
    return name === 'value'
      ? this._el.value
      : this._el.hasAttribute(name)
        ? this._el.getAttribute(name)
        : undefined
  },

  css(cssToMerge) {
    if (typeof cssToMerge === 'string') {
      if (cssToMerge !== '' && cssToMerge.split(':').length === 1)
        return this._el.style[camel(cssToMerge)]
      cssToMerge = cssStringToObject(cssToMerge)
    }
    let i,
      p,
      keys = Object.keys(cssToMerge)
    for (i = 0; i < keys.length; i++) {
      p = keys[i]
      if (cssToMerge[p] !== this._el.style[p]) {
        if (cssToMerge[p] === undefined) this._el.style[p] = ''
        else {
          // !important is invalid here
          this._el.style[p] = (cssToMerge[p] + '')
            .replace(' !important', '')
            .replace('!important', '')
            .replace(' ! important', '')
            .replace('! important', '')
        }
      }
    }
    return this
  },

  applyState(prevState = {}) {
    return prevState // this state
  },

  bindState(...args) {
    return bindState(this, ...args)
  },

  render(scope) {
    render(this, scope)
    return this._el
  },

  renderTo(parentEl) {
    return renderTo(this, parentEl)
  },

  /**
   * @returns Promise
   */
  renderAsync() {
    return renderAsync(this)
  },

  find(selector) {
    return Array.from(this._el.querySelectorAll(selector))
  },

  /**
   * sets the kids on a norm el and renders them replacing the
   * @param {qc[]} content
   * @returns this if setting content, else cmpCollection
   */
  kids(content) {
    if (content) content = normalizeChildren(content)

    if (!this.el) {
      if (arguments.length === 0) return this.children

      this.children = (Array.isArray(content) ? content : [content])
        .map(x => x ?? [])
        .map(x => (isElement(x) ? norm(x) : x))

      return this
    }

    if (arguments.length === 0)
      return cmpCollection(
        Array.from(this._el.childNodes).map(el => (el.tagName ? qc(el) : html(el.nodeValue)))
      )

    this.children = (Array.isArray(content) ? content : [content])
      .map(x => x ?? [])
      .map(x => (isElement(x) ? norm(x) : x))

    if (this._el && !this.rendering) render(this)
    return this
  },

  /**
   * sets properties on the qc object (not the element) using Object.assign()
   * convenient for chaining
   * returns this
   */
  props(props) {
    if (props.attrs) {
      const attrs = props.attrs
      delete props.attrs

      const style = attrs.style
      delete attrs.style
      if (style) this.css(style)

      const classes = attrs.class
      delete attrs.class
      if (classes) this.addClass(classes)

      this.attr(attrs)
    }
    Object.assign(this, props)
    return this
  },

  odisable(...args) {
    return this.disable(...args)
  },

  /**
   * sets removes disabled state and also
   * sets state on the div.resource_set if it has one.
   *
   * @param {Cmp} control to apply disabled state too usually input
   * @param {boolean} val disabled or not
   */
  disable(v) {
    const me = this
    v = v !== false
    const el = me._el
    const opts = (el.opts ??= me.opts ??= {})

    if (el.kDisable) el.kDisable(v)

    v ? (el.disabled = v) : delete el.disabled
    v ? (me.disabled = true) : delete me.disabled

    if (el.tagName?.toLowerCase() === 'button') me.attr({ disabled: v ? '' : undefined })

    v ? me.addClass('ow-disabled') : me.removeClass('ow-disabled')

    if (v)
      if (me.attr('tabindex') && me.attr('tabindex') !== '-1')
        (opts.attrs ??= {}).tabindex ??= me.attr('tabindex')

    me.attr({
      disabled: v ? '' : undefined,
      tabindex: v ? '-1' : opts.attrs?.tabindex
    })

    if (!v) me.attr({ readOnly: undefined })

    const wrapper = el.closest('ow-ctl-wrap')
    if (typeof me.wrap === 'function')
      v ? me.wrap?.().addClass('ow-disabled') : me.wrap?.().removeClass('ow-disabled')
    else
      wrapper && (v ? qc(wrapper).addClass('ow-disabled') : qc(wrapper).removeClass('ow-disabled'))

    const rs = el.closest('.resource_set')
    if (typeof me.rs === 'function')
      v ? me.rs?.().addClass('ow-disabled') : me.rs()?.removeClass('ow-disabled')
    else rs && (v ? qc(rs).addClass('ow-disabled') : qc(rs).removeClass('ow-disabled'))
  }
}

exports.disable = function (me, ...args) {
  return normProps.disable.call(me, ...args)
}

const Qc = Object.create(normProps)
const PreRenderQc = Object.assign(Object.create(normProps), {
  val(...args) {
    let [v] = args
    const { el, format } = this

    if (arguments.length && format) v = formatString(v, format)

    if (no$(this._el).is('input, textarea')) {
      if (v !== undefined) el.value = v
      return this._el.value
    } else {
      if (v !== undefined) no$(this._el).text(v)
      return no$(el).text()
    }
  },
  qcRendered() {
    delete this.isCmp
    Object.setPrototypeOf(this, Qc) // removes the val()
  }
})
delete Qc.toCode

/**
 * A standard DOM element that isn't controlled by Cmp
 * @param {HTMLElement} el
 */
const norm = el => {
  if ($map(el).cmp) return $map(el).cmp

  const me = Object.create(Qc).props({ el, _el: el })
  me.tag = el.tagName.toLowerCase()

  delete me.children // Array.from(me._el?.childNodes ?? []).map(node => decompose(node))

  $map(el).cmp = me
  return me
}

/**
 * @typedef {function} bindState
 * @param {function} evaluator(Qc) - either a prop name of the Cmp or a function to evaulate the current state value
 * @param {function} changeHandler(value, Qc) - (optional) called with the new value if changed
 * @returns {Qc} for chaining
 */

/**
 * @typedef {Object} Qc
 * @prop {function(Qc[]) : Qc} kids
 * @prop {function(Object):Qc} addClass
 * @prop {function(Object):Qc} removeClass
 * @prop {function(Object):Qc} css: Qc
 * @prop {function(Object):Qc} attr
 * @prop {function(HTMLElement):HTMLElement} renderTo
 * @prop {function():Promise} renderAsync()
 * @prop {function(Object):Qc} bindState : Qc
 * @prop {HTMLElement} el
 */

/**
 * Quick Component
 * qc('div#id.class1.class2', [qc('span'), 'text'])
 * @arg {string} args.0 tag - selector with id and classes 'div#id.class1.class2'
 * @arg {Array<(string|Qc)>} args.1 content
 * @returns {Qc}
 *
 */
const qc = (...args) => {
  if (args.length === 1) {
    if (isElement(args[0])) return $cmp(args[0])
    if (isCmp(args[0])) return args[0]
    if (Array.isArray(args[0])) return cmpCollection(args[0])

    const isHTML = s => typeof s === 'string' && s[0] === '<'
    if (isHTML(args[0])) return html(args[0])

    if (isObject(args[0])) {
      const { tag, children, attrs = {}, ...props } = args[0]
      const me = qc(tag, children).attr(attrs).props(props)

      return me
    }
  }

  let [tag = 'div', children, initOrProps, init] = args
  if (typeof tag !== 'string') console.error('Unexpected tag argument in qc()', args)

  // tag, children, props [, init]
  if (initOrProps && isObject(initOrProps) && !isCmp(initOrProps) && !Array.isArray(initOrProps))
    return qc(...args.filter(a => a !== initOrProps)).props(initOrProps)

  if (children && isObject(children) && !Array.isArray(children) && !isCmp(children)) {
    children = args[2]
    initOrProps = args[1]
  }

  let attrs = initOrProps && typeof initOrProps === 'object' ? initOrProps : {}
  let props = { attrs }
  if (typeof init === 'function') props.init = init
  if (typeof initOrProps === 'function') props.init = initOrProps

  for (let p in props) {
    if (p.slice(0, 2) === 'on' && p !== 'onInit') {
      const et = unCamel(p.slice(2))
      if (Array.isArray(props[p])) props[p].forEach(handler => this.on(et, handler))
      else console.warn('Suspicious qc prop name: ', p, props[p])
    }
  }

  const x = stringSelectorToCmp(tag)
  if (!x.tag) x.tag = 'div'
  const _el = document.createElement(x.tag) // has val() for backward compat

  const me = norm(_el)
  Object.setPrototypeOf(me, PreRenderQc)
  me.isCmp = true

  if (x.class) me.addClass(x.class)
  if (x.id) me.attr({ id: x.id })

  if (props.init && typeof props.init !== 'function')
    throw 'Unexpected condition, init is not a function'

  children ??= []
  children = qc(Array.isArray(children) ? children : [children])

  me.props({
    isQc: true,
    _el,
    tag: x.tag,
    ...props
  })
    .addClass(x.class)
    .attr({ ...attrs, id: x.id })

  delete me.el // it's not rendered yet
  delete me.isNorm

  if (children) me.kids(children)
  return me
}

exports.qc = qc
exports.norm = norm

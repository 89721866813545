const { killEvent } = require('../killEvent')
const { qcControls } = require('../qcControls/common-qc-controls')
const { wait } = require('../wait')
const { udafilter7 } = require('../ow7/multiSelect7')
const { combo7 } = require('../ow7/combo7')
const { currentStore7 } = require('../ow7/currentStore7')

/**
 * @typedef {import("../cmp/qc").Qc} QTop
 * @prop {function} setMinWidth
 * @prop {function} setMaxWidth
 */

/**
 * @typedef View
 * @prop {QTop} qTop
 * @prop {Object} $top
 */

/**
 * @typedef WireUpView4Result
 * @prop {View} view
 * @prop {function} button4
 * @prop {function} headerBar
 * @prop {function} footerBar
 */

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @arg {View} view
 * @returns {WireUpView4Result}
 */
exports.wireUpView4 = (view = { ow: ow4 }) => {
  if (view._wireUp) return view._wireUp
  view.ow = view.ow ?? ow4

  const { button4, footerBar, headerBar } = qcControls

  const result = {
    view,
    common,
    ...ow4,
    ...qcControls,

    button4,

    footerBar(opts, dsName) {
      return footerBar(opts, dsName, view)
    },

    headerBar(opts, dsName) {
      return headerBar(opts, dsName, view)
    },

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = true
      return $ajax(opts)
    },

    dc4(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return ow4.dc4(opts)
    },

    udaFilter4(opts) {
      opts.view = view
      return udafilter7(opts)
    },

    combo7(opts) {
      opts.view = view
      return combo7(opts)
    },

    currentStore7(opts) {
      opts.view = view
      return currentStore7(opts)
    }
  }

  view.afterInit = () => {
    const { viewWrap } = view

    // prevent filter change propagating out from the view
    // especially from filters
    viewWrap
      .addClass('ow4')
      .on('click keyup change ow-change', () => wait(50).then(() => viewWrap.renderAsync()))
      .on('change ow-change', e => killEvent(e))
  }

  view._wireUp = result
  return result
}

const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { $offset } = require('../no-jquery')
const { makeDropdown7 } = require('./makeDropdown7')
const { filterFunctions } = require('./filters')

const scrollItemIntoView = selectedLi => {
  const ul = selectedLi.el.parentElement

  if (!ul) return

  const bottom = selectedLi.el.offsetHeight + selectedLi.el.offsetTop
  if (bottom > ul.scrollTop + ul.clientHeight)
    ul.scrollTop = selectedLi.el.offsetTop - (ul.clientHeight - 40)
  else if (selectedLi.el.offsetTop < ul.scrollTop) ul.scrollTop = selectedLi.el.scrollTop - 2

  // console.log('scrolling to item at:', ul.scrollTop, 'px')
}

/**
 *
 * @param {object} opts
 * @param {Array} opts.list
 * @param {string} opts.url
 * @param {bool} opts.isFilterControl
 * @param {string} opts.textFilterField
 * @param {object} opts.filterMap
 * @param {string} opts.fieldName
 * @param {string} opts.filterFieldName
 * @param {string} opts.dsName
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {string} opts.objectFieldName
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {function} opts.template - returns HTML/qc for text display and dropdown items
 * @param {function} opts.itemTemplate - returns HTML/qc for dropdown items when you want the input to display a different value
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 * @param {boolean} opts.openOnType - default true
 * @param {boolean} opts.selectOnTab - when there's a match and the user tabs out, it will select that item
 *
 * @returns {qc}
 */
exports.combo7 = opts => {
  if (!opts.view) throw 'combo7 requires opts.view'
  opts.openOnType = opts.openOnType ?? true

  if (opts.display && ow0.dev) throw 'combo7 does not support opts.display, use template instead'
  if (opts.dropdownTemplate && ow0.dev)
    throw 'combo7 does not support opts.dropdownTemplate, use template or itemTemplate instead'

  function template(model, i) {
    if (opts.valueTextDisplay)
      return [
        (_v(model, opts.valueField ?? 'Value') ?? '') + '',
        (_v(model, opts.textField ?? 'Text') ?? '') + ''
      ]
        .filter(x => x)
        .join(' - ')

    return (_v(model, opts.textField ?? 'Text') ?? 'item ' + (i ?? '')) + ''
  }

  opts.template = opts.template ?? opts.display ?? opts.dropdownTemplate ?? template
  opts.itemTemplate =
    opts.itemTemplate ?? opts.dropdownTemplate ?? opts.display ?? opts.template ?? template

  const viewParent = opts.view.qTop.el.parentElement

  let open = false
  const closeDropdown = () => {
    open = false
    dd?.el?.parentElement && dd.el.remove()
  }
  const openDropdown = () => {
    open = true
    renderList()
  }

  opts.objectFieldName = opts.objectFieldName ?? '_' + (opts.fieldName ?? 'Value')
  opts.textField = opts.textField ?? 'Text'
  opts.valueField = opts.valueField ?? 'Value'

  let typedText = '',
    matchedOn,
    matchedOnUrl
  let nominee, dd, selectedLi

  const findMatch = () => {
    selectedLi = undefined

    const s = typedText.toLowerCase()
    if (s)
      return (
        opts.list?.find(item => {
          let itemText = opts.itemTemplate(item)
          itemText = typeof itemText === 'string' ? itemText : _v(item, opts.textField)
          return (itemText ?? '').toLowerCase?.().indexOf(s) + 1
        }) ?? opts.list?.[0]
      )

    const v = me.val()
    return v === undefined || v === null
      ? opts.list?.[0]
      : (opts.list?.find(item => _v(item, opts.valueField) + '' === v + '') ??
          me.selectedItem() ??
          opts.list?.[0])
  }

  let inWaiting
  const fetchList = async () => {
    if (opts.url) {
      if (opts.list && matchedOn === typedText && matchedOnUrl === opts.url) return
      if (inWaiting) return inWaiting.then(fetchList)

      if (opts.list && matchedOn === typedText && matchedOnUrl === opts.url) return

      let sub = typedText.toLowerCase()

      if (opts.valueTextDisplay) sub = sub.split(' - ')[0]

      const url = opts.url
      const limit = (opts.limit ??= 50)

      const myInWaiting = (inWaiting = $ajax({
        url: opts.url,
        data: sub ? { sub, limit } : { limit },
        cacheUrlMs: opts.cacheUrlMs
      })
        .finally(() => {
          if (myInWaiting === inWaiting) inWaiting = undefined
        })
        .then(async res => {
          opts.list = res.data ?? res
          if (sub === '' && (res.data ? res.data?.length === res.total : res.length < opts.limit))
            delete opts.url
          matchedOn = sub
          matchedOnUrl = url

          if (me.selectedItem()?.dummy && !typedText) me.val(me.val())
        })
        .catch(err => console.error('lookup failed to load combo data.', err)))

      return inWaiting
    }
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    if (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
      return true
  }

  let loading

  const renderList = async () => {
    const ddParent = viewParent

    console.log('combo7 renderList called')

    if (!opts.list || inWaiting) {
      loading = true
      if (!inWaiting) fetchList().then(renderList)
    }

    if (dd?.el) dd.el.remove()

    dd = qc('ul.combo7.dropdown')

    const buildItemLi = item =>
      qc('li.item', opts.itemTemplate(item))
        .bindState(
          () => nominee,
          (v, li) => {
            if (nominee === undefined) nominee = findMatch()
            nominee === item ? li.addClass('ow-selected') : li.removeClass('ow-selected')
            if (nominee === item) {
              selectedLi = li
              scrollItemIntoView(selectedLi)
            }
          }
        )
        .bindState(
          () => inWaiting || loading,
          (v, me) => me.css({ display: v ? 'none' : undefined })
        )
        .on('mousedown', () => {
          nominee = item
          me.select(item)
          closeDropdown()
          me.el.focus()
        })

    const addSelectedItem = l => {
      if (loading) return l
      const v = me.val()
      if (v === undefined || v === null) return l // no value

      if (!l.find(x => _v(x, opts.valueField) === v)) {
        const item = me.selectedItem()
        if (item) l.unshift(item)
      }
      return l
    }

    const buildList = () => {
      let sub = typedText.toLowerCase()

      const clientFilter = l =>
        typedText && !opts.showAll && !opts.url
          ? l.filter(item => {
              // if (_v(item, opts.valueField ?? 'Value') === me.val()) return true
              let itemText = opts.itemTemplate(item)
              itemText =
                typeof itemText === 'string' ? itemText : _v(item, opts.textField ?? 'Text')
              return !sub || (itemText ?? '').toLowerCase?.().indexOf(sub) + 1
            })
          : [...l]

      if (
        !dd.filteredOn ||
        dd.filteredOn.list !== opts.list ||
        dd.filteredOn.sub !== sub ||
        dd.filteredOn.value !== me.val()
      ) {
        const l = clientFilter(addSelectedItem((opts.list ??= [])))
        dd.list = l
        dd.filteredOn = { list: opts.list, sub, value: me.val() }
        dd.kids([
          qc('li', __('loading'))
            .css({ opacity: '0.5' })
            .bindState(
              () => inWaiting || loading,
              (v, me) => me.css({ display: !v ? 'none' : undefined })
            ),
          qc('li', __('No matches'))
            .css({ opacity: '0.5' })
            .bindState(
              () => !loading && !l?.length,
              (v, me) => me.css({ display: !v ? 'none' : undefined })
            ),
          l.map(buildItemLi)
        ])
      }
    }

    makeDropdown7(dd, ddParent, () => $offset(me.wrap().el)).bindState(
      () =>
        (opts.listWidth === undefined ? $offset(me.wrap().el).width : opts.listWidth || 300) + 'px',
      width => dd.css({ width })
    )

    dd.attr({ tabindex: '-1' })
      .on('focusin', () => common.wait(10).then(() => me.el.focus())) // set the focus back to the input.
      .bindState(
        () => opts.list,
        () => buildList()
      )

    if (open) dd.renderTo(ddParent)

    loading = false
  }

  const me = input7(opts)
    .addClass('combo7')
    .props({
      select(item) {
        me.model ??= {}

        if (!item) {
          _v(me.model, opts.objectFieldName, item)
          me.val(item)
          me.value('')
          return
        }

        if (opts.onSelectItem) if (opts.onSelectItem.call(me, item) === false) return false

        _v(me.model, opts.objectFieldName, item)
        me.val(_v(item, opts?.valueField ?? 'Value'))

        me.value(opts.template(item))
        me.trigger('ow-select', item)
        typedText = ''
        me.renderAsync()
      },

      selectedItem() {
        return _v(me.model, opts.objectFieldName)
      },

      val(v, model, populating = false) {
        const isOw4Read = opts.ow4 && v === undefined && model

        if (!isOw4Read && arguments.length > 0) {
          typedText = ''
          let item
          const prev = _v(me.model, opts.fieldName ?? 'Value')
          let hasChanged = v !== prev

          if (hasChanged) _v(me.model, opts.fieldName ?? 'Value', v)

          item = _v(me.model, opts.objectFieldName)
          if (item && _v(item, opts.valueField) !== v) item = undefined
          if ((v ?? undefined) !== undefined) item = findMatch()

          if (v !== undefined && v !== null && (!item || _v(item, opts.valueField) === v)) {
            if (opts.list) item = opts.list.find(x => _v(x, opts.valueField) === v)

            if (!item) {
              // console.warn('combo7', opts.objectFieldName, 'not found on model.  Creating dummy.')
              item = { dummy: true, [opts.valueField]: v }
              if (opts.url) fetchList()
            }
            _v(model, opts.objectFieldName, item)
          }
          _v(me.model, opts.objectFieldName, item)

          me.value(item ? opts.template(item) : '')
          if (hasChanged && !populating) me.trigger('ow-change')

          me.renderAsync()
        }
        return _v(me.model, opts.fieldName ?? 'Value')
      },

      populate(model) {
        typedText = ''

        const v = _v(model, opts.fieldName ?? 'Value')

        if ((v ?? null) !== null) me.val(v, model, true)
      },

      readField(model) {
        _v(model, opts.fieldName ?? 'Value', me.val())
        _v(model, opts.objectFieldName, me.selectedItem())
      },

      readFilter(filters) {
        let v = me.val()

        if (v === undefined && me.value() === '') return

        if (me.value() === '' && (me.op === undefined || me.op === 'contains' || me.op === 'eq')) {
          delete me.op // use this not isSet
          return
        }
        me.op ??= opts.op

        let filter = {
          field: opts.filterFieldName ?? opts.fieldName,
          operator: me.op ?? (v === undefined ? 'contains' : 'eq'),
          value: v ?? me.value()
        }

        if (opts.textFilterField && filter.value) {
          filter.field = opts.textFilterField
          filter.operator = me.op ?? 'contains'
          filter.value = me.value() ?? null
        }

        if (opts.filterMap && filter.value) {
          const s = filter.value.toLowerCase()
          filter.operator = me.op ?? 'contains'
          const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

          filter.value = Object.keys(opts.filterMap)
            .filter(matchFilter)
            .map(k => opts.filterMap[k])

          if (filter.value.length === 0) {
            ow0.popInvalid(__('Invalid value for ' + filter.field))
            me.value('')
            return
          }
          filter.operator = 'in'
        }

        filters.push(filter)
      }
    })
    .on('init', (e, el) => {
      // for use with ow4
      // el.val = me.val

      if (opts.ow4) {
        el.populate = (...args) => me.populate(...args)
        el.readData = model => {
          _v(model, opts.fieldName, me.val())
          if (me.val() ?? '' !== '') _v(model, opts.objectFieldName, me.selectedItem())
        }
      }
      el.readFilter = (...args) => me.readFilter(...args)
    })
    .props({ model: opts.model ?? {} })
    .on('keydown', e => {
      // tab
      if (e.which === 9 && !e.shiftKey) {
        if (
          opts.selectOnTab !== false &&
          (me.val() ?? null) === null &&
          nominee &&
          open &&
          (opts.required || opts.validation?.required)
        ) {
          me.select(nominee)
          typedText = ''
        }
        return
      }

      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        if (open) closeDropdown()
        else openDropdown()
        return
      }

      // enter
      if (e.which === 13 && open) {
        me.select(nominee)
        typedText = ''
        closeDropdown()
        return
      }

      // enter
      if (e.which === 13 && !open) {
        typedText = ''
        openDropdown()
        return
      }

      // escape
      if (e.which === 27) {
        closeDropdown()
        return
      }

      // downarrow
      if (e.which === 40) {
        if (!open) return
        nominee = dd.list[Math.min(dd.list.length - 1, dd.list.indexOf(nominee) + 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        if (!open) return
        nominee = dd.list[Math.max(0, dd.list.indexOf(nominee) - 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
    .on('input', async () => {
      typedText = me.el.value

      if (me.el.value === '' && me.selectedItem()) {
        me.select(opts.blankValue)
        nominee = undefined

        if (typedText !== matchedOn || opts.url !== matchedOnUrl) await fetchList()

        dd && renderList()

        return
      }

      const opening = open === false
      if (opening && opts.openOnType) openDropdown()

      if (typedText !== matchedOn || opts.url !== matchedOnUrl) {
        await fetchList()
        nominee = findMatch()
        return renderList()
      }
    })
    .bindState(
      () => open,
      () => open && renderList()
    )
    .bindState(
      () => opts.list,
      () => {
        if (dd) renderList()
      }
    )
    .bindState(
      () => opts.list,
      () => {
        if (opts.list && me.selectedItem()?.dummy && nominee && !nominee.dummy && !typedText)
          me.select(nominee)
      }
    )
    .bindState(() => matchedOnUrl && matchedOnUrl !== opts.url && fetchList())

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  const _w = me.wrap()

  _w.addClass('combo7 text-icon-after').on('focusout', e => {
    if (e.target !== me.el) return
    setTimeout(() => {
      if (hasFocus()) return
      // if (open && !me.selectedItem() && typedText && nominee) me.select(nominee)
      if (open) closeDropdown()
    }, 100)
  })

  if (!opts.popUp)
    _w.kids([
      me,
      icon('angleDown')
        .addClass('combo-icon')
        .bindState(
          () => me.disabled,
          (v, icon) =>
            icon.css({ cursor: !v ? 'pointer' : undefined, color: v ? '#ddd' : undefined })
        )
    ])

  _w.on('click', e => {
    if (opts.popUp) return
    if (me.disabled) return
    open ? closeDropdown() : openDropdown()
    me.el.focus()
    renderList()
    return killEvent(e, true)
  })

  return me
}

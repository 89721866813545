const { isDate } = require('../../../lib/js-types')

const { qc } = require('../cmp/qc')
const { makeMulti, $find, $index } = require('../no-jquery')
const { _v } = require('../_v')

const isArrowKey = key => [37, 38, 39, 40].indexOf(key) > -1

const sameEl = (el1, el2) => {
  if (!el1 || !el2) return false
  return el1.isEqualNode(el2)
}

const isAncestorOf = (an, des, depth = 0) => {
  if (!des || !des.parentElement) return false
  if (sameEl(an, des)) return false
  if (sameEl(an, des.parentElement)) return true
  return isAncestorOf(an, des.parentElement, depth++)
}

// todo:
// separate current from selected (multi select and checks - selection on spacebar)

const tvItem = (tv, model, opts, topMidBot = 'k-mid') => {
  let me

  const openCloseIcon = qc('span.k-icon').on('click', () => {
    me.expanded ? collapseItem(me) : expandItem(me)
    return false
  })

  const tvGroup = qc(
    'ul.k-group',
    (model.items || []).map((item, i, arr) =>
      tvItem(tv, item, opts, (i === 0 ? 'k-top ' : '') + (i === arr.length - 1 ? 'k-bot' : ''))
    )
  ).attr({ role: 'group' })

  const kIn = qc('span.k-in').on('click', e => {
    const targetItem = e.target.closest('.tv-item')

    if (!targetItem) return

    if (!opts.multiSelect) return tv.select(targetItem)

    if (e.shiftKey) {
      const nodes = tv.allNodes()
      const iFrom = nodes.indexOf(tv.currentNode() || nodes[0])
      const iTo = nodes.indexOf(targetItem)

      nodes
        .filter((x, i) => i >= Math.min(iFrom, iTo) && i <= Math.max(iFrom, iTo))
        .forEach(node => tv.select(node, true))
    } else if (e.ctrlKey) {
      tv.select(e.target, true)
    } else tv.select(e.target)
  })

  const itemLine = qc('div' + (topMidBot ? '.' + topMidBot.split(' ').join('.') : ''), [
    openCloseIcon,
    kIn
  ])

  me = qc('li.k-item.tv-item', [itemLine, tvGroup], {
    expanded: true,
    model
  })
    .bindState(
      () => model.items,
      items => openCloseIcon.css({ display: items?.length ? undefined : 'none' })
    )
    .bindState('expanded', expanded => {
      openCloseIcon
        .removeClass(!expanded ? 'k-i-collapse' : 'k-i-expand')
        .addClass(expanded ? 'k-i-collapse' : 'k-i-expand')

      me.attr({
        'data-expanded': expanded ? 'true' : 'false',
        'aria-expanded': expanded ? 'true' : 'false'
      })

      tvGroup.css({
        display: expanded ? 'block' : 'none'
      })
    })
    .bindState(
      () => opts.template({ item: model }, opts),
      content => kIn.kids(content)
    )
    .attr({
      draggable: 'true',
      role: 'treeitem'
    })
    .on('init', (e, el) => {
      el.model = model
    })
    .css({ borderTop: '1px' })

  if (opts.dragAndDrop)
    me.on('dragleave', function () {
      kIn.css({ borderTop: undefined, backgroundColor: undefined })
    })
      .on('dragend', function () {
        kIn.css({ borderTop: undefined, backgroundColor: undefined })
      })
      .on('drop', function () {
        kIn.css({ borderTop: undefined, backgroundColor: undefined })
      })

  // default
  const defaultCanDrop = () => (opts.dragAndDrop ? 'child' : false)

  if (opts.dragAndDrop)
    me.on('dragover', function (e) {
      // dragging onto itself of descendent
      const toItem = e.target.closest('.tv-item')
      const dragItem = window.dragEl.closest('.tv-item')

      if (dragItem === toItem || isAncestorOf(dragItem, toItem)) return false

      const dropType = (opts.canDrop || defaultCanDrop)(e, model)

      if (dropType === false) return false

      kIn.css(dropType === 'child' ? { backgroundColor: '#ddd' } : { borderTop: '1px solid #ddd' })

      e.stopPropagation()
      e.preventDefault()
      return false
    })

  return me
}

const expandItem = makeMulti(tvItem => {
  tvItem.expanded = true
  tvItem.render()
})

const collapseItem = makeMulti(tvItem => {
  tvItem.expanded = false
  tvItem.render()
})

const tvWrapper = tv =>
  qc('div.k-widget.k-treeview.ow-treeview.treeview4-wrap', tv).css({
    overflow: 'hidden'
  })

/**
 *
 * @param {HTMLDivElement} el - the treeview methods are added to the el
 * @param {Object} opts
 * @param {boolean} opts.readOnly - default false
 * @param {boolean} opts.dragAndDrop - allows receiving k-items, note: k-items are always draggable
 * @param {string} opts.dataTextField - default is text
 * @param {Object} opts.model - hierarchical data nodes: { [dataTextField], items[], icon }
 * @param {string} opts.fieldName - the name of the top level array of items on the main model
 * @param {function} opts.nodeEdit(targetEl) - default = rename(), when enter key, doubleclick or rightclick edit
 * @param {function} opts.canDrop - drag over k-item, how do we display what will happen
 * canDrop can return:
 * 'child' - background color is updated on k-item,
 * 'sibling' - a line appears above to indicate insert as sibling
 * false - nothing happens, this is not a valid
 * ddefault is 'child'
 * @returns treeView Qc
 */
const treeView4 = (el, opts) => {
  opts.fieldName = opts.fieldName || 'items'
  opts.dataTextField = opts.dataTextField || 'text'

  let me = qc(el)

  me.opts = opts
  el.opts = opts

  me.addClass('treeview4').addClass('focusable')

  if (!me.isQc) {
    const wrapper = tvWrapper(me)
    const wrapperParent = el.parentElement
    el.remove()
    wrapper.renderTo(wrapperParent)
    wrapper.el.append(el)
  }

  opts.template =
    opts.template ||
    (model =>
      qc('span.item', [
        qc('i.fa.' + (model.item?.icon || 'fa-file')),
        qc('span.nodeText', model?.item[opts.dataTextField])
      ]))
  opts.change = () => el.fireChange()

  me.model = Array.isArray(opts.model) ? { [opts.fieldName]: opts.model } : opts.model || {}
  el.model = me.model

  me.attr({ tabindex: '0' })

  me.addClass('k-group')
    .addClass('ow-treeview-lines')
    .attr({ role: 'tree', 'data-role': 'treeview' })
    .css({ paddingBottom: '2em', minHeight: '100%', boxSizing: 'border-box' })

  const methods = {
    allNodes() {
      return me.find('.tv-item')
    },

    readData(rec) {
      // we should run some checks
      rec[opts.fieldName] = me.model[opts.fieldName]
    },

    populate(model = {}) {
      me.model[opts.fieldName] = model[opts.fieldName] || []
      el.model = me.model

      me.kids(
        me.model[opts.fieldName].map((item, i, arr) =>
          tvItem(me, item, opts, (i === 0 ? 'k-top ' : '') + (i === arr.length - 1 ? 'k-bot' : ''))
        )
      )

      me.trigger('ow-populate')

      setTimeout(() => me.select(me.allNodes()[0]), 50)
    },

    dataItem(nodeEl) {
      nodeEl = $(nodeEl)[0]
      if (nodeEl === el) return me.model
      return qc(nodeEl.closest('.tv-item')).model
    },

    removeNode(nodeEl) {
      nodeEl = nodeEl.closest('.tv-item')
      const pNodeEl = nodeEl.parentElement.closest('.tv-item') || me.el

      const pItems = el.dataItem(pNodeEl)[pNodeEl === me.el ? opts.fieldName : 'items']

      pItems.splice($index(nodeEl), 1)
      nodeEl.remove()

      qc(pNodeEl).render()

      el.fireChange('remove')
    },

    addNode(model, nodeEl, index = -1) {
      nodeEl = $(nodeEl || el)[0]

      nodeEl = nodeEl.closest('.tv-item') || el

      let item = qc(nodeEl)
      const pModel = el.dataItem(nodeEl)
      if (nodeEl !== me.el) pModel.items = pModel.items || []
      const pItems = pModel[nodeEl === me.el ? opts.fieldName : 'items']

      if (index === -1) pItems.push(model)
      else pItems.splice(index, 0, model)
      const newNode = tvItem(me, model, opts, 'k-bot').render()

      const itemKidGroup = item === me ? me : item.kids()[1] // children[0] is ItemLine
      itemKidGroup.el.insertBefore(newNode, itemKidGroup.el.children[index])
      qc(newNode).render()

      item.render()
      el.fireChange('add')

      el.select(newNode)

      return $(newNode)
    },

    select(nodeEl, addToMultiSelect = false) {
      if (!addToMultiSelect) {
        $(el).find('.k-state-selected').removeClass('k-state-selected')
        $(el).find('.ow-selected').removeClass('ow-selected')
      }
      $(el).find('.ow-current').removeClass('ow-current')

      nodeEl = $(nodeEl)[0]

      nodeEl &&
        qc(nodeEl.closest('.tv-item').children[0].children[1])
          .addClass('ow-selected')
          .addClass('k-state-selected')
          .addClass('ow-current')

      if (opts.select) nodeEl ? opts.select(nodeEl.closest('.tv-item')) : opts.select()
    },

    fireChange(action) {
      qc(el).trigger('ow-ed-change', action)
    },

    filter(s) {
      const sLow = s.toLowerCase()

      me.allNodes()
        .map(qc)
        .forEach(tvItem =>
          tvItem.model[opts.dataTextField].toLowerCase().indexOf(sLow) > -1
            ? tvItem.removeClass('hidden')
            : tvItem.addClass('hidden')
        )
    },

    getSelected() {
      return me
        .find('.ow-selected')
        .filter(item => !item.closest('.hidden'))
        .map(item => item.closest('.tv-item'))
    },

    findByUid() {
      throw 'uid not supported'
    },

    collapseAll() {
      collapseItem(me.allNodes())
    },

    expandAll() {
      expandItem(me.allNodes())
    },

    rename(nodeEl) {
      const itemEl = $(nodeEl)[0].closest('.tv-item')
      const qItem = qc(itemEl)
      var model = me.el.dataItem(itemEl)

      const kIn = qc($find(itemEl, '.k-in')[0])
      const prevDisplay = kIn.el.style.display || undefined // probably undefined
      kIn.css({ display: 'none' })

      const inputRename = qc('input.treeNodeRename.k-in')
        .attr({ value: model[opts.dataTextField] })
        .css({
          outline: '0',
          paddingLeft: '1em',
          borderWidth: '0',
          minWidth: '100px'
        })
        // prevent the expand collapse when arrow key
        .on('keydown', e => e.which !== 13)
        .on('keyup', e => e.which !== 13)
        .on('keypress', e => {
          if (e.which === 13) {
            finishEdit()
            return false
          }
        })
        .on('blur', () => setTimeout(() => finishEdit(), 10))
        .renderTo(kIn.el.parentElement)

      const finishEdit = () => {
        kIn.css({ display: prevDisplay })
        const hasChanged = model.Name !== inputRename.value
        inputRename.remove()
        model[opts.dataTextField] = inputRename.value
        if (hasChanged) {
          qItem.render()
          el.fireChange('rename')
        }
        el.focus()
      }

      inputRename.focus()
    },

    nodeEdit(nodeEl) {
      nodeEl = $(nodeEl)[0]
      if (!nodeEl || nodeEl === me.el) return
      nodeEl = nodeEl.closest('.tv-item')
      if (!nodeEl) return

      return opts.nodeEdit
        ? opts.nodeEdit(nodeEl)
        : opts.readOnly !== true
          ? el.rename(nodeEl)
          : undefined
    },

    currentNode(el) {
      if (typeof el !== 'undefined') el = me.select(el.closest('.tv-item'))

      return me.find('.ow-current')[0]?.closest('.tv-item')
    }
  }

  Object.assign(el, methods)
  Object.assign(me, methods)

  me.on('dblclick', e => {
    if (!e.target.classList.contains('k-icon')) me.nodeEdit(e.target)
    return false
  })

  me.on('keypress', e => {
    if (e.target === me.el) {
      if (e.which === 13) me.nodeEdit(me.currentNode())
      if (e.which === 46 && !opts.readOnly) me.removeNode(me.currentNode())
      // spacebar adds node to selection
      // if (e.which === 32) me.select(me.currentNode())
    }

    return false
  })

  // left right arrows - expand/collapse
  me.on('keydown', e => {
    if (e.target !== el) return
    if (e.keyCode === 37 || e.keyCode === 39) {
      const selectedItemEl = $find(me.el, '.ow-selected')[0]
      if (!selectedItemEl) return

      const item = qc(selectedItemEl.parentElement?.parentElement)

      if (item.expanded && e.keyCode === 37) {
        collapseItem(item)
        return false
      }
      if (!item.expanded && e.keyCode === 39) {
        expandItem(item)
        return false
      }
    }

    if (e.keyCode === 38) {
      const selectedNode = me.find('.ow-selected')[0]?.closest('.tv-item')
      const nodes = me.allNodes().filter(el => !el.parentElement.closest('[data-expanded=false]'))

      if (!selectedNode) {
        me.select(nodes[0])
        return false
      }
      const index = nodes.indexOf(selectedNode)
      me.el.select(nodes[Math.max(0, index - 1)])
      return false
    }

    if (e.keyCode === 40) {
      const nodes = me.allNodes().filter(el => !el.parentElement.closest('[data-expanded=false]'))
      const selectedNode = me.find('.ow-selected')[0]?.closest('.tv-item')
      if (!selectedNode) {
        me.select(nodes[0])
        return false
      }
      const index = nodes.indexOf(selectedNode)
      me.el.select(nodes[Math.min(nodes.length - 1, index + 1)])
      return false
    }
  })

  me.on('focusin', e => {
    if (e.target === el) return
    if (
      e.target.tagName === 'a' ||
      e.target.tagName === 'input' ||
      e.target.tagName === 'button' ||
      e.target.hasAttribute('tabindex')
    )
      el.focus()
  })

  if (opts.dragAndDrop && opts.drop) {
    me.on('drop', (e, ...args) => {
      e.destinationNode = e.target
      e.sourceNode = window.dragEl // this is set in drag event

      e.setValid =
        e.setValid ||
        function (v) {
          e.valid = v
          if (e.originalEvent)
            if (e.originalEvent.setValid) e.originalEvent.setValid(v)
            else e.originalEvent.valid = v
        }

      return opts.drop(e, ...args)
    })
    // .on('dragover', e => {
    //   const dropType = opts.canDrop ? opts.canDrop(e, me.model) : 'child'
    //   if (dropType === false) return false
    //   e.preventDefault()
    //   return false
    // })
  }

  ;['keyup', 'keydown', 'keypress'].forEach(et =>
    me.on(et, e => {
      if (e.target === me.el && isArrowKey(e.which)) {
        e.preventDefault()
        return false
      }
    })
  )

  me.populate(me.model)

  return el
}

/**
 *
 * @param {object} opts - treeview opts { dsName, etc }
 * @returns the treeview, when adding into DOM, use tv.wrap()
 */
const qTreeView4 = opts => {
  const me = qc('ul.k-group.ow-treeview-lines')
    .css({ background: '#fff', overflowY: 'scroll', overflowX: 'auto' })
    .attr({ role: 'tree', 'data-role': 'treeview' })
    .on('init', (e, el) => treeView4(el, opts))

  me.opts = opts

  if (opts.dsName) me.attr({ 'data-field-for': opts.dsName })
  if (opts.fieldName) me.attr({ 'data-field': opts.fieldName })

  if (opts.header)
    if (opts.cols) {
      me.header = qc('div.tree-header.layout-row')
        .css({
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          background: '#f3f6f8'
        })
        .on('init', () => me.header.update())

      me.on('scroll', () => me.header.update())

      me.header.update = () => {
        me.header.kids(opts.cols.map(buildHeaderCell))
        setTimeout(() => {
          const totalHeaderWidth = Array.from(me.header.el.children).reduce(
            (total, el) => total + el.getBoundingClientRect().width,
            0
          )

          const w = Math.max(totalHeaderWidth, me.el.parentElement.getBoundingClientRect().width)

          me.header.css({ width: w + 'px', marginLeft: -1 * me.el.scrollLeft + 'px' })
          me.resize()

          me.css({
            minHeight:
              'calc(100% - ' + (me.header.el?.getBoundingClientRect().height || '16') + 'px)'
          })
        }, 10)
      }
    }

  me.resize = () => {
    me.css({
      height:
        me.el.parentElement.getBoundingClientRect().top -
        (me.header ? me.header.el.getBoundingClientRect().height : 0) +
        'px'
    })
  }
  me.on('scroll', () => me.resize()).on('init', () => me.resize())

  me.wrap = () => {
    const w = tvWrapper(me.header ? [me.header, me] : me)
    me.wrap = () => w
    return w
  }

  return me
}

// generic cell builder
const buildCell = (opts, model) => {
  const cell = qc('div.cell').css({
    width: opts.width || '100px',
    textAlign: opts.align
  })
  const value = opts.field ? _v(model, opts.field) : undefined // null?
  const formattedValue =
    value === undefined
      ? ''
      : isDate(value)
        ? ow0.formatDateTime(value, opts.format)
        : opts.format
          ? ow0.toString(value, opts.format)
          : '' + value

  cell.kids(formattedValue)

  if (opts.template) {
    const v = opts.template(model, cell)
    if (v !== undefined && cell !== v) cell.kids(v)
  }
  return cell
}

const buildHeaderCell = opts => {
  const hdr = qc('div.tv-col-header', opts.title || '').css({
    width: opts.width || '100px'
  })

  if (opts.header) {
    const v = opts.header(hdr)
    if (v !== undefined && v !== hdr) hdr.kids(v)
  }

  return hdr
}

// const styles = () =>
//   html(`
// div.treeview4 {
//   border-width: 0;
//   background: 0 0;
//   overflow: auto;
//   white-space: nowrap;
//   outline: 0;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   -webkit-touch-callout: none;
// }
// .treeview4 .k-item {
//   display: block;
//   border-width: 0;
//   margin: 0;
//   padding: 0 0 0 16px;
// }
// .treeview4 .k-content,
// .treeview4 .k-item > .k-group,
// .treeview4 > .k-group {
//   margin: 0;
//   padding: 0;
//   background: 0 0;
//   list-style-type: none;
//   position: relative;
// }
// .treeview4 .k-checkbox,
// .treeview4 .k-icon,
// .treeview4 .k-image,
// .treeview4 .k-in,
// .treeview4 .k-sprite {
//   display: inline-block;
//   vertical-align: top;
// }
// .treeview4 .k-checkbox {
//   margin-top: 0.2em;
// }
// .treeview4 .k-icon,
// .treeview4 .k-in {
//   vertical-align: middle;
// }
// .treeview4 .k-request-retry {
//   vertical-align: baseline;
// }
// .treeview4 .k-minus,
// .treeview4 .k-minus-disabled,
// .treeview4 .k-plus,
// .treeview4 .k-plus-disabled {
//   margin-top: 0.25em;
//   margin-left: -16px;
//   cursor: pointer;
// }
// .treeview4 .k-minus-disabled,
// .treeview4 .k-plus-disabled {
//   cursor: default;
// }
// .treeview4 .k-image,
// .treeview4 .k-sprite {
//   margin-right: 3px;
// }
// .treeview4 .k-in {
//   margin: 1px 0 1px 0.16666em;
//   padding: 1px 0.3333em 1px 0.25em;
//   line-height: 1.3333em;
//   text-decoration: none;
//   border-style: solid;
//   border-width: 1px;
// }
// .treeview4 span.k-in {
//   cursor: default;
// }
// .treeview4 .k-drop-hint {
//   position: absolute;
//   z-index: 10000;
//   visibility: hidden;
//   width: 80px;
//   height: 5px;
//   margin-top: -3px;
//   background-color: transparent;
//   background-repeat: no-repeat;
// }

// .treeview4 {
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }
// .treeview4 .k-in {
//   border-radius: 0.31em;
//   border-color: transparent;
// }
// .treeview4 .k-icon {
//   background-color: transparent;
//   border-radius: 0.31em;
// }

// .tab_content > .treeview4 {
//   margin: 1.23em;
// }

// .treeview4 .k-i-collapse {
//   margin-top: 0;
// }
// `)

// .k-popup .treeview4 .k-item > .k-group {
//   background: transparent;
//   color: #515967;
// }

module.exports = { qTreeView4, tvItem, buildCell, buildHeaderCell }

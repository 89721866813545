const { dataBind } = require('./databind7')
const { ctlFormatters, ctlParsers } = require('../ctl-format')
const { input7 } = require('./text7')
const { formatFloatOnType } = require('../culture')
const { _v } = require('../_v')

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

const float7 = opts => {
  opts.currency = opts.currency ?? opts.format?.[0] === 'c'
  if (opts.currency && !opts.format) opts.format = 'c'

  const me = input7(opts)
    .addClass('float7')
    .on('keyup mouseup', () => {
      const value = formatFloatOnType(me.el)
      me.val(value === '' ? undefined : parser(value, opts.format))
    })
    .on('blur', () => {
      me.val(me.el.value ? parser(me.el.value, opts.format) : opts.blankValue)
    })
    .on('keyup', e => {
      if (e.which === 13 && !e.shiftKey)
        me.val(me.el.value ? parser(me.el.value, opts.format) : opts.blankValue)
    })

  me.opts = opts

  var label = opts.label
  const placeholder = opts.placeholder || label || ''
  me.attr({ placeholder })

  const formatter = opts.currency ? ctlFormatters.currency : ctlFormatters.float
  const parser = opts.currency ? ctlParsers.currency : ctlParsers.float

  me.props({
    val(v, model, populating) {
      if (arguments.length) {
        var fmt = opts.format
        let s = (v ?? null) === null ? '' : formatter(v, fmt)

        let hasChanged = false
        if (me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName) !== v)
          hasChanged = true

        if (s !== me.value() && me.el !== document.activeElement) me.value(s)

        me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName, v)

        hasChanged && !populating && me.trigger('ow-change')
      }

      v = me.value()
      v = parser(v, fmt) ?? undefined

      return v
    },

    validate(onInvalid, messageArray) {
      var el = me.el
      var name = opts.label || opts.name || opts.fieldName

      messageArray = typeof messageArray === 'undefined' ? [] : messageArray
      var v = el.val()
      var hasValue = !(
        typeof v === 'undefined' ||
        v === null ||
        v === '' ||
        (typeof v === 'string' && v.trim() === '')
      )
      if (
        (opts.required || (opts.required !== false && opts.schema && opts.schema.required)) &&
        !hasValue
      ) {
        if (onInvalid) {
          onInvalid(name, 'must have a value', el, messageArray)
          return false
        }
      }
      if (typeof v === 'number' && isNaN(v) && opts.edType === 'lookup') {
        if (onInvalid) {
          onInvalid(name, 'must select from the list.', el, messageArray)
          return false
        }
      }
      opts.minLength = opts.minLength || opts.min
      opts.maxLength = opts.maxLength || opts.max

      if (typeof v === 'string' && opts.minLength && opts.minLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have min length of ' + opts.minLength, el, messageArray)
        return false
      }
      if (typeof v === 'string' && opts.maxLength && opts.maxLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have max length of ' + opts.maxLength, el, messageArray)
        return false
      }

      if (opts.validation && typeof opts.validation === 'object') {
        if (opts.validation.ne !== undefined && v === opts.validation.ne) {
          if (onInvalid) onInvalid(name, 'cannot be ' + opts.validation.ne, el, messageArray)
          return false
        }
      }

      // Validation for fields with from value and to value
      var fromField, toField

      if (opts.isToField && opts.fromID) {
        var $from = no$(el.closest('.win-con, body')).find(
          '#' + (opts.edType === 'float' || opts.edType === 'int' ? 'txt' : 'dp') + opts.fromID
        )[0]

        if ($from.length > 0) {
          toField = typeof el.val() === 'number' ? el.val().toString() : el.val()
          fromField =
            typeof $from[0].val() === 'number' ? $from[0].val().toString() : $from[0].val()

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                opts.fromID + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }

      return true
    },

    resolve() {
      me.val(me.val())
    }
  })

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  return me
}

module.exports = { float7 }

const { qc } = require('../cmp/qc')

exports.wrapControl = function (el) {
  var $el = $(el)
  const qEl = qc(el)

  if (qEl.wrap) return

  if ($el.is('button')) return $el.parent()

  if ($el.closest('.ow5').length === 0) return $el.parent()

  var formColumn = $el.closest(
    '.form_column_quarter, .form_column_third, .form_column, .form_column_half, .widget-list'
  )

  // Check if we are in a form_column
  if (
    !el.opts.noWrapper &&
    !el.closest('.resource_set') && // we don't want 2 resource_sets
    formColumn.length &&
    ($el.parent().is(formColumn) || $el.parent().is('.widget'))
  ) {
    let span
    const rs = qc('div.resource_set', [
      el.opts.noLabel ? [] : qc('label.resource_label', el.opts.label || ''),
      (span = qc('span').attr({ role: 'presentation' }))
    ])

    // var $label = (el.opts.nolabel) ? '' : '<label class="resource_label">' + (el.opts.label || '') + '</label>'
    // var $rs = $('<div class="resource_set">' + $label + '<span role="presentation"></span></div>');
    const p = qc(el.parentElement)
    const kids = p.kids()
    el.remove()
    p.kids(kids.map(x => (x.el === el ? rs : x)))
    span.kids([qc(el)])
  }

  if ($el.hasClass('ow-ctl-no-wrap')) return $el.parent()
  if ($el.is('td span.read-only')) return $el.parent()

  if ($el.parent().is('.ow-ctl-wrap')) {
    return $el.parent()
  }
  var $wrap = $('<div class="ow-ctl-wrap"></div>')
  $wrap.insertBefore($el)
  $el.detach()
  $wrap.append($el)
  ;['w1', 'w2', 'w3', 'w4', 'short'].forEach(c => {
    if ($el.hasClass(c)) $wrap.addClass(c)
  })

  el.classList.forEach(cls => {
    if (cls.substr(0, 5) === 'wrap-') {
      $wrap.addClass(cls.substr(5))
      el.classList.remove(cls)
    }
  })

  if ($el.is('input, textarea')) $wrap.addClass('ow-textbox') //.addClass('k-textbox');

  return $wrap
}

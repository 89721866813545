const { _id } = require('../_id')
const { cssStringToObject, propsToStyle, setStyle, unCamel, camel } = require('../css')
const { htmlEncode } = require('../html-encode')
const { no$ } = require('../no-jquery')
const { unnestArray } = require('../unnest-array')

const { cmpCollection } = require('./cmpCollection')
const {
  preRender,
  renderTo,
  renderAs,
  renderAsync,
  render,
  renderAttrs,
  renderStyles
} = require('./cmp-renderer')
const { bindState } = require('./cmp-state')
const { contentToString } = require('./contentToString')
const { isElement } = require('./isElement')
const { copyProps, mergeAttrs } = require('./mergeProps')
const { textnode } = require('./textnode')
const { on } = require('./cmp-event')
const { trigger } = require('../events')
const { formatString } = require('../ctl-format')

/**
 * Accepts a string of selectors, and an object of attributes, and returns an object containing the tag and attributes.
 * Pure Function - no side-effects
 *
 * @param {string} sel - String containing selector in valid CSS syntax. Contains at least a tag, and at most a tag + one ID + multiple classes.
 * @param {string} classes - String containing space separated class names
 *
 * @returns { tag, id, class }
 */
const stringSelectorToCmp = (sel, classes = '') => {
  // create an object of classes as keys
  if (!sel || typeof sel !== 'string') throw 'sel parameter should be a non-empty string.'
  if (typeof classes !== 'string') throw 'classes parameter should be a string.'

  let classList = {}
  classes.split(' ').forEach(x => (classList[x] = 1))

  let id, tag
  sel.split('.').forEach((cls, i) => {
    let x = cls.split('#')
    id = id || x[1]
    cls = x[0]
    if (i) classList[cls] = 1
    else tag = cls
  })

  return {
    tag,
    id,
    class: Object.keys(classList)
      .filter(x => x)
      .join(' ')
  }
}

const normalizeChildren = children => {
  if (children && !Array.isArray(children)) children = [children]
  children = cmpCollection(children || [])
  unnestArray(children)
  children.forEach((x, ix) => {
    if (typeof x === 'string') children[ix] = textnode(x)
  })
  return children
}

const hasNoEndTag = {
  input: 1,
  img: 1,
  br: 1,
  hr: 1,
  link: 1,
  meta: 1,
  base: 1
}

/**
 * Accepts an object of key/value pairs and returns a string in format:  <key1>='<finalValue1> <key2>='<finalValue2> .... If  <key> === 'style' then <finalValue> is a string of attribute/value pairs, in CSS format. Otherwise, <finalValue> consists of <value> converted to a string followed by the escaping of HTML.
 *
 * @param {object} attrs
 */
const toAttrs = attrs =>
  Object.keys(attrs)
    .map(k => {
      var v = attrs[k]
      if (k === 'style' && typeof v === 'object') v = propsToStyle(v)
      v = typeof v === 'undefined' || v === null ? '' : htmlEncode(v.toString())
      if (v === '' && (k === 'style' || k === 'class')) return ''
      return k + '="' + v + '"'
    })
    .filter(s => s !== '')
    .join(' ')

const instance = function (fn) {
  let scope = this || window
  scope.instances = scope.instances || {}
  var uid = _id()
  scope.instances[uid] = fn
  return uid
}

class Cmp {
  constructor(tag = 'div', attrs = {}, children = [], init) {
    const me = this
    me.isCmp = true

    if (typeof tag === 'object') {
      const props = tag
      me.tag = props.tag
      me.attrs = props.attrs || {}
      me.children = props.children || []
      if (props.init) init = props.init

      for (let p in props) {
        if (p.substr(0, 2) === 'on' && p !== 'onInit') {
          const et = unCamel(p.substr(2))
          if (Array.isArray(props[p])) props[p].forEach(handler => this.on(et, handler))
          else {
            console.warn('Suspicious Cmp prop name: ', p, props[p])
          }
        }
      }

      copyProps(me, props)
    } else {
      me.tag = tag
      me.attrs = attrs || {}
      me.children = children
      if (init && typeof init !== 'function') throw 'Unexpected condition, init is not a function'
      if (typeof init !== 'undefined') me.init = init
    }

    me.children = normalizeChildren(me.children)

    me.attrs = mergeAttrs({}, me.attrs)
    me.attrs.class = me.attrs.class || ''
    me.attrs.style = setStyle(me.attrs.style || {}, {})

    let r = stringSelectorToCmp(me.tag ?? 'div', me.attrs.class)
    me.tag = r.tag || 'div'
    if (r.id) me.attrs.id = me.attrs.id || r.id // should we?
    me.attrs.class = r.class
  }

  trigger(...args) {
    this.el && trigger(this.el, ...args)
    return this
  }

  // fire(et, e, ...args) {
  //   return fire(this, et, e, ...args)
  // }

  on(eventName, handler) {
    if (eventName === 'init') {
      on(this, eventName, handler)
    } else {
      if (this.el) no$(this.el).on(eventName, handler)
      else on(this, 'init', () => no$(this.el).on(eventName, handler))
    }
    return this
  }

  toString(renderScope) {
    preRender(this)

    if (this.init) this.attrs['data-cmp'] = instance.call(renderScope, this.init)
    let tag = this.tag
    let sAttrs = toAttrs(this.attrs)
    let content = contentToString(this.children || '', renderScope)
    return (
      '<' +
      tag +
      (sAttrs ? ' ' + sAttrs : '') +
      '>' +
      (hasNoEndTag[tag] ? '' : content + '</' + tag + '>')
    )
  }

  bindState(...args) {
    return bindState(this, ...args)
  }

  renderTo(x) {
    return renderTo(this, x)
  }

  renderAs(x) {
    return renderAs(this, x)
  }

  renderAsync() {
    return renderAsync(this)
  }

  render(scope) {
    return render(this, scope)
  }

  val(v) {
    const { el, format } = this

    if (arguments.length && format) v = formatString(v, format)

    if (no$(el).is('input, textarea')) {
      if (typeof v !== 'undefined') el.value = v
      return el.value
    } else {
      if (typeof v !== 'undefined') no$(el).text(v)
      return no$(el).text()
    }
  }

  css(cssToMerge) {
    let changed = false
    if (typeof cssToMerge === 'string') {
      if (cssToMerge !== '' && cssToMerge.split(':').length === 1)
        return this.attrs?.style?.[camel(cssToMerge)]

      cssToMerge = cssStringToObject(cssToMerge)
    }
    let i,
      p,
      keys = Object.keys(cssToMerge)
    for (i = 0; i < keys.length; i++) {
      p = keys[i]
      if (cssToMerge[p] !== this.attrs.style[p]) changed = true
    }
    if (changed) {
      this.attrs.style = setStyle(this.attrs.style, cssToMerge)
      this.stylesChanged = true
      if (this.el && !this.isQc) this.renderAsync()
    }
    return this
  }

  toggleClass(cls) {
    return this.hasClass(cls) ? this.removeClass(cls) : this.addClass(cls)
  }

  addClass(cls) {
    const currentClasses = this.attrs.class.split(' ')
    let changed = currentClasses.filter(x => x && x === cls).length === 0

    if (changed) {
      currentClasses.push(cls)
      this.attrs.class = currentClasses.filter(x => x !== '').join(' ')
      this.attrsChanged = true
      if (this.el && !this.isQc) this.renderAsync()
    }

    return this
  }

  removeClass(cls) {
    let changed = false
    this.attrs.class = this.attrs.class
      .split(' ')
      .filter(x => {
        if (x === cls) changed = true
        return x && x !== cls
      })
      .join(' ')

    if (changed) {
      this.attrsChanged = true
      if (this.el && !this.isQc) this.renderAsync()
    }

    return this
  }

  hasClass(cls) {
    return !!this.attrs.class.split(' ').find(x => x === cls)
  }

  setAttrs(attrsToMerge) {
    let changed = false
    let i,
      v,
      attr,
      keys = Object.keys(attrsToMerge)

    for (i = 0; i < keys.length; i++) {
      attr = keys[i]
      v = attrsToMerge[attr]
      if (attr === 'style') this.css(v)
      else if (attr === 'class') this.addClass(v)
      else {
        if (this.attrs[attr] !== v) {
          if (v === undefined || v === null) delete this.attrs[attr]
          else this.attrs[attr] = v
          changed = true
        }
      }
    }

    if (changed) {
      this.attrsChanged = true
      if (this.el && !this.isQc) this.renderAsync()
    }

    return this
  }

  attr(name, value) {
    if (typeof name === 'object') return this.setAttrs(name)
    if (typeof value !== 'undefined') return this.setAttrs({ [name]: value })
    return name === 'value' && this.el ? this.el.value : this.attrs[name]
  }

  /**
   *
   * @param {object} prevState
   * @returns currentState
   *
   * this is for overriding
   * this would be the right place to convert high level state
   * to Cmp attrs and children
   * keep it high performance
   * you might want to set this.attrsChanged = true here
   */
  applyState() {
    return {} // this state
  }

  renderAttrs() {
    if (this.el) {
      if (this.stylesChanged) this.renderStyles()
      renderAttrs(this.attrs, this.el)
      delete this.attrsChanged
    }
    return this
  }

  renderStyles() {
    if (this.el) {
      renderStyles(this.attrs.style, this.el)
      delete this.stylesChanged
    }
    return this
  }

  /**
   * sets the kids on a norm el and renders them replacing the
   * @param {qc[]} content
   * @returns
   */
  kids(content) {
    if (arguments.length === 0) return this.children

    const { norm } = require('./qc')

    this.children = (Array.isArray(content) ? content : [content])
      .map(x => x ?? [])
      .map(x => (isElement(x) ? norm(x) : x))

    // if (this.el && !this.rendering) render(this)
    return this
  }

  /**
   * sets properties on the cmp object (not the element) using Object.assign
   * convenient for chaining
   * returns this
   */
  props(props) {
    if (props.attrs) {
      const attrs = props.attrs
      delete props.attrs

      const style = attrs.style
      delete attrs.style
      if (style) this.css(style)

      const classes = attrs.class
      delete attrs.class
      if (classes) this.addClass(classes)

      this.attr(attrs)
    }
    Object.assign(this, props)
    return this
  }
}

/**
 * Used to determine if init argument is attributes or children
 * @param {any} obj
 * @returns true if it is an object and has a render function
 */
const isCmp = obj => typeof obj === 'object' && typeof obj.render === 'function'

/**
 * returns a Cmp obj for an HTML element
 * You can use these ways: 
 *   c(props) - props = { tag, attrs, children, onInit ... }
 *   c(tag, children)
 *   c(tag, children, otherProps) - don't use
 *   c(tag, attrs, children, onInit) - don't use
 * 
 * props are copied to the cmp object 
 * 
 * @param {string} tag - Html tag OR selector eg. 'div#myid.class1.class2'
 * @param {object} attrs - element attributes
 * @param {cmp[]} children - content for the
 * @param {function} init - adds data-cmp="2837659" attribute which maps to the init function when fireCmpInit is called
 
 */
const c = (tag, attrs, children = [], init) => {
  if (typeof tag === 'object' && attrs === undefined) {
    if (attrs !== undefined) throw 'Unexpected condition in cmp constructor arguments'
    return new Cmp(tag)
  }

  const props = { tag, attrs, children }
  if (init) props.init = init

  // check if it's the c(tag, children, props)
  if (Array.isArray(attrs) || isCmp(attrs) || typeof attrs === 'string') {
    props.children = attrs
    delete props.attrs
    if (typeof children === 'object') {
      if (children.attrs) props.attrs = children.attrs
      copyProps(props, children)
    }
  }

  return new Cmp(props)
}

module.exports.c = c
module.exports.Cmp = Cmp
module.exports.isCmp = isCmp
module.exports.stringSelectorToCmp = stringSelectorToCmp

const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const theme = require('../theme')
const { icon6 } = require('./icons')
const { addCss } = require('./stylesheet')

const buttonBar = props => {
  if (Array.isArray(props)) props = { left: props, right: [] }
  return qc('div.ow-buttonbar', [
    qc('span.buttonset_left', props.left || []),
    qc('span.buttonset_right', props.right || [])
  ])
}

const buttonStrip = children => qc('div.ow-buttonstrip', children)

const icon = props =>
  qc('i', {
    class: props.name + (props.display === false ? ' hidden' : ''),
    'aria-hidden': 'true'
  })

const button6 = props => {
  if (typeof props === 'string') {
    let opts = props
    if (opts === 'save') {
      opts = {
        name: opts,
        label: __('Save'),
        attrs: { class: 'green-button' }
      }
    } else if (opts === 'close') {
      opts = {
        name: opts,
        label: __('Close')
      }
    } else if (opts === 'add-row') {
      opts = {
        name: opts,
        label: __('Add Row'),
        iconName: 'plus',
        attrs: { class: 'icon-green' }
      }
    } else if (opts === 'new') {
      opts = {
        name: opts,
        label: __('New'),
        iconName: 'plus'
      }
    } else if (opts === 'delete') {
      opts = {
        name: 'delete',
        label: __('Delete'),
        iconName: 'trash'
      }
    } else if (opts === 'edit') {
      opts = {
        name: opts,
        label: __('Edit'),
        iconName: 'pencil'
      }
    } else if (opts === 'refresh') {
      opts = {
        name: opts,
        label: __('Refresh'),
        iconName: 'refresh'
      }
    } else if (opts === 'print') {
      opts = {
        name: opts,
        label: __('Print'),
        iconName: 'print'
      }
    } else {
      opts = {
        name: opts
      }
    }
    props = opts
  }
  props.label = props.label || props.name

  if (props.icon) {
    props.iconName = props.icon.replace('fa fa-', '')
    delete props.icon
  }

  const attrs = props.attrs || {}
  delete props.attrs
  attrs.class = attrs.class || ''
  attrs.class = attrs.class + (props.icon ? ' icon-left' : '')

  const me = qc(
    'button.button6',
    props.iconName ? [icon6(props.iconName), props.label] : [props.label]
  )
    .addClass(attrs.class)
    .attr(attrs)
    .props(props)
    .on('click', e => {
      if (me.disabled) return killEvent(e, false)
    })
    .bindState(
      () => me.disabled,
      () => me.attr({ disabled: this.disabled ? 'disabled' : undefined })
    )

  return me
}

const closeButton = view =>
  button6('close').on('click', () => {
    if (view.qTop.requestClose?.() === false) return false
    view.qTop.closeForm()
  })

module.exports = {
  icon,
  button6,
  buttonBar,
  buttonStrip,
  closeButton
}

addCss(
  'button6-css',
  `
#scope .btn6,
#scope .button6 {
  display: inline-block;
  padding: 2px 7px 2px;
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
#scope .button6 {
  border-radius: 0.31em;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #ceced2;
  box-shadow: none;
}
#scope .button6 {
  color: #515967;
  border-color: #ceced2;
  background-color: #fff;
  background-position: 50% 50%;
}
#scope .button6:hover {
  color: #263248;
  border-color: #bebec3;
  background-color: ${theme.buttonColor};
}

/* focus on buttons wasn't showing */
#scope .ow-buttonstrip .button6 {
  border: 0.077em solid transparent !important; /* need to stop buttons moving around as border size changes */
}
#scope .ow-buttonstrip .button6:focus {
  border-color: silver !important;
}

#scope .button6:active {
  color: #fff;
  background-color: #1984c8;
  border-color: #1a87cd;
}

#scope .button6.green-button {
  background: rgba(97, 177, 60, 1);
  color: #fff !important;
  border-color: #559238;
}
#scope .button6.red-button {
  background: rgba(204, 32, 49, 1);
  color: #fff;
  border-color: #ceced2;
}
#scope .button6.blue-button {
  background: rgba(204, 32, 49, 1);
  color: #fff;
  border-color: #ceced2;
}
#scope .button6[disabled]:hover,
#scope .button6[disabled]:active,
#scope .button6[disabled],
#scope .button6.green-button[disabled] {
  color: #a6a6ad !important;
  border-color: #ceced2;
  background-color: transparent;
  opacity: 0.5;
}
#scope .button6.green-button:hover,
#scope .button6.green-button:active {
  background: rgba(85, 146, 56, 1);
  color: #fff;
  border-color: #3d652a;
}
#scope .ow-btn6 {
  margin: 0;
}
#scope .button6.icon-green i {
  float: left;
  margin: 0.231em 0.39em 0 0;
  padding: 0;
  font-size: 1.1em;
  line-height: 1.2em;
  color: rgba(97, 177, 60, 1);
}
`
)

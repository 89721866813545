const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { humanize } = require('../humanize')
const { killEvent } = require('../killEvent')
const { iconCodes } = require('../icon-codes')

const renderIcon = icon =>
  icon.name
    ? qc('i.icon', html(iconCodes[icon.name.replace('fa ', '').replace('fa-', '')])).attr({
        'aria-hidden': 'true'
      })
    : []

const buttonOpts = (type, footer = false) =>
  ({
    copy: { name: __('Copy'), icon: { name: 'clone' }, command: type, type },
    new: { name: __('New'), icon: { name: 'plus' }, command: type, type },
    edit: { name: __('Edit'), icon: { name: 'pencil' }, command: type, type },
    refresh: { name: __('Refresh'), icon: { name: 'refresh' }, command: type, type },
    confirm: { name: __('Confirm'), icon: { name: 'check' }, command: type, type },
    print: { name: __('Print'), icon: { name: 'print' }, command: type, type },
    delete: {
      name: __('Delete'),
      icon: { name: 'trash' },
      command: type,
      type,
      color: footer ? 'red' : undefined
    },
    'add-row': {
      name: __('Add Row'),
      icon: { name: 'plus-circle', color: 'green' },
      command: type,
      type
    },
    'select-items': {
      name: __('Select Items'),
      icon: { name: 'plus-circle', color: 'green' },
      command: type,
      type
    },
    clear: {
      name: __('Clear'),
      icon: { name: 'eraser', color: 'black' },
      command: type,
      type
    },
    advanced: {
      name: __('Advanced'),
      icon: { name: 'caret-down', color: 'black' },
      command: type,
      type
    },
    email: {
      name: __('Email'),
      icon: { name: 'envelope' },
      command: type,
      type
    },
    export: {
      name: __('Export'),
      icon: { name: 'share', color: 'green' },
      command: type,
      type
    },
    excel: {
      name: __('Excel'),
      icon: { name: 'share', color: 'green' },
      command: type,
      type
    },
    select: {
      name: __('Select'),
      color: 'green',
      command: type,
      type
    },

    save: {
      name: __('Save'),
      color: 'green',
      command: type,
      type
    },
    cancel: {
      name: __('Cancel'),
      color: 'red',
      command: type,
      type
    }
  })[type] ?? { command: type, type }

module.exports.buttonOpts = buttonOpts

module.exports.disallowCommand = ({ CanRead, CanWrite, CanConfirm, CanDelete } = {}, cmd) =>
  ['new', 'copy', 'save', 'add-row'].includes(cmd)
    ? CanWrite === false
    : cmd === 'edit'
      ? CanRead === false
      : cmd === 'delete'
        ? CanDelete === false
        : cmd === 'confirm'
          ? CanConfirm === false
          : false

/**
 *
 * @param {string||object} opts
 *
 * @returns
 */
const button7 = opts => {
  opts = typeof opts === 'string' ? { type: opts } : opts
  opts.type = typeof opts === 'string' ? opts : opts.type ?? opts.name
  Object.assign(opts, { ...buttonOpts(opts.type), ...opts })
  opts.name = opts.name ?? humanize(opts.type)

  const me = qc('button.ow-button' + (opts.command ? '.command-button' : ''))
    .props({ opts })
    .addClass(Array.isArray(opts.class) ? opts.class.join(' ') : opts.class ?? '')

  opts.align = opts.align || ''
  opts.icon = opts.icon || { name: '' }

  if (opts.align) me.addClass('align-' + opts.align)
  if (opts.color) me.addClass(opts.color + '-button')

  if (opts.icon?.name) {
    opts.icon.align = opts.icon.align || 'left'
    opts.icon.color ? me.addClass('icon-' + opts.icon.color) : null
    opts.icon.align ? me.addClass('icon-' + opts.icon.align) : null
  }

  //Ctrl-D - Reserved for browser bookmark
  //F1 - Reserved for new Tab
  //F3 - Find
  //F6 - URL focus
  const isDefaultShortcut = {
    // 'filter-change': { key: 120 }, // F9
    save: { key: 113, focusFirst: true }, // F2
    refresh: { key: 118 } // F7?
    // close: { key: 115,  }, // F4?
    // 'close-form': { alt: true, key: 67 }, // Alt-C
    // if (e.which === 27) qc(g.closest('.win-con, body'))[0]?.closeForm?.(), 200); // ESC
    // if (e.which && e.altKey) {
    //   if (e.which === 114 && cmd === 'delete') me.el.click() //F3
    //   if (opts.shortcut) {
    //     if (!opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //     // Ctrl + Alt + key
    //     if (e.ctrlKey)
    //       if (opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //   }
    // }
  }

  if (!opts.shortcut) opts.shortcut = isDefaultShortcut[opts.command ?? opts.type]

  const { userRole } = opts.view?.viewdata ?? {}

  const cmd = opts.command

  const hasPermissions = !exports.disallowCommand(userRole ?? {}, cmd)

  return (
    me
      .props({
        hasPermissions,
        disable(disable = true) {
          if (!hasPermissions) disable = true
          disable = !!disable
          me.disabled = disable
          me.attr({ disabled: disable ? 'true' : undefined })
          disable ? me.addClass('ow-disabled') : me.removeClass('ow-disabled')
        },
        odisable(...args) {
          return me.disable(...args)
        }
      })
      .kids(
        opts.content || [opts.icon ? renderIcon(opts.icon) : undefined, opts.label ?? __(opts.name)]
      )
      .attr({
        'data-command': opts.command,
        // 'data-target-ref': opts.targetRef,
        'data-shortcut-key': opts.shortcut?.key || undefined
      })
      // .bindState(() => {
      //   if (opts.dsName) {
      //     if (!me.grid) {
      //       const g = opts.view.qTop.find('[data-iden=' + opts.dsName + ']')[0]
      //       if (g) me.grid = qc(g)
      //     }
      //     return me.grid?.currentRow()
      //   }
      // },
      // (tr) => {
      //   if (!tr) me.disabled = false
      //   (tr.rec)
      // })
      .bindState('disabled', () => me.attr({ disabled: me.disabled ? 'true' : undefined }))
      .on('click', e => me.disabled && killEvent(e))
      .on('click', () => cmd && me.trigger('command-' + cmd))
  )
}

const shortcutKeyHandler = view =>
  function (e) {
    if (e.which === 113) return killEvent(e)
    if (e.altKey) view.qTop.find('[data-shortcutkey]')
  }

module.exports.shortcutKeyHandler = shortcutKeyHandler

/**
 *
 * @param {Array<string||object>} arrButtons array of button options
 * @param {string} type 'header' or 'footer'
 * @param {string} dsName
 * @returns array of qcButtons
 */
const generateButtonType = (arrButtons, type, dsName) => {
  const value = []

  if (!Array.isArray(arrButtons)) return generateButtonType([arrButtons], type, dsName)

  const isCmp = item => typeof item === 'object' && typeof item.render === 'function'

  // Generate all required button type
  arrButtons.forEach(option => {
    if (option === 'divider') return value.push(qc('label', '|'))
    if (option === 'quick-search') return
    if (option.html) return value.push(html(option.html))

    // if it's already a Cmp object
    if (isCmp(option)) return value.push(option)

    if (typeof option === 'object' && option.type === undefined)
      option.type = option.html ? 'html' : 'custom'

    if (typeof option === 'string')
      option = {
        ...{
          name: humanize(option),
          type: option,
          command: option,
          class: option === 'save' ? 'green-button' : undefined
        },
        ...buttonOpts(option, type === 'footer')
      }
    else
      option = {
        ...buttonOpts(option.type ?? option.name, type === 'footer'),
        ...option
      }

    option.targetRef = option.targetRef ?? dsName
    option.type = option.type.toLowerCase()

    if (!option.name && option.type) option.name = humanize(option.type)
    if (option.name) option.name = __(option.name)

    value.push(button7(option))
  })

  return value
}

module.exports.generateButtonType = generateButtonType

module.exports.button7 = button7

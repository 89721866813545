const { isDate } = require('../../../lib/js-types')
const { _v } = require('../_v')
const { htmlEncode } = require('../html-encode')

const hex = v => Math.floor(v).toString(16)

/**
 * returns 24 char unique ID,
 * if no crypto module then 11 chars represent time and the rest are random
 */
const _id = () =>
  typeof crypto !== 'undefined' && crypto.randomUUID
    ? crypto.randomUUID().split('-').join('')
    : hex(new Date().getTime()) + '             '.replace(/./g, () => hex(Math.random() * 16))

const safeId = v => v.toString().replace(/[^A-Z0-9]/gi, '')

const clone = function (obj, restoreDates) {
  if (typeof obj === 'undefined') return

  if (!restoreDates) return JSON.parse(JSON.stringify(obj)) // Object.assign({}, obj); // this doesn't remove observables

  const dates = {}
  var recurDateFields = function (r, path, depth) {
    if (!r) return

    if (depth > 10) return

    if (r.toISOString) {
      if (!path) return new Date(r)
      dates[path] = new Date(r)
    }

    for (var p in r) {
      if ((r[p] || {}).toISOString) {
        // restore dates to Date Object
        dates[(path ? path + '.' : '') + p] = new Date(r[p])
      } else if (Array.isArray(r[p])) {
        r[p].forEach(function (item, index) {
          recurDateFields(item, (path ? path + '.' : '') + p + '.' + index, (depth || 0) + 1)
        })
      } else if (typeof r[p] === 'object') {
        recurDateFields(r[p], (path ? path + '.' : '') + p, (depth || 0) + 1)
      }
    }
  }

  if (obj && obj.toISOString) return new Date(obj)
  recurDateFields(obj)
  var result = JSON.parse(JSON.stringify(obj))
  for (var p in dates) _v(result, p, dates[p])
  return result
}

/**
 * converts objects deeply into value arrays
 *
 */
const arrayify = (obj, opts, depth) => {
  if (typeof opts === 'undefined') opts = {}
  if (typeof opts.ignore === 'undefined') opts.ignore = []
  if (!Array.isArray(opts.ignore)) opts.ignore = [opts.ignore]
  opts.names = opts.names || []
  opts.parent = opts.parent || []

  if (obj === null || typeof obj === 'undefined') return obj
  depth = depth || 0
  if (depth > 10) {
    console.warn('arrayify reached depth 10!')
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(x => arrayify(x, opts, depth + 1))
  }

  if (typeof obj !== 'object') return obj

  if (obj.toISOString) return obj // Date;

  return Object.keys(obj)
    .filter(k => {
      var fullName = [].concat(opts.parent, [k]).join('.')
      var ignoreThis = false
      opts.ignore.find(ign => {
        if (typeof ign === 'function' ? ign(fullName, obj[k]) : ign !== fullName) {
          ignoreThis = true
          return true // yes igmore
        }
      })
      return !ignoreThis
    })
    .map(k => {
      opts.names.push(k)
      opts.parent.push(k)
      var result = arrayify(obj[k], opts, depth + 1)
      opts.parent.pop()
      return result
    })
}

const toTextValue = arr => (arr || []).map((x, i) => ({ Text: x, Value: i }))

const toValueText = toTextValue

/**
 *
 * @param {int} val 32-bit rgb number
 */
const delphiColortoHex = val => {
  var r, g, b
  r = val & 0xff //convert delphi color to RGB
  g = (val >> 8) & 0xff
  b = (val >> 16) & 0xff
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) //rgb to hex
}

const hextoDelphiColor = val => {
  var bigint = parseInt(val.replace('#', ''), 16) //convert hex to rgb
  var r = (bigint >> 16) & 255
  var g = (bigint >> 8) & 255
  var b = bigint & 255
  return r | (g << 8) | (b << 16) //rgb to delphi color
}

/**
 *
 * @param {string} rgb CSS string representing an array of int values
 * eg. rgb(0,255,0) = green and returns '#00ff00'
 */
const rgb2hex = rgb => {
  rgb = rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)
  if (!rgb) return null
  return (
    '#' +
    ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
    ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
    ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
  )
}

const isSameValue = (a, b, epsilon) => (a > b ? a - b <= epsilon : b - a <= epsilon)

/**
 * Given an object representing a record in a database table, and a field name, return that field name's value.
 * Note: If the field name references a method in the record object, this function will return the return value of that method.
 * @param {object} rec - Object representing a record of data fetched from the database.
 * @param {string} fieldName - String containing the name of a field in the record.  If the record is from a table join, then this string will be of the form 'joinTableName.fieldName'.
 */
const readRecordValue = (rec, fieldName) => {
  let r = rec
  fieldName.split('.').forEach(function (rProp) {
    if (!r) return
    if (typeof r[rProp] === 'function') {
      r = r[rProp].call(r)
    } else {
      r = r[rProp]
    }
  })
  return r
}

/**
 * Given an object representing a record in a database table, a field name, and a value, do one of two things.
 * If value is defined: set the value of rec.fieldName to that value.
 * If value is undefined: remove the property fieldname from the rec object.
 * @param {object} rec - Object representing a record of data fetched from the database.
 * @param {string} fieldName - String containing the name of a field in the record.  If the record is from a table join, then this string will be of the form 'joinTableName.fieldName'.
 * @param {(string|number|boolean|Array|Object)} [value] - If value is undefined, the field indicated by fieldName is deleted entirely from the rec object.
 * @returns {(string|number|boolean|Array|Object)} Returns the value param, or an object (if value param is undefined).
 */
const setRecordValue = (rec, fieldName, value) => {
  var r = rec

  fieldName.split('.').forEach(function (rProp, depth) {
    if (typeof r[rProp] === 'undefined' && typeof value === 'undefined') return
    if (rProp === '__proto__' || rProp === 'prototype') return // extra protection against xss prototype pollution

    if (depth === fieldName.split('.').length - 1) {
      if (typeof r[rProp] === 'function') {
        r = r[rProp].call(r, value)
      } else {
        if (typeof value === 'undefined') {
          delete r[rProp]
        } else {
          r[rProp] = value
          r = value
        }
        return
      }
    } else {
      r[rProp] = r[rProp] || {}
      r = r[rProp]
    }
  })
  return r
}

const objToAttr = obj => JSON.stringify(obj).split('"').join('&quot;')

// We could use lodash...?
const debounce = function (func, wait, immediate) {
  let timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const throttle = (callback, delay) => {
  let throttleTimeout = null
  let storedEvent = null

  const throttledEventHandler = event => {
    storedEvent = event

    const shouldHandleEvent = !throttleTimeout

    if (shouldHandleEvent) {
      callback(storedEvent)

      storedEvent = null

      throttleTimeout = setTimeout(() => {
        throttleTimeout = null

        if (storedEvent) {
          throttledEventHandler(storedEvent)
        }
      }, delay)
    }
  }

  return throttledEventHandler
}

/**
 * Function to search for a value in an array and return it (or a fall back value).
 * Accepts three parameters.
 * If the first parameter is a non-number, or not positive, it is an invalid value, and function will return the third parameter.
 * Otherwise, search through the second parameter--if the first parameter is found, it is a valid value, and will be returned.
 * Otherwise return the third parameter.
 * @param {any} value - Can be of any type.
 * @param {array} testList - Must be an array of objects, each containing a property named 'Value.'
 * @param {number} validNumber - Must be a non-zero positive number.
 */
const listSearch = (value, testList, validNumber) => {
  if (typeof value !== 'number' || value <= 0) return validNumber
  function hasValue(obj, key, value) {
    return obj?.[key] === value
  }
  const isValidValue = testList.some(function (selection) {
    return hasValue(selection, 'Value', value)
  })
  if (isValidValue) {
    return value
  } else {
    return validNumber
  }
}

const roundAccurately = (number, decimalPlaces) =>
  Number(Math.round(Number(number + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1)

module.exports = {
  hex,
  _id,
  safeId,
  clone,
  arrayify,
  toTextValue,
  toValueText,
  delphiColortoHex,
  hextoDelphiColor,
  rgb2hex,
  isSameValue,
  readRecordValue,
  setRecordValue,
  objToAttr,
  val: _v, // deprecated
  _v,
  escapeHtml(...args) {
    return htmlEncode(...args)
  },
  debounce,
  throttle,
  warn: (...args) => console.warn(...args),
  error: (...args) => console.error(...args),
  log: (...args) => console.log(...args),
  devLog() {},
  compare() {},
  isDate,
  listSearch,
  roundAccurately
}

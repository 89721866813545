const { qc } = require('../cmp/qc')
const { date7 } = require('./date7')
const { datetime7 } = require('./datetime7')
const { float7 } = require('./float7')
const { int7 } = require('./int7')
const { text7 } = require('./text7')
const { filterOperators, filterFunctions } = require('./filters')
const { html } = require('../cmp/html')
const { cmenu7 } = require('./cmenu7')
const { _v } = require('../_v')
const theme = require('../theme')

const headerCellTag = 'td'

const filterTypeMap = {
  bit: 'boolean',
  check: 'boolean',
  decimal: 'float',
  int: 'integer',
  float: 'float',
  currency: 'float',
  date: 'date',
  datetime: 'datetime'
}

module.exports.drawColFilter7 = (qGrid, col, qFilterRow) => {
  if (col.gridCol === false) return ''
  if (qGrid.opts.noFilters) return ''

  if (!col.filterType) {
    if (col.ctl?.list) col.filterType = 'listFilter'
    else {
      col.filterType = col.type ?? col.dataType ?? 'string'
      if (col.filterType !== 'boolean')
        col.filterType = filterTypeMap[col.filterType] ?? col.filterType
    }
  }

  const { clientFilter = col.clientFilter } = col.ctl ?? col ?? {}

  const refilter = clientFilter ? () => qGrid.opts.dc?.refilter() : () => qGrid.opts.dc?.load()

  if (col.defaultOperator)
    if (col.filterable?.cell) col.filterable.cell.operator = col.defaultOperator

  const me = qc(
    headerCellTag +
      '.ow-filter-col.coli-' +
      col.coli +
      (col.type && !(col.type === 'text' || col.type === 'string') ? '.' + col.type + '-col' : '')
  ).props({ col, coli: col.coli, tr: qFilterRow })

  qGrid.bindColWidth(me)

  if (col.filterType === 'na' || !col.field) return me

  const dsName = qGrid.opts.dc.opts.dsName ?? qGrid.id
  const filterField = col.filterField || col.field

  let filterType = col.filterType

  me.filterControl = (
    {
      datetime: datetime7,
      datetime7: datetime7,
      date7: date7,
      date: date7,
      float: float7,
      float7: float7,
      int: int7,
      int7: int7
    }[filterType] ?? text7
  )({
    dc: qGrid.opts.dc,
    isFilterControl: true,
    fieldName: filterField,
    label: col.title ?? '',
    dsName,
    filterType,
    col,
    clientFilter: col.clientFilter || false
  }).addClass(filterType || '')

  me.filterControl.op =
    me.filterControl.op ?? me.filterControl.defOp ?? (filterType === 'string' ? 'contains' : 'eq')

  if (filterType === 'listFilter' || filterType === 'boolean')
    me.filterControl.props({
      op: 'contains',
      readFilter(filters) {
        const ctl = col.ctl ?? col

        ctl.list = ctl.list ?? [
          { Value: false, Text: __('false') },
          { Value: true, Text: __('true') }
        ]

        const valueField = ctl.valueField ?? 'Value'
        const textField = ctl.textField ?? 'Text'
        const sub = me.filterControl.el?.value.toLowerCase?.()

        if (sub) {
          const op = me.filterControl.op ?? 'contains'
          const matches = ctl.list
            .filter(item => filterFunctions[op](_v(item, textField)?.toLowerCase(), sub))
            .map(item => _v(item, valueField))

          let f
          filters.push(
            (f = {
              field: col.field,
              operator: 'in',
              value: matches.length ? matches : [filterType === 'boolean' ? 0 : -1]
            })
          )
          if (col.dataType || col.type) f.type = col.dataType || col.type
        }
      }
    })

  me.kids(
    qc('span', [
      me.filterControl.wrap(),

      qc('i.icon.filter-icon', html(''))
        .bindState(
          () => {
            const filters = []
            me.filterControl?.readFilter?.(filters)
            return filters.length > 0
          },
          (filterSet, icon) => icon.css({ color: filterSet ? undefined : theme.disabledIcon })
        )
        .on('click', e => {
          const openMenu = cmenu7(e.target, {
            view: qGrid.opts.view,
            point: { left: e.pageX, top: e.pageY },
            setWidth: false,
            content() {
              let ftypes =
                filterType === 'boolean'
                  ? { true: __('true'), false: __('false') }
                  : filterOperators()[
                      filterType === 'combo' || filterType === 'listFilter'
                        ? 'string'
                        : filterType ?? 'string'
                    ]

              if (!ftypes) {
                console.error('FilterTypes not found for field', col.title ?? col.field)
                ftypes = filterOperators().string
              }

              return qc(
                'ul',
                Object.keys(ftypes)
                  .map(op =>
                    qc('li', qc('a.menu-item', ftypes[op]).props({ op }).attr({ href: '#' }))
                      .on('click', () => {
                        console.log('Setting filter Op:', op)
                        if (me.filterControl) {
                          me.filterControl.op = op
                          if (op === 'true') {
                            me.filterControl.text(__('true'))
                            me.filterControl.op = 'contains'
                          } else if (op === 'false') {
                            me.filterControl.text(__('false'))
                            me.filterControl.op = 'contains'
                          }
                        }
                        openMenu.close()
                        me.filterControl?.el?.focus()
                        refilter()
                        me.renderAsync()
                      })
                      .addClass(me.filterControl?.op === op ? 'selected' : '')
                  )
                  .concat(
                    qc(
                      'li',
                      qc('a.menu-item', __('Clear Filter'))
                        .attr({ href: '#' })
                        .on('click', () => {
                          delete me.filterControl.op
                          if (me.filterControl.clear) return me.filterControl.clear()
                          me.filterControl.val(null)
                          me.filterControl.isSet = false
                          openMenu.close()
                          me.filterControl?.el?.focus()

                          refilter()
                          me.renderAsync()
                        })
                    )
                  )
              )
            }
          })
        })
    ])
  )

  col.preRenderFilter?.(me, qFilterRow)

  return me
}

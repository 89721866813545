const { html } = require('../cmp/html')
const theme = require('../theme')

exports.styles7 = (scope = '.css7') =>
  html(`<style>
.css7 * { float: none ! important }
style { display: none ! important; }

.sticky-rows > * {
position: absolute;
left: 0;
right: 0;
}

.sticky-rows > .footer { bottom: 0; }

.sticky-cols > * {
position: absolute;
top: 0;
bottom: 0;
}

${scope} div.form-header {
overflow: hidden;
box-sizing: border-box;
display: block;
width: inherit;
}
/* 33% x 3 +  2 gaps of 0.5% = 100% */
${scope} .form_column { vertical-align: top; }

${scope} div.form-header > * {
vertical-align: top;
margin-left: 0.5%; 
width: 33%;
overflow: hidden;
height: 100%;
display: inline-block;
}

${scope} div.form-header.c1 > * { width: 100%; }
${scope} div.form-header.c2 > * { width: 49.5%; }
${scope} div.form-header.c3 > * { width: 33%; }
${scope} div.form-header.c4 > * { width: 24.624%; }

${scope} div.form-header > *:first-child { margin-left: 0; }

${scope} .buttonset_left,
${scope} .buttonset_right { width: 50%; display: inline-block;  }
${scope} .buttonset_right { text-align: right; }

${scope} div.k-window-content { font-size: 12px; }

${scope} div.ow-buttonstrip {
  margin: 0;
  padding: 0;
  line-height: 1rem;
  height: 1.5rem;
  padding-top: 0.5rem;
}

${scope} div.ow-buttonstrip > label { line-height: 1rem; }

${scope} div.ow-buttonstrip > .ow-textbox { margin-top: 0; }

${scope} .ow-buttonstrip button {
  padding: 0 6px;
  line-height: 1.25rem;
  height: 1.5rem;
}
${scope} .ow-buttonstrip button i { display: inline-block; }

${scope} i.icon-blue,
${scope} i.fa,
${scope} i.icon { color: ${theme.iconBlue} }

${scope} i.icon-red,
${scope} .icon-red i.fa,
${scope} .icon-red i.icon { color: ${theme.iconRed} }

${scope} i.icon-green,
${scope} .icon-green i.fa,
${scope} .icon-green i.icon { color: ${theme.iconGreen} }

${scope} i.icon-black,
${scope} .icon-black i.fa,
${scope} .icon-black i.icon { color: #000 }

${scope} [disabled] i.fa,
${scope} [disabled] i.icon,
${scope} .ow-disabled i.fa,
${scope} .ow-disabled i.icon { color: #a6a6ad  }

.css7 .sticky-middle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  top: 2.5rem;
  overflow-y: auto;
}

${scope} span.ow-ctl-wrap { display: inline-block; }
.input7.ow-ctl-wrap.ow-disabled { background: ${theme.grayBackgroundColor}; }
.input7.ow-ctl-wrap.ow-disabled input { background: transparent; }
${scope} i.text-item-icon { color: ${theme.iconBlue}; }

a.check7.ow-disabled,
a.check7 { background-color: #fff; }

a.check7[disabled] { background-color: ${theme.grayBackgroundColor}; }

.check7[disabled] { pointer-events: none; }
.check7[disabled]::before { color: #e5e5e5; }

${scope} .check7:focus i {
  border-color: #1a87cd;
  -webkit-box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
  box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
}

${scope} .multi-select7-wrap {
  padding: 1px;
  position: relative;
  max-height: 4.8rem;
  overflow-y: auto;
}
${scope} .multi-select7-wrap > input { width: 3rem; }
${scope} .ow-pill { 
  padding: 3px;
  border: 1px solid #ececec;
  border-radius: 3px;
  white-space: nowrap;
  margin: 1px;
}
${scope} .ow-pill > label { 
  display: inline-block;
  white-space: nowrap;
  max-width: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 9px;
}
${scope} .ow-pill i.icon { 
  margin: 0 2px;
  cursor: pointer;
}

.css7 ::-webkit-scrollbar { width: 8px; height: 8px; background: transparent; }
.css7 .grid7 .ow-grid-content::-webkit-scrollbar { width: 12px; }
.css7 ::-webkit-scrollbar-track { background: transparent; }
.css7 .grid7 .ow-grid-content::-webkit-scrollbar-track { background: #eaeaea; }
.css7 ::-webkit-scrollbar-thumb { background: #d2d2d7; border-radius: 5px; }
.css7 ::-webkit-scrollbar-thumb:hover { background: #d2d2d7; }

${scope} .ow-button[disabled]  {
  color: #a6a6ad;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}

${scope} .ow-button {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 100%;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  box-sizing: border-box;
  margin: 0 2px;
}

${scope} .ow-button:first-child {  margin-left: 0; }
${scope} .ow-button:last-child { margin-right: 0; }

${scope} .ow-button i { display: inline; }

${scope} .ow-button.green-button[disabled], 
${scope} .ow-button.green-button[disabled] {
  color: #a6a6ad !important;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}

.grid7 .filterrow7 span.ow-toggle-btns7.ow-ctl-wrap {
  border: none;
  margin:0;
  display: inline-block;
}
a.toggle-btn7 {
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.textboxBorderColor};
  line-height: inherit;
  display: inline-block;
  padding: 0 0.25rem;
  color: ${theme.textboxColor};
  border-radius: ${theme.borderRadius};
  min-width: 1rem;
  text-align: center;
}
a.toggle-btn7.on i.icon.icon6.text-item-icon {
  color: ${theme.iconBlue};
}
a.toggle-btn7:hover {
  color: ${theme.highlightText};
  background-color: ${theme.buttonColor};
  text-decoration: underline;
}
a.toggle-btn7:focus {
  border-color: silver !important;
}
a.toggle-btn7.on {
  font-weight: bold;
  border: solid 1px ${theme.iconBlue};
  border-color: ${theme.highlightText};
  background-color: #fff;
}
a.toggle-btn7.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
.resource_set .toggle-btn7-wrap {
  padding-left: 0;
}
.resource_set > span > a.toggle-btn7 {
  line-height: inherit;
  padding: 0;
}

.css7 *:focus-visible {
  outline: 0;
}

.css7 .resource_set {
margin: 1px;
display: block;
min-height: 28px;
/* height: 1.5rem; */
line-height: 1.5rem ! important;
box-sizing: border-box;
white-space: nowrap;
}
.css7 .resource_set.multi-line {
height: unset;
}

.css7 .resource_set > label {
display: inline-block;
text-align: right;
line-height: inherit; 
padding-right: 0.5rem;
box-sizing: border-box;
width: 35%;
}

.css7 .resource_set > span {
width: 65%;
box-sizing: border-box;
display: inline-block;
line-height: inherit;
white-space: nowrap;
}

.css7 .ow-ctl-wrap {
display: inline-block;
height: inherit;
line-height: inherit;
vertical-align: top;
}

.css7 .ow-buttonstrip .ow-ctl-wrap.ow-textbox { height: 1.5rem; }

.css7 .ow-ctl-wrap.ow-textbox {
border: ${theme.textboxBorderColor} ${theme.borderWidth} solid;
background: #fff; 
border-radius: 4px;
margin-top: -${theme.borderWidth};
margin-bottom: -${theme.borderWidth};
white-space: nowrap;
box-sizing: border-box;
}

.css7 .ow-ctl-wrap input {
max-width: 99%;
border: 0;
outline: 0;
height: inherit;
text-indent: 4px;
background: transparent;
}

.css7 .rs7 .ow-ctl-wrap input {
width: 100%;
}

.css7 .ow-textbox.text-icon-after > input {
width: calc(100% - 1.5rem);
}
.css7 .ow-textbox.text-icon-after > i {
width: 1.5rem;
text-align: center;
padding: 0;
}

.css7 .ow-textbox.text-icon-after.text-2icon-after > input {
width: calc(100% - 2.5rem);
}
.css7 .ow-textbox.text-icon-after.text-2icon-after > i.icon {
width: 1.2rem;
}

.css7 .ow-textbox.text-icon-after.ow-multi-select-wrap > input {
max-width: 3rem;
}

.css7 .resource_set > span { margin-right: -0.25rem; }
.css7 .resource_set .ow-ctl-wrap { margin-right: 0.25rem; box-sizing: border-box; }
.css7 .resource_set .ow-ctl-wrap.w1 { width: calc(25% - 0.25rem); }
.css7 .resource_set .ow-ctl-wrap,
.css7 .resource_set .ow-ctl-wrap.w2 { width: calc(50% - 0.25rem); }
.css7 .resource_set .ow-ctl-wrap.w3 { width: calc(75% - 0.25rem);  }
.css7 .resource_set .ow-ctl-wrap.w4 { width: calc(100% - 0.25rem); }

.pdi ul.dropdown7 {
  padding: 0.5rem;
  border-radius: ${theme.borderRadius};
}

ul.dropdown7 {
  padding: 3px;
  border-radius: 3px;
  line-height: 1rem;
  cursor: pointer;
  position: absolute;
  min-width: 60px;
  border: solid 0.077em #bbb;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  box-shadow: #ccc 0.14em 0.14em 0.3em 0em;
  box-sizing: border-box;
  overflow-y: auto;
  list-style-type: none;
}

.pdi ul.dropdown7 > li.item {
  border-radius: ${theme.borderRadius};
}

ul.dropdown7 > li.item {
  min-height: 1em;
  padding: ${theme.listItemPadding};
  border: 1px solid transparent;
}

 ul.dropdown7 > li.item:hover {
  text-decoration: none;
  background-color: ${theme.grayBackgroundColor};
}
.pdi ul.dropdown7 > li.item:hover {
  text-decoration: underline;
}

.pdi ul.dropdown7 > li.item.ow-selected {
  background-color: transparent;
  color: ${theme.highlightText};
}
ul.dropdown7 > li.item.ow-selected {
  border: 1px ${theme.highlightText} solid;
  background-color: ${theme.highlightText};
  color: #fff;
}

.cmenu7 li:hover {
  background: #ccc;
}
.cmenu7 li.selected {
  color: #fff;
  background-color: #4d90fe
}
.cmenu7 a.menu-item {
  line-height: 2em;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.cmenu7 a.menu-item .fa {
  text-indent: 0;
  padding: 0 6px;
  color: ${theme.iconBlue};
}
.cmenu7 a.check7 {
  white-space: nowrap;
}

ul.calendar.dropdown {
  font-size: 0.75em;
  padding: 0.5rem;
  line-height: 1rem;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  min-width: 60px;
  border: solid 0.077em #bbb;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  box-shadow: #ccc 0.14em 0.14em 0.3em 0em;
  box-sizing: border-box;
  list-style-type: none;
}
ul.calendar.dropdown > li.item {
  border-radius: 4px;
  padding: 0.5rem;
  border: 1px solid transparent;
}
ul.calendar.dropdown > li.item:hover {
  text-decoration: underline;
}
ul.calendar.dropdown > li.item.ow-selected {
  border: 1px ${theme.highlightText} solid;
  color: ${theme.highlightText};
}

</style>`)

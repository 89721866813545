const { groupRows } = require('./groupRows')
const { $meta, initModel, hasChanges, isNewBlank, react } = require('./data-model7')
const { readServerFilterControls, readClientFilterControls } = require('./databind7')
const { applyFilter } = require('./filters')
const { recSorter } = require('./recSorter')

const applyGroupVisibility = (currentFilter, defaultOpen) => {
  const { includeDeletes = true, showGroupHeaders = true, showGroupFooters = true } = currentFilter

  // filteredRecs is the result of the filters before applyingGroup
  let i,
    r,
    fi = 0
  const recs = []

  const groupsAndRecs = currentFilter.groupsAndRecs

  for (i = 0; i < groupsAndRecs.length; i++) {
    r = groupsAndRecs[i]
    if (r._group && (r.footer ? !showGroupFooters : !showGroupHeaders)) continue

    const meta = $meta(r)
    if (!includeDeletes && meta.deleted) continue

    if (meta.group) {
      if (
        meta.group?.parentGroup &&
        (meta.group.parentGroup?._group?.open ?? defaultOpen) === false
      )
        continue
      if ((meta.group?._group?.open ?? defaultOpen) === false) continue
    }
    meta.filterIndex = fi
    fi++
    recs.push(r)
  }

  currentFilter.recs = recs
  return currentFilter
}

/**
 * Maintains arrays of records, change control, client filters etc.
 *
 * @param {object} opts
 * @param {view} opts.view - required
 * @param {object[]} opts.recs
 * @returns {object} collectionController
 */
module.exports.collectionController7 = opts => {
  if (!opts.view) throw 'collectionController7 requires the view object'
  if (!opts.dsName) throw 'collectionController7 requires opts.dsName'
  if (opts.sorts) throw 'collectionController7.opts.sorts is not correct, use sort'

  opts.expandAllGroups = opts.expandAllGroups || false

  const { qTop } = opts.view

  const itemSchema = (opts.schema = opts.schema ?? {})

  opts.hasFilters = opts.hasFilters !== false

  const me = {
    opts,
    sort: (opts.sort ??= []),

    populate(recs, useExistingMeta = opts.useExistingMeta) {
      me.recs = recs
      me.recs.forEach(rec => opts.prePopulate?.(rec, me.recs))

      if (!useExistingMeta) initModel(recs, opts.dsName, { itemSchema }).view = opts.view

      // client FSP
      opts.view.renderAsync()
      qTop.renderAsync()
    },

    hasChanges() {
      if (opts.readOnly) return false
      return hasChanges(me.recs)
    },

    afterRecSaved(rec) {
      const meta = $meta(rec)
      if (meta.deleted) return me.removeRec(rec)

      meta.changes = {}
      const { reci } = meta
      if (meta.new) {
        rec = { ...rec }
        me.recs[reci] = rec
      }
      const newMeta = initModel(rec, '' + reci, opts.schema, $meta(me.recs))
      newMeta.reci = reci
      newMeta.view = opts.view
      react(rec)
    },

    async saveChanges(replaceDataset = false) {
      if (!opts.baseURL) return

      let data = me.recs.filter(r => !isNewBlank(r) && !r._group)
      data = replaceDataset
        ? data.filter(rec => !$meta(rec).deleted)
        : data.filter(rec => hasChanges(rec))

      const response = await $ajax({
        type: 'PUT',
        url: opts.baseURL.split('?')[0] + '/update',
        data: data.map(rec =>
          $meta(rec).deleted
            ? { delete: true, ...rec }
            : $meta(rec).new
              ? { new: true, ...rec }
              : rec
        )
      }).catch(err => {
        console.error(err)
        ow.popSaveError(err)
      })

      if (!response) return

      data.forEach(me.afterRecSaved)
      react(me.recs)
      me.refilter()

      return response
    },

    async load(noConfirmation) {
      if (!noConfirmation && me.hasChanges())
        return common
          .confirm(__('Refresh'), __('Are you sure you want to discard changes and reload'))
          .then(ok => ok && me.load(true))

      let response = { data: opts.recs ?? [], total: 0, aggregates: [] }

      let url = opts.url ?? opts.baseURL

      if (opts.load) {
        response =
          (await Promise.resolve()
            .then(() => opts.load.call(me, noConfirmation))
            .catch(err => {
              console.error('load returned error', err)
              ow.popError(__('load returned error'), err.errMsg ?? err)
              return
            })) ?? response
      } else if (url) {
        let [base, theRest] = url.split('?')
        theRest = theRest?.split?.('&')?.join('&')
        url = base + (theRest ? '?' + theRest : '')

        const data = {}

        if (opts.hasFilters) {
          data.filter = {}
          data.filter = { filters: [] }
          readServerFilterControls(opts.view.qTop.el, opts.dsName, data.filter)
          data.filter = JSON.parse(JSON.stringify(data.filter)) // ensures correct Date string format for server.
        }

        if (me.paging) {
          data.limit = me.paging.pageSize
          data.skip = (me.paging.page - 1) * me.paging.pageSize
        }
        if (me.sort && opts.sortable !== 'client')
          data.sort = me.sort.map(s => [s[0], s[1] ?? 1 ? 'asc' : 'desc'])

        response = await $ajax({
          method: opts.httpMethod ?? 'get',
          view: opts.view,
          url,
          data,
          LRTask: opts.LRTask ?? true,
          showProgress: opts.showProgress ?? true
        }).catch(err => {
          console.error('load returned error', err)
          ow.popError(__('load returned error'), err.errMsg ?? err)
          return response
        })
      }

      // if it's a fetchAll, it will return only an Array
      response = Array.isArray(response)
        ? { data: response, total: response.length, aggregates: [] }
        : response

      me.response = response
      if (me.paging) me.paging.totalRecs = response.total ?? response.data?.length ?? 0
      me.aggregates = response.aggregates ?? {}
      me.populate(response.data)
    },

    filterRec(d, i, filters) {
      for (var j = 0; j < (filters || []).length; j++) {
        var f = filters[j]
        if (applyFilter(d, f) === false) return false
      }
      return true
    },

    applyGroupVisibility() {
      const result = applyGroupVisibility(me.currentFilter, opts.expandAllGroups)
      qTop.renderAsync()
      return result
    },

    // filtering
    applyClientFilterSort(
      recs,
      filters,
      sorts,
      includeDeletes,
      groupByArray,
      groupByArray2,
      showGroupHeaders = me.showGroupHeaders,
      showGroupFooters = me.showGroupFooters
    ) {
      const result = {
        filters,
        sorts,
        ags: opts.ags ?? {},
        groupByArray,
        groupByArray2,
        recs: [],
        groups: me.groups ?? {},
        showGroupHeaders,
        showGroupFooters
      }

      me.currentFilter = result

      let filterLength = 0
      result.recs = recs.filter(r => {
        const incl = (includeDeletes || !$meta(r).deleted) && me.filterRec(r, filterLength, filters)
        if (incl) filterLength++
        return incl
      })

      if (sorts?.[0]) result.recs.sort(recSorter(sorts))

      result.filterRecs = [...result.recs]

      if (groupByArray.length) {
        groupRows(result)
        me.groups = result.groups
        me.applyGroupVisibility()
      }

      // rebuild the index
      result.recs.filter(r => !r._group).forEach((r, fi) => ($meta(r).filterIndex = fi))

      return result
    },

    addRec(rec = {}) {
      rec = opts.newRec?.(rec) ?? rec

      me.recs.push(rec)
      const reci = me.recs.length - 1
      const meta = initModel(rec, '' + reci, opts.schema, $meta(me.recs))
      meta.reci = reci
      meta.view = opts.view
      meta.new = true

      me.currentFilter.recs = [...me.currentFilter.recs, rec]
      meta.filterIndex = me.currentFilter.recs.filter(r => !r._group).length - 1
      react(rec)

      return rec
    },

    removeRec(rec) {
      const i = me.recs.indexOf(rec)
      if (i < 0) {
        console.warn('attempting to remove a non-existing row from dc.recs')
        return
      }

      // remove from dc.recs
      const meta = $meta(me.recs)
      me.recs.splice(i, 1)

      // shift left all change tracking from array meta.
      let reci, kidMeta
      for (reci = i; reci <= me.recs.length; reci++) {
        kidMeta = $meta(me.recs[reci])
        if (kidMeta) {
          kidMeta.reci = reci
          kidMeta.name = '' + reci
          kidMeta.fullName = meta.fullName + '.' + reci
        }

        const shiftTracking = x =>
          x[reci + 1] !== undefined && reci !== me.recs.length
            ? (x[reci] = x[reci + 1])
            : delete x[reci]
        shiftTracking(meta.changes)
        shiftTracking(meta.orig)
        shiftTracking(meta.prev)
      }

      me.refilter()
    },

    refilter() {
      // get the grid client filters
      const filters = readClientFilterControls(opts.view.qTop.el, opts.dsName)

      const result = me.applyClientFilterSort(
        me.recs,
        filters,
        me.sort,
        opts.showDeletedRows,
        typeof opts.groupBy === 'string' ? opts.groupBy.split(',') : opts.groupBy ?? [],
        typeof opts.groupBy2 === 'string' ? opts.groupBy2.split(',') : opts.groupBy2,
        opts.showGroupHeaders,
        opts.showGroupFooters
      )

      opts.view.renderAsync()

      return result
    }
  }

  if (opts.paging) me.paging = opts.paging

  me.recs = []
  initModel(me.recs)

  return me
}

// grid-groups.js

const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')
const { check6 } = require('../check6')
const { applyGroupVisibility } = require('../dataset-view')
const { addCss } = require('../stylesheet')

const groupIcon = {
  close: '&#xf0da',
  open: '&#xf0d7'
}

const groupToggler = (data, grid, group) =>
  qc('i.icon6.group-toggler')
    .bindState(
      () => group && group.open !== false,
      (v, me) => me.kids(html(v ? groupIcon.open : groupIcon.close))
    )
    .on('click', () => {
      group.open = !group.open
      grid.dataView = applyGroupVisibility(grid.dataView)
      grid.body.renderAsync()
    })

const groupSelector =
  (fieldName = 'Selected') =>
  (data, grid, group) => {
    const chk = check6({ model: grid.dataSet })
      .on('ow-change', () => {
        const v = chk.val()
        group.recs.forEach(r => (r[fieldName] = v))
        group.recs.forEach(r => !r.isGroup && grid.react(r, fieldName))
        grid.opts.view.qTop.renderAsync()
      })
      .bindState(
        () => !group.recs.find(x => !x.isGroup && !x[fieldName]),
        (v, me) => {
          me.value = v
          me.model[fieldName] = me.value
          grid.react(me.model, fieldName)
        }
      )

    return chk.wrap().css({ display: 'inline' })
  }

const expandAllGroups = (g, expand = true) => {
  if (!g.dataSet || !g.groupBy) return
  g.dataView?.allGroupRows?.filter(item => item.isGroup).forEach(r => (r.open = expand))
  g.dataView = applyGroupVisibility(g.dataView)
  g.body.renderAsync()
}
const collapseAllGroups = g => expandAllGroups(g, false)

addCss('grid6-groups-css', {
  '#scope .grid6 .ow-group-header, #scope .grid6 .ow-group-footer': { backgroundColor: '#f3f3f4' },
  '#scope .grid6 i.group-toggler': {
    display: 'inline-block',
    color: '#666',
    width: '0.5em'
  }
})

module.exports = {
  expandAllGroups,
  collapseAllGroups,
  groupToggler,
  groupSelector
}

const { qc } = require('./cmp/qc')

/**
 * sets removes disabled state and also
 * sets state on the div.resource_set if it has one.
 *
 * @param {HTMLElement|HTMLElement[]} el usually input
 * @param {boolean} v disabled or not
 */
const odisable = (el, v) => {
  if (!el) return console.warn('unexpected el argument in odisable', el)
  if (typeof el?.forEach === 'function') return el.forEach(x => odisable(x, v))
  return qc(el).disable(v)
}

module.exports = { odisable }

const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { popError } = require('../pop-box')
const { $offset, no$ } = require('../no-jquery')
const { killEvent } = require('../killEvent')
const { makeDropdown7 } = require('./makeDropdown7')
const { dates } = require('../culture')

const filterSelected = (list, selectedItems, valueField) =>
  list.filter(x => !selectedItems.find(item => _v(item, valueField) === _v(x, valueField)))

/**
 *
 * @param {object} opts
 * @param {Array} opts.list
 * @param {string} opts.url
 * @param {boolean} opts.isFilterControl
 * @param {string} opts.textFilterField
 * @param {object} opts.filterMap
 * @param {string} opts.fieldName
 * @param {string} opts.dsName
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {function} opts.itemTemplate - returns HTML string a dropdown item
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 * @param {boolean} opts.preserveOrder - if true the order will match the list rather than the order entered
 * @param {Array} opts.value - initial selected Items
 *
 * @returns {qc}
 */
const multiSelect7 = opts => {
  if (!opts.view) throw 'multiSelect7 requires opts.view'

  const viewParent = opts.view.qTop.el.parentElement

  let open = false

  opts.textField = opts.textField ?? 'Text'
  opts.valueField = opts.valueField ?? 'Value'

  let typedText = '',
    matchedOn

  let nominee, dd

  let list = opts.list ?? []

  const loadingLine = { [opts.textField ?? 'Text']: __('Loading...') }

  const findMatch = () =>
    typedText?.toLowerCase?.()
      ? list.find(
          item => (me.template(item)?.toLowerCase() ?? '').indexOf(typedText.toLowerCase()) + 1
        ) ?? list[0]
      : list[0]

  let loading = false
  const fetchList = async () => {
    const loadData = () => {
      if (matchedOn !== sub) {
        if (!loading) {
          $ajax({
            url: opts.url,
            data: {
              sub,
              filter: {
                filters: [
                  {
                    field: opts.valueField ?? 'Value',
                    operator: 'notIn',
                    value: me.getArrayOfIDs()
                  }
                ]
              }
            }
          })
            .then(res => {
              matchedOn = sub
              list = res.data || res
              loading = false
              renderList()
            })
            .catch(() => popError('lookup failed to load data.'))

          loading = true
        }
        return [loadingLine]
      }
      return list
    }

    if (opts.list) return filterSelected(opts.list, me.selectedItems, me.opts.valueField ?? 'Value')
    const sub = typedText
    return filterSelected(
      opts.url ? loadData() : [],
      me.selectedItems,
      me.opts.valueField ?? 'Value'
    )
  }

  const renderList = async () => {
    const ddParent = viewParent

    list = await fetchList()
    if (!list.includes(nominee)) nominee = findMatch()

    if (dd) {
      open
        ? dd.el?.parentElement
          ? dd.renderAsync()
          : dd.renderTo(ddParent)
        : dd.el?.parentElement && dd.el.remove()

      dd.reposition()
    }
    dd = qc('ul.multi-select7.dropdown')
    no$(ddParent)
      .find('.ow-ctl-dropdown, .dropdown')
      .forEach(el => el !== dd && el.remove())

    list = await fetchList()

    const buildItemLi = item =>
      qc('li.item', me.template(item))
        .bindState(
          () => nominee,
          function () {
            item !== loadingLine && nominee === item
              ? this.addClass('ow-selected')
              : this.removeClass('ow-selected')
          }
        )
        .on('click', () => {
          if (item === loadingLine) return
          me.select(item)
          typedText = ''
          dd.renderAsync()
        })

    const buildList = () =>
      dd.kids(
        list.length ? list.map(buildItemLi) : qc('li', __('No matches')).css({ opacity: '0.5' })
      )

    makeDropdown7(dd, ddParent, () => $offset(me.wrap().el)).bindState(() =>
      dd.css({
        width:
          (opts.listWidth === undefined ? $offset(me.wrap().el).width : opts.listWidth || 300) +
          'px'
      })
    )

    dd.kids(buildList())
      .attr({ tabindex: '-1' })
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(() => list, buildList)

    if (open) {
      dd.renderTo(ddParent)
      dd.reposition()
    }
  }

  opts.placeholder = ''

  const me = input7(opts)
    .addClass('multi-select7')
    .props({
      template(model, i) {
        if (this.opts.template) return this.opts.template(model, i)

        if (this.opts.valueTextDisplay)
          return (
            (_v(model, this.opts.valueField ?? 'Value') ?? '') +
            ' - ' +
            _v(model, this.opts.textField ?? 'Text')
          )

        return _v(model, this.opts.textField ?? 'Text') ?? 'item-' + i
      },

      getArrayOfIDs() {
        return me.selectedItems.map(x => _v(x, opts.valueField ?? 'Value'))
      },

      select(item) {
        const me = this
        if (!item || item === loadingLine) return

        me.selectedItems = [...me.selectedItems, item]

        if (opts.preserveOrder && opts.list) {
          const unsortedItems = me.selectedItems
          me.selectedItems = []
          opts.list.forEach(x => {
            if (unsortedItems.includes(x)) me.selectedItems.push(x)
          })
        }
        me.val(me.selectedItems)

        me.value('')
        me.trigger('ow-select', item)
        me.renderAsync()
      },

      val(v, model, populating = false) {
        if (arguments.length > 0 && v !== loadingLine) {
          v = v ?? []
          me.selectedItems = v
          const prev = _v(this.model, this.opts.fieldName ?? 'Value')
          let hasChanged = v !== prev
          if (hasChanged) _v(this.model, this.opts.fieldName ?? 'Value', v)
          if (hasChanged && !populating) this.trigger('ow-change')

          list = filterSelected(list, me.selectedItems, me.opts.valueField ?? 'Value')

          this.wrap().renderAsync()

          dd?.renderAsync()
        }
        return _v(this.model, this.opts.fieldName ?? 'Value')
      },

      populate(model) {
        const me = this
        const v = _v(model, me.opts.fieldName ?? 'Value')
        me.val(v, model, true)
        me.value('')
      },

      readField(model) {
        const me = this
        _v(model, me.opts.fieldName ?? 'Value', this.val())
      },

      readFilter(filters) {
        const { opts } = this
        let v = this.val()

        const operator = this.op ?? 'in'

        if (v?.length) {
          filters.push({
            field: opts.fieldName,
            operator,
            value: me.getArrayOfIDs() // just the IDs
          })
        }
        return filters
      }
    })
    .on('init', (e, el) => {
      el.readFilter = (...args) => me.readFilter(...args)
    })
    .props({ model: opts.model ?? {} })
    .on('keydown', e => {
      // enter
      if (nominee && e.which === 13 && open) {
        if (nominee === loadingLine) return
        me.select(nominee)
        typedText = ''
        renderList()
        return
      }

      // shift + enter
      if (e.which === 13 && (e.shiftKey || !open)) {
        open = !open
        renderList()
        return
      }

      // escape
      if (e.which === 27) {
        open = false
        renderList()
        return
      }

      // downarrow
      if (e.which === 40) {
        if (!open) return
        nominee = list[Math.min(list.length - 1, list.indexOf(nominee) + 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        if (!open) return
        nominee = list[Math.max(0, list.indexOf(nominee) - 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // backspace
      if (e.which === 8 && me.el.value === '' && me.selectedItems[0]) {
        me.selectedItems.pop()
        me.val([...me.selectedItems])
        typedText = ''
        renderList()
        return killEvent(e, true)
      }
    })
    .on('keyup', () => {
      if (typedText === me.el.value) return
      typedText = me.el.value

      const opening = open === false

      if (opening) open = true

      if (typedText !== matchedOn) {
        nominee = undefined
        return renderList()
      }

      if (opening) renderList()
    })
    .on('blur', () =>
      setTimeout(() => {
        if (me.el === document.activeElement) return
        open = false
        renderList()
        me.renderAsync()
      }, 100)
    )
    .bindState(() => {
      if (open && document.activeElement !== me.el) {
        console.log('closing combo not focused')
        open = false
        me.renderAsync()
      }
    })
    .bindState(
      () => open,
      () => open && (dd ?? renderList())
    )

  me.selectedItems = opts.value ?? []

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(me.selectedItems)

  me.rs().addClass('multi-line')

  me.wrap()
    .addClass('multi-select7-wrap fancy-scrollbar')
    .removeClass('input7 input')
    .css({ whiteSpace: 'normal' })
    .kids([me])
    .on('click', () => me.el.focus())
    .bindState(
      () => me.selectedItems,
      function (items) {
        renderList()
        this.kids([displayItems(me, items), me])
      }
    )
  return me
}

const displayItems = (me, items, template, textField = me.opts?.textField ?? 'Text') =>
  items.map(
    item =>
      template?.(item) ??
      qc('span.multi-select-item.ow-pill', [
        qc('label', _v(item, textField)).attr({ title: _v(item, textField) }),
        icon('times').on('click', () => {
          me.el.focus() // return focus to the input
          me.val(me.val().filter(x => x !== item))
        })
      ])
  )

const weekdays7 = opts => {
  const list = dates.shortDaysOfWeekJs
    .map((d, Value) => ({
      Text: d,
      Value,
      order: (Value - dates.firstDay + 7) % 7
    }))
    .sort((a, b) => a.order - b.order)

  const me = multiSelect7({
    preserveOrder: true,
    list,
    ...opts,
    value: []
  })

  me._val = me.val

  me.props({
    val(...args) {
      let v = args[0]
      const arrayVal = []
      if (args.length && typeof v === 'number') {
        ;[6, 5, 4, 3, 2, 1, 0].forEach(Value => {
          const i = Math.pow(2, Value)
          if (v >= i) {
            arrayVal.push(list.find(x => x.Value === Value))
            v = v - i
          }
        })
        args[0] = arrayVal.sort((a, b) => a.order - b.order)
      }

      return opts.dataType === 'int' || opts.dataType === 'integer'
        ? this.valAsInt()
        : me._val(...args)
    },
    valAsInt() {
      return this.val()?.reduce((r, day) => r + Math.pow(2, day.Value), 0)
    }
  })

  me.val(opts.value)

  return me
}

module.exports = { multiSelect7, weekdays7 }

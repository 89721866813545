const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { hextoDelphiColor } = require('../ow0/core')
const {
  colsToKendoGridColumns,
  colsToKendoSchemaFields,
  initColumnMenu,
  commandsOnGrid,
  commonGrid,
  hasFilterControls
} = require('./grids4')

/**
   * Generates BASIC Kendo Grid for your form without configure it manually.
   * Save massive time for going thru the documentation of Kendo UI and manually configure it by yourself.
   * 
   * ***************************
   * * FSP grid options object *
   * ***************************
   * Example of grid options object:
   * {
   *      name: 'promotions',
   *      id: 'PromotionID',
   *      cols: [{
   *          'type': 'string',
   *          'title': 'Product',
   *          'field': 'ProductID',
   *          'width': 150
   *      }, ...],
   *      buttonColumn: ['edit', 'delete'],
   * }
   *
   * @param {HTMLElement} el
   * @param {Object} opts
   * @param {string} opts.name - DAL name to be called, make sure "dal-" is removed.
   *                             e.g By using "dal-promotions", we should change it to "promotions" is example.
   * @param {Array} opts.buttonColumn - Currently only edit and delete, if you need more add function into
   *                                    they will fire 'command-type' to the grid
   * @param {string} opts.id - Primary key of model.
   * @param {Array<Object>} opts.cols - OW Grid Columns.
   * @param {boolean|string} opts.editable - true or 'incell'.
   * @param {any} opts.defaultValues - Adds the field values of object to newly created records

   * @returns el
   */
exports.FSPGrid = (el, opts) => {
  el = $(el)[0] // in case of incorrect jquery object

  const { _v } = common

  const kTemplate = (...args) => window.kendo.template(...args)

  const o = el
  o.opts = opts
  var $top = el.myWin()

  opts.viewdata = opts.viewdata || $top.viewdata || {}
  const registry = opts.viewdata.registry || $top.viewdata.registry || {}

  opts.iden = el.id

  opts.viewdata.winpref.grids ??= {}
  opts.userSettings = opts.viewdata.winpref.grids[opts.iden] ??= {}

  opts.useKendoAggregates = true
  opts.reorderColumns = opts.reorderColumns === false ? false : true

  o.FSPGrid = true

  o.state = {}
  const state = o.state

  const timeFields = opts.cols
    .filter(r => (r.field && r.type === 'time') || r.filterType === 'time')
    .map(c => c.field)

  const dateFields = opts.cols
    .filter(
      r =>
        r.field &&
        r.type === 'date' &&
        (r.filterType == undefined || r.filterType === 'date' || r.filterType === 'date7')
    )
    .map(c => c.field)

  const datetimeFields = opts.cols
    .filter(
      r =>
        r.field &&
        r.type === 'datetime' &&
        (r.filterType == undefined || r.filterType === 'datetime' || r.filterType === 'datetime7')
    )
    .map(c => c.field)

  const colorFields = opts.cols
    .filter(r => r.field && r.type === 'integer' && r.basicEd && r.basicEd.edType === 'colorpicker')
    .map(c => c.field)

  const ajaxLookupFields = opts.cols
    .filter(r => r.field && r.basicEd && r.basicEd.edType.substr(0, 6) === 'lookup')
    .map(c => c.field)

  const ags = opts.cols
    .filter(col => col.footer === 'sum')
    .map(col => ({ field: col.field, aggregate: 'sum' }))

  let gridCols = colsToKendoGridColumns(opts.cols, el, opts)
  let schemaFields = colsToKendoSchemaFields(opts.cols, el, opts)

  let k
  o.sub = ''

  opts.buttonColumn = opts.buttonColumn || ['edit', 'delete']
  let totalIndex

  $(el).on('row-new', function () {
    if (!o.opts.batch && o.hasChanges()) {
      o.insertNewAfterSave = true
      o.state.saving = true
      el.saveChanges()
      return
    }

    o.addRow()
    var $cell = $(el).find(
      'tbody tr:last td[role=gridcell]:not(.no-tab):not(.non-editable-cell):first'
    )

    $cell[0] && o.focusCell($cell)
    return false
  })

  function doRemove($row) {
    k.removeRow($row)
    if (!o.hasChanges()) saveCancelState(false)
  }

  el.innerDelete = (rowIndex, data) => {
    const $row = o.rowByIndex(rowIndex)
    doRemove($row)
    if (!data._new) {
      doRemove($row)
      k.removeRow($row)
      o.saveChanges(data)
    }
  }

  $(el).on('row-delete', function (e, rowIndex, data) {
    if (rowIndex === -1 || (data && !data._new && opts.viewdata.userRole?.CanDelete === false))
      return

    if (el.isNewBlank(data)) {
      var $row = o.rowByIndex(rowIndex)
      doRemove($row)
      return false
    }

    ow0.confirm(
      opts.title || opts.viewdata.name || opts.name,
      __('Are you sure you want to delete this record?'),
      r => r && el.innerDelete(rowIndex, data)
    )
    return false
  })

  $(el).on('row-save', (e, rowIndex, data) => {
    o.saveChanges(data)
    return false
  })

  $(el).on('row-cancel', (e, rowIndex, data) => {
    k.cancelChanges(data)
    return false
  })

  const propagateChanges = el => {
    if (el.prevValue !== undefined && el.prevValue !== el.value) {
      $(el).trigger('ow-change')
      // $(el).trigger('blur')
      // $(el).trigger('focusout')
    }
  }

  $(el).on('keydown', 'td input', e => {
    if (e.target.prevValue === undefined) e.target.prevValue = e.target.value
  })

  // up arrow, down arrow
  // Copy from childgrid to test key-up down navigate/editing
  if (opts.batch)
    $(el).on('focus', 'td *', function (e) {
      k = $(el).data('kendoGrid')

      if (this === e.target) {
        $(e.target).on('keyup', function (e) {
          if (e.which === 38) {
            // if it's an open combobox, leave
            if ($(e.target).closest('.k-combobox.k-state-border-down').length) return

            var $tr = $(e.target).closest('tr')
            var $cell = $(e.target).closest('td')

            var isFirstRow = $tr.is(':first-child')
            if (isFirstRow) {
              return
            }
            var prevRow = $tr.prev()
            var prevCell = $(prevRow.children()[$cell.index()])

            propagateChanges(e.target) // need it or the value doesn't update

            o.select(prevRow)
            if (opts.editable === 'inline') k.editRow(prevRow)
            else k.editCell(prevCell)
            // needs timeout to allow editing cell to set the data in the model.
            setTimeout(() => {
              // var isEditing = k.dataItem($tr).dirty;
              var data = k.dataItem($tr)
              var isNewBlank = el.isNewBlank(data)
              if (isNewBlank) {
                el.leaveRow($tr)
                var $r = k.tbody.find('tr:last')
                o.select($r)
                var $c = $($r.find('td')[$cell.index()])
                $c.trigger('focus')
              }
            }, 50)

            // prevent it from going to the kendo handler
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false
          }
          if (e.which === 40) {
            // if it's an open combobox, leave
            if ($(e.target).closest('.k-combobox.k-state-border-down').length) return

            let $tr = $(e.target).closest('tr')
            let $cell = $(e.target).closest('td')

            var isLastRow = $tr.is(':last-child')
            if (isLastRow) {
              propagateChanges(e.target) // need or the value doesn't update

              if (opts.editable === 'inline') k.editRow($tr)
              else k.editCell($cell)

              // needs timeout to allow editing cell to set the data in the model.
              setTimeout(() => {
                if (opts.disallowNewWhenExistingNewInvalid) {
                  var isRequiredBlank = el.isRequiredBlank(k.dataItem($tr))
                  if (isRequiredBlank) return el.validate()
                }

                var isNewBlank = el.isNewBlank(k.dataItem($tr))
                if (!isNewBlank) {
                  if (opts.tabOutNewRow === false) {
                    return
                  }
                  el.addRow()
                  o.select('tr:last')

                  if (opts.editable === 'inline') k.editRow(k.tbody.find('tr:last'))
                  else k.editCell($(k.tbody.find('tr:last').children()[$cell.index()]))

                  el.state.inNewRow = true
                }
              }, 50)
            } else {
              var nextRow = $tr.next()
              propagateChanges(e.target)
              o.select(nextRow)
              if (opts.editable === 'inline') k.editRow(nextRow)
              else k.editCell($(nextRow.children()[$cell.index()]))
            }
            e.preventDefault()
            e.stopImmediatePropagation()
            e.stopPropagation()
            return false
          }
        })
      }
    })

  const buttonCol = {
    command: [],
    title: '',
    attributes: { class: 'command-cell no-tab' }
  }
  opts.buttonColumn.forEach(btn => {
    if (typeof btn === 'string')
      btn = {
        type: btn,
        imageClass: 'k-' + btn
      }

    const { userRole } = opts.viewdata

    buttonCol.command.push({
      name: btn.type + '-row',
      text: '',
      imageClass: btn.imageClass,
      iconClass:
        'k-icon ' +
        (btn.type === 'delete' && userRole?.CanDelete === false ? 'deleteBtnDisabled' : ''),
      click(e) {
        e.preventDefault()
        const $tr = $(e.target).closest('tr')
        $(el).trigger('row-' + btn.type, [$tr.index(), k.dataItem($tr)])
      }
    })
  })

  if (buttonCol.command.length) {
    buttonCol.width = buttonCol.width = 20 + buttonCol.command.length * 15 + 'px'
    gridCols.push(buttonCol)
  }

  function getRestfulURL(data) {
    if (typeof data[opts.id] === 'undefined') return 'data/' + opts.name + '/-1'

    //to cater routes.js recognize, else might getting 403 status
    const id = data[opts.id]

    return typeof id === 'string'
      ? 'data/' + opts.name + '/' + encodeURIComponent(id) + (id.indexOf('.') > -1 ? '/' : '')
      : 'data/' + opts.name + '/' + encodeURIComponent(JSON.stringify(id))
  }

  o.insertNewAfterSave = false
  var expandedItems = {}
  var maxPageSize = 9999

  o.url = opts.url || 'data/' + opts.name

  opts.bindBtnsonSelect = opts.bindBtnsonSelect || ['edit', 'delete', 'copy']

  var prefPageSize = registry.Pagination || opts.userSettings.pageSize

  let numArray = [20, 50, 100]
  if (prefPageSize != 20 && prefPageSize != 50 && prefPageSize != 100) {
    numArray = [20, 50, 100, prefPageSize]
    numArray.sort()
  }

  const kOpts = {
    autoBind: false,

    dataSource: {
      serverFiltering: true,

      serverSorting: true,

      serverPaging: opts.pageable !== false,

      pageSize:
        opts.pageable === false
          ? maxPageSize
          : prefPageSize
            ? prefPageSize
            : opts.pageSize
              ? opts.pageSize
              : 20,

      serverAggregates: true,

      error(e) {
        if (!e.xhr) return // just abort.

        if (!e.xhr.responseText) return ow0.popError(e.xhr)
        var errMsg = ''
        try {
          var objRes = JSON.parse(e.xhr.responseText)
          errMsg = objRes.errMsg || objRes.message || ''
        } catch {
          errMsg = e.xhr.responseText
        }
        ow0.popError(errMsg || e.xhr.statusText)
      },

      transport: {
        read(options) {
          var isSelectMode = opts.mode && opts.mode.indexOf('select') >= 0
          if (o.state.editing && !isSelectMode) {
            //cater for Select Items
            ow0.popInvalid(
              __('You must cancel or save any changes before attempting to reload the grid.')
            )
            return options.error()
          }
          if (typeof options.data.filter === 'undefined') options.data.filter = {}

          var flattenFieldsAfterLoad = []

          if (options.data.filter) {
            options.data.filter.filters = options.data.filter.filters || []

            const formatKendoFilters = (f, fields, fn) => {
              if (!f || !fields) return
              if (f.filters) f.filters.forEach(f1 => formatKendoFilters(f1, fields, fn))
              if (f.value && fields.indexOf(f.field) > -1) f.value = fn(f.value)
            }

            const formatTimeFilterValue = (f, fields) => {
              formatKendoFilters(f, fields, x => new Date(x).toHHMM())
            }

            const formatDateFilterValue = (f, fields) => {
              formatKendoFilters(f, fields, x => new Date(x).toJSON())
            }

            const formatDateTimeFilterValue = (f, fields) => {
              formatKendoFilters(f, fields, x => new Date(x).toJSON())
            }

            formatTimeFilterValue(options.data.filter, timeFields)
            formatDateFilterValue(options.data.filter, dateFields)
            formatDateTimeFilterValue(options.data.filter, datetimeFields)

            var lookupField = []
            options.data.filter.filters.forEach(f => {
              const cField = colorFields.filter(c => f.field === c)
              if (cField.length > 0) f.value = hextoDelphiColor(f.value)

              opts.cols
                .filter(c => f.field === c.nestedField)
                .forEach(c => {
                  f.field = c.field
                  flattenFieldsAfterLoad.push(c)
                })

              ajaxLookupFields
                .filter(a => f.field === a)
                .forEach(field => {
                  var control = o.findColumnFilterControl(f.field)
                  if (control && control.readFilter) lookupField.push({ control, field })
                })
            })

            //read the control
            lookupField.forEach(function (x) {
              var currentFilter = (options.data.filter.filters || []).filter(
                a => x.field == a.field
              )
              options.data.filter.filters = (options.data.filter.filters || []).filter(
                a => x.field != a.field
              )
              var newFilter = []
              x.control.opts.op = currentFilter[0].operator
              x.control.readFilter(newFilter)
              options.data.filter.filters = options.data.filter.filters.concat(newFilter)
            })

            // sort
            ;(options.data.sort || []).forEach(s =>
              opts.cols.filter(c => s.field === c.nestedField).forEach(c => (s.field = c.field))
            )
          }

          // convert all boolean filter values into 1 or 0 int
          options.data.filter.filters.forEach(f => {
            f.value = f.value === true ? 1 : f.value
            f.value = f.value === false ? 0 : f.value
          })

          var customFilters = { filters: [] }
          o.state.customFilters = o.state.customFilters || customFilters
          o.state.sub = o.state.sub || ''
          if (o.readFilterControls) {
            o.readFilterControls(options.data.filter) // Read out the ow-filter-controls
            o.readFilterControls(customFilters)
          }

          el.disableBindBtns(true)

          var url = el.url
          var base = url.split('?')[0]
          var qry = url.split('?')[1] ? url.split('?')[1].split('&') : []
          if (o.sub !== '') qry.push('sub=' + o.sub)
          url = base + '?' + qry.join('&')

          var resetPaging =
            JSON.stringify(state.customFilters) !== JSON.stringify(customFilters) ||
            state.sub !== o.sub ||
            JSON.stringify(state.qry) !== JSON.stringify(qry)
          state.filters = options.data.filter
          state.customFilters = customFilters
          state.sub = o.sub
          state.qry = qry

          if (resetPaging && options.data.page !== 1 && opts.pageable !== false)
            k.dataSource.page(1)

          if (opts.dataSourceGroup)
            opts.dataSourceGroup.forEach(f =>
              opts.cols
                .filter(c => f.field === c.nestedField)
                .forEach(c => flattenFieldsAfterLoad.push(c))
            )

          flattenFieldsAfterLoad = flattenFieldsAfterLoad.filter(
            (item, pos, self) => self.indexOf(item) == pos
          )

          if ($top.find('> div.ow-loading-mask').length > 0)
            $top.find('.k-grid-content .k-loading-mask')[0]?.remove()

          return $ajax({
            view: opts.view ?? $top.view(),
            showProgress: true,
            type: opts.httpMethod ?? 'get',
            url,
            data: options.data
          })
            .then(response => {
              response.data?.forEach(rec =>
                flattenFieldsAfterLoad.forEach(f => (rec[f.nestedField] = f.template(rec)))
              )

              var result = options.success(response)
              saveCancelState(false)

              if (o.insertNewAfterSave) {
                o.insertNewAfterSave = false
                setTimeout(() => {
                  o.addRow()
                  var newTr = k.tbody.find('tr:last-child')
                  o.focusCell(newTr.find('td:not(.no-tab):not(.non-editable-cell):first'))
                }, 10)
              }
              el.afterSave?.()

              setTimeout(() => (o.state.saving = false), 10)

              return result
            })
            .catch(err => {
              o.state.saving = false
              console.error('read error:', err)
              options.error(err)
            })
        },
        update(options) {
          o.state.saving = true

          $top.progress()

          $.ajax({
            type: 'PUT',
            url: getRestfulURL(options.data),
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(options.data),
            success(response) {
              o.state.saving = false
              $top.progress(false)
              saveCancelState(false)
              console.log('update: ' + JSON.stringify(response))
              ow0.popSaveOK(response)

              options.success(response)

              if ($top.viewdata.triggerClose) {
                $top.viewdata.triggerClose = false
                o.cancelChanges()
                $top.closeForm(1)
              } else o.refresh()
            },
            error(err) {
              o.state.saving = false
              $top.progress(false)
              console.log('update error: ' + JSON.stringify(err))
              ow0.popSaveError(err)
              options.error(err)
            }
          })
        },
        destroy(options) {
          $top.progress()
          $.ajax({
            type: 'DELETE',
            url: getRestfulURL(options.data),
            contentType: 'application/json',
            data: JSON.stringify(options.data),
            success(response) {
              $top.progress(false)
              saveCancelState(false)
              console.log('delete: ' + JSON.stringify(response))
              ow0.popDeleteOK(response)
              options.success(response)
              o.refresh()
            },
            error(err) {
              $top.progress(false)
              console.log('delete error: ' + JSON.stringify(err))
              if (err.status != '500') ow0.popDeleteError(err)
              options.error(err)
              o.cancelChanges()
              o.refresh()
            }
          })
        },
        create(options) {
          $top.progress()

          const innerCreate = options =>
            $.ajax({
              type: 'POST',
              url: 'data/' + opts.name,
              contentType: 'application/json',
              dataType: 'json',
              data: JSON.stringify(options.data),
              success(response) {
                o.state.saving = false
                $top.progress(false)
                saveCancelState(false)
                el.newrow = null
                console.log('create: ' + JSON.stringify(response))
                ow0.popSaveOK(response)
                options.success(response)
                if ($top.viewdata.triggerClose) {
                  $top.viewdata.triggerClose = false
                  o.cancelChanges()
                  $top.closeForm(1)
                } else setTimeout(() => o.refresh(), 10)
              },
              error(err) {
                o.state.saving = false
                $top.progress(false)
                console.log('create error: ' + JSON.stringify(err))
                return options.error(err)
              }
            })

          if (opts.checkUniqueID === false || !opts.id) return innerCreate(options)

          var id = options.data[opts.id]
          if (id === null || id === '') return innerCreate(options)

          $.ajax({
            type: 'GET',
            url: 'data/' + opts.name + '/' + id,
            contentType: 'application/json',
            dataType: 'json',
            data: options.data,
            success(response) {
              if (response && response[opts.id] === id && response.Deleted !== true) {
                var idCol = opts.cols.find(c => c.field === opts.id)
                ow0.popInvalid(
                  ((idCol && idCol.title) || opts.id) + ' [' + id + '] already in use.'
                )
                o.state.saving = false
                ow0.windows.resolveFocus()
                $top.progress(false)
                return options.error('')
              }
              return innerCreate(options)
            },
            error() {
              return innerCreate(options)
            }
          })
        }
      },

      batch: opts.batch ? true : false,

      schema: {
        model: {
          id: opts.id,
          fields: schemaFields
        },
        data: 'data',
        total: 'total',
        aggregates: r => r.aggregates
      },

      change(e) {
        var $current = o.current()
        if ($current && !$current.is('td')) $current = null // if it's a th

        var currentDataItem = $current ? k.dataItem($current) : null

        if (opts.lineNumberField && e.action === 'add')
          currentDataItem[opts.lineNumberField] = e.items.length

        if (e.action === 'add' || e.action === 'itemchange' || e.action === 'remove')
          saveCancelState(true)
        else if (e.action === 'sync') totalIndex = this.data().length - 1 //update grid total index count

        if (opts.change) opts.change(currentDataItem)

        $(el).trigger('ow-grid-change', [currentDataItem, $current])
      }
    },

    navigatable: true,

    reorderable: opts.reorderable === false ? false : true,

    filterable: opts.hasFilters === false ? false : { mode: 'row' },

    sortable: opts.sortable !== undefined ? opts.sortable : true,

    sort(e) {
      var dataItems = k.dataItems()
      if (!dataItems || (dataItems && dataItems.length <= 0)) e.preventDefault()
    },

    resizable: opts.resizable !== undefined ? opts.resizable : true,

    columnResize(e) {
      $top[0] && qc($top[0])?.renderAsync?.()
      k.resize()

      const col = e.column.owGridCol
      ;(opts.userSettings.cols[col.iden] ?? {}).width = Math.round(e.newWidth)
    },

    selectable: opts.selectable !== undefined ? opts.selectable : true,

    pageable:
      opts.pageable === undefined
        ? {
            numeric: false,
            input: true,
            pageSizes: numArray,
            messages: { itemsPerPage: __('per page') }
          }
        : opts.pageable,

    change() {
      var $tr = o.currentRow()
      el.disableBindBtns($tr ? false : true)
      $(el).trigger('ow-grid-change', [k.dataItem($tr), $tr]) //trigger when user select row/column
    },

    edit(e) {
      if (!opts.batch) {
        const hasOtherRowChanges = k.dataSource.data().find(d => d !== e.model && d.dirty)
        if (hasOtherRowChanges && e.model && !e.model.dirty) {
          k.closeCell()
          return false
        }
      }

      el.state.inNewRow = e.model._new

      var coords = [e.container.parent().index(), e.container.index()]

      e.container.on('keydown', e => {
        // F2 - save
        if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey)
          if (opts.editable !== false) {
            e.target.focus()
            setTimeout(() => {
              o.state.saving = true
              if (el.state.inNewRow) el.insertNewAfterSave = true
              else el.afterSave = () => o.cellFocus(coords[0], coords[1])
              el.saveChanges()
            }, 100)
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false
          }
      })
    },

    dataBound() {
      k = $(el).data('kendoGrid')

      const { CanWrite, CanDelete } = opts.viewdata.userRole || {}

      if (opts.buttonColumn.indexOf('delete') != -1 && CanWrite && CanDelete === false) {
        var currIndex = k.dataSource.view().length - 1
        if (totalIndex == undefined) totalIndex = currIndex

        if (totalIndex >= currIndex) {
          totalIndex = currIndex
          saveCancelState(false)
          return
        } else
          for (currIndex; currIndex > totalIndex; currIndex--)
            $(k.tbody.find('tr')[currIndex])
              .find('.deleteBtnDisabled')
              .removeClass('deleteBtnDisabled')
      }

      if (opts.editable !== false) {
        $(el)
          .find('td:not(.command-cell) span:not(.read-only)')
          .closest('td')
          .attr('tabindex', 0)
          .removeClass('non-editable-cell')
          .removeClass('no-tab')
        $(el).find('.k-grid-delete-row').attr('tabindex', -1)

        $(el)
          .find('td span.read-only')
          .closest('td')
          .attr('tabindex', -1)
          .addClass('non-editable-cell')
          .addClass('no-tab')
        $(el).find('.no-tab *').attr('tabindex', -1) // tabindex for buttons in buttonCol

        $(el)
          .find('td[role=gridcell]:not(.no-tab):not(.non-editable):not(.command-cell)')
          .on('focus', () => {
            if (el.state.saving) ow0.log('---------focus cell while saving - IGNORED', 'gridFocus')
          })

        $(el).find('tr td.command-cell,tr td.no-tab,tr td.non-editable').attr('tabIndex', -1)
        $(el)
          .find('tr td[role=gridcell]:not(.no-tab):not(.non-editable):not(.command-cell)')
          .attr('tabIndex', 0)
      }

      if (el.id)
        if (opts.allowColumnMenu || opts.allowColumnMenu == undefined)
          initColumnMenu($top, '#' + el.id, opts.allowSaveTemplate)

      if (opts.expandAllGroup) o.expandAll()
      else o.collapseAll()

      // for tabbing on last line, creates new.
      if (k.options?.editable !== false)
        $(el)
          .find('tr')
          .on('keydown', 'td', function (e) {
            if (e.which === 9) {
              var back = e.shiftKey

              var $cell = $(e.target).closest('td')
              var $rowEl = $cell.closest('tr')

              var isLastCell =
                $cell.index() >=
                $rowEl
                  .find('td[role=gridcell]:not(.non-editable-cell):visible:not(.no-tab):last')
                  .index()

              var isFirstCell =
                $cell.index() <=
                $rowEl
                  .find('td[role=gridcell]:not(.non-editable-cell):visible:not(.no-tab):first')
                  .index()

              propagateChanges(e.target)

              var leavingRow = (back && isFirstCell) || (!back && isLastCell)
              if (leavingRow) return setTimeout(() => el.leaveRow($rowEl, back), 10)

              // delay so that change can propagate
              setTimeout(
                () => o.moveNextCell($cell.parent().index(), $cell.index(), back, true),
                10
              )
            }
          })

      if (expandedItems[k.dataSource.page()])
        expandedItems[k.dataSource.page()].forEach(name => o.expandGroupByName(name))

      $(el).trigger('ow-grid-databound') // todo: add appropriate extra info

      opts.userSettings.pageSize = k.dataSource.pageSize()
    },

    navigate(e) {
      el.indicateCurrent()
      var $cell = $(e.element)

      if (opts.editable && ($cell.hasClass('non-editable-cell') || $cell.hasClass('command-cell')))
        return ow0.log(
          '#### grid non-editable field nav. ' +
            e.element +
            ' -> ' +
            JSON.stringify(el.state.lastFocused),
          'gridFocus'
        )

      const { selectable } = k.options

      if (typeof selectable !== 'boolean' && selectable.indexOf('multiple') >= 0) return

      var $row = $cell.closest('tr')
      if ($row.length && selectable !== false) o.selectRow($row)
    },

    columns: gridCols
  }

  if (ags.length) kOpts.dataSource.aggregate = ags

  if (opts.excelExportEnable) {
    kOpts.excel = { allPages: true }
    kOpts.excelExport = function (e) {
      var data = e.data
      var gridColumns = e.sender.columns
      var sheet = e.workbook.sheets[0]
      var visibleGridColumns = []
      var columnTemplates = []
      // Create element to generate templates in.
      var elem = document.createElement('div')

      // Get a list of visible columns
      for (var i = 0; i < gridColumns.length; i++)
        if (!gridColumns[i].hidden) visibleGridColumns.push(gridColumns[i])

      // Create a collection of the column templates, together with the current column index
      for (let i = 0; i < visibleGridColumns.length; i++)
        if (visibleGridColumns[i].template)
          columnTemplates.push({
            cellIndex: i,
            template: kTemplate(visibleGridColumns[i].template)
          })

      // Traverse all exported rows.
      for (let i = 1; i < sheet.rows.length; i++) {
        var row = sheet.rows[i]
        // Traverse the column templates and apply them for each row at the stored column position.

        // Get the data item corresponding to the current row.
        let dataItem = data[i - 1]
        for (var j = 0; j < columnTemplates.length; j++) {
          var columnTemplate = columnTemplates[j]
          // Generate the template content for the current cell.
          elem.innerHTML = columnTemplate.template(dataItem)
          if (row.cells[columnTemplate.cellIndex] != undefined)
            // Output the text content of the templated cell into the exported cell.
            row.cells[columnTemplate.cellIndex].value = elem.textContent || elem.innerText || ''
        }
      }
    }
  }

  if (opts.recognizeExpand)
    $(el)
      .on('mousedown', 'tr.k-grouping-row a.k-i-expand', e => {
        const name = $(e.target).closest('tr').text()
        // remove
        expandedItems[k.dataSource.page()] = (expandedItems[k.dataSource.page()] || []).filter(
          v => v !== name
        )
        // readd
        expandedItems[k.dataSource.page()].push(name)
      })
      .on('mousedown', 'tr.k-grouping-row a.k-i-collapse', e => {
        var name = $(e.target).closest('tr').text()
        // remove
        expandedItems[k.dataSource.page()] = (expandedItems[k.dataSource.page()] || []).filter(
          v => v !== name
        )
      })

  if ($top.viewdata.userRole && !$top.viewdata.userRole.CanWrite) opts.editable = false

  if (typeof opts.editable === 'undefined' || opts.editable === 'incell' || opts.editable === true)
    opts.editable = {
      createAt: 'bottom',
      confirmation: false
    }

  if (opts.editable !== false) opts.editable.confirmation = false

  kOpts.editable = opts.editable

  if (opts.gridIden) $(el).attr('gridIden', opts.gridIden) //daemon: Required when more than 1 grid in a form

  if (opts.dataSourceGroup) {
    if (!Array.isArray(opts.dataSourceGroup)) opts.dataSourceGroup = [opts.dataSourceGroup]
    var nestedField = opts.dataSourceGroup.map(x => {
      x.field = x.field.split('.').join('_')
      return x
    })
    // k.dataSource.group(nestedField)
    kOpts.dataSource.serverGrouping = false
    kOpts.dataSource.group = nestedField
  }

  $(el).kendoGrid(kOpts)
  k = $(el).data('kendoGrid')
  commonGrid(el, opts)
  commandsOnGrid(el)
  if (!el.id) console.log('Grid needs id for column menu to work.')
  else if (opts.allowColumnMenu || opts.allowColumnMenu == undefined)
    initColumnMenu($top, '#' + el.id, opts.allowSaveTemplate)

  o.leaveRow = function leaveRow($row, back, noSetFocus, hasOtherRowChanges) {
    if (!opts.editable) return

    if (o.state.saving || o.state.navigating)
      return ow0.log(
        ' !!!!!leaveRow IGNORED' + $row.index() + ' state: ' + JSON.stringify(state.lastFocused),
        'gridFocus'
      )

    var tabForward = back === false
    var tabBack = back === true
    var notTab = !tabBack && !tabForward // ie. undefined

    // what is focused now...
    ow0.log('leaverowFOCUSED: ' + $(el).find(':focus')[0], 'gridFocus')
    var $focusedCell = $(el).find(':focus').closest('td')

    var notInCell = !$focusedCell.is('td')
    var pos = { row: $focusedCell.parent().index(), cell: $focusedCell.index() }

    ow0.log(
      '>>>>>>>leaveRow ' +
        $row.index() +
        ' back:' +
        back +
        ' focused pos: ' +
        (notInCell ? 'table' : JSON.stringify(pos)),
      'gridFocus'
    )

    var dataItem = k.dataItem($row)
    var isNewBlank = el.isNewBlank(dataItem)
    var isLastRow = $row.is(':last-child')
    var isFirstRow = $row.is(':first-child')

    var nextCellRow = $row.index() + (tabBack ? -1 : 1)
    if (notTab && !notInCell) {
      o.state.lastFocused = pos
      nextCellRow = pos.row
    }

    if (isNewBlank) {
      if (tabBack)
        return setTimeout(() => {
          doRemove($row)
          var $c = k.tbody.find(
            'tr:eq(' + nextCellRow + ') td:not(.no-tab):not(.non-editable-cell):last'
          )
          o.focusCell($c)
        }, 10)

      doRemove($row)
      o.state.lastFocused = pos // we need because it gets set back to 0,0
      el.resolveFocus()
    }

    o.validate()

    if ((dataItem.dirty || hasOtherRowChanges) && !opts.batch) {
      if (isFirstRow && tabBack) {
        o.state.saving = true
        el.saveChanges()
        return
      }

      if (tabForward && (isLastRow || hasOtherRowChanges)) {
        o.insertNewAfterSave = opts.tabOutNewRow !== undefined ? opts.tabOutNewRow : true
        o.state.saving = true
        el.saveChanges()
        return
      }

      if (!notTab)
        el.afterSave = () => {
          el.afterSave = null
          if (noSetFocus !== true) {
            ow0.log('entering nextrow:' + nextCellRow)
            var $c = k.tbody.find(
              'tr:eq(' + nextCellRow + ') td:not(.no-tab):not(.non-editable-cell):first'
            )
            o.focusCell($c)
          }
        }

      o.state.saving = true
      el.saveChanges()

      return
    }

    if (back && isFirstRow) return
    if (tabForward && isLastRow) if (opts.tabOutNewRow !== false) el.addRow()

    if (!notTab)
      if (noSetFocus !== true) {
        var $c = k.tbody.find('tr:eq(' + nextCellRow + ') td:not(.no-tab,.k-group-cell):first')
        ow0.log('new row focusing: ' + nextCellRow, 'gridFocus')
        o.focusCell($c)
      }
  }

  o.hasChanges = () => k.dataSource.hasChanges()

  o.cancelChanges = function () {
    k.cancelChanges()
    saveCancelState(false)
  }

  o.saveChanges = function () {
    var res = o.validate()
    if (res && res.resVal) k.saveChanges()
    else if (res.resErr) {
      o.state.saving = false
      ow0.popInvalid(html(res.resErr.join('<br>')))
    }
  }

  o.search = function (sub) {
    if (sub !== o.sub) {
      o.sub = sub
      k.dataSource.read()
    }
  }

  o.refreshField = function (rec, field) {
    var v = _v(rec, field)
    rec.set(field, v !== null ? null : '')
    rec.set(field, v)
  }

  o.rowChanged = function (record) {
    var id = record[opts.id]
    record[opts.id] = null
    record.set(opts.id, id) // forces the grid to acknowledge the changed field and update.
  }

  o.collapseAll = () => k.tbody.find('tr.k-grouping-row').forEach(el => k.collapseGroup(el))
  o.expandAll = () => k.tbody.find('tr.k-grouping-row').forEach(el => k.expandGroup(el))
  o.expandGroupByName = name =>
    k.tbody.find('tr.k-grouping-row').forEach(el => $(el).text() === name && k.expandGroup(el))

  const getBoundControls = (ref = el.id) =>
    $top.find('[data-target-ref="' + ref + '"], [data-field-for="' + ref + '"]')

  getBoundControls()
    .filter('button')
    .forEach(el => {
      $(el).on(
        'ow-datastatechange',
        (e, s) =>
          ($(el).data('command') === 'save' || $(el).data('command') === 'cancel') &&
          el.odisable(!s.editing)
      )
    })

  const saveCancelState = val => {
    // val is editing
    state.editable = opts.editable
    state.current = o.current()
    state.editing = val
    state.dsName = el.id

    // Look for save and cancel buttons bound to the grid
    // broadcastStateChange
    getBoundControls().forEach(el => $(el).trigger('ow-datastatechange', [state]))

    if (val) $top.addClass('dirty')
    else $top.removeClass('dirty')
  }

  el.saveCancelState = saveCancelState

  hasFilterControls(el)

  el.state.tableHasFocus = false

  // click on grid bg adds row
  $(el).on('click', '.k-grid-content', function (e) {
    if (
      k.options.editable !== false &&
      $(e.target).is('.k-grid-content') &&
      !el.state.tableHasFocus
    ) {
      if (opts.tabOutNewRow === false) return

      var $lastRow = $(e.target).find('tr:last')
      if ($lastRow.length > 0) {
        if (opts.disallowNewWhenExistingNewInvalid) {
          var isRequiredBlank = el.isRequiredBlank(k.dataItem($lastRow))
          if (isRequiredBlank) {
            el.validate()
            return
          }
        }
        // emulate tabbing forward form last row
        const hasOtherRowChanges = k.dataSource.data().find(d => d.dirty)
        setTimeout(() => el.leaveRow($lastRow, false, false, hasOtherRowChanges), 1)
      } else {
        el.addRow()
        setTimeout(() => {
          var $cell = k.tbody.find('tr:last td[role=gridcell]:not(.no-tab):first')
          $cell[0] && o.focusCell($cell)
          el.state.inNewRow = true
        }, 10)
      }
      e.stopPropagation()
      e.preventDefault()
      return false
    }
    e.stopPropagation() // stops it from going up the DOM.
  })

  return o
}

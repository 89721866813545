// const kendo = require('kendo')
const { qc } = require('../cmp/qc')
const { formatString } = require('../ctl-format')
const { dates } = require('../dates')
const { htmlEncode } = require('../html-encode')
const { iconCodes } = require('../icon-codes')
const { odisable } = require('../odisable')
const { popInvalid, popError } = require('../pop-box')

// ow lib - namespace for OrbisWeb global vars and functions and controllers
// jQuery dependency
// scope would normally be window

if (typeof $ === 'undefined')
  if (typeof window !== 'undefined') window.$ = null
  else global.$ = null

if (typeof Element !== 'undefined') {
  // storing the object that extended behaviours are attached to so we can retrieve them later
  Element.prototype.ovar = function ($el) {
    return $($el || this)[0]
  }
  if (window.$)
    $.fn.ovar = function () {
      return this[0] ?? {}
    }

  /**
   * sets element to disabled
   *
   * @param {bool=} val - default is true = disable, false will enable
   */
  Element.prototype.odisable = function (v) {
    return odisable(this, v)
  }

  if (window.$)
    $.fn.odisable = function (v = true) {
      odisable(this, v)
      return this
    }

  // calls ow object val() on jquery reference
  if (window.$)
    $.fn.oval = function (...args) {
      if (!this.length) return
      var result = this.map(function () {
        return this.val ? this.val(...args) : $(this).val(...args)
      })
      return result[0]
    }

  if (window.$)
    $.fn.hideResourceSet = function () {
      this.closest('.resource_set').hide()
      return this
    }

  if (window.$)
    $.fn.showResourceSet = function () {
      this.closest('.resource_set').show()
      return this
    }

  if (window.$)
    $.fn.setLabel = function (value) {
      this.closest('.resource_set').find('label').text(value)
      return this
    }
}

window.addEventListener(
  'keydown',
  e => {
    if (e.keyCode !== 9) return
    if (e.target !== document.activeElement) return

    if (!e.target.requestTabOut) return

    const canTabOut = e.target.requestTabOut(e.shiftKey) !== false

    if (canTabOut) return

    console.log('InvalidValue, please use your mouse to navigate between textboxes', 'tab')
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    return false
  },
  true
)

// simplified tabbedIn tracking ...
window.addEventListener(
  'keydown',
  e => {
    if (e.target.tabbedIn !== true) e.target.tabbedIn = false
  },
  true
)

window.addEventListener(
  'keyup',
  e => {
    if (e.keyCode === 9 && !('tabbedIn' in e.target)) e.target.tabbedIn = true
  },
  true
)

window.addEventListener(
  'focus',
  e => {
    if (new Date().getTime() - (e.target.blurTime || 0) < 100) return // if it lost focus to dropdown etc for a sec.
    delete e.target.tabbedIn
  },
  true
)

window.addEventListener(
  'blur',
  e => {
    e.target.blurTime = new Date().getTime()
  },
  true
)

// prevent tab holddown
;['keydown', 'keypress', 'keyup'].forEach(et =>
  window.addEventListener(
    et,
    e => {
      if (e.keyCode === 9 && e.repeat) {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    },
    true
  )
)

const toString = (...args) => (kendo?.toString ? kendo?.toString(...args) : formatString(...args))

const ow0 = {
  version: 0,
  views: { comment: 'Same object is used by all libs' },
  controls: { version: 0 },
  windows: {},
  grids: {},
  ...require('../pop-box'),
  ...require('./core'),
  ...require('./notify'),
  ...require('./windows'),
  ...require('./viewPreprocessor'),
  ...require('./overviewCon'),
  ...require('./gridCon'),
  ...require('./editorCon'),
  ...require('./webStorage'),
  ...require('./dragndrop'),
  ...require('./owAutos'),
  dates,
  parseDate: dates.parseDate,
  formatDate: dates.formatDate,
  formatString: formatString,
  toString,
  formatDateTime: dates.formatDateTime,
  htmlEncode,
  escapeHtml: htmlEncode,
  iconCodes: iconCodes,
  alert: s => alert(s),
  ready: fn => $(fn),
  openHelpFile: (funcid, viewurl, currentUserLang) =>
    $ajax({
      type: 'POST',
      url: '/data/usersetting/method/gethelpfilesettingbyfuncid',
      data: {
        funcid: funcid,
        requestUrl: viewurl,
        lang: currentUserLang
      }
    })
      .then(response => {
        if (!response.helpfileurl) return popInvalid(__('No help available.'))

        var viewportwidth = document.documentElement.clientWidth
        var viewportheight = document.documentElement.clientHeight
        window.resizeBy(-300, 0)
        window.moveTo(0, 0)
        window.open(
          response.helpfileurl,
          '',
          'width=' +
            viewportwidth / 2 +
            ', height=' +
            viewportheight +
            ', left=' +
            (viewportwidth - 300) +
            ', top=0'
        )
      })
      .catch(err => popError('ERROR', err.Msg, 10000))
}

exports.ow0 = ow0

window.ow0 = ow0
window.ow = ow0

/**
 * jQuery trigger override
 *
 * Why?  we are currently (Jue 2022) looking to convert all views from EJS/kendo/jquery to qc() QuickCmp
 *
 * To make the code conversion work, we need the events to fire using the native event engine.
 *
 * if we call $(el).trigger('ow-change') ow-change does not trigger native events and therefore
 *  the handler in qcObject.on('ow-change', handler) does not get triggered
 *
 * so we can't change the $(el).on() to qc(el).on().  This override ensures it does.
 *
 */

if (window.$) {
  const jqueryTrigger = $.fn.trigger

  // // (event, data, elem, onlyHandlers)
  $.fn.trigger = function (et, ...args) {
    const etIsEventObject = typeof et === 'object'

    // we exclude blur and focus because of jquery and kendo data change in ow4 grids
    // we use $(control).trigger('focus') before moving to the next row on arrow down.
    // For some reason it doesn't work if we trigger natively so we need to pass thru to jQuery.
    // For type and tab out we check what constructor was used for
    if (etIsEventObject)
      //  || nativeEventTypes.indexOf(et) !== -1)
      return jqueryTrigger.call(this, et, ...args)

    const [args0 = []] = args
    const qcArgs = args[0] && Array.isArray(args[0]) ? args0 : []

    this.forEach(el => {
      // sometimes the object isn't an HTMLElement - see strange use of ow5.dc restoreData!?
      if (!el.dispatchEvent) return jqueryTrigger.call($(this), et, ...args)

      return qc(el).trigger(et, ...qcArgs)
    })

    return this
  }
}

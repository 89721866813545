const { combo7 } = require('./combo7')

module.exports.currentStore7 = opts => {
  Object.assign(
    opts, // use the original instance
    {
      fieldName: 'StoreID',
      valueTextDisplay: true,
      valueField: 'StoreID',
      textField: 'StoreName',
      objectFieldName: 'BOStoreInfo',
      classes: 'w4',
      ...opts
    }
  )

  if (ow.currentStore.StoreType === 2) {
    opts.noFilter = true
    const dalName = opts.view.viewdata.url?.split('/').slice(-1)[0].split('-')[0] ?? 'report'
    if (!opts.url) opts.url = 'data/' + dalName + '/lookup/stores'
  } else {
    delete opts.url
    opts.list = [ow0.currentStore]
  }

  if (ow0.currentStore.StoreType !== 2) opts.disabled = true

  const me = combo7(opts)

  const comboVal = me.val

  return me
    .props({
      val(...args) {
        let [v, m] = args
        if (m) opts.model = opts.model || m
        if (ow0.currentStore.StoreType !== 2) {
          me.selectedItem({ ...ow0.currentStore })
          v = ow0.currentStore.StoreID
          if (!args.length) return v
        }
        let v1 = comboVal.call(me, ...args)
        return v1
      }
    })
    .on('init', (e, el) => {
      el.opts = opts
      opts.ctlType = 'current-store'
      me.val(me.val(), undefined, true)
    })
    .attr({ 'data-ctl-type': 'current-store' })
}

const { qc } = require('../cmp/qc.js')
const { checklistbox5 } = require('../controls/checklist.js')
const { ow4 } = require('../ow4/ow4')
const { registerCtl, basicEditorInits } = require('./ctl5.js')

const ow5 = {
  version: 5,
  ...require('./wireUpView5'),
  ...require('./dc5.js'),
  ...require('./ctl5.js'),
  ...require('./gridcols5.js'),
  ...require('./grid5.js'),
  controls: {
    version: 5,
    basicEditorInits,
    ...require('./combo5.js'),
    ...require('./storeCombo5.js')
  },
  grids: {
    version: 5
  }
}
registerCtl('checklistbox', checklistbox5)
exports.ow5 = ow5

Object.setPrototypeOf(ow5, ow4)
Object.setPrototypeOf(ow5.controls, ow4.controls)
Object.setPrototypeOf(basicEditorInits, ow4.controls.basicEditorInits)
Object.setPrototypeOf(ow5.grids, ow4.grids)

qc('link.ow5css')
  .attr({ rel: 'stylesheet', href: '/styles/ow5.css?' + (window.cacheUrl || new Date().getTime()) })
  .renderTo(document.head)

window.ow5 = ow5
window.ow = ow5

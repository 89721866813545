const dates = require('../dates')
const { resolveDate, formatDate } = dates

const { dropdown } = require('./dropdown')
const { calendar6 } = require('./calendar6')
const { icon6 } = require('./icons')
const { react } = require('./data-model')
const { killEvent } = require('../killEvent')
const { $cmp } = require('../cmp/$cmp')
const { ctlParsers } = require('../ctl-format')
const { culture } = require('../culture')

const date6Props = {
  requestTabOut() {
    this.resolve()
    return true
  },

  resolve() {
    const el = this.el
    if (el.value) {
      var v = resolveDate(el.value, true)
      if (el.value !== v) el.value = v
    }
  },

  updateContent() {
    const { el, dropdown } = this

    dropdown.css({ width: undefined })
    const cal = calendar6(this.val())
    cal.select = value => {
      this.val(value) // formatting?
      if (this.model && this.fieldName) react(this.view, this.model, this.fieldName)
      $cmp(el).dropdown.close()
    }

    dropdown.kids([cal])
    dropdown.renderAsync()
    // cal.renderTo(dropdown.el)
    // return el
  },

  openDropDown() {
    this.dropdown = dropdown({ combo: this })
    this.dropdown.renderTo(this.view.qTop.el.parentElement)
    this.addClass('ow-dropdown-open')
    this.updateContent()
    this.renderAsync()
  }

  // val(v) {
  //   let r = typeof v !== 'undefined' ? super.val(v) : super.val()
  //   return r !== '' ? r : null
  // }
}

const date6 = props => {
  props.tag = props.tag || 'input.text6.date6.date.text-icon-after'
  props.parser = props.parser || ctlParsers.date
  props.formatter = (v, fmt) => (!v ? '' : formatDate(v, fmt || culture().DateFormatSetting))
  const me = props.view.controls.text6({ ...date6Props, ...props })

  me.on('keydown', e => {
    me.prevValue = me.prevValue || ''

    if (e.which === 13 && (e.shiftKey || me.openOnEnterKey)) {
      if (!me.dropdown) {
        if (me.hasClass('static-text') || me.disabled || me.readOnly) return
        me.openDropDown()
        return killEvent(e, false)
      } else {
        me.dropdown.close()
        return killEvent(e, false)
      }
    }

    // backspace
    if (e.which === 8) return

    // delete
    if (e.which === 46) {
      var charDeleted = (me.prevValue || me.el.value)[me.el.selectionEnd - 1]
      if ('/-.'.indexOf(charDeleted) > -1) return
    }
  })
    .on('keyup', e => {
      const { el } = me
      if (el.value !== me.prevValue) {
        // backspace // delete
        if (e.which !== 8 && e.which !== 46) {
          var x = el.selectionEnd
          var caretAtEnd = x === el.value.length
          var v = resolveDate(el.value, false, undefined, { position: x })
          if (v.indexOf('|') > -1) {
            x = v.indexOf('|')
            v = v.split('|').join('')
          }
          if (el.value !== v) el.value = v
          me.prevValue = v
          if (!caretAtEnd) {
            el.selectionStart = x
            el.selectionEnd = x
          }
        }

        const dtVal = me.val()
        if (!el.value || dtVal) {
          me.trigger('ow-change')
        }
      }
    })
    .on('blur', () => {
      setTimeout(() => {
        if (me.el !== document.activeElement) me.trigger('ow-delayed-blur')
      }, 200)
    })
    .on('ow-delayed-blur', () => {
      const { el, dropdown } = me
      if (dropdown) {
        if (me.ignoreNextChange) {
          me.ignoreNextChange = false
          return false
        } else dropdown.close()
      }
      if (el === document.activeElement) return false
      if (me.resolveBeforeExit) me.resolveBeforeExit(!me.allowUnmatchedText)
    })

  const w = me.wrap().addClass('ow-date-wrap text-icon-after')

  w.kids([
    ...w.kids(),
    icon6('calendar')
      .addClass('text-item-icon')
      .on('click', () => {
        me.el.focus()
        if (me.dropdown) me.dropdown.close()
        else {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          me.openDropDown()
        }
        return false
      })
  ])

  return me
}

module.exports = { date6 }

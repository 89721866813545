const { _v } = require('../_v')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { iconCodes } = require('../icon-codes')
const { react } = require('./data-model7')

let draggingCol

exports.applyOrderToRow = tr => {
  let orderChanged
  const kids = tr.kids().sort((a, b) => {
    const swap = a.col.order - b.col.order
    if (swap < 0) orderChanged = true
    return swap
  })
  if (orderChanged) tr.kids(kids)
}

exports.drawColHeader7 = (qGrid, col, headerCellTag = 'td') => {
  if (col.gridCol === false) return ''

  const typeClass = col.type === 'text' || col.type === 'string' ? '' : '.col-' + col.type

  const me = qc(headerCellTag + '.ow-header' + typeClass + '.coli-' + col.coli).props({
    col,
    coli: col.coli
  })

  if (qGrid.opts.reorderable !== false) {
    // me.attr({ draggable: 'true' })
  }

  qGrid.bindColWidth(me)

  if (!col.title && col.field) col.title = __(col.field)

  if (col.type === 'boolean')
    col.headerAttributes = col.headerAttributes ?? {
      style: 'text-align: ' + (col.headerAlign ?? col.align ?? 'center')
    }
  const numberTypes = ['int', 'decimal', 'float', 'currency', 'integer', 'number']
  if (numberTypes.includes[col.type])
    col.headerAttributes = col.headerAttributes || {
      style: 'text-align: ' + (col.headerAlign || col.align || 'right')
    }
  if (col.headerClass)
    col.headerAttributes.class = (col.headerAttributes.class || '') + col.headerClass
  if (col.headerAlign || col.align)
    col.headerAttributes = col.headerAttributes || {
      style: 'text-align: ' + col.headerAlign || col.align
    }
  if (col.headerAttributes?.class) me.addClass(col.headerAttributes.class)
  if (col.headerAttributes?.style) me.css(col.headerAttributes.style)

  const sortIcon = qc('i.icon')
    .css({
      marginLeft: '3px',
      fontSize: '1rem',
      color: '#999',
      verticalAlign: 'text-top'
    })
    .bindState(
      () => qGrid.dc.sort?.indexOf(sort => sort[2] === col.coli),
      (sortOrder, me) => me.css({ color: ['#000', '#666', '#999'][sortOrder] })
    )
    .bindState(
      () => qGrid.dc.sort?.find(sort => sort[2] === col.coli)?.[1],
      (sortDir, me) => me.kids(html(iconCodes[['angle-down', 'angle-up'][sortDir]] ?? ''))
    )

  const linerSpan = qc(
    'span',
    qc('a.col-sort', [html(col.title ?? ''), sortIcon])
      .attr({ href: '#', tabindex: '-1' })
      .on('click', () => {
        if (!qGrid.opts.sortable || col.sortable === false) return

        const { dc } = qGrid

        const addSort = (sorts = [], field, coli) => {
          if (sorts[2]?.[2] === col.coli) sorts.pop()
          if (sorts[1]?.[2] === col.coli) sorts.splice(1, 1)
          if (sorts[0]?.[2] === col.coli)
            sorts[0][1] === 0 ? sorts.splice(0, 1) : (sorts[0][1] = 0) // reverse
          else sorts.unshift([field, 1, coli])
          if (sorts.length > 3) sorts.pop()

          return sorts
        }

        dc.sort = addSort(dc.sort ?? [], col.sortOnField ?? col.field, col.coli)

        if (qGrid.opts.sortable === 'server') dc.load()
        else qGrid.refilter(true)
      })
      .attr({ draggable: qGrid.opts.reorderable !== false ? 'true' : 'false' })
      .on('dragstart', () => (draggingCol = me))
      .on('dragend', () => (draggingCol = undefined))
      .on('dragover', e => {
        if (draggingCol && draggingCol !== me) return common.killEvent(e, true)
      })
      .on('drop', e => {
        if (draggingCol === me) return

        e.preventDefault()

        const newIndex = me.col.order
        const fromIndex = draggingCol.col.order

        console.log(newIndex, fromIndex)

        qGrid.opts.cols.forEach(col => {
          if (col === draggingCol.col) col.order = newIndex
          else if (newIndex < fromIndex)
            col.order >= newIndex && col.order < fromIndex && col.order++
          else col.order <= newIndex && col.order > fromIndex && col.order--

          col.setting.order = col.order

          if (col.setting.order === col.coli) delete col.setting.order
        })

        // insert col
        qGrid.find('tr').forEach(el => exports.applyOrderToRow(qc(el)))
      })
      .css({ display: 'block' })
  )

  me.kids([
    linerSpan,

    ...(col.checkAllNone
      ? [
          qc(
            'a.check-all-none.on',
            qc(
              'span.tri-bl',
              qc('span', html('&#xf00c')).css({
                color: '#000',
                border: 'transparent',
                background: 'transparent',
                padding: '0',
                fontSize: '0.9em',
                bottom: '-0.25rem',
                position: 'absolute',
                height: '100%',
                left: '0.12em'
              })
            )
              .css({
                fontFamily: 'FontAwesome',
                position: 'absolute',
                display: 'inline-block',
                left: '0',
                bottom: '0',
                width: '0',
                height: '0',
                borderBottom: '1.8em solid #fff'
              })
              .on('click', e => {
                if (col.readOnly === true) return

                const value = !qc(e.target).toggleClass('all-on').hasClass('all-on')

                const f = col.checkAllNoneField || col.field
                qGrid.progress().then(() => {
                  qGrid.recs.forEach(rec => {
                    if (_v(rec, f) !== !value) {
                      _v(rec, f, !value)
                      react(rec)
                    }
                  })
                  qGrid.progress(false)
                })
              })
          )
            .attr({ tabindex: '-1' })
            .bindState(
              () => col.readOnly === true,
              (v, me) => me.css({ display: v ? 'none' : undefined })
            )
        ]
      : []),

    qc('div.resize-col-handle')
      .attr({ draggable: 'false' })
      .on('mousedown', e => {
        const m_pos = e.pageX
        const origWidth = me.col.width
        common.controls.setResizeHandler(({ pageX }) => {
          const newWidth = origWidth + pageX - m_pos
          let w = newWidth < 5 ? 5 : newWidth
          w = parseFloat(w)
          me.col.width = w
          me.col.setting.width = w
          qGrid.calcColWidth()
          qGrid.renderAsync()
          return false
        })
      })
  ]).attr({ scope: 'col', role: 'columnheader' })

  col.preRenderHeader?.(me)

  return me
}

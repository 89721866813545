// depends on module ../dates
const { isDate, isInvalidDate } = require('../../lib/js-types')
const { culture, dates } = require('./culture')
const { buddhistCalendarOffset } = dates

const minutems = 1000 * 60
const hourms = minutems * 60
const dayms = hourms * 24

const leftPad = (number, targetLength = 2) =>
  ('000000000000000000000000' + number + '').substr(-1 * targetLength)

const formatDate = (date, patternStr = dates.DateFormatSetting, calendar = dates.calendar) => {
  if (!isDate(date)) return date
  if (isInvalidDate(date)) return date

  if (patternStr?.length === 1) patternStr = dates.datePatterns[patternStr] || patternStr

  const { am, pm } = culture()

  let day = date.getDate(),
    month = date.getMonth(),
    year = date.getFullYear(),
    hour = date.getHours(),
    minute = date.getMinutes(),
    second = date.getSeconds(),
    miliseconds = date.getMilliseconds(),
    h = hour % 12,
    hh = leftPad(h),
    HH = leftPad(hour),
    mm = leftPad(minute),
    ss = leftPad(second),
    aaa = hour < 12 ? am : pm,
    EEEE = dates.daysOfWeekJs[date.getDay()],
    EEE = EEEE.substr(0, 3),
    dd = leftPad(day),
    M = month + 1,
    MM = leftPad(M),
    MMMM = dates.months[month],
    MMM = MMMM.substr(0, 3),
    yyyy = year + '',
    yy = yyyy.substr(2, 2)

  if (calendar === 'buddhist') yyyy = (parseInt(yyyy) + buddhistCalendarOffset).toString()

  // checks to see if month name will be used
  patternStr = patternStr
    .replace('hh', hh)
    .replace('h', h)
    .replace('HH', HH)
    .replace('H', hour)
    .replace('mm', mm)
    .replace('m', minute)
    .replace('ss', ss)
    .replace('s', second)
    .replace('S', miliseconds)
    .replace('dd', dd)
    .replace('d', day)

    .replace('yyyy', yyyy)
    .replace('yy', yy)
  patternStr =
    patternStr.indexOf('MMM') > -1
      ? patternStr.replace('MMMM', MMMM).replace('MMM', MMM)
      : (patternStr = patternStr.replace('MM', MM).replace('M', M))

  patternStr = patternStr.replace('EEEE', EEEE)
  patternStr = patternStr.replace('EEE', EEE)
  patternStr = patternStr.replace('aaa', aaa)

  return patternStr
}

const formatDateTime = (
  date,
  patternStr = dates.DateTimeFormatSetting,
  calendar = dates.calendar
) => formatDate(date, patternStr, calendar)

/**
 * takes a user entered/partially entered string value, tries to interpret and even complete the formatted date string
 *
 * @param {string} value
 * @param {boolean} finished - force completion of the string
 * @param {string} dfs date format string
 * @param {number} caret position with in the string
 * @returns string either the string as it is OR a modified version of that based on the interpreted date
 */
const resolveDate = (value, finished, dfs, caret) => {
  // Establish the date format
  if (!value) return ''

  dfs = dfs || culture().DateFormatSetting
  var sep = dfs.indexOf('-') + 1 ? '-' : dfs.indexOf('.') + 1 ? '.' : '/'

  // replace any incorrect separators with correct
  value = value.split('.').join(sep).split('/').join(sep).split('-').join(sep)

  var order = dfs.toLowerCase()[0] === 'm' ? 'mdy' : dfs.toLowerCase()[0] === 'y' ? 'ymd' : 'dmy'

  // This block removes overtyped char based on caret position overwriting existing chars
  if (!finished && caret && caret.position !== value.length) {
    caret = caret || {}

    var fullValue = value
    if (caret.position || caret.position === 0) {
      value = value.substr(0, caret.position)
      caret.afterCaret = fullValue.substr(caret.position)

      var aVals = fullValue.split(sep)

      var sParts = {
        d: aVals[order.indexOf('d')] || '',
        m: aVals[order.indexOf('m')] || '',
        y: aVals[order.indexOf('y')] || ''
      }

      var caretInPart = aVals.length
      if (aVals.length === 2) if (caret.position < aVals[0] + 1 + aVals[1] + 1) caretInPart = 1

      if (aVals.length >= 1) if (caret.position < aVals[0] + 1) caretInPart = 0

      //
      if (caretInPart !== 2) {
        var correctPartLength = order[caretInPart] === 'y' ? 4 : 2

        if (correctPartLength < (sParts[order[caretInPart]] || '').length) {
          if (value.length !== caret.position + 1)
            // this is really a char replacement
            // remove char at caret position because it is replaced by the one before
            return resolveDate(value, false, dfs) + '|' + caret.afterCaret.slice(1)
          // else most likely just keyed in extra digit inside month
          // do nothing - should just continue as is.
        }
      }
    }

    // var l1 = value.length
    value = resolveDate(value, false, dfs).substr(0, 10)
    caret.beforeCaret = value
    caret.position = value.length

    value = (value + '|' + caret.afterCaret).replace(sep + '|' + sep, sep + '|').substr(0, 11)
    value = value.split(sep).reduce((p, t, i) => p + ([0, 1, 1][i] ? sep : '') + t, '')

    return value
  }

  function fmt(d) {
    var dd = d.getDate()
    var mm = d.getMonth() + 1
    let yyyy = d.getFullYear() + (culture().calendar === 'buddhist' ? buddhistCalendarOffset : 0)

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (order === 'mdy') return mm + sep + dd + sep + yyyy
    if (order === 'ymd') return yyyy + sep + mm + sep + dd
    return dd + sep + mm + sep + yyyy
  }

  var validChars = Array.prototype.reduce.call(
    '0123456789' + sep,
    (t, c) => {
      t[c] = 1
      return t
    },
    {}
  )

  if (value.toLowerCase() === 't') return resolveDate(fmt(new Date()), finished, dfs)

  value = Array.prototype.filter.call(value, x => x in validChars).join('')
  var v = value

  const useShortYear = culture().DateFormatSetting.indexOf('yyyy') < 0

  function resolve3(v) {
    var yearString = useShortYear
      ? new Date().getFullYear().toString().substr(-2)
      : (
          new Date().getFullYear() +
          (culture().calendar === 'buddhist' ? buddhistCalendarOffset : 0)
        ).toString()

    var yearChars = useShortYear ? 2 : 4
    if (v === sep) {
      return {
        d: ('0' + new Date().getDate()).substr(-2),
        m: ('0' + (new Date().getMonth() + 1)).substr(-2),
        y: yearString
      }[order[2]]
    }

    if (v) v = v.split(sep).join('') // remove any additional separators

    if (order[2] === 'y') {
      if (v && v[0] === sep) return resolve3(v.slice(1))

      if (finished && v.length === 2)
        return useShortYear
          ? ''
          : culture().calendar === 'buddhist'
          ? '25' + v
          : parseInt(v) > 60
          ? '19' + v
          : '20' + v

      if (finished && v === '') return yearString

      if (finished && v.length === 1) return (culture().calendar === 'buddhist' ? '250' : '200') + v
      if (finished && v.length === 3) return v + '0'

      return v.substr(0, yearChars)
    } else if (order[2] === 'd') {
      if (v && v[0] === sep) return resolve3(v.slice(1))

      if (finished && v === '') v = new Date().getDate().toString()

      if (v.length === 1 && (v > '3' || finished)) return '0' + v

      return v.substr(0, 2)
    }
  }

  function resolve2(v) {
    if (v === sep) {
      return {
        d: ('0' + new Date().getDate()).substr(-2) + sep,
        m: ('0' + (new Date().getMonth() + 1)).substr(-2) + sep,
        y:
          (
            new Date().getFullYear() +
            (culture().calendar === 'buddhist' ? buddhistCalendarOffset : 0)
          ).toString() + sep
      }[order[1]]
    }

    if (v && v[0] === sep) return resolve2(v.slice(1)) // remove any leading sep

    if (order[1] === 'm') {
      if (finished && v === '') return resolve2((new Date().getMonth() + 1).toString())

      if (finished && v.length === 1) {
        if (v === '0') return resolve2('01')
        return resolve2('0' + v)
      }

      if (v.length >= 1) {
        var firstDigit = v[0]
        var secondDigit = v[1]
        if (secondDigit === sep) return resolve2('0' + v)

        if (parseInt(firstDigit) > 1) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve3(v)
          return v
        }

        if (parseInt(firstDigit + secondDigit) > 12) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve3(v)
          return v
        }

        if (v.length > 1) {
          firstDigit = v[0]
          secondDigit = v[1]
          v = v.slice(1)
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          return firstDigit + secondDigit + sep + resolve3(v)
        }
      }
    } else if (order[1] === 'd') {
      if (finished && (v === '' || v === '0' || v === '00'))
        return resolve2(new Date().getDate().toString())

      if (v.length >= 1) {
        firstDigit = v[0]
        secondDigit = v[1]
        if (secondDigit === sep) return resolve2('0' + v)

        if (parseInt(firstDigit) > 3) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve3(v)
          return v
        }

        if (parseInt(firstDigit + secondDigit) > 31) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve3(v)
          return v
        }

        if (v.length > 1) {
          v = v.slice(1).slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          return firstDigit + secondDigit + sep + resolve3(v)
        }

        if (finished && v.length === 1) return resolve2('0' + v)
      }
    }

    return v
  }

  function resolve1(v) {
    var yearString = useShortYear
      ? new Date().getFullYear().toString().substr(-2)
      : new Date().getFullYear().toString()
    var yearChars = useShortYear ? 2 : 4
    if (v === sep)
      return {
        d: ('0' + new Date().getDate()).substr(-2) + sep,
        m: ('0' + (new Date().getMonth() + 1)).substr(-2) + sep,
        y: yearString + sep
      }[order[0]]

    if (order[0] === 'd') {
      if (v.length >= 1) {
        var firstDigit = v[0]
        var secondDigit = v[1]
        if (secondDigit === sep) {
          return resolve1('0' + v)
        }

        if (parseInt(firstDigit) > 3) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve2(v)
          return v
        }

        if (parseInt(firstDigit + secondDigit) > 31) {
          v = v.slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          v = '0' + firstDigit + sep + resolve2(v)
          return v
        }

        if (v.length > 1) {
          v = v.slice(1).slice(1)
          if (v && v[0] === sep) v = v.slice(1)
          return firstDigit + secondDigit + sep + resolve2(v)
        }

        if (finished && v.length === 1) return resolve1('0' + v)
      }
    } else if (order[0] === 'm') {
      if (finished && (v === '' || v === '0' || v === '00')) {
        return resolve1((new Date().getMonth() + 1).toString())
      }

      if (v.length >= 1) {
        let firstDigit = v[0]
        let secondDigit = v[1]
        if (secondDigit === sep) {
          return resolve1('0' + v)
        }

        if (parseInt(firstDigit) > 1) {
          if (v && v[0] === sep) v = v.slice(1)
          return '0' + firstDigit + sep + resolve2(v.substr(1))
        }

        if (parseInt(firstDigit + secondDigit) > 12) {
          if (v && v[0] === sep) v = v.slice(1)
          return '0' + firstDigit + sep + resolve2(v.substr(1))
        }

        if (v.length > 1) {
          firstDigit = v[0]
          secondDigit = v[1]
          v = v.substr(2)
          if (v && v[0] === sep) v = v.slice(1)
          return firstDigit + secondDigit + sep + resolve2(v)
        }
      }
    } else if (order[0] === 'y') {
      if (v && v[0] === sep) return yearString + sep + resolve2(v.slice(1))

      if (finished && v.length === 2)
        return (
          (useShortYear
            ? ''
            : culture().calendar === 'buddhist'
            ? '25'
            : parseInt(v) > 60
            ? '19'
            : '20') + v
        )

      if (finished && v === '') return ''

      var values = v.split(sep)
      v = values[0]
      values[0] = v.substr(yearChars)
      v = v.substr(0, yearChars)
      values = values.join(sep)

      if (values === '' && !finished) return v

      if (useShortYear) {
        if (v.length === 1) v = '0' + v
      } else {
        if (v.length === 3) v = '2' + v
        if (v.length === 2)
          v = (culture().calendar === 'buddhist' ? '25' : parseInt(v) > 60 ? '19' : '20') + v
        if (v.length === 1) v = (culture().calendar === 'buddhist' ? '256' : '200') + v
      }

      return v + sep + resolve2(values)
    }

    return v
  }

  return resolve1(v)
}

const resolveTime = (value, finished, opts = {}, caret) => {
  if (!value) return ''

  var sep = ':'
  var allowMs = false
  opts = opts === null ? {} : opts
  const { allowSec = false } = opts || {}
  // var use24h = true
  var order = 'hns'

  if (value.toLowerCase() === 't' || value.toLowerCase() === 'n')
    return resolveTime(fmt(new Date()), true, opts)

  var endsWithSpace = value && value[value.length - 1] === ' '

  // This block removes overtyped char based on caret position overwriting existing chars
  if (!finished && caret && caret.position !== value.length) {
    caret = caret || {}

    var fullValue = value
    if (caret.position || caret.position === 0) {
      value = value.substr(0, caret.position)
      caret.afterCaret = fullValue.substr(caret.position)

      var aVals = fullValue.split(sep)

      var sParts = {
        h: aVals[0] || '',
        n: aVals[1] || '',
        s: aVals[2] || ''
      }

      var caretInPart = aVals.length
      if (aVals.length === 2) if (caret.position < aVals[0] + 1 + aVals[1] + 1) caretInPart = 1

      if (aVals.length >= 1) if (caret.position < aVals[0] + 1) caretInPart = 0

      //
      if (caretInPart !== 2) {
        var correctPartLength = 2

        if (correctPartLength < (sParts[order[caretInPart]] || '').length) {
          if (value.length === caret.position + 1) {
            // most likely just keyed in extra digit inside month
            // do nothing - should just continue as is.
          } else {
            // this is really a char replacement
            // remove char at caret position because it is replaced by the one before
            return resolveTime(value, false, opts) + '|' + caret.afterCaret.slice(1)
          }
        }
      }
    }

    // var l1 = value.length
    value = resolveTime(value, false, opts).substr(0, 10)
    caret.beforeCaret = value
    caret.position = value.length

    value = (value + '|' + caret.afterCaret).replace(sep + '|' + sep, sep + '|').substr(0, 11)
    value = value.split(sep).reduce(function (p, t, i) {
      return p + ([0, 1, 1][i] ? sep : '') + t
    }, '')

    return value
  }

  value = value.split(' ')

  var lastBit = value[1] ? value[1] : ''
  value = value[0]

  var pm = lastBit.indexOf('p') > -1 || value.indexOf('p') > -1
  var am = lastBit.indexOf('a') > -1 || value.indexOf('a') > -1
  if (pm) lastBit = ' ' + dates.pm
  else if (am) lastBit = ' ' + dates.am
  else lastBit = ''

  var chars = '0123456789' + sep
  if (allowMs) chars += '.'
  // if (!finished) chars += ' pam';
  var validChars = Array.prototype.reduce.call(
    chars,
    (t, c) => {
      t[c] = 1
      return t
    },
    {}
  )
  v = Array.prototype.filter.call(value, x => x in validChars).join('')
  var v = v.split(sep + sep).join(sep)

  function fmt(d) {
    var hh = d.getHours()
    var mm = d.getMinutes()
    var ss = d.getSeconds()
    if (hh < 10) hh = '0' + hh
    if (mm < 10) mm = '0' + mm
    if (ss < 10) ss = '0' + ss
    return hh + sep + mm + sep + ss
  }

  // resolve seconds
  function resolve3(v) {
    if (!allowSec) return ''
    if (v && v[0] === sep) return resolve3(v.slice(1))

    if (finished && v === '') return '00'

    if (v.length >= 1) {
      const [firstDigit, secondDigit = ''] = v
      if (secondDigit === sep) return resolve2('0' + firstDigit)
      if (parseInt(firstDigit) > 5) return '0' + firstDigit
      return firstDigit + secondDigit
    }

    return v.substr(0, 2)
  }

  // resolve mins
  function resolve2(v) {
    if (v && v[0] === sep) return resolve2(v.slice(1)) // remove any leading sep

    if (finished && v === '') return !allowSec ? '00' : '00:00'

    if (v.length >= 1) {
      var firstDigit = v[0]
      var secondDigit = v[1]
      if (secondDigit === sep) return resolve2('0' + v)

      if (parseInt(firstDigit + secondDigit) > 59) {
        v = v.slice(1)
        v = '0' + firstDigit + (!allowSec ? '' : sep + resolve3(v))
        return v
      }

      if (parseInt(firstDigit) > 5) {
        v = v.slice(1)
        v = '0' + firstDigit + (!allowSec ? '' : sep + resolve3(v))
        return v
      }

      if (v.length > 1) {
        firstDigit = v[0]
        secondDigit = v[1]
        v = v.slice(1)
        v = v.slice(1)
        return firstDigit + secondDigit + (!allowSec ? '' : v || finished ? sep + resolve3(v) : '')
      }
    }

    return v
  }

  // resolve hours
  function resolve1(v) {
    if (v.length >= 1) {
      var firstDigit = v[0]
      var secondDigit = v[1]

      if (secondDigit === sep) return resolve1('0' + v)
      if (parseInt(firstDigit) > 2) return resolve1('0' + v)
      if (finished && v.length === 1) return resolve1('0' + v)

      var hInt = parseInt(firstDigit + secondDigit)

      if (hInt > 24) return resolve1('0' + v)

      if (pm && hInt < 12) {
        v = (hInt + 12).toString() + v.slice(1).slice(1)
        return resolve1(v)
      }
      if (am && hInt === 12) {
        v = '00' + v.slice(1).slice(1)
        return resolve1(v)
      }

      if (v.length > 1) {
        v = v.slice(1).slice(1)
        return firstDigit + secondDigit + sep + resolve2(v)
      }
    }

    return v
  }
  if (lastBit !== '' || endsWithSpace) finished = true

  return resolve1(v) + (endsWithSpace ? ' ' : '') // + lastBit;
}

const resolveDateTime = (value, finished, dfs, caret) => {
  if (value.toLowerCase() === 'n') return resolveDate('t') + ' ' + resolveTime('t')

  var endsWithSpace = value && value[value.length - 1] === ' '
  if (value === '') return value
  var v = value.split(' ')

  var caretInDatePart = value.indexOf(' ') === -1 || !caret || caret.position < value.indexOf(' ')

  var dateCaret, timeCaret
  if (caretInDatePart) dateCaret = caret
  else if (caret)
    timeCaret = { position: value.indexOf(' ') === -1 ? value.length : value.indexOf(' ') }

  var d = resolveDate(
    v[0],
    finished || (value.indexOf(' ') > -1 && !caretInDatePart),
    dfs,
    dateCaret
  )
  v = v.slice(1)
  v = resolveTime(v.join(' '), finished, { allowSec: true }, timeCaret)
  if (finished && v === '') v = resolveTime('0', finished)
  return d + ((d && v) || finished || endsWithSpace ? ' ' : '') + v
}

const getWeekOfYear = (d, year) => {
  var first = new Date(year || d.getFullYear(), 0, 1)
  var numday = (d - first) / dayms
  // [Js] todo: test this for daylight savings change in both directions - I suspect it will be wrong
  return Math.ceil((numday + ((first.getDay() + 6) % 7) + 1) / 7) - 1
}

const weekStarts = (iWeek, year) => {
  var df = new Date(year || new Date().getFullYear(), 0, 1)
  return new Date(df.setDate(7 * iWeek - ((df.getDay() + 6) % 7) + 1))
}

const displayWeek = (iWeek, year) => {
  // year is optional, takes this year if none provided
  var df = weekStarts(iWeek, year)
  var dt = new Date(df.valueOf())
  dt.setDate(dt.getDate() + 6)
  return (
    'Week ' +
    iWeek +
    ': ' +
    df.getDate() +
    ' ' +
    dates.shortMonths[df.getMonth()] +
    ' - ' +
    dt.getDate() +
    ' ' +
    dates.shortMonths[dt.getMonth()]
  )
}

const displayRotation = (iWeek, year) => {
  // year is optional, takes this year if none provided
  var df = new Date(year || new Date().getFullYear(), 0, 1)
  df.setDate(7 * iWeek - ((df.getDay() + 6) % 7) + 1)
  var dt = new Date(df.valueOf())
  dt.setDate(dt.getDate() + 3 * 7 + 6)
  return (
    df.getDate() +
    ' ' +
    dates.shortMonths[df.getMonth()] +
    ' - ' +
    dt.getDate() +
    ' ' +
    dates.shortMonths[dt.getMonth()]
  )
}

const setNextIntervalTime = (currentDateTime, interval) => {
  var currentHours = currentDateTime.getHours()
  var currentMinutes = currentDateTime.getMinutes()
  var totalMinutes = currentHours * 60 + currentMinutes
  var runningMinutes = 0

  while (totalMinutes > runningMinutes) {
    runningMinutes += interval
  }

  currentHours = Math.floor(runningMinutes / 60)
  currentMinutes = runningMinutes - currentHours * 60
  return leftPad(currentHours, 2) + ':' + leftPad(currentMinutes, 2)
}

const HHMMtoIntMS = hhmm => {
  var h = parseInt(hhmm.split(':')[0])
  var m = parseInt(hhmm.split(':')[1])
  var v = h * hourms + m * minutems
  return v
}

const IntMStoHHMM = ms => {
  var h = Math.trunc(ms / hourms)
  var m = Math.trunc(ms / minutems - h * hourms)
  return ('0' + h.toString()).substr(-2) + ':' + ('0' + m.toString()).substr(-2)
}

const validateDateTimeField = (date, type) => {
  var presetFormat = ''
  var validFormat = ''

  if (type === 'date') {
    // Javascript Date object accepted format
    validFormat = 'yyyy-MM-dd'
    var validFormatArray = validFormat.split(/\W/)

    // Preset date format for this project (e.g. d.M.yyyy)
    presetFormat = 'dd.M.yyyy'
    var presetDateFormatAry = presetFormat.split(/\W/)

    // Split date string to an array
    var dateAry = date.split(/\W/)
    var formattedDateAry = []

    // Reformat the date to javascript accepted format
    validFormatArray.forEach(formatEl => {
      presetDateFormatAry.forEach((presetEl, index) => {
        if (formatEl.match(new RegExp(presetEl, 'i'))) formattedDateAry.push(dateAry[index])
      })
    })

    var isValidYear = formattedDateAry[0].length === 4

    if (!isValidYear) return false

    // Return the valid status of date
    if (culture().calendar === 'buddhist')
      formattedDateAry[0] = '' + (parseInt(formattedDateAry[0]) - buddhistCalendarOffset)

    return !isNaN(Date.parse(formattedDateAry.join('-')))
  }

  if (type === 'datetime') {
    // Javascript Date object accepted format
    validFormat = 'yyyy-MM-dd HH:mm:ss'
    presetFormat = culture().DateTimeFormatSetting
  }
}

const { parseDate } = require('./date-parser')

module.exports = Object.assign(dates, {
  isDate,
  addDays(dt, numDays) {
    return new Date(dt.setDate(dt.getDate() + numDays))
  },
  formatDate,
  formatDateTime,
  parseDate(s, fmt = dates.DateFormatSetting, calendar = dates.calendar) {
    return parseDate(s, fmt, calendar)
  },

  getWeekOfYear,
  weekStarts,
  displayWeek,
  displayRotation,
  leftPad,
  setNextIntervalTime,
  HHMMtoIntMS,
  IntMStoHHMM,
  validateDateTimeField,
  resolveDate,
  resolveDateTime,
  resolveTime
})

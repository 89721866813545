const { qc } = require('../cmp/qc')
const { popInvalid } = require('../pop-box')
const { qcControls } = require('../qcControls/common-qc-controls')

exports.overviewCon = function ($top, opts) {
  const tref =
    $top.find('button[data-command="edit"]').attr('data-target-ref') || // should be mainGrid
    $top.find('.k-grid, .ow-grid')[0]?.id

  if (!tref) return // not a valid overview form

  var g = $top.find('#' + tref + ' , .iden-' + tref)[0]

  if (opts.mode === 'select' || opts.mode === 'multi-select') {
    if (!$top.find('[data-command="select"]')[0]) {
      const rightButtons = qc(
        $top.find('.footer .buttonset_right, .sticky-footer .buttonset_right')[0]
      )

      const btn = qcControls.commandButton('select', tref).attr({
        'data-default-button': 'true'
        // 'data-command': 'edit'
      })

      btn.opts.command = 'edit'

      rightButtons.kids([btn, ...rightButtons.kids()])
    }

    const rowEditHandler = function (e, rowIndex, data) {
      if (rowIndex < 0 || typeof rowIndex === 'undefined') {
        popInvalid(__('Please select a row'))
        return false
      }

      if (opts.mode === 'multi-select') opts.result = g.selectedData() || []
      else opts.result = JSON.parse(JSON.stringify(data))

      if (opts.dontClose === true) {
        opts.result = data
        opts.callback($top, opts)
        opts.callback = null
      } else if (opts.delayClose === true) {
        opts.result = data
        opts.callback($top, opts)
        opts.callback = null
        setTimeout(() => $top.closeForm(), 500)
      } else $top.closeForm()

      return false
    }
    $(g).off('row-edit').on('row-edit', rowEditHandler)
    $(g)
      .first()
      .off('command-select')
      .on('command-select', () => qc(g).trigger('command-edit'))

    $top.find('button[data-command="add-row"]').hide()
    $top.find('.ow-buttonstrip button[data-command="edit"]').hide()
    $top.find('button[data-command="new"]').hide()
    $top.find('button[data-command="copy"]').hide()
    $top.find('button[data-command="delete"]').hide()
    $top.find('.ow-buttonstrip label').hide()

    if (opts.mode === 'multi-select' && g) {
      g.opts.selectable = 'multiple'
      g.selectable?.('multiple')
    }

    if (opts.mode === 'select' && g)
      $(g).on('keyup', e => {
        if (e.which === 13) {
          var $row = $(g).find('.ow-current-row')
          g.clearSelection()
          g.selectRow($row)
          qc(g).trigger('command-edit')
        }
      })
  }

  if (!opts.mode && g)
    qc(g).on('keyup', e => {
      if (e.which === 13 && e.target.closest('.k-grid-content table')) {
        // var $row = $(g).find('.ow-current-row')
        // g.clearSelection?.()
        // g.selectRow?.($row)
        qc(g).trigger('command-edit')
      }
    })
}

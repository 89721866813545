const { qc } = require('../cmp/qc')
const theme = require('../theme')
const { setResizeHandler } = require('../viewWindow')
const { footerBar7 } = require('./footerBar7')

module.exports.modalWindow7 = opts => {
  let { content, view } = opts

  opts.top = opts.top ?? 32
  opts.left = opts.left ?? 200

  if (!view) throw new Error('Invalid modalWindow7 options.  You must set view property')

  view.qTop.css({ pointerEvents: 'none' })

  let titleBar

  const me = qc('div.modal-popup', [
    (titleBar = qc('div.modal-title', [
      qc('span', opts.title).css({
        height: '100%',
        lineHeight: '2em',
        padding: '0.44em',
        fontWeight: 'bold'
      })
    ]).css({
      color: '#101114',
      height: '2em',
      padding: '0.4em 0 0.4em 0.75em',
      borderBottom: '0.15em solid lightgray',
      boxSizing: 'content-box',
      userSelect: 'none'
    })),

    qc('div.modal-middle', content).css({
      margin: '0.44em',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto'
    }),

    qc('div.modal-footer', [
      footerBar7({ view, right: opts.buttons ?? ['ok', 'close'] })
        .on('command-save command-ok', () => me.cancel())
        .on('command-close command-cancel', () => me.close())
    ]).css({ padding: '0.44em', textAlign: 'right' })
  ])
    .props({
      cancel() {
        const canClose = !opts.onCancel || opts.onCancel() !== false
        if (!canClose) return
        me.el.remove()
        view.qTop.css({ pointerEvents: undefined })
      },

      close() {
        const canClose = !opts.onClose || opts.onClose() !== false
        if (!canClose) return
        me.el.remove()
        view.qTop.css({ pointerEvents: undefined })
      },

      opts
    })
    .bindState(
      () => opts.left + 'px',
      (left, me) => me.css({ left })
    )
    .bindState(
      () => opts.top + 'px',
      (top, me) => me.css({ top })
    )
    .css({
      width: (opts.width ?? 300) + 'px',
      height: (opts.height ?? 240) + 'px',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f3f6f8',
      borderColor: 'rgba(0, 0, 0, 0.3)',
      boxShadow: '0 0.15em 0.77em rgba(0, 0, 0, 0.5)',
      borderRadius: theme.borderRadius,
      overflow: 'hidden',
      pointerEvents: 'auto',
      zIndex: '10'
    })

  me.renderTo(view.qTop.el.parentElement)

  titleBar.on('mousedown', e => {
    const m_pos_x = e.pageX
    const m_pos_y = e.pageY
    const orig = { left: opts.left, top: opts.top }

    setResizeHandler(
      e => {
        const { pageX, pageY } = e

        const diffX = pageX - m_pos_x
        const diffY = pageY - m_pos_y

        // console.log('move', { diffX, diffY })

        opts.left = Math.max(Math.min(orig.left + diffX, 2000), -500)
        opts.top = Math.max(Math.min(orig.top + diffY, 1200), -200)

        me.renderAsync()
        e.preventDefault()
      },
      () => qc(document.body).renderAsync()
    )
  })

  return me
}

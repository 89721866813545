exports.filterController = function (o, opts) {
  var $top = opts.$top || o.myWin()

  $top
    .find('.ow-filter-control[data-field-for="' + opts.dsName + '"]')
    .attr('data-filter-for', opts.dsName)

  o.getMyFilterControls = function () {
    return $top.find('.ow-filter-control[data-filter-for="' + (o.dsName || opts.dsName) + '"]')
  }

  o.readFilterControls = function (filter) {
    var $filterControls = o.getMyFilterControls()

    var filters = []
    // call the filter() on each filterControl that's bound to me.
    $filterControls.forEach(fc => fc.readFilter?.(filters))

    if (filters.length) {
      if (!filter.filters) filter.filters = filters
      else filter.filters = filter.filters.concat(filters)
    }
  }
}

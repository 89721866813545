const { qc } = require('./cmp/qc')
const { html } = require('./cmp/html')

const iconCodes = {
  magnifier: '&#xf002',
  filter: '&#xf0b0',
  ellipsis: '&#xf15c',
  trash: '&#xf1f8',
  delete: '&#xf1f8',
  pen: '&#xf044',
  edit: '&#xf044',
  save: '&#xf0c7',
  page: '&#xf15c',
  calendar: '&#xf073',
  comment: '&#xf0e5',
  'comment-o': '&#xf075',
  'file-o': '&#xf016',
  clock: '&#xf017',
  download: '&#xf019',
  backspace: '&#xf104',
  glass: '&#xf000',
  music: '&#xf001',
  search: '&#xf002',
  'envelope-o': '&#xf003',
  heart: '&#xf004',
  star: '&#xf005',
  'star-o': '&#xf006',
  user: '&#xf007',
  film: '&#xf008',
  'th-large': '&#xf009',
  th: '&#xf00a',
  'th-list': '&#xf00b',
  check: '&#xf00c',
  remove: '&#xf00d',
  close: '&#xf00d',
  times: '&#xf00d',
  'search-plus': '&#xf00e',
  'search-minus': '&#xf010',
  'power-off': '&#xf011',
  signal: '&#xf012',
  gear: '&#xf013',
  cog: '&#xf013',
  'trash-o': '&#xf014',
  home: '&#xf015',
  // 'file-o': '&#xf016',
  'clock-o': '&#xf017',
  road: '&#xf018',
  // download: '&#xf019',
  'arrow-circle-o-down': '&#xf01a',
  'arrow-circle-o-up': '&#xf01b',
  inbox: '&#xf01c',
  'play-circle-o': '&#xf01d',
  'rotate-right': '&#xf01e',
  repeat: '&#xf01e',
  refresh: '&#xf021',
  'list-alt': '&#xf022',
  lock: '&#xf023',
  flag: '&#xf024',
  headphones: '&#xf025',
  'volume-off': '&#xf026',
  'volume-down': '&#xf027',
  'volume-up': '&#xf028',
  qrcode: '&#xf029',
  barcode: '&#xf02a',
  tag: '&#xf02b',
  tags: '&#xf02c',
  book: '&#xf02d',
  bookmark: '&#xf02e',
  print: '&#xf02f',
  camera: '&#xf030',
  font: '&#xf031',
  bold: '&#xf032',
  italic: '&#xf033',
  'text-height': '&#xf034',
  'text-width': '&#xf035',
  'align-left': '&#xf036',
  'align-center': '&#xf037',
  'align-right': '&#xf038',
  'align-justify': '&#xf039',
  list: '&#xf03a',
  dedent: '&#xf03b',
  outdent: '&#xf03b',
  indent: '&#xf03c',
  'video-camera': '&#xf03d',
  photo: '&#xf03e',
  image: '&#xf03e',
  'picture-o': '&#xf03e',
  pencil: '&#xf040',
  'map-marker': '&#xf041',
  adjust: '&#xf042',
  tint: '&#xf043',
  // edit: '&#xf044',
  'pencil-square-o': '&#xf044',
  'share-square-o': '&#xf045',
  'check-square-o': '&#xf046',
  arrows: '&#xf047',
  'step-backward': '&#xf048',
  'fast-backward': '&#xf049',
  backward: '&#xf04a',
  play: '&#xf04b',
  pause: '&#xf04c',
  stop: '&#xf04d',
  forward: '&#xf04e',
  'fast-forward': '&#xf050',
  'step-forward': '&#xf051',
  eject: '&#xf052',
  'chevron-left': '&#xf053',
  'chevron-right': '&#xf054',
  'plus-circle': '&#xf055',
  'minus-circle': '&#xf056',
  'times-circle': '&#xf057',
  'check-circle': '&#xf058',
  'question-circle': '&#xf059',
  'info-circle': '&#xf05a',
  crosshairs: '&#xf05b',
  'times-circle-o': '&#xf05c',
  'check-circle-o': '&#xf05d',
  ban: '&#xf05e',
  'arrow-left': '&#xf060',
  'arrow-right': '&#xf061',
  'arrow-up': '&#xf062',
  'arrow-down': '&#xf063',
  'mail-forward': '&#xf064',
  share: '&#xf064',
  expand: '&#xf065',
  compress: '&#xf066',
  plus: '&#xf067',
  minus: '&#xf068',
  asterisk: '&#xf069',
  'exclamation-circle': '&#xf06a',
  gift: '&#xf06b',
  leaf: '&#xf06c',
  fire: '&#xf06d',
  eye: '&#xf06e',
  'eye-slash': '&#xf070',
  warning: '&#xf071',
  'exclamation-triangle': '&#xf071',
  plane: '&#xf072',
  // calendar: '&#xf073',
  random: '&#xf074',
  // comment: '&#xf075',
  magnet: '&#xf076',
  'chevron-up': '&#xf077',
  'chevron-down': '&#xf078',
  chevronUp: '&#xf077',
  chevronDown: '&#xf078',
  retweet: '&#xf079',
  'shopping-cart': '&#xf07a',
  folder: '&#xf07b',
  'folder-open': '&#xf07c',
  'arrows-v': '&#xf07d',
  'arrows-h': '&#xf07e',
  'bar-chart-o': '&#xf080',
  'bar-chart': '&#xf080',
  'twitter-square': '&#xf081',
  'facebook-square': '&#xf082',
  'camera-retro': '&#xf083',
  key: '&#xf084',
  gears: '&#xf085',
  cogs: '&#xf085',
  comments: '&#xf086',
  'thumbs-o-up': '&#xf087',
  'thumbs-o-down': '&#xf088',
  'star-half': '&#xf089',
  'heart-o': '&#xf08a',
  'sign-out': '&#xf08b',
  'linkedin-square': '&#xf08c',
  'thumb-tack': '&#xf08d',
  'external-link': '&#xf08e',
  'sign-in': '&#xf090',
  trophy: '&#xf091',
  'github-square': '&#xf092',
  upload: '&#xf093',
  'lemon-o': '&#xf094',
  phone: '&#xf095',
  'square-o': '&#xf096',
  'bookmark-o': '&#xf097',
  'phone-square': '&#xf098',
  twitter: '&#xf099',
  facebook: '&#xf09a',
  github: '&#xf09b',
  unlock: '&#xf09c',
  'credit-card': '&#xf09d',
  feed: '&#xf09e',
  rss: '&#xf09e',
  'hdd-o': '&#xf0a0',
  bullhorn: '&#xf0a1',
  bell: '&#xf0f3',
  certificate: '&#xf0a3',
  'hand-o-right': '&#xf0a4',
  'hand-o-left': '&#xf0a5',
  'hand-o-up': '&#xf0a6',
  'hand-o-down': '&#xf0a7',
  'arrow-circle-left': '&#xf0a8',
  'arrow-circle-right': '&#xf0a9',
  'arrow-circle-up': '&#xf0aa',
  'arrow-circle-down': '&#xf0ab',
  globe: '&#xf0ac',
  wrench: '&#xf0ad',
  tasks: '&#xf0ae',
  // filter: '&#xf0b0',
  briefcase: '&#xf0b1',
  'arrows-alt': '&#xf0b2',
  group: '&#xf0c0',
  users: '&#xf0c0',
  chain: '&#xf0c1',
  link: '&#xf0c1',
  cloud: '&#xf0c2',
  flask: '&#xf0c3',
  cut: '&#xf0c4',
  scissors: '&#xf0c4',
  copy: '&#xf0c5',
  'files-o': '&#xf0c5',
  paperclip: '&#xf0c6',
  'floppy-o': '&#xf0c7',
  square: '&#xf0c8',
  navicon: '&#xf0c9',
  reorder: '&#xf0c9',
  bars: '&#xf0c9',
  'list-ul': '&#xf0ca',
  'list-ol': '&#xf0cb',
  strikethrough: '&#xf0cc',
  underline: '&#xf0cd',
  table: '&#xf0ce',
  magic: '&#xf0d0',
  truck: '&#xf0d1',
  pinterest: '&#xf0d2',
  'pinterest-square': '&#xf0d3',
  'google-plus-square': '&#xf0d4',
  'google-plus': '&#xf0d5',
  money: '&#xf0d6',
  caretDown: '&#xf0d7',
  caretUp: '&#xf0d8',
  caretLeft: '&#xf0d9',
  caretRight: '&#xf0da',
  'caret-down': '&#xf0d7',
  'caret-up': '&#xf0d8',
  'caret-left': '&#xf0d9',
  'caret- right': '&#xf0da',
  'caret-right': '&#xf0da',
  columns: '&#xf0db',
  unsorted: '&#xf0dc',
  sort: '&#xf0dc',
  'sort-down': '&#xf0dd',
  'sort-desc': '&#xf0dd',
  'sort-up': '&#xf0de',
  'sort-asc': '&#xf0de',
  envelope: '&#xf0e0',
  linkedin: '&#xf0e1',
  'rotate-left': '&#xf0e2',
  undo: '&#xf0e2',
  legal: '&#xf0e3',
  gavel: '&#xf0e3',
  dashboard: '&#xf0e4',
  tachometer: '&#xf0e4',
  // 'comment-o': '&#xf0e5',
  'comments-o': '&#xf0e6',
  flash: '&#xf0e7',
  bolt: '&#xf0e7',
  sitemap: '&#xf0e8',
  umbrella: '&#xf0e9',
  paste: '&#xf0ea',
  clipboard: '&#xf0ea',
  'lightbulb-o': '&#xf0eb',
  exchange: '&#xf0ec',
  'cloud-download': '&#xf0ed',
  'cloud-upload': '&#xf0ee',
  'user-md': '&#xf0f0',
  stethoscope: '&#xf0f1',
  suitcase: '&#xf0f2',
  'bell-o': '&#xf0a2',
  coffee: '&#xf0f4',
  cutlery: '&#xf0f5',
  'file-text-o': '&#xf0f6',
  'building-o': '&#xf0f7',
  'hospital-o': '&#xf0f8',
  ambulance: '&#xf0f9',
  medkit: '&#xf0fa',
  'fighter-jet': '&#xf0fb',
  beer: '&#xf0fc',
  'h-square': '&#xf0fd',
  'plus-square': '&#xf0fe',
  'angle-double-left': '&#xf100',
  'angle-double-right': '&#xf101',
  'angle-double-up': '&#xf102',
  'angle-double-down': '&#xf103',
  'angle-left': '&#xf104',
  'angle-right': '&#xf105',
  'angle-up': '&#xf106',
  'angle-down': '&#xf107',
  angleDown: '&#xf107',
  desktop: '&#xf108',
  laptop: '&#xf109',
  tablet: '&#xf10a',
  'mobile-phone': '&#xf10b',
  mobile: '&#xf10b',
  'circle-o': '&#xf10c',
  'quote-left': '&#xf10d',
  'quote-right': '&#xf10e',
  spinner: '&#xf110',
  circle: '&#xf111',
  'mail-reply': '&#xf112',
  reply: '&#xf112',
  'github-alt': '&#xf113',
  'folder-o': '&#xf114',
  'folder-open-o': '&#xf115',
  'smile-o': '&#xf118',
  'frown-o': '&#xf119',
  'meh-o': '&#xf11a',
  gamepad: '&#xf11b',
  'keyboard-o': '&#xf11c',
  'flag-o': '&#xf11d',
  'flag-checkered': '&#xf11e',
  terminal: '&#xf120',
  code: '&#xf121',
  'mail-reply-all': '&#xf122',
  'reply-all': '&#xf122',
  'star-half-empty': '&#xf123',
  'star-half-full': '&#xf123',
  'star-half-o': '&#xf123',
  'location-arrow': '&#xf124',
  crop: '&#xf125',
  'code-fork': '&#xf126',
  unlink: '&#xf127',
  'chain-broken': '&#xf127',
  question: '&#xf128',
  info: '&#xf129',
  exclamation: '&#xf12a',
  superscript: '&#xf12b',
  subscript: '&#xf12c',
  eraser: '&#xf12d',
  'puzzle-piece': '&#xf12e',
  microphone: '&#xf130',
  'microphone-slash': '&#xf131',
  shield: '&#xf132',
  'calendar-o': '&#xf133',
  'fire-extinguisher': '&#xf134',
  rocket: '&#xf135',
  maxcdn: '&#xf136',
  'chevron-circle-left': '&#xf137',
  'chevron-circle-right': '&#xf138',
  'chevron-circle-up': '&#xf139',
  'chevron-circle-down': '&#xf13a',
  html5: '&#xf13b',
  css3: '&#xf13c',
  anchor: '&#xf13d',
  'unlock-alt': '&#xf13e',
  bullseye: '&#xf140',
  'ellipsis-h': '&#xf141',
  'ellipsis-v': '&#xf142',
  'rss-square': '&#xf143',
  'play-circle': '&#xf144',
  ticket: '&#xf145',
  'minus-square': '&#xf146',
  'minus-square-o': '&#xf147',
  'level-up': '&#xf148',
  'level-down': '&#xf149',
  'check-square': '&#xf14a',
  'pencil-square': '&#xf14b',
  'external-link-square': '&#xf14c',
  'share-square': '&#xf14d',
  compass: '&#xf14e',
  'toggle-down': '&#xf150',
  'caret-square-o-down': '&#xf150',
  'toggle-up': '&#xf151',
  'caret-square-o-up': '&#xf151',
  'toggle-right': '&#xf152',
  'caret-square-o-right': '&#xf152',
  euro: '&#xf153',
  eur: '&#xf153',
  gbp: '&#xf154',
  dollar: '&#xf155',
  usd: '&#xf155',
  rupee: '&#xf156',
  inr: '&#xf156',
  cny: '&#xf157',
  rmb: '&#xf157',
  yen: '&#xf157',
  jpy: '&#xf157',
  ruble: '&#xf158',
  rouble: '&#xf158',
  rub: '&#xf158',
  won: '&#xf159',
  krw: '&#xf159',
  bitcoin: '&#xf15a',
  btc: '&#xf15a',
  file: '&#xf15b',
  'file-text': '&#xf15c',
  'sort-alpha-asc': '&#xf15d',
  'sort-alpha-desc': '&#xf15e',
  'sort-amount-asc': '&#xf160',
  'sort-amount-desc': '&#xf161',
  'sort-numeric-asc': '&#xf162',
  'sort-numeric-desc': '&#xf163',
  'thumbs-up': '&#xf164',
  'thumbs-down': '&#xf165',
  'youtube-square': '&#xf166',
  youtube: '&#xf167',
  xing: '&#xf168',
  'xing-square': '&#xf169',
  'youtube-play': '&#xf16a',
  dropbox: '&#xf16b',
  'stack-overflow': '&#xf16c',
  instagram: '&#xf16d',
  flickr: '&#xf16e',
  adn: '&#xf170',
  bitbucket: '&#xf171',
  'bitbucket-square': '&#xf172',
  tumblr: '&#xf173',
  'tumblr-square': '&#xf174',
  'long-arrow-down': '&#xf175',
  'long-arrow-up': '&#xf176',
  'long-arrow-left': '&#xf177',
  'long-arrow-right': '&#xf178',
  apple: '&#xf179',
  windows: '&#xf17a',
  android: '&#xf17b',
  linux: '&#xf17c',
  dribbble: '&#xf17d',
  skype: '&#xf17e',
  foursquare: '&#xf180',
  trello: '&#xf181',
  female: '&#xf182',
  male: '&#xf183',
  gittip: '&#xf184',
  gratipay: '&#xf184',
  'sun-o': '&#xf185',
  'moon-o': '&#xf186',
  archive: '&#xf187',
  bug: '&#xf188',
  vk: '&#xf189',
  weibo: '&#xf18a',
  renren: '&#xf18b',
  pagelines: '&#xf18c',
  'stack-exchange': '&#xf18d',
  'arrow-circle-o-right': '&#xf18e',
  'arrow-circle-o-left': '&#xf190',
  'toggle-left': '&#xf191',
  'caret-square-o-left': '&#xf191',
  'dot-circle-o': '&#xf192',
  wheelchair: '&#xf193',
  'vimeo-square': '&#xf194',
  'turkish-lira': '&#xf195',
  try: '&#xf195',
  'plus-square-o': '&#xf196',
  'space-shuttle': '&#xf197',
  slack: '&#xf198',
  'envelope-square': '&#xf199',
  wordpress: '&#xf19a',
  openid: '&#xf19b',
  institution: '&#xf19c',
  bank: '&#xf19c',
  university: '&#xf19c',
  'mortar-board': '&#xf19d',
  'graduation-cap': '&#xf19d',
  yahoo: '&#xf19e',
  google: '&#xf1a0',
  reddit: '&#xf1a1',
  'reddit-square': '&#xf1a2',
  'stumbleupon-circle': '&#xf1a3',
  stumbleupon: '&#xf1a4',
  delicious: '&#xf1a5',
  digg: '&#xf1a6',
  'pied-piper': '&#xf1a7',
  'pied-piper-alt': '&#xf1a8',
  drupal: '&#xf1a9',
  joomla: '&#xf1aa',
  language: '&#xf1ab',
  fax: '&#xf1ac',
  building: '&#xf1ad',
  child: '&#xf1ae',
  paw: '&#xf1b0',
  spoon: '&#xf1b1',
  cube: '&#xf1b2',
  cubes: '&#xf1b3',
  behance: '&#xf1b4',
  'behance-square': '&#xf1b5',
  steam: '&#xf1b6',
  'steam-square': '&#xf1b7',
  recycle: '&#xf1b8',
  car: '&#xf1b9',
  taxi: '&#xf1ba',
  tree: '&#xf1bb',
  spotify: '&#xf1bc',
  deviantart: '&#xf1bd',
  soundcloud: '&#xf1be',
  database: '&#xf1c0',
  'file-pdf-o': '&#xf1c1',
  'file-word-o': '&#xf1c2',
  'file-excel-o': '&#xf1c3',
  'file-powerpoint-o': '&#xf1c4',
  'file-photo-o': '&#xf1c5',
  'file-picture-o': '&#xf1c5',
  'file-image-o': '&#xf1c5',
  'file-zip-o': '&#xf1c6',
  'file-archive-o': '&#xf1c6',
  'file-sound-o': '&#xf1c7',
  'file-audio-o': '&#xf1c7',
  'file-movie-o': '&#xf1c8',
  'file-video-o': '&#xf1c8',
  'file-code-o': '&#xf1c9',
  vine: '&#xf1ca',
  codepen: '&#xf1cb',
  jsfiddle: '&#xf1cc',
  'life-bouy': '&#xf1cd',
  'life-buoy': '&#xf1cd',
  'life-saver': '&#xf1cd',
  support: '&#xf1cd',
  'life-ring': '&#xf1cd',
  'circle-o-notch': '&#xf1ce',
  ra: '&#xf1d0',
  rebel: '&#xf1d0',
  ge: '&#xf1d1',
  empire: '&#xf1d1',
  'git-square': '&#xf1d2',
  git: '&#xf1d3',
  'y-combinator-square': '&#xf1d4',
  'yc-square': '&#xf1d4',
  'hacker-news': '&#xf1d4',
  'tencent-weibo': '&#xf1d5',
  qq: '&#xf1d6',
  wechat: '&#xf1d7',
  weixin: '&#xf1d7',
  send: '&#xf1d8',
  'paper-plane': '&#xf1d8',
  'send-o': '&#xf1d9',
  'paper-plane-o': '&#xf1d9',
  history: '&#xf1da',
  'circle-thin': '&#xf1db',
  header: '&#xf1dc',
  paragraph: '&#xf1dd',
  sliders: '&#xf1de',
  'share-alt': '&#xf1e0',
  'share-alt-square': '&#xf1e1',
  bomb: '&#xf1e2',
  'soccer-ball-o': '&#xf1e3',
  'futbol-o': '&#xf1e3',
  tty: '&#xf1e4',
  binoculars: '&#xf1e5',
  plug: '&#xf1e6',
  slideshare: '&#xf1e7',
  twitch: '&#xf1e8',
  yelp: '&#xf1e9',
  'newspaper-o': '&#xf1ea',
  wifi: '&#xf1eb',
  calculator: '&#xf1ec',
  paypal: '&#xf1ed',
  'google-wallet': '&#xf1ee',
  'cc-visa': '&#xf1f0',
  'cc-mastercard': '&#xf1f1',
  'cc-discover': '&#xf1f2',
  'cc-amex': '&#xf1f3',
  'cc-paypal': '&#xf1f4',
  'cc-stripe': '&#xf1f5',
  'bell-slash': '&#xf1f6',
  'bell-slash-o': '&#xf1f7',
  // trash: '&#xf1f8',
  copyright: '&#xf1f9',
  at: '&#xf1fa',
  eyedropper: '&#xf1fb',
  'paint-brush': '&#xf1fc',
  'birthday-cake': '&#xf1fd',
  'area-chart': '&#xf1fe',
  'pie-chart': '&#xf200',
  'line-chart': '&#xf201',
  lastfm: '&#xf202',
  'lastfm-square': '&#xf203',
  'toggle-off': '&#xf204',
  'toggle-on': '&#xf205',
  bicycle: '&#xf206',
  bus: '&#xf207',
  ioxhost: '&#xf208',
  angellist: '&#xf209',
  cc: '&#xf20a',
  ils: '&#xf20b',
  meanpath: '&#xf20c',
  buysellads: '&#xf20d',
  connectdevelop: '&#xf20e',
  dashcube: '&#xf210',
  forumbee: '&#xf211',
  leanpub: '&#xf212',
  sellsy: '&#xf213',
  shirtsinbulk: '&#xf214',
  simplybuilt: '&#xf215',
  skyatlas: '&#xf216',
  'cart-plus': '&#xf217',
  'cart-arrow-down': '&#xf218',
  diamond: '&#xf219',
  ship: '&#xf21a',
  'user-secret': '&#xf21b',
  motorcycle: '&#xf21c',
  'street-view': '&#xf21d',
  heartbeat: '&#xf21e',
  venus: '&#xf221',
  mars: '&#xf222',
  mercury: '&#xf223',
  intersex: '&#xf224',
  transgender: '&#xf224',
  'transgender-alt': '&#xf225',
  'venus-double': '&#xf226',
  'mars-double': '&#xf227',
  'venus-mars': '&#xf228',
  'mars-stroke': '&#xf229',
  'mars-stroke-v': '&#xf22a',
  'mars-stroke-h': '&#xf22b',
  neuter: '&#xf22c',
  genderless: '&#xf22d',
  'facebook-official': '&#xf230',
  'pinterest-p': '&#xf231',
  whatsapp: '&#xf232',
  server: '&#xf233',
  'user-plus': '&#xf234',
  'user-times': '&#xf235',
  hotel: '&#xf236',
  bed: '&#xf236',
  viacoin: '&#xf237',
  train: '&#xf238',
  subway: '&#xf239',
  medium: '&#xf23a',
  yc: '&#xf23b',
  'y-combinator': '&#xf23b',
  'optin-monster': '&#xf23c',
  opencart: '&#xf23d',
  expeditedssl: '&#xf23e',
  'battery-4': '&#xf240',
  'battery-full': '&#xf240',
  'battery-3': '&#xf241',
  'battery-three-quarters': '&#xf241',
  'battery-2': '&#xf242',
  'battery-half': '&#xf242',
  'battery-1': '&#xf243',
  'battery-quarter': '&#xf243',
  'battery-0': '&#xf244',
  'battery-empty': '&#xf244',
  'mouse-pointer': '&#xf245',
  'i-cursor': '&#xf246',
  'object-group': '&#xf247',
  'object-ungroup': '&#xf248',
  'sticky-note': '&#xf249',
  'sticky-note-o': '&#xf24a',
  'cc-jcb': '&#xf24b',
  'cc-diners-club': '&#xf24c',
  clone: '&#xf24d',
  'balance-scale': '&#xf24e',
  'hourglass-o': '&#xf250',
  'hourglass-1': '&#xf251',
  'hourglass-start': '&#xf251',
  'hourglass-2': '&#xf252',
  'hourglass-half': '&#xf252',
  'hourglass-3': '&#xf253',
  'hourglass-end': '&#xf253',
  hourglass: '&#xf254',
  'hand-grab-o': '&#xf255',
  'hand-rock-o': '&#xf255',
  'hand-stop-o': '&#xf256',
  'hand-paper-o': '&#xf256',
  'hand-scissors-o': '&#xf257',
  'hand-lizard-o': '&#xf258',
  'hand-spock-o': '&#xf259',
  'hand-pointer-o': '&#xf25a',
  'hand-peace-o': '&#xf25b',
  trademark: '&#xf25c',
  registered: '&#xf25d',
  'creative-commons': '&#xf25e',
  gg: '&#xf260',
  'gg-circle': '&#xf261',
  tripadvisor: '&#xf262',
  odnoklassniki: '&#xf263',
  'odnoklassniki-square': '&#xf264',
  'get-pocket': '&#xf265',
  'wikipedia-w': '&#xf266',
  safari: '&#xf267',
  chrome: '&#xf268',
  firefox: '&#xf269',
  opera: '&#xf26a',
  'internet-explorer': '&#xf26b',
  tv: '&#xf26c',
  television: '&#xf26c',
  contao: '&#xf26d',
  '500px': '&#xf26e',
  amazon: '&#xf270',
  'calendar-plus-o': '&#xf271',
  'calendar-minus-o': '&#xf272',
  'calendar-times-o': '&#xf273',
  'calendar-check-o': '&#xf274',
  industry: '&#xf275',
  'map-pin': '&#xf276',
  'map-signs': '&#xf277',
  'map-o': '&#xf278',
  map: '&#xf279',
  commenting: '&#xf27a',
  'commenting-o': '&#xf27b',
  houzz: '&#xf27c',
  vimeo: '&#xf27d',
  'black-tie': '&#xf27e',
  fonticons: '&#xf280',
  'reddit-alien': '&#xf281',
  edge: '&#xf282',
  'credit-card-alt': '&#xf283',
  codiepie: '&#xf284',
  modx: '&#xf285',
  'fort-awesome': '&#xf286',
  usb: '&#xf287',
  'product-hunt': '&#xf288',
  mixcloud: '&#xf289',
  scribd: '&#xf28a',
  'pause-circle': '&#xf28b',
  'pause-circle-o': '&#xf28c',
  'stop-circle': '&#xf28d',
  'stop-circle-o': '&#xf28e',
  'shopping-bag': '&#xf290',
  'shopping-basket': '&#xf291',
  hashtag: '&#xf292',
  bluetooth: '&#xf293',
  'bluetooth-b': '&#xf294',
  percent: '&#xf295'
}

const icon = (name, attrs = {}) => qc('i.icon', html(iconCodes[name])).attr(attrs ?? {})

module.exports = { iconCodes, icon }

exports.openCsv = function (name, fn, data, rename) {
  function convertDataURIToBinary(dataURI, hasMarker) {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = hasMarker ? window.atob(base64) : window.atob(dataURI)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }
  var blob = new Blob([convertDataURIToBinary(data)], { type: 'text/csv;charset=utf-8' })
  var filenameWithExtension = fn.replace(/^.*[\\\/]/, '')
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    console.log('openCsv_IE')
    window.navigator.msSaveOrOpenBlob(blob, fn)
  } else {
    console.log('opencsv_Chrome_FF')
    var URL = window.URL || window.webkitURL
    var objectUrl = URL.createObjectURL(blob)
    var link = window.document.createElement('a')
    link.setAttribute('href', objectUrl)
    link.setAttribute('download', rename ? name : filenameWithExtension)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.dispatchEvent(new MouseEvent('click'))
    document.body.removeChild(link)
  }
}

// This prents random dragndrop like urls and selected input text
// Anything that hasn't got attr draggable="true"
window.dragstartOnDraggableOnly = function (e) {
  if (!$(e.target).attr('draggable')) {
    e.preventDefault()
    return false
  }
}
window.$ &&
  $(document).on('dragstart', function (e) {
    return window.dragstartOnDraggableOnly(e)
  })

function DragNSort(config) {
  this.$activeItem = null
  this.$container = config.container
  this.$items = this.$container.querySelectorAll(config.itemClass)
  this.dragStartClass = config.dragStartClass
  this.dragEnterClass = config.dragEnterClass
  this.itemClass = config.itemClass

  this.onSelect = config.onSelect
}

Object.assign(DragNSort.prototype, {
  removeClasses() {
    ;[].forEach.call(
      this.$items,
      function ($item) {
        $item.classList.remove(this.dragStartClass, this.dragEnterClass)
      }.bind(this)
    )
  },

  on(elements, eventType, handler) {
    ;[].forEach.call(
      elements,
      function (element) {
        element.addEventListener(eventType, handler.bind(element, this), false)
      }.bind(this)
    )
  },

  onDragStart(_this, event) {
    _this.$activeItem = this
    this.classList.add(_this.dragStartClass)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/html', this.innerHTML)
    //event.dataTransfer.setData('text/html', this.outerHTML);
  },

  onDragEnd(_this) {
    this.classList.remove(_this.dragStartClass)
  },

  onDragEnter(_this) {
    this.classList.add(_this.dragEnterClass)
  },

  onDragLeave(_this) {
    this.classList.remove(_this.dragEnterClass)
  },

  onDragOver(_this, event) {
    if (event.preventDefault) {
      event.preventDefault()
    }
    event.dataTransfer.dropEffect = 'move'
    return false
  },

  onDrop(_this, event) {
    if (event.stopPropagation) {
      event.stopPropagation()
    }

    if (_this.$activeItem !== this) {
      _this.$activeItem.innerHTML = this.innerHTML
      this.innerHTML = event.dataTransfer.getData('text/html')
      // _this.$activeItem.outerHTML = this.outerHTML;
      // this.outerHTML = event.dataTransfer.getData('text/html');
    }
    _this.removeClasses()
    return false
  },

  onClick(_this, event) {
    if (typeof _this.onSelect === 'function') _this.onSelect(_this, event)
    return false
  },

  bind() {
    this.on(this.$items, 'dragstart', this.onDragStart)
    this.on(this.$items, 'dragend', this.onDragEnd)
    this.on(this.$items, 'dragover', this.onDragOver)
    this.on(this.$items, 'dragenter', this.onDragEnter)
    this.on(this.$items, 'dragleave', this.onDragLeave)
    this.on(this.$items, 'drop', this.onDrop)
    this.on(this.$items, 'click', this.onClick)
  },

  init() {
    this.bind()
  },

  addItem() {
    //var item = $(this.$container).find('.flex-handheld-item:last')[0];
    var item = $(this.$container).find(this.itemClass + ':last')[0]
    this.on([item], 'dragstart', this.onDragStart)
    this.on([item], 'dragend', this.onDragEnd)
    this.on([item], 'dragover', this.onDragOver)
    this.on([item], 'dragenter', this.onDragEnter)
    this.on([item], 'dragleave', this.onDragLeave)
    this.on([item], 'drop', this.onDrop)
    this.on([item], 'click', this.onClick)
  },

  rebindItems() {
    this.$items = this.$container.querySelectorAll(this.itemClass)
  }
})

exports.DND = {
  DragNSort(config) {
    return new DragNSort(config)
  }
}

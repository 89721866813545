//https://codepen.io/MatthewLeFevre/pen/RzLmao?editors=1111

const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { iconCodes } = require('../icon-codes')
/**
 * For lightweight richtextbox
 * @param {*} opts
 * @param {object} opts.view
 * @param {string} opts.value HTML
 * @returns
 */
module.exports.richtexteditor7 = opts => {
  let body

  const me = qc('div.richtexteditor7.focusable.fit', [
    qc('div.tool-list', [
      ...[
        { type: 'justifyLeft', icon: 'align-left' },
        { type: 'justifyRight', icon: 'align-right' },
        { type: 'justifyCenter', icon: 'align-justify' },
        { type: 'bold', icon: 'bold' },
        { type: 'italic', icon: 'italic' },
        { type: 'underline', icon: 'underline' },
        { type: 'insertOrderedList', icon: 'list-ol' },
        { type: 'insertUnorderedList', icon: 'list-ul' }
      ].map(opts =>
        qc('button.ow-button', qc('i.icon.icon-black', html(iconCodes[opts.icon]))).on(
          'click',
          () => {
            document.execCommand(opts.type, false, null)
            body.el.focus()
          }
        )
      ),
      ...(opts.extraButtons ?? [])
    ]).css({ padding: '0', margin: '2px' }),

    (body = qc('div.richtexteditor-content.fit', html(opts.value ?? ''))
      .css({ padding: '10px 20px', background: '#fefefe', margin: '2rem 2px' })
      .attr({ contenteditable: true, tabindex: '0' })
      .on('click', () => body.el.focus())
      .on('input', () => body.trigger('ow-change')))
  ])
    .css({ display: 'flex', flex: '1 0 auto', flexDirection: 'column' })
    .props({
      opts,
      val(v) {
        if (arguments.length) body.kids(html(v))
        v = body.el?.innerHTML ?? v
        return v
      },
      insert(s) {
        body.el.focus()
        const selection = document.getSelection()
        const range = selection.getRangeAt(0)
        let newRange = document.createRange()
        newRange.setStart(range.endContainer, range.endOffset)
        const newNode = document.createTextNode(s)
        newRange.insertNode(newNode)
        selection.removeAllRanges()
        selection.addRange(newRange)
      }
    })
    .attr({ tabindex: '0' })
    .css({ outline: '0' })

  return me
}

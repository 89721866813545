const { qc } = require('../cmp/qc')

exports.gridPager = (g, pageXOf, ofYPages, pageSize, totalRecs) => {
  let pageNum

  const me = qc('div.pager-wrap.grid-pager', [
    qc('a.ow-link.pager-nav.pager-first.btn-pager-first', qc('span.ow-icon.ow-i-seek-w'))
      .attr({
        href: '#',
        title: __('Go to the first page'),
        'data-page': '1',
        tabindex: '-1'
      })
      .on('click', () => {
        if (g.page === 0) return
        g.page = 0
        g.swapPage()
      }),
    qc('a.ow-link.pager-nav.btn-pager-prev', qc('span.ow-icon.ow-i-arrow-w'))
      .attr({
        href: '#',
        title: 'Go to the previous page',
        'data-page': '1',
        tabindex: '-1'
      })
      .on('click', () => {
        if (g.page === 0) return
        g.page = g.page - 1
        g.swapPage()
      }),
    qc('span.pager-input.ow-label', [
      'Page',
      (pageNum = qc('input.ow-textbox.page-x-of')).attr({ value: '' + pageXOf }).on('change', e => {
        var page = parseInt(qc(g).find('.page-x-of')[0].val())
        if (isNaN(page) || page < 1 || page > g.numPages)
          return qc(g)
            .find('.page-x-of')
            .val(g.page + 1)
        g.page = page - 1
        g.dc.load()
      }),
      'of',
      qc('span.of-y-pages', ofYPages)
    ]),
    qc('a.ow-link.pager-nav.btn-pager-next', qc('span.icon.ow-i-arrow-e'))
      .attr({
        href: '#',
        'aria-label': 'Go to the next page',
        title: 'Go to the next page',
        tabindex: '-1'
      })
      .on('click', () => {
        if (g.page === g.numPages - 1) return
        g.page = g.page + 1
        g.swapPage()
      }),
    qc('a.ow-link.pager-nav.pager-last.btn-pager-last', qc('span.icon.ow-i-seek-e'))
      .attr({
        href: '#',
        'aria-label': 'Go to the last page',
        title: 'Go to the last page',
        tabindex: '-1'
      })
      .on('click', e => {
        if (g.page === g.numPages - 1) return
        g.page = g.numPages - 1
        g.swapPage()
      }),
    // '<span class="pager-sizes ow-label"><span title="" class=" k-dropdown ow-header" unselectable="on" role="listbox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-owns="" aria-disabled="false" aria-busy="false" style="">',
    // '<span unselectable="on" class="dropdown-wrap k-state-default"><span unselectable="on" class="ow-input">1000</span>',
    // '<span unselectable="on" class="k-select" aria-label="select"><span class="icon ow-i-arrow-60-down"></span></span></span>',
    qc(
      'select.page-size.ow-label',
      [25, 50, 100, 500, 1000, 2000].map(x =>
        qc('option', '' + x).attr({
          selected: x === pageSize ? 'selected' : undefined,
          value: '' + x
        })
      )
    ).on('change', function (e) {
      g.pageSize = parseInt(e.target.value)
      g.page()
    }),
    qc('span', __('per page')),
    qc(
      'span.pager-info.k-label',
      pageXOf + 1 + ' - ' + (pageXOf + pageSize) + __(' of ') + totalRecs + ' items'
    )
  ])
    // .attr({ 'data-role': 'pager' })
    .props({ pageNum })

  return me
}

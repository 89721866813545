exports.saveUserSetting = (url, $win, inclGrids = true) => {
  const winpref = $win.viewdata.winpref ?? {
    viewName: url,
    windowHeight: parseInt($win.height()),
    windowWidth: parseInt($win.width())
  }

  if (!inclGrids) delete winpref.grids

  // detect changes
  const changed = $win.viewdata._winpref !== JSON.stringify(winpref)

  winpref.viewName = url

  if (changed)
    return $ajax({
      type: 'POST',
      url: 'data/usersetting/method/setuserwindowpref',
      data: winpref
    }).catch(err => {
      console.error(err)
      ow.popError('userSetting.Save Error', __('Failed to save preferences'), 5000)
    })
}

// ow.js
const ow6 = {}
module.exports.ow6 = ow6

Object.assign(ow6, require('./stylesheet'))
Object.assign(ow6, require('./icons'))
Object.assign(ow6, require('./check6'))
Object.assign(ow6, require('./radio6'))
Object.assign(ow6, require('./text6'))
Object.assign(ow6, require('./date6'))
Object.assign(ow6, require('./datetime6'))
Object.assign(ow6, require('./time6'))
Object.assign(ow6, require('./button6'))
Object.assign(ow6, require('./combo6'))
Object.assign(ow6, require('./store-combo6'))
Object.assign(ow6, require('./grid6/grid6'))
Object.assign(ow6, require('./scheduler6/scheduler6'))
Object.assign(ow6, require('./structure'))
Object.assign(ow6, require('./modal-popup'))
Object.assign(ow6, require('./tabstrip6'))
Object.assign(ow6, require('./data-model'))
Object.assign(ow6, require('./data-source'))
Object.assign(ow6, require('./data-ags'))
Object.assign(ow6, require('./calendar6'))
Object.assign(ow6, require('./win-con'))
Object.assign(ow6, require('./data-validation'))
Object.assign(ow6, require('./grid6/grid-groups'))
Object.assign(ow6, require('./grid6/grid-pager'))
Object.assign(ow6, require('./grid6/grid-header'))
Object.assign(ow6, require('./grid6/grid-cols'))
Object.assign(ow6, require('./wireUpView6'))
Object.assign(ow6, require('./richtexteditor6/richtexteditor6'))

// qCalendar
const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { common } = window

const styles = scope =>
  qc(
    'style',
    html(`
${scope} th,
${scope} td {
  text-align: center;
  padding: 0;
  line-height: 1.92em;
}
${scope} th > a,
${scope} td a {
  box-sizing: border-box;
  display: block;
  padding: 0.5em 0.825em;
  cursor: pointer;
  line-height: 1.92em;
}
${scope} td.prevmonthdates,
${scope} td.nextmonthdates {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}
${scope} td.currentmonthdates.today {
  font-weight: bold;
  text-decoration: underline;
}
${scope} td:hover {
  background-color: #99c0ff;
  color: #515956;
}
/* Wrapper div. That makes the inner div into an inline element that can be centered with text-align.*/
${scope} { text-align: center; }

/* This is a fluid div as width will be changing */
${scope} .calendar-controls > a {
  display: inline-block; margin-left: 0.625em; 
}
`)
  )

const qCalendar = (onSetDate, d = new Date(), template) => {
  const id = 'c' + (new Date().getTime() - new Date(2020, 1, 1).getTime())
  const me = qc('div.ow-calender.' + id)

  me.DaysOfWeek = ow.dates.shortDaysOfWeekJs // Days of week, starting on Sunday
  me.Months = ow.dates.shortMonths // Months, stating on January
  // Set the current month, year
  // var d = new Date()
  me.CurrentMonth = d.getMonth()
  me.CurrentYear = d.getFullYear()
  me.format = ow.dates.DateFormatSetting

  me.nextMonth = () => {
    if (me.CurrentMonth === 11) {
      me.CurrentMonth = 0
      me.CurrentYear++
    } else me.CurrentMonth++

    me.showCurrent()
  }

  me.previousMonth = () => {
    if (me.CurrentMonth === 0) {
      me.CurrentMonth = 11
      me.CurrentYear--
    } else me.CurrentMonth--

    me.showCurrent()
  }

  me.previousYear = () => {
    me.CurrentYear--
    me.showCurrent()
  }

  me.nextYear = () => {
    me.CurrentYear++
    me.showCurrent()
  }

  me.showCurrent = () => {
    me.build(me.CurrentYear, me.CurrentMonth)
  }

  me.selectDate = v => {
    me.value = v
    onSetDate(v)
    me.trigger('ow-select', v)
  }

  me.build = (y = new Date().getFullYear(), m = new Date().getMonth()) => {
    typeof y === 'number' ? (me.CurrentYear = y) : null
    typeof m === 'number' ? (me.CurrentMonth = m) : null

    var today = new Date()
    var todayMonth = today.getMonth()
    var todayYear = today.getFullYear()
    today = new Date(todayYear, todayMonth, today.getDate())

    // Write selected month and year. This HTML goes into <div id="month"></div>

    const sYear =
      (common.culture().calendar === 'buddhist' ? common.dates.buddhistCalendarOffset : 0) + y

    const table = qc('table', [
      styles('.' + id),
      qc('tr.calendar-controls', [
        qc(
          'th',
          qc('a', '<<')
            .attr({
              href: '#',
              title: __('Previous Year')
            })
            .on('click', () => me.previousYear())
        ),
        qc(
          'th',
          qc('a', '<')
            .attr({
              href: '#',
              title: __('Previous Month')
            })
            .on('click', () => me.previousMonth())
        ),
        qc('th', me.Months[m] + ' ' + sYear).attr({ colspan: '3' }),
        qc(
          'th',
          qc('a', '>')
            .attr({
              href: '#',
              title: __('Next Month')
            })
            .on('click', () => me.nextMonth())
        ),
        qc(
          'th',
          qc('a', '>>')
            .attr({
              href: '#',
              title: __('Next Year')
            })
            .on('click', () => me.nextYear())
        )
      ]),

      qc(
        'tr',
        me.DaysOfWeek.map(d => qc('th.daysheader', d))
      )
    ])

    let dt = new Date(y, m, 1)

    var p = dt.getDay() // 1st day of the selected month
    //p = p ? (p - 7) : 0; // if it's not the first day of the week then move back
    p = (p + 1) * -1
    dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + p)

    let meRow

    const dateCell = dt =>
      qc('td.date-cell', template?.(dt) ?? qc('a', '' + dt.getDate()).attr({ href: '#' })).on(
        'click',
        () => me.selectDate(dt)
      )

    var i = 0
    for (i = 0; i < 42; i++) {
      //eg Jun 2019, doesnt show all if 5 weeks only
      dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 1) // watch out for daylight savings

      if (i % 7 === 0) {
        meRow = qc('tr', [])
        table.children.push(meRow)
      }

      var isTodayClass = dt.getTime() === today.getTime() ? 'today ' : ''

      meRow.children.push(
        dateCell(dt).addClass(
          dt.getFullYear() !== y
            ? isTodayClass + (dt.getFullYear() < y)
              ? 'prevmonthdates'
              : 'nextmonthdates'
            : isTodayClass +
                ['prevmonthdates', 'currentmonthdates', 'nextmonthdates'][dt.getMonth() - m + 1]
        )
      )
    }

    me.kids(table)
  }

  me.showCurrent()
  return me
}

module.exports = { qCalendar }

const { qc } = require('../cmp/qc')
const { dataBind } = require('./databind7')
const { rs } = require('./rs7')

/**
 * A databound checkbox
 *
 * @param {object} opts
 * @param {bool} opts.disabled
 * @param {bool} opts.value - sets the initial value
 * @param {string} opts.fieldName
 * @param {string} opts.dsName dataSource name.
 * @param {object} opts.model
 * @param {string} opts.label - please translate first
 * @param {bool} opts.labelRight - displays the label to the right of the check rather than to the left
 *
 * @returns {qc} qc of the anchor element. Use its .wrap() or .rs() for adding to the DOM
 */
const check7 = opts => {
  const me = qc('a.check7.ow-check')
    .props({
      val(...args) {
        const [v, , populating] = args
        if (args.length) {
          var changed = v != me.hasClass('on')
          v ? me.addClass('on') : me.removeClass('on')
          if (changed && !populating) me.trigger('ow-change')
        }
        return me.hasClass('on')
      },
      readFilter(filters) {
        if (me.val()) {
          var filter = {
            field: opts.fieldName,
            operator: opts.op ?? 'eq',
            value: opts.filterValue ?? me.val()
          }
          filters.push(filter)
        }
      },
      odisable(...args) {
        me.disabled = args[0] ?? false
        me.el.odisable(...args)
      },
      opts
    })
    .attr({ href: '#' })
    .on('init', (e, el) => (el.opts = opts))
    .on('click', () => {
      const prev = me.val()
      me.val(!prev)
    })
    .on('keypress', e => {
      if (e.which === 32) {
        me.el.click()
        e.preventDefault()
        return false
      }
    })

  me.qCheck = true

  const _w = qc(
    'span.ow-ctl-wrap.check7',
    opts.labelRight
      ? [
          me,
          qc('span', opts.label).on('click', () => {
            if (me.disabled) return
            const prev = me.val()
            me.val(!prev)
          })
        ]
      : [me]
  )
    .props({ input: me })
    .bindState(
      () => me.disabled,
      v => {
        v ? _w.addClass('ow-disabled') : _w.removeClass('ow-disabled')
        _w.css({ cursor: v ? undefined : 'pointer' })
      }
    )

  me.wrap = () => _w

  if (opts.disabled) me.disabled = true

  dataBind(me)
  if (opts.model) me.populate(opts.model)

  if (opts.value !== undefined) me.val(opts.value)

  me.bindState(
    () => me.readOnly || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )

  const _rs = rs({ label: opts.labelRight ? '' : opts.label ?? '' }, _w)

  me.rs = () => opts.rs ?? _rs

  return me
}

module.exports = { check7 }

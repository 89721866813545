/**
 * takes nested data item.items array - expand and contract, checking parent makes children the same.
 * The value data (ie. checks) is not nested, the "schema" is
 *
 * @param {any} opts
 * @param {any} opts.indentField - the item fieldname in the list that indicates indentation level
 * @returns
 */
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { iconCodes } = require('../icon-codes')
const { check7 } = require('./check7')
const { dataBind } = require('./databind7')

exports.checktreelist7 = opts => {
  const me = qc('div.checklisttree.ow-checklist')

  opts.textField ??= 'Text'
  opts.valueField ??= 'Value'

  let list,
    dataList,
    allChecks = []

  const buildList = () => {
    allChecks = []
    const buildCheckBox = (listItem, siblings) => {
      let model = dataList.find(x => x[opts.valueField] === listItem[opts.valueField])

      // if (model) model.checked = true
      // else dataList.push((model = { [opts.valueField]: listItem[opts.valueField] }))
      // model[opts.textField] ??= listItem[opts.textField]

      const panel = qc('div.tree-node-wrap')
      const myKids = []

      const chk = check7({
        fieldName: 'checked',
        model,
        // label: listItem[opts.textField] + ' (' + listItem[opts.valueField] + ')',
        label: listItem[opts.textField],
        labelRight: true,
        disabled: listItem.disabled
      }).on('click', () => {
        const v = chk.val()
        myKids.forEach(chk => {
          if (chk.attr('disabled') === undefined && chk.val() !== v) chk.trigger('click')
        })
      })

      siblings?.push(chk)
      allChecks.push(chk)

      if (listItem.items?.[0]) {
        panel.addClass('has-kids')

        const openClose = qc('a.hide-kids', [
          qc('i.icon.up-icon', html(iconCodes.caretDown)).bindState(
            () => (panel.hasClass('closed') ? undefined : 'none'),
            (display, i) => i.css({ display })
          ),
          qc('i.icon.down-icon', html(iconCodes.caretUp)).bindState(
            () => (!panel.hasClass('closed') ? undefined : 'none'),
            (display, i) => i.css({ display })
          )
        ])
          .attr({ href: '#' })
          .css({ marginLeft: '0.5rem' })
          .on('click', () => panel.toggleClass('closed'))

        chk.addClass('parent-check').bindState(
          () => myKids.find(x => !x.model?.checked) === undefined,
          allChecked => {
            if (model && model.checked !== allChecked) {
              model.checked = allChecked
              setTimeout(() => me.renderAsync(), 5)
            }
          }
        )

        panel.kids([
          openClose,
          qc(
            'div.tree-kids',
            listItem.items.map(item => buildCheckBox(item, myKids))
          )
        ])

        let isAnyKidDisabled = listItem.items.find(x => x.disabled) !== undefined
        if (isAnyKidDisabled) listItem.disabled = true
      }

      return panel.kids([chk.wrap(), ...panel.kids()])
    }

    me.kids(list.map(item => buildCheckBox(item)))
  }

  me.props({
    opts,

    val(v) {
      if (v !== undefined)
        if (v !== dataList) {
          dataList = v
          if (list) buildList()
        }

      return dataList
    }
  })

  dataBind(me)

  const setTicks = v => allChecks.forEach(chk => chk.attr('disabled') === undefined && chk.val(v))

  me.on('command-tickall', () => setTicks(true)).on('command-untickall', () => setTicks(false))

  if (opts.url) {
    $ajax({ url: opts.url })
      .then(r => {
        list = r
        if (dataList) buildList()
      })
      .catch(err => {
        console.error(err)
        ow.popError(err)
      })
  } else if (!opts.list) console.warn('No list or url found in checktreelist options')
  else dataList = list = opts.list

  return me
}

const { overviewCon } = require('./overviewCon')
const { storageCon } = require('./webStorage')

exports.editorCon = function ($o, opts) {
  if (opts.mode === 'select' || opts.mode === 'multi-select') {
    if (opts.isOverview) overviewCon($o, opts)
    else console.warn('editorCon')
  }

  // register autoSaveDraft
  storageCon($o, opts)
}

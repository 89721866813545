const { dropdown } = require('./dropdown')
const { icon6 } = require('./icons')
const { calendar6 } = require('./calendar6')
const { react } = require('./data-model')
const { isInvalidDate } = require('../js-types')
const { killEvent } = require('../killEvent')
const { $cmp } = require('../cmp/$cmp')
const { qc } = require('../cmp/qc')
const dates = require('../dates')
const { ctlParsers } = require('../ctl-format')
const { culture } = require('../culture')

const { resolveDateTime, formatDateTime } = dates

const datetime6Props = {
  requestTabOut() {
    this.resolve()
    return true
  },

  openDropDown() {
    const { el } = this

    this.dropdown = dropdown({ combo: this }).css({ width: undefined })
    this.dropdown.renderTo(this.view.qTop.el.parentElement)
    this.addClass('ow-dropdown-open')

    const cal = calendar6(this.val())
    cal.select = value => {
      if (el.value) {
        //If a datetime has already been entered, preserve the existing time.
        value.setHours(el.value.slice(11, 13))
        value.setMinutes(el.value.slice(14, 16))
      }
      this.val(value) // formatting?
      this.trigger('ow-change')
      if (this.model && this.fieldName) react(this.view, this.model, this.fieldName)
      $cmp(el).dropdown.close()
    }
    this.dropdown.kids([cal])
    this.dropdown.renderAsync()
  },

  openTimeDropDown() {
    const { el } = this

    let list = []
    let i = 0,
      hh
    for (; i < 24; i++) {
      hh = ('0' + i).substr(-2)
      list.push(hh + ':00')
      list.push(hh + ':30')
    }
    list.push('24:00')

    this.dropdown = dropdown({
      combo: this,
      listWidth: 1,
      children: qc(
        'div.combo-list-popup',
        qc(
          'ul',
          { style: 'list-style-type:none;' },
          !list.length
            ? qc('li', 'No Matches Found')
            : list.map((x, i) =>
                qc('li' + (i === this.listIndex ? '.ow-selected' : ''), x).on('click', () => {
                  let v = this.val() || Date.today()
                  let [h, m] = x.split(':')
                  v.setHours(parseInt(h))
                  v.setMinutes(parseInt(m))
                  this.val(v) // If a datetime has already been entered, preserve the existing date.
                  this.trigger('ow-change')
                  el.focus()
                  this.dropdown.close()
                })
              )
        )
      )
    })

    this.dropdown.renderTo(this.view.qTop.el.parentElement)
    this.addClass('ow-dropdown-open')
  },

  updateContent() {},

  resolve() {
    const el = this.el
    if (el.value) {
      const v = resolveDateTime(el.value, true)
      if (el.value !== v && !isInvalidDate(v)) el.value = v
    }
  }

  // val(v) {
  //   let r = typeof v !== 'undefined' ? super.val(v) : super.val()
  //   return r !== '' ? r : undefined
  // },
}

const datetime6 = props => {
  props.tag = props.tag || 'input.text6'
  props.parser = props.parser || ctlParsers.datetime

  props.formatter = (v, fmt) =>
    !v ? '' : formatDateTime(v, fmt || culture().DateTimeFormatSetting)

  const me = props.view.controls
    .text6(props)
    .props(datetime6Props)
    .addClass('datetime6 text-icon-after')
    .css({ paddingRight: '3.3em' })

  me.on('keyup', e => {
    const { el } = me

    if (el.value !== me.prevValue) {
      // don't interfere if backspace || delete
      if (e.which !== 8 && e.which !== 46) {
        var x = el.selectionEnd
        var caretAtEnd = x === el.value.length
        var v = resolveDateTime(el.value, false, undefined, { position: x })
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        if (el.value !== v && !isInvalidDate(dtVal)) el.value = v
        me.prevValue = v
        if (!caretAtEnd) {
          el.selectionStart = x
          el.selectionEnd = x
        }
      }
      const dtVal = me.val()
      if ((!el.value || dtVal) && !isInvalidDate(dtVal)) me.trigger('ow-change')
    }
  })
    .on('keydown', e => {
      me.prevValue = me.el.value

      if (e.which === 13) {
        if (!me.dropdown) {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          if (e.shiftKey || me.openOnEnterKey) me.openDropDown() // openDropDownFactory(el, dateDropDownOpts())()
        } else {
          me.dropdown.close()
        }
        return killEvent(e, false)
      }

      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        var charDeleted = (me.prevValue || me.el.value)[me.el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }
    })
    .on('blur', () => {
      setTimeout(() => {
        if (me.el !== document.activeElement) me.trigger('ow-delayed-blur')
      }, 200)
    })
    .on('ow-delayed-blur', e => {
      const { el, dropdown } = me
      if (dropdown) {
        if (me.ignoreNextChange) {
          me.ignoreNextChange = false
          return killEvent(e, false)
        } else dropdown.close()
      }
      if (el === document.activeElement) return killEvent(e, false)
      if (me.resolveBeforeExit) me.resolveBeforeExit(!me.allowUnmatchedText)
    })

  const wrap = me.wrap().addClass('ow-datetime-wrap text-icon-after')

  wrap.kids([
    ...wrap.kids(),
    icon6('clock')
      .addClass('text-item-icon')
      .on('click', () => {
        me.el.focus()
        if (me.dropdown) me.dropdown.close()
        else {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          me.openTimeDropDown()
        }
        return false
      })
  ])

  wrap.kids([
    ...wrap.kids(),
    icon6('calendar')
      .addClass('text-item-icon')
      .css({ position: 'relative', right: '0.77em' })
      .on('click', () => {
        me.el.focus()
        if (me.dropdown) me.dropdown.close()
        else {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          me.openDropDown()
        }
        return false
      })
  ])

  return me
}

module.exports = {
  datetime6
}

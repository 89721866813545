const { _v } = require('../_v')

exports.clearSession = function () {
  localStorage.clear()
}

const getDBIdUserTag = () => ow0.DBID + '@' + ow0.UserID
const getCurrentStore = () => ow0.currentStore

const storageCon = function ($o, opts) {
  if (opts.savedraft) {
    const tagName = opts.savedraft.tagName

    $o.formDataSave = function (tagName) {
      if ($o.dc && $o.dc.hasChanged()) {
        var _formData = $o.dc.readData()

        var storeKey =
          typeof opts.savedraft.storeKey === 'string'
            ? _formData && _formData[opts.savedraft.storeKey]
            : _formData &&
              _formData[opts.savedraft.storeKey.objectFieldName] &&
              _formData[opts.savedraft.storeKey.objectFieldName][opts.savedraft.storeKey.valueField]
        if (storeKey) {
          storage.save(
            (tagName || opts.savedraft.tagName) + '_' + $o.wid,
            JSON.stringify(_formData)
          )
        } else {
          console.warn('No store found')
        }
      }
    }

    $o.formDataTimer =
      $o.formDataTimer ||
      setInterval(
        () => {
          $o.formDataSave(tagName)
        },
        opts.saveInterval || storage.saveInterval || 10000
      )
  }

  //cleanup autoSaveDraft timer
  $o.on('close', () => {
    if ($o.formDataTimer) {
      clearInterval($o.formDataTimer)
      storage.delete((opts.savedraft.tagName || '') + '_' + $o.wid)
    }
  })

  //bind event
  $($o).on('keydown', function (e) {
    if (e.which === 121) {
      //F10
      if ($o.formDataSave) $o.formDataSave()
    }
  })
}

const storageManager = function ($o, opts) {
  var sm = {}
  sm.recoveries = { formDatas: [], keys: [] }
  var openedWins = ow0.windows.findOpenWindows()
  var exceptions = (openedWins || []).map(({ wid }) => wid)
  var isCentralStore = getCurrentStore().StoreType === 2
  var _temp = storage.getGroupData(opts.tagName || '', exceptions)

  if (!isCentralStore && _temp.length) {
    //skip if current was restore window
    ;(_temp || []).map(function (d) {
      //filter non current store
      var _formData = JSON.parse(d.data)
      var storeKey =
        typeof opts.storeKey === 'string'
          ? _formData && _formData[opts.storeKey]
          : _formData &&
            _formData[opts.storeKey.objectFieldName] &&
            _formData[opts.storeKey.objectFieldName][opts.storeKey.valueField]

      if (getCurrentStore().StoreID === storeKey) {
        _formData._keyIndex = d.key
        sm.recoveries.formDatas.push(_formData)
        sm.recoveries.keys.push(d.key)
      }
    })
  }

  sm.openDrafts = function () {
    if (!sm.recoveries.formDatas.length) return
    ow0.windows.openView({
      name: 'Recovery',
      url: 'view/recover/common',
      modal: true,
      record: sm.recoveries,
      gridColMaps: opts.gridColMaps,
      height: 500,
      callback($win, viewdata) {
        if (viewdata.result) restoreFormData(viewdata.result)

        setTimeout(() => {
          if (viewdata.discard !== false) storage.clear(viewdata.record, viewdata.result) //viewdata.discard = false for close
          $o.trigger('draft-refresh', [viewdata.result])
        }, 1000)
      }
    })
  }

  function myId(r, alwaysReturnObj) {
    if (opts.idFields.length === 1) {
      if (alwaysReturnObj) {
        var res = {}
        res[opts.idField] = r && _v(r, opts.idField)
        return res
      } else {
        return r && _v(r, opts.idField)
      }
    }
    var ids = {}
    opts.idFields.forEach(function (x) {
      ids[x] = _v(r, x)
    })
    return ids
  }

  function idString(r) {
    let id = myId(r)
    return typeof id === 'object' ? JSON.stringify(id) : id
  }

  function restoreFormData(d) {
    d?.Recoveries.forEach(r => openRecovery(r))
  }

  function openRecovery(d) {
    var key = opts.key
    var isEditMode = d[key].toString().indexOf('New') < 0
    var restoreOpts = {
      name: opts.name.split(' - ')[0] + ' - ' + (isEditMode ? 'Edit' : 'New'),
      url: opts.url,
      restoreData: d
    }
    if (isEditMode) {
      restoreOpts.id = idString(d)
      restoreOpts.mode = 'edit'
    }
    ow0.windows.openView(restoreOpts)
  }

  return sm
}

const storage = {
  saveInterval: 10000, //10s
  save: function (wid, v) {
    wid = getDBIdUserTag() + '_' + wid
    localStorage.setItem(wid, v)
  },
  delete: function (wid) {
    wid = getDBIdUserTag() + '_' + wid
    localStorage.removeItem(wid)
  },
  clear: function (delList, resultList) {
    Object.keys(localStorage).map(function (key) {
      if (
        resultList &&
        resultList.Recoveries.some(function (r) {
          return r._keyIndex === key
        })
      ) {
        localStorage.removeItem(key)
      } else if (!resultList && delList.keys && delList.keys.indexOf(key) >= 0) {
        //no resultList = discard all
        localStorage.removeItem(key)
      }
    })
  },
  read: function (wid) {
    wid = getDBIdUserTag() + '_' + wid
    return localStorage.getItem(wid)
  },
  getGroupData: function (grp, exceptions) {
    var keys = Object.keys(localStorage)
    return keys
      .filter(function (key) {
        var keyParams = key.split('_')
        if (keyParams.length < 2 || keyParams[0] !== getDBIdUserTag()) {
          return false
        }
        if (keyParams[1] === grp) {
          if (exceptions.indexOf(keyParams[2]) < 0) {
            return true
          }
        }
      })
      .map(function (key) {
        return { key: key, data: localStorage[key] }
      })
  }
}

exports.storageCon = storageCon
exports.storageManager = storageManager
exports.storage = storage

// add to this eventTypes that don't bubble
const noBubble = {
  focus: 1,
  blur: 1,
  resize: 1,
  mouseenter: 1,
  mouseleave: 1
}

const eventTypes = [
  'focus',
  'focusin',
  'focusout',
  'blur',
  'click',
  'dblclick',
  'keyup',
  'keydown',
  'keypress',
  'change',
  'select',
  'scroll',
  'mousedown',
  'mouseup',
  'mousemove',
  'resize',
  'dragstart',
  'dragend',
  'dragenter',
  'dragleave',
  'dragover',
  'drop',

  // ow custom
  'enter-key',
  'ow-change',
  'ow-select',
  'command-delete',
  'command-save',
  'command-new',
  'ow-cmenu-open',
  'contextmenu',
  'ow-delayed-blur',
  'ow-field-changed',
  'ow-grid-change',
  'ow-populate',
  'ow-tab-change',
  'ow-grid-databound',
  'ow-data-bound',
  'ow-datastatechange',
  'ow-data-saved'
]

const trigger = (el, eventType, ...arrayOfArgs) => {
  if (arrayOfArgs.length === 1 && Array.isArray(arrayOfArgs[0]))
    console.warn(
      "trigger was called with an array event argument. Please check you haven't confused it with the $.trigger.",
      eventType,
      ...arrayOfArgs
    )

  let event

  if (typeof eventType === 'object') {
    event = eventType
    eventType = event.eventType
  }

  if (eventTypes.indexOf(eventType) === -1) eventTypes.push(eventType) // console.warn('$trigger with unknown eventType: ' + eventType)

  const MouseEvents = {
    mousedown: 1,
    mouseup: 1,
    click: 1,
    dblclick: 1,
    mouseenter: 1,
    mouseout: 1,
    mousemove: 1,
    mouseover: 1,
    mousewheel: 1,
    mouseleave: 1,
    contextmenu: 1
  }
  const TouchEvents = { touchstart: 1, touchmove: 1, touchend: 1, touchcancel: 1 }
  const KeyboardEvents = { keydown: 1, keypress: 1, keyup: 1 }
  const FormEvents = { focus: 1, blur: 1, change: 1, submit: 1 }
  const WindowEvents = { scroll: 1, resize: 1, hashchange: 1, load: 1, unload: 1 }
  const DragEvents = {
    drag: 1, //The event occurs when an element is being dragged
    dragend: 1, //The event occurs when the user has finished dragging an element
    dragenter: 1, //The event occurs when the dragged element enters the drop target
    dragleave: 1, //The event occurs when the dragged element leaves the drop target
    dragover: 1, //The event occurs when the dragged element is over the drop target
    dragstart: 1, // The event occurs when the user starts to drag an element
    drop: 1
  }

  let extras = { bubbles: !noBubble[eventType] }

  if (!event) {
    if (MouseEvents[eventType]) event = new MouseEvent(eventType, extras)
    else if (KeyboardEvents[eventType]) event = new KeyboardEvent(eventType, extras)
    else if (TouchEvents[eventType]) event = new TouchEvent(eventType, extras)
    else if (DragEvents[eventType]) event = new DragEvent(eventType, extras)
    else if (FormEvents[eventType]) event = new CustomEvent(eventType, extras)
    else if (WindowEvents[eventType]) event = new CustomEvent(eventType, extras)
    else event = new CustomEvent(eventType, extras)
  }

  event.owArgs =
    typeof arrayOfArgs === 'undefined'
      ? []
      : Array.isArray(arrayOfArgs)
      ? arrayOfArgs
      : [arrayOfArgs]

  if (!el.dispatchEvent) {
    console.error('$trigger called on something other than a DOM Element.')
    debugger
    return
  }
  return el.dispatchEvent(event)
}

const on = (el, et, handler, capture = false) => {
  if (typeof et !== 'string') {
    console.warn('on() EventType is not a string')
    debugger
  }

  // for compat with jquery on('keyup keydown keypress')
  const ets = et.split(' ')
  if (ets.length > 1) return ets.forEach(et => on(el, et, handler, capture))

  if (typeof handler !== 'function') {
    console.warn('on() hander is not a function')
    debugger
  }

  if (typeof handler === 'string')
    throw 'jquery selector events: on(eventtype, selector, handler, capture) not supported'

  if (
    !el ||
    !et ||
    !handler ||
    !el.nodeName ||
    typeof et !== 'string' ||
    typeof handler !== 'function'
  ) {
    console.error('Argument has incorrect type or value.')
    return
  }

  el.addEventListener(
    et,
    function (e) {
      return handler.call(this, e, ...(e.owArgs || []))
    },
    capture === true
  )
  return el
}

module.exports = { eventTypes, trigger, on, noBubble }

const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { __ } = require('../culture')
const { icon } = require('../icon-codes')
const theme = require('../theme')
const { combo7 } = require('./combo7')
const { int7 } = require('./int7')

const circleIcon = iconName =>
  qc('a', icon(iconName).addClass('circle-icon')).attr({
    href: '#',
    title: __('Go to the first page'),
    tabIndex: '-1'
  })

exports.gridPager7 = (view, paging, onChange) => {
  const me = qc('div.pager-wrap.grid-pager7').css({ display: paging ? undefined : 'none' })

  if (!paging) return

  let { page = 1, pageSize = 20, totalRecs = 0 } = paging

  paging.page = page
  paging.pageSize = pageSize
  paging.totalRecs = totalRecs

  let numPages = Math.ceil(totalRecs / pageSize)

  const kids = [
    styles(),

    circleIcon('angle-double-left')
      .attr({ title: __('Go to the first page') })
      .on('click', () => {
        if (paging.page === 1) return
        paging.page = 1
        me.renderAsync()
        onChange?.(paging)
      }),

    circleIcon('angle-left')
      .css({ marginRight: '1rem' })
      .attr({ title: __('Go to the previous page') })
      .on('click', () => {
        if (paging.page === 1) return
        paging.page = paging.page - 1
        me.renderAsync()
        onChange?.(paging)
      }),

    qc('span', qc('label', __('Page'))),

    qc(
      'span',
      int7({ min: 1, max: numPages })
        .css({ width: '2.5rem' })
        .on('change', function () {
          const v = this.val()
          paging.page = v
          if (!v || v < 1 || v > numPages) return this.val(paging.page)
          paging.page = v
          onChange?.(paging)
          me.renderAsync()
        })
        .bindState(
          () => (paging.totalRecs ? paging.page : 0),
          (page, me) => {
            me.val(page)
          }
        )
        .wrap()
    ),

    qc('span', qc('label')).bindState(
      () => Math.ceil(paging.totalRecs / paging.pageSize),
      (v, me) => {
        numPages = v
        me.kids([__(' of ') + numPages])
      }
    ),

    circleIcon('angle-right')
      .css({ marginLeft: '1rem' })
      .attr({ title: __('Go to the next page') })
      .on('click', () => {
        if (paging.page === numPages) return
        paging.page = paging.page + 1
        me.renderAsync()
        onChange?.(paging)
      }),

    circleIcon('angle-double-right')
      .css({ marginRight: '1rem' })
      .attr({ title: __('Go to the last page') })
      .on('click', () => {
        if (paging.page === numPages) return
        paging.page = numPages
        me.renderAsync()
        onChange?.(paging)
      }),

    qc(
      'span',
      (me => {
        const f = me.attr
        me.attr = function (...args) {
          if (typeof args[0] === 'object' && 'value' in args[0])
            console.warn('attr setting', args[0].value)
          return f.call(me, ...args)
        }
        return me
      })(
        combo7({
          view,
          model: paging,
          fieldName: 'pageSize',
          dataType: 'int',
          list: [20, 50, 100, 200, 500].map(Value => ({ Value, Text: '' + Value }))
        })
      )
        .on('ow-select', function () {
          paging.page = 1
          paging.pageSize = this.val()
          onChange?.(paging)
        })
        .css({ width: '2rem' })
        .wrap()
    ),

    qc(
      'span',
      qc('label').bindState(
        () =>
          paging.totalRecs
            ? [
                (paging.page - 1) * paging.pageSize + 1 + '',
                '-',
                Math.min(paging.totalRecs, paging.page * paging.pageSize - 1 + 1) + '',
                __('of'),
                paging.totalRecs + '',
                __('items')
              ].join(' ')
            : __('PAGER:empty'),
        (s, me) => me.kids(s)
      )
    ).css({ flexGrow: '2', textAlign: 'right' })
  ]

  return me.kids(kids)
}

const styles = (scope = '.css7') =>
  html(
    `<style>
${scope} .grid-pager7 i.circle-icon {
  display: inline-block;
  border: 1px solid ${theme.textboxBorderColor};
  border-radius: 1em;
  line-height: 1.5rem;
  vertical-align: middle;
  width: 1.5rem;
  text-align: center;
  margin: -1px 0; /* offset border */
}

${scope} .grid-pager7 i.circle-icon:hover { 
  background-color: ${theme.textboxBorderColor};
}

${scope} div.grid-pager7 {
  border: 1px transparent solid;
  border-top-color: ${theme.textboxBorderColor};
  display: flex;
  gap: 4px;
  padding: 3px;
  vertical-align: top;
  font-size: 1em;
  background: ${theme.grayBackgroundColor};
  line-height: 1.5rem;
  box-sizing: border-box;
}

${scope} .grid-pager7 > * {
  vertical-align: baseline;
  display: inline-block;
  height: inherit;
  line-height: inherit;
}

${scope} .grid-pager7 label {
  line-height: 24px;
}
</style>`
  )

const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { popError } = require('../pop-box')
const { $offset } = require('../no-jquery')
const { makeDropdown7 } = require('./makeDropdown7')
const { filterFunctions } = require('./filters')

const scrollItemIntoView = selectedLi => {
  const ul = selectedLi.el.parentElement

  if (!ul) return

  const bottom = selectedLi.el.offsetHeight + selectedLi.el.offsetTop
  if (bottom > ul.scrollTop + ul.clientHeight)
    ul.scrollTop = selectedLi.el.offsetTop - (ul.clientHeight - 40)
  else if (selectedLi.el.offsetTop < ul.scrollTop) ul.scrollTop = selectedLi.el.scrollTop - 2

  // console.log('scrolling to item at:', ul.scrollTop, 'px')
}

/**
 *
 * @param {object} opts
 * @param {Array} opts.list
 * @param {string} opts.url
 * @param {bool} opts.isFilterControl
 * @param {string} opts.textFilterField
 * @param {object} opts.filterMap
 * @param {string} opts.fieldName
 * @param {string} opts.dsName
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {string} opts.objectFieldName
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {function} opts.template - returns HTML/qc for text display and dropdown items
 * @param {function} opts.itemTemplate - returns HTML/qc for dropdown items when you want the input to display a different value
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 * @param {boolean} opts.openOnType - default true
 * @param {boolean} opts.selectOnTab - when there's a match and the user tabs out, it will select that item

 *
 * @returns {qc}
 */
const combo7 = opts => {
  if (!opts.view) throw 'combo7 requires opts.view'
  opts.openOnType = opts.openOnType ?? true

  if (opts.display && ow.dev) throw 'combo7 does not support opts.display, use template instead'
  if (opts.dropdownTemplate && ow.dev)
    throw 'combo7 does not support opts.dropdownTemplate, use template or itemTemplate instead'

  function template(model, i) {
    if (opts.valueTextDisplay)
      return (
        (_v(model, opts.valueField ?? 'Value') ?? '') + ' - ' + _v(model, opts.textField ?? 'Text')
      )
    return _v(model, opts.textField ?? 'Text') ?? 'item-' + i
  }

  opts.template = opts.template ?? opts.display ?? opts.dropdownTemplate ?? template
  opts.itemTemplate =
    opts.itemTemplate ?? opts.dropdownTemplate ?? opts.display ?? opts.template ?? template

  const viewParent = opts.view.qTop.el.parentElement

  let open = false
  const closeDropdown = () => {
    open = false
    dd?.el?.parentElement && dd.el.remove()
  }
  const openDropdown = () => {
    open = true
    renderList()
  }

  opts.objectFieldName = opts.objectFieldName ?? '_' + (opts.fieldName ?? 'Value')
  opts.textField = opts.textField ?? 'Text'
  opts.valueField = opts.valueField ?? 'Value'

  let typedText = '',
    matchedOn
  let nominee, dd, selectedLi

  let list = opts.list ?? []

  const findMatch = () => {
    selectedLi = undefined

    if (typedText) {
      const s = typedText.toLowerCase()
      return s
        ? list.find(item => {
            let itemText = opts.itemTemplate(item)
            itemText = typeof itemText === 'string' ? itemText : _v(item, opts.textField)
            return (itemText?.toLowerCase?.() ?? '').indexOf(s) + 1 ?? true
          }) ?? list[0]
        : list[0]
    }

    const v = me.val()
    return v === undefined
      ? list?.[0]
      : list?.find(item => _v(item, opts.valueField) + '' === v + '') ??
          me.selectedItem() ??
          list?.[0]
  }

  const addSelectedItem = list => {
    const v = me.val()
    if (v === undefined || v === null) return list // no value

    if (!list.find(x => _v(x, opts.valueField) === v)) {
      const item = me.selectedItem()
      if (item) list.unshift(item)
    }
    return list
  }

  const loadingLine = { [opts.textField ?? 'Text']: __('Loading...') }
  const fetchList = () => {
    const s = typedText.toLowerCase()
    if (opts.list)
      return addSelectedItem(
        typedText && !opts.showAll
          ? opts.list.filter(item => {
              if (_v(item, opts.valueField ?? 'Value') === me.val()) return true
              let itemText = opts.itemTemplate(item)
              itemText =
                typeof itemText === 'string' ? itemText : _v(item, opts.textField ?? 'Text')
              return (itemText?.toLowerCase?.() ?? '').indexOf(s) + 1 ?? true
            })
          : opts.list
      )

    if (opts.url && matchedOn !== typedText) {
      const sub = typedText
      $ajax({ url: opts.url, data: { sub } })
        .then(res => {
          matchedOn = sub
          list = addSelectedItem(res.data || res)
          if (sub === '' && res.data && res.data?.length === res.total) {
            delete opts.url
            opts.list = list
          }
          renderList()
        })
        .catch(() => popError('lookup failed to load data.'))

      return [loadingLine]
    }

    return list
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    return (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
  }

  const renderList = async () => {
    const ddParent = viewParent

    if (dd)
      return open
        ? dd.el?.parentElement
          ? dd.renderAsync()
          : dd.renderTo(ddParent)
        : dd.el?.parentElement && dd.el.remove()

    dd = qc('ul.combo7.dropdown')

    list = fetchList()

    const buildItemLi = item =>
      qc('li.item', opts.itemTemplate(item))
        .bindState(
          () => nominee,
          (v, li) => {
            if (item === loadingLine) return
            if (!nominee) nominee = findMatch()
            nominee === item ? li.addClass('ow-selected') : li.removeClass('ow-selected')
            if (nominee === item) {
              selectedLi = li
              scrollItemIntoView(selectedLi)
            }
          }
        )
        .on('click', () => {
          if (item === loadingLine) return
          me.select(item)
          typedText = ''
          nominee = item
          closeDropdown()
        })

    const buildList = () =>
      dd.kids(
        list.length ? list.map(buildItemLi) : qc('li', __('No matches')).css({ opacity: '0.5' })
      )

    makeDropdown7(dd, ddParent, () => $offset(me.wrap().el)).bindState(() =>
      dd.css({
        width:
          (opts.listWidth === undefined ? $offset(me.wrap().el).width : opts.listWidth || 300) +
          'px'
      })
    )

    dd.kids(buildList())
      .attr({ tabindex: '-1' })
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(
        () => opts.list,
        async () => {
          opts.list && (list = opts.list)
          buildList()
        }
      )
      .bindState(() => list, buildList)

    if (open) dd.renderTo(ddParent)
  }

  const me = input7(opts)
    .addClass('combo7')
    .props({
      select(item) {
        if (item === loadingLine) return

        if (!item) {
          _v(me.model, opts.objectFieldName, item)
          me.val(item)
          me.value('')
          return
        }

        if (opts.onSelectItem) if (opts.onSelectItem.call(me, item) === false) return false

        _v(me.model, opts.objectFieldName, item)
        me.val(_v(item, opts?.valueField ?? 'Value'))

        me.value(opts.template(item))
        me.trigger('ow-select', item)
        typedText = ''
        me.renderAsync()
      },

      selectedItem() {
        return _v(me.model, opts.objectFieldName)
      },

      val(v, model, populating = false) {
        const isOw4Read = v === undefined && model

        if (!isOw4Read && arguments.length > 0) {
          typedText = ''
          let item
          const prev = _v(me.model, opts.fieldName ?? 'Value')
          let hasChanged = v !== prev

          if (hasChanged) _v(me.model, opts.fieldName ?? 'Value', v)

          item = _v(me.model, opts.objectFieldName)
          if (item && _v(item, opts.valueField) !== v) item = undefined
          if ((v ?? undefined) !== undefined) item = findMatch()
          _v(me.model, opts.objectFieldName, item)

          me.value(item ? opts.template(item) : '')
          if (hasChanged && !populating) me.trigger('ow-change')

          me.renderAsync()
        }
        return _v(me.model, opts.fieldName ?? 'Value')
      },

      populate(model) {
        typedText = ''
        const v = _v(model, opts.fieldName ?? 'Value')
        let item = _v(model, opts.objectFieldName)

        if (v !== undefined && v !== null && (!item || _v(item, opts.valueField) === v)) {
          if (opts.list) item = opts.list.find(x => _v(x, opts.valueField) === v)

          if (!item) {
            console.warn('combo7', opts.objectFieldName, 'not found on model.  Creating dummy.')
            item = {
              dummy: true,
              [opts.valueField]: v,
              [opts.textField]: v + ''
            }
          }
          _v(model, opts.objectFieldName, item)
        }

        me.value(item ? opts.template(item) : '')
      },

      readField(model) {
        _v(model, opts.fieldName ?? 'Value', me.val())
        _v(model, opts.objectFieldName, me.selectedItem())
      },

      readFilter(filters) {
        let v = me.val()

        if (me.el.value === '' && (me.op === undefined || me.op === 'contains' || me.op === 'eq')) {
          delete me.op // use this not isSet
          return
        }
        me.op ??= opts.op

        let filter = {
          field: opts.fieldName,
          operator: me.op ?? 'eq',
          value: v
        }

        if (opts.textFilterField && filter.value) {
          filter.field = opts.textFilterField
          filter.operator = me.op ?? 'contains'
          filter.value = me.el.value ?? null
        }

        if (opts.filterMap && filter.value) {
          const s = filter.value.toLowerCase()
          filter.operator = me.op ?? 'contains'
          const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

          filter.value = Object.keys(opts.filterMap)
            .filter(matchFilter)
            .map(k => opts.filterMap[k])

          if (filter.value.length === 0) {
            ow.popInvalid(__('Invalid value for ' + filter.field))
            me.value('')
            return
          }
          filter.operator = 'in'
        }

        filters.push(filter)
      }
    })
    .on('init', (e, el) => {
      // for use with ow4
      // el.val = me.val
      // el.populate = (...args) => me.populate(...args)
      el.readFilter = (...args) => me.readFilter(...args)
    })
    .props({ model: opts.model ?? {} })
    .on('keydown', e => {
      // tab
      if (e.which === 9) {
        if (
          me.val() === undefined &&
          nominee &&
          open &&
          (opts.selectOnTab || opts.required || opts.validation?.required)
        ) {
          me.select(nominee)
          typedText = ''
        }
        return
      }

      // enter
      if (e.which === 13 && open) {
        me.select(nominee)
        typedText = ''
        closeDropdown()
        return
      }

      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        if (open) closeDropdown()
        else openDropdown()
        return
      }

      // escape
      if (e.which === 27) {
        closeDropdown()
        return
      }

      // downarrow
      if (e.which === 40) {
        if (!open) return
        nominee = list[Math.min(list.length - 1, list.indexOf(nominee) + 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        if (!open) return
        nominee = list[Math.max(0, list.indexOf(nominee) - 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })
    .on('input', async () => {
      typedText = me.el.value

      if (me.el.value === '' && me.selectedItem()) me.select(opts.blankValue)

      const opening = open === false
      if (opening && opts.openOnType) openDropdown()

      if (typedText !== matchedOn) {
        list = await fetchList()
        nominee = findMatch()
        return renderList()
      }
    })
    .bindState(
      () => open,
      () => open && (dd ?? renderList())
    )
    .bindState(
      () => opts.list,
      v => (list = v) && (dd ?? renderList())
    )

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  const _w = me.wrap()

  _w.addClass('combo7 text-icon-after').on('focusout', e => {
    if (e.target !== me.el) return
    setTimeout(() => {
      if (hasFocus()) return
      if (typedText && nominee && nominee !== me.selectedItem() && nominee !== loadingLine)
        me.select(nominee)
      closeDropdown()
    }, 100)
  })

  if (!opts.popUp)
    _w.kids([
      me,
      icon('angleDown')
        .addClass('combo-icon')
        .bindState(
          () => me.disabled,
          (v, icon) =>
            icon.css({ cursor: !v ? 'pointer' : undefined, color: v ? '#ddd' : undefined })
        )
        .on('click', e => {
          if (me.disabled) return
          if (open) closeDropdown()
          else openDropdown()
          me.el.focus()
          renderList()
          return killEvent(e, true)
        })
    ])

  return me
}

module.exports = { combo7 }

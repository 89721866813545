// wireUpView7.js
const { collectionController7 } = require('./collectionController7')
const { shortcutKeyHandler, buttonOpts } = require('./button7')
const { dc7 } = require('./dc7')
const { grid7 } = require('./grid7')
const { killEvent } = require('../killEvent')
const { tabstrip7 } = require('./tabstrip7')
const { hasChanges } = require('./data-model7')
const { checktreelist7 } = require('./checktreelist7')
const { scheduler7 } = require('./scheduler7/scheduler7')
const { headerBar7 } = require('./headerBar7')
const { footerBar7 } = require('./footerBar7')
const { weekdays7, multiSelect7 } = require('./multiSelect7')
const { currentStore7 } = require('./currentStore7')
const { combo7 } = require('./combo7')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { wait } = require('../wait')
const { picturebox7 } = require('./picturebox7')

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @param {object} view
 * @returns
 */
const wireUpView7 = (view = { ow: ow0 }, viewType = 'view') => {
  view.ow = view.ow ?? ow0
  view.viewType = viewType

  const controls = {
    combo7(opts) {
      opts.view = view
      return combo7(opts)
    },

    currentStore7(opts) {
      opts.view = view
      return currentStore7(opts)
    },

    multiSelect7(opts) {
      opts.view = view
      return multiSelect7(opts)
    },

    weekdays7(opts) {
      opts.view = view
      return weekdays7(opts)
    },

    button(opts) {
      return result.button7({
        ...buttonOpts(typeof opts === 'string' ? opts : opts.type ?? opts.name),
        ...(typeof opts === 'string' ? {} : opts),
        view
      })
    },

    footerBar7(opts, dsName, view = result.view) {
      return footerBar7(opts, dsName, view)
    },

    headerBar7(opts, dsName, view = result.view) {
      return headerBar7(opts, dsName, view)
    },

    tabstrip7,

    tabstrip: tabstrip7,

    scheduler7(opts) {
      return scheduler7({ view, ...opts })
    },

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = opts.LRTask ?? true
      return $ajax(opts)
    },

    grid7(opts) {
      opts.view = view
      return grid7(opts)
    },

    collectionController7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return collectionController7(opts)
    },

    dc7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return dc7(opts)
    },

    // styles(scope = view.qTop.el?.id ? '#' + view.qTop.el.id : undefined) {
    //   return styles7(scope)
    // },

    checktreelist7,

    picturebox7,

    addStyle(stylesheet) {
      return qc('style', html(stylesheet)).renderTo(view.viewWrap.el)
    }
  }

  const result = {
    view,
    common,
    ...common.ow7,
    ...common.cmp,
    cmp: common.cmp,
    ...controls,
    controls
  }

  view.afterInit = () => {
    const { viewWrap, qTop } = view

    if (view.viewdata && viewType === 'overview') view.viewdata.formCon = () => {} // prevent old overviewCon

    if (view.viewdata && viewType !== 'overview') {
      view.viewdata.formCon = () => {} // prevent old editorCon and gridCon

      view.viewdata.requestClose =
        view.viewdata.requestClose ??
        function () {
          if (qTop.dc) view.model = qTop.dc.opts?.rec ?? qTop.dc.recs // view or grid

          if (!view.model) return true
          if (!hasChanges(view.model)) return true

          ow0
            .confirm(view.viewdata.name.split('-')[0], __('ConfirmDiscardChangesCloseForm'))
            .then(r => r && qTop.closeForm(true))
          return false
        }
    }

    qTop.on('keydown', shortcutKeyHandler(view))

    // prevent filter change propagating out from the view
    // especially from filters
    qTop.addClass('css7')

    viewWrap
      .on('click change ow-change', () => wait(50).then(() => viewWrap.renderAsync()))
      .on('change ow-change', e => killEvent(e, false))

    // result.styles().renderTo(viewWrap.el)
  }
  return result
}

exports.wireUpView7 = wireUpView7

exports.wireUpOverview7 = (...args) => wireUpView7(...args, 'overview')

exports.wireUpGrid7 = (...args) => wireUpView7(...args, 'grid')

const { html } = require('../cmp/html')
const theme = require('../theme')
const { iconBlue } = require('../theme')

const headerCellTag = 'td'

const grid7Css = (scope = '') =>
  html(
    `<style>
${scope} .grid7 tr:focus-visible { outline: 0; }

${scope} .grid7 .ow-grid-header .ow-filterrow { display: none; }
${scope} .grid7.ow-has-filterrow .ow-grid-header .ow-filterrow {display: table-row;}
${scope} .grid7.ow-hide-filterrow .ow-grid-header .ow-filterrow {display: none;}
${scope} .grid7 .ow-grid-header .ow-grid-top-right i {display: none;}
${scope} .grid7 .ow-grid-header .ow-grid-top-right {position: absolute;right: 0;top: 0;height: 3em;display: inline-block;width: 2em;background-color: inherit;z-index: 2;text-align: right;overflow: visible;}
${scope} .grid7.ow-has-filterrow .ow-grid-header .ow-grid-top-right i {display: inline-block; }
     
${scope} .grid7 tr.row.ow-alt { background-color: ${theme.rowAltColor} }
${scope} .grid7 tr.colgroup ${headerCellTag} { line-height: 0; }
${scope} .grid7 tr.row.ow-group-header,
${scope} .grid7 tr.row.ow-group-footer {
  background-color: ${theme.grayBackgroundColor};
}
.tab_content > .grid7.fit,
.tab_content > div > .grid7.fit,
${scope} .tab_content > div.fit { margin: 1rem; }

.grid-widget > .grid7 { margin-bottom: 1rem; }

.tab_content div.grid-widget > .grid7 {
  margin-left: 0;
  margin-right: 0;
}

.grid7 .ow-btn {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

${scope} .grid7 .ow-grid-header { 
  border-bottom: ${theme.borderWidth} solid;
}

${scope} .grid7 .ow-grid-header ${headerCellTag} {
  border-color: #dedee0;
  border-style: solid;
  overflow: hidden;
  white-space: normal;
  vertical-align: top;
  text-overflow: ellipsis;
}

${scope} .grid7 .ow-grid-header .ow-filterrow {
  border-top: ${theme.borderWidth} solid #dedee0;
}

${scope} .grid7 td {
  border-color: #dedee0;
  border-style: solid;
  border-width: 0 0 ${theme.borderWidth} ${theme.borderWidth};
  overflow: hidden;
  white-space: normal;
  vertical-align: top;
  text-overflow: ellipsis;
}
${scope} .grid7 .ow-btn.ow-grid-btn {
  padding-right: 0;
}

${scope} .grid7 td.ow-state-focused {
  -webkit-box-shadow: inset 0 0 0.15em 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0.15em 0 rgba(0, 0, 0, 0.25);
}

${scope} .grid7 > div.ow-grid-footer,
${scope} .grid7 > div.ow-grid-header {
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
  padding-right: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
${scope} .grid7 div.ow-grid-header-wrap {
  border-right: 0;
}
${scope} .grid7 .add-row-on-focus {
  height: 0;
  width: 0;
  border: 0;
}
${scope} .grid7 .ow-titlerow,
${scope} .grid7 tr.ow-filterrow {
  min-height: 1.5rem !important;
}

${scope} .grid7 .ow-titlerow ${headerCellTag} {
  border-bottom-color: transparent;
}

${scope} .grid7 { border: ${theme.borderWidth} solid #dedee0; }
${scope} .grid7 tr.row td.non-editable-cell {
  background-color: ${theme.grayBackgroundColor};
}

${scope} .grid7 tr.row,
${scope} .grid7 td,
${scope} .grid7 tr.ow-filterrow ${headerCellTag} {
  padding: 0;
}
${scope} .grid7 td.gridcell.non-editable-cell.ow-current-cell {
  border: ${theme.borderWidth} solid rgb(77, 144, 254);
}
${scope} .grid7 td > span.read-only {
  padding: 0 4px;
}
${scope} .grid7 .ow-filterrow input,
${scope} .grid7 td input {
  width: 100%;
  border: 0px;
  height: 26px;
  max-width: 98%;
  padding: 0 4px 0 0;
  color: #555;
}

${scope} .grid7 .ow-grid-content td span.ow-ctl-wrap,
${scope} .grid7 .ow-grid-content td span.ow-ctl-wrap.text-icon-after {
  display: block;
  margin: 0 0;
  width: 100%;
}
${scope} .grid7 td span.ow-ctl-wrap.text-icon-after > input {
  width: calc(100% - 2rem);
}
${scope} .grid7 td span.ow-ctl-wrap.text-icon-after.text-2icon-after > input {
  width: calc(100% - 3rem);
}

${scope} .grid7 td.boolean-col {
  text-align: center;
}
${scope} .grid7 table {
  margin: 0;
  min-height: 3px; /* keeps horizontal scroll bar when no records  */
  max-width: none;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: 0;
}

${scope} .grid7 td.ow-footer.float-col,
${scope} .grid7 td.ow-footer.currency-col,
${scope} .grid7 td.ow-footer.number-col,
${scope} .grid7 td.ow-footer.int-col,
${scope} .grid7 td.number-col.non-editable-cell {
  text-align: right;
}
${scope} .grid7 {
  overflow-y: hidden;
  overflow-x: auto;
}
${scope} .grid7 > .ow-grid-header .ow-filterrow ${headerCellTag} {
  text-align: left;
}
${scope} .grid7 > .ow-grid-header .ow-titlerow ${headerCellTag} {
  position: relative;
}
${scope} .grid7 > .ow-grid-header ${headerCellTag} .check-all-none {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  text-decoration: none;
  padding: 0;
}
${scope} .grid7 > .ow-grid-header ${headerCellTag} .check-all-none::before {
  border: 0px;
  text-decoration: none;
}

${scope} .grid7 { position: relative; }

${scope} .grid7 a.col-sort::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0.4em;
}

${scope} .grid7.indicate-current-row tbody tr.ow-current-row > td,
${scope} .grid7.indicate-current-cell tbody td.ow-current-cell {
  background-color: ${theme.currentRowBackgroundColor};
}

${scope} .grid7 tbody tr.ow-selected > td {
  background-color: ${theme.selectedRowBackgroundColor};
}

${scope} .grid7 td > span {
  width: inherit;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

${scope} .grid7 .ow-grid-content td > span {
  height: inherit;
  box-sizing: border-box;
  white-space: nowrap;
}

${scope} .grid7.non-editable .ow-grid-content td > span {
  padding: 0 3px;
}

i.icon.text-item-icon { color: ${iconBlue}; }
${scope} .grid7 .ow-grid-btn-delete-row i.icon {
  color: ${theme.iconRed};
}
${scope} .grid7 .ow-grid-btn {
  border: 0px;
}

${scope} .grid7 input.int,
${scope} .grid7 input.float,
${scope} .grid7 input.number,
${scope} .grid7 input.currency {
  text-align: right;
}
${scope} .grid7 input.int.combo { text-align: left; }

${scope} .grid7 tr td:first-child > span {
  box-sizing: border-box;
  border-left: 0.33em transparent solid;
}
${scope} .grid7.ow-mark-changes tr.ow-dirty td:first-child > span,
${scope} .grid7.ow-mark-changes tr.ow-deleted-row td:first-child > span {
  border-left-color: rgb(77, 144, 254);
}
${scope} .grid7 tr.ow-selected td:first-child > span {
  border-left-color: blue;
}
${scope} .grid7 tr.ow-deleted-row td > * {
  opacity: 0.5;
}
${scope} .grid7 tr.ow-deleted-row * {
  text-decoration: line-through;
}
${scope} .grid7 tr.row {
  background-color: inherit;
  transition: background-color 0.5s, border-color 0.5s;
}
${scope} .grid7 tr.row.ow-row-invalid td {
  background-color: pink;
  transition: background-color 1s;
}

${scope} .grid7 tr.row.ow-row-invalid td input {
  background-color: pink;
  transition: background-color 1s;
}

${scope} .grid7.no-footer > div.ow-grid-footer {
  display: none;
  height: 0;
}

${scope} .grid7 td .ow-ctl-wrap,
${scope} .grid7 ${headerCellTag} .ow-ctl-wrap {
  text-indent: 0;
  padding-top: 0;
  padding-left: 0;
}

${scope} .grid7 .ow-grid-content td .ow-textbox {
  border: transparent;
}

${scope} .grid7 .resize-col-handle {
  width: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
}
${scope} .grid7.resizable-columns .resize-col-handle {
  cursor: ew-resize;
  width: 0.4em;
}
${scope} .grid7 .ow-header { border-color: ${theme.textboxBorderColor}; }

${scope} .grid7 td.ow-footer { border-top: 0; border-bottom: 0; }

${scope} .grid7 .ow-header ${headerCellTag} {
  padding-left: 0;
  padding-right: 0;
}
${scope} .grid7 .ow-header ${headerCellTag} > span {
  display: inline-block;
  padding: 0 0.22em;
}
${scope} .grid7 .int-col > span.read-only {
  text-align: right;
}

${scope} .grid7 .ow-grid-content {
  background: #fff;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  zoom: 1;
  min-height: 0;
}
${scope} .grid7 tr.row .ow-ctl-wrap.ow-textbox {
  border-radius: 0;
  border-color: transparent;
}

${scope} .grid7.grid7 tr.row .ow-ctl-wrap.ow-textbox { background: transparent; }
${scope} .grid7.grid7 tr.row .ow-ctl-wrap.ow-textbox:focus-within { background: #fff; opacity: 0.85 }

${scope} .grid7 tr.row span.ow-ctl-wrap.input7.k-input-errbg,
${scope} .grid7 tr.row span.ow-ctl-wrap.input7.k-input-errbg:focus-within { background: ${theme.invalidCellBackgroundColor}; }

${scope} .grid7 tr {
  font-weight: normal;
  line-height: 24px;
}

${scope} .grid7 ${headerCellTag} > span, 
${scope} .grid7 td > span {
  height: inherit;
}

${scope} .grid7.non-editable tr.row {
  cursor: pointer;
}

${scope} .grid7 .ow-grid-header tr > * > span {
  padding: 3px;
  display: block;
  box-sizing: border-box;
}

${scope} .grid7 .ow-grid-header tr.ow-titlerow > * > span {
  line-height: 1rem;
  padding: 6px 3px;
}

${scope} .grid7 .ow-grid-header tr.ow-filterrow > * > span {
  line-height: inherit;
}

${scope} .grid7 .ow-grid-header tr.ow-filterrow .filter-icon {
  padding-left: 0.25rem;
  cursor: pointer;
}
${scope} .grid7 .ow-grid-header tr.ow-filterrow .ow-ctl-wrap.ow-textbox {
  border-color: ${theme.textboxBorderColor};
  outline: 0;
  margin: 0;
  box-sizing: border-box;
  width: calc(100% - 1rem);
}

${scope} .grid7 td.currency-col input,
${scope} .grid7 td.float-col input,
${scope} .grid7 td.number-col input,
${scope} .grid7 td.int-col input {
  text-align: right;
  box-sizing: border-box;
}
${scope} .grid7 td.int-col input.combo7 { text-align: left; }
/*

Nice idea but this causes the clicked caret position to be lost (or never gained)

${scope} .grid7 td.currency-col input:focus,
${scope} .grid7 td.float-col input:focus,
${scope} .grid7 td.number-col input:focus,
${scope} .grid7 td.int-col input:focus {
   text-align: left;
}
 */
${scope} a.check7 { padding: 0; }

</style>
`
  )

module.exports = { grid7Css }

const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { killEvent } = require('../killEvent')
const theme = require('../theme')
const { dataBind } = require('./databind7')
const { rs } = require('./rs7')

/**
 * A databound checkbox
 *
 * @param {object} opts
 * @param {bool} opts.disabled
 * @param {bool} opts.value - sets the initial value
 * @param {string} opts.fieldName
 * @param {string} opts.dsName dataSource name.
 * @param {object} opts.model
 * @param {string} opts.label - please translate first
 * @param {bool} opts.labelRight - displays the label to the right of the check rather than to the left
 *
 * @returns {qc} qc of the anchor element. Use its .wrap() or .rs() for adding to the DOM
 */
const check7 = opts => {
  const me = qc(
    'a.check7',
    icon('check')
      .css({
        display: 'inline',
        padding: '2px',
        borderRadius: '4px',
        backgroundColor: theme.backgroundColor,
        border: '1px solid ' + theme.textboxBorderColor
      })
      .bindState(
        () => me.hasClass('on'),
        (v, i) => i.css({ color: v ? theme.textColor : 'transparent' })
      )
  )
    .css({ lineHeight: '1.5rem' })

    .props({
      val(...args) {
        const [v, , populating] = args
        if (args.length) {
          var changed = v != me.hasClass('on')
          v ? me.addClass('on') : me.removeClass('on')
          if (changed && !populating) me.trigger('ow-change')
        }
        return me.hasClass('on')
      },

      readFilter(filters) {
        if (me.val()) {
          var filter = {
            field: opts.fieldName,
            operator: opts.op ?? 'eq',
            value: opts.filterValue ?? me.val()
          }
          filters.push(filter)
        }
      },

      opts
    })
    .attr({ href: '#' })
    .on('init', (e, el) => (el.opts = opts))
    .on('click', () => {
      if (me.disabled) return
      const prev = me.val()
      me.val(!prev)
      me.el.focus()
    })
    .on('keypress', e => {
      if (e.which === 32) {
        me.el.click()
        return killEvent(e)
      }
    })

  me.qCheck = true

  const _w = qc(
    'span.ow-ctl-wrap.ow-check-wrap',
    opts.labelRight
      ? [
          me,
          qc('label', opts.label)
            .css({ marginLeft: '4px' })
            .on('click', () => {
              if (me.disabled) return
              me.el.focus()
              me.el.click()
            })
        ]
      : [me]
  )
    .css({ userSelect: 'none' })
    .props({ input: me })

  me.wrap = () => _w

  dataBind(me)
  if (opts.model) me.populate(opts.model)

  if (opts.value !== undefined) me.val(opts.value)

  const _rs = rs({ label: opts.labelRight ? '' : opts.label ?? '' }, _w)

  me.rs = () => opts.rs ?? _rs

  if (opts.disabled) me.disable(true)

  return me
}

module.exports = { check7 }

const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { dataControls, populateControls } = require('./databind7')
const { initModel, $meta, hasChanges, cancelChanges, react } = require('./data-model7')
const { html } = require('../cmp/html')

const validateEditors = function (qTop, dsName, messageArray, onInvalid) {
  messageArray = messageArray || []

  var result = true

  var defaultInvalid = function (display, err, el) {
    result = false
    console.log(display + ' invalid: ' + err)
    messageArray.push(display + ' invalid: ' + err)
    qc(el).displayValidity?.(false, err)

    // if (el.opts.edType == 'password') passwordChecklist.push(r)
    // if (el.opts.edType == 'grid') gridFieldsChecklist.push(r)
  }

  onInvalid = onInvalid || defaultInvalid

  var passwordChecklist = []
  var gridFieldsChecklist = [] //for multiple grids

  qTop
    .find("[data-field-for='" + dsName + "']")
    .forEach(el => (qc(el).validate ?? el.validate)?.(onInvalid, messageArray))

  //process password
  var pwdGroups = passwordChecklist.reduce(function (obj, item) {
    obj[item.group] = obj[item.group] || []
    obj[item.group].push({ value: item.value, target: item.target })
    return obj
  }, {})
  for (var rec in pwdGroups) {
    if (rec in pwdGroups && Array.isArray(pwdGroups[rec])) {
      var pwdCompare = null
      var matched = true
      pwdGroups[rec].forEach(function (l) {
        if (pwdCompare === null) pwdCompare = l.value
        else {
          if (l.value !== pwdCompare) {
            matched = false
          }
        }
      })
      if (!matched) {
        pwdGroups[rec].map(function (item) {
          var el = item.target
          if (el.makeMeInvalid) el.makeMeInvalid(onInvalid, messageArray)
          qc(el)?.displayValidity(false, __('Password and Confirm Password mismatch'))
        })
        result = false
      }
    }
  }

  //process grids validation
  gridFieldsChecklist.map(f => {
    if (!f.resVal) {
      ;(f.resErr || []).map(err => messageArray.push(err))
      result = false
    }
  })

  if ((messageArray || []).length) {
    ow.popInvalid(html((messageArray || []).join('<br>')))
    qTop.find('.k-input-errbg input')[0]?.focus()
  }
  return result
}

// form-data controller
// population and form flow
module.exports.dc7 = opts => {
  if (!opts.view) throw 'dc7 requires the view object'
  const { view } = opts
  const { qTop } = view

  opts.rec = opts.rec ?? {}

  const me = Object.create({
    rec() {
      return opts.rec
    },

    fieldReact(f) {
      return react(opts.rec, f) // fieldReact(opts.rec, f, opts.callIfChanged, view)
    },

    react(f) {
      return react(opts.rec, f) // recordReact(opts.rec, f, opts.callIfChanged, view)
    },

    newRec() {
      // override this for default values
      return this.opts.newRec?.() ?? {}
    },

    myId(r, alwaysReturnObj) {
      if (opts.idFields.length === 1) {
        if (alwaysReturnObj) {
          const res = {}
          res[opts.idField] = r && _v(r, opts.idField)
          return res
        } else return r && _v(r, opts.idField)
      }
      const ids = {}
      opts.idFields.forEach(x => (ids[x] = _v(r, x)))
      return ids
    },

    idString(r) {
      var id = me.myId(r)
      return typeof id === 'object' ? JSON.stringify(id) : id
    },

    hasChanges() {
      if (opts.readOnly) return false
      return hasChanges(opts.rec)
    },

    edChanged() {
      if (!me.populating()) opts.view.renderAsync()
    },

    validateData(messageArray, onInvalid) {
      // todo: - do we really want to do this control based validation?
      return validateEditors(opts.view.qTop, me.dsName, messageArray, onInvalid)
    },

    // load the main record - request from server and then populate the controls
    async load(id) {
      if (typeof id === 'object') id = JSON.stringify(id)

      var data = {}
      var url = (typeof opts.url === 'function' ? opts.url() : opts.url) || opts.baseURL

      url = url.split('?')
      url[0] = url[0] + (typeof id !== 'undefined' ? '/' + encodeURIComponent(id) : '')
      url = url.join('?')

      return $ajax({ view: opts.view, showProgress: true, LRTask: opts.LRTask ?? true, url, data })
        .then(response => me.populate(response))
        .catch(err => ow.popError(__('load returned error ') + (err?.errMsg ?? err)))
    },

    // extracts the data in an independent instance for saving.
    // todo: generalize for other component types, subEds etc.
    readData() {
      const orig = me.rec()

      // detach the child datasets
      var kids = {}
      dataControls(me.dsName, opts.view.qTop.el)
        .filter(el => qc(el).hasClass('ow-grid'))
        .forEach(g => {
          var f = qc(g).opts?.fieldName ?? g.opts?.fieldName
          if (f in kids) return // in case there are 2, just do the first
          kids[f] = orig[f] // store for later
          delete orig[f]
        })

      const result = JSON.parse(JSON.stringify(orig, true))

      // attach to result
      dataControls(me.dsName, opts.view.qTop.el)
        .filter(el => qc(el).hasClass('ow-grid'))
        .forEach(g => {
          var f = g.opts.fieldName
          if (f in result) return
          orig[f] = kids[f]
          _v(
            result,
            f,
            qc(g).dc.recs.filter(r => !$meta(r).deleted)
          ) // use the extracted instance from the grid.
        })

      return result
    },

    async save() {
      var qTop = opts.view.qTop
      const { viewdata } = opts.view

      if (!me.validateData()) return false

      var result = me.readData()
      if (opts.preSave?.(result) === false) return false

      $meta(result, {})

      // if this is a nested edit
      if (viewdata.record) {
        viewdata.result = result
        qTop.closeForm(true)
        return true
      }

      const url = (typeof opts.url === 'function' ? opts.url() : opts.url) || opts.baseURL

      var idField = opts.idField
      var isNew = $meta(opts.rec).new

      if (!(!isNew || !result[idField] || result[idField] === -1) && opts.checkUniqueID !== false) {
        // check for ID match
        const response = await $ajax({
          view: opts.view,
          showProgress: true,
          LRTask: opts.LRTask ?? true,
          url: url.split('?')[0] + '/' + me.idString(result)
        })

        if (response) {
          const inputField = qTop.find('[data-field="' + idField + '"')[0]
          if (!inputField) return
          const errMsg = __('ID already in use.')
          console.error(errMsg)
          ow.displayValidity(inputField, false, errMsg)
          ow.popInvalid(errMsg)
          inputField.focus()
          return false
        }
      }

      return $ajax({
        view: opts.view,
        showProgress: true,
        LRTask: opts.LRTask ?? true,
        type: isNew ? 'POST' : 'PUT',
        url:
          url.split('?')[0] +
          (!isNew ? '/' + me.idString(result) : '') +
          (url.split('?')[1] ? '?' + url.split('?')[1] : ''),
        data: result
      })
        .then(response => {
          ow.popSaveOK(response)
          // console.warn('// todo: reload with data returned from the server.');
          me.populate(result)

          if (opts.closeAfterSave !== false) {
            opts.view.viewdata.result = result
            qTop.closeForm(true)
          }
          return true
        })
        .catch(err => ow.popSaveError(err))
    },

    async delete() {
      if (!(await ow.confirm(opts.displayName, __('Are you sure you want to delete this record?'))))
        return

      return $ajax({
        view: opts.view,
        showProgress: true,
        LRTask: opts.LRTask ?? true,
        type: 'DELETE',
        url: opts.baseURL + '/' + me.idString(opts.rec)
      })
        .then(response => {
          ow.popDeleteOK(response)
          var r = me.newRec()
          r.Name = ''
          me.populate(r)
        })
        .catch(err => ow.popDeleteError(err))
    },

    copy(id) {
      if (typeof id === 'object') id = JSON.stringify(id)

      $ajax({
        view: opts.view,
        showProgress: true,
        LRTask: opts.LRTask ?? true,
        url: opts.baseURL + '/' + id
      })
        .then(response => {
          var r = me.newRec()
          Object.assign(response, me.myId(r, true))
          me.populate(response)
        })
        .catch(err => ow.alert('load returned error ' + err?.errMsg ?? err))
    },

    cancel() {
      cancelChanges(opts.rec)
      var result = me.populate(opts.rec)
      console.log(result)
    },

    async new() {
      return hasChanges(opts.rec) &&
        !(await ow.confirm(
          opts.displayName,
          __('Are you sure you want to discard changes and reset form?')
        ))
        ? false
        : me.populate(me.newRec())
    },

    // todo: move this to the winCon
    // Applies standard view form loading behaviour based on viewdata.
    async loadInitial(viewdata) {
      if (!viewdata.mode) viewdata.mode = viewdata.record || viewdata.id ? 'edit' : 'new'

      if (viewdata.mode === 'new') {
        const r = me.newRec()
        if (opts.uid)
          r[opts.idField] = (
            await common.$put({
              url: '/nextUids',
              data: { dbType: opts.uid === true ? 'sagapos' : opts.uid, count: 1 }
            })
          ).uids[0]

        me.populate(r)
        if ($meta(opts.rec)) $meta(opts.rec).new = true
        return
      }

      return viewdata.record
        ? me.populate(viewdata.record)
        : viewdata.id !== undefined
          ? me.load(viewdata.id)
          : undefined
    },

    populate(r) {
      const { qTop } = opts.view

      dataControls(me.dsName, qTop.el).forEach(el => {
        const f = el.opts?.fieldName
        if (f) opts.schema[f] = opts.schema[f] ?? {}
      })

      r = opts.prePopulate?.(r) ?? r // don't assume prePopulate will return something everytime but use result if it does

      opts.rec = r
      initModel(r, opts.dsName, opts.schema).view = opts.view
      me.populating(true)
      me.updateControls()

      me.populating(false)

      return r
    },

    restore(r) {
      r = opts.prePopulate?.(r) ?? r
      opts.rec = r

      //update Meta
      for (var p in r) {
        if (opts.schema?.[p]?.ignore !== true)
          if ((r[p] || {}).toISOString) {
            // restore dates to Date Object
            $meta(opts.rec).orig[p] = r[p]
            delete $meta(opts.rec).prev[p]
          }
      }
      me.populating(true)
      me.updateControls()
      me.populating(false)
    },

    updateControls() {
      const rec = opts.rec
      me.populating(TextTrackCue)
      populateControls(opts.dsName, rec, opts.view.qTop.el)
      me.populating(false)
      opts.view.renderAsync()
    }
  })
  me.opts = opts
  me.dsName = opts.dsName

  qTop.dataSources = qTop.dataSources || {}
  qTop.dataSources[me.dsName] = me

  opts.el = opts.el || qTop.el

  opts.schema = opts.schema || {}

  opts.idFields = Array.isArray(opts.idField) ? opts.idField : [opts.idField]
  opts.idField = opts.idFields[0]

  // flag to prevent ow-data-changed events firing while populating
  var populating = 0

  // access for overriding
  me.populating = function (v) {
    if (v !== undefined) v ? populating++ : populating--
    return populating
  }

  return me
}

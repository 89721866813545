// fileUpload7.js
const { $param } = require('../ajax')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { iconCodes } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { rs } = require('./rs7')
const { input7 } = require('./text7')

module.exports.fileUpload7 = opts => {
  opts.ctlType = 'fileUpload7'
  opts.edType = 'fileUpload7'

  const me = input7(opts)
    .addClass('file-upload fileupload7')
    .attr({ 'data-role': 'upload', type: 'file', accept: opts.accept || '.zip' })

  me.opts = opts
  dataBind(me)
  // applyAttrsAndClasses(me)

  let file
  let progress = 0

  const qProgressBar = qc('div.progress-bar').attr({
    role: 'progressbar',
    'aria-valuemin': '0',
    'aria-valuemax': '100'
  })
  const qProgressDiv = qc('div.progress', qProgressBar)

  // const id = opts.id || 'fu' + new Date().valueOf()
  let label = __('Select a file') + '...'

  const w = me
    .css({ opacity: '0' })
    .wrap()
    .kids([
      qc('label.input-file-trigger', label)
        .attr({ tabindex: '0' })
        .css({
          overflow: 'visible',
          width: '0',
          display: 'inline-block',
          lineHeight: 'inherit',
          height: '0',
          padding: '1px',
          paddingLeft: '3px'
        })
        .bindState(
          () => me.el?.value || label,
          function (v) {
            this.kids([v])
          }
        ),

      qc('i.fa.text-item-icon.icon', html(iconCodes['file-o'])).css({
        cursor: 'pointer',
        height: '0',
        overflow: 'visible',
        width: '1.5rem',
        boxSizing: 'border-box',
        textAlign: 'center',
        marginLeft: 'calc(100% - 1.7rem)'
      }),

      me.css({
        opacity: 0,
        display: 'block',
        marginTop: '-1.5rem',
        width: '100%',
        maxWidth: '100%'
      }),

      qProgressDiv
    ])
    .addClass('ow-textbox text-icon-after ow-fileupload-wrap') // ow5 version
    .css({ height: '26px' })
    .props({ input: me, rs: () => me.rs() })

  me.on('change', () => w.renderAsync())

  // progress state
  me.bindState(
    () => progress,
    v => {
      me.css({ float: v ? 'left' : 'unset' })
      qProgressDiv.css({
        width: v ? '18%' : '0%',
        float: v ? 'right' : 'unset',
        display: v ? undefined : 'none'
      })
      qProgressBar.css({ width: v + '%' }).kids([v + '%'])
    }
  )

  const onSubmit = function () {
    const data = new FormData()
    data.append('saveAs', opts.saveAs ? opts.saveAs : file && file.name)
    if (opts.dirname) data.append('dirname', opts.dirname)
    if (opts.maxFileSize) data.append('maxFileSize', opts.maxFileSize)
    data.append('fileInput', file)
    if (window.csrfToken) data.append('x-csrf-token', window.csrfToken)

    function update_progressbar(value) {
      progress = value
      w.renderAsync()
    }

    var ajax = new XMLHttpRequest()
    ajax.upload.addEventListener(
      'progress',
      e => update_progressbar(Math.round((e.loaded / e.total) * 100)),
      false
    )

    ajax.addEventListener(
      'load',
      e => {
        if (e.target.responseText.toLowerCase().indexOf('error') >= 0) {
          ow0.popError(__('Error'), e.target.responseText, 5000)
          me.trigger('ow-file-uploaderror', e)
        } else me.trigger('ow-file-uploaded', e.target)

        update_progressbar(0)
        me.val('')
      },
      false
    )

    ajax.addEventListener(
      'error',
      e => {
        ow0.popError(__('Error'), __('Upload Failed'))
        me.trigger('ow-file-uploaderror', e)
        update_progressbar(0)
      },
      false
    )

    ajax.addEventListener(
      'abort',
      e => {
        ow0.popError(__('Error'), __('Upload Aborted'))
        me.trigger('ow-file-uploaderror', e)
        update_progressbar(0)
      },
      false
    )

    me.trigger('ow-file-uploadstart')
    ajax.open(
      window.usePUT ? 'PUT' : 'POST',
      '/data/fileupload?' +
        $param({
          ...(window.csrfToken ? { _csrf: window.csrfToken } : {}),
          ...(opts.dirname ? { dirname: opts.dirname } : {}),
          ...(opts.saveAs ? { saveas: opts.saveAs } : {})
        })
    )
    ajax.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    if (sessionStorage.getItem('sessionid'))
      ajax.setRequestHeader('sessionid', sessionStorage.getItem('sessionid'))

    ajax.send(data)
  }

  me.upload = function () {
    file = this[0].files[0]
    if (file && file.size / 1024 / 1024 > opts.maxFileSize)
      return ow0.popError(
        __('Error'),
        __('Filesize should not greater than ' + opts.maxFileSize + ' M'),
        5000
      )
    var reader = new FileReader()
    reader.onload = onSubmit
    reader.readAsDataURL(file)
  }

  me.saveAs = v => (opts.saveAs = v)

  const _rs = rs({ label: opts.label }, w).props({ input: me })

  me.rs = () => opts.rs ?? _rs

  return me
}

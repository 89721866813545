const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { isDate } = require('../js-types')
const { qCalendar } = require('../controls/q-calendar')
const { ctlFormatters, ctlParsers } = require('../ctl-format')
const dates = require('../dates')
const { sameDateValue } = require('./sameDateValue')

const date7 = opts => {
  opts.edType = 'date'
  const me = input7(opts)
  let open = false
  let dd
  let prevValue

  opts.edType = 'date' // for use in ow4

  const close = () => {
    open = false
    dd && dd.el.remove()
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    return (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
  }

  const renderCalendar = async () => {
    const win = me.el.closest('.win-con')
    const ddParent = win?.parentElement ?? document.body

    if (dd) return open ? dd.renderTo(ddParent) : dd.el?.parentElement && dd.el.remove()
    dd = qc('ul.calendar.dropdown')

    const cal = qCalendar(d => {
      me.val(d)
      me.trigger('ow-select', d)
      close()
      dd.renderAsync()
    }, me.val())

    dd.kids(cal)
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(() => {
        // set position again
        // vertical position
        // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
        const viewTop = no$(ddParent).offset().top
        const topFromBody = no$(me.wrap().el).offset().top + no$(me.wrap().el).offset().height
        const topFromView = topFromBody - viewTop

        const height = Math.max(no$(dd.el).offset().height, 210)
        const floatUp = topFromBody + height > document.body.scrollHeight

        dd.css({
          left: no$(me.wrap().el).offset().left - no$(ddParent).offset().left + 'px',
          top: floatUp
            ? topFromView - (no$(dd.el).offset().height + no$(me.wrap().el).offset().height) + 'px'
            : topFromView + 'px'
        })
      })

    if (open) dd.renderTo(ddParent)
  }

  opts.ctlFormatter = ctlFormatters.date
  opts.ctlParser = ctlParsers.date

  me.props({
    val(v, model, populating) {
      const isOw4Read = v === undefined && model

      if (!isOw4Read && arguments.length > 0) {
        const s = !v
          ? ''
          : ctlFormatters.date(isDate(v) ? v : new Date(v) ?? '', me.opts.format) ?? ''

        me.value(s)

        let hasChanged = false
        if (me.model && me.opts?.fieldName && !sameDateValue(_v(me.model, me.opts?.fieldName), v))
          hasChanged = true
        if (s !== me.value() && me.el !== document.activeElement) me.value(s)
        me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName, v)
        hasChanged && !populating && me.trigger('ow-change')
      }
      return me.value()
        ? ctlParsers.date(dates.resolveDate(me.value(), true), me.opts.format)
        : undefined
    },

    resolve() {
      if (me.attr('value')) {
        const s = dates.resolveDate(me.value(), true)
        if (me.value() !== s) me.value(s)
      }
    },

    defOp: 'eq'
  })
    .props({ model: opts.model ?? {} })
    .addClass('date7')
    .on('keydown', e => {
      // tab
      if (e.which === 9) {
        // to resolve on tab
      }

      // shift + enter
      if (e.which === 13 && open) {
        close()
        renderCalendar()
        return
      }
      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        open = !open
        renderCalendar()
        return
      }

      // escape
      if (e.which === 27) {
        open = false
        renderCalendar()
        return
      }

      // downarrow
      if (e.which === 40) {
        renderCalendar()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        renderCalendar()
        e.preventDefault()
        return false
      }
    })
    .on(
      'init',
      (e, el) =>
        (el.requestTabOut = function () {
          me.resolve()
          return true
        })
    )
    .on('input', () => renderCalendar())
    .on('keydown', () => (prevValue = me.el.value))
    .on('keyup', e => {
      var el = me.el
      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        var charDeleted = (prevValue || el.value)[el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }

      if (el.value !== prevValue) {
        var x = el.selectionEnd
        var caretAtEnd = x === el.value.length
        var v = dates.resolveDate(el.value, false, undefined, { position: el.selectionEnd })
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        if (el.value !== v) el.value = v
        prevValue = v
        if (caretAtEnd) return
        el.selectionStart = x
        el.selectionEnd = x
      }
    })
    .bindState(
      () => open,
      () => open && (dd ?? renderCalendar())
    )
    .bindState(
      () => me.val(),
      () => open && renderCalendar()
    )

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  me.wrap()
    .addClass('date7 text-icon-after ow-date-wrap')
    .on('focusout', e => {
      if (e.target !== me.el) return
      common.wait(100).then(() => {
        if (hasFocus()) return
        open = false
        renderCalendar()
      })
    })
    .kids([
      me,
      icon('calendar')
        .addClass('calendar-icon')
        .css({ textAlign: 'center' })
        .bindState(
          () => me.disabled,
          (v, me) =>
            me.css({
              cursor: !v ? 'pointer' : undefined,
              color: v ? '#ddd' : undefined
            })
        )
        .on('click', e => {
          if (me.disabled) return
          me.el.focus()
          open = !open
          me.el.focus()
          renderCalendar()
          me.renderAsync()
          return killEvent(e, false)
        })
    ])

  return me
}

module.exports = { date7 }

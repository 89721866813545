const { _v } = require('../_v')
const { qc } = require('../cmp/qc')
const { $find } = require('../no-jquery')
const { popError, popInvalid } = require('../pop-box')
const { viewWindow } = require('../viewWindow')
const { _id } = require('./core')
const { editorCon } = require('./editorCon')
const { gridCon } = require('./gridCon')
const { overviewCon } = require('./overviewCon')
const { viewPreprocessor } = require('./viewPreprocessor')

if (window.Element)
  Element.prototype.myWin =
    Element.prototype.myWin ??
    function () {
      const topEl = this.closest('.win-con')
      return topEl ? qc(topEl).$top : $(document.body)
    }

if (typeof $ !== 'undefined')
  $.fn.myWin = function () {
    return this.closest('.win-con')[0]?.myWin?.()
  }

const flashPermissionDenied = viewdata => {
  if (viewdata.funcid) saveAppEvent(viewdata.funcid, (viewdata.name ?? '') + ' [Permission Denied]')
  popError(__('Error'), __('readPermissionDenied'), 1000)
}

const windows = {
  // This is used from menu
  showWindow: (name, url, width, height, funcid, command, callback) =>
    windows.openView({
      name,
      url,
      width,
      height,
      useExisting: true,
      funcid,
      command,
      callback
    }),

  windowListMenu: () => (window.R2 ? window.rebuildShortcuts() : ow0.menu.rebuildShortcuts()),

  findOpenWindows: () =>
    $find('.win-con')
      .filter(el => el.id !== 'LoginFormContent')
      .map(w => qc(w).$top)
      .sort(($w1, $w2) =>
        $w1.viewdata.name.toLowerCase() > $w2.viewdata.name.toLowerCase() ? -1 : 1
      ),

  closeAllOpenWindows: () => windows.findOpenWindows().forEach(w => w.closeForm?.(true)),

  /**
   * Option object:
   * *****************************
   * * showWindow options object *
   * *****************************
   * url (string)                     --> This is the routes defined for rendering the window.
   *                                      e.g. By following the convention at above, defining url with '/view/hoschangelog',
   *                                      it will call the views template on '<rootDirecotry>/views/hoschangelog/hoschangelog-view'
   *                                      *** Please note, if url is empty string, it will show error message ***
   * name (string)                    --> The name/title for the window being called.
   * windowName (string)              --> Optional, by default it will follow the name's value from attribute at above.
   * workspace (string)               --> Optional. To be defined.
   * noWindow                         --> Optional. To be defined.
   * useExisting                      --> Optional. To be defined.
   * uid (string)                     --> Optional. Parent window's uid.
   * minWidth (number)                --> Optional. Minimum width for the window.
   * minHeight (number)               --> Optional. Minimum height for the window.
   * width (number)                   --> Optional. Width for the window.
   * height (number)                  --> Optional. Height for the window.
   * requestClose (callback function) --> Optional. Make sure the callback function return boolean value.
   *                                      In some case, we want to make sure user to proceed something only able to close the window.
   * preGridPref (boolean)            --> Optional. Customized for Resource Overview module
   * grids (objects in array)         --> Optional. Customized for Resource Overview module
   * modal (boolean)                  --> Optional. In some scenario we may want to use modal window, we can set this value to true.
   * scheduler (objects in array)     --> Optional. Column settings for window that relying on Kendo Scheduler
   * callback (callback function)     --> Optional. Function to call after window close button been clicked.
   * isContentProvided (boolean)      --> Optional. Only set it to true when we need to print pdf file.
   *                                      Check Resource Booking module as a reference.
   * contentRef (string)              --> Optional. HTML tag for displaying the pdf file's content.
   * ignoreFocusCtrl (boolean)        --> Optional. Set it to true if you want to ignore autofocus on main input field.
   * Example of grid options object:
   * {
   *      url:
   *      name:
   *      windowName:
   *      workspace:
   *      noWindow:
   *      useExisting:
   *      uid:
   *      minWidth:
   *      minHeight:
   *      width:
   *      height:
   *      requestClose:
   *      preGridPref:
   *      modal:
   *      scheduler:
   *      grids:
   *      callback:
   *      isContentProvided:
   *      contentRef:
   *      ignoreFocusCtrl:
   * }
   *
   * @param {any} viewdata
   * @returns
   */
  async openView(viewdata) {
    viewdata =
      typeof viewdata === 'string'
        ? {
            url: viewdata,
            width: 1200,
            height: 800,
            mode: 'new'
          }
        : viewdata

    viewdata.name =
      viewdata.name ??
      (viewdata.url
        ? viewdata.url
            .replace('view/', '')
            .replace('views/', '')
            .replace('/', ' ')
            .replace('.js', ' ')
        : '')

    viewdata.name = unescape(viewdata.name)

    const openEODWorkflow = vd =>
      $ajax('data/eodworkflow/method/getTargetEOD')
        .then(eod => {
          if (!eod?.JournalDate) {
            ow0.popInfo('information', __('NoBizDayAvailForEODW'))
            return false
          }
          vd.eod = eod
          vd.formTitle = vd.formTitle + ' (' + eod.JournalDate.split(' ')[0] + ')'
          if (eod?.Status === 0) {
            ow0.confirm(vd.formTitle, __('ConfirmStartEODW'), r => {
              if (r) {
                vd.doEODStart = true
                return windows.openView(vd)
              }
            })
            return false
          }
          return windows.openView(vd)
        })
        .catch(err => console.warn('Error-> ', err))

    // VISUAL CUE FOR slow loading
    if (viewdata.caller) {
      viewdata.caller.addClass('ow-loading')
      setTimeout(() => viewdata.caller.removeClass('ow-loading'), 1500)
    } else if (!viewdata.cue) {
      viewdata.cue = qc('div.ow-launching', qc('span', 'Launching ' + viewdata.name))
        .css({ height: '0', overflowY: 'visible' })
        .renderTo(document.body)
      viewdata.cue.done = () => {
        viewdata.cue.remove()
        delete viewdata.cue
      }
      setTimeout(() => viewdata.cue?.done(), 4000)
    }

    var targitFunctions = [17012, 17013, 17014, 17015, 17016]
    var healthCheckFunctions = [17091]
    var isTargit = targitFunctions.indexOf(viewdata.funcid) >= 0
    var isHealthCheck = healthCheckFunctions.indexOf(viewdata.funcid) >= 0
    if (viewdata.command && !isTargit && !isHealthCheck) {
      var cmds = viewdata.command.split(';')
      if (cmds.length > 1) {
        viewdata.formKey = viewdata.formKey || (cmds.length && cmds[0])
        viewdata.formTitle = viewdata.formTitle || (cmds.length > 1 && cmds[1])
      }
      viewdata.formMarkAsWorkflow = (cmds.length > 2 && cmds[2]) || '0'
      viewdata.formEnforceOrder = (cmds.length > 3 && cmds[3]) || '0'
    }

    if (typeof viewdata.getUrl === 'function') viewdata.url = viewdata.getUrl()
    if (!viewdata.url) {
      //missing view's command value will lead to no viewdata url
      ow0.popError(__('ERROR'), __('Viewdata URL Missing Error'), 5000)
      throw __('Viewdata URL Missing Error')
    }
    if (viewdata.url[0] === '/') viewdata.url = viewdata.url.substr(1)

    const isReportView = viewdata.funcid === 9002 || viewdata.url.indexOf('report-view') + 1

    if (isReportView) {
      viewdata.skipSavePrefs ??= viewdata.skipLoadPrefs ??= true
      if (ow0.reportRegistry) {
        viewdata.registry = ow0.reportRegistry
        viewdata.userRole = { CanRead: !!ow0.canOpenReport }
        viewdata.settingsLoaded = true
      }
    }

    //for change password & change store, no checking required.
    if (!viewdata.settingsLoaded && ((viewdata.funcid && !viewdata.userRole) || !viewdata.winpref))
      return $ajax({
        url: 'data/viewsettings',
        data: {
          funcid: viewdata.funcid,
          viewName: viewdata.url,
          ...(viewdata.skipLoadPrefs ? { skipLoadPrefs: 1 } : {})
        }
      })
        .then(settings => {
          viewdata.settingsLoaded = true
          viewdata.userRole = settings.userRole
          if (viewdata.userRole) viewdata.userRole.funcid = viewdata.funcid

          const storeUserDealerFunctions = [17009, 17010]
          if (
            storeUserDealerFunctions.includes(viewdata.funcid) &&
            ow0.currentStore.DealerID !== ow0.UserDealerID
          ) {
            flashPermissionDenied(viewdata)
            return false
          }

          const storeLevelAccessOnlyFunc = [
            1183, 7034, 17055, 17057, 17060, 17240, 17241, 17243, 17235, 17245
          ]
          if (
            storeLevelAccessOnlyFunc.includes(viewdata.funcid) &&
            ow0.currentStore.StoreType !== 0
          ) {
            popError(__('Error'), __('DeniedAccessInvalidStore'), 1000)
            return false
          }

          if (settings.userRole && !settings.userRole.CanRead && viewdata.funcid) {
            flashPermissionDenied(viewdata)
            return false
          }
          if (viewdata.funcid) saveAppEvent(viewdata.funcid, name)
          viewdata.winpref = settings.userSettings?.DataValue ?? null

          try {
            viewdata.winpref =
              typeof viewdata.winpref === 'string' ? JSON.parse(viewdata.winpref) : viewdata.winpref
          } catch {
            viewdata.winpref = {}
          }

          viewdata.registry = settings.userSettings?.registry ?? {}

          if (isReportView) ow0.reportRegistry = viewdata.registry

          viewdata.origWidth = viewdata.width
          viewdata.origHeight = viewdata.height
          viewdata.width = viewdata.winpref ? viewdata.winpref.windowWidth : viewdata.width
          viewdata.height = viewdata.winpref ? viewdata.winpref.windowHeight : viewdata.height

          if (viewdata.formMarkAsWorkflow === '1' && viewdata.funcid === 17003)
            return openEODWorkflow(viewdata)

          if (viewdata.funcid === 17057 && !viewdata.registry?.targeteod) {
            ow0.popError(__('Error'), __('NoRecordForBizDay'), 5000)
            return false
          }

          return windows.openView(viewdata)
        })
        .catch(err => {
          console.warn(err)
          flashPermissionDenied(viewdata)
          return false
        })

    viewdata.userRole = viewdata.userRole ??
      viewdata.caller?.viewdata?.userRole ?? {
        CanRead: true,
        CanWrite: true,
        CanConfirm: true,
        CanDelete: true
      }

    if (viewdata.url === '') {
      popInvalid(viewdata.name, 'Function not implemented', 5000)
      return false
    }

    if (viewdata.url[0] === '/') viewdata.url = viewdata.url.substr(1)

    var windowName = (viewdata.windowName = viewdata.url
      .replace('.js', '')
      .replace('views/', '')
      .replace('view/', '')
      .replace(/[^A-Z0-9]/gi, '')
      .concat(viewdata.funcid || ''))

    if (viewdata.workspace) {
      var $targetWS = $(viewdata.workspace)
      var $currentWS = $('.current-workspace')
      if (!$targetWS[0]) {
        $('<div class="container workspace ' + viewdata.workspace + '"></div>').appendTo(
          $('.main-content')
        )

        const workspace = viewdata.workspace
        const $workspaceTab = $('<li class="container workspace"></li>').insertAfter(
          $('.workspace-tabs').last()
        )
        const setWorkspaceTab = $workspaceTab => {
          $('.workspace-tab').removeClass('current-workspace-tab')
          $workspaceTab.addClass('current-workspace-tab')
        }
        const $link = $('<a href="#" >' + workspace + '</a>')
        $link.on('click', () => {
          $('.current-workspace').removeClass('current-workspace')
          $targetWS.addClass('current-workspace')
          setWorkspaceTab($workspaceTab)
        })
        $workspaceTab.append($link)
      }

      const changeWorkspace = function () {
        $('.current-workspace').removeClass('current-workspace')
        $targetWS.addClass('current-workspace')
      }
      if ($targetWS !== $currentWS) {
        changeWorkspace()
        if (viewdata.noWindow) $('h1.nowindow-caption').html(viewdata.name)
      }
    }

    let sName = windowName + (viewdata.formKey ? '-' + viewdata.formKey : '')
    if (!viewdata.funcid || viewdata.funcid === 9002) sName += viewdata.reportKey || ''
    const $win = sName ? $('#' + sName) : []

    if ($win[0] && viewdata.useExisting) {
      var w = $win.myWin()

      w.setVisible(true)
      w.toFront()

      viewdata.cue?.done()

      if (viewdata.noWindow) $('h1.nowindow-caption').html(viewdata.name)

      return w
    }

    let uidX = 0
    while ($('.' + windowName + uidX).length) uidX++

    var uid = windowName + uidX
    viewdata.uid = uid

    var winNameFormKey = windowName + (viewdata.formKey ? '-' + viewdata.formKey : '')
    viewdata.winNameFormKey = winNameFormKey

    viewdata.minWidth = viewdata.minWidth ?? 200
    viewdata.minHeight = viewdata.minHeight ?? 100

    viewdata.width = viewdata.width ?? 1024
    viewdata.height = viewdata.height ?? 700

    const win = viewWindow(viewdata)
    win.windowName = windowName
    win.renderTo(qc(document.body).find('.current-workspace')[0])

    win.qTop.addClass(uid)
    var $o = win.qTop.$top

    if (viewdata.noWindow) $('h1.nowindow-caption').html(viewdata.name)

    // button commmands
    $o.on('command-advanced', () => {
      $o.toggleClass('hide-filters')
      win.renderAsync()
    })

    if (viewdata.isContentProvided) {
      windows.windowListMenu()
      return $o
    }

    $o.viewdata = viewdata
    loadForm($o)

    win.on('focusout', e => ow0.log('focusOUT: ' + viewdata.uid + ' | ' + e.target, 'focus'))

    $(win.el).on('focusin', '*', e => {
      if (e.target === $o[0]) return
      if (e.target !== e.currentTarget) return

      ow0.log(
        'focusin: ' +
          viewdata.uid +
          ' | ' +
          e.target +
          ' | ' +
          e.currentTarget +
          ' | ' +
          e.delegateTarget +
          ' | ' +
          $o[0]?.id,
        'focus'
      )

      if (windows.activeWindow !== $o) {
        windows.activeWindow = $o
        windows.resolveFocus()
        return
      }

      // not activeWindow change so set the lastFocused
      if ($(e.target).hasClass('k-formatted-value')) return
      if ($(e.target).is('TABLE.k-selectable')) {
        $o.lastFocused = $(e.target).closest('.k-grid')[0]
        ow0.log('setting lastFocused to the grid OF TABLE', 'focus')
        return
      }

      if ($o.requestFormControlFocus) return

      if (
        (qc(e.target).attr('tabindex') ?? '-1') !== '-1' ||
        $(e.target).is(
          'input:not([disabled]), select, textarea, checkbox, button, a, td[role=gridcell], .focusable, .k-selectable, .ow-selectable'
        )
      ) {
        if (
          $(e.target).closest('.k-grid td[role=gridcell]').length ||
          $(e.target).is('.k-grid td[role=gridcell]')
        ) {
          var $cell = $(e.target)
          if (!$cell.is('td[role=gridcell]')) $cell = $cell.closest('td[role=gridcell]')

          $o.lastFocused = $cell.closest('.k-grid')[0]

          if ($o.lastFocused[0]) {
            var g = $o.lastFocused[0]
            g.state = g.state || {}
            g.state.lastFocused = {
              row: $cell.parent().index(),
              cell: $cell.index()
            }
            ow0.log(
              'lastFocused= grid[' + $cell.parent().index() + ', ' + $cell.index() + ']',
              'focus'
            )
          }
        } else if ($o.lastFocused !== e.target) {
          ow0.log('lastFocused= ' + e.target, 'focus')
          $o.lastFocused = e.target
        }
      } else ow0.log("ctrl doesn't qualify " + e.target, 'focus')
    })

    //hide top menu onclick item
    $('.menu-max-cols').hide(0, () => setTimeout(() => $('.menu-max-cols').show(), 500))

    $o.preLoads = {}

    // This loads lists for reuse in combos, usage: opts.list = $top.preLoadList(url)
    // added for ow5 speededits item and subdepartment
    $o.preLoadList = url => {
      if ($o.preLoads[url] === undefined) {
        $o.preLoads[url] = []
        window.$ajax(url + '?limit=999').then(r => ($o.preLoads[url] = r))
      }
      return () => $o.preLoads[url]
    }

    return $o
  },

  ...require('./openPdf'),
  ...require('./openCsv'),
  ...require('./downloadFile'),
  ...require('../viewWindow')
}
exports.windows = windows

// load the content
const loadForm = async $top => {
  const viewdata = $top.viewdata
  const { url } = viewdata
  const k = qc($top[0].parentElement) // $o.data().kendoWindow

  let view = viewdata.view

  $top.view = () => view

  const isJS = !url || url.indexOf('.js') > 0

  if (!view) {
    $top.progress(true)
    let result = await $ajax({
      dataType: isJS ? 'javascript' : 'text',
      url: url + '?_v=' + ow0.lang + '_' + ow0.orbisVersion
    })
      .catch(err => {
        console.warn(err) // cater redirect on wantsJSON
        if (err.status === 401) window.location.reload()
      })
      .finally(() => $top.progress(false))

    const viewRef = url.indexOf('.js') + 1 ? url.replace('views/', '').replace('.js', '') : url

    $top.edInit = (el, opts) => view.ed?.(el, opts)

    if (!isJS) {
      result = viewPreprocessor($top[0].id, result, window.__)
      // append scripts first
      var $result = $(result)

      // first append normal scripts - sets up components etc
      $result.forEach(x => {
        if ($(x).is('script') && $(x).attr('type') !== 'text/x-kendo-template') $top.append(x)
      })

      view = ow0.views[viewRef]
      if (view.ed) {
        console.warn(
          'Deprecated pattern: view.ed found, please try to upgrade',
          viewRef,
          view.ow?.version
        )
        $top.edInit = (el, opts) => view.ed(el, opts)
        $top[0].edInit = (el, opts) => view.ed(el, opts)
      }

      // Then Append the rest
      $result.forEach(x => {
        if (!$(x).is('script')) $top.append(x)
      })
    } else {
      eval(result) // jquery $.ajax runs eval automatically.
      view = ow0.views[viewRef]
    }
    delete ow0.views[viewRef]

    viewdata._winpref = JSON.stringify(viewdata.winpref)

    viewdata.cue?.done()
    if (viewdata.caller) viewdata.caller.removeClass('ow-loading')
    if (!view) {
      console.warn('view not found: ', url)
      return
    }
  }

  $top.ow = view.ow ? view.ow : $top.find('.ow5')[0] ? ow5 : ow4
  console.log(viewdata.url + ' using ow' + $top.ow.version)

  windows.windowListMenu()
  $top.wid = _id()

  // hook up the scope info
  view.$top = $top
  view.qTop = qc($top[0])
  view.top = view.qTop.el
  view.viewdata = viewdata
  view.registry = viewdata.registry ?? {}
  view.qTop.viewdata = viewdata

  view.progress = (...args) => $top.progress(...args)
  view.setFocusOnFirstEnableInput = () => $top.find('input:not([disabled])')[0]?.focus()

  view.viewWrap = qc(view.qTop.el.parentElement)
  view.renderAsync = async () => view.viewWrap.renderAsync()
  view.init?.($top, viewdata)
  view.afterInit?.()

  const isOldView = !$top.ow || $top.ow.version !== 0

  if (viewdata.maximize) k.maximize()

  if (isOldView) {
    $top.ow.controls?.enterKeyHandler?.($top[0]) // really?
    view.qTop.el.focus()

    viewdata.formCon =
      viewdata.formCon ??
      (url?.indexOf('view/grid/') !== -1 || url?.indexOf('-grid.js') !== -1
        ? gridCon
        : url?.indexOf('view/overview/') >= 0 ||
            url?.indexOf('-search.js') > 0 ||
            url?.indexOf('view/search/') >= 0 ||
            url?.indexOf('-overview.js') > 0
          ? overviewCon
          : editorCon)

    viewdata.formCon($top, viewdata)

    // adjust px settings to the
    var pxAdj = 12.96
    $top.find('.sticky-header,.sticky-middle').forEach(el => {
      var $el = $(el)
      if (el.style.height.indexOf('px') + 1)
        $el.css('height', parseFloat($el.css('height').replace('px', '')) / pxAdj + 'em')

      if (el.style.top.indexOf('px') + 1)
        $el.css('top', parseFloat($el.css('top').replace('px', '')) / pxAdj + 'em')

      qc(el).renderAsync()
    })
  }

  view.qTop.toFront()
}
exports.loadForm = loadForm

var focusRequestTick = null

windows.resolveFocus = function () {
  if (focusRequestTick) return

  ow0.log('resolveFocus: ' + windows.activeWindow?.[0]?.id, 'focus')

  setTimeout(() => {
    if (windows.activeWindow) {
      var $w = windows.activeWindow
      var $o = $w

      // first try to set Focus to last Focused
      if ($o.lastFocused) {
        ow0.log('FOCUSING: ' + $o.lastFocused, 'focus')

        if ($o.lastFocused.resolveFocus) {
          $o.lastFocused.resolveFocus()
          return
        }

        if ($($o.lastFocused).hasClass('k-grid')) {
          ow0.log('setting focus to lastfocused grid', 'focus')

          var g = $($o.lastFocused)[0]
          if (g.cellFocus) {
            if (!_v(g, 'state.lastFocused') && g.current?.()) {
              var $cell = g.current()
              _v(g, 'state.lastFocused', { row: $cell.parent().index(), cell: $cell.index() })
            }
            if (!_v(g, 'state.lastFocused'))
              g.cellFocus(_v(g, 'state.lastFocused.row') || 0, _v(g, 'state.lastFocused.cell') || 0)
            else ow0.log('g no have state.lastFocused - not setting!', 'focus')
          }
        } else $o.lastFocused.focus({ preventScroll: true })
      } else {
        // if nothing on this window is focused

        var validFocusSelector =
          'input:not([disabled]), select, textarea, checkbox, button, a, div.tabstrip'

        var $focused = $w.find(':focus')
        if ($focused.length && $focused.is(validFocusSelector)) return

        focusRequestTick = setTimeout(function () {
          focusRequestTick = null
          if ($w !== windows.activeWindow) return
          $focused = $w.find(':focus')
          if ($focused.length && $focused.is(validFocusSelector)) return

          var $ctrls = $w.find(validFocusSelector)
          var $default = $ctrls.filter('.default-focus')
          if ($default.length) {
            ow0.log('Focusing default: ' + $w.viewdata.uid, 'focus')
            $default[0].focus({ preventScroll: true })
          } else if ($ctrls.length) {
            ow0.log('Focusing first: ' + $w.viewdata.uid, 'focus')
            $ctrls[0].focus({ preventScroll: true })
          }
        }, 100)
      }
    }
  }, 300)
}

exports.openReport = function (reportKey, flag, name, reportName, reportCb) {
  if (!ow0.canOpenReport) return flashPermissionDenied({ funcid: 9002, name })

  saveAppEvent(9002, 'Report [' + unescape(name) + ']')
  windows.openView({
    name: name,
    url: 'views/report-view.js',
    reportKey: reportKey,
    minWidth: 610,
    width: 610,
    useExisting: true,
    reportName: reportName,
    userSettingKey: 'report' + reportKey,
    callback: reportCb
  })
}

const saveAppEvent = (funcID, name) =>
  $ajax({
    url: '/data/auditevent/method/saveappevent',
    data: { FuncID: funcID, EventMsg: name || '', SysCritical: 99 }
  })
    .then(() => console.log('saveappevent-> ', name))
    .catch(err => console.log('saveappevent Error-> ', err))

qc(document.body).on('keydown', e => {
  // ow0.log((e.altKey ? 'Alt+' : '') + e.which + ' ' + e.target.tagName + '#' + e.target.id + '.' + e.target.className.split(' ').join('.'));

  // alt+t
  if (e.which === 84 && e.altKey) ow0.test()

  // alt+x
  if (e.which === 88 && e.altKey) {
    if (!windows.findOpenWindows().length) return
    ow0.confirm('Confirm', __('ConfirmCloseAllOpened'), r => r && windows.closeAllOpenWindows())
  }

  // alt+F1
  if (e.which === 112 && e.altKey) {
    var activeWnd = windows.activeWindow
    var funcID = activeWnd?.viewdata?.funcid ?? activeWnd?.viewdata?.userRole?.funcid ?? 0
    ow0.openHelpFile(funcID, activeWnd?.viewdata?.url ?? '', ow0.lang)
  }
})

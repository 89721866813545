const { addCss } = require('../add-css')
const { qc } = require('../cmp/qc')
const { isNumeric, ctlParsers, ctlFormatters } = require('../ctl-format')
const { formatString, culture } = require('../culture')
const theme = require('../theme')
const { dataBind } = require('./databind7')
const { filterFunctions } = require('./filters')
const { rs } = require('./rs7')

const input7Props = {
  value(v) {
    const me = this
    if (v === undefined) return me.el ? me.el.value : me.attr('value') ?? ''
    if (me.el) me.el.value = v
    else me.attr({ value: v })

    return this
  },

  val(v) {
    const { format, parser, formatter } = this

    if (v !== undefined) {
      const value = formatV(v, format, formatter) || ''
      const hasChanged = value !== this.attr('value')
      if (hasChanged) {
        this.attr({ value })
        if (this.el) this.trigger('ow-change')
      }
    }
    v = this.attr('value')

    if (v && parser) return parser(v)

    if (v === '') return this.blankValue
    return v
  },

  validate(onInvalid, messageArray = []) {
    const { label, fieldName, required, schema } = this.opts

    var name = label || fieldName

    var v = this.val()
    var hasValue = !(
      v === undefined ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )
    if ((required || (required !== false && schema?.required)) && !hasValue) {
      if (onInvalid) {
        onInvalid(name, 'must have a value', this.el, messageArray)
        return false
      }
    }

    let { minLength, maxLength, min, max, validation, ctlType } = this.opts

    if (typeof v === 'number' && isNaN(v) && ctlType === 'combo') {
      if (onInvalid) {
        onInvalid(name, 'must select from the list.', this.el, messageArray)
        return false
      }
    }
    minLength = minLength || min
    maxLength = maxLength || max

    if (typeof v === 'string' && minLength && minLength < (v || '').length) {
      onInvalid?.(name, 'must be have min length of ' + minLength, this.el, messageArray)
      return false
    }
    if (typeof v === 'string' && maxLength && maxLength < (v || '').length) {
      onInvalid?.(name, 'must be have max length of ' + maxLength, this.el, messageArray)
      return false
    }

    if (typeof validation === 'object' && validation) {
      if ('$ne' in validation && v === validation.$ne) {
        onInvalid?.(name, 'cannot be ' + validation.$ne, this.el, messageArray)
        return false
      }
    }

    let { isToField, fromCmp } = this.opts

    // Validation for fields with from value and to value
    if (['date', 'time', 'currency', 'float', 'int'].find(x => x === ctlType)) {
      if (isToField && fromCmp) {
        let fromField, toField
        let v = this.val()
        toField = typeof v === 'number' ? formatString(v) : v

        const vFrom = fromCmp.val()
        fromField = typeof vFrom === 'number' ? formatString(vFrom) : vFrom

        if (isNumeric(fromField) && isNumeric(toField)) {
          fromField = fromField * 1
          toField = toField * 1
        }
        if (fromField > toField) {
          if (onInvalid) {
            onInvalid(
              fromCmp.label + ' field',
              'From field must be always lower than to field',
              this.el,
              messageArray
            )
            return false
          }
        }
      }
    }

    return true
  },

  readFilter(filters) {
    const { opts } = this
    let v = this.val()

    // this.isSet = true // indicates to the outside that there's a filter set on this field/col

    this.op || (this.op = this.opts.op ?? 'eq')

    const operator = this.op

    var filter = {
      field: opts.fieldName,
      operator,
      value: v
    }

    if (opts.textFilterField && filter.value) {
      filter.field = opts.textFilterField
      filter.operator = operator || 'contains'
      filter.value = this.el.value || null
    }

    if (opts.filterMap && filter.value) {
      const s = filter.value.toLowerCase()
      const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

      filter.value = Object.keys(opts.filterMap)
        .filter(matchFilter)
        .map(k => opts.filterMap[k])

      if (filter.value.length === 0) {
        ow.popInvalid(__('Invalid value for ' + filter.field))
        this.attr({ value: '' })
        return
      }
      filter.operator = 'in'
    }

    if (filter.value !== undefined || common.ow7.allowFilterUndefined[filter.operator])
      filters.push(filter)
  }
}

/**
 * Cmp for using as part of an input based control - text7 and combo both use input7
 * @param {object} opts
 * @param {boolean} props.disabled
 * @param {boolean} props.readOnly
 * @returns Cmp Object
 */
const input7 = opts => {
  let lastValue

  const me = qc('input.input7')
    .attr({ type: 'text', autocomplete: 'off', spellcheck: 'false' })
    .props({ opts })
    .props(input7Props)
    .on('init', () => (me.el.opts = me.opts))
    .on('focus', () => {
      //normalize
      var decimal = culture().numberFormat['.']
      if (!me.el.value) return
      if (opts === 'currency')
        me.el.value = ('' + ctlParsers.currency(me.el.value)).replace('.', decimal)
      else if (opts.type === 'float')
        me.el.value = ('' + ctlParsers.float(me.el.value)).replace('.', decimal)
    })
    .on('keydown', () => (lastValue = me.el.value))
    .on('keyup', e => {
      if (e.which === 9) return
      if (e.which === 13 && !e.shiftKey) me.resolve?.() // enter

      const hasChanged = lastValue === me.el.value

      if (me.type === 'float' || me.type === 'currency' || me.type === 'int') {
        if (e.which > 46 || e.which === 8) {
          var decimal = culture().numberFormat['.'] //use standard decimal

          const { el } = me

          let isFloat = me.type === 'float' || me.type === 'currency'
          if (!isFloat) decimal = ''

          let validChars = Array.prototype.reduce.call(
            '-0123456789' + decimal,
            (t, c) => (t[c] = 1) && t,
            {}
          )
          let value = Array.prototype.filter.call(el.value, x => x in validChars).join('')

          // only a single decimal point
          value = value.split(decimal)
          if (value.length > 1) value[1] = decimal + value[1]
          value = value.join('')

          if (value !== el.value) el.value = value

          me.trigger('ow-change')
        }
      } else if (me.type === 'string' || !me.type) if (hasChanged) me.trigger('ow-change')
    })
    .on('blur', () => {
      if ({ float: true, int: true, currency: true }[me.type]) me.val(me.val())
    })

  if (opts.disabled) me.disabled = true
  if (me.disabled) me.readOnly = true

  const _w = qc('span.ow-ctl-wrap.input7.ow-textbox', me).props({ input: me })
  me.wrap = () => _w

  if (opts.width) _w.addClass(opts.width)

  if (me.disabled) me.wrap().addClass('ow-disabled') // makes the input transparent "grayed out"

  me.attr({ placeholder: opts.placeholder ?? opts.label ?? undefined })

  // 'static-text'
  me.bindState(
    () => me.readOnly || me.obscure || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )

  me._rs = rs({ ...opts }, _w)
  _w.rs = () => opts.rs ?? me._rs
  me.rs = () => opts.rs ?? me._rs

  return me
}

const formatV = (v, fmt, formatter) => {
  if (formatter) return formatter(v, fmt) || ''
  if (v && fmt) return formatString(v, fmt) || ''
  return v === null || v === undefined ? '' : formatString(v)
}

const text7Props = {
  val(v) {
    const { format, parser, formatter } = this

    if (v !== undefined) {
      const value = formatV(v, format, formatter) || ''
      const hasChanged = value !== this.attr('value')
      if (hasChanged) {
        this.attr({ value })
        if (this.el) this.trigger('ow-change')
      }
    }
    v = this.attr('value')

    if (v && parser) return parser(v)

    if (v === '') return this.blankValue
    return v
  },

  validate(onInvalid, messageArray = []) {
    const { label, fieldName, required, schema } = this.opts

    var name = label || fieldName

    var v = this.val()
    var hasValue = !(
      v === undefined ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )
    if ((required || (required !== false && schema?.required)) && !hasValue) {
      if (onInvalid) {
        onInvalid(name, 'must have a value', this.el, messageArray)
        return false
      }
    }

    let { minLength, maxLength, min, max, validation, ctlType } = this.opts

    if (typeof v === 'number' && isNaN(v) && ctlType === 'combo') {
      if (onInvalid) {
        onInvalid(name, 'must select from the list.', this.el, messageArray)
        return false
      }
    }
    minLength = minLength || min
    maxLength = maxLength || max

    if (typeof v === 'string' && minLength && minLength < (v || '').length) {
      onInvalid?.(name, 'must be have min length of ' + minLength, this.el, messageArray)
      return false
    }
    if (typeof v === 'string' && maxLength && maxLength < (v || '').length) {
      onInvalid?.(name, 'must be have max length of ' + maxLength, this.el, messageArray)
      return false
    }

    if (typeof validation === 'object' && validation) {
      if ('$ne' in validation && v === validation.$ne) {
        onInvalid?.(name, 'cannot be ' + validation.$ne, this.el, messageArray)
        return false
      }
    }

    let { isToField, fromCmp } = this.opts

    // Validation for fields with from value and to value
    if (['date', 'time', 'currency', 'float', 'int'].find(x => x === ctlType)) {
      if (isToField && fromCmp) {
        let fromField, toField
        let v = this.val()
        toField = typeof v === 'number' ? formatString(v) : v

        const vFrom = fromCmp.val()
        fromField = typeof vFrom === 'number' ? formatString(vFrom) : vFrom

        if (isNumeric(fromField) && isNumeric(toField)) {
          fromField = fromField * 1
          toField = toField * 1
        }
        if (fromField > toField) {
          if (onInvalid) {
            onInvalid(
              fromCmp.label + ' field',
              'From field must be always lower than to field',
              this.el,
              messageArray
            )
            return false
          }
        }
      }
    }

    return true
  },

  readFilter(filters) {
    const { opts } = this
    let v = this.val()

    this.op = this.op ?? 'contains'

    // this.isSet = true // indicates to the outside that there's a filter set on this field/col

    this.op || (this.op = this.opts.op ?? 'contains')

    const operator = this.op

    var filter = {
      field: opts.fieldName,
      operator,
      value: v
    }

    if (opts.textFilterField && filter.value) {
      filter.field = opts.textFilterField
      filter.operator = operator || 'contains'
      filter.value = this.el.value || null
    }

    if (opts.filterMap && filter.value) {
      const s = filter.value.toLowerCase()
      const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

      filter.value = Object.keys(opts.filterMap)
        .filter(matchFilter)
        .map(k => opts.filterMap[k])

      if (filter.value.length === 0) {
        ow.popInvalid(__('Invalid value for ' + filter.field))
        this.attr({ value: '' })
        return
      }
      filter.operator = 'in'
    }

    if (filter.value !== undefined || common.ow7.allowFilterUndefined[filter.operator])
      filters.push(filter)
  }
}

const text7 = opts => {
  if (typeof opts === 'string') opts = { name: opts }
  if (opts.name === undefined) opts.name = opts.fieldName ?? opts.label
  if (opts.label === undefined) opts.label = __(opts.name ?? opts.fieldName)

  const me = input7(opts)
    .addClass('text7')
    .props({ opts })
    .props({ ...text7Props })

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  if (opts.disabled) opts.readOnly = true
  if (opts.disabled) me.wrap().addClass('ow-disabled').addClass('text7') // makes the input transparent "grayed out"

  const attrs = opts.attrs ?? {}

  if (opts.width)
    opts.width === 'full'
      ? me.wrap().addClass('w4')
      : ['w1', 'w2', 'w3', 'w4'].includes(me.width)
      ? me.wrap().addClass(opts.width)
      : me.wrap().css({ width: opts.width })

  opts.parser = opts.parser || ctlParsers[opts.type] || null
  opts.formatter = opts.formatter || ctlFormatters[opts.type] || null

  if (!('blankValue' in opts)) {
    opts.blankValue = ''
    if (opts.type === 'currency') opts.blankValue = undefined
    if (opts.type === 'float') opts.blankValue = undefined
    if (opts.type === 'int') opts.blankValue = undefined
    if (opts.type === 'date') opts.blankValue = undefined
    if (opts.type === 'datetime') opts.blankValue = undefined
    if (opts.type === 'time') opts.blankValue = undefined
  }

  attrs.type = opts.obscure ? 'password' : 'text'
  attrs.placeholder = opts.placeholder ?? opts.label

  let v = opts.value !== undefined ? formatV(opts.value, opts.format, opts.formatter) : ''
  if (v) attrs.value = v

  if (opts.isFilterControl) opts.op = opts.op ?? 'contains'

  // 'static-text'
  return me.attr(attrs).bindState(
    () => me.readOnly || me.obscure || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )
}

const init = scope => {
  addCss(
    'input7-css',
    `
${scope} span.ow-ctl-wrap { display: inline-block; }
${scope} .input7.ow-ctl-wrap.ow-disabled { background: transparent; }
${scope} .input7.ow-ctl-wrap.ow-disabled input { background: transparent; }
${scope} i.text-item-icon { color: ${theme.iconBlue}; }
`,
    scope
  )
}

module.exports = { text7, input7, init }

const { qc } = require('../cmp/qc')
const { popInvalid } = require('../pop-box')

exports.gridCon = function ($top, opts) {
  $top.on('keydown', e => {
    if (e.which === 27) {
      let g = $top.find('.k-grid, .ow-grid')[0]
      if (g) g.cancelChanges()
    } //Esc (cancel)
  })

  opts.closeAfterSave = true

  $top.on('command-close-save', () => {
    opts.triggerClose = true
    const g = $top.find('.k-grid, .ow-grid')[0]
    if (g) {
      const selectedRow = g.getDataForRowIndex(g.rowIndex())
      if (selectedRow?.dirty) g.saveChanges()
      else g.cancelChanges()
    }
  })

  var tref = $top.find('button[data-command="edit"]').attr('data-target-ref') // should be mainGrid
  if (!tref && !opts.isHierarchy) {
    var x = $top.find('.k-grid')[0]
    if (!x) return // not a valid overview form
    tref = x.id
  }

  var g = $top.find('#' + tref + ' , .iden-' + tref)[0]
  if (opts.mode === 'select' && (opts.isInlineEdit || opts.isHierarchy)) {
    $(
      '<button class="icon-left k-button auto-command-button owauto" data-command="edit" data-target-ref="' +
        tref +
        '"><i class="fa fa-check" aria-hidden="true"></i>Select</button>'
    )
      .attr('data-default-button', 'true')
      .insertBefore($top.find('.sticky-footer .buttonset_right button:last'))

    var rowEditHandler = function (e, rowIndex, data) {
      if (rowIndex < 0 || typeof rowIndex === 'undefined') {
        popInvalid('Please select a row')
        return false
      }

      opts.result = JSON.parse(JSON.stringify(data))
      if (opts.dontClose === true) {
        opts.result = data
        opts.callback($top, opts)
        opts.callback = null
      } else if (opts.delayClose === true) {
        opts.result = data
        opts.callback($top, opts)
        opts.callback = null
        setTimeout(() => $top.closeForm(), 500)
      } else $top.closeForm()

      return false
    }

    if (opts.isHierarchy)
      $top.find('.ow-treeview-lines').off('row-edit').on('row-edit', rowEditHandler)
    else $top.find('.k-grid, .ow-grid').first().off('row-edit').on('row-edit', rowEditHandler)

    $top.find('button[data-command="add-row"]').hide()
    $top.find('button[data-command="edit"]').hide()
    $top.find('button[data-command="new"]').hide()
    $top.find('button[data-command="copy"]').hide()
    $top.find('button[data-command="delete"]').hide()

    if (opts.mode === 'select' && g)
      $(g).on('keyup', e => {
        if (e.which === 13) {
          var $row = $(g).find('.ow-current-row')
          g.clearSelection()
          g.selectRow($row)
          qc(g).trigger('command-edit')
        }
      })
  }
}

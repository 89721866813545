const { qc } = require('../cmp/qc')

/**
 * resourceSet - label | control
 * @param {Object} opts
 * @param {boolean} opts.noLabel - don't create a label element at all
 * @param {string} opts.label
 * @param {object} opts.input - the qc object we're wrapping
 * @param {string} opts.type - becomes a class of the RS div
 * @param {Object} content - what to put in the presentation span
 *
 * Note: the cmp this returns has method addContent
 */
const rs = (opts, content = []) => {
  opts.type = opts.type || ''

  let input = Array.isArray(content) ? content[0] : content
  input = input?.input ?? opts.input ?? input

  const span = qc('span', content).attr({ role: 'presentation' })

  return qc(
    'div.resource_set.' +
      opts.type +
      (opts.noLabel ? '.no-label' : '') +
      (opts.edType === 'static' ? '.static' : '')
  )
    .kids([qc('label.resource_label', opts.noLabel ? '' : opts.label), span])
    .attr(Object.assign({}, opts.attrs || {}))
    .props({
      input,
      addContent(x) {
        span.kids([...span.kids(), x])
      }
    })
}

module.exports = { rs }

// qCalendar
const { qc } = require('../cmp/qc')
const { html } = require('../cmp/html')
const { dates } = require('../dates')
const { culture } = require('../culture')

const styles = scope =>
  qc(
    'style',
    html(`
${scope} th,
${scope} td {
  text-align: center;
  padding: 0;
  line-height: 1.92em;
}
${scope} th > a,
${scope} td a {
  box-sizing: border-box;
  display: block;
  padding: 0.5em 0.825em;
  cursor: pointer;
  line-height: 1.92em;
}
${scope} td.prevmonthdates,
${scope} td.nextmonthdates {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}
${scope} td.currentmonthdates.today {
  font-weight: bold;
  text-decoration: underline;
}
${scope} td:hover {
  background-color: #99c0ff;
  color: #515956;
}
/* Wrapper div. That makes the inner div into an inline element that can be centered with text-align.*/
${scope} { text-align: center; }

/* This is a fluid div as width will be changing */
${scope} .calendar-controls > a {
  display: inline-block; margin-left: 0.625em; 
}
`)
  )

const qCalendar = (onSetDate, d = new Date(), template) => {
  const id = 'c' + (new Date().getTime() - new Date(2020, 1, 1).getTime())
  const me = qc('div.ow-calendar.' + id)

  me.DaysOfWeek = [...dates.shortDaysOfWeekJs] // Days of week, starting on Sunday
  me.WeekDaysMap =
    dates.firstDay === 1 // mon fisrt
      ? [1, 2, 3, 4, 5, 6, 0]
      : dates.firstDay === 6 // sat first
        ? [6, 0, 1, 2, 3, 4, 5]
        : [0, 1, 2, 3, 4, 5, 6]

  me.Months = dates.shortMonths // Months, stating on January

  me.CurrentMonth = d.getMonth()
  me.CurrentYear = d.getFullYear()
  if (isNaN(me.CurrentMonth) || isNaN(me.CurrentMonth)) {
    // Set the current month, year
    d = new Date()
    me.CurrentMonth = d.getMonth()
    me.CurrentYear = d.getFullYear()
  }

  me.props({
    format: dates.DateFormatSetting,

    nextMonth() {
      if (me.CurrentMonth === 11) {
        me.CurrentMonth = 0
        me.CurrentYear++
      } else me.CurrentMonth++

      me.showCurrent()
    },

    previousMonth() {
      if (me.CurrentMonth === 0) {
        me.CurrentMonth = 11
        me.CurrentYear--
      } else me.CurrentMonth--

      me.showCurrent()
    },

    previousYear() {
      me.CurrentYear--
      me.showCurrent()
    },

    nextYear() {
      me.CurrentYear++
      me.showCurrent()
    },

    showCurrent() {
      me.build(me.CurrentYear, me.CurrentMonth)
    },

    selectDate(v) {
      me.value = v
      onSetDate?.(v)
      me.trigger('ow-select', v)
    },

    build(y = new Date().getFullYear(), m = new Date().getMonth()) {
      typeof y === 'number' ? (me.CurrentYear = y) : null
      typeof m === 'number' ? (me.CurrentMonth = m) : null

      y = me.CurrentYear
      m = me.CurrentMonth

      if (isNaN(y) || isNaN(m)) {
        debugger // NaN somehow
        return me.build()
      }
      let today = new Date()
      let todayMonth = today.getMonth()
      let todayYear = today.getFullYear()
      today = new Date(todayYear, todayMonth, today.getDate())

      // Write selected month and year. This HTML goes into <div id="month"></div>

      const sYear =
        (culture().calendar === 'buddhist' ? dates.buddhistCalendarOffset : 0) + me.CurrentYear

      const table = qc('table', [
        styles('.' + id),
        qc('tr.calendar-controls', [
          qc(
            'th',
            qc('a', '<<')
              .attr({ href: '#', title: __('Previous Year') })
              .on('click', () => me.previousYear())
          ),
          qc(
            'th',
            qc('a', '<')
              .attr({ href: '#', title: __('Previous Month') })
              .on('click', () => me.previousMonth())
          ),
          qc('th', me.Months[m] + ' ' + sYear).attr({ colspan: '3' }),
          qc(
            'th',
            qc('a', '>')
              .attr({ href: '#', title: __('Next Month') })
              .on('click', () => me.nextMonth())
          ),
          qc(
            'th',
            qc('a', '>>')
              .attr({ href: '#', title: __('Next Year') })
              .on('click', () => me.nextYear())
          )
        ]),

        qc(
          'tr',
          me.WeekDaysMap.map(i => qc('th.daysheader', me.DaysOfWeek[i]))
        )
      ])

      let dt = new Date(me.CurrentYear, me.CurrentMonth, 1)
      const p = dt.getDay() // 1st day of the selected month
      // p = p + 1 + ow.dates.firstDay

      // if p=0 and firstDay=6 => -1 ok
      // if 1st=6 (sat) and firstDay=0 =>  ok
      // if 1st=0 (sun) and firstDay=0 ok
      // if 1st=0 (sun) and firstDay=0 ok

      const { firstDay } = dates

      dt = new Date(
        dt.getFullYear(),
        dt.getMonth(),
        -p + (firstDay === 6 ? -1 : firstDay === 1 ? 1 : 0)
      )

      let meRow

      const dateCell = dt =>
        qc('td.date-cell', template?.(dt) ?? qc('a', '' + dt.getDate()).attr({ href: '#' })).on(
          'click',
          () => me.selectDate(dt)
        )

      let i = 0
      for (i = 0; i < 42; i++) {
        //eg Jun 2019, doesnt show all if 5 weeks only
        dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 1) // watch out for daylight savings

        if (i % 7 === 0) {
          meRow = qc('tr', [])
          table.kids([...table.kids(), meRow])
        }

        var isTodayClass = dt.getTime() === today.getTime() ? 'today ' : ''

        meRow.kids([
          ...meRow.kids(),
          dateCell(dt).addClass(
            dt.getFullYear() !== y
              ? isTodayClass + (dt.getFullYear() < y)
                ? 'prevmonthdates'
                : 'nextmonthdates'
              : isTodayClass +
                  ['prevmonthdates', 'currentmonthdates', 'nextmonthdates'][dt.getMonth() - m + 1]
          )
        ])
      }

      me.kids(table)
    }
  })

  me.showCurrent()
  return me
}

module.exports = { qCalendar }

// data-model7.js

// Notes:
// No presentation level functionality here.
//
// Grid ORDERING/Sorting is a presentation issue, not data
// it can be based on data but does not change the ordering in the arrays.
// Never reorder array model members
//
// Deleting - When a child is deleted, mark the record as deleted
// The appearance of deleted records is presentation NOT data
// Do not remove them
//
// The original dataset array index is meta.reci (no longer rowi)
const { qc } = require('../cmp/qc')
const { isDate, isObject } = require('../js-types')

const isModel = x => x && isObject(x) && !isDate(x)

const metaMap = new WeakMap()

/**
 * @typedef {Object} meta
 * @prop {Object} model
 * @prop {Object} schema
 * @prop {string} name
 * @prop {Object} changes
 * @prop {Object} orig
 * @prop {Object} prev
 * @prop {string} fullName
 */

/**
 * @returns {meta}
 */
const $meta = (model, setTo) => {
  if (!setTo && !metaMap.has(model)) return
  let meta = setTo ?? {}
  if (setTo || !metaMap.has(model)) metaMap.set(model, meta)
  else meta = metaMap.get(model)
  meta.schema = meta.schema ?? {}
  return meta
}

const schemaReserved = {
  isModel: 1,
  isCollection: 1,
  itemSchema: 1,
  ignore: 1,
  validation: 1,
  required: 1,
  prePopulate: 1
}

/**
 *
 * @param {object} model
 * @returns Array of field Definitions
 */
const schemaFields = model =>
  Object.keys($meta(model).schema)
    .filter(key => !(key in schemaReserved))
    .map(key => {
      const result = { fieldName: key }
      Object.assign(result, $meta(model).schema[key])
      return result
    })

const compareVal = (v, fieldSch) => {
  if (fieldSch && fieldSch.type === undefined && v !== null) {
    if (typeof v === 'boolean') fieldSch.type = 'boolean'
    else if (typeof v === 'string') fieldSch.type = 'string'
    else if (typeof v === 'number') fieldSch.type = 'number'
    else if (isDate(v)) fieldSch.type = 'date'
  }
  if (fieldSch?.type === 'boolean') return v ? true : false
  return isDate(v) ? v.valueOf() : v
}

/**
 * Creates a "meta" object associated using a Weak map
 * the object maintains data change tracking information
 * @param {Object} model - the hierarchical data object
 * @param {string} name - top level should be '' used as dsName for the change notifications to the Cmp objects in the DOM
 * @param {object} schema - Object where keys are fieldnames and values describe field schema
 * @param {object} parentMeta meta of the parent data object in the data hierarhy
 * @returns {meta}
 */
const initModel = (model, name = '', schema = {}, parentMeta) => {
  if (!model) console.warn('model cannot be null')
  let meta = $meta(model)
  meta = $meta(model, {})

  meta.model = model

  meta.orig = {}
  meta.prev = {}
  meta.changes = {}

  meta.name = name

  if (parentMeta) meta.parent = parentMeta
  meta.fullName = (parentMeta && parentMeta.fullName) || ''
  meta.fullName += (meta.fullName ? '.' : '') + name

  schema = schema ?? meta.schema ?? meta?.parent?.schema?.itemSchema ?? {}
  meta.schema = schema

  schema.prePopulate?.(model)

  if (Array.isArray(model) && schema?.isCollection !== false) {
    meta.isCollection = true
    meta.schema.itemSchema = meta.schema.itemSchema || {}
    let i, item, kidM
    for (i = 0; i < model.length; i++) {
      item = model[i]
      if (isModel(item)) {
        kidM = initModel(item, i.toString(), meta.schema.itemSchema, meta)
        kidM.reci = i
      }
      meta.orig[i.toString()] = item
    }
    meta.prevLength = model.length
  } else {
    let i,
      f,
      kid,
      fields = Object.keys(model)
    for (i = 0; i < fields.length; i++) {
      f = fields[i]
      schema[f] = schema[f] || {}
      kid = model[f]

      if (schema[f].ignore) continue

      if (schema[f].isModel || isModel(kid)) {
        schema[f].isModel = true

        if (schema[f].isCollection !== true && Array.isArray(kid)) schema[f].isCollection = true
        if (!kid) model[f] = schema[f].isCollection ? [] : {}
        if (kid && typeof kid === 'object') initModel(kid, f, schema[f], meta) // if not null or undefined
      }

      // convert string dates
      if (typeof kid === 'string' && (schema[f].type === 'date' || schema[f].type === 'datetime')) {
        kid = kid === '' ? undefined : new Date(model[f])
        model[f] = kid
      }

      compareVal(kid, schema[f])
      meta.orig[f] = kid
    }
  }

  return meta
}

const reactItem = (collection, i) => {
  const meta = $meta(collection)
  const f = '' + i
  const item = collection[i]
  if (item && hasChanges(item)) meta.changes[f] = true
  else delete meta.changes[f]

  if (item) {
    let itemMeta = $meta(item)
    if (!itemMeta) {
      console.warn(meta.fullName + '.' + f + ' has no meta.')
      itemMeta = initModel(item, f, meta.schema.itemSchema, meta)
      itemMeta.new = true
      itemMeta.reci = parseInt(f)
    }

    if (itemMeta.new && itemMeta.deleted) {
      delete meta.changes[f]
      delete itemMeta.prev[f]
    }
  }
}

/**
 * tests for changes to the model since before
 *
 * @param {Object} model
 * @param {string?} fieldName optional
 */
const react = (model, ...fieldNames) => {
  const meta = $meta(model)
  if (!meta) return

  if (meta.isCollection) {
    if (!fieldNames.length) {
      const l = Math.max(model.length, meta.prevLength)
      let i
      for (i = 0; i < l; i++) reactItem(model, i)

      while (meta.prevLength > model.length) {
        const lasti = meta.prevLength - 1
        delete meta.changes[lasti]
        delete meta.orig[lasti]
        delete meta.prev[lasti]
        delete meta.schema[lasti]
        meta.prevLength--
      }
      meta.prevLength = model.length
    } else {
      let i
      for (i = 0; i < fieldNames.length; i++) reactItem(model, fieldNames[i])
    }
  } else {
    if (!fieldNames.length) {
      fieldNames = Object.keys(model)

      Object.keys(meta.schema)
        .filter(f => !schemaReserved[f])
        .forEach(f => {
          if (!fieldNames.includes(f)) fieldNames.push(f)
        })
    }

    let i, fieldName
    for (i = 0; i < fieldNames.length; i++) {
      fieldName = fieldNames[i]

      if (!(fieldName in meta.schema) && !meta.isCollection)
        meta.schema[fieldName] = { ignore: fieldName[0] === '_' }

      if (!meta.schema[fieldName].ignore) {
        const sch = meta.schema[fieldName]
        const vNew = model[fieldName]
        const vOrig = meta.orig[fieldName]

        // changes from original has changed or not
        const isOriginalValue =
          compareVal(vNew, sch) === compareVal(vOrig, sch) && (!isModel(vNew) || !hasChanges(vNew))

        const wasOriginalValue = !meta.changes[fieldName]
        if (isOriginalValue !== wasOriginalValue) {
          if (!isOriginalValue) meta.changes[fieldName] = true
          else delete meta.changes[fieldName]
        }

        meta.prev[fieldName] = model[fieldName]
        if (compareVal(meta.prev[fieldName], sch) === compareVal(vOrig, sch))
          delete meta.prev[fieldName]
      }
    }
  }

  // reactUp
  if (meta.parent) {
    if (!meta.view && meta.parent?.view) meta.view = meta.parent.view // propagate view
    react(meta.parent.model, meta.name)
    if (!meta.view && meta.parent?.view) meta.view = meta.parent.view // propagate view
  } else {
    if (!meta.view) console.warn('meta has no view assigned, renderAsync on document.body')
    ;(meta.view ?? qc(document.body))?.renderAsync()
  }
  meta.afterReact?.(model)
}

const hasChanges = model => {
  const meta = $meta(model)
  return !!(
    (
      meta &&
      !meta.readOnly &&
      !(meta.new && meta.deleted) &&
      (meta.deleted || Object.keys(meta.changes).length)
    ) // new with no changes doesn't now count
  )
}

/**
 * Note, react() isn't called
 *
 * @param {*} model
 * @returns model
 */
const cancelChanges = model => {
  const meta = $meta(model)
  if (meta.new) meta.deleted = true

  // remove all the values
  Object.keys(model).forEach(k => delete model[k])

  // restore all the orig values
  Object.keys(meta.orig).forEach(k => (model[k] = meta.orig[k]))

  meta.prev = {}
  meta.changes = {}

  Object.values(model)?.forEach(
    prop => prop && isObject(prop) && !isDate(prop) && cancelChanges(prop)
  )

  return model
}

const addRow = (model, item) => {
  const meta = $meta(model)
  const reci = model.length
  model[reci] = item
  const recMeta = initModel(item, reci.toString(), ((meta.schema ??= {}).itemSchema ??= {}), meta)
  recMeta.new = true
  recMeta.reci = reci
  return item
}

const isNewBlank = model => {
  if (!model) return false
  const meta = $meta(model)
  return meta.new && Object.keys(meta.changes).length === 0
}

/**
 * deletes or undeletes record
 * @param {Object} model
 */
const markDeleted = (model, value = true) => {
  const meta = $meta(model)
  meta.deleted = value
}

module.exports = {
  react,
  initModel,
  hasChanges,
  $meta,
  addRow,
  cancelChanges,
  isNewBlank,
  schemaFields,
  markDeleted
}

const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { modelBind } = require('./data-model')

// const endsWith = (s, sEnding) => s.substr(-1 * sEnding.length) === sEnding

const calcAgs = (agType, data, fieldName) => {
  let i,
    v,
    r = 0
  if (agType === 'count') return data.length
  if (agType === 'sum') {
    for (i = 0; i < data.length; i++) {
      v = typeof fieldName === 'function' ? fieldName(data[i]) : _v(data[i], fieldName)
      if (v || v === 0) r += v
    }
    return r
  }
  if (agType === 'avg') return data.length ? calcAgs('sum', data, fieldName) / data.length : 0
  if (agType === 'min') {
    r = 999999999999999
    for (i = 0; i < data.length; i++) {
      v = typeof fieldName === 'function' ? fieldName(data[i]) : _v(data[i], fieldName)
      if (v || v === 0) r = Math.min(r, v)
    }
    return r
  }
  if (agType === 'max') {
    r = -999999999999999
    for (i = 0; i < data.length; i++) {
      v = typeof fieldName === 'function' ? fieldName(data[i]) : _v(data[i], fieldName)
      if (v || v === 0) r = Math.max(r, v)
    }
    return r
  }
}

const agCell = (agType, field, props = {}) => {
  const opts = Object.assign(
    {
      field,
      type: 'number',
      ag: agType,
      format: agType === 'count' ? 'n0' : 'n',
      template(data, grid) {
        const span = qc('span.static-value.ag').bindState(function () {
          this.value = calcAgs(agType, data || [], field)
          this.kids([ow.formatString(this.value, opts.format)])
        })
        if (grid.dataSet) {
          modelBind(grid.view, span, grid.dataSet)
          span.model = grid.dataSet
        }
        return span
      }
    },
    props
  )

  return opts
}

module.exports = { agCell }

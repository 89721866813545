const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { dates } = require('../dates')
const { htmlEncode } = require('../html-encode')
const { _v } = require('../ow0/core')

const __ = window.__

const typeMap = {
  char: 'string',
  bit: 'boolean',
  decimal: 'number',
  integer: 'number',
  float: 'number',
  currency: 'number',
  date: 'date',
  datetime: 'date',
  time: 'string',
  timeString: 'string',
  int: 'number'
}

const edTypeMap = {
  char: 'text',
  bit: 'check',
  boolean: 'check',
  decimal: 'float',
  integer: 'int',
  float: 'float',
  currency: 'float',
  date: 'date',
  datetime: 'datetime',
  time: 'time',
  weekdays: 'weekdays',
  timeString: 'timeString'
}

const filterOperators = {
  boolean: {
    eq: __('FILTERCELL:NUMBER:eq')
  },
  string: {
    contains: __('FILTERCELL:STRING:contains'),
    startswith: __('FILTERCELL:STRING:startswith'),
    eq: __('FILTERCELL:NUMBER:eq'),
    neq: __('FILTERCELL:NUMBER:neq'),
    doesnotcontain: __('FILTERCELL:STRING:doesnotcontain'),
    endswith: __('FILTERCELL:STRING:endswith')
  },
  number: {
    eq: __('FILTERCELL:NUMBER:eq'),
    neq: __('FILTERCELL:NUMBER:neq'),
    gte: __('FILTERCELL:NUMBER:gte'),
    gt: __('FILTERCELL:NUMBER:gt'),
    lte: __('FILTERCELL:NUMBER:lte'),
    lt: __('FILTERCELL:NUMBER:lt')
  },
  date: {
    eq: __('FILTERCELL:NUMBER:eq'),
    neq: __('FILTERCELL:NUMBER:neq'),
    gte: __('FILTERCELL:DATETIME:gte'),
    gt: __('FILTERCELL:DATETIME:gt'),
    lte: __('FILTERCELL:DATETIME:lte'),
    lt: __('FILTERCELL:DATETIME:lt'),
    sameDay: __('FILTERCELL:DATETIME:sameDay')
  },
  datetime: {
    eq: __('FILTERCELL:NUMBER:eq'),
    neq: __('FILTERCELL:NUMBER:neq'),
    gte: __('FILTERCELL:DATETIME:gte'),
    gt: __('FILTERCELL:DATETIME:gt'),
    lte: __('FILTERCELL:DATETIME:lte'),
    lt: __('FILTERCELL:DATETIME:lt'),
    sameDay: __('FILTERCELL:DATETIME:sameDay'),
    sameHour: __('FILTERCELL:DATETIME:sameHour'),
    sameMinute: __('FILTERCELL:DATETIME:sameMinute')
  },
  enums: {
    eq: __('FILTERCELL:NUMBER:eq'),
    neq: __('FILTERCELL:NUMBER:neq')
  }
}

exports.filterOperators = filterOperators

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} grid.opts - grid opts
 * @param {boolean=false} grid.opts.hasFilters - if false, the column top filters aren't created
 */
exports.colsToGridColumns = function (cols, grid) {
  // Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".

  var opts = grid.opts
  if (!opts.gridPrefs) {
    //check user preferences existed
    opts.gridPrefs = _v(opts.viewdata, 'winpref.grids') || {}
    opts.gridPrefs = grid.id in opts.gridPrefs ? opts.gridPrefs[grid.id] : null
  }

  if (!opts.gridPrefs && opts.gridTemplate) {
    //check gridtemplate if user pref not found
    debugger
    var gridTemplates = JSON.parse(opts.gridTemplate)
    if (!Array.isArray(gridTemplates)) {
      // ow4 gridTemplate is array.
      opts.gridPrefs = gridTemplates[grid.id] || null
    }
  }

  function setFilters(col, kCol) {
    var isFilterable = col.filterType !== 'na'
    col.filterable = isFilterable
    if (!isFilterable) return

    col.filterType = col.filterType || col.type

    if (col.filterType !== 'boolean') col.filterType = typeMap[col.filterType] || 'string'

    if (col.filterType === 'check') col.filterType = 'boolean'

    if (col.filterable) {
      if (col.defaultOperator) {
        if (kCol.filterable?.cell) kCol.filterable.cell.operator = col.defaultOperator
      }
    }
  }

  function toKCol(col, i) {
    if (col.editable === false) {
      console.log('gridCol.editable is deprecated, please use gridCol.readOnly: true')
      debugger
    }

    col.index = i
    var kCol = {
      attributes: { class: '' },
      options: {},
      title: col.title || '',
      align: col.align || ''
    }

    if (typeof col.required !== 'undefined')
      if (col.ctl && !('required' in col.ctl)) col.ctl.required = col.required

    if (typeof col.validation === 'function') col.validation = { validateFunction: col.validation }
    col.validation = col.validation || {}

    if ('required' in col || col.ctl?.required)
      col.validation.required = col.required || col.ctl?.required

    if (col.min) col.validation.gte = col.validation.gte || col.min

    if (col.max) {
      col.validation.lte = col.validation.lte || col.max
      delete col.max
    }

    kCol.hidden = col.hidden
    if (col.width) kCol.width = col.width
    if (col.field) kCol.field = col.field
    if (col.type) kCol.type = typeMap[col.type] || col.type

    if ((kCol.field || '').indexOf('.') > -1 && !col.template && opts.unnestFields) {
      var field = kCol.field
      col.template =
        col.template ||
        function (d) {
          var v = _v(d, field)
          return v === undefined && v === null ? '' : col.format ? ow0.toString(v, col.format) : v
        }
      kCol.field = field.split('.').join('_')
      col.nestedField = kCol.field
    }

    if (kCol.type === 'boolean') {
      kCol.headerAttributes = kCol.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'center')
      }
    }
    if (col.type === 'int' || col.type === 'decimal' || col.type === 'float') {
      kCol.headerAttributes = kCol.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'right')
      }
    }
    if (col.type === 'currency') {
      kCol.headerAttributes = kCol.headerAttributes || {
        style: 'text-align: ' + (col.headerAlign || col.align || 'right')
      }
    }
    if (col.headerClass) {
      kCol.headerAttributes.class = (kCol.headerAttributes.class || '') + col.headerClass
    }

    if (col.fullTimeCheck) {
      kCol.attributes.class = (kCol.attributes.class || '') + ' full-time-edit k-edit-cell'
      // col.classes = (col.classes || '') + ' full k-textbox';
    }

    if ('command' in col && col.command.length > 0) {
      kCol.attributes.class = (col.attributes?.class || '') + ' command-cell no-tab'
    }

    if (kCol.type === 'boolean' && !col.fullTimeCheck) {
      // update boolean type string
      kCol.options.zeroString = kCol.options.zeroString || 'false'
      kCol.options.oneString = kCol.options.oneString || 'true'
      if (kCol.options.zeroString) {
        //Build display template
        col.template =
          col.template ||
          function (data) {
            var list = [kCol.options.zeroString, kCol.options.oneString]
            return list[data[kCol.field] * 1]
          }
      }
    }

    // formats
    if (col.type === 'date') col.format = col.format || dates.DateFormatSetting

    if (col.type === 'datetime') col.format = col.format || dates.DateTimeFormatSetting

    if (col.type === 'time') {
      col.format = col.format || dates.TimeFormatSetting
      kCol.attributes = kCol.attributes || {}
      kCol.attributes.class = (kCol.attributes.class || '') + ' time'
    }
    if (col.type === 'currency') col.format = col.format || 'c'

    if (col.format) {
      if (col.format[0] === '{') {
        console.log('please set column format as inner format string eg. n2 not {0:n2}')
        debugger
        col.format = col.format.split(':')[1].split('}')[0]
      }
      kCol.format = '{0:' + col.format + '}'
    }

    // staticlookup
    if (col.list) {
      col.ctl = col.ctl || {}
      col.ctl.list = col.ctl.list || col.list
      col.ctl.ctlType = 'combo'
      col.ctl.fieldName = col.ctl.fieldName || col.field
    }

    if (col.ctl?.ctlType === 'combo') {
      col.align = col.align || 'left'
      var list = col.ctl.list || []

      col.template =
        col.template ||
        function (d) {
          var v = _v(d, col.field)
          if (v === undefined) return ''

          if (d) {
            var matches = list.filter(y => _v(y, col.ctl.valueField) == v)
            if (matches[0]) return matches[0][col.ctl.textField || 'Text']
            if (typeof v === 'number') {
              v = list[parseInt(v)]
              if (typeof v === 'object') v = ''
            }
          }
          return v
        }
    }

    const isLookup = () => col.ctl?.ctlType === 'combo'

    if (!col.ctl)
      col.ctl = {
        ctlType: col.ctlType || edTypeMap[col.type] || 'text',
        fieldName: col.field,
        noWrapper: true,
        noLabel: true,
        inGrid: true
      }

    col.ctl.inGrid = true
    col.ctl.col = function () {
      return col
    }
    col.ctl.fieldName = col.ctl.fieldName || col.field
    col.ctl.noWrapper = true
    col.ctl.noLabel = true
    col.ctl.width = col.ctl.width || 'full'
    if (col.maxLength) col.ctl.maxLength = col.maxLength

    if (isLookup()) kCol.attributes.class = (kCol.attributes.class || '') + ' lookup-col'

    if (kCol.type && !isLookup()) {
      kCol.attributes.class =
        (kCol.attributes.class || '') +
        ' ' +
        kCol.type +
        '-col' +
        (col.type === 'integer' ? ' align-left' : '')
      if (kCol.align) {
        kCol.attributes.class = (kCol.attributes.class || '') + ' align-' + kCol.align
      }
    }

    // cell templates
    kCol.template = function (d) {
      function readValue(col) {
        var v = typeof col.calc === 'function' ? col.calc(d) : col.field ? _v(d, col.field) : ''
        if (col.type === 'date' || col.type === 'datetime')
          if (typeof v === 'string' && v !== '') v = ow0.parseDate(v, col.format)

        return v ?? ''
      }

      var defaultValue = readValue(col)
      if (col.format) defaultValue = ow0.toString(defaultValue, col.format)

      if (col.encoded !== false) defaultValue = htmlEncode(defaultValue)

      // col.format is 'XXX', kCol.format is '{0:XXX}'
      var val = col.template ? col.template(d, defaultValue) : defaultValue

      var classes = col.classes
        ? typeof col.classes === 'function'
          ? col.classes(d)
          : col.classes
        : ''

      if (typeof val === 'string' && !col.encoded) val = html(val)
      val = val ?? []

      return (typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly)
        ? qc('span.read-only', val)
        : qc('span' + (classes ? '.' + classes.split(' ').join('.') : ''), val)
    }

    // header
    if (col.headerAlign || col.align) {
      kCol.headerAttributes = kCol.headerAttributes || {
        style: 'text-align: ' + col.headerAlign || col.align
      }
    }

    const wrapFooter = content =>
      qc('div.col-' + col.index, content).css({
        textAlign: col.footerAlign || kCol.align || 'right'
      })

    // footer
    if (col.groupFooter) {
      if (typeof col.groupFooter === 'string') {
        let ag = col.footer
        col.groupFooter = function (d) {
          // FROM R1: if (d[col.field]) return d[ag](col.field, d[col.field] && d[col.field].group)
          // FROM R2: if (d[col.field]) return grid[ag](col.field, d[col.field] && d[col.field].group)
          if (d[col.field])
            return (d[ag] ? d[ag] : grid[ag])(col.field, d[col.field] && d[col.field].group)
        } // eg. g.sum('Quantity', group)
      }

      if (typeof col.groupFooter === 'function')
        col.groupFooterTemplate = function (d) {
          return wrapFooter(ow0.toString(col.groupFooter(d), col.format))
        }
    }

    if (col.footer) {
      if (typeof col.footer === 'string') {
        // let ag = col.footer
        // col.footer = d => d[ag](col.field) // eg. g.sum('Quantity')
      }

      if (typeof col.footer === 'function') {
        col.footerTemplate = function (d) {
          return wrapFooter(ow0.toString(col.footer(d), col.format))
        }
      }
    }

    col.refreshFooter = function () {
      if (col.footerTemplate) {
        const footerCell = $(grid).find('.k-footer-template td div.col-' + col.index)[0]
        if (!footerCell) return
        const content = col.footerTemplate({})
        qc(footerCell).kids(typeof content === 'string' ? html(content) : content)
      }
    }
    // filters
    if (opts.noFilters !== true) setFilters(col, kCol)

    col.kCol = kCol

    kCol.defaultWidth = col.defaultWidth || col.width
    return kCol
  }

  var result = cols.filter(x => x.gridCol !== false).map(toKCol)

  return result
}

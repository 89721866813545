const { _v } = require('../_v')
const { qc } = require('../cmp/qc')
const { dataBind } = require('./databind7')
const { rs } = require('./rs7')

const imageToStr64 = data => {
  var strOfChar = ''
  var bufferArray = data
  var typeArray
  var sizeToChunck = 100000
  var numOfSeparation = Math.ceil(bufferArray.length / sizeToChunck)
  for (let i = 0; i < numOfSeparation; i++) {
    typeArray = new Uint8Array(bufferArray.splice(0, sizeToChunck))
    strOfChar += String.fromCharCode.apply(null, typeArray)
  }
  return btoa(strOfChar)
}

const base64toHEX = base64 => {
  var raw = atob(base64)
  var HEX = ''
  for (let i = 0; i < raw.length; i++) {
    var _hex = raw.charCodeAt(i).toString(16)
    HEX += _hex.length === 2 ? _hex : '0' + _hex
  }
  return HEX.toUpperCase()
}

exports.picturebox7 = opts => {
  const allowedImageTypes = opts.accept?.length
    ? opts.accept.map(text => text.toLowerCase())
    : ['jpg', 'jpeg']

  let imgContainer = qc('img')
    .addClass(opts.classes ?? '')
    .attr({ alt: '' })

  const me = qc('div.picturebox', [
    qc('div.k-button.k-upload-button', [
      qc('input.btn-sel')
        .attr({
          type: 'file',
          name: 'files',
          accept: allowedImageTypes.map(attr => `.${attr}`).join(', ')
        })
        .on('change', e => {
          let input = e.target
          var file = input.files[0]
          if (!file) return

          var imageIdentifier = /^image\//
          if (!imageIdentifier.test(file.type))
            return ow0.popError(
              __('Error'),
              `${__('Fileshouldbeoneoftheallowedimagetypes')}: ${opts.accept?.join(', ')}.`,
              5000
            )

          const imageType = file.type.split('/')[1].toLowerCase()
          if (imageType && !allowedImageTypes.includes(imageType.toLowerCase()))
            return ow0.popError(
              __('Error'),
              `${__('Fileshouldbeoneoftheallowedimagetypes')}: ${opts.accept?.join(', ')}.`,
              5000
            )

          var fileSize = file.size / 1024 / 1024
          if (opts.maxFileSize && fileSize > opts.maxFileSize)
            return ow0.popError(
              __('Error'),
              `${__('Filesizecannotbegreaterthan')} ${opts.maxFileSize} ${__('MB')}.`,
              5000
            )

          me.files = input.files

          var reader = new FileReader()
          reader.onload = e => {
            me.isDirty = true
            imgContainer.el.src = e.target.result
            me.trigger('change')
          }

          reader.readAsDataURL(file)
        }),
      qc('span', [__('Select files'), '...'])
    ]),

    qc('button.btn-clear.k-button', __('Clear')).on('click', () => {
      me.isDirty = true
      me.val(null, me.model)
    }),

    imgContainer
  ]).props({
    opts,
    populate(model) {
      me.isDirty = false
      var v = _v(model, opts.fieldName)
      me.val(v, model, true)
    },
    readData(rec) {
      var v = me.val(undefined, rec)
      if (me.isDirty && v === '') rec.isDirty = true
      _v(rec, opts.fieldName, me.val())
      return v
    },
    val(v, model, populating = false) {
      if (populating) me.model = model

      if (typeof v !== 'undefined') {
        var hasChanged = false

        if (v === imgContainer.el.src) return v

        const addPrefix = s => {
          var hex = base64toHEX(s.substr(0, 8)).slice(0, 4)
          if (hex === 'FFD8') s = 'data:image/jpeg;base64,' + s
          else if (hex === '424D') s = 'data:image/bmp;base64,' + s
          else if (hex === '4749') s = 'data:image/gif;base64,' + s
          else if (hex === '8950') s = 'data:image/png;base64,' + s
          return s
        }
        const convertToHexData = v => {
          var s = imageToStr64(v[0].Pic.data)
          return addPrefix(s)
        }
        if (Array.isArray(v) && me.model.length) v = convertToHexData(v)

        if (v === null) {
          hasChanged = !imgContainer.el.src || imgContainer.el.src.length < 30
          imgContainer.el.src = 'data:image/jpeg;base64,'
          if (me.model?.[opts.fieldName]) {
            hasChanged = true
            delete me.model[opts.fieldName]
          }
        } else {
          if (v) {
            const afterLoadBlob = data => {
              if (
                data === '' ||
                data === 'data:image/jpeg;base64,' ||
                (typeof data === 'object' && !data?.size)
              ) {
                imgContainer.el.src = 'data:image/jpeg;base64,'
                return
              }
              imgContainer.el.src = URL.createObjectURL(data)

              // newer promise based version of img.onload
              return imgContainer.el.decode().then(() => URL.revokeObjectURL(imgContainer.el.src))
            }

            if (typeof v === 'string' && v.slice(0, 5) !== 'data:')
              $ajax({ method: 'post', url: v }).then(afterLoadBlob)
            else afterLoadBlob(v)
          }
        }
        if (hasChanged && !populating) me.trigger('change')
      }

      if (imgContainer.el.src === 'data:image/jpeg;base64,') return imgContainer.el.src
      if ((imgContainer.el.src || '').substr(0, 5) !== 'data:') return null

      // read from control
      return imgContainer.el.src
    }
  })

  dataBind(me)

  me.wrap = () => me

  const _rs = rs({ label: opts.label }, me).addClass('multi-line').props({ input: me })

  me.rs = () => opts.rs ?? _rs

  return me
}

const { _v } = require('../_v')
const { qc } = require('../cmp/qc')
const { readServerFilterControls, readClientFilterControls } = require('./databind7')
const { cancelChanges, initMeta, $meta } = require('./dc7')

const buildGroupRows = (recs, currentFilter, fields, ags = {}, groups = {}) => {
  if (!Array.isArray(fields)) fields = [fields]

  currentFilter.groups = groups
  const data = []

  const addGroupFooterRecord = group => {
    group = JSON.parse(JSON.stringify(group))
    group._group.footer = true
    // console.log('GroupFooterRow: ', group._group)
    data.push(group)
  }

  let lastGroup, sKey, group, rec, i

  for (i = 0; i < recs.length; i++) {
    rec = recs[i]
    if (rec._group) console.log('discarding: ', i)

    if (!rec._group) {
      group = { _meta: { rowi: -1 } }
      sKey = fields
        .map(f => {
          group[f] = _v(rec, f)
          return group[f]
        })
        .map(v => {
          if (v === undefined) v = ''
          if (v === null) v = 'null'
          return v.toString()
        })
        .filter(s => s)
        .join('&')

      group._group = { open: true, key: sKey, ags: { count: 0 } }

      // is it in the same group?
      if (lastGroup && sKey === lastGroup._group.key) {
        group = lastGroup
      } else {
        // it's different
        if (lastGroup) addGroupFooterRecord(lastGroup) // footer row

        if (!groups[sKey]) {
          groups[sKey] = group
        } else {
          // console.warn('Reusing the existing group ', sKey)
          group = groups[sKey]
          group._group.ags = { count: 0 } // reset ags
        }

        data.push(group) // headerRow
      }

      lastGroup = group
      // ags are registered in column definitions,
      // eg. groupFooter: 'sum'
      group._group.ags.count++
      let count = group._group.ags.count
      Object.keys(ags).forEach(f => {
        const agType = typeof ags[f] === 'string' ? [ags[f]] : ags[f]

        group._group.ags[f] = group._group.ags[f] ?? {}

        const v = _v(rec, f)

        if (agType.includes('sum')) group._group.ags[f].sum = (group._group.ags[f].sum || 0) + v

        if (agType.includes('avg'))
          group._group.ags[f].avg = ((count - 1) * (group._group.ags[f].avg || 0) + v) / count

        if (agType.includes('max'))
          if (group._group.ags[f].max !== undefined) group._group.ags[f].max = v
          else group._group.ags[f].max = Math.max(v, group._group.ags[f].max)

        if (agType.includes('min'))
          if (group._group.ags[f].min !== undefined) group._group.ags[f].min = v
          else group._group.ags[f].min = Math.min(v, group._group.ags[f].min)
      })

      $meta(rec).group = group
      $meta(rec).groupKey = group._group.key
      data.push(rec)
    }
  }

  if (lastGroup) addGroupFooterRecord(lastGroup)

  currentFilter.recs = data
  return currentFilter
}

const applyGroupVisibility = currentFilter => {
  const { includeDeletes = true, showGroupHeaders = true, showGroupFooters = true } = currentFilter

  // filteredRecs is the result of the filters
  //   before applyingGroup
  currentFilter.filterRecs = currentFilter.filterRecs || currentFilter.recs

  currentFilter.recs = currentFilter.filterRecs.filter(r => {
    // const rowi = $meta(r).rowi
    if (r._group) {
      let group = r._group
      if (!group.footer && !showGroupHeaders) return false
      if (group.footer && !showGroupFooters) return false
    } else {
      delete $meta(r).filterIndex
      let open = $meta(r).group ? $meta(r).group._group.open : true
      if (open === false) return false
      if (!includeDeletes && $meta(r).deleted) return false
    }

    return true
  })

  // rebuild the index
  currentFilter.recs.forEach((r, i) => ($meta(r).filterIndex = i))

  return currentFilter
}

const collectionController7Props = {
  populate(data) {
    this.opts.view.qTop.trigger('ow-populate', this, data)
  },

  saveCancelState() {
    var o = this

    var hasChanged = o.hasChanges()

    var broadcast = hasChanged !== o._dirty
    this._dirty = hasChanged

    const dsName = o.opts.dsName

    var state = {
      dataController: o,
      dsName,
      editing: hasChanged
    }

    // Look for save and cancel buttons bound to this grid
    function broadcastStateChange(el) {
      state.called = el
      qc(el).trigger('ow-datastatechange', state)
    }

    if (broadcast) {
      if (o.opts.top) {
        no$(o.opts.top)
          .find(
            `[data-field-for="${dsName}"],[data-target-ref="${dsName}"],[data-filter-for="${dsName}"]`
          )
          .forEach(el => broadcastStateChange(el))
        if (o === o.opts.top.dc) broadcastStateChange(o.opts.top)
      }
    }
  },

  hasChanges() {
    return this._numChanges > 0
  },

  async load(noConfirmation) {
    const me = this

    if (!noConfirmation && me.hasChanges())
      return common
        .confirm(__('Refresh'), __('Are you sure you want to discard changes and reload'))
        .then(ok => ok && me.load(true))

    const dc = me
    const { opts } = me

    let data = {}

    if (opts.hasFilters) {
      data.filter = {}
      data.filter = { filters: [] }
      readServerFilterControls(opts.top, opts.dsName, data.filter)
      data.filter = JSON.parse(JSON.stringify(data.filter)) // ensures correct Date string format for server.
    }

    var url = dc.url || opts.baseURL
    var base = url.split('?')[0]
    var qry = url.split('?')[1] ? url.split('?')[1].split('&') : []
    url = base + '?' + qry.join('&')

    if (dc.paging) {
      data.limit = dc.paging.pageSize
      data.skip = (dc.paging.page - 1) * dc.paging.pageSize
    }

    if (dc.sort) data.sort = dc.sort.map(s => [s[0], s[1] ? 'asc' : 'desc'])

    return $ajax({
      view: opts.view,
      url,
      data,
      LRTask: opts.LRTask ?? true,
      showProgress: opts.showProgress ?? true
    })
      .then(response => {
        if (dc.paging) dc.paging.totalRecs = response.total
        dc.response = response
        dc.aggregates = response.aggregates
        dc.recs = response.data

        dc.recs.forEach((rec, rowi) => {
          rec = opts.prePopulate?.(rec) ?? rec
          dc.initMeta(rec, rowi)
        })

        // client FSP
        dc.currentFilter = dc.refilter()
        dc.populate(dc.currentFilter.recs)
        dc.saveCancelState()
      })
      .catch(err => {
        console.error('load returned error', err)
        ow.popError(__('load returned error'), err.errMsg ?? err)
      })
  },

  cancelRowChanges(rowi) {
    const rec = this.recs[rowi]

    if ($meta(rec).new) {
      this.deleteRow(rec)
      this.refilter()
    }
    cancelChanges(rec)
  },

  cancelChanges() {
    // todo: go through all recs and cancel meta changes, restore original.
    Object.keys(this._changes).forEach(srowi => {
      const rowi = parseInt(srowi)
      this.cancelRowChanges(this.recs[rowi])
    })
    this._changes = {}
    this._numChanges = 0
  },

  // for override
  filterRec(d, i, filters) {
    for (var j = 0; j < (filters || []).length; j++) {
      var f = filters[j]
      if (this.applyStandardClientFilter(d, i, f) === false) return false
    }
    return true
  },

  recSorter(sorts) {
    return function (a, b) {
      if (sorts.length === 0) return 0

      let f, i, av, bv, result

      for (i = sorts.length - 1; i >= 0; i--) {
        f = sorts[i][0]

        if (typeof f === 'function') {
          av = f(a) || 0
          bv = f(b) || 0
        } else {
          av = _v(a, f) || 0
          bv = _v(b, f) || 0
        }

        if (typeof av === 'string') av = av.toLowerCase()
        if (typeof bv === 'string') bv = bv.toLowerCase()

        if (av !== bv) {
          result = sorts[i][1] ? av > bv : bv > av
          return result ? 1 : -1
        }
      }
      return 0
    }
  },

  applyStandardClientFilter(r, i, f) {
    var v = _v(r, f.field)
    const s = ((v ?? '') + '').toLowerCase()
    const sF = ((f.value ?? '') + '').toLowerCase()

    if (f.operator === null || f.operator === 'null') {
      if (f.value === true) if (!(v === undefined || v == null)) return false
      return true
    }
    if (f.operator === 'notnull') {
      if (f.value === true) if (v === undefined || v == null) return false
      return true
    }
    if (f.operator === 'neq') return s !== sF
    if (f.operator === 'eq') return s === sF
    if (f.operator === 'contains') return s.indexOf(sF) !== -1
    if (f.operator === 'doesnotcontain') return s.indexOf(sF) === -1
    if (f.operator === 'startswith') return s.indexOf(sF) === 0
    if (f.operator === 'endswith') return s.endsWith(sF)

    return true
  },

  applyGroupVisibility() {
    return applyGroupVisibility(this.currentFilter)
  },

  // filtering
  applyClientFilterSort(
    recs,
    filters,
    sorts,
    includeDeletes,
    groupBy,
    showGroupHeaders = this.showGroupHeaders,
    showGroupFooters = this.showGroupFooters
  ) {
    const dc = this

    const result = {
      filters,
      sorts,
      groupBy,
      recs: [],
      showGroupHeaders,
      showGroupFooters
    }

    dc.currentFilter = result

    let filterLength = 0
    result.recs = recs.filter(r => {
      const incl = (!includeDeletes || !$meta(r).deleted) && dc.filterRec(r, filterLength, filters)
      if (incl) filterLength++
      return incl
    })

    if (sorts) result.recs.sort(this.recSorter(sorts))

    if (groupBy) {
      result.recs.sort(
        this.recSorter([
          [groupBy, 1],
          [x => !x.BOSupplierProduct, 1] // has Item - what is this doing here!
        ])
      )
      buildGroupRows(result.recs, result, groupBy, dc.opts.ags || {}, dc.groups)
      dc.groups = result.groups
      applyGroupVisibility(dc.currentFilter)
    }

    // rebuild the index
    result.recs.forEach((row, fi) => ($meta(row).filterIndex = fi))
    return result
  },

  refilter() {
    const { opts, recs } = this

    // get the grid client filters
    const filters = readClientFilterControls(opts.view.qTop.el, opts.dsName)

    return this.applyClientFilterSort(
      recs,
      filters,
      this.sort,
      opts.showDeletedRows,
      opts.groupBy,
      opts.showGroupHeaders,
      opts.showGroupFooters
    )
  },

  initMeta(rec, i) {
    return initMeta(rec, i, this.opts.reactFields)
  }
}

/**
 * Maintains arrays of records, change control, client filters etc.
 *
 * @param {object} opts
 * @param {view} opts.view - required
 * @param {HTMLElement} opts.top - required
 * @returns {object} collectionController
 */
const collectionController7 = opts => {
  if (!opts.view) throw 'collectionController7 requires the view object'
  if (!opts.dsName) throw 'collectionController7 requires opts.dsName'
  opts.top = opts.view.qTop.el

  const me = Object.create(collectionController7Props)
  me.opts = opts
  me.recs = []
  me.currentFilter = { recs: me.recs }

  me.sort = opts.sort ?? []
  if (opts.sorts) throw 'collectionController7.opts.sorts is not correct, use sort'

  if (opts.paging) me.paging = opts.paging

  me.opts.hasFilters = me.opts.hasFilters !== false

  // if (me.opts.hasFilters !== false) filterController7(me.opts.top, me.opts)

  me._changes = {} // = { "3": true }
  me._numChanges = 0

  me.saveCancelState()

  return me
}

module.exports.collectionController7 = collectionController7

const MISC_RP_ROUNDMETHOD = 4
const RM_NO_ROUNDING = 0
const RM_ROUND_TO = 1
const RM_ROUND_UP = 2
const RM_ROUND_DOWN = 3
const RM_TRIM_TO = 5
const RM_TRIM_UP = 6
const RM_TRIM_DOWN = 7
const EPSILON = 0.000001

const sameValue = (A, B, Epsilon) =>
  A > B ? A - B <= (Epsilon || EPSILON) : B - A <= (Epsilon || EPSILON)

const frac = f => f % 1

const delphiRoundUp = val => Math.trunc(val) + Math.trunc(frac(val) * 2)

function roundingMethod(paramValue, roundMethod, decimalSep) {
  roundMethod = Number(roundMethod)
  decimalSep = decimalSep || '.'

  return roundMethod === 1
    ? roundMethod1(paramValue)
    : roundMethod === 2
      ? roundMethod2(paramValue)
      : roundMethod === 3
        ? roundMethod3(paramValue)
        : paramValue

  function roundMethod1(v) {
    var sPound = ''
    var sPrice = roundNumber(v).toFixed(2)
    var sPence = '49'
    if (sPrice.split(decimalSep).length > 1) {
      sPence = sPrice.split(decimalSep)[1]
      sPound = sPrice.split(decimalSep)[0]
      sPence = parseInt(sPence) < 50 ? '49' : '99'
    }
    return parseFloat(sPound + decimalSep + sPence)
  }

  function roundMethod2(v) {
    var sPound = ''
    var sPrice = roundNumber(v).toFixed(2)
    var iLen = sPrice.length
    var sP2 = '9'
    var sP1 = sPrice[iLen - 2]

    for (let index = 1; index < iLen - 1; index++) {
      sPound = sPound + sPrice[index - 1]
    }

    if (v >= 1 && v < 10) {
      if (parseInt(sP1) % 2 === 0) {
        sP1 = (parseInt(sP1) + 1).toString()
      }
    } else if (v >= 10 && v <= 20) {
      sP1 = parseInt(sP1) <= 4 ? '4' : '9'
    } else if (v >= 20) {
      sP1 = '9'
    }

    return parseFloat(sPound + sP1 + sP2)
  }

  function roundMethod3(v) {
    var orgVal = v

    var dResult = roundEx(Math.abs(v), 2)
    var iVal = Math.round(frac(dResult) * 100)

    if (iVal >= 0 && iVal <= 24) {
      dResult = parseInt(dResult)
    } else if (iVal >= 25 && iVal <= 74) {
      dResult = parseInt(dResult) + 0.5
    } else if (iVal >= 75 && iVal <= 99) {
      dResult = parseInt(dResult) + 1
    }

    return orgVal < 0 ? dResult * -1 : dResult
  }
}

function roundEx(v, iDecimal, iMaxRound) {
  if (iDecimal >= 20) return v

  if (iDecimal > iMaxRound) iDecimal = iMaxRound

  var iRounding = Math.round(Math.pow(10, iDecimal))

  return delphiRoundUp(iDecimal > 0 ? (v * iRounding) / iRounding : v)
}

function findBaseUnitConvert(FromUnitID, ToUnitID) {
  var resFactor = 1
  if (FromUnitID !== ToUnitID) {
    var res = financial.unitconverts.find(
      r => r.FromUnitID === FromUnitID && r.ToUnitID === ToUnitID
    )
    if (res) resFactor = sameValue(res.Factor, 0, 0.0000000001) ? 1 : res.Factor
    else {
      res = financial.unitconverts.find(r => r.FromUnitID === ToUnitID && r.ToUnitID === FromUnitID)
      if (res) {
        resFactor = 1 / res.Factor
        resFactor = sameValue(resFactor, 0, 0.00000000001) ? 1 : resFactor
      }
    }
  }

  return resFactor
}

const calcPriceToMargin = (price, vat, ucp) => {
  price = price || 0
  var priceNoVat = sameValue(price, 0, 0.000001) ? price / (1 + vat / 100) : price
  return sameValue(ucp, 0, 0.00001) ? 0 : roundEx((priceNoVat / ucp - 1) * 100, 2)
}

const calcMarkup = (price1, price2, price3, vatPct, ucp) => ({
  margin1: calcPriceToMargin(price1, vatPct, ucp),
  margin2: calcPriceToMargin(price2, vatPct, ucp),
  margin3: calcPriceToMargin(price3, vatPct, ucp)
})

const roundNumber = (rnum, rlength = 2) =>
  parseFloat(Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength))

function calcUom(packUnitId, compareUnitId, sellingPrice, packUom, compareUom) {
  var result = 0
  if (!sameValue(sellingPrice, 0, 0.0000001)) {
    if (!sameValue(packUom, 0, 0.000001)) {
      var factor = findBaseUnitConvert(packUnitId, compareUnitId)
      result = sellingPrice * ((factor / packUom) * compareUom)
    }
  }
  return roundNumber(result, 4)
}

const financial = {
  test: roundingMethod,
  unitconverts: [],
  roundmethods: [],
  Indicators: ['UNIT_COST_PRICE', 'MARGIN', 'PRICE_PRE_VAT', 'SELLING_PRICE'],
  calcForm(params) {
    //variables
    var _UnitID
    var _CaseQty,
      _CaseCostPrice = 0
    var _UOM

    var result = {
      ResCode: 99,
      ResMessage: 'Unknown error',
      UnitCostPrice: 0,
      NewMargin: 0,
      NewPriceExclVAT: 0,
      NewSellingPrice: 0,
      NewMargin1: 0,
      NewMargin2: 0,
      NewMargin3: 0,
      NewUofMPrice: 0
    }
    var _UCP
    var _Margins //dMargin1,2,3
    var _NewSellingPrice, _NewMargin, _NewPriceExclVAT, _NewUofMPrice
    var _Indicator

    var _FRoundingMethods = { frmList: [] }
    var _FProducts = {}

    _FProducts.calcBaseUnitCostPrice = function (
      caseCostPrice,
      caseQty,
      orderUom,
      stockUom,
      baseUnitId,
      orderUnitId,
      stockUnitId
    ) {
      //FProducts.fCalcBaseUnitCostPrice(sdSQL, False, dCaseCostPrice, dCaseQty, dUOM, dStockUOM, sBaseUnitID, sUnitID, sStockUnitID, dBaseUCP, dStockUCP);
      var dBaseUCP, dStockUCP
      var dStockUOM = stockUom
      caseQty = sameValue(caseQty, 0, 0.000001) ? 1 : caseQty
      orderUom = sameValue(orderUom, 0, 0.000001) ? 1 : orderUom
      dStockUOM = sameValue(dStockUOM, 0, 0.000001) ? 1 : dStockUOM

      var dValue = caseCostPrice / caseQty
      //dValue = roundNumber(dValue, 16);
      dStockUCP = uom.UOMConvertFromBaseUnit(baseUnitId, stockUnitId, dStockUOM, dValue)
      dBaseUCP =
        orderUnitId !== ''
          ? uom.UOMConvertFromBaseUnit(baseUnitId, orderUnitId, orderUom, dValue)
          : dStockUCP

      return { stockUcp: dStockUCP, baseUcp: dBaseUCP }
    }

    function updatingRoundingMethods() {
      //TRPRoundingMethods.create
      if (financial.roundmethods?.length <= 0)
        financial.roundmethods.push({
          Id: 0,
          Description: 'No Rounding',
          Algorithm: RM_NO_ROUNDING,
          iPreVat: 0,
          DecimalPointPos: 0,
          Val1: 0,
          Val2: 0,
          Val3: 0,
          Val4: 0,
          ValCnt: 0,
          PreVat: false,
          Number: 0
        })

      financial.roundmethods?.forEach(rm => {
        var instance = {
          Id: rm.Number,
          Description: rm.sValue1 || '',
          Algorithm: rm.iValue1 || RM_NO_ROUNDING,
          iPreVat: rm.iValue2,
          DecimalPointPos: rm.iValue3 || 0,
          Val1: 0,
          Val2: 0,
          Val3: 0,
          Val4: 0,
          ValCnt: 0,
          PreVat: rm.iValue2 === 0 ? false : true
        }

        if ([RM_ROUND_TO, RM_ROUND_UP, RM_ROUND_DOWN].indexOf(instance.Algorithm) >= 0) {
          instance.Val1 = rm.dValue1
        } else if ([RM_TRIM_TO, RM_TRIM_UP, RM_TRIM_DOWN].indexOf(instance.Algorithm) >= 0) {
          instance.Val1 = rm.dValue1
          instance.Val2 = rm.dValue2
          instance.Val3 = rm.dValue3
          instance.Val4 = rm.dValue4
          instance.ValCnt = rm.iValue4
        }

        var oRounding = rounding.rpRounding(instance)
        _FRoundingMethods.frmList.push(oRounding)
      })

      _FRoundingMethods.Round = function (
        roundMethod,
        indicator,
        unitCostPrice,
        vatPct,
        sellingPrice = 0,
        priceExclVat = 0,
        marginPct
      ) {
        var result = {
          newSellingPrice: sellingPrice || 0,
          newPriceExclVat: priceExclVat,
          newMargin: marginPct || 0
        }
        _FRoundingMethods.frmList?.forEach(r => {
          if (roundMethod === 0 && _FRoundingMethods.frmList.some(fl => fl.FID !== 0)) return
          if (r.FID === roundMethod) {
            var newResult = r.Round(
              indicator,
              unitCostPrice,
              vatPct,
              sellingPrice,
              priceExclVat,
              marginPct
            )
            if (newResult) {
              result.newSellingPrice = newResult.sellingPrice || sellingPrice
              result.newPriceExclVat = newResult.priceExclVat || priceExclVat
              result.newMargin = newResult.marginPct
            }
            return false
          }
        })

        return result
      }
    }
    updatingRoundingMethods()

    if (!params.PerformRoundingCalc) {
      result.ResCode = 0
      return result
    }

    //maintain this logic, in case using API call used
    if (params && params.FSupplierInfoData) {
      var mainSupp = params.FSupplierInfoData.find(function (f) {
        return f.PrimarySupplier === '0'
      }) //delphi check invert
      if (!mainSupp) {
        result.ResMessage = 'No primary supplier found'
        return result
      }
      _CaseQty = mainSupp.CaseQuantity
      _CaseCostPrice = mainSupp.CaseCostPrice
    } else {
      result.ResMessage = 'No supplier found'
      return result
    }

    if (!params.SaleUOMUnitID && params.SaleUOMUnitID !== '') {
      result.ResCode = 0
      result.ResMessage = ''
      return result
    }

    _NewSellingPrice = params.SellingPrice
    if (params.RoundingMethod > 0) {
      if (_NewSellingPrice > 0) {
        _NewSellingPrice = roundingMethod(_NewSellingPrice, params.RoundingMethod)
      }
    }

    var _UnitCostPrice = _CaseQty !== 0 ? _CaseCostPrice / _CaseQty : 0
    if (mainSupp.UseStkUnitInGR || params.OrderUnitID === '') {
      _UnitID = params.StockUnitID
      _UOM = params.StockUOM
    } else {
      _UnitID = params.OrderUnitID
      _UOM = params.OrderUOM
    }

    _UCP = _FProducts.calcBaseUnitCostPrice(
      _CaseCostPrice,
      _CaseQty,
      _UOM,
      params.StockUOM,
      params.BaseUnitID,
      _UnitID,
      params.StockUnitID
    )
    _UCP.baseUcp = uom.UOMConvertToBaseUnit(
      params.BaseUnitID,
      params.SaleUOMUnitID,
      params.SaleUOM,
      _UCP.baseUcp
    )
    _UnitCostPrice = _UCP.baseUcp
    _NewMargin = params.Margin
    _NewPriceExclVAT = params.PriceExclVAT

    _Indicator = financial.Indicators.indexOf(params.Indicator)

    var newRoundObj = _FRoundingMethods.Round(
      params.RoundingMethod * 1,
      _Indicator,
      _UnitCostPrice,
      params.VATPCT,
      _NewSellingPrice,
      _NewPriceExclVAT,
      _NewMargin
    )
    if (newRoundObj) {
      _NewSellingPrice = newRoundObj.newSellingPrice
      _NewPriceExclVAT = newRoundObj.newPriceExclVat
      _NewMargin = newRoundObj.newMargin
    }

    if (_NewSellingPrice <= 0) {
      _NewSellingPrice = params.SellingPrice
    }

    result.UnitCostPrice = _UnitCostPrice
    result.NewMargin = _NewMargin
    result.NewPriceExclVAT = _NewPriceExclVAT
    result.NewSellingPrice = _NewSellingPrice

    _Margins = calcMarkup(
      params.Price1,
      params.Price2,
      params.Price3,
      params.VATPCT,
      _UnitCostPrice
    )
    _NewUofMPrice = calcUom(
      params.PackUnitID,
      params.ComparisonUnitID,
      _NewSellingPrice,
      params.PackUOM,
      params.ComparisonUOM
    )
    // fCalcMarkup (dPrice1, dPrice2, dPrice3, dVatPCT, dUnitCostPrice,  dMargin1, dMargin2, dMargin3 );
    // fCalcUOM (sdSQL, sPackUnitID ,sComparisonUnitID , dNewSellingPrice, dPackUOM , dComparisonUOM , dUofMPrice);

    result.NewMargin1 = _Margins.margin1
    result.NewMargin2 = _Margins.margin2
    result.NewMargin3 = _Margins.margin3
    result.NewUofMPrice = sameValue(_NewUofMPrice, 0, 0.000001) ? 0 : roundEx(_NewUofMPrice, 10)
    result.ResCode = 0
    result.ResMessage = 'OK'

    return result
  }
}

const uom = {
  UOMConvertFromBaseUnit(BaseUnitID, UnitID, UOMValue, dValue) {
    UOMValue = sameValue(UOMValue, 0, 0.000001) ? 1 : UOMValue
    if (UnitID === BaseUnitID) return dValue / UOMValue
    const UOMFactor = findBaseUnitConvert(UnitID, BaseUnitID)
    return (dValue / UOMValue) * UOMFactor
  },
  UOMConvertToBaseUnit(BaseUnitID, UnitID, UOMValue, dValue) {
    UOMValue = sameValue(UOMValue, 0, 0.000001) ? 1 : UOMValue

    if (UnitID === BaseUnitID) return dValue * UOMValue

    const UOMFactor = findBaseUnitConvert(UnitID, BaseUnitID)
    return (dValue * UOMValue) / UOMFactor
  }
}

const rounding = {
  rounding(Algoritm, RoundTo, DecimalPointPos, TrimList) {
    var instance = {
      TMethod: 0,
      TRoundTo: 0.0,
      TTrimList: [],
      TValue: 0.0,
      TRoundValue: 0.0,
      TDigitPos: 0,
      nLower: 0.0,
      nHigher: 0.0,
      bSign: false
    }

    instance.SortTrimList = function () {
      var low = 0
      var high = instance.TTrimList.length - 1
      instance.QuickSort(low, high)
    }

    instance.QuickSort = function (low, high) {
      if (low < high) {
        var pivot = Math.floor((low + high) / 2)
        pivot = instance.Partition(low, high, pivot)

        if (low < pivot) {
          instance.QuickSort(low, pivot - 1)
        }
        if (pivot < high) {
          instance.QuickSort(pivot + 1, high)
        }
      }
    }

    instance.Partition = function (low, high, pivot) {
      if (pivot !== low) {
        instance.Swap(low, pivot)
      }

      pivot = low
      low = low + 1
      while (low <= high) {
        if (instance.TTrimList[low] <= instance.TTrimList[high]) {
          low = low + 1
        } else if (instance.TTrimList[high] <= instance.TTrimList[pivot]) {
          high = high - 1
        } else {
          instance.Swap(low, high)
        }
      }

      if (high !== pivot) {
        instance.Swap(pivot, high)
      }

      return high
    }
    instance.Swap = function (i, j) {
      var k = instance.TTrimList[i]
      instance.TTrimList[i] = instance.TTrimList[j]
      instance.TTrimList[j] = k
    }
    instance.RoundTo = function () {
      instance.RoundPrep()
      var nH = instance.nHigher - instance.TValue
      var nL = instance.TValue - instance.nLower

      if (nH > nL) {
        instance.TRoundValue = instance.nLower
      } else {
        instance.TRoundValue = instance.nHigher
      }
    }
    instance.RoundUp = function () {
      instance.RoundPrep()
      instance.TRoundValue = instance.nHigher
    }
    instance.RoundDown = function () {
      instance.RoundPrep()
      instance.TRoundValue = instance.nLower
    }
    instance.RoundPrep = function () {
      var n = Math.log10(instance.TRoundTo)
      var m = -Math.floor(n) - 1
      var l = Math.pow(10, m)
      var k = 1 / l //power(10, -m);
      var nBase = Math.floor(instance.TValue * l) / l

      n = k / instance.TRoundTo
      m = Math.floor(k / instance.TRoundTo)
      if (n === m) {
        instance.nLower = nBase
      } else {
        instance.nLower = nBase + instance.TRoundTo
      }

      if (instance.TValue < instance.nLower) {
        instance.nLower = nBase - l
        while (instance.nLower < nBase) {
          instance.nLower = instance.nLower + instance.TRoundTo
        }
        instance.nLower = instance.nLower - instance.TRoundTo // one step back !!
      }

      instance.nHigher = nBase + instance.TRoundTo
      if (instance.nLower < 0) instance.nLower = 0

      if (instance.TValue === instance.nLower) {
        instance.nHigher = instance.nLower
      } else {
        while (instance.TValue > instance.nHigher) {
          instance.nLower = instance.nHigher
          instance.nHigher = instance.nHigher + instance.TRoundTo
          if (instance.nHigher > nBase + k) {
            instance.nHigher = nBase + k + instance.TRoundTo
          }
        }
      }
    }

    instance.TrimTo = function () {
      instance.TrimPrep()
      var nH = instance.nHigher - instance.TValue
      var nL = instance.TValue - instance.nLower

      instance.TRoundValue = nH > nL ? instance.nLower : instance.nHigher
    }

    instance.TrimUp = function () {
      instance.TrimPrep()
      instance.TRoundValue = instance.nHigher
    }

    instance.TrimDown = function () {
      instance.TrimPrep()
      instance.TRoundValue = instance.nLower
    }

    instance.TrimPrep = function () {
      var maxpos = instance.TTrimList.length - 1
      var nMul = Math.pow(10, instance.TDigitPos)
      var nTemp1 = Math.floor((instance.TValue * nMul) / 10) * 10
      var nTemp1a = (nTemp1 - (nTemp1 % 10)) / nMul
      var nTemp2 = nTemp1a + instance.TTrimList[0]

      if (nTemp2 >= instance.TValue) {
        instance.nHigher = nTemp2
        if (nTemp2 === instance.TValue) {
          instance.nLower = nTemp2
        } else {
          instance.nLower = (nTemp1 - nMul / 10) / nMul + instance.TTrimList[maxpos]
          if (instance.nLower < 0) {
            instance.nLower = instance.TTrimList[0] * -1
          }
        }
      } else {
        var i = 1
        while (i <= maxpos) {
          var nTemp3 = nTemp2
          nTemp2 = nTemp1a + instance.TTrimList[i]
          if (nTemp2 >= instance.TValue) {
            instance.nHigher = nTemp2
            if (nTemp2 === instance.TValue) {
              instance.nLower = nTemp2
            } else {
              instance.nLower = nTemp3
            }

            return
          }
          i++
        }
      }
    }

    instance.Round = function (v) {
      instance.bSign = v < 0 ? false : true
      instance.TValue = Math.abs(v)
      instance.TRoundValue = instance.TValue

      if (instance.TMethod === RM_ROUND_TO) {
        instance.RoundTo()
      } else if (instance.TMethod === RM_ROUND_UP) {
        instance.RoundUp()
      } else if (instance.TMethod === RM_ROUND_DOWN) {
        instance.RoundDown()
      } else if (instance.TMethod === RM_TRIM_TO) {
        instance.TrimTo()
      } else if (instance.TMethod === RM_TRIM_UP) {
        instance.TrimUp()
      } else if (instance.TMethod === RM_TRIM_DOWN) {
        instance.TrimDown()
      }

      if (instance.bSign === false) {
        instance.TRoundValue = instance.TRoundValue * -1
      }
      return instance.TRoundValue
    }

    instance.init = function (method, roundTo, digitPos, trimList) {
      instance.TMethod = method
      instance.TRoundTo = roundTo
      instance.TDigitPos = digitPos
      if ([RM_TRIM_TO, RM_TRIM_UP, RM_TRIM_DOWN].indexOf(method) >= 0) {
        var len = trimList
        instance.TTrimList = Array(len).fill(0.0)
        for (let index = 0; index < trimList.length; index++) {
          instance.TTrimList[index] = trimList[index]
        }
        if (len > 1) {
          instance.SortTrimList()
        }
      } else if ([RM_ROUND_TO, RM_ROUND_UP, RM_ROUND_DOWN].indexOf(method) >= 0) {
        instance.TTrimList = []
      } else {
        instance.TTrimList = Array(1).fill(0.0)
      }
    }
    instance.init(Algoritm, RoundTo, DecimalPointPos, TrimList)

    return instance
  },

  rpRounding(params) {
    var rpInstance = {
      FRounding: {},
      FID: 0,
      FRoundPreVat: false,
      FDescription: ''
    }
    rpInstance.Round = function (i, UnitCostPrice, VatPct, sellingPrice, priceExclVat, marginPct) {
      if (i === 0) return pCalcMargin(UnitCostPrice, priceExclVat)

      if (i === 1)
        return pCalcPricePreVATFromMargin(
          UnitCostPrice,
          VatPct,
          sellingPrice,
          priceExclVat,
          marginPct
        )

      if (i === 2)
        return pCalcSellingPrice(UnitCostPrice, VatPct, sellingPrice, priceExclVat, marginPct)
      else if (i === 3)
        return pCalcPricePreVAT(UnitCostPrice, VatPct, sellingPrice, priceExclVat, marginPct)

      return

      function pCalcMargin(ucp, priceExclVat) {
        console.warn('pCalcMargin')
        return { marginPct: margincalculator.dGetMargin(priceExclVat, ucp) }
      }

      function pCalcPricePreVATFromMargin(ucp, vatpct, sellingPrice, priceExclVat, marginPct) {
        console.warn('pCalcPricePreVATFromMargin')
        //dPriceExclVat := TBOMarginCalculator.dGetSellingPriceWithoutVAT( dUnitCostPrice, dMarginPct);
        priceExclVat = margincalculator.dGetSellingPriceWithoutVAT(ucp, marginPct)
        priceExclVat = roundNumber(priceExclVat)
        sellingPrice = margincalculator.dGetSellingPriceWithVATGivenSellingPrice(
          priceExclVat,
          vatpct
        )

        if (rpInstance.FRoundPreVat) {
          //priceExclVat = FRounding.Round(dPriceExclVat);
          //var marginPct = TBOMarginCalculator.dGetMargin(dPriceExclVat, dUnitCostPrice);
          priceExclVat = rpInstance.FRounding.Round(priceExclVat)
          marginPct = margincalculator.dGetMargin(priceExclVat, ucp)
        } else {
          //sellingPrice := FRounding.Round(dSellingPrice);
          //TBOMarginCalculator.GetMarginAndSPExclVat(dUnitCostPrice, dSellingPrice, dVatPCT, dMarginPct, dPriceExclVat);
          sellingPrice = rpInstance.FRounding.Round(sellingPrice)
          var obj = margincalculator.GetMarginAndSPExclVat(ucp, sellingPrice, vatpct)
          if (obj) {
            priceExclVat = obj.sellingPriceWithoutVAT
            marginPct = obj.margin
          }
        }

        return { sellingPrice, priceExclVat, marginPct }
      }

      function pCalcPricePreVAT(ucp, vatpct, sellingPrice, priceExclVat, marginPct) {
        console.warn('pCalcPricePreVAT')
        if (!rpInstance.FRoundPreVat) {
          sellingPrice = rpInstance.FRounding.Round(sellingPrice)
        }
        var obj = margincalculator.GetMarginAndSPExclVat(ucp, sellingPrice, vatpct)
        if (obj) {
          priceExclVat = obj.sellingPriceWithoutVAT
          marginPct = obj.margin
        }
        if (rpInstance.FRoundPreVat) {
          pCalcSellingPrice(ucp, vatpct, sellingPrice, priceExclVat, marginPct)
        }
        return {
          sellingPrice: sellingPrice,
          priceExclVat: priceExclVat,
          marginPct: marginPct
        }
      }

      function pCalcSellingPrice(ucp, vatpct, sellingPrice, priceExclVat, marginPct) {
        console.warn('pCalcSellingPrice')
        if (rpInstance.FRoundPreVat) priceExclVat = rpInstance.FRounding.Round(priceExclVat)

        sellingPrice = margincalculator.dGetSellingPriceWithVATGivenSellingPrice(
          priceExclVat,
          vatpct
        )

        if (!rpInstance.FRoundPreVat) {
          sellingPrice = rpInstance.FRounding.Round(sellingPrice)
          var obj = margincalculator.GetMarginAndSPExclVat(ucp, sellingPrice, vatpct)
          if (obj) {
            priceExclVat = obj.sellingPriceWithoutVAT
            marginPct = obj.margin
          }
        } else marginPct = margincalculator.dGetMarginWithVat(sellingPrice, ucp, vatpct)

        return { sellingPrice, priceExclVat, marginPct }
      }
    }

    rpInstance.init = function (params) {
      rpInstance.FID = params.Id * 1
      rpInstance.FDescription = params.Description
      rpInstance.FRoundPreVat = params.PreVat
      var roundTo = 0
      var trimList = []
      if ([RM_TRIM_TO, RM_TRIM_UP, RM_TRIM_DOWN].indexOf(params.Algorithm) >= 0) {
        trimList = Array(params.ValCnt).fill(0)
        if (params.ValCnt >= 1) trimList[0] = params.Val1
        else if (params.ValCnt >= 2) trimList[1] = params.Val2
        else if (params.ValCnt >= 3) trimList[2] = params.Val3
        else if (params.ValCnt >= 4) trimList[3] = params.Val4
      } else {
        if ([RM_ROUND_TO, RM_ROUND_UP, RM_ROUND_DOWN].indexOf(params.Algorithm) >= 0)
          roundTo = params.Val1
      }
      rpInstance.FRounding = rounding.rounding(
        params.Algorithm,
        roundTo,
        params.DecimalPointPos,
        trimList
      )
    }
    rpInstance.init(params)

    return rpInstance
  }
}

const margincalculator = {
  calcSellingPrice(costPrice, margin, vatPct) {
    const studull = 1 - margin / 100
    return studull !== 0 ? (costPrice / studull) * (1 + vatPct / 100) : 0.0
  },
  dGetMargin(sellingPrice, costPrice) {
    var zero = 0.0
    if (costPrice === 0) {
      return zero
    } else {
      //dfCalcMargin(dSellingPriceWithoutVAT, dCostPrice)
      return sellingPrice !== 0 ? roundNumber((1 - costPrice / sellingPrice) * 100) : zero
    }
  },
  dGetMarginWithVat(sellingPrice, costPrice, vatPct) {
    var zero = 0.0
    if (costPrice === 0) {
      return zero
    }
    sellingPrice = roundNumber(sellingPrice / (vatPct / 100 + 1))
    if (sellingPrice !== 0) {
      //Utils.bfNumberisZero
      return roundNumber(((sellingPrice - costPrice) / sellingPrice) * 100)
    } else {
      return zero
    }
  },
  dGetSellingPriceWithVAT(costPrice, margin, vatPct) {
    //result := StrToFloat(FormatFloat('0.00', dfCalcSellingPrice(dCostPrice, dMargin, dVATPercentage)));
    return margincalculator.calcSellingPrice(costPrice, margin, vatPct)
  },
  dGetSellingPriceWithoutVAT(costPrice, margin) {
    //result := StrToFloat(FormatFloat('0.00', dfCalcSellingPrice(dCostPrice, dMargin, 0)));
    return margincalculator.calcSellingPrice(costPrice, margin, 0)
  },
  dGetSellingPriceWithVATGivenSellingPrice(sellingPrice, vatPct) {
    //result := StrToFloat(FormatFloat('0.00', dSellingPriceWithoutVAT * (1.00 + dVATPercentage / 100)));
    return roundNumber(sellingPrice * (1.0 + vatPct / 100))
  },
  GetMarginAndSPExclVat(costPrice, sellingPrice, vatPct) {
    // dSellingPriceWithoutVAT := Utils.dfRoundEx(StrToFloat(FormatFloat('0.00', dSellingPrice / (1 + dVatPCT / 100))), CurrencyDecimals);
    // dMargin := StrToFloat(FormatFloat('0.00', dGetMargin(dSellingPriceWithoutVAT, dCostPrice)));
    var sellingPriceWithoutVat = roundEx(sellingPrice / (1 + vatPct / 100), 2)
    return {
      sellingPriceWithoutVAT: sellingPriceWithoutVat,
      margin: margincalculator.dGetMargin(sellingPriceWithoutVat, costPrice)
    }
  }
}

exports.financial = financial
exports.uom = uom
exports.rounding = rounding
exports.margincalculator = margincalculator

const { culture } = require('./culture')
const dates = require('./dates')

// const thousandsSeparator = () => {
//   let r = formatString(1111.11, 'n2').split('1').join('')
//   return r.length === 1 ? '' : r[0]
// }
const currencyDecimal = () => culture().numberFormat.currency['.']
const currencyThousand = () => culture().numberFormat.currency[',']
const floatThousand = () => culture().numberFormat[',']
const floatDecimal = () => culture().numberFormat['.']

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

const parseCurrency = (v, fmt = 'c') => kendo.parseFloat(v, fmt)
//  {
//   const sym = culture().numberFormat.currency.symbol

//   if (typeof v === 'string' && v.indexOf(sym) !== -1)
//     v = v
//       .split(currencyThousand())
//       .join('')
//       .replace(currencyDecimal(), '.')
//       .replace(sym, '')
//       .replace(' ', '')

//   return parseFloat(v)
// }

const ctlParsers = {
  int(v) {
    return v === '' ? null : parseInt(v.split(floatThousand()).join(''))
  },

  currency(v) {
    return parseCurrency(v)
  },

  float(v, fmt = 'n') {
    if (typeof kendo !== 'undefined') return kendo.parseFloat(v, fmt)

    if (typeof v === 'string' && v.indexOf(floatDecimal()) !== -1) {
      v = v.split(floatThousand()).join('')
      if (floatDecimal() !== '.') v = v.split(floatDecimal()).join('.')
    }
    return parseFloat(v)
  },

  decimal(v, fmt = 'n') {
    if (typeof kendo !== 'undefined') return kendo.parseFloat(v, fmt)

    if (typeof v === 'string' && v.indexOf(floatDecimal()) !== -1) {
      v = v.split(floatThousand()).join('')
      if (floatDecimal() !== '.') v = v.split(floatDecimal()).join('.')
    }
    return parseFloat(v)
  },

  date(v, fmt = dates.DateFormatSetting, calendar = dates.calendar) {
    return dates.parseDate(v, fmt, calendar)
  },

  datetime(v, fmt = dates.DateTimeFormatSetting, calendar = dates.calendar) {
    return dates.parseDate(v, fmt, calendar)
  },

  time(v) {
    if (v === '') return undefined
    return dates.parseDate(v, dates.TimeFormatSetting)
  }
  //ow6:
  // time(v, fmt) {
  //   if (v === '') return undefined
  //   let d = parseDate(v, fmt || culture().TimeFormatSetting, culture().calendar) // 'HH:mm:ss'
  //   return (
  //     d &&
  //     (d.getHours() * 60 * 60 + d.getMinutes() * 60 + d.getSeconds()) * 1000 + d.getMilliseconds()
  //   )
  // }
}

const ctlFormatters = {
  int(v = null) {
    return v === null ? '' : '' + v
  },

  currency(v = null, fmt = 'c') {
    return v === null ? '' : common.toString(v, fmt)
  },

  float(v = null, fmt = 'n') {
    return v === null ? '' : common.toString(v, fmt)
  },

  decimal(v = null, fmt = 'n') {
    return v === null ? '' : common.toString(v, fmt)
  },

  date(v = null, fmt = dates.DateFormatSetting, calendar = dates.calendar) {
    if (v && typeof v === 'string') v = new Date(v)
    return v === null ? '' : dates.formatDate(v, fmt, calendar)
  },

  // ow6:
  // date(v, fmt = culture().DateFormatSetting, calendar = culture().calendar) {
  //   if (v && typeof v === 'string') v = parseDate(v, fmt, calendar) || new Date(v)
  //   return !v ? '' : v.toLocaleDateString() // formatString(v, fmt || culture().DateFormatSetting)
  // },

  datetime(v = null, fmt = dates.DateTimeFormatSetting, calendar = dates.calendar) {
    if (v && typeof v === 'string') v = new Date(v)
    return v === null ? '' : dates.formatDateTime(v, fmt, calendar)
  },

  // ow6:
  // datetime(v = null, fmt) {
  //   if (v && typeof v === 'string')
  //     v = parseDate(v, fmt || culture().DateFormatSetting, culture().calendar) || new Date(v)
  //   return !v ? '' : v.toLocaleString() //toString(v, fmt || culture().DateTimeFormatSetting)
  // },

  time(v = null, fmt) {
    if (!v) return ''
    return v === null ? '' : common.formatString(v, fmt || dates.TimeFormatSetting)
  }
}

module.exports = {
  ctlFormatters,
  ctlParsers,
  isNumeric,
  floatDecimal,
  parseCurrency
}

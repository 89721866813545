const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const { wrapControl } = require('../ow5/wrapControl')

const qCheck = me => {
  me.on('click', () => {
    if (me.attr('disabled') !== undefined) return

    const prev = me.val()
    me.val(!prev)
  }).on('keypress', e => {
    if (e.which === 32) {
      me.el.click()
      return killEvent(e, true)
    }
  })
  me.qCheck = true

  return me
}
exports.qCheck = qCheck

const basicEdCheck = function (el, opts) {
  el.opts = el.opts || opts
  const me = qc(el)

  el.val = function (v) {
    if (v !== undefined) {
      const prevVal = me.hasClass('on')
      if (prevVal !== v) {
        me.attr({ checked: v ? 'checked' : undefined })
        v ? me.addClass('on') : me.removeClass('on')
        me.trigger('ow-change', v)
      }
    }
    return me.hasClass('on')
  }
  me.val = el.val

  el.displayValidity = function (bValid, msg) {
    if (bValid) {
      el.closest('.resource_set')?.classList.remove('k-input-errbg')
      el.parentNode.invalidMsg = ''
      el.parentNode.title = ''
    } else {
      el.parentNode.invalidMsg = msg
      el.parentNode.title = msg
      el.closest('.resource_set')?.classList.add('k-input-errbg')
    }
  }

  if (!me.qCheck) {
    if (me.isQc) {
      // temporary condition for build ow4 views.
      me.on('keypress', e => {
        if (e.which === 32) {
          me.el.click()
          return killEvent(e)
        }
      })
      me.qCheck = true
    } else qCheck(me)
  }

  return el
}
exports.basicEdCheck = basicEdCheck

const ctlCheck = {
  init() {
    const el = this
    const me = qc(el)
    const opts = (el.opts ??= {})

    el.classList.add('ow-check')

    if (!me.hasClass('ow-ctl-no-wrap')) qc(el.parentElement).addClass('ow-check-wrap')

    // initial value
    let f = (el.opts.fieldName = el.opts.fieldName || $(el).attr('data-field'))
    let v = 'value' in opts ? opts.value : f && opts.model ? ow0._v(opts.model, f) : false

    if (v) me.addClass('on')

    if (!me.qCheck) {
      wrapControl(el)
      me.addClass('check5') // this prevents the bubble up version of .ow5 .ow-check on click

      qCheck(me)
    }
  },
  val(...args) {
    const el = this
    const me = qc(el)
    const [v, , populating] = args
    if (args.length) {
      var changed = v != me.hasClass('on')
      v ? me.addClass('on') : me.removeClass('on')
      if (changed && !populating) me.trigger('ow-change')
    }
    return me.hasClass('on')
  },
  readFilter(filters) {
    const el = this
    if (el.val()) {
      var filter = {
        field: el.opts.fieldName ?? $(el).attr('data-field'),
        operator: el.opts.op ?? 'eq',
        value: el.opts.filterValue ?? el.val()
      }
      filters.push(filter)
    }
  }
}

exports.ctlCheck = ctlCheck

const { icon6 } = require('../icons')
const { check6 } = require('../check6')

const { text6 } = require('../text6')
const { combo6 } = require('../combo6')
const { datetime6 } = require('../datetime6')
const { date6 } = require('../date6')
const { time6 } = require('../time6')
const { agCell } = require('../data-ags')
const { modelBind } = require('../data-model')
const { escapeHtml } = require('../../html-encode')
const { _v } = require('../../_v')
const { culture, formatString } = require('../../culture')
const { ctlParsers } = require('../../ctl-format')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')
const { delphiColortoHex } = require('../delphiColortoHex')
const { parseDate } = require('../../date-parser')

const wrapFooter = (content, col) =>
  qc('span.cell-liner.col-' + col.coli, content).css({
    textAlign: col.footerAlign || col.align || undefined
  })

const typeMap = {
  char: 'string',
  bit: 'boolean',
  decimal: 'number',
  integer: 'number',
  int: 'number',
  float: 'number',
  currency: 'number',
  date: 'date',
  datetime: 'datetime',
  time: 'time',
  timeString: 'string'
}

const edTypeMap = {
  char: 'text',
  bit: 'check',
  boolean: 'check',
  decimal: 'float',
  integer: 'int',
  float: 'float',
  currency: 'float',
  date: 'date',
  datetime: 'datetime',
  time: 'time',
  weekdays: 'weekdays',
  timeString: 'timeString'
}

/**
 * Ensures that filterable columns are assigned the correct type, e.g. boolean, string, etc.
 * @param {object} col - Object describing the properties of a column in a grid.
 * @param {object} [x] - Object containing options for col.
 */
const setFilters = (col, x) => {
  if (typeof col !== 'object') throw 'col param is of invalid type.'
  var isFilterable = col.filterType !== 'na'
  col.filterable = isFilterable
  if (!isFilterable) return

  //Setting col.filterType to the correct value here will ensure the correct operators are displayed in each filter.
  col.filterType = col.filterType || col.type
  if (col.filterType !== 'boolean') {
    if (col.filterType === 'int' || col.filterType === 'float' || col.filterType === 'currency') {
      col.filterType = 'number'
    } else if (col.filterType === 'check') {
      col.filterType = 'boolean'
    } else {
      col.filterType = edTypeMap[col.filterType] || 'string'
    }
  }
  if (col.defaultOperator) {
    if (x.filterable.cell) x.filterable.cell.operator = col.defaultOperator
  }
}

/**
 * Accepts a column object, and an object containing a row of data, and returns the value to be displayed in the current gird cell.
 * @param {object} col - Object describing a column in a grid.
 * @param {object} d - Object describing a row of grid data.
 */
const columnValue = (col, d) => {
  if (typeof col !== 'object') throw 'col param is invalid type.'
  if (typeof d !== 'object') throw 'd param is invalid type.'

  var f = col.field
  var v = 'calc' in col ? col.calc(d) : _v(d, f)

  if (v && (col.type === 'date' || col.type === 'datetime') && typeof v === 'string') {
    v = parseDate(v, col.format)
  }
  if (v && col.type === 'time' && typeof v === 'string') {
    v = ctlParsers.time(v)
  }
  return v
}

const formattedColumnValue = (col, d, i, readOnly, textUse) => {
  let v = columnValue(col, d)

  if (typeof v !== 'undefined' && v !== null) {
    v = col.format ? formatString(v, col.format) : v
  } else v = col.format ? '' : null

  if (readOnly) {
    var nullValue = textUse ? '\xa0' : null
    if (v === null) return nullValue
    return col.readOnlyText ? col.readOnlyText(d) : escapeHtml(v) || nullValue
  }

  return v
}

const cellReadOnly = (col, data) =>
  typeof col.readOnly === 'function' ? col.readOnly(data) : col.readOnly || false

function checkBoxTemplate(model, defaultValue, grid) {
  let col = this
  var fieldName = col.field
  const readOnly = cellReadOnly(this, model)

  var v = formattedColumnValue(col, model, null, readOnly)
  if (readOnly) return v

  return modelBind(
    grid.view,
    check6({ view: grid.view, inGrid: true, fieldName, model }).bindState(function () {
      this.value = _v(model, fieldName) || false
    }),
    model
  ).wrap()
}

function textTemplate(model, defaultValue, grid) {
  const col = this // change?

  var fieldName = col.field
  var readOnly = cellReadOnly(col, model)
  var v = formattedColumnValue(col, model, null, readOnly)

  if (readOnly) return v

  const props = { fieldName, type: col.type, inGrid: true, model, value: v, col }
  if (col.format) props.format = col.format
  if (col.maxLength) props.maxlength = col.maxLength

  props.view = grid.view
  return text6(props).wrap()
}

function dateTemplate(d, defaultValue, grid) {
  const col = this // change?

  var fieldName = col.field
  var readOnly = cellReadOnly(col, d)

  if (readOnly) return formattedColumnValue(col, d, null, readOnly)

  var v = columnValue(col, d)

  const props = { fieldName, type: col.type, inGrid: true, model: d, value: v }
  props.class = ''
  props.class += ' ' + col.type
  if (col.maxLength) props.maxlength = col.maxLength

  props.view = grid.view

  return date6(props).wrap()
}

function datetimeTemplate(d, defaultValue, grid) {
  const col = this // change?

  var fieldName = col.field
  var readOnly = cellReadOnly(col, d)

  if (readOnly) return formattedColumnValue(col, d, null, readOnly)

  var v = columnValue(col, d)

  const props = { fieldName, type: col.type, inGrid: true, model: d, value: v }
  props.class = ''
  props.class += ' ' + col.type
  if (col.maxLength) props.maxlength = col.maxLength

  props.view = grid.view

  return datetime6(props).wrap()
}

function timeTemplate(model, defaultValue, grid) {
  const gridCol = this // change?
  let readOnly = cellReadOnly(gridCol, model)
  if (readOnly) return formattedColumnValue(gridCol, model, null, false)
  const props = {
    fieldName: this.field,
    type: gridCol.type,
    inGrid: true,
    model,
    gridCol,
    class: gridCol.type
  }
  if (gridCol.maxLength) props.maxlength = gridCol.maxLength

  props.view = grid.view
  return time6(props).wrap()
}

function comboTemplate(d, defaultValue, grid) {
  const col = this // change?

  var fieldName = col.field
  var readOnly = cellReadOnly(col, d)
  var v = columnValue(col, d)

  if (readOnly) return v

  const props = Object.assign({}, col.combo)
  props.type = props.type || col.type
  props.inGrid = props.inGrid || true
  props.model = props.model || d
  props.value = props.value || v
  props.fieldName = fieldName

  if (col.combo.list) props.list = col.combo.list
  if (col.maxLength) props.maxlength = col.maxLength

  props.view = grid.view
  return combo6(props).wrap()
}

const colorPickerTemplate = function (d) {
  const col = this
  var v = columnValue(col, d)
  return qc('span', html('&nbsp;')).css({
    display: 'block',
    backgroundColor: col.type === 'integer' ? delphiColortoHex(v) : v
  })
}

const colTemplates = {
  boolean: checkBoxTemplate,
  checkBox: checkBoxTemplate,
  datetime: datetimeTemplate,
  date: dateTemplate,
  time: timeTemplate,
  text: textTemplate,
  combo: comboTemplate,
  string: textTemplate,
  int: textTemplate,
  float: textTemplate,
  number: textTemplate,
  currency: textTemplate,
  colorpicker: colorPickerTemplate
}

// builds the following:
//   editor control
//   manages classes, readOnly state, redrawing etc
const toGridColumn = (grid, col) => {
  if (col.editable === false)
    console.warn('gridCol.editable is deprecated, please use gridCol.readOnly: true')

  col.attrs = col.attrs || {}

  if ((col.field || '').indexOf('.') > -1 && !col.template && grid.unnestFields) {
    var field = col.field
    col.template =
      col.template ||
      function (d) {
        var v = _v(d, field)
        if (typeof v !== 'undefined' && v !== null) {
          return col.format ? formatString(v, col.format) : v
        }
        return ''
      }
    col.nestedField = field.split('.').join('_')
  }

  // if (col.kType === 'boolean' && !col.fullTimeCheck) {
  //   // update boolean type string
  //   options.zeroString = options.zeroString || 'false'
  //   options.oneString = options.oneString || 'true'
  //   if (options.zeroString) {
  //     // Build display template
  //     col.template =
  //       col.template ||
  //       function (data, defaultValue, grid) {
  //         var list = [options.zeroString, options.oneString]
  //         return list[defaultValue]
  //       }
  //   }
  // }

  // formats
  if (col.type === 'date') {
    col.format = col.format || culture().DateFormatSetting
  }
  if (col.type === 'datetime') {
    col.format = col.format || culture().DateTimeFormatSetting
  }
  if (col.type === 'time') {
    col.format = col.format || culture().TimeFormatSetting
    col.attrs.class = (col.attrs.class || '') + ' time'
  }
  if (col.type === 'currency') {
    col.format = col.format || 'c'
  }
  if (col.type === 'float') {
    col.format = col.format || 'n'
  }
  if (col.type === 'number') {
    col.format = col.format || 'n'
  }
  if (col.type === 'int') {
    col.format = col.format || 'n0'
  }

  if (col.format) {
    if (col.format[0] === '{') {
      console.warn('please set column format as inner format string eg. n2 not {0:n2}')
      col.format = col.format.split(':')[1].split('}')[0]
    }
  }

  if (col.combo) console.warn('col.combo not supported, use Cmp template')
  if (col.list) console.warn('col.list not supported, use Cmp template')
  if (col.ctl) console.warn('col.ctl not supported, use Cmp template')
  if (col.editor) console.warn('col.editor not supported, use Cmp template')

  // footer
  col.groupFooter = col.groupFooter || {
    template: () => html('&nbsp;')
  }

  if (typeof col.groupFooter === 'string') {
    // use ours
    const ag = col.groupFooter
    const f = col.field

    col.groupFooter = agCell(ag, f)
  }

  if (typeof col.groupFooter === 'function') {
    const fn = col.groupFooter
    col.groupFooter = {
      fn,
      template(group) {
        return col.groupFooter.fn(group)
      }
    }
  }

  if (col.footer) normalizeColFooter(col)

  // filters
  if (grid.noFilters !== true) setFilters(col)

  col.groupHeader = col.groupHeader || {}
  col.groupHeader.template = col.groupHeader.template || (() => html('&nbsp;'))

  col.defaultWidth = col.defaultWidth || col.width
  return col
}

/**
 * Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {grid ctl} grid (grid.el will give you the element after rendering)
 * @param {boolean=false} grid.hasFilters - if false, the column top filters aren't created
 */
const colsToGridColumns = function (cols, grid) {
  if (!grid.gridPrefs) {
    //check user preferences existed
    if (grid.viewdata) {
      let prefs = _v(grid.viewdata, 'winpref.grids') || {}
      grid.gridPrefs = grid.id in prefs ? prefs[grid.id] : null
    }
  }

  if (!grid.gridPrefs && grid.gridTemplate) {
    // check gridtemplate if user pref not found
    var gridTemplates = JSON.parse(grid.gridTemplate)
    if (!Array.isArray(gridTemplates)) {
      // ow4 gridTemplate is array.
      grid.gridPrefs = gridTemplates[grid.id] || null
    }
  }

  return cols.filter(x => x.gridCol !== false).map(c => toGridColumn(grid, c))
}

const columnButtonIcons = {
  delete: (g, rec) =>
    icon6('delete')
      .addClass('red-icon')
      .on('click', () => g.deleteRow(rec)),

  edit: (g, rec) =>
    icon6('edit')
      .addClass('blue-icon')
      .on('click', () => g.deleteRow(rec))
}

/**
 * returns a column definition for adding to grid cols array
 * @param {string[]} iconNames Array of iconNames
 * @returns object - column definition
 */
const buildButtonColumn = iconNames => {
  return {
    title: '',
    attrs: { class: 'command-cell no-tab' },
    width: 24 + iconNames.length * 24,
    readOnly: true,
    template(rec) {
      const grid = this.grid
      return iconNames.map(iconName => columnButtonIcons[iconName](grid, rec) || icon6(iconName))
    }
  }
}

const gCols = cols => cols.filter(c => c.gridCol !== false)

const forTestingOnly = { setFilters }

const totalColWidth = grid => {
  var r = 0
  var i, col
  for (i = 0; i < grid.cols.length; i++) {
    col = grid.cols[i]
    if (col.gridCol !== false && !col.hidden) r += col.width
  }
  return r
}

const normalizeColFooter = col => {
  if (col.footerAlign) {
    console.warn(
      'col.footerAlign no longer supported, use footer.align OR cmp attributes footer: { attrs: { style: { textAlign: "right" } } } }  '
    )
  }
  if (col.footerAttributes) {
    console.warn('col.col.footerAttributes no longer supported, use  footer.attrs')
  }

  if (typeof col.footer === 'string') {
    col.footer = { ag: col.footer }
    // col.footer = () => grid[ag](col.field) // eg. grid.sum('Quantity')
  }

  if (typeof col.footer === 'function') {
    const _footer = col.footer
    col.footer = {
      template(d) {
        return wrapFooter(formatString(_footer.call(col, d), col.format), col)
      }
    }
  }

  const attrs = col.footer.attrs || {}
  attrs.style = attrs.style || {}
  attrs.class = attrs.class || ''
  col.footer.attrs = attrs
}

const normalizeCols = (cols, grid, userSettings) => {
  cols.forEach((col, coli) => {
    col.coli = coli

    if (col.combo) col.template = col.template || colTemplates['combo']

    if (col.type === 'date') col.format = col.format || culture().DateFormatSetting

    if (col.type === 'integer') col.type = 'int'
    if (col.type === 'decimal') col.type = 'float'

    if (col.gridCol !== false && col.readOnly !== true) {
      col.template = col.template || colTemplates[col.type] || colTemplates.text
    }

    if (userSettings) {
      col.width = _v(userSettings, 'cols.' + coli + '.width') || col.width
      col.hidden = _v(userSettings, 'cols.' + coli + '.hidden') || 0
    }

    if (col.attributes) {
      console.warn('col.attributes no longer in use, please use Cmp attrs: { }')
    }

    if (col.headerAttributes) {
      console.warn(
        "col.headerAttributes no longer in use, please use header: { attrs: { class: '', style: '', ... } }"
      )
    }

    const header = col.header || {}
    col.header = header
    header.style = header.style || ''
    header.class = header.class || ''

    const attrs = col.attrs || {}
    col.attrs = attrs
    attrs.style = attrs.style || ''
    attrs.class = attrs.class || ''

    col.validation = col.validation || {}

    if (col.min) col.validation.gte = col.validation.gte || col.min
    if (col.max) col.validation.lte = col.validation.lte || col.max

    delete col.min
    delete col.max
  })

  grid.totalColWidth = totalColWidth(grid)
}

module.exports = {
  cellReadOnly,
  colTemplates,
  columnValue,
  buildButtonColumn,
  colsToGridColumns,
  edTypeMap,
  typeMap,
  totalColWidth,
  gCols,
  normalizeCols,
  forTestingOnly
}

exports.openPdf = (name, fn, data, isNewWindow = ow0.reportNewWindow) => {
  const convertDataURIToBinary = (dataURI, hasMarker) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = hasMarker ? window.atob(base64) : window.atob(dataURI)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) array[i] = raw.charCodeAt(i)
    return array
  }
  var pdfAsArray = convertDataURIToBinary(data)
  var blob = new Blob([pdfAsArray], { type: 'application/pdf' })

  var URL = window.URL || window.webkitURL
  var objectUrl = URL.createObjectURL(blob)
  return isNewWindow
    ? ow0.windows.openView({
        name: name,
        url: fn,
        isContentProvided: true,
        contentRef:
          "<iframe style='width:100%;height:100%;' frameBorder='0' scrolling='no' src='" +
          objectUrl +
          "'></iframe>",
        width: 1024,
        height: 768,
        useExisting: true,
        scrollable: false
      })
    : window.open(objectUrl)
}

const numberFormats = [
  { locale: 'aa-DJ', currency: 'DJF' },
  { locale: 'aa-ER', currency: 'ERN' },
  { locale: 'aa-ET', currency: 'ETB' },
  { locale: 'aa', currency: '' },
  { locale: 'af-NA', currency: 'NAD' },
  { locale: 'af-ZA', currency: 'ZAR' },
  { locale: 'af', currency: '' },
  { locale: 'agq-CM', currency: 'XAF' },
  { locale: 'agq', currency: '' },
  { locale: 'ak-GH', currency: 'GHS' },
  { locale: 'ak', currency: '' },
  { locale: 'am-ET', currency: 'ETB' },
  { locale: 'am', currency: '' },
  { locale: 'ar-001', currency: 'XDR' },
  { locale: 'ar-AE', currency: 'AED' },
  { locale: 'ar-BH', currency: 'BHD' },
  { locale: 'ar-DJ', currency: 'DJF' },
  { locale: 'ar-DZ', currency: 'DZD' },
  { locale: 'ar-EG', currency: 'EGP' },
  { locale: 'ar-ER', currency: 'ERN' },
  { locale: 'ar-IL', currency: 'ILS' },
  { locale: 'ar-IQ', currency: 'IQD' },
  { locale: 'ar-JO', currency: 'JOD' },
  { locale: 'ar-KM', currency: 'KMF' },
  { locale: 'ar-KW', currency: 'KWD' },
  { locale: 'ar-LB', currency: 'LBP' },
  { locale: 'ar-LY', currency: 'LYD' },
  { locale: 'ar-MA', currency: 'MAD' },
  { locale: 'ar-MR', currency: 'MRO' },
  { locale: 'ar-OM', currency: 'OMR' },
  { locale: 'ar-PS', currency: 'ILS' },
  { locale: 'ar-QA', currency: 'QAR' },
  { locale: 'ar-SA', currency: 'SAR' },
  { locale: 'ar-SD', currency: 'SDG' },
  { locale: 'ar-SO', currency: 'SOS' },
  { locale: 'ar-SS', currency: 'SSP' },
  { locale: 'ar-SY', currency: 'SYP' },
  { locale: 'ar-TD', currency: 'XAF' },
  { locale: 'ar-TN', currency: 'TND' },
  { locale: 'ar-YE', currency: 'YER' },
  { locale: 'ar', currency: '' },
  { locale: 'arn-CL', currency: 'CLP' },
  { locale: 'arn', currency: '' },
  { locale: 'as-IN', currency: 'INR' },
  { locale: 'as', currency: '' },
  { locale: 'asa-TZ', currency: 'TZS' },
  { locale: 'ast-ES', currency: 'EUR' },
  { locale: 'asa', currency: '' },
  { locale: 'ast', currency: '' },
  { locale: 'az-Cyrl-AZ', currency: 'AZN' },
  { locale: 'az-Cyrl', currency: '' },
  { locale: 'az-Latn-AZ', currency: 'AZN' },
  { locale: 'az-Latn', currency: '' },
  { locale: 'az', currency: '' },
  { locale: 'ba-RU', currency: 'RUB' },
  { locale: 'ba', currency: '' },
  { locale: 'bas-CM', currency: 'XAF' },
  { locale: 'bas', currency: '' },
  { locale: 'be-BY', currency: 'BYN' },
  { locale: 'be', currency: '' },
  { locale: 'bem-ZM', currency: 'ZMW' },
  { locale: 'bem', currency: '' },
  { locale: 'bez-TZ', currency: 'TZS' },
  { locale: 'bez', currency: '' },
  { locale: 'bg-BG', currency: 'BGN' },
  { locale: 'bg', currency: '' },
  { locale: 'bin-NG', currency: 'NGN' },
  { locale: 'bin', currency: '' },
  { locale: 'bm-Latn-ML', currency: 'XOF' },
  { locale: 'bm-Latn', currency: '' },
  { locale: 'bm', currency: '' },
  { locale: 'bn-BD', currency: 'BDT' },
  { locale: 'bn-IN', currency: 'INR' },
  { locale: 'bn', currency: '' },
  { locale: 'bo-CN', currency: 'CNY' },
  { locale: 'bo-IN', currency: 'INR' },
  { locale: 'bo', currency: '' },
  { locale: 'br-FR', currency: 'EUR' },
  { locale: 'br', currency: '' },
  { locale: 'brx-IN', currency: 'INR' },
  { locale: 'brx', currency: '' },
  { locale: 'bs-Cyrl-BA', currency: 'BAM' },
  { locale: 'bs-Cyrl', currency: '' },
  { locale: 'bs-Latn-BA', currency: 'BAM' },
  { locale: 'bs-Latn', currency: '' },
  { locale: 'bs', currency: '' },
  { locale: 'byn-ER', currency: 'ERN' },
  { locale: 'byn', currency: '' },
  { locale: 'ca-AD', currency: 'EUR' },
  { locale: 'ca-ES-valencia', currency: 'EUR' },
  { locale: 'ca-ES', currency: 'EUR' },
  { locale: 'ca-FR', currency: 'EUR' },
  { locale: 'ca-IT', currency: 'EUR' },
  { locale: 'ca', currency: '' },
  { locale: 'ce-RU', currency: 'RUB' },
  { locale: 'ce', currency: '' },
  { locale: 'cgg-UG', currency: 'UGX' },
  { locale: 'cgg', currency: '' },
  { locale: 'chr-Cher-US', currency: 'USD' },
  { locale: 'chr-Cher', currency: '' },
  { locale: 'chr', currency: '' },
  { locale: 'co-FR', currency: 'EUR' },
  { locale: 'co', currency: '' },
  { locale: 'cs-CZ', currency: 'CZK' },
  { locale: 'cs', currency: '' },
  { locale: 'cu-RU', currency: 'RUB' },
  { locale: 'cu', currency: '' },
  { locale: 'cy-GB', currency: 'GBP' },
  { locale: 'cy', currency: '' },
  { locale: 'da-DK', currency: 'DKK' },
  { locale: 'da-GL', currency: 'DKK' },
  { locale: 'da', currency: '' },
  { locale: 'dav-KE', currency: 'KES' },
  { locale: 'dav', currency: '' },
  { locale: 'de-AT', currency: 'EUR' },
  { locale: 'de-BE', currency: 'EUR' },
  { locale: 'de-CH', currency: 'CHF' },
  { locale: 'de-DE', currency: 'EUR' },
  { locale: 'de-LI', currency: 'CHF' },
  { locale: 'de-LU', currency: 'EUR' },
  { locale: 'de', currency: '' },
  { locale: 'dje-NE', currency: 'XOF' },
  { locale: 'dje', currency: '' },
  { locale: 'dsb-DE', currency: 'EUR' },
  { locale: 'dsb', currency: '' },
  { locale: 'dua-CM', currency: 'XAF' },
  { locale: 'dua', currency: '' },
  { locale: 'dv-MV', currency: 'MVR' },
  { locale: 'dv', currency: '' },
  { locale: 'dyo-SN', currency: 'XOF' },
  { locale: 'dyo', currency: '' },
  { locale: 'dz-BT', currency: 'BTN' },
  { locale: 'dz', currency: '' },
  { locale: 'ebu-KE', currency: 'KES' },
  { locale: 'ebu', currency: '' },
  { locale: 'ee-GH', currency: 'GHS' },
  { locale: 'ee-TG', currency: 'XOF' },
  { locale: 'ee', currency: '' },
  { locale: 'el-CY', currency: 'EUR' },
  { locale: 'el-GR', currency: 'EUR' },
  { locale: 'el', currency: '' },
  { locale: 'en-001', currency: 'XDR' },
  { locale: 'en-029', currency: 'XCD' },
  { locale: 'en-150', currency: 'XDR' },
  { locale: 'en-AG', currency: 'XCD' },
  { locale: 'en-AI', currency: 'XCD' },
  { locale: 'en-AS', currency: 'USD' },
  { locale: 'en-AT', currency: 'EUR' },
  { locale: 'en-AU', currency: 'AUD' },
  { locale: 'en-BB', currency: 'BBD' },
  { locale: 'en-BE', currency: 'EUR' },
  { locale: 'en-BI', currency: 'BIF' },
  { locale: 'en-BM', currency: 'BMD' },
  { locale: 'en-BS', currency: 'BSD' },
  { locale: 'en-BW', currency: 'BWP' },
  { locale: 'en-BZ', currency: 'BZD' },
  { locale: 'en-CA', currency: 'CAD' },
  { locale: 'en-CC', currency: 'AUD' },
  { locale: 'en-CH', currency: 'CHF' },
  { locale: 'en-CK', currency: 'NZD' },
  { locale: 'en-CM', currency: 'XAF' },
  { locale: 'en-CX', currency: 'AUD' },
  { locale: 'en-CY', currency: 'EUR' },
  { locale: 'en-DE', currency: 'EUR' },
  { locale: 'en-DK', currency: 'DKK' },
  { locale: 'en-DM', currency: 'XCD' },
  { locale: 'en-ER', currency: 'ERN' },
  { locale: 'en-FI', currency: 'EUR' },
  { locale: 'en-FJ', currency: 'FJD' },
  { locale: 'en-FK', currency: 'FKP' },
  { locale: 'en-FM', currency: 'USD' },
  { locale: 'en-GB', currency: 'GBP' },
  { locale: 'en-GD', currency: 'XCD' },
  { locale: 'en-GG', currency: 'GBP' },
  { locale: 'en-GH', currency: 'GHS' },
  { locale: 'en-GI', currency: 'GIP' },
  { locale: 'en-GM', currency: 'GMD' },
  { locale: 'en-GU', currency: 'USD' },
  { locale: 'en-GY', currency: 'GYD' },
  { locale: 'en-HK', currency: 'HKD' },
  { locale: 'en-ID', currency: 'IDR' },
  { locale: 'en-IE', currency: 'EUR' },
  { locale: 'en-IL', currency: 'ILS' },
  { locale: 'en-IM', currency: 'GBP' },
  { locale: 'en-IN', currency: 'INR' },
  { locale: 'en-IO', currency: 'USD' },
  { locale: 'en-JE', currency: 'GBP' },
  { locale: 'en-JM', currency: 'JMD' },
  { locale: 'en-KE', currency: 'KES' },
  { locale: 'en-KI', currency: 'AUD' },
  { locale: 'en-KN', currency: 'XCD' },
  { locale: 'en-KY', currency: 'KYD' },
  { locale: 'en-LC', currency: 'XCD' },
  { locale: 'en-LR', currency: 'LRD' },
  { locale: 'en-LS', currency: 'ZAR' },
  { locale: 'en-MG', currency: 'MGA' },
  { locale: 'en-MH', currency: 'USD' },
  { locale: 'en-MO', currency: 'MOP' },
  { locale: 'en-MP', currency: 'USD' },
  { locale: 'en-MS', currency: 'XCD' },
  { locale: 'en-MT', currency: 'EUR' },
  { locale: 'en-MU', currency: 'MUR' },
  { locale: 'en-MW', currency: 'MWK' },
  { locale: 'en-MY', currency: 'MYR' },
  { locale: 'en-NA', currency: 'NAD' },
  { locale: 'en-NF', currency: 'AUD' },
  { locale: 'en-NG', currency: 'NGN' },
  { locale: 'en-NL', currency: 'EUR' },
  { locale: 'en-NR', currency: 'AUD' },
  { locale: 'en-NU', currency: 'NZD' },
  { locale: 'en-NZ-old', currency: 'ISK' },
  { locale: 'en-NZ', currency: 'NZD' },
  { locale: 'en-PG', currency: 'PGK' },
  { locale: 'en-PH', currency: 'PHP' },
  { locale: 'en-PK', currency: 'PKR' },
  { locale: 'en-PN', currency: 'NZD' },
  { locale: 'en-PR', currency: 'USD' },
  { locale: 'en-PW', currency: 'USD' },
  { locale: 'en-RW', currency: 'RWF' },
  { locale: 'en-SB', currency: 'SBD' },
  { locale: 'en-SC', currency: 'SCR' },
  { locale: 'en-SD', currency: 'SDG' },
  { locale: 'en-SE', currency: 'SEK' },
  { locale: 'en-SG', currency: 'SGD' },
  { locale: 'en-SH', currency: 'SHP' },
  { locale: 'en-SI', currency: 'EUR' },
  { locale: 'en-SL', currency: 'SLL' },
  { locale: 'en-SS', currency: 'SSP' },
  { locale: 'en-SX', currency: 'ANG' },
  { locale: 'en-SZ', currency: 'SZL' },
  { locale: 'en-TC', currency: 'USD' },
  { locale: 'en-TK', currency: 'NZD' },
  { locale: 'en-TO', currency: 'TOP' },
  { locale: 'en-TT', currency: 'TTD' },
  { locale: 'en-TV', currency: 'AUD' },
  { locale: 'en-TZ', currency: 'TZS' },
  { locale: 'en-UG', currency: 'UGX' },
  { locale: 'en-UM', currency: 'USD' },
  { locale: 'en-US', currency: 'USD' },
  { locale: 'en-VC', currency: 'XCD' },
  { locale: 'en-VG', currency: 'USD' },
  { locale: 'en-VI', currency: 'USD' },
  { locale: 'en-VU', currency: 'VUV' },
  { locale: 'en-WS', currency: 'WST' },
  { locale: 'en-ZA', currency: 'ZAR' },
  { locale: 'en-ZM', currency: 'ZMW' },
  { locale: 'en-ZW', currency: 'ZMW' },
  { locale: 'en', currency: '' },
  { locale: 'eo-001', currency: 'XDR' },
  { locale: 'eo', currency: '' },
  { locale: 'es-419', currency: 'XDR' },
  { locale: 'es-AR', currency: 'ARS' },
  { locale: 'es-BO', currency: 'BOB' },
  { locale: 'es-CL', currency: 'CLP' },
  { locale: 'es-CO', currency: 'COP' },
  { locale: 'es-CR', currency: 'CRC' },
  { locale: 'es-CU', currency: 'CUP' },
  { locale: 'es-DO', currency: 'DOP' },
  { locale: 'es-EC', currency: 'USD' },
  { locale: 'es-ES', currency: 'EUR' },
  { locale: 'es-GQ', currency: 'XAF' },
  { locale: 'es-GT', currency: 'GTQ' },
  { locale: 'es-HN', currency: 'HNL' },
  { locale: 'es-MX', currency: 'MXN' },
  { locale: 'es-NI', currency: 'NIO' },
  { locale: 'es-PA', currency: 'PAB' },
  { locale: 'es-PE', currency: 'PEN' },
  { locale: 'es-PH', currency: 'PHP' },
  { locale: 'es-PR', currency: 'USD' },
  { locale: 'es-PY', currency: 'PYG' },
  { locale: 'es-SV', currency: 'USD' },
  { locale: 'es-US', currency: 'USD' },
  { locale: 'es-UY', currency: 'UYU' },
  { locale: 'es-VE', currency: 'VEF' },
  { locale: 'es', currency: '' },
  { locale: 'et-EE', currency: 'EUR' },
  { locale: 'et', currency: '' },
  { locale: 'eu-ES', currency: 'EUR' },
  { locale: 'eu', currency: '' },
  { locale: 'ewo-CM', currency: 'XAF' },
  { locale: 'ewo', currency: '' },
  { locale: 'fa-IR', currency: 'IRR' },
  { locale: 'fa', currency: '' },
  { locale: 'ff-CM', currency: 'XAF' },
  { locale: 'ff-GN', currency: 'GNF' },
  { locale: 'ff-Latn-SN', currency: 'XOF' },
  { locale: 'ff-Latn', currency: '' },
  { locale: 'ff-MR', currency: 'MRO' },
  { locale: 'ff-NG', currency: 'NGN' },
  { locale: 'ff', currency: '' },
  { locale: 'fi-FI', currency: 'EUR' },
  { locale: 'fi', currency: '' },
  { locale: 'fil-PH', currency: 'PHP' },
  { locale: 'fil', currency: '' },
  { locale: 'fo-DK', currency: 'DKK' },
  { locale: 'fo-FO', currency: 'DKK' },
  { locale: 'fo', currency: '' },
  { locale: 'fr-029', currency: 'XCD' },
  { locale: 'fr-BE', currency: 'EUR' },
  { locale: 'fr-BF', currency: 'XOF' },
  { locale: 'fr-BI', currency: 'BIF' },
  { locale: 'fr-BJ', currency: 'XOF' },
  { locale: 'fr-BL', currency: 'EUR' },
  { locale: 'fr-CA', currency: 'CAD' },
  { locale: 'fr-CD', currency: 'CDF' },
  { locale: 'fr-CF', currency: 'XAF' },
  { locale: 'fr-CG', currency: 'XAF' },
  { locale: 'fr-CH', currency: 'CHF' },
  { locale: 'fr-CI', currency: 'XOF' },
  { locale: 'fr-CM', currency: 'XAF' },
  { locale: 'fr-DJ', currency: 'DJF' },
  { locale: 'fr-DZ', currency: 'DZD' },
  { locale: 'fr-FR', currency: 'EUR' },
  { locale: 'fr-GA', currency: 'XAF' },
  { locale: 'fr-GF', currency: 'EUR' },
  { locale: 'fr-GN', currency: 'GNF' },
  { locale: 'fr-GP', currency: 'EUR' },
  { locale: 'fr-GQ', currency: 'XAF' },
  { locale: 'fr-HT', currency: 'HTG' },
  { locale: 'fr-KM', currency: 'KMF' },
  { locale: 'fr-LU', currency: 'EUR' },
  { locale: 'fr-MA', currency: 'MAD' },
  { locale: 'fr-MC', currency: 'EUR' },
  { locale: 'fr-MF', currency: 'EUR' },
  { locale: 'fr-MG', currency: 'MGA' },
  { locale: 'fr-ML', currency: 'XOF' },
  { locale: 'fr-MQ', currency: 'EUR' },
  { locale: 'fr-MR', currency: 'MRO' },
  { locale: 'fr-MU', currency: 'MUR' },
  { locale: 'fr-NC', currency: 'XPF' },
  { locale: 'fr-NE', currency: 'XOF' },
  { locale: 'fr-PF', currency: 'XPF' },
  { locale: 'fr-PM', currency: 'EUR' },
  { locale: 'fr-RE', currency: 'EUR' },
  { locale: 'fr-RW', currency: 'RWF' },
  { locale: 'fr-SC', currency: 'SCR' },
  { locale: 'fr-SN', currency: 'XOF' },
  { locale: 'fr-SY', currency: 'SYP' },
  { locale: 'fr-TD', currency: 'XAF' },
  { locale: 'fr-TG', currency: 'XOF' },
  { locale: 'fr-TN', currency: 'TND' },
  { locale: 'fr-VU', currency: 'VUV' },
  { locale: 'fr-WF', currency: 'XPF' },
  { locale: 'fr-YT', currency: 'EUR' },
  { locale: 'fr', currency: '' },
  { locale: 'fur-IT', currency: 'EUR' },
  { locale: 'fur', currency: '' },
  { locale: 'fy-NL', currency: 'EUR' },
  { locale: 'fy', currency: '' },
  { locale: 'ga-IE', currency: 'EUR' },
  { locale: 'ga', currency: '' },
  { locale: 'gd-GB', currency: 'GBP' },
  { locale: 'gd', currency: '' },
  { locale: 'gl-ES', currency: 'EUR' },
  { locale: 'gl', currency: '' },
  { locale: 'gn-PY', currency: 'PYG' },
  { locale: 'gn', currency: '' },
  { locale: 'gsw-CH', currency: 'CHF' },
  { locale: 'gsw-FR', currency: 'EUR' },
  { locale: 'gsw-LI', currency: 'CHF' },
  { locale: 'gsw', currency: '' },
  { locale: 'gu-IN', currency: 'INR' },
  { locale: 'gu', currency: '' },
  { locale: 'guz-KE', currency: 'KES' },
  { locale: 'guz', currency: '' },
  { locale: 'gv', currency: '' },
  { locale: 'gv-IM', currency: 'GBP' },
  { locale: 'ha-Latn-GH', currency: 'GHS' },
  { locale: 'ha-Latn-NE', currency: 'XOF' },
  { locale: 'ha-Latn-NG', currency: 'NGN' },
  { locale: 'ha-Latn', currency: '' },
  { locale: 'ha', currency: '' },
  { locale: 'haw-US', currency: 'USD' },
  { locale: 'haw', currency: '' },
  { locale: 'he-IL', currency: 'ILS' },
  { locale: 'he', currency: '' },
  { locale: 'hi-IN', currency: 'INR' },
  { locale: 'hi', currency: '' },
  { locale: 'hr-BA', currency: 'BAM' },
  { locale: 'hr-HR', currency: 'HRK' },
  { locale: 'hr', currency: '' },
  { locale: 'hsb-DE', currency: 'EUR' },
  { locale: 'hsb', currency: '' },
  { locale: 'hu-HU', currency: 'HUF' },
  { locale: 'hu', currency: '' },
  { locale: 'hy-AM', currency: 'AMD' },
  { locale: 'hy', currency: '' },
  { locale: 'ia-001', currency: 'XDR' },
  { locale: 'ia-FR', currency: 'EUR' },
  { locale: 'ia', currency: '' },
  { locale: 'ibb-NG', currency: 'NGN' },
  { locale: 'ibb', currency: '' },
  { locale: 'id-ID', currency: 'IDR' },
  { locale: 'id', currency: '' },
  { locale: 'ig-NG', currency: 'NGN' },
  { locale: 'ig', currency: '' },
  { locale: 'ii-CN', currency: 'CNY' },
  { locale: 'ii', currency: '' },
  { locale: 'is-IS', currency: 'ISK' },
  { locale: 'is', currency: '' },
  { locale: 'it-CH', currency: 'CHF' },
  { locale: 'it-IT', currency: 'EUR' },
  { locale: 'it-SM', currency: 'EUR' },
  { locale: 'it', currency: '' },
  { locale: 'iu-Cans-CA', currency: 'CAD' },
  { locale: 'iu-Cans', currency: '' },
  { locale: 'iu-Latn-CA', currency: 'CAD' },
  { locale: 'iu-Latn', currency: '' },
  { locale: 'iu', currency: '' },
  { locale: 'ja-JP', currency: 'JPY' },
  { locale: 'ja', currency: '' },
  { locale: 'jgo-CM', currency: 'XAF' },
  { locale: 'jgo', currency: '' },
  { locale: 'jmc-TZ', currency: 'TZS' },
  { locale: 'jmc', currency: '' },
  { locale: 'jv-Java-ID', currency: 'IDR' },
  { locale: 'jv-Java', currency: '' },
  { locale: 'jv-Latn-ID', currency: 'IDR' },
  { locale: 'jv-Latn', currency: '' },
  { locale: 'jv', currency: '' },
  { locale: 'ka-GE', currency: 'GEL' },
  { locale: 'ka', currency: '' },
  { locale: 'kab-DZ', currency: 'DZD' },
  { locale: 'kab', currency: '' },
  { locale: 'kam-KE', currency: 'KES' },
  { locale: 'kam', currency: '' },
  { locale: 'kde-TZ', currency: 'TZS' },
  { locale: 'kde', currency: '' },
  { locale: 'kea-CV', currency: 'CVE' },
  { locale: 'kea', currency: '' },
  { locale: 'khq-ML', currency: 'XOF' },
  { locale: 'khq', currency: '' },
  { locale: 'ki-KE', currency: 'KES' },
  { locale: 'ki', currency: '' },
  { locale: 'kk-KZ', currency: 'KZT' },
  { locale: 'kk', currency: '' },
  { locale: 'kkj-CM', currency: 'XAF' },
  { locale: 'kkj', currency: '' },
  { locale: 'kl-GL', currency: 'DKK' },
  { locale: 'kl', currency: '' },
  { locale: 'kln-KE', currency: 'KES' },
  { locale: 'kln', currency: '' },
  { locale: 'km-KH', currency: 'KHR' },
  { locale: 'km', currency: '' },
  { locale: 'kn-IN', currency: 'INR' },
  { locale: 'kn', currency: '' },
  { locale: 'ko-KP', currency: 'KPW' },
  { locale: 'ko-KR', currency: 'KRW' },
  { locale: 'ko', currency: '' },
  { locale: 'kok-IN', currency: 'INR' },
  { locale: 'kok', currency: '' },
  { locale: 'kr-NG', currency: 'NGN' },
  { locale: 'kr', currency: '' },
  { locale: 'ks-Arab-IN', currency: 'INR' },
  { locale: 'ks-Arab', currency: '' },
  { locale: 'ks-Deva-IN', currency: 'INR' },
  { locale: 'ks-Deva', currency: '' },
  { locale: 'ks', currency: '' },
  { locale: 'ksb-TZ', currency: 'TZS' },
  { locale: 'ksb', currency: '' },
  { locale: 'ksf-CM', currency: 'XAF' },
  { locale: 'ksf', currency: '' },
  { locale: 'ksh-DE', currency: 'EUR' },
  { locale: 'ksh', currency: '' },
  { locale: 'ku-Arab-IQ', currency: 'IQD' },
  { locale: 'ku-Arab-IR', currency: 'IRR' },
  { locale: 'ku-Arab', currency: '' },
  { locale: 'ku', currency: '' },
  { locale: 'kw-GB', currency: 'GBP' },
  { locale: 'kw', currency: '' },
  { locale: 'ky-KG', currency: 'KGS' },
  { locale: 'ky', currency: '' },
  { locale: 'la-001', currency: 'XDR' },
  { locale: 'la', currency: '' },
  { locale: 'lag-TZ', currency: 'TZS' },
  { locale: 'lag', currency: '' },
  { locale: 'lb-LU', currency: 'EUR' },
  { locale: 'lb', currency: '' },
  { locale: 'lg-UG', currency: 'UGX' },
  { locale: 'lg', currency: '' },
  { locale: 'lkt-US', currency: 'USD' },
  { locale: 'lkt', currency: '' },
  { locale: 'ln-AO', currency: 'AOA' },
  { locale: 'ln-CD', currency: 'CDF' },
  { locale: 'ln-CF', currency: 'XAF' },
  { locale: 'ln-CG', currency: 'XAF' },
  { locale: 'ln', currency: '' },
  { locale: 'lo-LA', currency: 'LAK' },
  { locale: 'lo', currency: '' },
  { locale: 'lrc-IQ', currency: 'IQD' },
  { locale: 'lrc-IR', currency: 'IRR' },
  { locale: 'lrc', currency: '' },
  { locale: 'lt-LT', currency: 'EUR' },
  { locale: 'lt', currency: '' },
  { locale: 'lu-CD', currency: 'CDF' },
  { locale: 'lu', currency: '' },
  { locale: 'luo-KE', currency: 'KES' },
  { locale: 'luo', currency: '' },
  { locale: 'luy-KE', currency: 'KES' },
  { locale: 'luy', currency: '' },
  { locale: 'lv-LV', currency: 'EUR' },
  { locale: 'lv', currency: '' },
  { locale: 'mas-KE', currency: 'KES' },
  { locale: 'mas-TZ', currency: 'TZS' },
  { locale: 'mas', currency: '' },
  { locale: 'mer-KE', currency: 'KES' },
  { locale: 'mer', currency: '' },
  { locale: 'mfe-MU', currency: 'MUR' },
  { locale: 'mfe', currency: '' },
  { locale: 'mg-MG', currency: 'MGA' },
  { locale: 'mg', currency: '' },
  { locale: 'mgh-MZ', currency: 'MZN' },
  { locale: 'mgh', currency: '' },
  { locale: 'mgo-CM', currency: 'XAF' },
  { locale: 'mgo', currency: '' },
  { locale: 'mi-NZ', currency: 'NZD' },
  { locale: 'mi', currency: '' },
  { locale: 'ml', currency: '' },
  { locale: 'mk-MK', currency: 'MKD' },
  { locale: 'mk', currency: '' },
  { locale: 'ml-IN', currency: 'INR' },
  { locale: 'mn-Cyrl', currency: '' },
  { locale: 'mn-MN', currency: 'MNT' },
  { locale: 'mn-Mong-CN', currency: 'CNY' },
  { locale: 'mn-Mong-MN', currency: 'MNT' },
  { locale: 'mn-Mong', currency: '' },
  { locale: 'mn', currency: '' },
  { locale: 'mni-IN', currency: 'INR' },
  { locale: 'mni', currency: '' },
  { locale: 'moh-CA', currency: 'CAD' },
  { locale: 'moh', currency: '' },
  { locale: 'mr-IN', currency: 'INR' },
  { locale: 'mr', currency: '' },
  { locale: 'ms-BN', currency: 'BND' },
  { locale: 'ms-MY', currency: 'MYR' },
  { locale: 'ms-SG', currency: 'SGD' },
  { locale: 'ms', currency: '' },
  { locale: 'mt-MT', currency: 'EUR' },
  { locale: 'mt', currency: '' },
  { locale: 'mua-CM', currency: 'XAF' },
  { locale: 'mua', currency: '' },
  { locale: 'my-MM', currency: 'MMK' },
  { locale: 'my', currency: '' },
  { locale: 'mzn-IR', currency: 'IRR' },
  { locale: 'mzn', currency: '' },
  { locale: 'naq-NA', currency: 'NAD' },
  { locale: 'naq', currency: '' },
  { locale: 'nb-NO', currency: 'NOK' },
  { locale: 'nb-SJ', currency: 'NOK' },
  { locale: 'nb', currency: '' },
  { locale: 'nd-ZW', currency: 'USD' },
  { locale: 'nd', currency: '' },
  { locale: 'ne-IN', currency: 'INR' },
  { locale: 'ne-NP', currency: 'NPR' },
  { locale: 'ne', currency: '' },
  { locale: 'nl-AW', currency: 'AWG' },
  { locale: 'nl-BE', currency: 'EUR' },
  { locale: 'nl-BQ', currency: 'USD' },
  { locale: 'nl-CW', currency: 'ANG' },
  { locale: 'nl-NL', currency: 'EUR' },
  { locale: 'nl-SR', currency: 'SRD' },
  { locale: 'nl-SX', currency: 'ANG' },
  { locale: 'nl', currency: '' },
  { locale: 'nmg-CM', currency: 'XAF' },
  { locale: 'nmg', currency: '' },
  { locale: 'nn-NO', currency: 'NOK' },
  { locale: 'nn', currency: '' },
  { locale: 'nnh-CM', currency: 'XAF' },
  { locale: 'nnh', currency: '' },
  { locale: 'no', currency: '' },
  { locale: 'nqo-GN', currency: 'GNF' },
  { locale: 'nqo', currency: '' },
  { locale: 'nr-ZA', currency: 'ZAR' },
  { locale: 'nr', currency: '' },
  { locale: 'nso-ZA', currency: 'ZAR' },
  { locale: 'nso', currency: '' },
  { locale: 'nus-SS', currency: 'SSP' },
  { locale: 'nus', currency: '' },
  { locale: 'nyn-UG', currency: 'UGX' },
  { locale: 'nyn', currency: '' },
  { locale: 'oc-FR', currency: 'EUR' },
  { locale: 'oc', currency: '' },
  { locale: 'om-ET', currency: 'ETB' },
  { locale: 'om-KE', currency: 'KES' },
  { locale: 'om', currency: '' },
  { locale: 'or-IN', currency: 'INR' },
  { locale: 'or', currency: '' },
  { locale: 'os-GE', currency: 'GEL' },
  { locale: 'os-RU', currency: 'RUB' },
  { locale: 'os', currency: '' },
  { locale: 'pa-Arab-PK', currency: 'PKR' },
  { locale: 'pa-Arab', currency: '' },
  { locale: 'pa-IN', currency: 'INR' },
  { locale: 'pa', currency: '' },
  { locale: 'pap-029', currency: 'USD' },
  { locale: 'pap', currency: '' },
  { locale: 'pl-PL', currency: 'PLN' },
  { locale: 'pl', currency: '' },
  { locale: 'prg-001', currency: 'XDR' },
  { locale: 'prg', currency: '' },
  { locale: 'prs-AF', currency: 'AFN' },
  { locale: 'prs', currency: '' },
  { locale: 'ps-AF', currency: 'AFN' },
  { locale: 'ps', currency: '' },
  { locale: 'pt-AO', currency: 'AOA' },
  { locale: 'pt-BR', currency: 'BRL' },
  { locale: 'pt-CV', currency: 'CVE' },
  { locale: 'pt-GW', currency: 'XOF' },
  { locale: 'pt-MO', currency: 'MOP' },
  { locale: 'pt-MZ', currency: 'MZN' },
  { locale: 'pt-PT', currency: 'EUR' },
  { locale: 'pt-ST', currency: 'STD' },
  { locale: 'pt-TL', currency: 'USD' },
  { locale: 'pt', currency: '' },
  { locale: 'quc-Latn-GT', currency: 'GTQ' },
  { locale: 'quc-Latn', currency: '' },
  { locale: 'quc', currency: '' },
  { locale: 'quz-BO', currency: 'BOB' },
  { locale: 'quz-EC', currency: 'USD' },
  { locale: 'quz-PE', currency: 'PEN' },
  { locale: 'quz', currency: '' },
  { locale: 'rm-CH', currency: 'CHF' },
  { locale: 'rm', currency: '' },
  { locale: 'rn-BI', currency: 'BIF' },
  { locale: 'rn', currency: '' },
  { locale: 'ro-MD', currency: 'MDL' },
  { locale: 'ro-RO', currency: 'RON' },
  { locale: 'ro', currency: '' },
  { locale: 'rof-TZ', currency: 'TZS' },
  { locale: 'rof', currency: '' },
  { locale: 'ru-BY', currency: 'BYN' },
  { locale: 'ru-KG', currency: 'KGS' },
  { locale: 'ru-KZ', currency: 'KZT' },
  { locale: 'ru-MD', currency: 'MDL' },
  { locale: 'ru-RU', currency: 'RUB' },
  { locale: 'ru-UA', currency: 'UAH' },
  { locale: 'ru', currency: '' },
  { locale: 'rw-RW', currency: 'RWF' },
  { locale: 'rw', currency: '' },
  { locale: 'rwk-TZ', currency: 'TZS' },
  { locale: 'rwk', currency: '' },
  { locale: 'sa-IN', currency: 'INR' },
  { locale: 'sa', currency: '' },
  { locale: 'sah-RU', currency: 'RUB' },
  { locale: 'sah', currency: '' },
  { locale: 'saq-KE', currency: 'KES' },
  { locale: 'saq', currency: '' },
  { locale: 'sbp-TZ', currency: 'TZS' },
  { locale: 'sbp', currency: '' },
  { locale: 'sd-Arab-PK', currency: 'PKR' },
  { locale: 'sd-Arab', currency: '' },
  { locale: 'sd-Deva-IN', currency: 'INR' },
  { locale: 'sd-Deva', currency: '' },
  { locale: 'sd', currency: '' },
  { locale: 'se-FI', currency: 'EUR' },
  { locale: 'se-NO', currency: 'NOK' },
  { locale: 'se-SE', currency: 'SEK' },
  { locale: 'se', currency: '' },
  { locale: 'seh-MZ', currency: 'MZN' },
  { locale: 'seh', currency: '' },
  { locale: 'ses-ML', currency: 'XOF' },
  { locale: 'ses', currency: '' },
  { locale: 'sg-CF', currency: 'XAF' },
  { locale: 'sg', currency: '' },
  { locale: 'shi-Latn-MA', currency: 'MAD' },
  { locale: 'shi-Latn', currency: '' },
  { locale: 'shi-Tfng-MA', currency: 'MAD' },
  { locale: 'shi-Tfng', currency: '' },
  { locale: 'shi', currency: '' },
  { locale: 'si-LK', currency: 'LKR' },
  { locale: 'si', currency: '' },
  { locale: 'sk-SK', currency: 'EUR' },
  { locale: 'sk', currency: '' },
  { locale: 'sl-SI', currency: 'EUR' },
  { locale: 'sl', currency: '' },
  { locale: 'sma-NO', currency: 'NOK' },
  { locale: 'sma-SE', currency: 'SEK' },
  { locale: 'sma', currency: '' },
  { locale: 'smj-NO', currency: 'NOK' },
  { locale: 'smj-SE', currency: 'SEK' },
  { locale: 'smj', currency: '' },
  { locale: 'smn-FI', currency: 'EUR' },
  { locale: 'smn', currency: '' },
  { locale: 'sms-FI', currency: 'EUR' },
  { locale: 'sms', currency: '' },
  { locale: 'sn-Latn-ZW', currency: 'USD' },
  { locale: 'sn-Latn', currency: '' },
  { locale: 'sn', currency: '' },
  { locale: 'so-DJ', currency: 'DJF' },
  { locale: 'so-ET', currency: 'ETB' },
  { locale: 'so-KE', currency: 'KES' },
  { locale: 'so-SO', currency: 'SOS' },
  { locale: 'so', currency: '' },
  { locale: 'sq-AL', currency: 'ALL' },
  { locale: 'sq-MK', currency: 'MKD' },
  { locale: 'sq-XK', currency: 'EUR' },
  { locale: 'sq', currency: '' },
  { locale: 'sr-Cyrl-BA', currency: 'BAM' },
  { locale: 'sr-Cyrl-ME', currency: 'EUR' },
  { locale: 'sr-Cyrl-RS', currency: 'RSD' },
  { locale: 'sr-Cyrl-XK', currency: 'EUR' },
  { locale: 'sr-Cyrl', currency: '' },
  { locale: 'sr-Latn-BA', currency: 'BAM' },
  { locale: 'sr-Latn-ME', currency: 'EUR' },
  { locale: 'sr-Latn-RS', currency: 'RSD' },
  { locale: 'sr-Latn-XK', currency: 'EUR' },
  { locale: 'sr-Latn', currency: '' },
  { locale: 'sr', currency: '' },
  { locale: 'ss-SZ', currency: 'SZL' },
  { locale: 'ss-ZA', currency: 'ZAR' },
  { locale: 'ss', currency: '' },
  { locale: 'ssy-ER', currency: 'ERN' },
  { locale: 'ssy', currency: '' },
  { locale: 'st-LS', currency: 'ZAR' },
  { locale: 'st-ZA', currency: 'ZAR' },
  { locale: 'st', currency: '' },
  { locale: 'sv-AX', currency: 'EUR' },
  { locale: 'sv-FI', currency: 'EUR' },
  { locale: 'sv-SE-custom', currency: 'SEK' },
  { locale: 'sv-SE-old', currency: 'SEK' },
  { locale: 'sv-SE', currency: 'SEK' },
  { locale: 'sv', currency: '' },
  { locale: 'sw-CD', currency: 'CDF' },
  { locale: 'sw-KE', currency: 'KES' },
  { locale: 'sw-TZ', currency: 'TZS' },
  { locale: 'sw-UG', currency: 'UGX' },
  { locale: 'sw', currency: '' },
  { locale: 'syr-SY', currency: 'SYP' },
  { locale: 'syr', currency: '' },
  { locale: 'ta-IN', currency: 'INR' },
  { locale: 'ta-LK', currency: 'LKR' },
  { locale: 'ta-MY', currency: 'MYR' },
  { locale: 'ta-SG', currency: 'SGD' },
  { locale: 'ta', currency: '' },
  { locale: 'te-IN', currency: 'INR' },
  { locale: 'te', currency: '' },
  { locale: 'teo-KE', currency: 'KES' },
  { locale: 'teo-UG', currency: 'UGX' },
  { locale: 'teo', currency: '' },
  { locale: 'tg-Cyrl-TJ', currency: 'TJS' },
  { locale: 'tg-Cyrl', currency: '' },
  { locale: 'tg', currency: '' },
  { locale: 'th-TH', currency: 'THB' },
  { locale: 'th', currency: '' },
  { locale: 'ti-ER', currency: 'ERN' },
  { locale: 'ti-ET', currency: 'ETB' },
  { locale: 'ti', currency: '' },
  { locale: 'tig-ER', currency: 'ERN' },
  { locale: 'tig', currency: '' },
  { locale: 'tk-TM', currency: 'TMT' },
  { locale: 'tk', currency: '' },
  { locale: 'tn-BW', currency: 'BWP' },
  { locale: 'tn-ZA', currency: 'ZAR' },
  { locale: 'tn', currency: '' },
  { locale: 'to-TO', currency: 'TOP' },
  { locale: 'to', currency: '' },
  { locale: 'tr-CY', currency: 'EUR' },
  { locale: 'tr-TR', currency: 'TRY' },
  { locale: 'tr', currency: '' },
  { locale: 'ts-ZA', currency: 'ZAR' },
  { locale: 'ts', currency: '' },
  { locale: 'tt-RU', currency: 'RUB' },
  { locale: 'tt', currency: '' },
  { locale: 'twq-NE', currency: 'XOF' },
  { locale: 'twq', currency: '' },
  { locale: 'tzm-Arab-MA', currency: 'MAD' },
  { locale: 'tzm-Arab', currency: '' },
  { locale: 'tzm-Latn-DZ', currency: 'DZD' },
  { locale: 'tzm-Latn-MA', currency: 'MAD' },
  { locale: 'tzm-Latn', currency: '' },
  { locale: 'tzm-Tfng-MA', currency: 'MAD' },
  { locale: 'tzm-Tfng', currency: '' },
  { locale: 'tzm', currency: '' },
  { locale: 'ug-CN', currency: 'CNY' },
  { locale: 'ug', currency: '' },
  { locale: 'uk-UA', currency: 'UAH' },
  { locale: 'uk', currency: '' },
  { locale: 'ur-IN', currency: 'INR' },
  { locale: 'ur-PK', currency: 'PKR' },
  { locale: 'ur', currency: '' },
  { locale: 'uz-Arab-AF', currency: 'AFN' },
  { locale: 'uz-Arab', currency: '' },
  { locale: 'uz-Cyrl-UZ', currency: 'UZS' },
  { locale: 'uz-Cyrl', currency: '' },
  { locale: 'uz-Latn-UZ', currency: 'UZS' },
  { locale: 'uz-Latn', currency: '' },
  { locale: 'uz', currency: '' },
  { locale: 'vai-Latn-LR', currency: 'LRD' },
  { locale: 'vai-Latn', currency: '' },
  { locale: 'vai-Vaii-LR', currency: 'LRD' },
  { locale: 'vai-Vaii', currency: '' },
  { locale: 'vai', currency: '' },
  { locale: 've-ZA', currency: 'ZAR' },
  { locale: 've', currency: '' },
  { locale: 'vi-VN', currency: 'VND' },
  { locale: 'vi', currency: '' },
  { locale: 'vo-001', currency: 'XDR' },
  { locale: 'vo', currency: '' },
  { locale: 'vun-TZ', currency: 'TZS' },
  { locale: 'vun', currency: '' },
  { locale: 'wae-CH', currency: 'CHF' },
  { locale: 'wae', currency: '' },
  { locale: 'wal-ET', currency: 'ETB' },
  { locale: 'wal', currency: '' },
  { locale: 'wo-SN', currency: 'XOF' },
  { locale: 'wo', currency: '' },
  { locale: 'xh-ZA', currency: 'ZAR' },
  { locale: 'xh', currency: '' },
  { locale: 'xog-UG', currency: 'UGX' },
  { locale: 'xog', currency: '' },
  { locale: 'yav-CM', currency: 'XAF' },
  { locale: 'yav', currency: '' },
  { locale: 'yi-001', currency: 'XDR' },
  { locale: 'yi', currency: '' },
  { locale: 'yo-BJ', currency: 'XOF' },
  { locale: 'yo-NG', currency: 'NGN' },
  { locale: 'yo', currency: '' },
  { locale: 'zgh-Tfng-MA', currency: 'MAD' },
  { locale: 'zgh-Tfng', currency: '' },
  { locale: 'zgh', currency: '' },
  { locale: 'zh-CHS', currency: '' },
  { locale: 'zh-CHT', currency: '' },
  { locale: 'zh-CN', currency: 'CNY' },
  { locale: 'zh-Hans-HK', currency: 'HKD' },
  { locale: 'zh-Hans-MO', currency: 'MOP' },
  { locale: 'zh-Hans', currency: '' },
  { locale: 'zh-Hant', currency: '' },
  { locale: 'zh-HK', currency: 'HKD' },
  { locale: 'zh-MO', currency: 'MOP' },
  { locale: 'zh-SG', currency: 'SGD' },
  { locale: 'zh-TW', currency: 'TWD' },
  { locale: 'zh', currency: '' },
  { locale: 'zu-ZA', currency: 'ZAR' },
  { locale: 'zu', currency: '' }
]

const defaultLocale = { locale: 'en-US', currency: 'USD' }

const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()

const numberFormat = locale => {
  const r = numberFormats.find(l => l.locale === locale) || defaultLocale
  r.symbol = getCurrencySymbol(locale, r.currency)
  return r
}
module.exports = {
  numberFormat
}

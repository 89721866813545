const applyControlAutos = function (t, ow) {
  if (typeof t.owautoOpts === 'undefined') {
    t.owautoOpts = []

    // anything with a class auto-xxx will try to
    // call ow.controls.xxx(el, e.opts)
    let classAutos = (t.className || '').split(' ')
    classAutos.forEach(cls => {
      if (cls && cls.substr(0, 5) === 'auto-') {
        let newCls = cls.substr(5)
        t.classList.add(newCls) // add xxx
        t.classList.remove(cls) // remove auto-xxx
        t.owautoOpts.push(newCls)
      }
    })
  }

  if (!t.owautoOpts.length) {
    ow5.ctlType(t)
    window.autosApplied = (window.autosApplied || 0) + 1
  }

  t.owautoOpts.forEach(autoInit => {
    // if it's a string make it an object
    if (typeof autoInit === 'string') autoInit = { init: autoInit }

    t.classList.add(autoInit.init)
    t.opts = autoInit

    // Call the behaviour adding functions
    const fn = ow.controls[autoInit.init]
    if (typeof fn !== 'function')
      console.warn(`ow${ow.version}.controls[${autoInit.init}] NOT FOUND`)
    else fn(t, autoInit)

    if (t.onInit) {
      t.onInit(t, t.opts)
      delete t.onInit
    }
    window.autosApplied = (window.autosApplied || 0) + 1
  })
}

/**
 * Gathers auto options, works out what to apply and applies ow auto inits
 * This function is to be called when dynamic html is added to the dom
 * Must have owauto
 * if no data-owauto-opts, checks through classnames for auto-xxx legacy functions
 * then checks through data-owauto-opts
 *
 * @param {HTMLElement} t - the element to check and apply, has class owauto
 * @param {Array<string,Object>)} t.data-owauto-opts - the element to check and apply, has class owauto
 * @param {string} t.className - (HTML attribute class) must have 'owauto', for legacy autos can have auto-xxx where autos[xxx] is an init function
 * @returns null
 */
const applyAutos = function (t, ow) {
  if (!t.classList.contains('owauto')) return
  t.classList.remove('owauto') // this will not run again

  if (t.hasAttribute('data-owauto-opts')) {
    // owautos for basicEd etc
    let s = t.getAttribute('data-owauto-opts') // reads as string
    t.removeAttribute('data-owauto-opts')
    // translation
    let sTranslated
    try {
      sTranslated = ow.viewPreprocessor('viewId', s, __)
      t.owautoOpts = JSON.parse(sTranslated)
    } catch (err) {
      console.error('Problem with OwAuto Opts attribute', s, sTranslated, err)
    }
  }
  applyControlAutos(t, ow)
}

const $owLib = el => {
  let d = $(el).data()
  if (d.ow) return d.ow
  if (el.classList.contains('ow5')) {
    d.ow = ow5
    return d.ow
  }
  if (el.classList.contains('ow4')) {
    d.ow = ow4
    return d.ow
  }
  if (el.classList.contains('ow0')) {
    d.ow = ow0
    return d.ow
  }

  if (el.classList.contains('k-window')) {
    // need to set view.ow?
    return ow0
  }

  if (el.classList.contains('win-con')) {
    var $top = common.cmp.qc(el).$top
    if ($top?.ow) return $top.ow

    if ($(el).find('.ow5').length) {
      d.ow = ow5
      return d.ow
    }
    if ($(el).find('.ow4').length) {
      d.ow = ow4
      return d.ow
    }
  }

  if (!el.parentElement) return ow0
  return $owLib(el.parentElement)
}

const callAutosOn = function (t, ow) {
  if (t.nodeType !== 1) return // t.nodeType !== 1 && t.nodeType !== 11) return // element or document fragment
  if (t.closest && !t.closest('body')) return // console.warn('exit owautos - not connected to the dom', t)

  // do the first kid until there aren't any more to do.
  let kid = t.querySelector('.owauto')
  while (kid) {
    window.autoSearches = (window.autoSearches || 0) + 1
    ow = ow || $owLib(kid) // calls the first time
    callAutosOn(kid, ow)
    window.autoSearches = (window.autoSearches || 0) + 1
    kid = t.querySelector('.owauto')
  }

  if (t.classList && t.classList.contains('owauto')) {
    applyAutos(t, ow || $owLib(t))
    window.autoSearches = (window.autoSearches || 0) + 1
  }
}

const hookElementMethod = (nodeType, method) => {
  const _super = nodeType.prototype[method]
  if (!_super) return console.error(method, ' not found')

  nodeType.prototype[method] = function (...args) {
    const callOn = args.map(arg => (arg.nodeType === 11 ? this : arg))
    var r = _super.apply(this, args)
    if (this.nodeType === 1) callOn.forEach(callAutosOn)
    return r
  }
}
hookElementMethod(HTMLElement, 'replaceChildren')
hookElementMethod(HTMLElement, 'prepend')
hookElementMethod(HTMLElement, 'append')
hookElementMethod(HTMLElement, 'after')
hookElementMethod(HTMLElement, 'before')
hookElementMethod(HTMLElement, 'replaceWith')

hookElementMethod(Node, 'appendChild') // Node NOT Element method

{
  const _super = Node.prototype.insertBefore
  if (!_super) console.error('Node.insertBefore not found')
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    const callOn = newNode === 11 ? this : newNode
    const r = _super.call(this, newNode, referenceNode)
    callAutosOn(callOn)
    return r
  }
}
{
  const _super = Node.prototype.replaceChild
  if (!_super) console.error('Node.replaceChild not found')
  Node.prototype.replaceChild = function (newNode, referenceNode) {
    const callOn = newNode === 11 ? this : newNode
    const r = _super.call(this, newNode, referenceNode)
    callAutosOn(callOn)
    return r
  }
}

const overrideProto = function (objectProto, property) {
  let ownObjectProto = objectProto

  while (!Object.getOwnPropertyDescriptor(ownObjectProto, property)) {
    ownObjectProto = Object.getPrototypeOf(ownObjectProto)
  }

  const ownProperty = Object.getOwnPropertyDescriptor(ownObjectProto, property)
  Object.defineProperty(objectProto, property, {
    get() {
      return ownProperty.get.call(this)
    },

    set(val) {
      ownProperty.set.call(this, val)
      callAutosOn(this)
    }
  })
}

overrideProto(HTMLElement.prototype, 'innerHTML')
overrideProto(HTMLElement.prototype, 'outerHTML')

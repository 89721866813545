const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')

/**
 * @namespace
 */
module.exports.ow7 = {
  ...require('./data-validation7'),

  ...require('./cmenu7'),
  ...require('./filters'),
  ...require('./databind7'),
  ...require('./data-model7'),
  ...require('./dc7'),
  ...require('./collectionController7'),
  ...require('./gridPager7'),
  ...require('./rs7'),
  ...require('./check7'),
  ...require('./text7'),
  ...require('./combo7'),
  ...require('./currentStore7'),
  ...require('./float7'),
  ...require('./int7'),
  ...require('./date7'),
  ...require('./datetime7'),
  ...require('./time7'),
  ...require('./button7'),
  ...require('./fileUpload7'),
  ...require('./toggleBtns7'),
  ...require('./multiSelect7'),
  ...require('./richtexteditor7'),
  ...require('./scheduler7/scheduler7'),
  ...require('./grid7'),
  ...require('./footerBar7'),
  ...require('./tabstrip7'),
  ...require('./headerBar7'),
  ...require('./modalWindow7'),
  ...require('./wireUpView7')
}

qc('style.styles7css', html(require('./styles7').styles7())).renderTo(document.head)
qc('style.grid7css', html(require('./grid7css').grid7Css())).renderTo(document.head)

/**
 * scoped broadcast io.socket topic 'long-running'
 *
 * Your app must call :
 *
 * io.socket.on('long-running', onLongRunning)
 *
 * or equivalent
 *
 * This has been set up release resources when the view is closed
 *
 */

const { qc } = require('./cmp/qc')

const initLongRunning = (scope = document.body) => {
  if (scope.longRunningProcesses !== undefined) return
  scope.longRunningProcesses = {}

  qc(scope)
    .addClass('long-running-subscriber')
    .on('long-running', (e, r) => {
      const f = scope.longRunningProcesses[r.uid]
      if (f) {
        console.log('LRTask arrived,', r.data.taskTook, r.uid)
        delete scope.longRunningProcesses[r.uid]
        //   return console.warn('LongRunning arrived but no subscriber function not found', r.uid, r)
        f(r.data)
      }
    })
}

const longRunning = (opts, myFetch) => {
  if (opts.LRTask === false) return myFetch
  const scope = opts.top ?? opts.view?.qTop.el ?? document.body

  return (url, fetchOpts) => {
    fetchOpts.headers = { ...(fetchOpts.headers ?? {}), 'long-running': 'true' }
    return myFetch(url, fetchOpts).then(r => {
      if (!r?.longRunning) return r
      console.log('Switching to Long Running Task mode.', url)
      initLongRunning(scope)

      return new Promise((resolve, reject) => {
        let giveUp = () => {
          if (!scope.closest('body'))
            return console.log('LongRunning Task arrived but the view has been closed.')

          if (scope.longRunningProcesses[r.uid]) {
            // todo: prompt to continue waiting?
            delete scope.longRunningProcesses[r.uid]
            ow0.popError(__('Error'), __('Your request took too long.'))
            reject({ success: false, errMsg: __('Timed out') })
          }
        }
        let handle = setTimeout(giveUp, 5 * 60 * 1000) // 5 min

        scope.longRunningProcesses[r.uid] = x => {
          clearTimeout(handle)
          x.status === 200 ? resolve(x.data) : reject(x.data)
        }
      })
    })
  }
}

module.exports = { longRunning }

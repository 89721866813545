const { addCss } = require('../add-css')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { humanize } = require('../humanize')
const theme = require('../theme')
const { commandButton7 } = require('./commandButton7')
const { input7 } = require('./text7')

function assignHeaderBtnIconProp(option) {
  if (option.icon.display !== false) {
    option.icon.display = true
    option.icon.align = option.icon.align || 'left'
    option.icon.color = option.icon.color || ''
  }
  return option
}

function assignFooterBtnIconProp(option) {
  if (option.icon.display === false) return option

  if (option.icon.align || option.icon.color || option.icon.name) {
    option.icon.display = true
  }

  if (option.icon.display) {
    option.icon.align = option.icon.align || ''
    option.icon.color = option.icon.color || ''
    option.icon.name = option.icon.name || ''
  }

  return option
}

const generateButtonType = (arrButtons, type, dsName, view) => {
  const value = []

  if (!Array.isArray(arrButtons)) return generateButtonType([arrButtons])

  // Generate all required button type
  arrButtons.forEach(option => {
    let dataCommand = ''
    let isAcceptedType = true
    let buttonColor = ''

    // if (option === 'advanced') return
    if (option === 'quick-search') return

    // if it's already a Cmp object
    const isCmp = typeof option === 'object' && 'render' in option
    if (isCmp) return value.push(option)

    if (option.html) {
      value.push(html(option.html))
      return
    }

    if (typeof option === 'object' && option.type === undefined)
      option.type = option.html ? 'html' : 'custom'

    if (typeof option === 'string')
      option = {
        name: humanize(option),
        type: option,
        view,
        command: option,
        class: option === 'save' ? 'green-button' : undefined
      }

    option.targetRef = option.targetRef || dsName
    option.icon = option.icon || {}

    option.type = option.type.toLowerCase()

    option.view = view

    // If button type is match with standard support buttons. We will set the default behaviour of button.
    // If icon.display is undefined, we will set the default configuration for button at first.
    if (type === 'header') {
      if (option.type.match(/new/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-plus'
        dataCommand = 'new'
      } else if (option.type.match(/edit/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-pencil'
        dataCommand = 'edit'
      } else if (option.type.match(/delete/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-trash'
        dataCommand = 'delete'
      } else if (option.type.match(/copy/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-clone'
        dataCommand = 'copy'
      } else if (option.type.match(/print/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-print'
        dataCommand = 'print'
      } else if (option.type.match(/email/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-envelope'
        dataCommand = 'email'
      } else if (option.type.match(/refresh/i)) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-refresh'
        dataCommand = 'refresh'
      } else if (option.type.match(/advanced/i) !== null) {
        option = assignHeaderBtnIconProp(option)
        option.icon.name = option.icon.name || 'fa fa-caret-down'
        dataCommand = 'advanced'
      } else if (option.type.match(/custom/gi)) {
        option = assignHeaderBtnIconProp(option)
      } else if (option.type === 'html') {
        value.push(html(option.html))
        isAcceptedType = false
      } else if (option.type.match(/divider/i)) {
        value.push(qc('label', '|'))
        isAcceptedType = false
      }
    } else if (type === 'footer') {
      // If button type is match with standard support buttons. We will set the default behaviour of button.
      // If icon.display is undefined, we will set the default configuration for button at first.
      if (option.type.match(/add|add-row/i)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-plus-circle'
        }

        dataCommand = option.command || option.type
        isAcceptedType = true
      } else if (option.type.match(/add|add row/i)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-plus-circle'
        }

        dataCommand = option.command || 'add'
        isAcceptedType = true
      } else if (option.type.match(/clear|clear value|clean/gi)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'black'
          option.icon.name = option.icon.name || 'fa fa-eraser'
        }

        dataCommand = 'clear'
        isAcceptedType = true
      } else if (option.type.match(/excel|export|share/gi)) {
        if (option.icon.display === undefined || option.icon.display === true) {
          option.icon.display = true
          option.icon.align = option.icon.align || 'left'
          option.icon.color = option.icon.color || 'green'
          option.icon.name = option.icon.name || 'fa fa-share'
        }

        dataCommand = 'export'
        isAcceptedType = true
      } else if (option.type.match(/select/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'green'
        dataCommand = 'select'
        isAcceptedType = true
      } else if (option.type.match(/save/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'green'
        dataCommand = 'save'
        isAcceptedType = true
      } else if (option.type.match(/delete/gi)) {
        option = assignFooterBtnIconProp(option)

        buttonColor = 'red'
        dataCommand = 'delete'
        isAcceptedType = true
      } else if (option.type.match(/close/gi)) {
        option = assignFooterBtnIconProp(option)

        dataCommand = 'close'
        isAcceptedType = true
      } else if (option.type.match(/cancel/gi)) {
        option = assignFooterBtnIconProp(option)

        dataCommand = 'cancel'
        isAcceptedType = true
      } else if (option.type.match(/custom/gi)) {
        option = assignFooterBtnIconProp(option)

        isAcceptedType = true
      }
    }

    // If option.icon.display set to false, we will destruct the icon object
    if (option.icon.display === false) {
      delete option.icon.color
      delete option.icon.name
      delete option.icon.align
    }

    // If color or command is not defined, then we will follow the default color and command for standard button
    option.color = option.color === undefined ? buttonColor : option.color
    option.command = option.command === undefined ? dataCommand : option.command

    // Set class for button
    if (typeof option.class === 'string') option.class = [option.class]

    option.hidden ? option.class.push('hide') : null

    if (!option.name && option.type) option.name = humanize(option.type)

    if (option.name) option.name = __(option.name)

    // Only accept those standard button, any customised button need to
    // configure using option object with type: 'custom'

    if (isAcceptedType) {
      if (typeof option === 'string') option = { type: option, command: option }
      value.push(option.label ? input7(option).rs() : commandButton7(option))
    }
  })

  return value
}

addCss(
  'button-css',
  `
.ow-button[disabled]  {
  color: #a6a6ad;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}

.ow-button {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 100%;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  box-sizing: border-box;
  margin: 0 2px;
}

.ow-button:first-child {  margin-left: 0; }
.ow-button:last-child { margin-right: 0; }

.ow-button i { display: inline; }

.ow-button.green-button[disabled], 
.ow-button.green-button[disabled] {
  color: #a6a6ad !important;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}
`
)

module.exports.generateButtonType = generateButtonType
module.exports.commandButton7 = commandButton7
module.exports.button7 = commandButton7

// data-validation.js
const { _v } = require('../_v')
const { schemaFields, $meta, isDeleted } = require('./data-model7')

const validateField = (model, fieldDef) => {
  // if required and nullish fail
  const sch = $meta(model).schema

  if (sch[fieldDef.fieldName] && sch[fieldDef.fieldName].isCollection) {
  }

  const v = _v(model, fieldDef.fieldName)

  if (fieldDef.required && (v === null || v === undefined)) return false

  return true
}

const rightSideVal = (model, rightSide) =>
  typeof rightSide === 'object' && rightSide.field ? _v(model, rightSide.field) : rightSide

const tests = {
  required: fieldName => model => {
    const v = _v(model, fieldName)
    return v === '' || v === null || v === undefined
      ? { fieldName, model, reason: 'required' }
      : true
  },
  eq: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v === rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'eq', rightSide }
  },
  ne: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v !== rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'ne', rightSide }
  },
  gt: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v > rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'gt', rightSide }
  },
  gte: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v >= rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'gte', rightSide }
  },
  lt: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v < rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'lt', rightSide }
  },

  lte: (fieldName, rightSide) => model => {
    const v = _v(model, fieldName)
    let valid = v <= rightSideVal(model, rightSide)
    return valid ? true : { fieldName, model, reason: 'lte', rightSide }
  }
}

const validateCollection = (modelArray, onFail) =>
  Array.isArray(modelArray)
    ? modelArray.filter((kid, i) => !isDeleted(kid) && !validateModel(kid, onFail, i)).length === 0
    : true

/**
 *
 * @param {object} model
 * @returns {boolean|object} if the model is valid it returns true, otherwise it returns an array of invalids.
 */
const validateModel = model => {
  const results = []
  const fieldDefs = schemaFields(model)
  let isValid = true

  // modelValidators
  fieldDefs
    .reduce((r, fieldDef) => {
      let { validation = {} } = fieldDef
      if (fieldDef.required) validation.required = fieldDef.required

      Object.keys(tests).forEach(
        reason =>
          validation[reason] && r.push(tests[reason](fieldDef.fieldName, validation[reason]))
      )

      if (fieldDef.isModel && !fieldDef.ignore) {
        const kid = model[fieldDef.fieldName]
        const invalids = fieldDef.isCollection ? validateCollection(kid) : validateModel(kid)
        if (invalids !== true) r.push(...invalids)
      }

      return r
    }, [])
    .forEach(fn => {
      const result = fn(model)
      if (result !== true) {
        isValid = false
        results.push(result)
      }
    })

  return isValid || results
}

module.exports = { validateField, validateModel, tests }

const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { culture, __ } = require('../culture')
const { parseDate } = require('../date-parser')
const { iconCodes } = require('../icon-codes')
const { no$, $find, $offset } = require('../no-jquery')
const theme = require('../theme')
const { check7 } = require('./check7')
const { cmenu7 } = require('./cmenu7')
const { combo7 } = require('./combo7')
const { time7 } = require('./time7')
const { date7 } = require('./date7')
const { datetime7 } = require('./datetime7')
const { float7 } = require('./float7')
const { int7 } = require('./int7')
const { text7 } = require('./text7')
const { gridPager7 } = require('./gridPager7')
const { dataBind } = require('./databind7')
const { killEvent } = require('../killEvent')
const { _v } = require('../_v')
const { htmlEncode } = require('../html-encode')
const { $meta, hasChanges, react, cancelChanges } = require('./data-model7')
const { liveGrid7 } = require('./liveGrid7')
const { grid7Css } = require('./grid7css')
const { drawColFilter7 } = require('./drawColFilter7')
const { formatString } = require('../ctl-format')
const { groupSelector } = require('./groupSelector')
const { selectableGrid, toggleSelect, selectableRow, setSelected } = require('./grid7-selection')
const { applyOrderToRow, drawColHeader7 } = require('./drawColHeader7')

const edTypeMap = {
  char: 'text7',
  bit: 'check7',
  check: 'check7',
  boolean: 'check7',
  decimal: 'float7',
  integer: 'int7',
  int: 'int7',
  float: 'float7',
  currency: 'float7',
  date: 'date7',
  datetime: 'datetime7',
  time: 'time7',
  weekdays: 'weekdays',
  timeString: 'time7'
}

/**
 *
 * @param {Array<Object>} cols - OW Grid Columns
 * @param {HTMLelement} grid
 * @param {Object} grid.opts - grid opts
 * @param {boolean=false} grid.opts.hasFilters - if false, the column top filters aren't created
 */
const colsToGridColumns = function (cols, qGrid) {
  // Specifies the type of the field. The available options are "string", "number", "boolean", "date" and "object". The default is "string".
  const { opts } = qGrid

  opts.cols.forEach((col, coli) => {
    col.coli = coli
    const ro = col.readOnly && typeof col.readOnly !== 'function'

    const editable = col.gridCol !== false && !ro && opts.editable !== false

    if (col.type === 'date') col.format = col.format || culture().DateFormatSetting
    if (col.type === 'integer') col.type = 'int'
    if (col.type === 'decimal') col.type = 'float'

    col.name = col.name ?? col.field ?? coli
    col.setting = opts.userSettings?.cols?.[col.name] ?? {}

    if (col.gridCol === false) return

    if (col.width === undefined) col.width = 100

    if (editable)
      col.template =
        col.template ??
        function (model, i) {
          const col = this
          const f = col.field
          const readOnly = cellReadOnly(col, model)

          if (readOnly) return '' + (columnValue(col, model, i, readOnly, false) ?? '&nbsp;')
          const v = columnDataValue(col, model, i, readOnly, true)

          const ctlTypes = { check7, combo7, int7, float7, text7, datetime7, time7, date7 }

          // col.dataType === 'int' || col.type === 'int'
          //     ? int7
          //     : col.dataType === 'date' || col.type === 'date'
          //     ? date7
          //     : col.dataType === 'date' || col.type === 'datetime'
          //     ? datetime7
          //     : col.dataType === 'float' || col.type === 'float7'
          //     ? float7
          //     : col.dataType === 'time' || col.type === 'time7'
          //     ? time7
          //     : text7

          let me = (ctlTypes[col.ctl?.ctlType] ?? text7)({
            view: opts.view,
            inGrid: true,
            label: opts.label ?? col.title ?? f ?? '',
            col,
            model,
            value: v,
            fieldName: f,
            maxLength: col.maxLength ?? col.validation?.maxLength,
            ...(col.ctl ?? {})
          })

          return me.wrap()
        }

    if (col.gridCol !== false) {
      col.width = col.setting.width ?? col.width ?? 100
      col.hidden = col.setting.hidden ?? 0
      col.order = col.setting.order ?? coli
    }
  })

  const result = cols
    .filter(x => x.gridCol !== false)
    .map((col, i) => {
      if (col.editable === false)
        console.warn('gridCol.editable is deprecated, please use gridCol.readOnly: true')

      col.index = i
      Object.assign(
        col,
        Object.assign(
          {
            validation: {},
            attributes: { class: '' },
            title: '',
            align: ''
          },
          col
        )
      )

      // validation
      col.validation =
        typeof col.validation === 'function' ? { validateFunction: col.validation } : col.validation
      col.validation.required = col.validation.required ?? col.ctl?.required ?? col.required

      if ((col.field || '').indexOf('.') > -1 && !col.template && opts.unnestFields) {
        const field = col.field
        col.template =
          col.template ||
          function (d) {
            const v = _v(d, field) ?? ''
            return v && col.format ? formatString(v, col.format) : v
          }
        col.nestedField = field.split('.').join('_')
      }

      if (col.type === 'bit') {
        col.type = 'boolean'
        console.warn('Incorrect col.type: bit.  Please use boolean')
      }

      if (col.type === 'boolean')
        col.template =
          col.template ??
          (col.fullTimeCheck === false
            ? data => {
                const list = [__('false'), __('true')]
                return list[data[col.field] * 1]
              }
            : data => qc('i.icon', data[col.field] ? html(iconCodes.check) : html('&nbsp;')))

      // formats
      if (col.type === 'date') col.format = col.format || culture().DateFormatSetting

      if (col.type === 'datetime') col.format = col.format || culture().DateTimeFormatSetting

      if (col.type === 'time') {
        col.format = col.format || culture().TimeFormatSetting
        col.attributes = col.attributes || {}
        col.attributes.class = (col.attributes.class || '') + ' time'
      }
      if (col.type === 'currency') col.format = col.format || 'c'

      // staticlookup
      col.ctl = col.ctl || {}

      if (col.list) {
        col.ctl.list = col.ctl.list || col.list
        col.ctl.ctlType = 'combo7'
        col.ctl.fieldName = col.ctl.fieldName || col.field
      }

      if (col.type === 'combo') col.ctl.ctlType = col.ctl.ctlType ?? 'combo7'

      if (col.ctl.ctlType === 'combo') col.ctl.ctlType = 'combo7'

      if (!col.ctl.ctlType) col.ctl.ctlType = col.ctlType ?? edTypeMap[col.type] ?? 'text'

      const isLookup = () => col.ctl?.ctlType === 'combo7'
      if (isLookup()) {
        col.align = col.align || 'left'
        const list = col.ctl.list || []

        col.template =
          col.template ||
          function (d) {
            let v = _v(d, col.field)
            if (v === undefined) return ''

            if (d) {
              let matches = list.filter(y => _v(y, col.ctl.valueField) == v)
              if (matches[0]) return matches[0][col.ctl.textField || 'Text']
              if (typeof v === 'number') {
                v = list[parseInt(v)]
                if (typeof v === 'object') v = ''
              }
            }
            return v
          }
      }

      if (col.format) col.ctl.format = col.ctl.format ?? col.format

      col.ctl.inGrid = true
      col.ctl.col = () => col
      col.ctl.fieldName = col.ctl.fieldName ?? col.field
      col.ctl.noLabel = true
      if (col.maxLength) col.ctl.maxLength = col.maxLength

      if (isLookup()) col.attributes.class = (col.attributes.class || '') + ' lookup-col'

      if (col.type && !isLookup()) {
        col.attributes.class =
          (col.attributes.class || '') + (col.type === 'integer' ? ' align-left' : '')
        if (col.align) col.attributes.class += ' align-' + col.align
      }

      // cell templates
      col.kColTemplate = function (d) {
        function readValue(col) {
          let v = typeof col.calc === 'function' ? col.calc(d) : col.field ? _v(d, col.field) : ''
          if (col.type === 'date' || col.type === 'datetime')
            if (typeof v === 'string' && v !== '') v = parseDate(v, col.format)

          return v ?? ''
        }

        const defaultValue = () => {
          let result = readValue(col)
          if (col.format) result = formatString(result, col.format)
          if (col.encoded !== false) result = htmlEncode(result)
          return result
        }

        let val = col.template ? col.template(d, defaultValue()) : defaultValue()
        if (typeof val === 'string' && !col.encoded) val = html(val)
        val = val ?? []

        const classes = col.classes
          ? typeof col.classes === 'function'
            ? col.classes(d)
            : col.classes
          : ''

        const span = cellReadOnly(col, d)
          ? qc('span.read-only', val)
          : qc('span' + (classes ? '.' + classes.split(' ').join('.') : ''), val)

        if (!col.template)
          span.bindState(
            () => defaultValue(),
            val => {
              if (typeof val === 'string' && !col.encoded) val = html(val)
              val = val ?? []
              span.kids(val)
            }
          )

        return span
      }

      // group footer
      if (col.groupFooter) {
        const wrapFooter = content =>
          qc('span.col-' + col.index, content).css({
            textAlign: col.footerAlign || col.align || 'right'
          })

        if (typeof col.groupFooter === 'string') {
          let ag = col.footer
          col.groupFooter = function (d) {
            // FROM R1: if (d[col.field]) return d[ag](col.field, d[col.field] && d[col.field].group)
            // FROM R2: if (d[col.field]) return grid[ag](col.field, d[col.field] && d[col.field].group)
            if (d[col.field])
              return (d[ag] ? d[ag] : qGrid.el[ag])(col.field, d[col.field] && d[col.field].group)
          } // eg. qGrid.sum('Quantity', group)
        }

        if (typeof col.groupFooter === 'function')
          col.groupFooterTemplate = d => wrapFooter(formatString(col.groupFooter(d), col.format))
      }

      col.width = col.width ?? col.defaultWidth
      return col
    })

  return result
}

const bindColWidth = cell =>
  cell
    .bindState(
      () => cell.col.width + 'px',
      width => cell.css({ width })
    )
    .bindState(
      () => (cell.col.hidden ? 'none' : undefined),
      display => cell.css({ display })
    )

const toggleGroup = (qGrid, rec) => {
  const { dc } = qGrid
  rec._group.open = !(rec._group.open ?? dc.opts?.expandAllGroups ?? false)
  dc.applyGroupVisibility()
  qGrid.renderAsync()
}

const expandAllGroups = (g, expand = true) => {
  const qGrid = qc(g)
  const { dc } = qGrid
  if (!dc.groups) return
  Object.values(dc.groups).forEach(r => (r._group.open = expand))
  dc.applyGroupVisibility()
}
const collapseAllGroups = g => expandAllGroups(g, false)

const isNewBlank = rec =>
  rec === undefined ? false : $meta(rec).new && Object.keys($meta(rec).changes).length === 0

const drawColFooter = function (qGrid, col, qFooterRow) {
  if (col.gridCol === false) return ''

  const { dc, opts } = qGrid

  if (col.footer && opts.hasFilter !== false) opts.hasFooter = true

  let classes = '',
    style = ''

  if (col.footerAttributes?.class) classes = classes + ' ' + col.footerAttributes.class + ' '
  if (col.footerAttributes?.style) style = style + ';' + col.footerAttributes.style

  const me = qc(
    'td.ow-footer.ow-footer.coli-' + col.coli + '.' + classes,
    dataBind(
      qc('span.' + (col.type || '')).props({ opts: { fieldName: 'footer-coli-' + col.coli } })
    )
  )
    .css(style)
    .props({ coli: col.coli, col })

  const typeMap = {
    char: 'string',
    bit: 'boolean',
    decimal: 'number',
    integer: 'number',
    float: 'number',
    currency: 'number',
    date: 'date',
    datetime: 'date',
    time: 'string',
    timeString: 'string'
  }
  const typeClass = (typeMap[col.type] ?? '') + '-col'

  if (!['text-col', 'string-col', '-col'].includes(typeClass)) me.addClass(typeClass)

  qGrid.bindColWidth(me)

  const wrapFooter = content =>
    qc('span.col-' + col.coli, content).css({
      textAlign: col.footerAlign || col.align || 'right'
    })

  if (typeof col.footer === 'string') {
    const ag = col.footer
    if (['sum', 'avg', 'max', 'min', 'count'].includes(ag))
      me.bindState(
        () => (ag === 'count' ? dc.count : dc.aggregates?.[col.field]?.[ag] ?? ''),
        v => {
          v = v === undefined ? '' : formatString(v, col.format || 'n')
          me.kids([wrapFooter(v)])
        }
      )
  }

  if (typeof col.footer === 'function')
    me.bindState(
      () => col.footer(),
      v => me.kids([wrapFooter(v)])
    )

  me.kids([wrapFooter(html('&nbsp;'))])

  col.preRenderFooter?.(me, qFooterRow)

  return me
}

/**
 * @params {object} opts.dc -
 * @params {array|object} opts.cols -
 * @params {boolean} opts.live - will cause edited lines to be saved on row change and deletes to call to the server on delete click.
 * @params {boolean} opts.sortable - can click on header to sort by that field on clientside
 * @params {boolean} opts.resizable - can resize columns
 * @params {boolean} opts.selectable - can select rows (not yet implemented)
 * @params {boolean=true} opts.reorderable - if false, the column top filters aren't created
 * @params {boolean} opts.hasFooter -
 * @params {boolean} opts.hasFilters -
 * @params {boolean} opts.hasColFilters -
 * @params {boolean} opts.editable - can edit rows
 * @params {boolean} opts.allowNewRow
 * @params {object} opts.disallowNewWhenExistingNewInvalid
 * @params {boolean} opts.showDeletedRows - when user deletes a row, it can still be seen but is struck through - disappears on save.
 * @params {boolean} opts.indicateCurrentRow -
 * @params {boolean} opts.indicateCurrentCell -
 * @params {boolean} opts.saveOnlyChangedRows - when calling qGrid.readData, only records that have changed will be .
 * @params {boolean} opts.allowColumnMenu - right click to hide-show columns
 * @params {boolean} opts.allowSaveTemplate - in header context menu you can save current column sizes etc as default.
 * @params {array} opts.buttonColumn - icons in an extra column eg ['save', 'delete', {...}]
 * @params {string} opts.fieldName - for dataBind to Array property of parent record - childGrid
 * @params {boolean} opts.markChanges - indicates a row has unsaved changes on the left side
 * @params {boolean} opts.replaceDataset - on save, send all non-deleted validate rows even if not saved.  This is no paging full-client-side batch grid.
 */
const grid7 = opts => {
  if (opts.live) return liveGrid7(opts)

  if (!opts.view) throw 'grid7 needs opts.view'

  const iden = opts.iden ?? opts.id ?? opts.name + 'Grid'
  opts.iden = iden
  if (!iden) throw 'Grid7 requires opts.iden'

  opts.userSettings = JSON.parse(
    JSON.stringify(opts.view.viewdata?.winpref?.grids?.[opts.iden] ?? {})
  )

  let state = {
    populating: 0
    // ,
    // currentRow,
    // currentCell,
    // lastCellControlIndex
    // lastClickedRow
  }

  const qGrid = qc('div.grid.fit.grid7.iden-' + iden)
    // methods
    .props({
      bindColWidth,

      populate(model) {
        return dc.populate(_v(model, opts.fieldName), true)
      },

      currentRow() {
        return state.currentRow
      },

      readField(model) {
        const me = qGrid
        me.opts.fieldName ?? _v(model, me.opts.fieldName, me.val())
      },

      readData(rec, changedRowsOnly = opts.saveOnlyChangedRows) {
        const f = opts.fieldName

        if (changedRowsOnly) {
          // this is normally for batch grids rather than childgrids.
          _v(
            rec,
            f,
            Object.keys(dc._changes)
              .map(i => dc.recs[i])
              .filter(
                rec => qGrid.recs.indexOf(rec) > -1 || ($meta(rec).deleted && !$meta(rec).new) // either in filteredRows OR has been deleted
              )
              .map(rec => JSON.parse(JSON.stringify({ ...rec, _deleted: $meta(rec).deleted })))
          )
          return rec
        }

        _v(
          rec,
          f,
          qGrid.recs
            .filter(r => !$meta(r).deleted && !isNewBlank(r) && !r._group)
            .map(r => {
              var r1 = Object.assign({}, r)
              $meta(r1, {})
              return r1
            })
        )
        return rec
      },

      refilter(isClient) {
        isClient ? qGrid.refilter() : qGrid.dc.load()
      },

      newRowAllowed() {
        if (opts.editable === false) return false
        if (opts.disallowNewWhenExistingNewInvalid) {
          if (qGrid.validate() !== true) return false
        }
        if (typeof opts.allowNewRow === 'function') return opts.allowNewRow()
        return opts.allowNewRow
      },

      ...(opts.editable === false
        ? {}
        : {
            validate(onInvalid, messageArray) {
              let result = true
              document.activeElement && qc(document.activeElement).trigger('change')
              Object.keys($meta(dc.recs).changes).forEach(reci => {
                const tr = qGrid.getTr(dc.recs[reci], false)
                const rv = qGrid.validateRow(tr, onInvalid, messageArray)
                if (tr) result = result && (!rv || rv.resVal === 1)
              })
              return result
            },

            async saveChanges() {
              if (qGrid.validate() === true) return qGrid.saveRows()
            },

            async addRow() {
              if (!qGrid.newRowAllowed()) return

              const atBottom =
                qBody.el.scrollTop + 2 > qBody.el.scrollHeight - qBody.el.clientHeight

              if (qGrid.recs.length && !atBottom) {
                qBody.el.scrollTop = Math.ceil(qBody.el.scrollHeight - qBody.el.clientHeight)
                qGrid.renderAsync()
                qGrid.addRow() // try again
                return
              }

              const newRec = qGrid.quietAddRow()

              return qGrid.renderAsync().then(() => {
                const newRow = qGrid.getTr(newRec)
                if (newRow) {
                  const cellEl = newRow.kids().find(c => !c.hasClass('non-editable-cell'))?.el
                  if (cellEl) qGrid.focusCell(cellEl)
                }
                return newRow
              })
            },

            async deleteRow(trEl, force = false) {
              const { rec } = qc(trEl)
              const meta = $meta(rec)
              if (isNewBlank(rec)) {
                meta.deleted = true
                return removeRowFromRecs(rec)
              }

              if (
                !force &&
                !(await ow.confirm(
                  opts.displayName ?? dc.opts?.displayName ?? __('Confirm'),
                  __('Are you sure you want to delete this record?')
                ))
              )
                return

              if (meta.new) removeRowFromRecs(rec)

              meta.deleted = true
              if (trEl && trEl === state.currentRow?.el) {
                delete state.currentRow
                delete state.currentCell
              }
              if (trEl === state.lastClickedRow?.el) {
                delete state.lastClickedRow
              }
              react(rec)
              dc.refilter()
            }
          })
    })
    .props({ opts, id: iden, rowHeight: 26, state, defOp: 'contains' })

  let rowMap = new WeakMap()

  // forget means that this rec changes are removed from changeTracking.
  const removeRowFromRecs = rec => {
    const hadFocus = false // document.activeElement === trEl || document.activeElement?.closest('tr') === trEl
    const scrollTop = qBody.el.scrollTop

    if (state.currentRow?.rec === rec) {
      state.currentRow = undefined
      state.currentCell = undefined
    }

    const i = qGrid.recs.indexOf(rec)
    const meta = $meta(rec)
    if (meta?.new) qGrid.dc.removeRec(rec)
    qGrid.refilter()

    qGrid.renderAsync().then(() => {
      if (hadFocus) {
        qBody.el.scrollTop = scrollTop
        let nextRec = qGrid.recs[i]
        if (nextRec) {
          let nextRow = qGrid.getTr(nextRec, false)
          nextRow && qGrid.focusCell(nextRow.el?.children[0])
        }
      }
    })
  }

  qGrid.addClass(qGrid.opts.editable === false ? 'non-editable' : 'editable')

  if (opts.editable === false) qGrid.attr({ tabindex: '0' })

  let rowParent, qFooter, qBody, qHeader

  let dc = (qGrid.dc = opts.dc)
  if (opts.paging) dc.paging = opts.paging === true ? (opts.paging = { pageSize: 20 }) : opts.paging

  dc.sort = opts.sorts ?? dc.sort ?? []

  opts.cols.forEach((col, coli) => {
    if (typeof col === 'string') {
      col = { field: col }
      opts.cols[coli] = col
    }
  })

  // button column
  const buttonCol = {
    command: [],
    title: '',
    attributes: { class: 'command-cell no-tab' },
    width: 24,
    template(rec) {
      return this.command.map(btn => {
        const me = qc('a.ow-btn.ow-grid-btn.ow-grid-btn-' + btn.name, qc('i.icon', html(btn.text)))
          .props({ rec })
          .attr({ href: '#', tabindex: '-1' })

        return (btn.preRender?.(me) ?? me).on('click', e => {
          const tr = e.target.closest('tr')
          qc(tr).trigger('row-' + btn.type)
        })
      })
    }
  }
  const { buttonColumn = [] } = opts
  buttonColumn.forEach(btn => {
    btn = typeof btn === 'string' ? { type: btn, imageClass: btn } : btn
    buttonCol.command.push({
      text: iconCodes[btn.type] || '',
      name: btn.type + '-row',
      imageClass: btn.name,
      iconClass: '',
      ...btn
    })
    buttonCol.width = buttonCol.width + (btn.width ?? 14)
  })
  if (buttonCol.command.length) opts.cols.push(buttonCol)
  // end of button column

  const gridCols = opts.cols.filter(c => c.gridCol !== false)

  colsToGridColumns(opts.cols, qGrid)

  // set up reaction fields
  opts.schema = opts.schema ?? {}
  Object.keys(opts.reactFields ?? {}).forEach(f => {
    opts.schema[f] = opts.schema[f] ?? {}
    opts.schema[f].ignore = !opts.reactFields[f]
  })
  delete opts.reactFields

  opts.cols.forEach(col => {
    const noTrack =
      col.trackChanges !== true &&
      (col.trackChanges === false || col.calc || col.readOnly === true || col.uid) // remember readOnly can be a function

    if (col.field) (opts.schema[col.field] = opts.schema[col.field] ?? {}).ignore = !noTrack
  })

  if (opts.fitColumns)
    qGrid.bindState(
      () => qGrid.el?.clientWidth,
      w => {
        if (!w) return
        const widthRatio = totalColWidth / (w - 27)

        if (widthRatio > 0.8 && widthRatio < 1.2) {
          var i, col
          for (i = 0; i < opts.cols.length; i++) {
            col = opts.cols[i]
            if (col.gridCol !== false && !col.hidden) col.width = col.width / widthRatio
          }
          calcColWidth()
        }
      }
    )

  let totalColWidth = 200
  const calcColWidth = () => {
    totalColWidth = 0
    gridCols.forEach(col => (totalColWidth += col.hidden ? 0 : col.width || 0))
  }
  qGrid.calcColWidth = calcColWidth

  const qTitleRow = qc(
    'tr.ow-titlerow',
    qc(gridCols.sort((a, b) => a.order - b.order).map(col => drawColHeader7(qGrid, col)))
  )
  const qFilterRow = qc('tr.ow-filterrow')
  qFilterRow.kids(
    opts.hasColFilters !== false
      ? qc(
          gridCols
            .sort((a, b) => a.order - b.order)
            .map(col => drawColFilter7(qGrid, col, qFilterRow))
        )
      : []
  )

  qHeader = qc('div.ow-grid-header')

  let topRight
  qHeader.kids([
    grid7Css(opts.view.qTop.el.id ? '#' + opts.view.qTop.el.id : undefined),

    (topRight = qc(
      'span.ow-grid-top-right',
      qc('i.icon', html('')).css({
        fontSize: '1.2em',
        // color: theme.iconBlue,
        overflow: 'visible',
        position: 'absolute',
        right: '0',
        paddingRight: '2px',
        top: 0,
        bottom: 0,
        paddingTop: '2px',
        backgroundColor: 'inherit',
        cursor: 'pointer'
      })
    )
      .bindState(() => {
        topRight.css({
          height: qHeader.el?.offsetHeight ?? '0' + 'px',
          width: (qHeader.el.offsetWidth ?? 0) - (qHeader.el?.children[0]?.offsetWidth ?? 0) + 'px'
        })
      })
      .on('click', () => {
        qGrid.toggleClass('ow-hide-filterrow')
        recalcBodyHeight()
      })),

    qc(
      'div.ow-grid-header-wrap.ow-auto-scrollable',
      qc('table', qc('thead', [qTitleRow, qFilterRow])).bindState(
        () => (totalColWidth ?? 1000) + 'px',
        (width, me) => me.css({ width })
      )
    ).attr({ 'data-role': 'resizable' })
  ])

  if (opts.allowColumnMenu !== false)
    qHeader.on('contextmenu', e =>
      cmenu7(e.target, {
        view: opts.view,
        point: { left: e.pageX, top: e.pageY },
        setWidth: false,
        content() {
          return qc('ul', [
            gridCols
              .filter(col => col.field)
              .map(col =>
                qc('li', [
                  check7({
                    label: col.title,
                    labelRight: true,
                    model: { show: !col.hidden },
                    fieldName: 'show'
                  })
                    .addClass(col.isMandatory ? 'ow-disabled' : '')
                    .bindState(
                      () => col.hidden,
                      (hidden, me) => me.val(!hidden)
                    )
                    .on('ow-change', e => {
                      col.hidden = qc(e.target).val() ? 0 : 1
                      calcColWidth()
                      qGrid.renderAsync()
                      if (col.hidden) col.setting.hidden = col.hidden
                      else delete col.setting.hidden
                    })
                    .wrap()
                    .css({ whiteSpace: 'nowrap' })
                ])
              ),
            ...(opts.allowSaveTemplate
              ? [
                  qc(
                    'li',
                    qc('a.menu-item', __('Set Default Template'))
                      .attr({ href: '#' })
                      .on('click', () => qGrid.trigger('command-defaulttemplate'))
                  )
                ]
              : [])
          ])
        }
      })
    )

  const qAddRowOnFocus = qc('input.add-row-on-focus').on('focus', () => {
    if (!opts.tabOutNewRow) return
    const tr = state.currentCell?.parentElement
    if (tr && isNewBlank(qc(tr).rec))
      return common.wait(50).then(() => tr.closest('table') && qGrid.leaveRow(tr)) // this should be cancelled but check later in case

    qGrid.addRow()
  })

  let firstRowIndex = 0,
    fullHeight = 1000,
    useVirtualScroll,
    tableTop = 0,
    activeEl

  const renderRows = () => {
    activeEl = document.activeElement
    const fRecs = qGrid.recs

    const pageSize = !useVirtualScroll
      ? fRecs.length - 1
      : Math.ceil((qBody.el?.clientHeight ?? 200) / qGrid.rowHeight) + numRowsBefore * 2

    let lastRowIndex = useVirtualScroll
      ? Math.min(firstRowIndex + pageSize, fRecs.length - 1)
      : fRecs.length - 1

    let activeRow
    // eslint-disable-next-line no-constant-condition
    if (useVirtualScroll && false) {
      activeRow = activeEl?.tagName === 'tr' ? activeEl : activeEl?.closest('tr.row')
      activeRow = activeRow ? qc(activeRow) : activeRow
      if (activeRow && (!$meta(activeRow.rec) || $meta(activeRow.rec)?.deleted))
        activeRow = undefined
    }
    const tableKids = []

    let activeRowInTable, i
    for (i = firstRowIndex; i <= lastRowIndex; i++) {
      const rec = fRecs[i]
      const tr = qGrid.getTr(rec)
      if (tr === activeRow) {
        activeRowInTable = true
        activeRow.css({ position: undefined, top: undefined })
      }
      tableKids.push(tr.css({ height: qGrid.rowHeight + 'px' }))
    }

    if (
      useVirtualScroll &&
      !activeRowInTable &&
      activeRow &&
      activeRow.el?.parentElement === rowParent.el
    ) {
      let hideActive = true

      let activeRowIndex = fRecs.indexOf(activeRow)
      if (activeRowIndex > lastRowIndex) tableKids.push(activeRow)
      else if (activeRowIndex < firstRowIndex) tableKids.unshift(activeRow)
      else hideActive = false

      if (hideActive) activeRow.css({ position: 'absolute', top: '-' + tableTop + 'px' })
    }

    const kids = rowParent.kids()
    let changes
    if (tableKids.length !== kids.length) changes = true
    if (!changes && tableKids.find((tr, i) => tr !== kids[i])) changes = true
    if (changes) {
      rowParent.kids(tableKids) // let the cmp renderer do the work
      common.wait(100).then(() => rowParent.renderAsync())
    }
  }

  let qBodyInner, table

  const numRowsBefore = 12

  qBody = qc('div.ow-grid-content.ow-auto-scrollable', [
    (qBodyInner = qc('div.ow-grid-content-wrap.ow-auto-scrollable', [
      (table = qc(
        'table',
        (rowParent = qc('tbody').bindState(() => {
          if (!state.currentRow || !qGrid.recs.includes(state.currentRow.rec)) {
            state.currentRow = rowParent.kids().find(tr => !tr.rec._group)
            if (state.currentRow) opts.view.renderAsync()
          }
        }))
      )
        .css({ position: 'absolute' })
        .bindState(
          () => (totalColWidth ?? 1000) + 'px',
          (width, me) => me.css({ width })
        )),
      qAddRowOnFocus
    ])
      .css({ boxSizing: 'border-box' })
      .bindState(() => {
        useVirtualScroll =
          useVirtualScroll || qGrid.recs.length > (opts.virtualScrollThreshold ?? 50)
        fullHeight = (1 + qGrid.recs.length ?? 0) * qGrid.rowHeight

        if (!useVirtualScroll) {
          qBodyInner.css({ top: 0, height: undefined })
          firstRowIndex = 0
          tableTop = 0
          return
        }

        firstRowIndex = Math.max(0, Math.ceil(qBody.el.scrollTop / qGrid.rowHeight) - numRowsBefore)

        const height = fullHeight + 'px'
        qBodyInner.css({ height })

        renderRows()
        // set table top position in the scrollable fullHeight
        tableTop = Math.max(0, firstRowIndex * qGrid.rowHeight)
        const top = tableTop + 'px'
        table.css({ top })
      }))
  ])
    .on('scroll', () => qHeader.renderAsync() && qFooter.renderAsync())
    .on('click', e => {
      if (e.target !== qBody.el && e.target.parentElement !== qBody.el) return
      if (e.target.closest('table')) return

      const newRowAllowed = qGrid.newRowAllowed()
      const lastRec = qGrid.recs[qGrid.recs.length - 1]

      const focusLast = (lastRec && isNewBlank(lastRec)) || !newRowAllowed

      if (focusLast && lastRec) {
        const tr = qGrid.getTr(lastRec)
        return qGrid.focusCell(tr.find('td')[0]) // this will be cancelled.
      }
      if (newRowAllowed) return qGrid.addRow()

      // if you reach here, it's because you aren't allowed to addNewRows and grid is empty (can be client-side filters)
    })
    // Can we put some of these on the body and add in '.grid7 '
    // split these out
    .on('scroll', () => common.wait(5).then(() => qGrid.renderAsync()))

  if (opts.selectable) selectableGrid(qGrid, rowParent)

  qFooter = qc('div.ow-grid-footer.ow-grid-footer')

  qFooter
    .kids(
      qc(
        'div.ow-grid-footer-wrap',
        qc(
          'table',
          qc(
            'tbody',
            qc(
              'tr.ow-footer-template.ow-footer-template',
              gridCols
                .sort((a, b) => a.order - b.order)
                .map(col => drawColFooter(qGrid, col, qFooter))
            )
          )
        ).bindState(
          () => (totalColWidth ?? 1000) + 'px',
          (width, me) => me.css({ width })
        )
      )
    )
    .attr({ tabindex: '-1' })
    .css({
      borderTop: theme.borderWidth + ' solid ' + theme.textboxBorderColor,
      display: opts.hasFooter !== true ? 'none' : undefined
    })

  if (dc.paging && opts.userSettings?.pageSize) {
    if (dc.paging === true) dc.paging = {}
    dc.paging.pageSize = opts.userSettings?.pageSize
  }

  qGrid.kids([
    qHeader.bindState(
      () => qBody.el?.scrollLeft,
      (left = 0, me) => me.css({ marginLeft: -1 * left + 'px' })
    ),
    qBody,
    qFooter.bindState(
      () => qBody.el?.scrollLeft,
      (left = 0, me) => me.css({ marginLeft: -1 * left + 'px' })
    ),
    gridPager7(opts.view, dc.paging, () => dc?.load())
  ])

  dataBind(qGrid, opts.view)

  const progress = async (...args) => opts.view.progress(...args)

  const recalcBodyHeight = function () {
    let newHeight = qGrid.el?.clientHeight ?? 100,
      otherElementsHeight = 0
    qGrid.kids().forEach(q => q.el !== qBody.el && (otherElementsHeight += $offset(q.el).height))

    const height = newHeight - otherElementsHeight + 'px'
    if (height !== qBody.el?.style.height) qBody.css({ height })
  }

  const setCurrentRow = trNew => {
    let hasChanged = state.currentRow !== trNew

    if (trNew?.rec._group) return // is it focusable
    if (hasChanged) {
      state.currentRow = trNew
      opts.view.renderAsync()
    }
  }

  const drawCell = (col, rec, tr) => {
    if (col.gridCol === false) return ''

    const me = qc('td.gridcell.coli-' + col.coli)
      .props({ col, coli: col.coli, rec })
      .on('click', () => me.hasClass('non-editable-cell') && qGrid.current(me))
      .on('focusin', () => qGrid.current(me))
      .bindState(
        () => state.currentCell,
        cc => (cc === me ? me.addClass('ow-current-cell') : me.removeClass('ow-current-cell'))
      )

    bindColWidth(me)

    if (col.field === 'i') return me.kids('' + tr.reci) // For testing

    let ro = cellReadOnly(col, rec)
    if (ro) me.addClass('non-editable-cell no-tab')

    let classes = (typeof col.classes === 'function' ? col.classes(rec) : col.classes) || ''

    classes && me.addClass(classes)
    col.attributes?.class && me.addClass(col.attributes.class)
    col.type && !(col.type === 'text' || col.type === 'string') && me.addClass(col.type + '-col')

    let content
    if (rec._group) {
      content = qc(
        'span',
        (!rec.footer ? col.groupHeaderTemplate : col.groupFooterTemplate)?.(rec) ?? ''
      )
    } else {
      content = col.kColTemplate(rec)
      if (content && ro && col.field) content.attr({ 'data-field': col.field })
    }

    me.kids(typeof content === 'string' ? html(content || '&nbsp;') : content)

    if (col.colspan > 1) me.attr({ colspan: '' + col.colspan })

    col.preRender?.(me, tr)
    return me
  }

  const drawRow = rec => {
    const meta = $meta(rec) ?? { reci: -1 }

    const tr = qc('tr.row')
      .props({
        reci: meta.reci,
        rec,
        select(...args) {
          return setSelected(qGrid, tr, ...args)
        },
        toggleSelect() {
          return toggleSelect(qGrid, tr)
        }
      })
      .bindState(
        () => meta.filterIndex,
        fi => (fi % 2 ? tr.addClass('ow-alt') : tr.removeClass('ow-alt'))
      )
      .bindState(
        () => state.currentRow,
        curr => {
          curr === tr ? tr.addClass('ow-current-row') : tr.removeClass('ow-current-row')
        }
      )
      .on('focusin', () => setCurrentRow(tr))

    if (rec._group) tr.addClass(rec.footer ? 'ow-group-footer' : 'ow-group-header')

    const cells = gridCols.sort((a, b) => a.order - b.order).map(col => drawCell(col, rec, tr))

    if (opts.editable && !rec._group)
      tr.bindState(
        () => hasChanges(rec),
        v => (v ? tr.addClass('ow-dirty') : tr.removeClass('ow-dirty'))
      )
        .bindState(
          () => $meta(rec).deleted,
          v => (v ? tr.addClass('ow-deleted-row') : tr.removeClass('ow-deleted-row'))
        )
        .on('row-delete', e => {
          qGrid.deleteRow(tr.el)
          return killEvent(e)
        })

    if (opts.editable === false && !rec._group)
      tr.attr({ tabindex: '0' }).on(
        'keyup',
        e =>
          e.which === 13 &&
          qGrid.trigger('command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit'))
      )
    else
      tr.on('mousedown', () => tr.attr({ tabindex: '0' })).on('blur', () =>
        tr.attr({ tabindex: undefined })
      )

    tr.on('dblclick', () =>
      qGrid.trigger('command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit'))
    )

    if (opts.selectable) selectableRow(qGrid, tr)

    return (
      tr
        .kids(cells.map(x => (typeof x === 'string' ? html(x) : x)))
        .on('keydown keyup keypress', e => {
          if (e.which === 33 || e.which === 34) {
            if (e.type === 'keyup') e.which === 33 ? qGrid.pageUp() : qGrid.pageDown()
            return killEvent(e, false)
          }
        })
        .on('keydown', e => {
          // F2
          if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
            if (opts.editable === false) {
              tr.trigger('command-' + (opts.view.viewdata.mode === 'select' ? 'select' : 'edit'))
              return killEvent(e)
            }
          }
        })
        .on('keydown', e => {
          if (e.which === 9) {
            if (no$(e.target).is('tr'))
              qAddRowOnFocus.attr('tabindex', isNewBlank(qc(e.target).rec) ? '-1' : '0')
          }
        })
        // apply arrow up and down on grid.
        .on('keydown', e => {
          if (e.which === 38 || e.which === 40) {
            const navRecs = qGrid.recs.filter(x => !x._group)

            const i = navRecs.indexOf(rec)
            if (i === -1) throw 'record not found in navRecs'
            const gotoRec = navRecs[i + (e.which === 38 ? -1 : 1)]

            if (gotoRec) {
              const gotoTr = qGrid.getTr(gotoRec)
              if (gotoTr) {
                if (opts.editable) {
                  const cell = e.target.closest('td')
                  const { coli = 0 } = cell ? qc(cell) : {}
                  const gotoTdEl = gotoTr.find('td.coli-' + coli)[0]
                  qGrid.focusCell(gotoTdEl)
                } else gotoTr?.el?.focus()
              }
            }

            // if arrow down and on last row
            if (e.which === 40 && navRecs.slice(-1)[0] === rec) {
              if (isNewBlank(rec)) return
              if (opts.allowNewRow) return qGrid.addRow()
            }

            return killEvent(e, true)
          }
        })
    )
  }

  qGrid
    .addClass('ow-grid')
    .bindState(
      () => dc.recs,
      () => {
        rowParent.kids([])
        state.currentCell = undefined
        state.currentRow = undefined
        dc.refilter()
      }
    )
    .bindState(
      () => dc.currentFilter?.recs,
      recs => {
        if (recs) qGrid.recs = recs

        progress()
          .then(() => renderRows())
          .then(() => progress(false))
      }
    )
    .props({
      opts,
      recs: [],

      progress,

      wrap() {
        return qGrid
      },

      groupSelector(group) {
        return groupSelector(qGrid, group)
      },

      groupTogglerHTML(rec) {
        return qc('i.icon.group-toggler')
          .css({
            display: 'inline-block',
            color: '#666',
            padding: '0 6px',
            width: '9px',
            cursor: 'pointer'
          })
          .on('click', () => toggleGroup(qGrid, rec))
          .bindState(
            () => rec._group.open ?? dc.opts.expandAllGroups ?? false,
            (v, me) => {
              me.kids(html(!v ? iconCodes['plus-square'] : iconCodes['minus-square']))
            }
          )
      },

      current(cell) {
        if (arguments.length === 0) return state.currentCell
        if (!no$(cell.el).is('td')) return state.currentCell

        // No group header or footers
        if (qc(cell.el.parentElement).hasClass('row--1')) return state.currentCell

        let leavingRow =
          state.currentCell?.parentElement &&
          state.currentCell.parentElement !== cell.el?.parentElement &&
          qc(state.currentCell.parentElement).rec !== qc(cell.el?.parentElement).rec

        if (leavingRow) {
          leavingRow = state.currentCell.parentElement
          if (leavingRow) qGrid.leaveRow(leavingRow)
        }

        state.currentCell = cell.el

        return cell
      },

      /**
       *
       * @param {object|integer} rec model
       * @param {boolean} createIfNone default true
       * @returns qc(tr)
       */
      getTr(rec, createIfNone = true) {
        let tr
        if (!rowMap.has(rec)) {
          if (!createIfNone) return
          tr = drawRow(rec)
          rowMap.set(rec, tr)
        } else {
          tr = rowMap.get(rec)
          applyOrderToRow(tr)
        }
        return tr
      },

      expandAllGroups: () => expandAllGroups(qGrid.el),
      collapseAllGroups: () => collapseAllGroups(qGrid.el),

      getUserSettings(grids) {
        if (opts.userSettings) {
          // hasChanged = true
          opts.userSettings.cols = opts.cols.reduce((r, col) => {
            if (Object.keys(col.setting).length) r[col.name] = col.setting
            return r
          }, {})

          if (qGrid.hasClass('ow-hide-filterrow')) delete opts.userSettings.filterToggle
          else opts.userSettings.filterToggle = true

          if (dc.paging?.pageSize) opts.userSettings.pageSize = dc.paging.pageSize

          grids[opts.iden] = opts.userSettings
        }
      },

      update() {},

      resizeGrid() {
        qGrid.renderAsync()
      },

      async leaveRow(trEl) {
        if (!trEl) return

        if (opts.editable === false) return

        const data = qc(trEl).rec
        if (!data) return // if it has reloaded or something
        if (data._group) return // don't do saving on group headers/footers

        if (!($meta(data)?.reci >= 0)) return

        if (isNewBlank(data) && opts.editable && opts.tabOutNewRow !== false) {
          if ($meta(data).deleted) return
          removeRowFromRecs(data)
          dc.refilter()
        }
        // allow changes to propagate before validating and saving etc.
        else
          return common.wait(500).then(() => {
            if (data) {
              qGrid.validateRow(trEl)
              if (opts.groupBy) dc.refilter()
            }
          })
      },

      /**
       * quietAddRow (rec, asNew = true)
       * Adds record to the grid.dc.recs model, meta(rec) is true unless asNew is false.
       * The added records will be the newRec default values (from col.defaultValue and g.dc.newRec)
       * recsArray are added as changes
       *
       * If asNew is false, the model will not be new and will be considered unchanged.
       *
       * If you want to add rec as edited but not new, you can set the values on the records
       * after calling addRecords with blank objects then add the values after and call react(rec)
       *
       * @param {object} recs
       * @param {boolean} asNew
       * @returns {object} the actual model added to dc.recs with meta initalized
       */
      quietAddRow(r = {}, asNew = true) {
        let rec = {}
        // apply defaults if nothing is set already.filterMap

        if (asNew) {
          opts.cols.forEach(col => {
            if ('defaultValue' in col && col.field && _v(rec, col.field) === undefined)
              _v(
                rec,
                col.field,
                typeof col.defaultValue === 'function' ? col.defaultValue() : col.defaultValue
              )
            else if (col.uid && !rec[col.field])
              common
                .$put({
                  url: '/nextUids',
                  data: { dbType: col.uid === true ? 'sagapos' : col.uid, count: 1 }
                })
                .then(({ uids }) => (rec[col.field] = uids[0]))
          })
          dc.addRec(rec) // this inits the meta as new
          Object.assign(rec, r)
          react(rec)
        } else {
          Object.assign(rec, r)
          dc.addRec(rec) // this inits the meta as new
          delete $meta(rec).new // remove new
        }

        return rec
      },

      /**
       * addRecords (recsArray, asNew = true)
       *
       * calls grid.quietAddRow for each record.
       *
       * @see {@link quietAddRow} for further information.
       *
       * @param {object[]} recs
       * @param {boolean} asNew
       * @returns {object[]} the actual models added to dc.recs with meta initalized
       */
      addRecords(recs = [], asNew = true) {
        return recs.map(r => qGrid.quietAddRow(r, asNew))
      },

      // updates the _meta with any changes, or just for field if provided
      rowReact(tr) {
        return tr ? react(qc(tr).rec) : undefined
      },

      // rec is optional
      fieldReact(tr, f, rec) {
        rec = rec || qc(tr).rec
        return react(rec, f)
      },

      pageUp() {
        const h = Math.floor(qBody.el.clientHeight / qGrid.rowHeight) * qGrid.rowHeight
        qBody.el.scrollTop = qBody.el.scrollTop - h
      },

      pageDown() {
        const h = Math.floor(qBody.el.clientHeight / qGrid.rowHeight) * qGrid.rowHeight
        qBody.el.scrollTop(qBody.el.scrollTop + h)
      },

      focusCell(tdEl) {
        if (tdEl) tdEl = qc(tdEl).hasClass('non-editable-cell') ? tdEl.children[0] : tdEl
        if (document.activeElement?.closest('td') === tdEl) return

        const q = 'input, a.check7'

        let x = qc(tdEl).find(q)
        if (x.length === 0) {
          let span = qc(tdEl).find('span')[0]
          if (span) {
            qc(span).attr('tabindex', 1) // will be removed
            span.focus() // focus cell because no control
            qc(span).attr('tabindex', -1) // removed
          }
        } else {
          let ci = state.lastCellControlIndex
          ci = Math.min(x.length - 1, ci || 0)
          x[ci].focus()
        }
      },

      moveNextCell(back) {
        const currCell = qGrid.current()
        if (!currCell) return

        const allcells = $find(qBody.el, 'td.gridcell').filter(td => !td.hasClass('no-tab'))

        const currIndex = allcells.indexOf(currCell)

        const goToCell = allcells[currIndex + (back ? -1 : 1)]
        if (goToCell.length) qGrid.focusCell(goToCell)
        else qAddRowOnFocus.el.focus()
      },

      // this is called from window win_close for all .ow-grid.
      hasChanges: () => hasChanges(dc.recs),

      async refilter() {
        await progress()
        dc.refilter()
        qGrid.renderAsync()
        await progress(false)

        if (qGrid.focusFirstRowOnPopulate) {
          qGrid.focusFirstRowOnPopulate = false
          common.wait(10).then(() => {
            const firstTr = qBody.find('tr')[0]
            const td = $find(firstTr, 'td.non-editable-cell, td')[0]
            td && qGrid.focusCell(td)
          })
        }
      },

      refresh: noConfirm => dc.load(noConfirm) // todo - if we have a live grid we also need to manage refreshing from server...?  separate client and server filters?
    })
    .bindState(recalcBodyHeight)
    .bindState(calcColWidth)
    .on('init', (e, g) => {
      g.ctlTypeClass = 'grid7'
      g.opts = opts

      commandsOnGrid(g)

      if (opts.resizable !== false) qGrid.addClass('resizable-columns')
      if (opts.hasColFilters === undefined && opts.hasFilters === false) opts.hasColFilters = false
      if (opts.hasColFilters !== false) qGrid.addClass('ow-has-filterrow')

      dc.opts.control = qGrid

      opts.editable = opts.editable !== false

      if (opts.allowNewRow === undefined) opts.allowNewRow = opts.editable
      if (opts.tabOutNewRow === undefined) opts.tabOutNewRow = opts.allowNewRow !== false
      if (opts.markChanges !== false) qGrid.addClass('ow-mark-changes')

      qGrid.recs = []

      if (opts.indicateCurrentRow !== false) qGrid.addClass('indicate-current-row')
      if (opts.indicateCurrentCell === true) qGrid.addClass('indicate-current-cell')

      // if (opts.hasFilters !== false)
      hasFilterControls(qGrid)

      g.getUserSettings = qGrid.getUserSettings

      if (opts.editable !== false) {
        qGrid
          .props({
            async cancelChanges(trEl) {
              if (!trEl) return cancelChanges(dc.recs)

              const tr = qc(trEl)
              const { rec } = tr

              cancelChanges(rec)
              if ($meta(rec).new) $meta(rec).deleted = true
              react(rec)
              qGrid.refilter()
            },

            async saveRows() {
              const response = await dc.saveChanges(opts.replaceDataset).catch(err => {
                console.error(err)
                ow.popSaveError(err)
              })
              if (!response) return
              opts.view.renderAsync()
              ow.popSaveOK(response)
              if (opts.reloadAfterSave) qGrid.refresh(true)
            },

            validateRow(tr, onInvalid) {
              qc(tr)
                .find('.ow-input-errbg')
                .forEach(x => qc(x).removeClass('ow-input-errbg'))

              const result = { resVal: 1, uid: [], errMsg: '' }
              const localMessageArray = []

              onInvalid ??= (title, msg, el) => {
                localMessageArray.push(title + ': ' + msg)
                el && tr !== el && qc(el).displayValidity?.(false, msg)
                qc(tr).addClass('ow-row-invalid')
                common.wait(1000).then(() => qc(tr).removeClass('ow-row-invalid'))
              }

              const { rec } = qc(tr)

              // ignore new blank rows
              if (isNewBlank(rec)) return result

              opts.cols.forEach(col => {
                if (!result) return false
                if ($meta(rec).deleted) return

                if (col.validation?.validateFunction) {
                  let rv = col.validation.validateFunction.call(col, rec)
                  if (typeof rv === 'string') rv = { resVal: 0, errMsg: rv, uid: [col.coli] }

                  if (rv && rv.resVal === 0) {
                    result.resVal = 0
                    result.errMsg = rv.errMsg
                    result.uid = result.uid.concat(rv.uid)
                    onInvalid(
                      col.title,
                      result.errMsg,
                      qc(tr).find('[data-field=' + col.field + ']')[0] || tr
                    )
                  }
                }

                const objectValidation = validation => {
                  for (const rule in validation) {
                    if (rule === 'validateFunction') continue
                    let v = validation[rule]
                    let displayValue = v // if it's a column name

                    v = typeof v === 'function' ? v(rec, col) : v

                    if (v === undefined) continue // if the rule refers to another field by Name

                    let val = _v(rec, col.field)

                    if (typeof v === 'string') {
                      displayValue =
                        (opts.cols.filter(c => c.field === v)[0] || {}).title + ', ' + rec[v]
                      v = rec[v]
                      if (col.type === 'time') {
                        if (val && val.toJSON) val = val.toJSON().split(' ')[1]
                        if (v && v.toJSON) v = v.toJSON().split(' ')[1]
                      }
                    }

                    let err

                    if (!val && val !== 0) {
                      if (rule === 'required' && v) err = ' ' + __('requires a value')
                    } else {
                      // if (typeof val !== 'undefined' && val !== null && val !== '') {
                      if (rule === 'eq' && !(val === v))
                        err = ' ' + __('should be equal to') + ' ' + displayValue
                      else if (rule === 'neq' && !(val !== v))
                        err = ' ' + __('should not be equal to') + ' ' + displayValue
                      else if (rule === 'gt' && !(val > v))
                        err = ' ' + __('should be greater than') + ' ' + displayValue
                      else if (rule === 'gte' && !(val >= v))
                        err = ' ' + __('should be greater than or equal to') + ' ' + displayValue
                      else if (rule === 'lt' && !(val < v))
                        err = ' ' + __('should be less than') + ' ' + displayValue
                      else if (rule === 'lte' && !(val <= v))
                        err = ' ' + __('should be less than or equal to') + ' ' + displayValue
                      else if (
                        rule === 'url' &&
                        !new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i).test(val)
                      )
                        err = ' ' + __('Invalid URL') + ' ' + displayValue
                      else if (
                        rule === 'email' &&
                        !new RegExp(
                          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
                        ).test(val)
                      )
                        err = ' ' + __('Invalid Email Format')
                      else if (rule === 'regEx' && !new RegExp(validation.regEx).test(val))
                        err = ' ' + __('should match ' + validation.regEx)
                      else if (rule === 'minLength' && val?.length < v)
                        err = ' ' + __('should have min length of ' + v)
                      else if (rule === 'maxLength' && val?.length > v)
                        err = ' ' + __('should have max length of ' + v)
                    }

                    if (err) {
                      result.resVal = 0
                      result.errMsg = err
                      result.uid.push(col.coli)
                      onInvalid(
                        col.title,
                        result.errMsg,
                        qc(tr).find('[data-field=' + col.field + ']')[0] || tr
                      )
                    }
                  }
                }

                if (typeof col.validation === 'object') objectValidation(col.validation)
              })

              if (localMessageArray.length) ow.popInvalid(html(localMessageArray.join('<br>')))

              return result
            }
          })
          .on('row-new', () => {
            if (!opts.tabOutNewRow) return
            const trEl = state.currentCell?.parentElement
            if (trEl && isNewBlank(qc(trEl).rec)) {
              const tr = qc(trEl)
              return common.wait(50).then(() => {
                trEl.closest('table') && qGrid.leaveRow(trEl)
                if ($meta(tr.rec).deleted) {
                  $meta(tr.rec).deleted = false // undelete
                  react(tr.rec)

                  tr.find('td')[0]?.focus()
                }
              }) // this should be cancelled but check later in case
            }
            qGrid.addRow()
            return false
          })
      }
    })

  if (!opts.userSettings?.filterOpen) qGrid.addClass('ow-hide-filterrow')

  return qGrid
}

const cellReadOnly = (col, d) =>
  typeof col.readOnly === 'function' ? col.readOnly(d) : col.readOnly || false

const columnDataValue = (col, rec) => {
  const f = col.field
  let v = col.calc ? col.calc(rec) : f ? _v(rec, f) : undefined

  if (v && (col.type === 'date' || col.type === 'datetime') && typeof v === 'string') {
    // this happens after JSON.parse() and is still a string
    if (v.indexOf('T') + 1) {
      console.error('Unexpected condition, ISO string ', col.type, 'timezone may be wrong')
      v = new Date(v)
    } else if (
      v.split(':').length === 3 &&
      v.split('-').length === 3 &&
      v.split(' ').length === 2
    ) {
      v = new Date(v)
    } else {
      console.error('Unexpected condition, formatted date string ', col.type)
      v = parseDate(v, col.format)
    }
  }

  return v
}

/**
 * returns the formatted string value for a column.
 * @param {*} col
 * @param {*} d
 * @param {*} i
 * @param {boolean} readOnly
 * @param {boolean} ctlUse - if it's for in a control rather than HTML span content
 * @returns
 */
const columnValue = (col, d, i, readOnly, ctlUse) => {
  let v = columnDataValue(col, d)

  v = v === undefined && v === null && !ctlUse ? '' : col.format ? formatString(v, col.format) : v

  if (readOnly) {
    if (col.readOnlyText) col.readOnlyText(d)

    if (v !== null && col.type === 'combo') {
      const ctl = {
        ...{ objectFieldName: '_' + col.field, valueField: 'Value', textField: 'Text', list: [] },
        ...col,
        ...(col.ctl ?? {})
      }

      return (
        d[ctl.objectFieldName]?.[ctl.textField] ??
        ctl.list.find(item => item[ctl?.valueField] === v)?.[ctl.textField] ??
        v
      )
    }

    if (v === null) return ctlUse ? '' : '&nbsp;'
    return col.readOnlyText?.(d) ?? v ?? (ctlUse ? '' : '&nbsp;')
  }

  return v
}

/**
 * adds the standard grid button command event handlers to a grid
 * commands are refresh, edit, copy, delete, new
 *
 */
const commandsOnGrid = function (g) {
  const qGrid = qc(g)

  const targetIsMe = e =>
    e.target === g ||
    e.target.closest('.grid7') === g ||
    qc(e.target).opts?.targetRef === qGrid.opts.iden ||
    qc(e.target).opts?.targetRef === qGrid.opts.dc.opts.dsName

  const triggerCommandOnCurrentRow = (cmd, e) => {
    if (!targetIsMe(e)) return
    const trEl = e.target.closest('tr.row')
    const tr = trEl ? qc(trEl) : qGrid.currentRow()
    tr && tr.trigger(cmd)
    return e ? killEvent(e) : false
  }

  qGrid.opts.view.qTop.on('command-refresh', e => {
    if (!targetIsMe(e)) return
    qGrid.refresh()
    return killEvent(e)
  })

  if (qGrid.opts.editable !== false)
    return qGrid.opts.view.qTop
      .on('command-save', e => targetIsMe(e) && qGrid.saveChanges())
      .on('command-add-row', e => {
        if (!targetIsMe(e)) return
        qGrid.trigger('row-new')
        return killEvent(e)
      })

  qGrid.opts.view.qTop
    .on('command-new', e => {
      if (!targetIsMe(e)) return
      qGrid.trigger('row-new')
      return killEvent(e)
    })
    .on('command-edit', e => triggerCommandOnCurrentRow('row-edit', e))
    .on('command-select', e => triggerCommandOnCurrentRow('row-select', e))
    .on('command-copy', e => triggerCommandOnCurrentRow('row-copy', e))
    .on('command-delete', e => triggerCommandOnCurrentRow('row-delete', e))
    .on('command-save', e => triggerCommandOnCurrentRow('row-save', e))
    .on('command-cancel', e => triggerCommandOnCurrentRow('row-cancel', e))
}

const hasFilterControls = qGrid => {
  qGrid.on('ow-grid-databound', function () {
    if (qGrid.focusFirstRowOnPopulate) {
      qGrid.focusFirstRowOnPopulate = false
      const c = qGrid.find('tbody tr:first td:first')[0]
      if (c) {
        qGrid.current(c)
        qGrid.find('.ow-grid-content table')[0]?.focus()
      }
    }
  })

  const fireFilters = e => {
    if (qGrid.opts.focusFirstRowOnPopulate !== false) {
      qGrid.focusFirstRowOnPopulate = true
      const panel = e.target.closest('.filter-panel')
      panel.focus()
      qc(panel).find('.filter-button')[0]?.focus()
    }
    qc(e.target).trigger('change')
    common
      .wait(10)
      .then(
        e.target.opts?.clientFilter
          ? () => (qGrid.refilter ?? qGrid.refresh)?.()
          : () => (qGrid.el?.dc?.load ?? qGrid.refresh)?.()
      )
  }

  qGrid.on('command-filter-change', () => common.wait(50).then(() => qGrid.refresh()))
}

module.exports = { grid7 }

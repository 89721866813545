const { qc } = require('../cmp/qc')

let resize // function to call on drag handles or titlebar for resizing or moving the window.
document.body.addEventListener(
  'mousemove',
  e => (e.buttons === 0 ? (resize = undefined) : resize?.(e)),
  true
)
document.body.addEventListener('mouseup', () => (resize = undefined), true)

module.exports.owSplitter = (opts, kids) => {
  if (kids.length !== 2) throw 'there can only be 2 kids in an owSplitter'

  let width

  const getWidth = () => width ?? leftPane.el?.offsetWidth ?? 400

  const dragBar = qc('div.dragbar')
    .css({
      top: '0',
      bottom: '0',
      width: '2px',
      cursor: 'ew-resize',
      display: 'inline-block',
      position: 'absolute',
      borderLeft: '1px solid #ddd',
      borderRight: '1px solid #ddd'
    })
    .bindState(getWidth, v => dragBar.css({ left: v + 'px' }))
    .on('mousedown', e => {
      width = leftPane.el.offsetWidth
      const orig = { width }
      const m_pos_x = e.pageX
      resize = ({ pageX }) => {
        width = Math.max(
          Math.min(parseFloat(orig.width + pageX - m_pos_x), opts.maxWidth ?? me.el?.offsetWidth),
          opts.minWidth ?? 0
        )
        me.renderAsync()

        e.preventDefault()
      }
      e.preventDefault()
    })

  const leftPane = qc('div.left-pane', kids[0])
    .bindState(getWidth, v => leftPane.css({ width: v + 'px' }))
    .css({
      display: 'inline-block',
      top: '0',
      bottom: '0',
      left: '0',
      position: 'absolute',
      width: '50%'
    })
    .on('init', () => {
      leftPane.css({ width: opts.leftWidth ?? '50%' })
      width = leftPane.el.offsetWidth
      me.renderAsync()
    })

  const rightPane = qc('div.right-pane', kids[1])
    .css({ top: '0', bottom: '0', right: '0', position: 'absolute' })
    .bindState(getWidth, v => rightPane.css({ left: v + 4 + 'px' }))

  const me = qc('div.splitter', [leftPane, dragBar, rightPane])

  return me
}

const { $ajax } = require('./ajax')
const { qc } = require('./cmp/qc')
const { no$, $find } = require('./no-jquery')

window.require = module => (module === 'common' ? { common: window.common } : window[module]) // hack for browserify excludes, couldn't get expose working

require('../../lib/dates')
require('./polyfills')

window.cacheUrl = 'v=' + new Date().getTime()

const cmp = {
  ...require('./cmp/qc'),
  ...require('./cmp/html'),
  ...require('./cmp/$cmp'),
  ...require('./cmp/cmpCollection'),
  ...require('./cmp/textnode'),
  ...require('./css'),
  isAncestorOf: require('./cmp/cmp-renderer').isAncestorOf
}

const pop = { ...require('./pop-box') }

const common = {
  ...require('./_v'),
  ...require('./_id'),
  theme: require('./theme'),
  ...require('./toValueText'),
  ...require('./debounce'),

  ...require('./wait'),

  hexTo36: b16 => {
    let s = b16.split('-').join('')
    let r = [],
      piece
    while (s.length) {
      piece = parseInt(s.slice(-9), 16) // last part first
      s = s.slice(0, -9)
      r.push(((s.length ? '000000' : '') + piece.toString(36)).slice(-7))
    }

    return r.reverse().join('')
  },
  ...require('./element-map'),
  ...require('./culture'),
  ...require('./set-culture'),
  ...require('./dates'),
  ...require('./ctl-format'),
  ...require('./html-encode'),
  events: { ...require('./events') },
  ...require('./killEvent'),
  ...require('./setDragRectHandler'),
  ...require('./ajax'),
  nojquery: { ...require('./no-jquery') },

  ...require('./odisable'),
  ...require('./icon-codes'),
  pop,

  ...require('./controls/controls'),

  ...require('./ow7/ow7'),
  ...require('./ow5/ow5'),
  ...require('./ow4/ow4'),
  ...require('./ow0/ow0'),

  cmp,

  // Deprecated unbundled qc built ow4 and ow5 controls.
  qcControls: require('./qcControls/common-qc-controls').qcControls,

  deplhifinancial: { ...require('./delphifinancial') },

  ...require('./ow0/notify')
}

exports.common = common

common.toString = common.formatString

window.common = common

window.no$ = no$
window.$ajax = (...args) => $ajax(...args)
window.cmp = cmp
window.qc = qc
window.pop = pop

document.body.addEventListener?.(
  'dragstart',
  e => (window.dragEl = e.target),
  true // capture
)

window?.addEventListener?.('resize', e =>
  $find(document.body, '.win-con').forEach(el => qc(el).trigger('resize', { originalEvent: e }))
)

// turn off browser interference
document.body.addEventListener(
  'focus',
  e => {
    const el = e.target
    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
      el.setAttribute('autocomplete', 'off')
      el.setAttribute('spellcheck', 'false')
    }
  },
  true
)

// href:# -> prevent navigation
document.body.addEventListener(
  'mousedown',
  e => {
    if (e.target.tagName === 'A' && e.target.href && e.target.href.substr(-1) === '#')
      e.target.href = 'javascript:void(0)'
  },
  true
)

const { _v } = require('../_v')

/**
 *
 * @param {Qc} me (or Cmp)
 * @param {function} evaluator - either a prop name of the Cmp or a function to evaulate the current state value
 * @param {function} changeHandler(value) - (optional) called with the new value if changed
 * @returns {Qc} for chaining
 */
const bindState = (me, evaluator, changeHandler) => {
  if (typeof evaluator === 'string') return bindState(me, () => _v(me, evaluator), changeHandler)

  if (!me.applyState) me.applyState = () => {} // this state

  const _applyState = me.applyState
  let prevValue = {} // set to something so it always fires the first time

  me.applyState = function (prevState) {
    const value = evaluator.call(me, prevValue, me)
    if (changeHandler && value !== prevValue) {
      changeHandler.call(me, value, me)
      prevValue = value
    }
    return _applyState.call(me, prevState)
  }
  return me
}

exports.bindState = bindState

const { ow0 } = require('../ow0/ow0')

exports.ow4 = {
  version: 4,
  ...require('./dc4'),
  ...require('./filterController4'),
  ...require('./wireUpView4'),
  controls: {
    version: 4,
    ...require('./controls4'),
    ...require('./cmenu4'),
    dataController: require('./dc4').dc4
  },
  grids: {
    version: 4,
    ...require('./grids4'),
    ...require('./childgrid4'),
    ...require('./fspgrid4')
  }
}
Object.setPrototypeOf(exports.ow4, ow0)
window.ow4 = exports.ow4

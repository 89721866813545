/* 

todo: use Intl instead of kendo (with polyfills?) 
OR globalisejs

Intl (ECMA2020):
const number = 123456.789
new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number) // expected output: "123.456,79 €"
// the Japanese yen doesn't use a minor unit
new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(number) // expected output: "￥123,457"
// limit to three significant digits
new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number) // expected output: "1,23,000"

new Intl.NumberFormat('en-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"€1,234.45"
new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"1.234,45 €"
new Intl.NumberFormat('en-DE', {}).format(1234.45)
"1.234,45"
new Intl.NumberFormat('de-DE', {}).format(1234.45)
"1.234,45"

*/

const { isDate, isString, isNumber } = require('../../lib/js-types')
const { numberFormat } = require('./number-formats')

const dates = {
  buddhistCalendarOffset: 543,
  calendar: undefined,
  am: 'AM',
  pm: 'PM',
  daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  shortDaysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

  // javascript weeks start 0=Sunday
  daysOfWeekJs: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDaysOfWeekJs: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  DateFormatSetting: 'dd/MM/yyyy',
  DateTimeFormatSetting: 'dd/MM/yyyy HH:mm',
  TimeFormatSetting: 'HH:mm',

  SchedulerTimeFormatSetting: 'HH',
  SchedulerDateFormatSetting: 'ddd d/M/yyyy',

  datePatterns: {
    d: 'd/MM/yyyy',
    D: "dddd, d 'de' MMMM 'de' yyyy",
    F: "dddd, d 'de' MMMM 'de' yyyy HH:mm:ss",
    g: 'd/MM/yyyy HH:mm',
    G: 'd/MM/yyyy HH:mm:ss',
    m: "d 'de' MMMM",
    M: "d 'de' MMMM",
    s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
    t: 'HH:mm',
    T: 'HH:mm:ss',
    u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
    y: "MMMM 'de' yyyy",
    Y: "MMMM 'de' yyyy"
  }
}

// todo
let __ = s => s //

if (typeof window !== 'undefined') {
  window.__ =
    window.__ ||
    ((key, isData = false) => {
      if (ow.dev && window.cat[key] === undefined && !isData) {
        ;(window.missingTxKeys = window.missingTxKeys ?? []).push(key)
        console.log('Missing Translation key found:', key)
        window.cat[key] = key // prevent it being added again.
      }
      return window.cat[key] ?? key
    })
  __ = s => window.__?.(s) ?? s
}

const formatString = (v, fmt, locale = window.ow?.locale || 'en-US') => {
  if (v === undefined) return ''
  if (v === null) return ''

  if (!fmt) return v || v === 0 || v === false ? v.toString() : ''

  if (typeof v === 'string') return v

  if (isDate(v) && isString(fmt)) {
    return dates.formatDateTime(v, fmt.length === 1 ? dates.patterns[fmt] : fmt || fmt)
  }

  // time
  if (isNumber(v) && (fmt === 'HH:mm' || fmt === 'HH:mm:ss'))
    return dates.formatDate(new Date(v), fmt)

  if (fmt[0] === 'n' || fmt[0] === 'c') {
    // todo!
    if (v === undefined || v === null || isNaN(v)) return ''

    const minimumFractionDigits = fmt.length > 1 ? parseInt(fmt.substr(1, 100)) : undefined
    const maximumFractionDigits = minimumFractionDigits

    /**
     * Removes negative sign from zero, e.g. changes -0 to 0.
     *
     * @param {string} sVal
     * @returns {string}
     */
    const removeNegativeZero = sVal =>
      common.ctlParsers.float(sVal) === 0 ? sVal.replace('- ', '').replace('-', '') : sVal

    //return cultureFormat(v, fmt)
    return removeNegativeZero(
      new Intl.NumberFormat(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
        style: fmt[0] === 'c' ? 'currency' : 'decimal',
        currency: culture(locale).numberFormat.currency?.abbr || 'USD'
      }).format(v)
    )
  }

  if (isDate(v) && isString(fmt)) return dates.formatDate(v, fmt)

  return v
}

// const cultureNumberFormat = (locale = 'en-US') => {
//   // let { thousandSep, decimalSep } = intlSymbol('n', locale)
//   // let {
//   //   thousandSep: currencyThousand,
//   //   decimalSep: currencyDecimal,
//   //   sym: currencySym
//   // } = intlSymbol('c', locale)
//   const currentCulture = kendo.cultures.current

//   return {
//     '.': decimalSep,
//     ',': thousandSep,
//     currency: {
//       '.': currencyDecimal,
//       ',': currencyThousand,
//       symbol: currencySym
//     }
//   }
// }

const w = () => typeof window !== 'undefined' && window

const culture = (locale = w()?.ow?.locale || 'USD') => ({
  ...dates,
  numberFormat: w()?.kendo?.culture().numberFormat || {
    // window.kendo?.culture().numberFormat // cultureNumberFormat((typeof window !== 'undefined' && window.ow?.locale) || 'en-US')
    '.': '.',
    ',': ',',
    currency: {
      abbr: numberFormat(locale).currency,
      '.': '.',
      ',': ',',
      symbol: numberFormat(locale).symbol
    }
  }
})

module.exports = {
  culture,
  dates,
  __,
  formatString
}

// commandButton7.js

const { qc } = require('../cmp/qc')
const { humanize } = require('../humanize')
const { killEvent } = require('../killEvent')

const renderIcon = icon =>
  icon.display && icon.name ? qc('i.' + icon.name).attr({ 'aria-hidden': 'true' }) : []

const commandButton7 = opts => {
  if (typeof opts === 'string') opts = { type: opts }
  opts.name = opts.name || humanize(opts.type)
  opts.type = opts.type || opts.name

  if (opts.command && !opts.view && window.dev) throw 'commandButton7 must have opts.view'

  // Button basic settings
  if (typeof opts.class === 'string') opts.class = opts.class.split(' ')

  opts.class = opts.class || []

  opts.align = opts.align || ''
  opts.icon = opts.icon || { name: '' }
  // opts.command = opts.command || ''

  if (opts.align) opts.class.push('align-' + opts.align)
  if (opts.color) opts.class.push(opts.color + '-button')

  if (opts.icon.display && opts.icon.name) {
    opts.icon.align = opts.icon.align || 'left'
    opts.icon.color ? opts.class.push('icon-' + opts.icon.color) : null
    opts.icon.align ? opts.class.push('icon-' + opts.icon.align) : null
  }

  //Ctrl-D - Reserved for browser bookmark
  //F1 - Reserved for new Tab
  //F3 - Find
  //F6 - URL focus
  const isDefaultShortcut = {
    // 'filter-change': { key: 120 }, // F9
    save: { key: 113, focusFirst: true }, // F2
    refresh: { key: 118 } // F7?
    // close: { key: 115,  }, // F4?
    // 'close-form': { alt: true, key: 67 }, // Alt-C
    // if (e.which === 27) qc(g.closest('.win-con, body'))[0]?.closeForm?.(), 200); // ESC
    // if (e.which && e.altKey) {
    //   if (e.which === 114 && cmd === 'delete') me.el.click() //F3
    //   if (opts.shortcut) {
    //     if (!opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //     // Ctrl + Alt + key
    //     if (e.ctrlKey)
    //       if (opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //   }
    // }
  }

  if (!opts.shortcut) opts.shortcut = isDefaultShortcut[opts.command]

  const { userRole = {} } = opts.view.viewdata

  const cmd = opts.command

  const hasPermissions = !(
    cmd !== 'close' &&
    cmd !== 'refresh' &&
    (!userRole ||
      (!userRole.CanWrite &&
        (cmd === 'new' ||
          (!userRole.CanRead && cmd === 'edit') ||
          cmd === 'copy' ||
          cmd === 'save' ||
          cmd === 'add-row')) ||
      (!userRole.CanDelete && cmd === 'delete'))
  )

  const me = qc('button.ow-button' + (opts.command ? '.command-button' : ''))
    .props({
      hasPermissions,
      odisable(v = true) {
        if (!hasPermissions) v = true
        me.disabled = v ? false : true
        me.attr({ disabled: me.disabled ? 'true' : undefined })
      }
    })
    .kids(opts.content || [renderIcon(opts.icon), opts.label ?? __(opts.name)])
    .addClass(opts.class.join(' '))
    .attr({
      id: opts.id || undefined,
      'data-command': opts.command,
      'data-target-ref': opts.targetRef,
      'data-shortcut-key': opts.shortcut?.key || undefined
    })
    .on('ow-datastatechange', (e, state) => {
      if (opts.targetRef !== state.dsName) return
      if (cmd === 'save' || cmd === 'cancel') {
        var disableSaveCancel = !state.editing || !me.hasPermissions
        me.disabled = disableSaveCancel
        // me.odisable(disableSaveCancel)
      }
    })
    // .bindState(
    //   () => dc.hasChanged(),
    //   function (v) {
    //     this.disabled = !v
    //   }
    // )
    .bindState('disabled', () => me.attr({ disabled: me.disabled ? 'true' : undefined }))
    .on('click', e => me.disabled && killEvent(e))
    .on('click', () => {
      const $w = qc(me.el.closest('.win-con') ?? document.body)
      let target
      var targetRef = opts.targetRef
      if (targetRef) target = $w.find('#' + targetRef + ' , .iden-' + targetRef)[0]

      if (!target) target = me.el // grids etc atomatically register themselves with the view
      cmd && $(target).trigger('command-' + cmd, me.el)
    })

  return me
}
module.exports.commandButton7 = commandButton7

const shortcutKeyHandler = view =>
  function (e) {
    if (e.which === 113) {
      return killEvent(e)
    }

    if (e.altKey) {
      view.qTop.find('[data-shortcutkey]')
    }
  }

module.exports.shortcutKeyHandler = shortcutKeyHandler

const { qc } = require('../cmp/qc')
const { rs } = require('./rs7')

/**
 * Creates a cmp component representing a toggle group, containing an arbitrary number of buttons.
 * @param {object} props - Properties for toggle group component.
 * @property {string[]} props.labels - Array of strings, each representing the label for each toggle button. Specify a blank string to represent no label. Order array elements in order you want buttons to display (left to right).
 * @returns {object} - cmp toggle group component.
 */
const toggleBtns7 = opts => {
  let value // = opts.labels.map(() => false)

  if (opts.value) value = opts.value

  const me = qc(
    'span.ow-ctl-wrap.ow-toggle-btns7',
    opts.labels.map((label, i) => {
      const btn = qc('a.toggle-btn7', label)
        .attr({ href: '#' })
        .bindState(
          () => value,
          v => (i === v ? btn.addClass('on') : btn.removeClass('on'))
        )
        .on('click', () => me.val(i))

      return btn
    })
  ).props({
    val(v) {
      if (arguments.length)
        if (value !== v) {
          value = v
          me.renderAsync()
        }
      return value
    }
  })

  me.wrap = () => me
  me.rs = () => opts.rs ?? rs({ ...opts }, me)

  return me
}

module.exports = { toggleBtns7 }

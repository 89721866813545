window.require = module => window[module] // hack for browserify excludes, couldn't get expose working

require('../../lib/dates')
require('./polyfills')

window.cacheUrl = 'v=' + new Date().getTime()

window.common = {}
const { common } = window

Object.assign(common, require('./_v'))
Object.assign(common, require('./_id'))
common.theme = require('./theme')
Object.assign(common, require('./toValueText'))
Object.assign(common, require('./debounce'))

Object.assign(common, require('./element-map'))
Object.assign(common, require('./culture'))
Object.assign(common, require('./set-culture'))
common.dates = require('./dates')
Object.assign(common, require('./ctl-format'))
Object.assign(common, require('./html-encode'))
common.toString = common.formatString

common.events = require('./events')
common.killEvent = require('./killEvent').killEvent
Object.assign(common, require('./ajax'))

common.nojquery = require('./no-jquery')
window.no$ = common.nojquery.no$

window.$ajax = common.$ajax

window.cmp = common.cmp = require('./cmp/cmp')
window.qc = common.cmp.qc

Object.assign(common, require('./odisable'))
Object.assign(common, require('./icon-codes'))
Object.assign(common, require('./add-css'))

window.pop = require('./pop-box')
common.pop = window.pop

const controls = {}
common.controls = controls

controls.gridPager = require('./controls/grid-pager').gridPager
Object.assign(controls, require('./controls/check')) // qCheck, basicEdCheck
Object.assign(controls, require('./controls/qc-applyattrs')) // applyAttrsAndClasses
Object.assign(controls, require('./controls/q-calendar')) // qCalendar
Object.assign(controls, require('./controls/databind'))
Object.assign(controls, require('./controls/rs'))

Object.assign(controls, require('./controls/text4'))
Object.assign(controls, require('./controls/checklist'))
Object.assign(controls, require('./controls/owSplitter'))
Object.assign(controls, require('./viewWindow'))

common.ow7 = require('./ow7/ow7').ow7
common.ow6 = require('./ow6/ow6').ow6
common.ow5 = require('./ow5/ow5').ow5
common.ow4 = require('./ow4/ow4').ow4
common.ow0 = require('./ow0/ow0').ow0

// Deprecated unbundled qc built ow4 and ow5 controls.
common.qcControls = require('./qcControls/common-qc-controls').qcControls

document.body.addEventListener?.(
  'dragstart',
  e => (window.dragEl = e.target),
  true // capture
)

window?.addEventListener?.('resize', e =>
  no$(document.body)
    .find('.win-con')
    .forEach(el => window.qc(el).trigger('resize', { originalEvent: e }))
)

// turn off browser interference
document.body.addEventListener(
  'focus',
  e => {
    const el = e.target
    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
      el.setAttribute('autocomplete', 'off')
      el.setAttribute('spellcheck', 'false')
    }
  },
  true
)

// href:# -> prevent navigation
document.body.addEventListener(
  'mousedown',
  e => {
    if (e.target.tagName === 'A' && e.target.href && e.target.href.substr(-1) === '#')
      e.target.href = 'javascript:void(0)'
  },
  true
)

module.exports = { common }

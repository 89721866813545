const { qc } = require('../cmp/qc')
const { ctlParsers, isNumeric } = require('../ctl-format')
const { dataBind } = require('./databind')
const { rs } = require('./rs')

/**
 * Cmp for using as part of an input based control - text4 and combo both use input4
 * @param {object} opts
 * @param {boolean} props.disabled
 * @param {boolean} props.readOnly
 * @returns Cmp Object
 */
const input4 = opts => {
  const me = qc('input.input4')
    .props({ opts, ...text4Props })
    .on('focus', () => {
      //normalize
      var decimal = common.culture().numberFormat['.']
      if (!me.el.value) return
      if (opts === 'currency')
        me.el.value = ('' + ctlParsers.currency(me.el.value)).replace('.', decimal)
      else if (opts.type === 'float')
        me.el.value = ('' + ctlParsers.float(me.el.value)).replace('.', decimal)
    })
    .on('keyup', function (e) {
      if (e.which === 13 && !e.shiftKey) me.resolve?.() // enter

      if (me.type === 'float' || me.type === 'currency' || me.type === 'int') {
        if (e.which > 46 || e.which === 8) {
          var decimal = common.culture().numberFormat['.'] //use standard decimal

          const { el } = me

          let isFloat = me.type === 'float' || me.type === 'currency'
          if (!isFloat) decimal = ''

          let validChars = Array.prototype.reduce.call(
            '-0123456789' + decimal,
            (t, c) => (t[c] = 1) && t,
            {}
          )
          let value = Array.prototype.filter.call(el.value, x => x in validChars).join('')

          // only a single decimal point
          value = value.split(decimal)
          if (value.length > 1) value[1] = decimal + value[1]
          value = value.join('')

          if (value !== el.value) el.value = value

          me.trigger('ow-change')
        }
      } else if (me.type === 'string' || me.type === 'textarea' || !me.type) me.trigger('ow-change')
    })
    .on('blur', () => {
      if ({ float: true, int: true, currency: true }[me.type]) me.val(me.val())
    })

  if (me.disabled) me.readOnly = true

  me._w = qc('span.ow-ctl-wrap.input4', me).props({ input: me })
  me.wrap = () => me._w
  if (me.disabled) me.wrap().addClass('ow-disabled') // makes the input transparent "grayed out"

  me.attr({ placeholder: opts.placeholder || opts.label || undefined })

  dataBind(me)

  // 'static-text'
  me.bindState(
    () => me.readOnly || me.obscure || me.disabled,
    v =>
      me.attr({
        disabled: v ? 'true' : undefined,
        readonly: v ? 'readonly' : undefined,
        tabindex: v ? '-1' : undefined
      })
  )

  me._rs = rs({ label: me.label ?? '' }, me._w)

  me.rs = () => me._rs

  return me
}

const formatV = (v, fmt, formatter) => {
  if (formatter) return formatter(v, fmt) || ''
  if (v && fmt) return common.toString(v, fmt) || ''
  return v === null || v === undefined ? '' : common.toString(v)
}

const text4Props = {
  val(v, model, populating = false) {
    const { format, parser, formatter, el } = this

    if (v !== undefined) {
      const value = formatV(v, format, formatter) || ''
      this.attr({ value })
      if (el && !populating) this.trigger('ow-change')
    }
    v = el.value

    if (v && parser) return parser(v)

    if (v === '') return this.blankValue
    return v
  },

  validate(onInvalid, messageArray = []) {
    const { label, fieldName, required, schema } = this.opts

    var name = label || fieldName

    var v = this.val()
    var hasValue = !(
      v === undefined ||
      v === null ||
      v === '' ||
      (typeof v === 'string' && v.trim() === '')
    )
    if ((required || (required !== false && schema?.required)) && !hasValue) {
      if (onInvalid) {
        onInvalid(name, 'must have a value', this.el, messageArray)
        return false
      }
    }

    let { minLength, maxLength, min, max, validation, ctlType } = this.opts

    if (typeof v === 'number' && isNaN(v) && ctlType === 'combo') {
      if (onInvalid) {
        onInvalid(name, 'must select from the list.', this.el, messageArray)
        return false
      }
    }
    minLength = minLength || min
    maxLength = maxLength || max

    if (typeof v === 'string' && minLength && minLength < (v || '').length) {
      onInvalid?.(name, 'must be have min length of ' + minLength, this.el, messageArray)
      return false
    }
    if (typeof v === 'string' && maxLength && maxLength < (v || '').length) {
      onInvalid?.(name, 'must be have max length of ' + maxLength, this.el, messageArray)
      return false
    }

    if (typeof validation === 'object' && validation) {
      if ('$ne' in validation && v === validation.$ne) {
        onInvalid?.(name, 'cannot be ' + validation.$ne, this.el, messageArray)
        return false
      }
    }

    let { isToField, fromCmp } = this.opts

    // Validation for fields with from value and to value
    if (['date', 'time', 'currency', 'float', 'int'].find(x => x === ctlType)) {
      if (isToField && fromCmp) {
        let fromField, toField
        let v = this.val()
        toField = typeof v === 'number' ? common.toString(v) : v

        const vFrom = fromCmp.val()
        fromField = typeof vFrom === 'number' ? common.toString(vFrom) : vFrom

        if (isNumeric(fromField) && isNumeric(toField)) {
          fromField = fromField * 1
          toField = toField * 1
        }
        if (fromField > toField) {
          if (onInvalid) {
            onInvalid(
              fromCmp.label + ' field',
              'From field must be always lower than to field',
              this.el,
              messageArray
            )
            return false
          }
        }
      }
    }

    return true
  },

  readFilter(filters) {
    let { op, fieldName, textFilterField, filterMap } = this.opts
    let v = this.val()
    this.isSet = false

    if (this.el.value === '' && (op === 'contains' || op === 'eq')) return

    var filter = {
      field: fieldName,
      operator: op,
      value: v
    }

    filter.operator = op ? op : filter.operator

    if (textFilterField && filter.value) {
      filter.field = textFilterField
      filter.operator = op || 'contains'
      filter.value = this.el.value || null
    }

    if (filterMap && filter.value) {
      const s = filter.value.toLowerCase()
      const matchFilter = v => ow5.filterFunctions[filter.operator](v.toLowerCase(), s)

      filter.value = Object.keys(filterMap)
        .filter(matchFilter)
        .map(k => filterMap[k])

      if (filter.value.length === 0) {
        ow.popInvalid(__('Invalid value for ' + filter.field))
        this.attr({ value: '' })
        return
      }
      filter.operator = 'in'
    }

    // if (filter.value !== null && filter.value !== '')
    filters.push(filter)

    this.isSet = true // indicates to the outside that there's a filter set on this field/col
  }
}

// const text4 = opts => {
//   // if (!opts.view) throw 'text4 requires opts.view to be set.'

//   const { label, fieldName } = opts
//   const tag = opts.type === 'textarea' ? 'textarea.text4' : 'input.text4'

//   const me = input4(opts).props(text4Props)

//   if (me.disabled) me.readOnly = true

//   me.wrapper = qc('span.ow-ctl-wrap.text4', me).props({ input: me })
//   if (me.disabled) me.wrapper.addClass('ow-disabled') // makes the input transparent "grayed out"

//   me.wrap = () => me.wrapper

//   if (opts.type === 'textarea') me.wrapper.addClass('ow-textarea-wrap')
//   if (opts.width)
//     me.wrapper.css({
//       display: 'inline-block',
//       width: opts.width
//     })

//   me.parser = me.parser || ctlParsers[me.type] || null
//   me.formatter = me.formatter || ctlFormatters[me.type] || null

//   if (!('blankValue' in me)) {
//     me.blankValue = ''
//     if (me.type === 'currency') me.blankValue = undefined
//     if (me.type === 'float') me.blankValue = undefined
//     if (me.type === 'int') me.blankValue = undefined
//     if (me.type === 'date') me.blankValue = undefined
//     if (me.type === 'datetime') me.blankValue = undefined
//     if (me.type === 'time') me.blankValue = undefined
//   }

//   me.attrs.type = me.obscure ? 'password' : 'text'
//   let ph = me.placeholder || me.label || ''
//   if (ph) me.attrs.placeholder = ph

//   let v = me.value !== undefined ? formatV(me.value, me.format, me.formatter) : ''
//   if (v) me.attrs.value = v

//   // if (me.model) modelBind(view, me, me.model) // will renderAsync() when data in the model changes

//   // 'static-text'
//   me.bindState(
//     () => me.readOnly || me.obscure || me.disabled,
//     v =>
//       me.attr({
//         disabled: v ? 'true' : undefined,
//         readonly: v ? 'readonly' : undefined,
//         tabindex: v ? '-1' : undefined
//       })
//   )

//   me.rs = () =>
//     rs(
//       {
//         label
//       },
//       me.wrap()
//     )

//   me.wrap().rs = () => me.rs()

//   return me
// }

// const initText4 = view => {
//   const scope = '#' + view.qTop.el.id

//   addCss(
//     'input4-css',
//     `
// ${scope} span.ow-ctl-wrap { display: inline-block; }
// ${scope} .input4.ow-ctl-wrap.ow-disabled { background: transparent; }
// ${scope} .input4.ow-ctl-wrap.ow-disabled input { background: transparent; }
// ${scope} i.text-item-icon { color: #307ebe; }
// `,
//     view.qTop.el.parentElement
//   )
// }

module.exports = { input4 }

exports.controls = {
  ...require('./check'), // qCheck, basicEdCheck
  ...require('./qc-applyattrs'), // applyAttrsAndClasses
  ...require('./q-calendar'), // qCalendar
  ...require('./databind'),
  ...require('./grid-pager'),
  ...require('./rs'),
  ...require('./text4'),
  ...require('./checklist'),
  ...require('./owSplitter'),
  ...require('../viewWindow'),
  ...require('../openChildView')
}

const isObject = x => typeof x === 'object'
const isFunction = x => typeof x === 'function'
const isDate = x => isObject(x) && x !== null && 'getFullYear' in x
const isString = v => typeof v === 'string'
const isNumber = v => typeof v === 'number'

const isValidDate = d => d instanceof Date && !isNaN(d)
const isInvalidDate = d => d instanceof Date && isNaN(d)

exports.isObject = isObject
exports.isFunction = isFunction
exports.isDate = isDate
exports.isString = isString
exports.isNumber = isNumber
exports.isValidDate = isValidDate
exports.isInvalidDate = isInvalidDate
